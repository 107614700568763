import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _typeof from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import DefaultLayout from "@/layout/Default";
import GuestLayout from "@/layout/Guest";
import RulesupLayout from "@/layout/RulesupLayout";
import FullScreenLayout from "@/layout/FullScreenLayout";
export default {
  name: "App",
  components: {
    DefaultLayout: DefaultLayout,
    GuestLayout: GuestLayout,
    RulesupLayout: RulesupLayout,
    FullScreenLayout: FullScreenLayout
  },
  created: function created() {
    vm.core = this;
    if (this.$route.query.fullsecreen == 1) {
      this.$store.state.layout = 'FullScreenLayout';
    }
  },
  computed: {
    fullsecreen: function fullsecreen() {
      return this.$store.state.layout;
    },
    routeMeta: function routeMeta() {
      return this.$route.matched.reduce(function (matched, m) {
        return Object.assign(matched, m.meta);
      }, {
        layout: this.$isRulesup ? "RulesupLayout" : this.$route.query.fullsecreen == 1 ? 'FullScreenLayout' : "DefaultLayout"
      });
    }
  },
  methods: {
    runExternalCommand: function runExternalCommand(params) {
      params = JSON.parse(params);
      if (_typeof(params) == "object") {
        if (params.type == "createNewTask") {
          this.$store.state.tasks.popup.task = {
            record_id: null,
            description: params.selectionText,
            attachment: params.imageData
          };
          this.$store.state.tasks.popup.show = true;
        } else if (params.type == "startAThread") {
          var _this$$store$state$ap;
          this.$store.state.app.templatePopup.data = (_this$$store$state$ap = {
            openSearchBox: false,
            recordId: null,
            attachment: params.imageData,
            record_id: null,
            subject: params.selectionText
          }, _defineProperty(_this$$store$state$ap, "recordId", null), _defineProperty(_this$$store$state$ap, "app", "inbox"), _defineProperty(_this$$store$state$ap, "model", "thread"), _this$$store$state$ap);
          this.$store.state.app.templatePopup.show = true;
          this.$store.state.app.templatePopup.showView = false;
        } else {}
      }
    }
  }
};