import _toConsumableArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.filter.js";
import usersMixin from "@/mixins/users.js";
export default {
  mixins: [usersMixin],
  props: {
    selected_users: [Object, Array, String, Number],
    nameHidden: Boolean,
    media_size: {
      type: String,
      default: 'small'
    },
    pushRouteOnClick: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    get_user_avatar: function get_user_avatar(id) {
      var users = this.users;
      var user = users.find(function (k) {
        return k.user_id == id;
      });
      return user;
    },
    pushRoute: function pushRoute(userId) {
      if (this.pushRouteOnClick) {
        this.$router.push({
          name: "overview",
          params: {
            page: 'member',
            id: userId
          }
        });
      }
    }
  },
  computed: {
    user_list: function user_list() {
      var colors = ["success", "info", "warning", "fail", "green", "black"];
      var ul = _toConsumableArray(this.users);
      var list = [];
      var alluser = [];
      if (typeof this.selected_users == "string" || typeof this.selected_users == "number") {
        alluser = this.selected_users.split(",");
      } else {
        alluser = this.selected_users;
      }
      // console.log(alluser,"alluser")

      if (alluser && alluser[0] && ul) {
        // console.log(alluser, ul);
        alluser.forEach(function (u, i) {
          var rc = ul.filter(function (k) {
            return k.userId == u || "U" + k.userId == u;
          });
          if (rc[0]) {
            var image = rc[0].firstname.charAt(0) + rc[0].lastname.charAt(0);
            var color_class = colors[i];
            rc[0].image = image;
            rc[0].color_class = color_class;
            list.push(rc[0]);
          }
        });
      }
      return list;
    }
  }
};