export var state = {
  tasks: [],
  search: {
    text: '',
    list_type: 'user_status_base',
    smartFilter: {
      value: ["my", "assigned_or_dependent"],
      owner: [],
      relation: []
    }
  },
  view: {
    selectedTab: 'list'
  },
  lastVisibilityStatus: {
    'BACKLOG': false
  },
  focusedTask: {
    statusId: ""
  },
  popup: {
    show: false,
    zindex: 99,
    task: {
      record_id: 0
    }
  },
  timeCounterAll: [],
  timeCounter: {
    time: 0,
    minutes: 0,
    hours: 0,
    uniq: '',
    task: null,
    thread: null,
    status: null,
    checked: false
  },
  counterIsRunning: false,
  toggleRunning: 'na',
  lastTaskUpdate: {
    work: '',
    // update or create or delete
    id: 0,
    time: 0
  },
  lastTimeEntryUpdate: {
    work: '',
    // update or create or delete
    id: 0,
    time: 0
  }
};