import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import svgIcon from "@/components/kiva/kvSvgIcon.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    svgIcon: svgIcon,
    PagesModal: function PagesModal() {
      return import("@/components/pages/forms/pagesModal");
    },
    PagesTemplateModal: function PagesTemplateModal() {
      return import("@/components/pages/forms/pagesTemplateModal");
    },
    InboxModal: function InboxModal() {
      return import("@/views/inbox/components/InboxModal");
    },
    quickTask: function quickTask() {
      return import("@/components/tasks/fast/form");
    },
    SessionExpired: function SessionExpired() {
      return import("@/components/global/sessionExpired");
    },
    internetConnection: function internetConnection() {
      return import("@/components/global/internetConnection");
    },
    inviteUser: function inviteUser() {
      return import("@/components/global/inviteUser");
    },
    helpMenu: function helpMenu() {
      return import("@/components/global/helpMenu");
    }
  },
  data: function data() {
    return {
      componentName: ""
    };
  },
  computed: _objectSpread(_objectSpread({
    templatePopup: {
      get: function get() {
        return this.$store.state.app.templatePopup;
      },
      set: function set(val) {
        this.$store.state.app.templatePopup = val;
      }
    },
    openPagePopup: {
      get: function get() {
        return this.$store.state.app.openPagePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPagePopup = val;
      }
    },
    openPageTemplatePopup: {
      get: function get() {
        return this.$store.state.app.openPageTemplatePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPageTemplatePopup = val;
      }
    },
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    HELP_DRAWER: {
      get: function get() {
        return this.$store.state.common.HELP_DRAWER;
      },
      set: function set(val) {
        this.$store.state.common.HELP_DRAWER = val;
      }
    },
    invitePeople: function invitePeople() {
      return this.$store.state.app.invitePeopleModal;
    },
    helpMenuModal: function helpMenuModal() {
      return this.$store.state.app.helpMenuModal;
    },
    quickTaskModal: function quickTaskModal() {
      return this.$store.state.app.quickTaskModal;
    },
    sessionDialogVisible: function sessionDialogVisible() {
      return this.$store.state.auth.forceLogin;
    },
    connectionLost: function connectionLost() {
      return this.$store.state.auth.connectionLost;
    },
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  }, mapGetters({
    profile: "users/MY_UX_PROFILE"
  })), {}, {
    device: function device() {
      return this.$store.state.app.device;
    },
    key: function key() {
      return this.$route.path;
    },
    threadPopup: {
      get: function get() {
        return this.$store.state.inbox.threadPopup;
      },
      set: function set(val) {
        this.$store.state.inbox.threadPopup = val;
      }
    }
  }),
  watch: {
    sessionDialogVisible: function sessionDialogVisible(val) {
      if (val) {
        this.componentName = "SessionExpired";
      }
    },
    connectionLost: function connectionLost(val) {
      if (val) {
        this.componentName = "internetConnection";
      }
    },
    invitePeople: function invitePeople(val) {
      if (val) {
        this.componentName = "inviteUser";
      }
    },
    helpMenuModal: function helpMenuModal(val) {
      if (val) {
        this.componentName = "helpMenu";
      }
    }
  }
};