!function () {
  var Ajax = {
      settings: {},
      post: function (t) {
        return new AjaxRequest("post", t);
      },
      get: function (t) {
        return new AjaxRequest("get", t);
      }
    },
    AjaxRequest = function (t, e) {
      var i = {
        method: t,
        url: "",
        before: function () {},
        success: function () {},
        error: function () {},
        data: !1,
        async: !0,
        headers: {}
      };
      this.p = this.extend(i, e), this.p = this.extend(this.p, Ajax.settings), this.p.method = this.p.method.toUpperCase(), this.prepareData(), this.xhr = new XMLHttpRequest(), this.xhr.open(this.p.method, this.p.url, this.p.async), this.setHeaders(), !1 !== ("function" != typeof this.p.before || this.p.before(this.xhr)) && this.send();
    };
  AjaxRequest.prototype = {
    extend: function (t, e) {
      if (e) for (var i in e) t[i] = e[i];
      return t;
    },
    prepareData: function () {
      "POST" !== this.p.method || this.isFormData() || (this.p.headers["Content-Type"] = "application/x-www-form-urlencoded"), "object" != typeof this.p.data || this.isFormData() || (this.p.data = this.toParams(this.p.data)), "GET" === this.p.method && (this.p.url = this.p.data ? this.p.url + "?" + this.p.data : this.p.url);
    },
    setHeaders: function () {
      for (var t in this.xhr.setRequestHeader("X-Requested-With", this.p.headers["X-Requested-With"] || "XMLHttpRequest"), this.p.headers) this.xhr.setRequestHeader(t, this.p.headers[t]);
    },
    isFormData: function () {
      return void 0 !== window.FormData && this.p.data instanceof window.FormData;
    },
    isComplete: function () {
      return !(this.xhr.status < 200 || 300 <= this.xhr.status && 304 !== this.xhr.status);
    },
    send: function () {
      this.p.async ? (this.xhr.onload = this.loaded.bind(this), this.xhr.send(this.p.data)) : (this.xhr.send(this.p.data), this.loaded.call(this));
    },
    loaded: function () {
      if (this.isComplete()) {
        var t = this.xhr.response,
          e = this.parseJson(t);
        t = e || t, "function" == typeof this.p.success && this.p.success(t, this.xhr);
      } else "function" == typeof this.p.error && this.p.error(this.xhr.statusText);
    },
    parseJson: function (t) {
      try {
        var e = JSON.parse(t);
        if (e && "object" == typeof e) return e;
      } catch (t) {}
      return !1;
    },
    toParams: function (e) {
      return Object.keys(e).map(function (t) {
        return encodeURIComponent(t) + "=" + encodeURIComponent(e[t]);
      }).join("&");
    }
  };
  var DomCache = [0],
    DomExpando = "data" + new Date().getTime(),
    Dom = function (t, e) {
      return this.parse(t, e);
    };
  Dom.ready = function (t) {
    "loading" != document.readyState ? t() : document.addEventListener("DOMContentLoaded", t);
  }, Dom.prototype = {
    get dom() {
      return !0;
    },
    get length() {
      return this.nodes.length;
    },
    parse: function (t, e) {
      var i;
      if (t) {
        if (t.dom) return this.nodes = t.nodes, t;
        i = "string" != typeof t ? t.nodeType && 11 === t.nodeType ? t.childNodes : t.nodeType || t === window ? [t] : t : /^\s*<(\w+|!)[^>]*>/.test(t) ? this.create(t) : this._query(t, e);
      } else i = [];
      this.nodes = this._slice(i);
    },
    create: function (t) {
      if (/^<(\w+)\s*\/?>(?:<\/\1>|)$/.test(t)) return [document.createElement(RegExp.$1)];
      var e = [],
        i = document.createElement("div"),
        s = i.childNodes;
      i.innerHTML = t;
      for (var r = 0, o = s.length; r < o; r++) e.push(s[r]);
      return e;
    },
    add: function (t) {
      return this.nodes = this.nodes.concat(this._toArray(t)), this;
    },
    get: function (t) {
      return this.nodes[t || 0] || !1;
    },
    getAll: function () {
      return this.nodes;
    },
    eq: function (t) {
      return new Dom(this.nodes[t]);
    },
    first: function () {
      return new Dom(this.nodes[0]);
    },
    last: function () {
      return new Dom(this.nodes[this.nodes.length - 1]);
    },
    contents: function () {
      return this.get().childNodes;
    },
    each: function (t) {
      for (var e = this.nodes.length, i = 0; i < e; i++) t.call(this, this.nodes[i].dom ? this.nodes[i].get() : this.nodes[i], i);
      return this;
    },
    is: function (t) {
      return 0 < this.filter(t).length;
    },
    filter: function (e) {
      var t;
      return void 0 === e ? this : (t = "function" == typeof e ? e : function (t) {
        return e instanceof Node ? e === t : e && e.dom ? -1 !== e.nodes.indexOf(t) : (t.matches = t.matches || t.msMatchesSelector || t.webkitMatchesSelector, 1 === t.nodeType && t.matches(e || "*"));
      }, new Dom(this.nodes.filter(t)));
    },
    not: function (e) {
      return this.filter(function (t) {
        return !new Dom(t).is(e || !0);
      });
    },
    find: function (s) {
      var r = [];
      return this.each(function (t) {
        for (var e = this._query(s || "*", t), i = 0; i < e.length; i++) r.push(e[i]);
      }), new Dom(r);
    },
    children: function (t) {
      var s = [];
      return this.each(function (t) {
        if (t.children) for (var e = t.children, i = 0; i < e.length; i++) s.push(e[i]);
      }), new Dom(s).filter(t);
    },
    parent: function (t) {
      var e = [];
      return this.each(function (t) {
        t.parentNode && e.push(t.parentNode);
      }), new Dom(e).filter(t);
    },
    parents: function (i, s) {
      s = this._getContext(s);
      var r = [];
      return this.each(function (t) {
        for (var e = t.parentNode; e && e !== s;) i ? new Dom(e).is(i) && r.push(e) : r.push(e), e = e.parentNode;
      }), new Dom(r);
    },
    closest: function (e, i) {
      i = this._getContext(i), e = e.dom ? e.get() : e;
      var s = [],
        r = e && e.nodeType;
      return this.each(function (t) {
        do {
          if (r && t === e || new Dom(t).is(e)) return s.push(t);
        } while ((t = t.parentNode) && t !== i);
      }), new Dom(s);
    },
    next: function (t) {
      return this._getSibling(t, "nextSibling");
    },
    nextElement: function (t) {
      return this._getSibling(t, "nextElementSibling");
    },
    prev: function (t) {
      return this._getSibling(t, "previousSibling");
    },
    prevElement: function (t) {
      return this._getSibling(t, "previousElementSibling");
    },
    css: function (s, r) {
      if (void 0 !== r || "object" == typeof s) return this.each(function (t) {
        var e = {};
        for (var i in "object" == typeof s ? e = s : e[s] = r, e) t.style && (t.style[i] = e[i]);
      });
      var t = this.get();
      return "width" === s || "height" === s ? t.style ? this._getHeightOrWidth(s, t, !1) + "px" : void 0 : t.style ? getComputedStyle(t, null)[s] : void 0;
    },
    attr: function (s, r, o) {
      if (o = o ? "data-" : "", void 0 !== r || "object" == typeof s) return this.each(function (t) {
        var e = {};
        for (var i in "object" == typeof s ? e = s : e[s] = r, e) 3 !== t.nodeType && ("checked" === i ? t.checked = e[i] : t.setAttribute(o + i, e[i]));
      });
      var t = this.get();
      return t && 3 !== t.nodeType ? "checked" === s ? t.checked : this._getBooleanFromStr(t.getAttribute(o + s)) : void 0;
    },
    data: function (t, e) {
      if (void 0 !== t) return this.attr(t, e, !0);
      function i(t) {
        return t[1].toUpperCase();
      }
      var s = /^data\-(.+)$/,
        r = this.get().attributes,
        o = {};
      for (var a in r) if (r[a] && s.test(r[a].nodeName)) {
        var n = r[a].nodeName.match(s)[1],
          l = r[a].value;
        n = n.replace(/-([a-z])/g, i), l = this._isObjectString(l) ? this._toObject(l) : this._isNumber(l) ? parseFloat(l) : this._getBooleanFromStr(l), o[n] = l;
      }
      return o;
    },
    val: function (e) {
      if (void 0 !== e) return this.each(function (t) {
        t.value = e;
      });
      var t = this.get();
      return t.type && "checkbox" === t.type ? t.checked : t.value;
    },
    removeAttr: function (t) {
      return this.each(function (e) {
        t.split(" ").forEach(function (t) {
          3 !== e.nodeType && e.removeAttribute(t);
        });
      });
    },
    removeData: function (t) {
      return this.each(function (e) {
        t.split(" ").forEach(function (t) {
          3 !== e.nodeType && e.removeAttribute("data-" + t);
        });
      });
    },
    dataset: function (e, i) {
      return this.each(function (t) {
        DomCache[this.dataindex(t)][e] = i;
      });
    },
    dataget: function (t) {
      return DomCache[this.dataindex(this.get())][t];
    },
    dataindex: function (t) {
      var e = t[DomExpando],
        i = DomCache.length;
      return e || (e = t[DomExpando] = i, DomCache[e] = {}), e;
    },
    addClass: function (t) {
      return this._eachClass(t, "add");
    },
    removeClass: function (t) {
      return this._eachClass(t, "remove");
    },
    toggleClass: function (t) {
      return this._eachClass(t, "toggle");
    },
    hasClass: function (e) {
      return this.nodes.some(function (t) {
        return !!t.classList && t.classList.contains(e);
      });
    },
    empty: function () {
      return this.each(function (t) {
        t.innerHTML = "";
      });
    },
    html: function (t) {
      return void 0 === t ? this.get().innerHTML || "" : this.empty().append(t);
    },
    text: function (e) {
      return void 0 === e ? this.get().textContent || "" : this.each(function (t) {
        t.textContent = e;
      });
    },
    after: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t) e.insertAdjacentHTML("afterend", t);else for (var i = t instanceof Node ? [t] : this._toArray(t).reverse(), s = 0; s < i.length; s++) e.parentNode.insertBefore(i[s], e.nextSibling);
        return e;
      });
    },
    before: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t) e.insertAdjacentHTML("beforebegin", t);else for (var i = t instanceof Node ? [t] : this._toArray(t), s = 0; s < i.length; s++) e.parentNode.insertBefore(i[s], e);
        return e;
      });
    },
    append: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t || "number" == typeof t) e.insertAdjacentHTML("beforeend", t);else for (var i = t instanceof Node ? [t] : this._toArray(t), s = 0; s < i.length; s++) e.appendChild(i[s]);
        return e;
      });
    },
    prepend: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t || "number" == typeof t) e.insertAdjacentHTML("afterbegin", t);else for (var i = t instanceof Node ? [t] : this._toArray(t).reverse(), s = 0; s < i.length; s++) e.insertBefore(i[s], e.firstChild);
        return e;
      });
    },
    wrap: function (t) {
      return this._inject(t, function (t, e) {
        var i = "string" == typeof t || "number" == typeof t ? this.create(t)[0] : t instanceof Node ? t : this._toArray(t)[0];
        return e.parentNode && e.parentNode.insertBefore(i, e), i.appendChild(e), new Dom(i);
      });
    },
    unwrap: function () {
      return this.each(function (t) {
        var e = new Dom(t);
        return e.replaceWith(e.contents());
      });
    },
    replaceWith: function (t) {
      return this._inject(t, function (t, e) {
        for (var i = document.createDocumentFragment(), s = "string" == typeof t || "number" == typeof t ? this.create(t) : t instanceof Node ? [t] : this._toArray(t), r = 0; r < s.length; r++) i.appendChild(s[r]);
        var o = i.childNodes[0];
        return e.parentNode.replaceChild(i, e), o;
      });
    },
    remove: function () {
      return this.each(function (t) {
        t.parentNode && t.parentNode.removeChild(t);
      });
    },
    clone: function (i) {
      var s = [];
      return this.each(function (t) {
        var e = this._clone(t);
        i && (e = this._cloneEvents(t, e)), s.push(e);
      }), new Dom(s);
    },
    show: function () {
      return this.each(function (t) {
        if (t.style && this._hasDisplayNone(t)) {
          var e = t.getAttribute("domTargetShow");
          t.style.display = e || "block", t.removeAttribute("domTargetShow");
        }
      }.bind(this));
    },
    hide: function () {
      return this.each(function (t) {
        if (t.style && !this._hasDisplayNone(t)) {
          var e = t.style.display;
          "block" !== e && t.setAttribute("domTargetShow", e), t.style.display = "none";
        }
      });
    },
    scrollTop: function (t) {
      var e = this.get(),
        i = e === window,
        s = 9 === e.nodeType,
        r = s ? document.scrollingElement || document.body.parentNode || document.body || document.documentElement : e;
      if (void 0 === t) return s ? void 0 !== window.pageYOffset ? window.pageYOffset : document.documentElement.scrollTop ? document.documentElement.scrollTop : document.body.scrollTop ? document.body.scrollTop : 0 : i ? window.pageYOffset : r.scrollTop;
      i ? window.scrollTo(0, t) : r.scrollTop = t;
    },
    offset: function () {
      return this._getDim("Offset");
    },
    position: function () {
      return this._getDim("Position");
    },
    width: function (t, e) {
      return this._getSize("width", "Width", t, e);
    },
    height: function (t, e) {
      return this._getSize("height", "Height", t, e);
    },
    outerWidth: function () {
      return this._getInnerOrOuter("width", "outer");
    },
    outerHeight: function () {
      return this._getInnerOrOuter("height", "outer");
    },
    innerWidth: function () {
      return this._getInnerOrOuter("width", "inner");
    },
    innerHeight: function () {
      return this._getInnerOrOuter("height", "inner");
    },
    click: function () {
      return this._triggerEvent("click");
    },
    focus: function () {
      return this._triggerEvent("focus");
    },
    blur: function () {
      return this._triggerEvent("blur");
    },
    trigger: function (r) {
      return this.each(function (t) {
        for (var e = r.split(" "), i = 0; i < e.length; i++) {
          var s;
          try {
            s = new window.CustomEvent(e[i], {
              bubbles: !0,
              cancelable: !0
            });
          } catch (t) {
            (s = document.createEvent("CustomEvent")).initCustomEvent(e[i], !0, !0);
          }
          t.dispatchEvent(s);
        }
      });
    },
    on: function (o, a, n) {
      return this.each(function (t) {
        for (var e = o.split(" "), i = 0; i < e.length; i++) {
          var s = this._getEventName(e[i]),
            r = this._getEventNamespace(e[i]);
          a = n ? this._getOneHandler(a, o) : a, t.addEventListener(s, a), t._e = t._e || {}, t._e[r] = t._e[r] || {}, t._e[r][s] = t._e[r][s] || [], t._e[r][s].push(a);
        }
      });
    },
    one: function (t, e) {
      return this.on(t, e, !0);
    },
    off: function (o, a) {
      function n(t, e, i) {
        return t === i;
      }
      function l(t, e, i, s) {
        return e === s;
      }
      function p(t, e, i, s) {
        return t === i && e === s;
      }
      function e() {
        return !0;
      }
      return void 0 === o ? this.each(function (t) {
        this._offEvent(t, !1, !1, a, e);
      }) : this.each(function (t) {
        for (var e = o.split(" "), i = 0; i < e.length; i++) {
          var s = this._getEventName(e[i]),
            r = this._getEventNamespace(e[i]);
          "_events" === r ? this._offEvent(t, s, r, a, n) : s || "_events" === r ? this._offEvent(t, s, r, a, p) : this._offEvent(t, s, r, a, l);
        }
      });
    },
    serialize: function (t) {
      for (var e = {}, i = this.get().elements, s = 0; s < i.length; s++) {
        var r = i[s];
        if ((!/(checkbox|radio)/.test(r.type) || r.checked) && r.name && !r.disabled && "file" !== r.type) {
          if ("select-multiple" === r.type) for (var o = 0; o < r.options.length; o++) {
            var a = r.options[o];
            a.selected && (e[r.name] = a.value);
          }
          e[r.name] = this._isNumber(r.value) ? parseFloat(r.value) : this._getBooleanFromStr(r.value);
        }
      }
      return t ? e : this._toParams(e);
    },
    ajax: function (t, e) {
      if (void 0 !== AjaxRequest) {
        var i = this.attr("method") || "post",
          s = {
            url: this.attr("action"),
            data: this.serialize(),
            success: t,
            error: e
          };
        return new AjaxRequest(i, s);
      }
    },
    _queryContext: function (t, e) {
      return 3 !== (e = this._getContext(e)).nodeType && "function" == typeof e.querySelectorAll ? e.querySelectorAll(t) : [];
    },
    _query: function (t, e) {
      if (e) return this._queryContext(t, e);
      if (/^[.#]?[\w-]*$/.test(t)) {
        if ("#" !== t[0]) return "." === t[0] ? document.getElementsByClassName(t.slice(1)) : document.getElementsByTagName(t);
        var i = document.getElementById(t.slice(1));
        return i ? [i] : [];
      }
      return document.querySelectorAll(t);
    },
    _getContext: function (t) {
      return (t = "string" == typeof t ? document.querySelector(t) : t) && t.dom ? t.get() : t || document;
    },
    _inject: function (t, e) {
      for (var i = this.nodes.length, s = []; i--;) {
        var r = "function" == typeof t ? t.call(this, this.nodes[i]) : t,
          o = 0 === i ? r : this._clone(r),
          a = e.call(this, o, this.nodes[i]);
        a && (a.dom ? s.push(a.get()) : s.push(a));
      }
      return new Dom(s);
    },
    _cloneEvents: function (t, e) {
      var i = t._e;
      if (i) for (var s in (e._e = i)._events) for (var r = 0; r < i._events[s].length; r++) e.addEventListener(s, i._events[s][r]);
      return e;
    },
    _clone: function (t) {
      if (void 0 !== t) return "string" == typeof t ? t : t instanceof Node || t.nodeType ? t.cloneNode(!0) : "length" in t ? [].map.call(this._toArray(t), function (t) {
        return t.cloneNode(!0);
      }) : void 0;
    },
    _slice: function (t) {
      return t && 0 !== t.length ? t.length ? [].slice.call(t.nodes || t) : [t] : [];
    },
    _eachClass: function (t, i) {
      return this.each(function (e) {
        if (t) {
          t.split(" ").forEach(function (t) {
            e.classList && e.classList[i](t);
          });
        }
      });
    },
    _triggerEvent: function (t) {
      var e = this.get();
      return e && 3 !== e.nodeType && e[t](), this;
    },
    _getOneHandler: function (t, e) {
      var i = this;
      return function () {
        t.apply(this, arguments), i.off(e);
      };
    },
    _getEventNamespace: function (t) {
      var e = t.split("."),
        i = e[1] ? e[1] : "_events";
      return e[2] ? i + e[2] : i;
    },
    _getEventName: function (t) {
      return t.split(".")[0];
    },
    _offEvent: function (t, e, i, s, r) {
      for (var o in t._e) for (var a in t._e[o]) if (r(a, o, e, i)) for (var n = t._e[o][a], l = 0; l < n.length; l++) void 0 !== s && n[l].toString() !== s.toString() || (t.removeEventListener(a, n[l]), t._e[o][a].splice(l, 1), 0 === t._e[o][a].length && delete t._e[o][a], 0 === Object.keys(t._e[o]).length && delete t._e[o]);
    },
    _getInnerOrOuter: function (t, e) {
      return this[t](void 0, e);
    },
    _getDocSize: function (t, e) {
      var i = t.body,
        s = t.documentElement;
      return Math.max(i["scroll" + e], i["offset" + e], s["client" + e], s["scroll" + e], s["offset" + e]);
    },
    _getSize: function (e, t, i, s) {
      if (void 0 !== i) return this.each(function (t) {
        i = parseFloat(i), i += this._adjustResultHeightOrWidth(e, t, s || "normal"), new Dom(t).css(e, i + "px");
      }.bind(this));
      var r = this.get();
      return i = 3 === r.nodeType ? 0 : 9 === r.nodeType ? this._getDocSize(r, t) : r === window ? window["inner" + t] : this._getHeightOrWidth(e, r, s || "normal"), Math.round(i);
    },
    _getHeightOrWidth: function (t, e, i) {
      if (!e) return 0;
      var s = t.charAt(0).toUpperCase() + t.slice(1),
        r = 0,
        o = getComputedStyle(e, null),
        a = new Dom(e),
        n = a.parents().filter(function (t) {
          return 1 === t.nodeType && "none" === getComputedStyle(t, null).display && t;
        });
      if ("none" === o.display && n.add(e), 0 !== n.length) {
        var l = "visibility: hidden !important; display: block !important;",
          p = [];
        n.each(function (t) {
          var e = new Dom(t),
            i = e.attr("style");
          null !== i && p.push(i), e.attr("style", null !== i ? i + ";" + l : l);
        }), r = a.get()["offset" + s] - this._adjustResultHeightOrWidth(t, e, i), n.each(function (t, e) {
          var i = new Dom(t);
          void 0 === p[e] ? i.removeAttr("style") : i.attr("style", p[e]);
        });
      } else r = e["offset" + s] - this._adjustResultHeightOrWidth(t, e, i);
      return r;
    },
    _adjustResultHeightOrWidth: function (t, e, i) {
      if (!e || !1 === i) return 0;
      var s = 0,
        r = getComputedStyle(e, null),
        o = "border-box" === r.boxSizing;
      return "height" === t ? (("inner" === i || "normal" === i && o) && (s += (parseFloat(r.borderTopWidth) || 0) + (parseFloat(r.borderBottomWidth) || 0)), "outer" === i && (s -= (parseFloat(r.marginTop) || 0) + (parseFloat(r.marginBottom) || 0))) : (("inner" === i || "normal" === i && o) && (s += (parseFloat(r.borderLeftWidth) || 0) + (parseFloat(r.borderRightWidth) || 0)), "outer" === i && (s -= (parseFloat(r.marginLeft) || 0) + (parseFloat(r.marginRight) || 0))), s;
    },
    _getDim: function (t) {
      var e = this.get();
      return 3 === e.nodeType ? {
        top: 0,
        left: 0
      } : this["_get" + t](e);
    },
    _getPosition: function (t) {
      return {
        top: t.offsetTop,
        left: t.offsetLeft
      };
    },
    _getOffset: function (t) {
      var e = t.getBoundingClientRect(),
        i = t.ownerDocument,
        s = i.documentElement,
        r = i.defaultView;
      return {
        top: e.top + r.pageYOffset - s.clientTop,
        left: e.left + r.pageXOffset - s.clientLeft
      };
    },
    _getSibling: function (e, i) {
      var s,
        r = (e = e && e.dom ? e.get() : e) && e.nodeType;
      return this.each(function (t) {
        for (; t = t[i];) if (r && t === e || new Dom(t).is(e)) return void (s = t);
      }), new Dom(s);
    },
    _toArray: function (t) {
      if (t instanceof NodeList) {
        for (var e = [], i = 0; i < t.length; i++) e[i] = t[i];
        return e;
      }
      return void 0 === t ? [] : t.dom ? t.nodes : t;
    },
    _toParams: function (t) {
      var e = "";
      for (var i in t) e += "&" + this._encodeUri(i) + "=" + this._encodeUri(t[i]);
      return e.replace(/^&/, "");
    },
    _toObject: function (t) {
      return new Function("return " + t)();
    },
    _encodeUri: function (t) {
      return encodeURIComponent(t).replace(/!/g, "%21").replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\*/g, "%2A").replace(/%20/g, "+");
    },
    _isNumber: function (t) {
      return !isNaN(t) && !isNaN(parseFloat(t));
    },
    _isObjectString: function (t) {
      return -1 !== t.search(/^{/);
    },
    _getBooleanFromStr: function (t) {
      return "true" === t || "false" !== t && t;
    },
    _hasDisplayNone: function (t) {
      return "none" === t.style.display || "none" === (t.currentStyle ? t.currentStyle.display : getComputedStyle(t, null).display);
    }
  };
  var uuid = 0,
    ArticleEditor = function (t, e) {
      return ArticleEditorInit(t, e);
    };
  ArticleEditor.instances = [], ArticleEditor.version = "1.4.8", ArticleEditor.settings = {}, ArticleEditor.lang = {}, ArticleEditor._mixins = {}, ArticleEditor._store = {}, ArticleEditor._subscribe = {}, ArticleEditor.keycodes = {
    BACKSPACE: 8,
    DELETE: 46,
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    SPACE: 32,
    ESC: 27,
    TAB: 9,
    CTRL: 17,
    META: 91,
    SHIFT: 16,
    ALT: 18,
    RIGHT: 39,
    LEFT: 37
  }, ArticleEditor.dom = function (t, e) {
    return new Dom(t, e);
  }, ArticleEditor.ajax = Ajax;
  var ArticleEditorInit = function (t, i, e) {
    var s,
      r = "article-editor";
    return ArticleEditor.dom(t).each(function (t) {
      var e = ArticleEditor.dom(t);
      (s = e.dataget(r)) || (s = new App(e, i, uuid), e.dataset(r, s), ArticleEditor.instances[uuid] = s, uuid++);
    }), s;
  };
  ArticleEditor.add = function (t, e, i) {
    if (i.translations && (ArticleEditor.lang = ArticleEditor.extend(!0, ArticleEditor.lang, i.translations)), i.defaults) {
      var s = {};
      s[e] = i.defaults, ArticleEditor.opts = ArticleEditor.extend(!0, ArticleEditor.opts, s);
    }
    if ("mixin" === t) ArticleEditor._mixins[e] = i;else {
      if (i.subscribe) for (var r in i.subscribe) {
        void 0 === ArticleEditor._subscribe[r] && (ArticleEditor._subscribe[r] = []);
        var o = {
          module: e,
          func: i.subscribe[r]
        };
        ArticleEditor._subscribe[r].push(o);
      }
      function a() {}
      if ((a.prototype = i).mixins) for (var n = 0; n < i.mixins.length; n++) ArticleEditor.inherit(a, ArticleEditor._mixins[i.mixins[n]]);
      ArticleEditor._store[e] = {
        type: t,
        proto: a
      };
    }
  }, ArticleEditor.addLang = function (t, e) {
    void 0 === ArticleEditor.lang[t] && (ArticleEditor.lang[t] = {}), ArticleEditor.lang[t] = ArticleEditor.extend(!0, ArticleEditor.lang[t], e);
  }, ArticleEditor.inherit = function (t, e) {
    function i() {}
    i.prototype = e;
    var s = new i();
    for (var r in t.prototype) t.prototype.__lookupGetter__(r) ? s.__defineGetter__(r, t.prototype.__lookupGetter__(r)) : s[r] = t.prototype[r];
    return t.prototype = s, t.prototype.super = e, t;
  }, ArticleEditor.error = function (t) {
    throw t;
  }, ArticleEditor.extend = function () {
    var i = {},
      s = !1,
      t = 0,
      e = arguments.length;
    "[object Boolean]" === Object.prototype.toString.call(arguments[0]) && (s = arguments[0], t++);
    function r(t) {
      for (var e in t) Object.prototype.hasOwnProperty.call(t, e) && (s && "[object Object]" === Object.prototype.toString.call(t[e]) ? i[e] = ArticleEditor.extend(!0, i[e], t[e]) : i[e] = t[e]);
    }
    for (; t < e; t++) {
      r(arguments[t]);
    }
    return i;
  }, ArticleEditor.opts = {
    plugins: [],
    editor: {
      focus: !1,
      control: !0,
      classname: "arx-content",
      lang: "en",
      scrollTarget: document,
      direction: "ltr",
      spellcheck: !0,
      grammarly: !1,
      notranslate: !1,
      minHeight: !1,
      maxHeight: !1,
      undoredo: !1,
      shortcutsPopup: !0,
      multipleSelection: !1
    },
    source: !0,
    image: {
      upload: !1,
      url: !0,
      select: !1,
      name: "file",
      data: !1,
      drop: !0,
      multiple: !0,
      clipboard: !0,
      tag: "figure"
    },
    codemirror: !1,
    buffer: {
      limit: 100
    },
    path: {
      title: "## editor.title ##"
    },
    autosave: {
      url: !1,
      name: !1,
      data: !1
    },
    paste: {
      clean: !0,
      autolink: !0,
      paragraphize: !0,
      plaintext: !1,
      linkTarget: !1,
      images: !0,
      links: !0,
      keepStyle: [],
      keepClass: [],
      keepAttrs: ["td", "th"],
      formTags: ["form", "input", "button", "select", "textarea", "legend", "fieldset"],
      blockTags: ["pre", "div", "h1", "h2", "h3", "h4", "h5", "h6", "table", "tbody", "thead", "tfoot", "th", "tr", "td", "ul", "ol", "li", "blockquote", "p", "figure", "figcaption", "address", "section", "header", "footer", "aside", "article"],
      inlineTags: ["a", "svg", "img", "br", "strong", "ins", "code", "del", "span", "samp", "kbd", "sup", "sub", "mark", "var", "cite", "small", "b", "u", "em", "i", "abbr"]
    },
    plaintext: {
      markup: !1,
      classname: "arx-text"
    },
    tab: {
      spaces: !1
    },
    noneditable: {
      classname: "noneditable"
    },
    pre: {
      classname: !1,
      spaces: 4
    },
    clean: {
      enter: !0
    },
    toolbar: {
      sticky: !0,
      stickyMinHeight: 200,
      stickyTopOffset: 0
    },
    buttons: {
      editor: {
        shortcuts: {
          title: "## buttons.shortcuts ##",
          command: "shortcuts.buildPopup",
          observer: "shortcuts.observePopup"
        },
        templates: {
          title: "## buttons.templates ##",
          command: "template.buildPopup",
          observer: "template.observe"
        },
        html: {
          title: "## buttons.html ##",
          command: "source.toggle"
        }
      },
      toolbar: {
        add: {
          title: "## buttons.add ##",
          popup: {
            name: "addbar",
            width: "400px",
            builder: "toolbar.addbar"
          }
        },
        undo: {
          title: "## buttons.undo ##",
          command: "buffer.undo"
        },
        redo: {
          title: "## buttons.redo ##",
          command: "buffer.redo"
        }
      },
      control: {
        trash: {
          command: "block.remove",
          title: "## command.delete ##"
        },
        duplicate: {
          command: "block.duplicate",
          title: "## command.duplicate ##"
        }
      }
    },
    embed: {
      responsive: "embed-responsive"
    },
    code: {
      template: "<pre></pre>"
    },
    line: !0,
    layer: {
      template: "<div></div>"
    },
    table: {
      template: "<table><tr><td></td><td></td></tr><tr><td></td><td></td></tr></table>"
    },
    addbar: {
      text: {
        title: "## buttons.text ##",
        command: "block.addBlock"
      },
      image: {
        title: "## buttons.image ##",
        command: "image.buildPopup",
        observer: "image.observeAdd"
      },
      embed: {
        title: "## buttons.embed ##",
        command: "embed.buildPopup",
        observer: "embed.observeAdd"
      },
      line: {
        title: "## buttons.line ##",
        command: "block.addBlock",
        observer: "block.observeAdd"
      },
      table: {
        title: "## buttons.table ##",
        command: "table.addBlock",
        observer: "table.observeAdd"
      },
      snippet: {
        title: "## buttons.snippet ##",
        command: "snippet.buildPopup",
        observer: "snippet.observeAdd"
      },
      quote: {
        title: "## buttons.quote ##",
        command: "block.addBlock",
        observer: "block.observeAdd"
      },
      code: {
        title: "## buttons.code ##",
        command: "code.buildPopup",
        observer: "code.observeAdd"
      },
      grid: {
        title: "## buttons.grid ##",
        command: "grid.buildPopup",
        observer: "grid.observeAdd"
      },
      layer: {
        title: "## buttons.layer ##",
        command: "block.addBlock",
        observer: "block.observeAdd"
      }
    },
    format: {
      p: {
        title: "## format.normal-text ##",
        params: {
          tag: "p",
          block: "paragraph"
        }
      },
      h1: {
        title: '<span style="font-size: 20px; font-weight: bold;">## format.large-heading ##</span>',
        params: {
          tag: "h1",
          block: "heading"
        }
      },
      h2: {
        title: '<span style="font-size: 16px; font-weight: bold;">## format.medium-heading ##</span>',
        params: {
          tag: "h2",
          block: "heading"
        }
      },
      h3: {
        title: '<span style="font-weight: bold;">## format.small-heading ##</span>',
        params: {
          tag: "h3",
          block: "heading"
        }
      },
      ul: {
        title: "&bull; ## format.unordered-list ##",
        params: {
          tag: "ul",
          block: "list"
        }
      },
      ol: {
        title: "1. ## format.ordered-list ##",
        params: {
          tag: "ol",
          block: "list"
        }
      }
    },
    inline: {
      bold: {
        title: '<span style="font-weight: bold;">## inline.bold ##</span>',
        params: {
          tag: "b"
        }
      },
      italic: {
        title: '<span style="font-style: italic;">## inline.italic ##</span>',
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: '<span style="text-decoration: line-through;">## inline.deleted ##</span>',
        params: {
          tag: "del"
        }
      }
    },
    link: {
      size: 30,
      nofollow: !1,
      target: !1
    },
    outset: {
      "outset-none": {
        title: "## outset.none ##",
        params: {
          classname: !1
        }
      },
      "outset-left": {
        title: "## outset.left ##",
        params: {
          classname: "outset-left"
        }
      },
      "outset-both": {
        title: "## outset.both ##",
        params: {
          classname: "outset-both"
        }
      },
      "outset-right": {
        title: "## outset.right ##",
        params: {
          classname: "outset-right"
        }
      }
    },
    align: {
      "align-left": {
        title: "## align.left ##",
        params: {
          classname: "align-left"
        }
      },
      "align-center": {
        title: "## align.center ##",
        params: {
          classname: "align-center"
        }
      },
      "align-right": {
        title: "## align.right ##",
        params: {
          classname: "align-right"
        }
      },
      "align-justify": {
        title: "## align.justify ##",
        params: {
          classname: "align-justify"
        }
      }
    },
    valign: {
      "valign-none": {
        title: "## valign.none ##",
        params: {
          classname: !1
        }
      },
      "valign-top": {
        title: "## valign.top ##",
        params: {
          classname: "valign-top"
        }
      },
      "valign-middle": {
        title: "## valign.middle ##",
        params: {
          classname: "valign-middle"
        }
      },
      "valign-bottom": {
        title: "## valign.bottom ##",
        params: {
          classname: "valign-bottom"
        }
      }
    },
    quote: {
      template: "<blockquote><p>Quote...</p><p><cite>Author Attribution</cite></p></blockquote>"
    },
    grid: {
      classname: "grid",
      overlay: !0,
      columns: {
        size: 12,
        gutter: "24px",
        classname: "column",
        prefix: "column-"
      },
      patterns: ["6|6", "4|4|4", "3|3|3|3", "2|2|2|2|2|2", "3|6|3", "2|8|2", "5|7", "7|5", "4|8", "8|4", "3|9", "9|3", "2|10", "10|2", "12"]
    },
    snippets: {
      json: !1
    },
    templates: {
      json: !1
    },
    shortcutsBase: {
      "meta+z": "## shortcuts.meta-z ##",
      "meta+shift+z": "## shortcuts.meta-shift-z ##",
      "meta+a": "## shortcuts.meta-a ##",
      "meta+shift+a": "## shortcuts.meta-shift-a ##",
      "meta+click": "## shortcuts.meta-click ##"
    },
    shortcuts: {
      "ctrl+shift+d, meta+shift+d": {
        title: "## shortcuts.meta-shift-d ##",
        name: "meta+shift+d",
        command: "block.duplicate"
      },
      "ctrl+shift+up, meta+shift+up": {
        title: "## shortcuts.meta-shift-up ##",
        name: "meta+shift+&uarr;",
        command: "block.moveUp"
      },
      "ctrl+shift+down, meta+shift+down": {
        title: "## shortcuts.meta-shift-down ##",
        name: "meta+shift+&darr;",
        command: "block.moveDown"
      },
      "ctrl+shift+m, meta+shift+m": {
        title: "## shortcuts.meta-shift-m ##",
        name: "meta+shift+m",
        command: "inline.removeFormat"
      },
      "ctrl+b, meta+b": {
        title: "## shortcuts.meta-b ##",
        name: "meta+b",
        command: "inline.format",
        params: {
          tag: "b"
        }
      },
      "ctrl+i, meta+i": {
        title: "## shortcuts.meta-i ##",
        name: "meta+i",
        command: "inline.format",
        params: {
          tag: "i"
        }
      },
      "ctrl+u, meta+u": {
        title: "## shortcuts.meta-u ##",
        name: "meta+u",
        command: "inline.format",
        params: {
          tag: "u"
        }
      },
      "ctrl+h, meta+h": {
        title: "## shortcuts.meta-h ##",
        name: "meta+h",
        command: "inline.format",
        params: {
          tag: "sup"
        }
      },
      "ctrl+l, meta+l": {
        title: "## shortcuts.meta-l ##",
        name: "meta+l",
        command: "inline.format",
        params: {
          tag: "sub"
        }
      },
      "ctrl+alt+0, meta+alt+0": {
        title: "## shortcuts.meta-alt-0 ##",
        name: "meta+alt+0",
        command: "block.format",
        params: {
          tag: "p",
          block: "paragraph"
        }
      },
      "ctrl+alt+1, meta+alt+1": {
        title: "## shortcuts.meta-alt-1 ##",
        name: "meta+alt+1",
        command: "block.format",
        params: {
          tag: "h1",
          block: "heading"
        }
      },
      "ctrl+alt+2, meta+alt+2": {
        title: "## shortcuts.meta-alt-2 ##",
        name: "meta+alt+2",
        command: "block.format",
        params: {
          tag: "h2",
          block: "heading"
        }
      },
      "ctrl+alt+3, meta+alt+3": {
        title: "## shortcuts.meta-alt-3 ##",
        name: "meta+alt+3",
        command: "block.format",
        params: {
          tag: "h3",
          block: "heading"
        }
      },
      "ctrl+alt+4, meta+alt+4": {
        title: "## shortcuts.meta-alt-4 ##",
        name: "meta+alt+4",
        command: "block.format",
        params: {
          tag: "h4",
          block: "heading"
        }
      },
      "ctrl+alt+5, meta+alt+5": {
        title: "## shortcuts.meta-alt-5 ##",
        name: "meta+alt+5",
        command: "block.format",
        params: {
          tag: "h5",
          block: "heading"
        }
      },
      "ctrl+alt+6, meta+alt+6": {
        title: "## shortcuts.meta-alt-6 ##",
        name: "meta+alt+6",
        command: "block.format",
        params: {
          tag: "h6",
          block: "heading"
        }
      },
      "ctrl+shift+7, meta+shift+7": {
        title: "## shortcuts.meta-shift-7 ##",
        name: "meta+shift+7",
        command: "block.format",
        params: {
          tag: "ol",
          block: "list"
        }
      },
      "ctrl+shift+8, meta+shift+8": {
        title: "## shortcuts.meta-shift-8 ##",
        name: "meta+shift+8",
        command: "block.format",
        params: {
          tag: "ul",
          block: "list"
        }
      },
      "ctrl+], meta+]": {
        title: "## shortcuts.meta-indent ##",
        name: "meta+]",
        command: "list.indent"
      },
      "ctrl+[, meta+[": {
        title: "## shortcuts.meta-outdent ##",
        name: "meta+[",
        command: "list.outdent"
      }
    },
    markerChar: "\ufeff",
    arxclasses: "arx-block-editable arx-block-focus arx-block-focus-offset arx-block-multiple-focus arx-block-multiple-hover arx-block-hover arx-block-no-hover arx-grid-overlay arx-empty-layer",
    tags: {
      denied: ["font", "html", "head", "link", "title", "body", "meta", "applet", "marquee"],
      form: ["form", "input", "button", "select", "textarea", "legend", "fieldset"],
      inline: ["a", "svg", "span", "strong", "strike", "b", "u", "em", "i", "code", "del", "ins", "samp", "kbd", "sup", "sub", "mark", "var", "cite", "small", "abbr"],
      block: ["pre", "ul", "ol", "li", "p", "h1", "h2", "h3", "h4", "h5", "h6", "dl", "dt", "dd", "div", "table", "tbody", "thead", "tfoot", "tr", "th", "td", "blockquote", "output", "figcaption", "figure", "address", "main", "section", "header", "footer", "aside", "article", "iframe"]
    },
    bsmodal: !1,
    blocks: {
      text: {
        selector: !1,
        command: "parser.parseTextBlock",
        editable: !0,
        controls: !0,
        hover: !0
      },
      paragraph: {
        selector: "p",
        command: "parser.parseTextBlock",
        editable: !0,
        controls: !0,
        hover: !0
      },
      heading: {
        selector: "h1, h2, h3, h4, h5, h6",
        command: "parser.parseTextBlock",
        editable: !0,
        controls: !0,
        hover: !0
      },
      quote: {
        selector: "blockquote",
        command: "parser.parseQuote",
        editable: !1,
        controls: !0,
        hover: !1
      },
      quoteitem: {
        selector: "blockquote p",
        command: "parser.parseBlock",
        editable: !0,
        controls: !1,
        hover: !0
      },
      list: {
        selector: "ul, ol",
        command: "parser.parseList",
        editable: !0,
        controls: !0,
        hover: !0
      },
      figcaption: {
        selector: "figcaption",
        command: "parser.parseBlock",
        editable: !0,
        controls: !1,
        hover: !0
      },
      table: {
        selector: "table",
        command: "parser.parseBlock",
        editable: !1,
        controls: !0,
        hover: !0
      },
      row: {
        selector: "tr",
        command: "parser.parseBlock",
        editable: !1,
        controls: !1,
        hover: !1
      },
      cell: {
        selector: "td, th",
        command: "parser.parseBlock",
        editable: !0,
        controls: !1,
        hover: !0
      },
      layer: {
        selector: "div, main, section, header, footer, aside, article",
        command: "parser.parseLayer",
        editable: !1,
        controls: !0,
        hover: !0
      },
      line: {
        selector: "hr",
        command: "parser.parseBlock",
        editable: !1,
        controls: !0,
        hover: !0
      },
      code: {
        selector: "pre",
        command: "parser.parseCode",
        editable: !1,
        controls: !0,
        hover: !0
      },
      image: {
        selector: "img",
        command: "parser.parseImage",
        editable: !1,
        controls: !0,
        hover: !0
      },
      embed: {
        selector: "figure, iframe",
        command: "parser.parseEmbed",
        editable: !1,
        controls: !0,
        hover: !0
      },
      snippet: {
        selector: !1,
        command: "parser.parseBlock",
        editable: !1,
        controls: !0,
        hover: !0
      },
      column: {
        selector: !1,
        command: "parser.parseBlock",
        editable: !1,
        controls: !1,
        hover: !1
      },
      grid: {
        selector: !1,
        command: "parser.parseBlock",
        editable: !1,
        controls: !0,
        hover: !1
      },
      noneditable: {
        selector: !1,
        command: !1,
        editable: !1,
        controls: !0,
        hover: !0
      }
    },
    regex: {
      youtube: /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com\S*[^\w\-\s])([\w\-]{11})(?=[^\w\-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi,
      vimeo: /(http|https)?:\/\/(?:www.|player.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_-]+)?/gi,
      imageurl: /((https?|www)[^\s]+\.)(jpe?g|png|gif)(\?[^\s-]+)?/gi,
      url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
    }
  }, ArticleEditor.lang.en = {
    accessibility: {
      "help-label": "Rich text editor"
    },
    editor: {
      title: "Article",
      multiple: "Multiple"
    },
    command: {
      delete: "Delete",
      duplicate: "Duplicate",
      sort: "Sort",
      edit: "Edit"
    },
    placeholders: {
      figcaption: "Type caption (optional)",
      text: "Type something...",
      code: "Edit to add code...",
      layer: "Press enter to add a new text..."
    },
    align: {
      left: "Left align",
      center: "Center align",
      right: "Right align",
      justify: "Justify"
    },
    valign: {
      none: "None",
      top: "Top",
      middle: "Middle",
      bottom: "Bottom"
    },
    outset: {
      none: "None",
      left: "Left",
      both: "Both",
      right: "Right"
    },
    shortcuts: {
      "meta-a": "Select text in the block",
      "meta-shift-a": "Select all blocks",
      "meta-click": "Select multiple blocks",
      "meta-z": "Undo",
      "meta-shift-z": "Redo",
      "meta-shift-m": "Remove inline format",
      "meta-b": "Bold",
      "meta-i": "Italic",
      "meta-u": "Underline",
      "meta-h": "Superscript",
      "meta-l": "Subscript",
      "meta-alt-0": "Normal text",
      "meta-alt-1": "Heading 1",
      "meta-alt-2": "Heading 2",
      "meta-alt-3": "Heading 3",
      "meta-alt-4": "Heading 4",
      "meta-alt-5": "Heading 5",
      "meta-alt-6": "Heading 6",
      "meta-shift-7": "Ordered List",
      "meta-shift-8": "Unordered List",
      "meta-indent": "Indent",
      "meta-outdent": "Outdent",
      "meta-shift-backspace": "Delete block",
      "meta-shift-d": "Duplicate block",
      "meta-shift-up": "Move line up",
      "meta-shift-down": "Move line down"
    },
    headings: {
      h1: "Large Heading",
      h2: "Medium Heading",
      h3: "Small Heading",
      h4: "Heading 4",
      h5: "Heading 5",
      h6: "Heading 6"
    },
    inline: {
      bold: "Bold",
      italic: "Italic",
      deleted: "Deleted"
    },
    list: {
      indent: "Indent",
      outdent: "Outdent"
    },
    link: {
      link: "Link",
      "edit-link": "Edit link",
      unlink: "Unlink",
      "link-in-new-tab": "Open link in new tab",
      save: "Save",
      insert: "Insert",
      cancel: "Cancel",
      text: "Text",
      url: "URL"
    },
    table: {
      width: "Width",
      nowrap: "Nowrap",
      save: "Save",
      cancel: "Cancel"
    },
    image: {
      or: "or",
      "alt-text": "Alt Text",
      save: "Save",
      link: "Link",
      delete: "Delete",
      cancel: "Cancel",
      insert: "Insert",
      "link-in-new-tab": "Open link in new tab",
      "url-placeholder": "Paste url of image...",
      "upload-new-placeholder": "Drag to upload a new image<br>or click to select"
    },
    code: {
      code: "Code",
      insert: "Insert",
      save: "Save",
      cancel: "Cancel"
    },
    embed: {
      embed: "Embed",
      insert: "Insert",
      save: "Save",
      cancel: "Cancel",
      description: "Paste any embed/html code or enter the url (vimeo or youtube video only)",
      "responsive-video": "Responsive video"
    },
    upload: {
      placeholder: "Drag to upload <br>or click to select"
    },
    templates: {
      templates: "Templates"
    },
    snippets: {
      snippets: "Snippets"
    },
    format: {
      "normal-text": "Normal Text",
      "large-heading": "Large Heading",
      "medium-heading": "Medium Heading",
      "small-heading": "Small Heading",
      "unordered-list": "Unordered List",
      "ordered-list": "Ordered List"
    },
    buttons: {
      "transform-to-text": "Transform to text",
      align: "Alignment",
      valign: "Valign",
      outset: "Outset",
      indent: "Indent",
      outdent: "Outdent",
      head: "Head",
      row: "Row",
      cell: "Cell",
      html: "HTML",
      templates: "Templates",
      shortcuts: "Keyboard Shortcuts",
      format: "Format",
      table: "Table",
      add: "Add",
      undo: "Undo",
      redo: "Redo",
      style: "Style",
      config: "Config",
      text: "Text",
      embed: "Embed",
      snippet: "Snippet",
      grid: "Grid",
      line: "Line",
      image: "Image",
      quote: "Quote",
      code: "Code",
      layer: "Layer"
    },
    blocks: {
      noneditable: "Noneditable",
      paragraph: "Paragraph",
      heading: "Heading",
      image: "Image",
      figcaption: "Figcaption",
      embed: "Embed",
      line: "Line",
      code: "Code",
      quote: "Quote",
      quoteitem: "Paragraph",
      snippet: "Snippet",
      column: "Column",
      grid: "Grid",
      list: "List",
      table: "Table",
      row: "Row",
      text: "Text",
      cell: "Cell"
    }
  };
  var App = function (t, e, i) {
    this.uuid = i, this.keycodes = ArticleEditor.keycodes, this.dom = ArticleEditor.dom, this.ajax = ArticleEditor.ajax, this.$win = this.dom(window), this.$doc = this.dom(document), this.$body = this.dom("body"), this.$element = t, this._store = ArticleEditor._store, this._subscribe = ArticleEditor._subscribe, this.initialSettings = e, this._initer = ["setting", "lang"], this._priority = ["container", "source", "editor", "toolbar", "statusbar", "path", "control", "popup", "focus", "buffer"], this._plugins = [], this.started = !1, this.start();
  };
  App.prototype = {
    start: function (t) {
      this.isStarted() || (t && (this.initialSettings = t), this._initCore(), this._plugins = this.setting.get("plugins"), this.broadcast("app.before.start"), this._initModules(), this._initPlugins(), this._startPriority(), this._startModules(), this._startPlugins(), this.started = !0, this.broadcast("app.start"));
    },
    isStarted: function () {
      return this.started;
    },
    stop: function () {
      this.isStopped() || (this.broadcast("app.before.stop"), this._stopPriority(), this._stopModules(), this._stopPlugins(), this.started = !1, this.broadcast("app.stop"));
    },
    isStopped: function () {
      return !this.started;
    },
    broadcast: function (t, e) {
      var i = e instanceof App.Event ? e : new App.Event(t, e);
      if (void 0 !== this._subscribe[t]) for (var s = this._subscribe[t], r = 0; r < s.length; r++) {
        var o = this[s[r].module];
        o && s[r].func.call(o, i);
      }
      var a = this.setting.has("subscribe") ? this.setting.get("subscribe") : {};
      return "function" == typeof a[t] && a[t].call(this, i), i;
    },
    create: function (t) {
      void 0 === this._store[t] && ArticleEditor.error('The class "' + t + '" does not exist.');
      var e = [].slice.call(arguments, 1),
        i = new this._store[t].proto();
      if (i.app = this, i.uuid = this.uuid, i.dom = this.dom, i.ajax = this.ajax, this.lang && (i.lang = this.lang), this.setting && (i.opts = this.setting.dump()), i.init) {
        var s = i.init.apply(i, e);
        i = s || i;
      }
      return i;
    },
    api: function (t) {
      for (var e = [].slice.call(arguments, 1), i = t.split("."), s = i.pop(), r = this, o = 0; o < i.length; o++) r = r[i[o]];
      if (r && "function" == typeof r[s]) return r[s].apply(r, e);
    },
    _initCore: function () {
      for (var t = 0; t < this._initer.length; t++) this[this._initer[t]] = this.create(this._initer[t]);
    },
    _initModules: function () {
      for (var t in this._store) "module" === this._store[t].type && -1 === this._initer.indexOf(t) && (this[t] = this.create(t));
    },
    _initPlugins: function () {
      var t = this.setting.get("plugins");
      for (var e in this._store) "plugin" === this._store[e].type && -1 !== t.indexOf(e) && (this[e] = this.create(e));
    },
    _startPriority: function () {
      for (var t = 0; t < this._priority.length; t++) this._call(this[this._priority[t]], "start");
    },
    _startModules: function () {
      this._iterate("module", "start");
    },
    _startPlugins: function () {
      this._iterate("plugin", "start");
    },
    _stopPriority: function () {
      for (var t = this._priority.slice().reverse(), e = 0; e < t.length; e++) this._call(this[t[e]], "stop");
    },
    _stopModules: function () {
      this._iterate("module", "stop");
    },
    _stopPlugins: function () {
      this._iterate("plugin", "stop");
    },
    _iterate: function (t, e) {
      for (var i in this._store) {
        var s = "module" === t ? -1 === this._priority.indexOf(i) : -1 !== this._plugins.indexOf(i);
        this._store[i].type === t && s && this._call(this[i], e);
      }
    },
    _call: function (t, e) {
      "function" == typeof t[e] && t[e].apply(t);
    }
  }, App.Event = function (t, e) {
    this.name = t, this.params = void 0 === e ? {} : e, this.stopped = !1;
  }, App.Event.prototype = {
    is: function (t) {
      return this.get(t);
    },
    has: function (t) {
      return void 0 !== this.params[t];
    },
    get: function (t) {
      return this.params[t];
    },
    set: function (t, e) {
      this.params[t] = e;
    },
    stop: function () {
      this.stopped = !0;
    },
    isStopped: function () {
      return this.stopped;
    }
  }, ArticleEditor.add("mixin", "block", {
    init: function (t) {
      this.$block = this._parse(t), this.$block.dataset("instance", this), this.$block.attr("data-arx-type", this.getType()), this.isEditable() && (this.$block.attr("contenteditable", !0), this.opts.editor.grammarly || this.$block.attr("data-gramm_editor", !1));
    },
    isEmpty: function () {
      return this.app.create("utils").isEmptyHtml(this.$block.html());
    },
    isAllowedButton: function (t, e) {
      var i = this.getType();
      return (!t.hasOwnProperty("except") || -1 === t.except.indexOf(i)) && (void 0 === t.blocks || "all" === t.blocks || !(!Array.isArray(t.blocks) || -1 === t.blocks.indexOf(i)) || !("first-level" !== t.blocks || !this.isFirstLevel()) || !("editable" !== t.blocks || !this.isEditable()));
    },
    isEditable: function () {
      return this._getProp("editable");
    },
    isHoverable: function () {
      return this._getProp("hover");
    },
    isControls: function () {
      return this._getProp("controls");
    },
    isFirstLevel: function () {
      return this.$block.attr("data-arx-first-level");
    },
    isSelectedAll: function () {
      return this.app.create("selection").isAll(this.$block);
    },
    isStart: function () {
      return this.app.create("caret").is("start", this.$block);
    },
    isEnd: function () {
      return this.app.create("caret").is("end", this.$block);
    },
    getType: function () {
      return this.type;
    },
    getName: function () {
      var t = this.lang.get("blocks"),
        e = this.getType(),
        i = this.$block.attr("data-title");
      return name = i || ("layer" === e ? this._getNameByTag() : void 0 !== t[e] ? t[e] : name), name;
    },
    getTag: function () {
      return this.$block.get().tagName.toLowerCase();
    },
    getParent: function (t) {
      return this.$block.closest(t, this.app.editor.$editor);
    },
    getClass: function (t) {
      if ("string" == typeof t) return this.$block.hasClass(t);
      for (var e = 0; e < t.length; e++) if (this.$block.hasClass(t[e])) return t[e];
      return !1;
    },
    getAlign: function () {
      var t = this._buildClasses(this.opts.align),
        e = this.getClass(t),
        i = {
          name: e,
          key: this._getKeyByClassName(this.opts.align, e)
        };
      return !1 === e && (i = this._getDefaultAlign()), i;
    },
    getValign: function () {
      var t = this._buildClasses(this.opts.valign),
        e = this.getClass(t);
      return {
        name: e,
        key: !1 === e ? "valign-none" : this._getKeyByClassName(this.opts.valign, e)
      };
    },
    getOutset: function () {
      var t = this._buildClasses(this.opts.outset),
        e = this.getClass(t);
      return {
        name: e,
        key: !1 === e ? "outset-none" : this._getKeyByClassName(this.opts.outset, e)
      };
    },
    setAlign: function (t) {
      this.removeAlign(), this.$block.addClass(t);
    },
    setValign: function (t) {
      this.removeValign(), t && this.$block.addClass(t);
    },
    setOutset: function (t) {
      this.removeOutset(), t && this.$block.addClass(t), this.app.control.rebuild();
    },
    hasChildren: function () {
      return 0 !== this.$block.children().length;
    },
    removeAlign: function () {
      this._removeClasses(this.opts.align);
    },
    removeValign: function () {
      this._removeClasses(this.opts.valign);
    },
    removeOutset: function () {
      this._removeClasses(this.opts.outset);
    },
    removeEmptyAttr: function (t) {
      this.app.create("utils").removeEmptyAttr(this.$block, t);
    },
    remove: function (t) {
      var e = this.getType(),
        i = this.$block.parents("[data-arx-type]").first(),
        s = this.app.block.next(),
        r = this.app.block.prev();
      if ("image" === e) {
        var o = this.getImage();
        this.app.broadcast("image.remove", {
          url: o.attr("src"),
          id: o.attr("data-image")
        });
      }
      if (this.$block.remove(), this.app.block.unset(), this.app.broadcast("block.remove", {
        type: e,
        parent: i
      }), this.app.editor.images.observe(), !1 !== t) {
        if (0 !== this.app.editor.blocks.get().length) {
          if (0 !== i.length) {
            var a = i.attr("data-arx-type"),
              n = this.app.create("block." + a, i);
            if (-1 !== ["column", "layer"].indexOf(a) && !n.hasChildren() && n.isEmpty()) return this.app.create("utils").createPlaceholder(i), this.app.block.set(i);
          }
          return s ? this.app.block.set(s.$block) : r ? this.app.block.set(r.$block) : void 0;
        }
        this.app.editor.empty();
      }
    },
    format: function (e) {
      var t,
        i = this.app.create("caret"),
        s = this.app.create("selection"),
        r = this.getTag(),
        o = this.getType();
      if (this._isTextFormat(e) && (e.block = "text", e.tag = "div"), r !== e.tag) {
        var a;
        if (s.save(), "text" === o && this.$block.removeClass(this.opts.plaintext.classname), "list" === e.block) {
          if ("text" === o || "paragraph" === o || "heading" === o) this.app.create("content").removeBlockTags(this.$block.get()), this._wrapToItem();
        } else "heading" === e.block ? (this.$block.find("img").remove(), "list" === o && (t = this._prepareItemsToFormat())) : "paragraph" !== e.block && "text" !== e.block || "list" !== o || (t = this._prepareItemsToFormat());
        (t || this.$block).each(function (t) {
          a = this.convert(t, e), this.app.block.set(a), this.app.broadcast("block.format", {
            element: a
          });
        }.bind(this));
        var n = this.app.block.get();
        n.isEmpty() ? i.set("start", n.$block) : s.restore();
      }
    },
    split: function () {
      var t = this.app.create("utils").splitNode(this.$block),
        e = this.app.create("block." + this.getType(), t);
      return this.app.block.set(e.$block), !0;
    },
    empty: function () {
      return this.$block.html(""), this.$block;
    },
    convert: function (t, e) {
      var i = this.app.create("utils").replaceToTag(t, e.tag),
        s = this.app.parser.getProps(e.block);
      return "text" === e.block && this.opts.plaintext.markup && i.addClass(this.opts.plaintext.classname), this.app.parser.parseProps(i, e.block, s), this.app.block.unset(), this.app.editor.rebuild(), i;
    },
    handleSpace: function (t, e, i, s) {
      return this.isEditable() && t.shiftKey && !t.metaKey ? s.insertNonBreakSpace() : this.isEditable() ? void 0 : s.replaceToParagraph(this);
    },
    handleTab: function (t, e, i, s) {
      if (this.isEditable() && !1 !== this.opts.tab && s.traverseTab(t, e, this)) return !0;
    },
    handleEnter: function (t, e, i, s) {
      var r = this.getType();
      return "heading" === r || "paragraph" === r ? t.shiftKey || t.ctrlKey ? s.insertBreakline() : this.isSelectedAll() ? s.makeEmpty(this) : this.isEnd() ? s.createAfter() : this.isStart() ? s.createBefore(!1) : i.collapsed ? this.split() : !(i.range && !i.collapsed) || s.deleteContents(i.range) : "text" === r || "quoteitem" === r ? this.isSelectedAll() ? s.makeEmpty(this) : i.range && !i.collapsed ? s.deleteContents(i.range) : s.insertBreakline() : this.isEditable() ? void 0 : s.replaceToParagraph(this);
    },
    handleArrow: function (t, e, i, s) {
      if (this.isEditable() && e.is("left-right")) {
        var r = this.app.create("caret"),
          o = this.app.create("utils"),
          a = e.is("left") ? "before" : "after";
        if (s.trimInvisibleChar(t, a)) return !0;
        if (i.current && o.isEmptyOrImageInline(i.current)) return r.set(a, i.current), !0;
      }
      if (e.is("up-left")) {
        var n = this.app.block.prev();
        if (!this.isEditable() || this.isStart()) {
          if (n) this.app.block.set(n.$block, "end");else {
            if (this.isEditable() && this.isEmpty()) return !0;
            if (this.isFirstLevel()) return s.createBefore();
          }
          return !0;
        }
      } else if (e.is("down-right")) {
        var l = this.app.block.next();
        if (!this.isEditable() || this.isEnd()) {
          if (l) this.app.block.set(l.$block, "start");else {
            if (this.isEditable() && this.isEmpty()) return !0;
            if (this.isFirstLevel()) return s.createAfter();
          }
          return !0;
        }
      }
    },
    handleDelete: function (t, e, i, s) {
      var r = this.getType();
      if (this.isEditable()) {
        var o = e.is("backspace") ? "before" : "after";
        if (s.trimEmptyInlines(t)) return !0;
        if (s.trimInvisibleChar(t, o, !0)) return !0;
        s.removeUnwantedStyles(this), s.removeEmptySpans(this);
      }
      if ("cell" === r || "figcaption" === r || "quoteitem" === r) {
        if (this.isSelectedAll()) return s.makeEmpty(this);
      } else {
        if ("heading" !== r && "text" !== r && "paragraph" !== r) {
          n = this.app.block.next();
          return this.remove(), n && this.app.block.set(n.$block), !0;
        }
        if (this.isSelectedAll()) return s.makeEmpty(this);
        var a = ["paragraph", "heading", "text"],
          n = this.app.block.next(),
          l = this.app.block.prev(),
          p = this.app.create("caret");
        if (l && this.isStart() && e.is("backspace")) {
          if ("list" === l.getType()) {
            var c = this.$block.html(),
              h = l.$block.find("li").last();
            return p.set("end", h), h.append(c), this.$block.remove(), !0;
          }
          if (-1 !== a.indexOf(l.getType()) && l.isEmpty()) return l.remove(), !0;
          if (-1 !== a.indexOf(l.getType()) && !l.isEmpty()) {
            c = this.$block.html();
            return p.set("end", l.$block), l.$block.append(c), this.app.block.set(l.$block), this.$block.remove(), !0;
          }
          if (!l.isEditable()) return this.app.block.set(l.$block);
        } else if (n && this.isEnd() && e.is("delete")) {
          if ("list" === n.getType()) {
            (h = n.$block.find("li").first()).find("ul, ol, li").unwrap();
            c = h.html();
            return this.$block.append(c), h.remove(), !0;
          }
          if (-1 !== a.indexOf(n.getType()) && n.isEmpty()) return n.remove(), !0;
          if (-1 !== a.indexOf(n.getType()) && !n.isEmpty()) {
            c = n.$block.html();
            return this.$block.append(c), n.$block.remove(), !0;
          }
          if (!n.isEditable()) return this.app.block.set(n.$block), this.isEmpty() && this.remove(), !0;
        }
      }
    },
    _isTextFormat: function (t) {
      return "paragraph" === t.block && this.opts.plaintext.markup;
    },
    _wrapToItem: function () {
      var t = this.dom("<li>");
      t.html(this.$block.contents()), this.$block.append(t);
    },
    _prepareItemsToFormat: function () {
      var t = this.$block.children("li");
      return t.find("li").append("<br>").unwrap(), t.find("ul, ol").unwrap(), t.nodes.reverse(), t.each(function (t) {
        this.$block.after(t);
      }.bind(this)), this.$block.remove(), t;
    },
    _removeClasses: function (t) {
      var e = this._buildClasses(t);
      this.$block.removeClass(e.join(" ")), this.removeEmptyAttr("class");
    },
    _buildClasses: function (t) {
      var e = [];
      for (var i in t) t[i].params.classname && e.push(t[i].params.classname);
      return e;
    },
    _getKeyByClassName: function (t, e) {
      for (var i in t) if (t[i].params.classname === e) return i;
    },
    _getDefaultAlign: function () {
      if (!1 !== this.opts.align) {
        var t = this.opts.editor.direction,
          e = this.opts.align,
          i = {};
        return "ltr" === t && void 0 !== e["align-left"] ? (i.name = e["align-left"].params.classname, i.key = "align-left") : "rtl" === t && void 0 !== e["align-right"] && (i.name = e["align-right"].params.classname, i.key = "align-right"), i;
      }
    },
    _getNameByTag: function () {
      var t = this.app.create("utils"),
        e = this.$block.get().tagName.toLowerCase();
      return t.capitalize(e);
    },
    _getProp: function (t) {
      return this.app.parser.getProp(this.getType(), t);
    }
  }), ArticleEditor.add("module", "observer", {
    init: function () {
      this.observer = !1, this.trigger = !0;
    },
    start: function () {
      if (window.MutationObserver) {
        var t = this.app.editor.$editor.get();
        this.observer = this._build(t), this.observer.observe(t, {
          attributes: !0,
          subtree: !0,
          childList: !0,
          characterData: !0,
          characterDataOldValue: !0
        });
      }
    },
    stop: function () {
      this.observer && this.observer.disconnect(), this.trigger = !0;
    },
    _build: function (e) {
      var i = this;
      return new MutationObserver(function (t) {
        i._observe(t[t.length - 1], e);
      });
    },
    _observe: function (t, e) {
      "attributes" === t.type && t.target === e || this.trigger && (this.app.broadcast("observer.change"), this.app.editor.sync.build());
    }
  }), ArticleEditor.add("module", "autosave", {
    init: function () {
      this.utils = this.app.create("utils");
    },
    start: function () {},
    stop: function () {},
    send: function () {
      this.opts.autosave.url && this._sending();
    },
    _getName: function () {
      return this.opts.autosave.name ? this.opts.autosave.name : this.app.$element.attr("name") || "content" + this.uuid;
    },
    _sending: function () {
      var e = this._getName(),
        i = {};
      i[e] = this.app.$element.val(), i = this.utils.extendData(i, this.opts.autosave.data), this.ajax.post({
        url: this.opts.autosave.url,
        data: i,
        before: function (t) {
          if (this.app.broadcast("autosave.before.send", {
            xhr: t,
            name: e,
            data: i
          }).isStopped()) return !1;
        }.bind(this),
        success: function (t) {
          this._complete(t, e, i);
        }.bind(this)
      });
    },
    _complete: function (t, e, i) {
      var s = t && t.error ? "autosave.error" : "autosave.send";
      this.app.broadcast(s, {
        name: e,
        data: i,
        response: t
      });
    }
  }), ArticleEditor.add("module", "block", {
    init: function () {
      this.activeInstance = !1, this.caret = this.app.create("caret"), this.utils = this.app.create("utils"), this.content = this.app.create("content"), this.selection = this.app.create("selection");
    },
    buildPopup: function (t) {
      var e = {},
        i = this.get();
      if ("block" === t) for (var s in e = this.opts.format) e[s].command = "block.format";else if ("align" === t) for (var s in e = this.opts.align) e[s].command = "block.align";else if ("valign" === t) for (var s in e = this.opts.valign) e[s].command = "block.valign";else if ("outset" === t) if (i.isFirstLevel()) for (var s in e = this.opts.outset) e[s].command = "block.outset";else e = !1;
      return e;
    },
    is: function () {
      return this.activeInstance;
    },
    set: function (t, e, i, s) {
      var r = t.attr("data-arx-type"),
        o = this.app.create("block." + r, t);
      if (!0 === s || !this.activeInstance || t.get() !== this.activeInstance.$block.get()) return this._removeBlur(), this._removeFocus(), this._setFocus(o, e, i), this.activeInstance = o, this.app.control.open(), this.app.path.build(), this.app.toolbar.build(), this.app.editor.events.pause("multiple"), this.app.broadcast("block.set"), !0;
    },
    unset: function () {
      this._removeBlur(), this._removeFocus(), this.activeInstance = !1, this.app.control.close(), this.app.toolbar.build(), this.app.path.build(), this.app.broadcast("block.unset");
    },
    get: function () {
      return this.activeInstance;
    },
    copy: function (t) {
      var e = this.get();
      if (e.isEditable() && !e.isSelectedAll()) {
        var i = this.selection.getHtml().trim(),
          s = !0;
        if ("list" === e.getType()) {
          var r = e.getTag();
          -1 !== i.search(/<li/gi) && (-1 === i.search(/^<li/g) && (i = "<li>" + i + "</li>"), i = "<" + r + ">" + i + "</" + r + ">"), s = !1;
        }
        return t.preventDefault(), void this.content.copy(t, i, s);
      }
      t.preventDefault(), this.content.copy(t, e.$block);
    },
    cut: function (t) {
      var e = this.get();
      if (!e.isEditable() || e.isSelectedAll()) {
        if (t.preventDefault(), "embed" === e.getType()) {
          var i = decodeURI(e.$block.attr("data-embed-code"));
          this.content.copy(t, "<figure>" + i + "</figure>", !1);
        } else this.content.copy(t, e.$block);
        e.isEditable() ? e.empty() : this.remove();
      }
    },
    remove: function (t) {
      this.is() && this.activeInstance.remove(t);
    },
    duplicate: function () {
      if (this.is()) {
        var t = this.activeInstance.$block.clone();
        this.activeInstance.$block.after(t), this.utils.scrollToElement(t), this.set(t);
      }
    },
    observeAdd: function (t, e) {
      for (var i = ["quote", "line", "layer"], s = 0; s < i.length; s++) if (e === i[s] && !1 === this.opts[i[s]]) return !1;
      return t;
    },
    addBlock: function (t) {
      if (this.is()) {
        var e = "text" === t.name ? this.create() : this.app.create("block." + t.name);
        this.add(e), this.app.popup.close();
      }
    },
    insert: function (t, e) {
      var i = this.get(),
        s = this.content.parse(t, !0, !0),
        r = {
          list: !1,
          position: "after"
        };
      i ? i.$block.hasClass("arx-empty-layer") ? (i.$block.html(s), i.$block.removeClass("arx-empty-layer")) : i.isEditable() ? r = this._insertToEditable(i, s) : i.$block.after(s) : this.app.editor.$editor.prepend(s), this.app.editor.rebuild();
      var o = this.app.editor.$editor.find("[data-arx-inserted]");
      if (this.app.broadcast("block.insert", {
        inserted: o
      }), !1 !== e && !r.list) {
        var a = "after" === r.position ? o.first() : o.last();
        this.app.block.set(a);
      }
      o.removeAttr("data-arx-inserted");
    },
    add: function (t, e, i) {
      this._add(t, e), this.app.editor.rebuild(), !1 !== i && (this.set(t.$block), this.utils.scrollToElement(t.$block)), this.app.broadcast("block.add", {
        instance: t
      });
    },
    change: function (t, e) {
      var i = this.get();
      i.$block.after(t.$block), i.$block.remove(), this.app.editor.rebuild(), !1 !== e && this.set(t.$block), this.app.broadcast("block.change", {
        instance: t
      });
    },
    create: function () {
      var t = this.opts.plaintext.markup ? "text" : "paragraph",
        e = this.app.create("block." + t),
        i = this.get();
      return i && !this.opts.clean.enter && 0 !== i.$block.length && t === i.getType() && this.utils.cloneAttributes(i.$block, e.$block), e;
    },
    moveUp: function (t) {
      if (!this.app.editor.blocks.isMultiple()) {
        var e = this.prev();
        if (e) {
          var i = t.e,
            s = this.activeInstance.$block,
            r = this.activeInstance.isEditable();
          i.preventDefault(), r && this.selection.save(s), e.$block.before(this.activeInstance.$block), this.set(this.activeInstance.$block, !1, !1, !0), r && this.selection.restore(s);
        }
      }
    },
    moveDown: function (t) {
      if (!this.app.editor.blocks.isMultiple()) {
        var e = this.next();
        if (e) {
          var i = t.e,
            s = this.activeInstance.$block,
            r = this.activeInstance.isEditable();
          i.preventDefault(), r && this.selection.save(s), e.$block.after(this.activeInstance.$block), this.set(this.activeInstance.$block, !1, !1, !0), r && this.selection.restore(s);
        }
      }
    },
    next: function () {
      return this._getSibling("nextElementSibling");
    },
    prev: function () {
      return this._getSibling("previousElementSibling");
    },
    input: function (t) {
      if (this.is()) {
        var e = t.get("e"),
          i = (t.get("key"), this.get()),
          s = this.selection.get(),
          r = this.app.create("input");
        return t.is("enter") && i.handleEnter(e, t, s, r) ? e.preventDefault() : t.is("arrow") && i.handleArrow(e, t, s, r) ? e.preventDefault() : t.is("space") && i.handleSpace(e, t, s, r) ? e.preventDefault() : t.is("tab") && i.handleTab(e, t, s, r) ? e.preventDefault() : (t.is("delete") || t.is("backspace")) && i.handleDelete(e, t, s, r) ? e.preventDefault() : void 0;
      }
    },
    observe: function (t, e) {
      var i = this.get();
      if ("align" === e) {
        var s = i.getAlign();
        for (var r in t) t[r].active = r === s.key;
      } else if ("valign" === e) {
        s = i.getValign();
        for (var r in t) t[r].active = r === s.key;
      } else if ("outset" === e) {
        s = i.getOutset();
        for (var r in t) t[r].active = r === s.key;
      } else if ("block" === e) {
        var o = i.getTag();
        for (var r in o = "text" === i.getType() ? "p" : o, t) t[r].active = r === o;
      }
      return t;
    },
    align: function (t) {
      this.app.popup.close();
      var e = this.get();
      if (e.setAlign(t.params.classname), "embed" === e.getType()) {
        var i = e.$block.find("figcaption");
        if (0 !== i.length) this.app.create("block.figcaption", i).setAlign(t.params.classname);
      }
      this.selection.restore(e.$block);
    },
    valign: function (t) {
      this.app.popup.close();
      var e = this.get();
      e.setValign(t.params.classname), this.selection.restore(e.$block);
    },
    outset: function (t) {
      this.app.popup.close();
      var e = this.get();
      e.setOutset(t.params.classname), this.selection.restore(e.$block);
    },
    format: function (t) {
      this.app.popup.close();
      var e = this.get(),
        i = e.getType();
      -1 !== ["paragraph", "text", "heading", "list"].indexOf(i) && e.format(t.params);
    },
    _add: function (t, e) {
      e = e || !1;
      var i = t.$block,
        s = this.get();
      if (s) s.$block.hasClass("arx-empty-layer") && !1 === e && (e = "append", s.$block.html(""), s.$block.removeClass("arx-empty-layer")), e ? s.$block[e](i) : s.isEditable() ? this._insertToEditable(s, i) : s.$block.after(i);else if ("start" === e) this.app.editor.$editor.prepend(i);else if ("end" === e) this.app.editor.$editor.append(i);else if (this.app.editor.blocks.isMultiple()) {
        var r = this.app.editor.blocks.get("multiple");
        r.first().before(t.$block), r.remove();
      } else this.app.editor.isSelectedAll() && this.app.editor.$editor.html(t.$block);
    },
    _insertToEditable: function (t, e) {
      var i = this.dom(e),
        s = this._isListToList(i, t),
        r = {
          position: "after",
          list: !1
        };
      if (t.isEmpty()) t.$block.after(e), t.$block.remove();else if (t.isStart()) s ? (t.$block.prepend(i.contents()), r.list = !0) : t.$block.before(e), r.position = "before";else if (t.isEnd()) s ? (t.$block.append(i.contents()), r.list = !0) : t.$block.after(e);else {
        this.selection.restore(t.$block);
        var o = this.utils.splitNode(t.$block);
        if (s) t.$block.append(i.contents()), t.$block.append(o.contents()), o.remove(), r.list = !0;else {
          var a = this.app.create("block." + t.getType(), o);
          a.$block.before(e), this._removeFocusFromElement(a.$block);
        }
      }
      return r;
    },
    _isListToList: function (t, e) {
      return "list" === e.getType() && -1 !== ["ul", "ol"].indexOf(t.get().tagName.toLowerCase());
    },
    _setFocus: function (t, e, i) {
      var s = !0;
      !1 !== this.opts.editor.control || this.app.control.hasButtons(t) || (s = !1), (t.isEditable() || s) && t.$block.addClass("arx-block-focus"), t.isEditable() ? (t.$block.focus(), e && this.caret.set(e, t.$block)) : (this.utils.saveScroll(), t.$block.attr("tabindex", "-1"), t.$block.focus(), this.utils.restoreScroll());
    },
    _removeBlur: function () {
      this.activeInstance && this.activeInstance.isEditable() && this.activeInstance.$block.blur();
    },
    _removeFocus: function () {
      this.app.editor.setSelectedAll(!1), this._removeFocusFromElement(this.app.editor.blocks.get());
    },
    _removeFocusFromElement: function (t) {
      t.removeClass("arx-block-focus arx-block-hover arx-block-focus-offset");
    },
    _getSibling: function (t) {
      for (var e = (r = this.get()).$block.get(), i = this.dom(); e = e[t];) if ("script" !== e.tagName && "none" !== getComputedStyle(e, null).display) {
        i = e;
        break;
      }
      var s = this.dom(i),
        r = !1,
        o = s.attr("data-arx-type");
      return o && (r = this.app.create("block." + o, s)), r;
    }
  }), ArticleEditor.add("module", "buffer", {
    init: function () {
      this.utils = this.app.create("utils"), this.offset = this.app.create("offset"), this.started = !1, this.storage2 = !1, this.storage = !1, this.state = !1, this.passed = !0, this.undoStorage = [], this.redoStorage = [];
    },
    start: function () {
      this.clear(), this.trigger(!0);
    },
    stop: function () {
      this.clear();
    },
    clear: function () {
      this.storage = !1, this.state = !1, this.passed = !0, this.undoStorage = [], this.redoStorage = [];
    },
    get: function () {
      return this.undoStorage;
    },
    add: function (t) {
      t && (t.ctrlKey || t.metaKey || this._isUndo(t) || this._isRedo(t)) || !this.app.observer.trigger || (this.state = this._createState(), !1 === this.started && (this._setBuffer(this.state, 0), this.started = !0));
    },
    trigger: function (t) {
      if (this.passed) {
        var e = this._createState();
        this.state ? e = this.state : this.state || t || (e = this.storage, this.started = !0), this._addBuffer(e), this.storage = this._createState(), this.state = !1;
      }
    },
    listen: function (t) {
      return this._isUndo(t) ? (t.preventDefault(), this.undo(), !0) : this._isRedo(t) ? (t.preventDefault(), this.redo(), !0) : void (this.passed = !0);
    },
    undo: function () {
      if (this._hasUndo()) {
        this.passed = !1;
        var t = this._getUndo();
        this._setRedo(), this.app.editor.$editor.html(t[0]), this.offset.set(t[1]), this._rebuild(t, "undo");
      }
    },
    redo: function () {
      if (this._hasRedo()) {
        this.passed = !1;
        var t = this.redoStorage.pop();
        this._addBuffer(t), this.app.editor.$editor.html(t[0]), this.offset.set(t[1]), this._rebuild(t, "redo");
      }
    },
    _rebuild: function (t, e) {
      this.app.editor.$editor.find(".arx-block-focus").each(function (t) {
        this.app.block.set(this.dom(t));
      }.bind(this)), this.app.editor.rebuild(), this.app.broadcast("buffer." + e, {
        html: t[0],
        offset: t[1]
      });
    },
    _isUndo: function (t) {
      var e = t.which;
      return (t.ctrlKey || t.metaKey) && 90 === e && !t.shiftKey && !t.altKey;
    },
    _isRedo: function (t) {
      var e = t.which;
      return (t.ctrlKey || t.metaKey) && (90 === e && t.shiftKey || 89 === e && !t.shiftKey) && !t.altKey;
    },
    _hasUndo: function () {
      return 0 !== this.undoStorage.length;
    },
    _hasRedo: function () {
      return 0 !== this.redoStorage.length;
    },
    _getUndo: function () {
      return 1 === this.undoStorage.length ? this.undoStorage[0] : this.undoStorage.pop();
    },
    _createState: function () {
      return {
        html: this.app.editor.$editor.html(),
        offset: this.offset.get()
      };
    },
    _setBuffer: function (t, e) {
      this.undoStorage[e] = [t.html, t.offset];
    },
    _addBuffer: function (t) {
      var e = this.undoStorage[this.undoStorage.length - 1];
      void 0 !== e && e[0] === t.html || (this.undoStorage.push([t.html, t.offset]), this._removeOverStorage());
    },
    _setRedo: function () {
      var t = this._createState();
      this.redoStorage.push([t.html, t.offset]), this.redoStorage = this.redoStorage.slice(0, this.opts.buffer.limit);
    },
    _removeOverStorage: function () {
      this.undoStorage.length > this.opts.buffer.limit && (this.undoStorage = this.undoStorage.slice(0, this.undoStorage.length - this.opts.buffer.limit));
    }
  }), ArticleEditor.add("module", "container", {
    init: function () {
      this.containers = ["toolbar", "editor", "source", "statusbar"];
    },
    start: function () {
      this._buildMain(), this._buildContainers(), this._buildBSModal();
    },
    stop: function () {
      this.$main.remove();
    },
    _buildMain: function () {
      this.$main = this.dom("<div>"), this.$main.addClass("arx-container arx-container-" + this.uuid), this.$main.attr("arx-uuid", this.uuid), this.app.$element.after(this.$main);
    },
    _buildContainers: function () {
      for (var t, e = 0; e < this.containers.length; e++) this["$" + (t = this.containers[e])] = this.dom("<div>"), this["$" + t].addClass("arx-" + t + "-container"), this.$main.append(this["$" + t]);
    },
    _buildBSModal: function () {
      this.opts.bsmodal = 0 !== this.$main.closest(".modal-dialog").length;
    }
  }), ArticleEditor.add("module", "editor", {
    init: function () {
      this.selectedAll = !1, this.savedSelection = !1, this.utils = this.app.create("utils"), this._build(), this._buildBlocksList(), this._buildClasses();
    },
    start: function () {
      var t = this.app.$element.val();
      this.app.$element.hide(), t = this.content.build(t), this._buildAccessibility(), this._buildOptions(), this._buildStyles(), this._buildGridCssVar(), this._appendToContainer(), this.content.set(t, !0), this.sync.compose();
    },
    stop: function () {
      this.events.stop(), this.app.$element.show();
    },
    rebuild: function () {
      this.blocks.build(), this.blocks.buildPlaceholders(), this.events.build(), this.images.observe();
    },
    is: function (t) {
      return this.dom(t).hasClass("arx-editor");
    },
    isSourceMode: function () {
      return "none" === this.app.container.$editor.css("display");
    },
    isSelectedAll: function () {
      return this.$editor.hasClass("arx-editor-selected");
    },
    isEmpty: function () {
      return this.utils.isEmptyHtml(this.$editor.html());
    },
    setFocus: function () {
      this.$editor.hasClass("arx-editor-focus") || (this.$editor.addClass("arx-editor-focus"), this.app.broadcast("editor.focus"));
    },
    removeFocus: function () {
      this.$editor.hasClass("arx-editor-focus") && (this.app.editor.setSelectedAll(!1), this.app.block.unset(), this.app.editor.blocks.unset(), this.$editor.blur(), this.$editor.removeClass("arx-editor-focus"), this.app.broadcast("editor.blur"));
    },
    hasFocus: function () {
      return this.$editor.hasClass("arx-editor-focus");
    },
    setSelectedAll: function (t) {
      t ? (this.$editor.addClass("arx-editor-selected"), this.$editor.attr("tabindex", "-1"), this.setFocus()) : this.$editor.removeClass("arx-editor-selected");
    },
    empty: function () {
      this.$editor.html("");
      var t = this.app.block.create();
      this.app.block.add(t, "start"), this.setSelectedAll(!1), this.images.observe(), this.sync.build();
    },
    input: function (t) {
      var e = t.get("e"),
        i = t.get("key"),
        s = this.app.block.get(),
        r = t.is("enter") || t.is("delete") || t.is("backspace") || t.is("alpha") || t.is("space");
      if (this.isSelectedAll() && r) return t.is("alpha") || t.is("space") ? void this.empty() : (e.preventDefault(), void setTimeout(this.empty.bind(this), 1));
      var o = s && s.isEditable() && s.isSelectedAll(),
        a = s && !s.isEditable(),
        n = t.is("ctrl") && 65 === i;
      if (n && (o || a) || t.is("shift") && n) return e.preventDefault(), window.getSelection().removeAllRanges(), this.app.block.unset(), this.setSelectedAll(!0), void this.blocks.get("first-level").addClass("arx-block-focus");
      t.is("ctrl") || t.is("shift") || this.setSelectedAll(!1);
    },
    executeEmbed: function (scripts) {
      if (void 0 === scripts) {
        var scripts = this.$editor.find("[data-arx-type=embed]").find("script").getAll();
        this.executeEmbed.call(this, scripts);
      } else for (var i = 0; i < scripts.length; i++) {
        if ("" !== scripts[i].src) {
          var src = scripts[i].src;
          this.app.$doc.find('head script[src="' + src + '"]').remove();
          var $script = this.dom("<script>");
          $script.attr("src", src), $script.attr("async defer"), $script.get().onload = function () {
            -1 !== src.search("instagram") && window.instgrm.Embeds.process(), this.executeEmbed(scripts.slice(i + 1));
          }.bind(this);
          var head = document.getElementsByTagName("head")[0];
          head && head.appendChild($script.get());
          break;
        }
        try {
          eval(scripts[i].innerHTML);
        } catch (t) {}
      }
    },
    _build: function () {
      this.$editor = this.dom("<div>");
    },
    _buildBlocksList: function () {
      this.blockslist = this.opts.blocks;
    },
    _buildAccessibility: function () {
      this.$editor.attr({
        "aria-labelledby": "article-editor-voice",
        role: "presentation"
      });
      var t = this.dom("<span />");
      t.addClass("arx-voice-label"), t.attr({
        id: "arx-voice-" + this.uuid,
        "aria-hidden": !1
      }), t.html(this.lang.get("accessibility.help-label")), this.app.container.$main.prepend(t);
    },
    _buildClasses: function () {
      for (var t = ["blocks", "content", "events", "images", "sync"], e = 0; e < t.length; e++) this[t[e]] = this.app.create("editor." + t[e]);
    },
    _buildStyles: function () {
      this.$editor.addClass("arx-editor arx-reset arx-editor-" + this.uuid), this.$editor.addClass(this.opts.editor.classname);
    },
    _buildGridCssVar: function () {
      if (this.opts.grid) {
        var t = document.documentElement.style;
        t.setProperty("--arx-grid-columns", this.opts.grid.columns.size), t.setProperty("--arx-grid-gutter", this.opts.grid.columns.gutter);
      }
    },
    _buildOptions: function () {
      var t = this.$editor,
        e = this.opts.editor;
      t.attr("dir", e.direction), e.minHeight && t.css("min-height", e.minHeight), e.maxHeight && t.css("max-height", e.maxHeight), e.notranslate && t.addClass("notranslate"), e.spellcheck || t.attr("spellcheck", !1);
    },
    _appendToContainer: function () {
      this.app.container.$editor.append(this.$editor);
    }
  }), ArticleEditor.add("class", "editor.blocks", {
    init: function () {
      this.utils = this.app.create("utils"), this.content = this.app.create("content"), this.multipleClass = "arx-block-multiple-focus", this.multipleHoverClass = "arx-block-multiple-hover";
    },
    build: function () {
      this._buildFirstLevel();
    },
    buildPlaceholders: function () {
      var i = this;
      this.app.editor.$editor.find("[data-arx-type=layer],[data-arx-type=column]").each(function (t) {
        if ("" === t.innerHTML.trim()) {
          var e = i.dom(t);
          i.utils.createPlaceholder(e);
        }
      });
    },
    isMultiple: function () {
      return 0 !== this.get("multiple").length;
    },
    remove: function (t) {
      this.get("multiple").remove();
    },
    copy: function (t) {
      t.preventDefault();
      var e = this.get("multiple");
      this._copy(t, e);
    },
    cut: function (t) {
      t.preventDefault();
      var e = this.get("multiple");
      this._copy(t, e), e.remove();
    },
    set: function (t, e) {
      e.preventDefault(), (t = t.closest("[data-arx-first-level]")).removeClass(this.multipleHoverClass), t.hasClass(this.multipleClass) ? t.removeClass(this.multipleClass) : t.addClass(this.multipleClass);
      var i = this.app.block.get();
      i && (i.$block.closest("[data-arx-first-level]").addClass(this.multipleClass), this.app.block.unset());
      this.app.path.buildMultiple(), e.target.blur();
      var s = this.get("first-level"),
        r = this.get("multiple");
      s.length === r.length && (this.unset(), this.app.editor.setSelectedAll(!0), this.get("first-level").addClass("arx-block-focus"));
    },
    unset: function () {
      this.get("first-level").removeClass(this.multipleClass + " " + this.multipleHoverClass);
    },
    unsetHover: function () {
      this.get("first-level").removeClass(this.multipleHoverClass);
    },
    get: function (t) {
      var e = "[data-arx-type]";
      if ("editable" === t) e = "[contenteditable=true]";else if ("multiple" === t) e = "." + this.multipleClass;else if ("first-level" === t) e = "[data-arx-first-level]";else if ("first" === t) return this.app.editor.$editor.find(e).first();
      return this.app.editor.$editor.find(e);
    },
    _copy: function (t, e) {
      var i = "";
      e.each(function (t) {
        i += t.outerHTML + "\n";
      }), this.content.copy(t, i);
    },
    _buildFirstLevel: function () {
      this.app.editor.$editor.children("[data-arx-type]").attr("data-arx-first-level", !0);
    }
  }), ArticleEditor.add("class", "editor.content", {
    init: function () {
      this.content = this.app.create("content"), this.utils = this.app.create("utils"), this.insertion = this.app.create("insertion");
    },
    build: function (t) {
      var e = this.app.broadcast("editor.content.before.load", {
          html: t
        }),
        i = this.content.parse(e.get("html"));
      return e.set("html", i), this.app.broadcast("editor.content.load", e), e.get("html");
    },
    drop: function (t, e) {
      this._pasteDrop(t, e);
    },
    paste: function (t) {
      t.preventDefault();
      var e = t.clipboardData || t.originalEvent.clipboardData;
      if (!this._pasteImageFromClipboard(e)) {
        var i = e.getData("URL"),
          s = this.content.isClipboardPlainText(e) ? e.getData("text/plain") : e.getData("text/html");
        s = i && "" !== i ? i : s, this._paste(s);
      }
    },
    insert: function (t) {
      this.app.block.insert(t);
    },
    copy: function (t) {
      t.preventDefault(), this.content.copy(t, this.app.$element.val());
    },
    cut: function (t) {
      t.preventDefault(), this.content.copy(t, this.app.$element.val()), this.app.editor.empty();
    },
    get: function () {
      var t = this.app.$element.val();
      return t = t.replace("&amp;", "&");
    },
    set: function (t, e) {
      e ? this.app.$element.val(this.content.unparse(t)) : t = this.content.parse(t), this.app.editor.$editor.html(t), this.app.editor.rebuild(), this.app.editor.executeEmbed(), e || (this.utils.saveScroll(), this.app.editor.$editor.focus(), this.utils.restoreScroll(), this.app.editor.setFocus(), this.app.broadcast("editor.content.set"));
    },
    _pasteImageFromClipboard: function (t) {
      var e = t.getData("text/plain") || t.getData("text/html");
      if ("" === (e = e.trim())) {
        for (var i = t.items, s = null, r = 0; r < i.length; r++) 0 === i[r].type.indexOf("image") && (s = i[r].getAsFile());
        return null !== s ? (this.app.image.paste(s), !0) : void 0;
      }
    },
    _pasteDrop: function (t, e) {
      var i = this.dom(e.target).closest("[data-arx-type]");
      0 < i.length && (this.app.block.set(i), this.insertion.insertPoint(e), t = this.content.clean(t), t = this.content.paragraphize(t), this.insert(t));
    },
    _paste: function (t, e) {
      t = this.content.clean(t);
      var i = this.app.block.get();
      if (i) {
        var s = i.isEditable() && this.content.isLineText(t),
          r = "text" === i.getType() && this.opts.plaintext.markup && this.content.isPlainLine(t);
        s || r ? (r && (t = this.content.getPlainLine(t)), t = this.content.parse(t), this.insertion.insertNode(t, "end")) : (t = this.content.paragraphize(t), this.insert(t));
      } else {
        t = this.content.paragraphize(t);
        var o = this.content.parse(t);
        if (this.app.editor.blocks.isMultiple()) {
          var a = this.app.editor.blocks.get("multiple");
          a.first().before(o), a.remove();
        } else this.app.editor.isSelectedAll() && this.app.editor.$editor.html(o);
        this.app.editor.rebuild();
      }
    }
  }), ArticleEditor.add("class", "editor.events", {
    init: function () {
      this.utils = this.app.create("utils"), this.caret = this.app.create("caret"), this.pressedCmd = !1, this.mouseDownIn = !1, this.dragoverEvent = !1, this.events = {
        editor: ["click", "dblclick", "mouseover", "mouseup", "touchstart", "contextmenu", "drop", "dragstart", "dragover", "dragleave"],
        doc: ["keydown", "keyup", "mousedown", "paste", "cut", "copy", "click"],
        block: ["focus"]
      };
    },
    stop: function () {
      this.dragoverEvent = !1, this._buildTargets(), this._stopEvents(), this.app.$doc.off(".arx-blur-event-" + this.uuid);
    },
    start: function () {
      this._buildTargets(), this._startEvents();
    },
    pause: function (t) {
      "multiple" === t && (this.pressedCmd = !1);
    },
    build: function () {
      this._buildBlocks(), this.stop(), this.start();
    },
    onclick: function (t) {
      var e = this._getBlockFromTarget(t);
      if (this.pressedCmd ? this._setMultipleBlock(e, t) : this._setBlock(e, t), this.app.buffer.add(t), this._isPreventedLink(t)) return t.preventDefault();
      if (this.app.editor.setFocus(), this.app.broadcast("editor.click", {
        e: t
      }).isStopped()) return t.preventDefault();
      var i = this.app.block.get();
      if (i && i.isEditable()) {
        var s = t.target;
        this.utils.isEmptyOrImageInline(s) && this.caret.set("after", s);
      }
    },
    onfocus: function (t) {
      var e = this._getBlockFromTarget(t);
      if (this.pressedCmd) return t.preventDefault();
      this._setBlock(e, t);
    },
    ondblclick: function (t) {
      var e = this.app.block.get();
      e && "image" === e.getType() && this.app.api("image.buildConfig");
    },
    ontouchstart: function (t) {
      this.app.buffer.add(t);
    },
    onmouseup: function (t) {
      this.app.buffer.add(t);
    },
    onmouseover: function (t) {
      var e = this.app.block.get();
      if (this.pressedCmd && e) {
        var i = this._getFirstBlockFromTarget(t);
        0 !== i.length && this._buildMultipleHover(i);
      }
      this.app.broadcast("editor.hover", {
        e: t
      });
    },
    ondocmousedown: function (t) {
      this.mouseDownIn = !this._isOutsideEditor(t);
    },
    ondocclick: function (t) {
      !this.mouseDownIn && this._isOutsideEditor(t) && this.app.editor.removeFocus();
    },
    ondockeydown: function (t) {
      if (this._isFocusEditor()) if (this.app.buffer.listen(t)) this.pressedCmd = !1;else {
        var e = t.which,
          i = this.app.keycodes;
        if ((e === i.DELETE || e === i.BACKSPACE) && this.app.editor.blocks.isMultiple()) return this.app.editor.blocks.remove(), t.preventDefault();
        this.pressedCmd = !!this.opts.editor.multipleSelection && (t.ctrlKey || t.metaKey);
        var s = this._buildEventKeysObj(t),
          r = this.app.broadcast("editor.keydown", s);
        if (r.isStopped()) return t.preventDefault();
        this.app.shortcuts.handle(t) || (this.app.editor.input(r), this.app.block.input(r));
      }
    },
    ondockeyup: function (t) {
      if (this._isFocusEditor()) {
        if (this.pressedCmd = !1, this.app.editor.blocks.unsetHover(), !this._isOutsideEditor(t) || this.app.editor.isSelectedAll()) {
          this.app.editor.setFocus();
          var e = this._buildEventKeysObj(t);
          return this.app.broadcast("editor.keyup", e).isStopped() ? t.preventDefault() : void 0;
        }
        this.app.editor.removeFocus();
      }
    },
    ondrop: function (t) {
      if (this._isEditorTarget(t)) {
        t.preventDefault(), t.stopPropagation();
        var e = t.dataTransfer;
        if (this.opts.image && this.opts.image.upload && null !== e.files && 0 < e.files.length) this.app.image.drop(t, e);else {
          var i = e.getData("text/html");
          "" === i.trim() && (i = e.getData("Text")), this.app.editor.content.drop(i, t);
        }
      }
      this.app.broadcast("editor.drop", {
        e: t
      });
    },
    ondragstart: function (t) {
      this.app.broadcast("editor.dragstart", {
        e: t
      });
    },
    ondragover: function (t) {
      t.preventDefault(), this.dragoverEvent = !0, this.app.broadcast("editor.dragover", {
        e: t
      });
    },
    ondragleave: function (t) {
      t.preventDefault(), this.dragoverEvent = !1, this.app.broadcast("editor.dragleave", {
        e: t
      });
    },
    oncontextmenu: function (t) {
      if (this.pressedCmd) {
        t.preventDefault();
        var e = this._getBlockFromTarget(t);
        this._setMultipleBlock(e, t);
      }
    },
    ondoccut: function (t) {
      this._isFocusEditor() && (this.app.editor.blocks.isMultiple() ? this.app.editor.blocks.cut(t) : this.app.block.is() ? this.app.block.cut(t) : this.app.editor.isSelectedAll() && this.app.editor.content.cut(t), this.app.broadcast("editor.cut", {
        e: t
      }));
    },
    ondoccopy: function (t) {
      this._isFocusEditor() && (this.app.editor.blocks.isMultiple() ? this.app.editor.blocks.copy(t) : this.app.block.is() ? this.app.block.copy(t) : this.app.editor.isSelectedAll() && this.app.editor.content.copy(t), this.app.broadcast("editor.copy", {
        e: t
      }));
    },
    ondocpaste: function (t) {
      if (this._isFocusEditor()) {
        if (this.app.broadcast("editor.paste", {
          e: t
        }).isStopped()) return t.preventDefault();
        this.app.editor.content.paste(t);
      }
    },
    _isOutsideEditor: function (t) {
      return 0 === this.dom(t.target).closest(".arx-container-" + this.uuid + ", .arx-popup-container-" + this.uuid + ", .arx-control-" + this.uuid).length;
    },
    _isEditorTarget: function (t) {
      return 0 !== this.dom(t.target).closest(".arx-editor-" + this.uuid).length;
    },
    _isFocusEditor: function () {
      return !this.app.popup.isOpen() && !this.app.editor.isSourceMode() && (this.app.block.is() || this.app.editor.isSelectedAll() || this.app.editor.hasFocus());
    },
    _isPreventedLink: function (t) {
      return "A" === t.target.tagName || t.target.parentNode && "A" === t.target.parentNode.tagName;
    },
    _getBlockFromTarget: function (t) {
      return this.dom(t.target).closest("[data-arx-type]");
    },
    _getFirstBlockFromTarget: function (t) {
      return this.dom(t.target).closest("[data-arx-first-level]");
    },
    _buildMultipleHover: function (t) {
      this.$blocks.removeClass("arx-block-multiple-hover"), t.hasClass("arx-block-multiple-focus") || t.addClass("arx-block-multiple-hover");
    },
    _buildBlocks: function () {
      this.$blocks = this.app.editor.blocks.get(), this.$blocksEditable = this.app.editor.blocks.get("editable");
    },
    _buildTargets: function () {
      this.eventTargets = {
        editor: this.app.editor.$editor,
        doc: this.app.$doc,
        block: this.$blocksEditable
      };
    },
    _buildEventKeysObj: function (t) {
      var e = t.which,
        i = [this.app.keycodes.UP, this.app.keycodes.DOWN, this.app.keycodes.LEFT, this.app.keycodes.RIGHT],
        s = !t.ctrlKey && !t.metaKey && (48 <= e && e <= 57 || 65 <= e && e <= 90),
        r = this.app.keycodes;
      return {
        e: t,
        key: e,
        ctrl: t.ctrlKey || t.metaKey,
        shift: t.shiftKey,
        alt: t.altKey,
        select: (t.ctrlKey || t.metaKey) && !t.altKey && 65 === e,
        enter: e === r.ENTER,
        space: e === r.SPACE,
        esc: e === r.ESC,
        tab: !(e !== r.TAB || t.shiftKey || t.altKey || t.ctrlKey || t.metaKey),
        delete: e === r.DELETE,
        backspace: e === r.BACKSPACE,
        alpha: s,
        arrow: -1 !== i.indexOf(e),
        left: e === r.LEFT,
        right: e === r.RIGHT,
        up: e === r.UP,
        down: e === r.DOWN,
        "left-right": e === r.LEFT || e === r.RIGHT,
        "up-left": e === r.UP || e === r.LEFT,
        "down-right": e === r.DOWN || e === r.RIGHT
      };
    },
    _setBlock: function (t, e) {
      0 !== t.length && this.app.block.set(t, !1, e), this.app.editor.blocks.unset();
    },
    _setMultipleBlock: function (t, e) {
      0 !== t.length && this.app.editor.blocks.set(t, e);
    },
    _startEvents: function () {
      for (var t in this.events) for (var e = this.events[t], i = this.eventTargets[t], s = 0; s < e.length; s++) {
        var r = "doc" === t ? "ondoc" + e[s] : "on" + e[s];
        i.on(e[s] + ".arx-block-events-" + this.uuid, this[r].bind(this));
      }
    },
    _stopEvents: function () {
      for (var t in this.eventTargets) this.eventTargets[t].off(".arx-block-events-" + this.uuid);
    }
  }), ArticleEditor.add("class", "editor.sync", {
    init: function () {
      this.syncedHtml = "", this.content = this.app.create("content");
    },
    compose: function () {
      this.syncedHtml = this.app.$element.val();
    },
    build: function () {
      var t = this,
        e = this.app.editor.$editor.html();
      e = this.content.unparse(e), this.is(e) && (this.timeout && clearTimeout(this.timeout), this.timeout = setTimeout(function () {
        t._sync(e);
      }, 200));
    },
    invoke: function () {
      var t = this.app.editor.$editor.html();
      t = this.content.unparse(t), this.syncedHtml = t, this._sync(t);
    },
    is: function (t) {
      var e = !1;
      return this.syncedHtml !== t && (this.syncedHtml = t, e = !0), e;
    },
    _sync: function (t) {
      var e = this.app.broadcast("editor.content.before.change", {
        html: t
      });
      e.isStopped() || (this.app.$element.val(e.get("html")), this.app.autosave.send(), this.app.buffer.trigger(), this.app.broadcast("editor.content.change", e));
    }
  }), ArticleEditor.add("class", "editor.images", {
    init: function () {
      this.data = [];
    },
    observe: function () {
      this._find().each(this._add.bind(this));
    },
    get: function () {
      var t = this._find();
      for (var e in this.data) {
        var i = this.data[e],
          s = t.is('[data-image="' + i.id + '"]');
        this._set(i.id, s);
      }
      return this.data;
    },
    _find: function () {
      return this.app.editor.$editor.find("[data-image]");
    },
    _add: function (t) {
      var e = t.getAttribute("data-image");
      this.data[e] = {
        type: "image",
        status: !0,
        url: t.src,
        node: t,
        id: e
      };
    },
    _set: function (t, e) {
      this.data[t].status = e;
    }
  }), ArticleEditor.add("module", "focus", {
    init: function () {},
    start: function () {
      this.opts.editor.focus && setTimeout(this.set.bind(this), 1);
    },
    set: function () {
      var t = this.app.editor.blocks.get().first();
      0 !== t.length && this.app.block.set(t);
    }
  }), ArticleEditor.add("module", "inline", {
    init: function () {
      this.utils = this.app.create("utils"), this.caret = this.app.create("caret"), this.insertion = this.app.create("insertion"), this.selection = this.app.create("selection");
    },
    buildPopup: function () {
      var t = this.opts.inline;
      for (var e in t) t[e].command = "inline.format";
      return t;
    },
    observe: function (t) {
      return t;
    },
    removeFormat: function () {
      this.app.popup.close();
      var t = this.app.block.get();
      this.selection.save(t.$block);
      for (var e = this.selection.getNodes({
          type: "inline"
        }), i = 0; i < e.length; i++) this.dom(e[i]).unwrap();
      this.selection.restore(t.$block);
    },
    format: function (t) {
      this.app.popup.close(), this.params = this._buildParams(t);
      var e = [];
      return e = this.selection.get().collapsed ? this.formatCollapsed() : this.formatUncollapsed(), this.app.broadcast("inline.format", {
        nodes: e
      }), e;
    },
    formatCollapsed: function () {
      var t,
        e = [],
        i = this.selection.getInline(),
        s = this.dom(i),
        r = i && i.tagName.toLowerCase() === this.params.tag;
      if (i) {
        if (this.utils.isEmptyHtml(i.innerHTML)) {
          if (r) this.caret.set("after", i), s.remove();else {
            var o = this.utils.replaceToTag(i, this.params.tag);
            this.caret.set("start", o);
          }
        } else if (r) {
          var a = this.utils.extractHtmlFromCaret(i),
            n = this.dom("<" + this.params.tag + " />");
          n = this.utils.cloneAttributes(i, n), s.after(n.append(a)), this.caret.set("before", n);
        } else t = this._insertInline(e, this.params.tag);
      } else t = this._insertInline(e, this.params.tag);
      return t && (e = [t]), e;
    },
    formatUncollapsed: function () {
      var t = this.app.block.get(),
        e = this.selection.getNodes({
          type: "inline"
        });
      this._convertTags("u", t), this._convertToStrike(e, t), this.selection.save(t.$block), document.execCommand("strikethrough");
      var i = this._revertToInlines(t);
      this.selection.restore(t.$block);
      for (var s = [], r = this.selection.getText(), o = 0; o < i.length; o++) this._isInSelection(i[o], r) && s.push(i[o]);
      if (this._clearEmptyStyle(), void 0 !== this.params.attr) for (o = 0; o < s.length; o++) for (var a in this.params.attr) s[o].setAttribute(a, this.params.attr[a]);
      return this.selection.save(t.$block), t.$block.get().normalize(), this._revertTags("u", t), this.selection.restore(t.$block), s;
    },
    _clearEmptyStyle: function () {
      for (var t = this.selection.getNodes({
          type: "inline"
        }), e = 0; e < t.length; e++) {
        this._clearEmptyStyleAttr(t[e]);
        var i = t[e].childNodes;
        if (i) for (var s = 0; s < i.length; s++) this._clearEmptyStyleAttr(i[s]);
      }
    },
    _clearEmptyStyleAttr: function (t) {
      3 !== t.nodeType && "" === t.getAttribute("style") && t.removeAttribute("style");
    },
    _isInSelection: function (t, e) {
      return -1 !== this.utils.removeInvisibleChars(t.textContent).search(new RegExp(this.utils.escapeRegExp(e)));
    },
    _buildParams: function (t) {
      for (var e = !0, i = {}, s = ["tag", "classname", "attr"], r = 0; r < s.length; r++) t.hasOwnProperty(s[r]) && (i[s[r]] = t[s[r]], e = !1);
      return e ? t.params : i;
    },
    _insertInline: function (t, e) {
      return [this.insertion.insertNode(document.createElement(e), "start")];
    },
    _convertTags: function (e, t) {
      this.params.tag !== e && t.$block.find(e).each(function (t) {
        this.utils.replaceToTag(t, "span").addClass("arx-convertable-" + e);
      }.bind(this));
    },
    _revertTags: function (i, t) {
      t.$block.find("span.arx-convertable-" + i).each(function (t) {
        var e = this.utils.replaceToTag(t, i);
        e.removeClass("arx-convertable-" + i), this.utils.removeEmptyAttr(e, "class") && e.removeAttr("class");
      }.bind(this));
    },
    _convertToStrike: function (t, e) {
      this.selection.save(e.$block);
      for (var i = 0; i < t.length; i++) {
        var s = t[i],
          r = this.dom(s);
        t[i].tagName.toLowerCase() === this.params.tag && this._replaceToStrike(r);
      }
      this.selection.restore(e.$block);
    },
    _removeAllAttr: function (t) {
      t.each(function (t) {
        if (0 < t.attributes.length) for (var e = t.attributes, i = e.length - 1; 0 <= i; i--) "class" !== e[i].name && t.removeAttribute(e[i].name);
      });
    },
    _replaceToStrike: function (t) {
      t.replaceWith(function () {
        return this.dom("<strike>").append(t.contents());
      }.bind(this));
    },
    _revertToInlines: function (t) {
      var i = [];
      return t.$block.find("strike").each(function (t) {
        var e = this.utils.replaceToTag(t, this.params.tag);
        i.push(e.get());
      }.bind(this)), i;
    }
  }), ArticleEditor.add("module", "lang", {
    init: function () {
      this.langKey = this.app.setting.get("editor.lang"), this.vars = this._build();
    },
    get: function (t) {
      var e = this._get(t, this.vars);
      return void 0 === e && "en" !== this.langKey && (e = this._get(t, ArticleEditor.lang.en)), void 0 === e ? "" : e;
    },
    parse: function (t) {
      if ("string" != typeof t) return t;
      var e = t.match(/## (.*?) ##/g);
      if (e) for (var i = 0; i < e.length; i++) {
        var s = e[i].replace(/^##\s/g, "").replace(/\s##$/g, "");
        t = t.replace(e[i], this.get(s));
      }
      return t;
    },
    _get: function (t, e) {
      var i = t.split(".");
      return 1 === i.length ? e[t] : void 0 !== e[i[0]] ? e[i[0]][i[1]] : void 0;
    },
    _build: function () {
      var t = ArticleEditor.lang.en;
      return "en" !== this.langKey && (t = "undefined" !== ArticleEditor.lang[this.langKey] ? ArticleEditor.lang[this.langKey] : t), t;
    }
  }), ArticleEditor.add("module", "link", {
    popups: {
      link: {
        format: {
          title: '<span style="color: #448fff; text-decoration: underline;">## link.link ##</span>',
          command: "link.format"
        },
        edit: {
          title: '<span style="color: #448fff; text-decoration: underline;">## link.edit-link ##</span>',
          command: "link.edit"
        },
        unlink: {
          title: '<span style="color: #ff3366">## link.unlink ##</span>',
          command: "link.unlink"
        }
      },
      edit: {
        name: "link",
        width: "100%",
        form: {
          text: {
            type: "input",
            label: "## link.text ##"
          },
          url: {
            type: "input",
            label: "## link.url ##"
          },
          target: {
            type: "checkbox",
            label: "## link.link-in-new-tab ##"
          }
        },
        header: "Link",
        footer: {
          insert: {
            title: "## link.insert ##",
            command: "link.insert",
            type: "primary"
          },
          cancel: {
            title: "## link.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    init: function () {
      this.content = this.app.create("content"), this.selection = this.app.create("selection");
    },
    buildPopup: function () {
      return this.popups.link;
    },
    observe: function (t) {
      return 0 === this._getLink().length ? (t.format.hidden = !1, t.edit.hidden = !0, t.unlink.hidden = !0) : (t.format.hidden = !0, t.edit.hidden = !1, t.unlink.hidden = !1), t;
    },
    format: function (t) {
      this.app.popup.build(this.popups.edit), this.app.popup.open();
      var e = this.selection.getText();
      this.app.popup.getInput("text").val(e), this.app.popup.getInput("url").focus();
    },
    edit: function (t) {
      this.app.popup.build(this.popups.edit), this.app.popup.open(), this.app.popup.setButton("insert", {
        name: "save",
        title: "## link.save ##",
        command: "link.save"
      });
      var e = this._getLink();
      (i = this.app.popup.getInput("text")).val(e.text());
      var i,
        s = e.attr("href").replace("&amp;", "&");
      (i = this.app.popup.getInput("url")).val(s), i.focus(), (e.attr("target") || this.opts.link.target) && this.app.popup.getInput("target").attr("checked", !0);
    },
    insert: function () {
      this.app.popup.close();
      var t = this.app.block.get(),
        e = this.app.popup.getData();
      if (this.selection.restore(t.$block), e.url = this.content.escapeHtml(e.url), -1 !== e.url.search(/^javascript:/i) && (e.url = ""), "" !== e.url) {
        var i = this.app.inline.format({
            tag: "a"
          }),
          s = this.dom(i[0]);
        e.url = e.url.replace("&amp;", "&"), e.text = "" === e.text ? e.url : e.text, s.text(e.text), s.attr("href", e.url), e.hasOwnProperty("target") && s.attr("target", "_blank"), this.app.broadcast("link.add", {
          url: e.url,
          text: e.text
        });
      }
    },
    save: function () {
      this.app.popup.close();
      var t = this.app.block.get(),
        e = this.app.popup.getData();
      if (this.selection.restore(t.$block), e.url = this.content.escapeHtml(e.url), -1 !== e.url.search(/^javascript:/i) && (e.url = ""), "" !== e.url) {
        var i = this._getLink();
        e.url = e.url.replace("&amp;", "&"), e.text = "" === e.text ? e.url : e.text, i.text(e.text), i.attr("href", e.url), e.hasOwnProperty("target") ? i.attr("target", "_blank") : i.removeAttr("target"), this.app.broadcast("link.change", {
          url: e.url,
          text: e.text
        });
      }
    },
    unlink: function () {
      this.app.popup.close();
      var t = this.selection.getNodes({
        tags: ["a"]
      });
      if (0 !== t.length) for (var e = 0; e < t.length; e++) {
        var i = this.dom(t[e]);
        this.app.broadcast("link.remove", {
          url: i.attr("href"),
          text: i.text()
        }), i.unwrap();
      }
    },
    _getLink: function () {
      var t = this.selection.getNodes({
        tags: ["a"]
      });
      return 0 !== t.length ? this.dom(t[0]) : this.dom([]);
    }
  }), ArticleEditor.add("module", "setting", {
    init: function () {
      this.opts = this._build();
    },
    dump: function () {
      return this.opts;
    },
    has: function (t) {
      var e = t.split(".");
      return 1 === e.length ? void 0 !== this.opts[t] : void 0 !== this.opts[e[0]] && void 0 !== this.opts[e[1]];
    },
    set: function (t, e, i) {
      void 0 === this.opts[t] && (this.opts[t] = {}), void 0 === i ? this.opts[t] = e : this.opts[t][e] = i;
    },
    get: function (t) {
      var e = t.split(".");
      return 1 === e.length ? this.opts[t] : void 0 !== this.opts[e[0]] ? this.opts[e[0]][e[1]] : void 0;
    },
    _build: function () {
      var t = ArticleEditor.extend(!0, {}, ArticleEditor.opts, this.app.initialSettings);
      return t = ArticleEditor.extend(!0, t, ArticleEditor.settings);
    }
  }), ArticleEditor.add("module", "source", {
    init: function () {
      this.utils = this.app.create("utils"), this._build();
    },
    start: function () {
      this._buildStyles(), this._appendToContainer();
    },
    stop: function () {
      this.$source.off(".arx-source-events"), this.$source.remove();
    },
    toggle: function () {
      this.app.editor.isSourceMode() ? this.close() : this.open();
    },
    open: function () {
      this.app.broadcast("source.before.open");
      var t = this.app.$element.val();
      t = t.replace("&amp;", "&");
      var e = this.app.container.$editor.height();
      this.$source.height(e), this.$source.val(t), this.$source.on("input.arx-source-events", this._handleChanges.bind(this)), this.$source.on("keydown.arx-source-events", this._handleTab.bind(this)), this.app.container.$editor.hide(), this.app.container.$source.show(), this.utils.createCodemirror(this.$source, this.opts.source, e);
      var i = this.utils.getCodemirror();
      i && i.on("change", this._handleChanges.bind(this)), this.app.path.disableAll(), this.app.toolbar.disableAll(), this.app.toolbar.disableSticky(), this.app.toolbar.enable("html"), this.app.toolbar.set("html"), this.app.popup.close(!1), this.app.broadcast("source.open");
    },
    close: function () {
      this.app.broadcast("source.before.close");
      var t = this._getCode();
      this.app.editor.content.set(t), this.app.editor.sync.invoke(), this.$source.off(".arx-source-events"), this.app.container.$source.hide(), this.app.container.$editor.show(), this.app.path.enableAll(), this.app.toolbar.enableAll(), this.app.toolbar.enableSticky(), this.app.toolbar.unset("html"), this.app.broadcast("source.close");
    },
    _build: function () {
      this.$source = this.dom("<textarea>");
    },
    _buildStyles: function () {
      this.$source.addClass("arx-source"), this.$source.attr("data-gramm_editor", !1);
    },
    _getCode: function () {
      var t = this.$source.val();
      return t = this.utils.getCodemirrorValue(t, this.opts.source), t = this.utils.sanitize(t);
    },
    _getCodeSoft: function () {
      var t = this.$source.val();
      return t = this.utils.getCodemirrorValueSoft(t, this.opts.source), t = this.utils.sanitize(t);
    },
    _appendToContainer: function () {
      this.app.container.$source.append(this.$source);
    },
    _handleChanges: function (t) {
      var e = this._getCodeSoft();
      this.app.$element.val(e), this.app.broadcast("source.change", {
        e: t
      });
    },
    _handleTab: function (t) {
      if (9 !== t.keyCode) return !0;
      t.preventDefault();
      var e = this.$source.get(),
        i = this.$source.val(),
        s = e.selectionStart;
      this.$source.val(i.substring(0, s) + "    " + i.substring(e.selectionEnd)), e.selectionStart = e.selectionEnd = s + 4;
    }
  }), ArticleEditor.add("module", "parser", {
    init: function () {
      this.utils = this.app.create("utils"), this.exclude = ["embed", "image", "figcaption"], this.items = this.opts.blocks;
    },
    register: function (t, e) {
      this.items[t] = e;
    },
    getProps: function (t) {
      if (void 0 !== this.items[t]) return this.items[t];
    },
    getProp: function (t, e) {
      if (void 0 !== this.items[t]) return this.items[t][e];
    },
    parse: function (t) {
      var e, i;
      for (var s in this.items) if (i = this.items[s], -1 === this.exclude.indexOf(s) && "noneditable" !== s) {
        if ("text" === s) e = "." + this.opts.plaintext.classname;else if ("snippet" === s) {
          var r = [];
          for (var o in this.opts.snippets.items) r.push("." + o);
          e = 0 !== r.length && r.join(", ");
        } else e = "column" === s ? !!this.opts.grid && "." + this.opts.grid.columns.classname : "grid" === s ? !!this.opts.grid && "." + this.opts.grid.classname : i.selector;
        e && (t = this._parse(t, e, i, s));
      }
      return t;
    },
    parseExclude: function (t) {
      var e, i;
      for (var s in this.items) i = this.items[s], -1 !== this.exclude.indexOf(s) && (e = i.selector) && (t = this._parse(t, e, i, s));
      return t;
    },
    parseNoneditable: function (t) {
      var e = "." + this.opts.noneditable.classname,
        i = this.items.noneditable;
      return this._parse(t, e, i, "noneditable");
    },
    parseBlock: function (t, e) {
      return "grid" === e && this.opts.grid && this.opts.grid.overlay && t.addClass("arx-grid-overlay"), t;
    },
    parseLayer: function (t) {
      if (!t.attr("data-arx-type")) return t;
    },
    parseTextBlock: function (t) {
      if (0 === t.closest("blockquote").length) return "" === t.html().trim() && t.attr("data-placeholder", this.lang.get("placeholders.text")), t;
    },
    parseList: function (t) {
      if (0 === t.closest("li", this.app.editor.$editor).length && 0 === t.closest("blockquote").length) return t;
    },
    parseQuote: function (t) {
      var e = t.closest("figure");
      return t = 0 !== e.length ? e : t;
    },
    parseCode: function (t) {
      var e = t.find("code"),
        i = 0 === e.length ? t : e;
      "" === i.html().trim() && i.attr("data-placeholder", this.lang.get("placeholders.code"));
      var s = t.closest("figure");
      return t = 0 !== s.length ? s : t;
    },
    parseImage: function (t) {
      var e = t.closest("a"),
        i = 0 !== e.length ? e : t,
        s = i.get(),
        r = i.closest("div, figure, p"),
        o = s.previousSibling && 3 === s.previousSibling.nodeType && !this.utils.isEmpty(s.previousSibling),
        a = s.nextSibling && 3 === s.nextSibling.nodeType && !this.utils.isEmpty(s.nextSibling);
      if (0 !== r.length && !o && !a) return t.attr("data-image") || t.attr("data-image", this.utils.getRandomId()), r;
    },
    parseEmbed: function (t) {
      if (!t.attr("data-arx-type")) {
        var e = t.get();
        0 === t.closest("figure").length && "IFRAME" === e.tagName && (t = t.wrap("<figure>"));
        var i = t.clone();
        return i.find("." + this.opts.embed.responsive).unwrap(), i.find("figcaption").remove(), t.attr("data-embed-code", encodeURI(i.get().innerHTML.trim())), t;
      }
    },
    parseProps: function (t, e, i) {
      t && (t.attr("data-arx-type", e), t.removeAttr("contenteditable"), t.removeClass("arx-block-no-hover"), i.editable && (t.attr("contenteditable", !0), this.opts.editor.grammarly || t.attr("data-gramm_editor", !1)), i.hover || t.addClass("arx-block-no-hover"));
    },
    _parse: function (t, e, i, s) {
      return t.find(e).each(function (t) {
        var e = this.dom(t);
        i.command && (e = this.app.api(i.command, e, s)), this.parseProps(e, s, i);
      }.bind(this)), t;
    }
  }), ArticleEditor.add("module", "statusbar", {
    init: function () {
      this.items = {};
    },
    start: function () {
      this._build();
    },
    stop: function () {},
    add: function (t, e) {
      return this.update(t, e);
    },
    update: function (t, e) {
      var i;
      return void 0 !== this.items[t] ? i = this.items[t] : (i = this.dom("<li>"), this.$statusbar.append(i), this.items[t] = i), i.html(e);
    },
    get: function (t) {
      return this.items[t] ? this.items[t] : this.items;
    },
    remove: function (t) {
      this.items[t] && (this.items[t].remove(), delete this.items[t]);
    },
    clear: function () {
      this.items = {}, this.$statusbar.html("");
    },
    _build: function () {
      this.$statusbar = this.dom("<ul>"), this.$statusbar.addClass("arx-statusbar arx-statusbar-" + this.uuid), this.$statusbar.attr("dir", this.opts.editor.direction), this.app.container.$statusbar.append(this.$statusbar);
    }
  }), ArticleEditor.add("module", "toolbar", {
    subscribe: {
      "app.start": function () {
        this._buildEditorButtons();
      },
      "editor.content.set": function () {
        this._checkSticky();
      },
      "editor.content.change": function () {
        this._checkSticky();
      }
    },
    init: function () {
      this.customButtons = {}, this.toolbarButtons = this.opts.buttons.toolbar, this.editorButtons = this.opts.buttons.editor, this.addbarButtons = this.opts.addbar, this.utils = this.app.create("utils"), this.button = this.app.create("button");
    },
    start: function () {
      this._build(), this.enableSticky();
    },
    stop: function () {
      var t = this.utils.getScrollTarget();
      this.app.$doc.off(".arx-toolbar-" + this.uuid), this.app.$win.off(".arx-toolbar-" + this.uuid), t.off(".arx-toolbar-" + this.uuid), this.app.$body.find(".arx-tooltip").remove();
    },
    build: function () {
      if (this.app.block.is()) {
        this._clear();
        var t = this.app.block.get(),
          e = t.buttons;
        for (var i in t.isControls() && this._createButton("add", this.toolbarButtons.add), e) this._createButton(i, e[i]);
        for (var i in this.customButtons) t.isAllowedButton(this.customButtons[i], "toolbar") && this._createButton(i, this.customButtons[i]);
        this.opts.editor.undoredo && (this._createButton("undo", this.toolbarButtons.undo), this._createButton("redo", this.toolbarButtons.redo));
      } else this._buildEditorButtons();
    },
    addbar: function () {
      if (!1 === this.addbarButtons) return !1;
      var t = this.addbarButtons;
      for (var e in t) t[e].text = !0;
      return t;
    },
    disable: function (t) {
      this.enableAll(), this.get(t).addClass("disable");
    },
    disableSticky: function () {
      this.app.container.$toolbar.removeClass("arx-top-sticky"), this.app.container.$toolbar.css("top", "");
    },
    disableAll: function () {
      this._getAll().addClass("disable");
    },
    enable: function (t) {
      this.get(t).removeClass("disable");
    },
    enableSticky: function () {
      this.opts.toolbar.sticky && (this.app.container.$toolbar.addClass("arx-top-sticky"), this.app.container.$toolbar.css("top", this.opts.toolbar.stickyTopOffset + "px"));
    },
    enableAll: function () {
      this._getAll().removeClass("disable");
    },
    set: function (t) {
      this._getAll().removeClass("active"), this.get(t).addClass("active");
    },
    unset: function (t) {
      this.get(t).removeClass("active");
    },
    get: function (t) {
      return this.app.container.$toolbar.find("[data-name=" + t + "]");
    },
    add: function (t, e) {
      this.customButtons[t] = e;
    },
    addTo: function (t, e, i) {
      "addbar" === t ? this.addbarButtons[e] = i : "editor" === t && (this.editorButtons[e] = i);
    },
    remove: function (t) {
      this.get(t).remove();
    },
    removeFrom: function () {},
    change: function (t, e) {
      this.get(t);
    },
    popup: function (t) {
      var e = t.button.popup;
      this.app.popup.build(e);
      var i = this.app.popup.getBody();
      if (e.hasOwnProperty("builder")) this._createBuilderButtons(e, i);else for (var s in e) {
        var r = e[s];
        if ("object" == typeof r) {
          var o = "list" === r.type || "group" === r.type;
          if (r.hasOwnProperty("observer") && o) {
            var a = this.app.api(r.observer, r.buttons, s);
            void 0 !== a && (r.buttons = a);
          }
          if (r.hasOwnProperty("builder")) {
            var n = this._createButtonTarget(r, i);
            if (!n) continue;
            for (var l in r.buttons) this._createPopupButton(l, r.buttons[l], r.type, n);
          } else this._createPopupButton(s, r, !1, i);
        }
      }
      this.app.popup.open(t.$btn);
    },
    _getAll: function () {
      return this.app.container.$toolbar.find("[data-name]");
    },
    _clear: function () {
      this.$toolbar.find(".arx-button").off(".arx-button-" + this.uuid), this.$toolbar.html("");
    },
    _createButtonTarget: function (t, e) {
      var i = e;
      return 0 !== Object.keys(t.buttons).length && ("list" === t.type ? i = this._buildPopupList(e) : "group" === t.type && (i = this._buildPopupGroup(e, t)), i);
    },
    _createBuilderButtons: function (t, e) {
      for (var i in t.buttons) this.button.create(i, t.buttons[i], e, "popup");
    },
    _createPopupButton: function (t, e, i, s) {
      this.button.create(t, this._buildPopupButton(e, i), s, "popup");
    },
    _createButton: function (t, e) {
      this.button.create(t, e, this.$toolbar);
    },
    _buildPopupButton: function (t, e) {
      return t.text = t.hasOwnProperty("text") ? t.text : "group" !== e, t.icon = t.hasOwnProperty("icon") ? t.icon : "group" === e, t;
    },
    _buildPopupList: function (t) {
      var e = this.dom("<div>");
      return e.addClass("arx-popup-tool arx-popup-buttons-list"), t.append(e), e;
    },
    _buildPopupGroup: function (t, e) {
      var i = this.dom("<div>");
      i.addClass("arx-popup-tool"), e.hasOwnProperty("title") && this._buildPopupGroupTitle(i, e);
      var s = this.dom("<div>");
      return s.addClass("arx-popup-buttons-group"), i.append(s), t.append(i), s;
    },
    _buildPopupGroupTitle: function (t, e) {
      var i = this.lang.parse(e.title),
        s = this.dom("<div>");
      s.addClass("arx-popup-buttons-group-title"), s.html(i), t.append(s);
    },
    _buildEditorButtons: function () {
      for (var t in this._clear(), this.editorButtons) "html" === t && !this.opts.source || "templates" === t && !this.opts.templates.json || this._createButton(t, this.editorButtons[t]);
    },
    _build: function () {
      this.$toolbar = this.dom("<div>"), this.$toolbar.addClass("arx-toolbar"), this.app.container.$toolbar.append(this.$toolbar);
    },
    _checkSticky: function () {
      this.app.editor.$editor.height() < this.opts.toolbar.stickyMinHeight || this.app.editor.isEmpty() ? this.disableSticky() : this.enableSticky();
    }
  }), ArticleEditor.add("module", "control", {
    init: function () {
      this.opts.editor.control ? this.buttons = this.opts.buttons.control : this.buttons = {}, this.customButtons = {}, this.utils = this.app.create("utils"), this.button = this.app.create("button");
    },
    start: function () {
      this._build();
    },
    stop: function () {
      this._stopResize(), this.$control.remove();
    },
    open: function () {
      var t = this.app.block.get();
      if (!t.isControls() && 0 === Object.keys(this.customButtons).length) return this._stopResize(), void this.close();
      this._clear(), 0 < Object.keys(this.buttons).length && (this._buildButtons(t), this._updatePosition(!1, t), this._startResize(), this.$control.show());
    },
    close: function () {
      this.$control.hide();
    },
    add: function (t, e) {
      this.buttons[t] = e, this.customButtons[t] = !0;
    },
    get: function (t) {
      var e = this.$control.find("[data-name=" + t + "]");
      return 0 === e.length && (e = this.dom([])), e;
    },
    remove: function (t) {
      delete this.buttons[t];
    },
    rebuild: function () {
      this._updatePosition(!1);
    },
    hasButtons: function (t) {
      var e = 0;
      for (var i in this.buttons) t.isAllowedButton(this.buttons[i], "control") && e++;
      return 0 !== e;
    },
    _clear: function () {
      this.$control.html("");
    },
    _startResize: function () {
      this.app.$win.on("resize.arx-control", this._updatePosition.bind(this));
    },
    _stopResize: function () {
      this.app.$win.off(".arx-control");
    },
    _updatePosition: function (t, e) {
      if (e = e || this.app.block.get()) {
        var i = e.$block.offset(),
          s = i.top,
          r = i.left;
        if (this.utils.isScrollTarget()) {
          var o = this.utils.getScrollTarget(),
            a = o.offset(),
            n = parseInt(o.css("border-top-width")),
            l = parseInt(o.css("border-left-width"));
          s = i.top - a.top - n + o.scrollTop(), r = i.left - a.left - l;
        }
        var p = -33;
        "rtl" === this.opts.editor.direction && (r = i.left + e.$block.width(), p = 4, this.utils.isScrollTarget() && (r = r - a.left - l)), this.$control.css({
          top: s + -3 + "px",
          left: r + p + "px"
        });
      }
    },
    _build: function () {
      this.$control = this.dom("<div>"), this.$control.addClass("arx-control arx-control-" + this.uuid), this.$control.hide(), this.opts.bsmodal && this.$control.css("z-index", 1051), (this.utils.isScrollTarget() ? this.utils.getScrollTarget() : this.app.$body).append(this.$control);
    },
    _buildButtons: function (t) {
      for (var e in this.buttons) (t.isControls() || this.customButtons.hasOwnProperty(e)) && t.isAllowedButton(this.buttons[e], "control") && this.button.create(e, this.buttons[e], this.$control, "control");
    }
  }), ArticleEditor.add("module", "path", {
    start: function () {
      this._build(), this._buildRoot(), this._buildActive();
    },
    build: function () {
      this._clear(), this._buildRoot();
      var t = this.app.block.get();
      if (t) {
        var e = t.$block.parents("[data-arx-type]");
        e.nodes.reverse(), e.each(this._buildParentItem.bind(this)), this._buildItem(t);
      }
      this._buildActive();
    },
    buildMultiple: function () {
      this._clear(), this._buildRoot();
      var t = this.dom("<a>");
      t.addClass("arx-path-item"), t.attr("href", "#"), t.addClass("active"), t.html(this.lang.get("editor.multiple")), this.$path.append(t);
    },
    disableAll: function () {
      this._getAll().addClass("disable");
    },
    enableAll: function () {
      this._getAll().removeClass("disable");
    },
    _clear: function () {
      this.$path.find(".arx-path-item").off(".arx-path-" + this.uuid), this.$path.html("");
    },
    _getAll: function () {
      return this.$path.find(".arx-path-item");
    },
    _selectItem: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target);
      if (!e.hasClass("disable")) {
        var i = e.dataget("instance");
        this.app.block.set(i.$block);
      }
    },
    _selectRoot: function (t) {
      t.preventDefault(), t.stopPropagation(), this.dom(t.target).hasClass("disable") || (this._clear(), this._buildRoot(), this._buildActive(), this.app.block.unset());
    },
    _build: function () {
      this.$path = this.dom("<div>"), this.$path.addClass("arx-path"), this.app.container.$toolbar.append(this.$path);
    },
    _buildParentItem: function (t) {
      var e = this.dom(t),
        i = e.attr("data-arx-type"),
        s = this.app.create("block." + i, e);
      this._buildItem(s);
    },
    _buildActive: function () {
      var t = this.$path.find("a");
      t.removeClass("active"), t.last().addClass("active");
    },
    _buildItem: function (t, e) {
      var i = this.dom("<a>");
      i.addClass("arx-path-item"), i.attr("href", "#"), i.html(e || t.getName()), e ? i.on("click.arx-path-" + this.uuid, this._selectRoot.bind(this)) : (i.dataset("instance", t), i.on("click.arx-path-" + this.uuid, this._selectItem.bind(this))), this.$path.append(i);
    },
    _buildRoot: function () {
      this._buildItem(!1, this.lang.parse(this.opts.path.title));
    }
  }), ArticleEditor.add("module", "popup", {
    init: function () {
      this.utils = this.app.create("utils"), this.selection = this.app.create("selection"), this.$scrollTarget = this.utils.getScrollTarget();
    },
    start: function () {
      this.$container = this.dom("<div>"), this.$container.addClass("arx-popup-hide arx-popup-container arx-popup-container-" + this.uuid), (this.utils.isScrollTarget() ? this.utils.getScrollTarget() : this.app.$body).append(this.$container);
    },
    stop: function () {
      this.close(), this.$container.remove();
    },
    isOpen: function () {
      return this.$container && !this.$container.hasClass("arx-popup-hide");
    },
    isForm: function () {
      return this.$popup && this.$popup.hasClass("arx-popup-aform");
    },
    getContainer: function () {
      return this.$container;
    },
    getPopup: function () {
      return this.$popup;
    },
    getBody: function () {
      return this.$popupBody;
    },
    getForm: function () {
      return this.$popupBody;
    },
    getHeader: function () {
      return this.$popupHeader;
    },
    getFooter: function () {
      return this.$popupFooter;
    },
    getButton: function (t) {
      if (this.isForm() && this.$popupFooter) return this.$popupFooter.find("[data-name=" + t + "]");
    },
    getInput: function (t) {
      if (this.isForm()) return this.$popupBody.find("[name=" + t + "]");
    },
    getData: function (t) {
      if (this.isForm() && void 0 === t) return this.$popupBody.serialize(!0);
      var e = this.getInput(t);
      return e ? e.val().trim() : void 0;
    },
    setData: function (t, e) {
      var i = this.getInput(t);
      i && i.val(e);
    },
    setButton: function (t, e) {
      var i = this.getButton(t);
      i && (e.hasOwnProperty("command") && i.attr("data-command", e.command), e.hasOwnProperty("name") && i.attr("data-name", e.name), e.hasOwnProperty("title") && i.html(this.lang.parse(e.title)));
    },
    setFocus: function (t) {
      var e = this.getInput(t);
      e && e.focus();
    },
    setDark: function () {
      this.$popup.addClass("arx-popup-dark");
    },
    setLight: function () {
      this.$popup.removeClass("arx-popup-dark");
    },
    build: function (t) {
      this.params = ArticleEditor.extend(!0, {
        name: !1,
        dark: !1,
        form: !1,
        header: !1,
        footer: !1,
        list: !1,
        width: !1
      }, t), this.$container.html(""), this.opts.bsmodal && this.$container.css("z-index", 1052), this._buildPopup(), this._buildBody(), this._buildForm(), this._buildDark(), this._buildList(), this._buildClassname(), this._buildWidth(), this._buildHeader(), this._buildFooter();
    },
    open: function (t) {
      if (t) {
        var e = t.attr("data-popup-name");
        if (this.isOpen() && e && e === this.params.name) return void this.close(!1);
        this.app.$body.find(".arx-popup-in-" + this.uuid).removeClass("arx-popup-in arx-popup-in-" + this.uuid).removeAttr("data-popup-name"), t.addClass("arx-popup-in arx-popup-in-" + this.uuid), this.params.name && t.attr("data-popup-name", this.params.name);
      }
      this.app.$body.find(".arx-popup-container").not(".arx-popup-container-" + this.uuid).addClass("arx-popup-hide"), this._stopEvents(), this.updatePosition(), this.$container.removeClass("arx-popup-hide"), this.$container.addClass("arx-popup-fade-in"), this.$container.one("animationend webkitAnimationEnd", function (t) {
        this.$container.removeClass("arx-popup-fade-in");
      }.bind(this));
      var i = this.app.block.get();
      i && i.isEditable() && this.selection.save(i.$block), this.app.$doc.on("keydown.arx-popup-container-" + this.uuid, this._handleKeyboard.bind(this)), this.app.$doc.on("click.arx-popup-container-" + this.uuid, this._handleClick.bind(this)), this.app.$win.on("resize.arx-popup-container-" + this.uuid, this._handleResize.bind(this)), this.$scrollTarget.on("scroll.arx-popup-container-" + this.uuid, this._handleResize.bind(this)), this.$scrollTarget.on("scroll.arx-popup-container-" + this.uuid, this.updatePosition.bind(this)), this.$popup.find("input[type=text],input[type=url],input[type=email]").on("keydown.arx-popup-" + this.uuid, this._handleEnter.bind(this)), this._handleResize();
    },
    close: function (t) {
      if (!this.$container.hasClass("arx-popup-hide")) {
        this.app.$body.find(".arx-popup-in-" + this.uuid).removeClass("arx-popup-in arx-popup-in-" + this.uuid).removeAttr("data-popup-name"), this._stopEvents(), !1 !== t ? (this.$container.addClass("arx-popup-fade-out"), this.$container.one("animationend webkitAnimationEnd", function (t) {
          this.$container.removeClass("arx-popup-fade-out"), this.$container.addClass("arx-popup-hide");
        }.bind(this))) : this.$container.addClass("arx-popup-hide"), this.$popup.find(".arx-popup-footer-btn").off(".arx-popup-footer-btn-" + this.uuid), this.$popup.find(".arx-button").off(".arx-button-" + this.uuid), this.$popup.find(".arx-popup-event").off(".arx-popup-event-" + this.uuid), this.$popup.find("input[type=text],input[type=url],input[type=email]").off(".arx-popup-" + this.uuid);
        var e = this.app.block.get();
        e && e.isEditable() && this.selection.restore(e.$block);
      }
    },
    updatePosition: function () {
      var t = this.app.container.$toolbar,
        e = t.offset();
      if (this.utils.isScrollTarget()) {
        var i = this.utils.getScrollTarget(),
          s = i.offset(),
          r = this.app.container.$main.position(),
          o = parseInt(i.css("border-top-width"));
        e.left = r.left, e.top = e.top - s.top + i.scrollTop() - o;
      }
      var a = t.height();
      this.$container.css({
        top: e.top + a + 5 + "px",
        left: e.left + 5 + "px"
      });
    },
    _stopEvents: function () {
      this.app.$doc.off(".arx-popup-container-" + this.uuid), this.app.$win.off(".arx-popup-container-" + this.uuid), this.$scrollTarget.off(".arx-popup-container-" + this.uuid);
    },
    _handleResize: function () {
      this.$popup.css("min-width", "");
      var t = !1;
      if (this.params.width) {
        var e = this.app.container.$main.width() - 10;
        if (this.$popup.hasClass("arx-popup-adaptive")) t = !0;else {
          var i = this._buildRelativeWidth(this.params.width);
          e < i ? t = !0 : (t = !0, e = i);
        }
      }
      t && this.$popup.css({
        "min-width": e + "px",
        "max-width": e + "px"
      });
      var s = this.utils.getScrollTarget(),
        r = (this.utils.isScrollTarget() ? this.$popup.position() : this.$popup.offset()).top - s.scrollTop(),
        o = this.app.$win.height();
      if (this.utils.isScrollTarget()) {
        var a = parseInt(s.css("border-top-width")),
          n = parseInt(s.css("border-bottom-width"));
        o = s.height() - a - n;
      }
      var l = o - r - 10;
      this.$popup.css("max-height", l + "px");
    },
    _handleClick: function (t) {
      0 !== this.dom(t.target).closest(".arx-popup-container-" + this.uuid).length || this.close();
    },
    _handleEnter: function (t) {
      13 === t.which && t.preventDefault();
    },
    _handleKeyboard: function (t) {
      27 === t.which && this.close();
    },
    _buildPopup: function () {
      this.$popup = this.dom("<div>").addClass("arx-popup"), this.$popup.attr("dir", this.opts.editor.direction), this.$container.append(this.$popup);
    },
    _buildBody: function () {
      this.params.form ? (this.$popupBody = this.dom("<form>"), this.$popupBody.addClass("arx-form arx-popup-body-aform"), this.$popup.addClass("arx-popup-aform")) : (this.$popupBody = this.dom("<div>"), this.$popup.removeClass("arx-popup-aform")), this.$popupBody.addClass("arx-popup-body"), this.$popup.append(this.$popupBody);
    },
    _buildHeader: function () {
      this.params.header && (this.$popupHeader = this.dom("<header>"), this.$popupHeader.addClass("arx-popup-header"), this.$popupHeader.html(this.lang.parse(this.params.header)), this.$popup.prepend(this.$popupHeader));
    },
    _buildFooter: function () {
      if (this.params.footer) {
        for (var t in this.$popupFooter = this.dom("<footer>"), this.$popupFooter.addClass("arx-popup-footer"), this.params.footer) {
          var e = this.params.footer[t],
            i = this.dom("<button>");
          i.addClass("arx-popup-footer-btn arx-form-button"), i.attr("data-command", e.command), i.attr("data-name", t), i.html(this.lang.parse(e.title)), i.on("click.arx-popup-footer-btn-" + this.uuid, this._catch.bind(this)), e.hasOwnProperty("type") && i.addClass("arx-form-button-" + e.type), this.$popupFooter.append(i);
        }
        this.$popup.append(this.$popupFooter);
      }
    },
    _buildWidth: function () {
      this.params.width && "100%" === this.params.width && this.$popup.addClass("arx-popup-adaptive");
    },
    _buildList: function () {
      this.params.list && (this.$popup.addClass("arx-popup-alist"), this.$popupBody.addClass("arx-popup-body-alist"));
    },
    _buildClassname: function () {
      this.params.name && (this.$popup.addClass("arx-popup-" + this.params.name), this.$popupBody.addClass("arx-popup-body-" + this.params.name));
    },
    _buildDark: function () {
      this.params.dark && this.setDark();
    },
    _buildForm: function () {
      if (this.params.form) for (var t in this.params.form) {
        var e = this.params.form[t],
          i = this._createFormItem();
        this._buildInput(i, e, t), this.$popupBody.append(i);
      }
    },
    _buildInput: function (t, e, i) {
      if ("checkbox" === e.type) {
        (o = this.dom("<label>")).addClass("arx-form-checkbox");
        var s = this.dom("<input>");
        if (s.attr("type", "checkbox"), s.attr("name", i), o.append(s), e.hasOwnProperty("label")) {
          var r = this.dom("<span>");
          r.html(this.lang.parse(e.label)), o.append(r);
        }
        t.append(o);
      } else {
        if (e.hasOwnProperty("label")) {
          var o = this._buildInputLabel(e);
          t.append(o);
        }
        var a = this.dom("<" + e.type + ">");
        a.attr("name", i), a.addClass("arx-form-input"), "input" === e.type && a.attr("type", "text"), "textarea" === e.type && a.attr("data-gramm_editor", !1), e.hasOwnProperty("attr") && a.attr(e.attr), t.append(a);
      }
    },
    _buildInputLabel: function (t) {
      return this.dom("<label>").html(this.lang.parse(t.label));
    },
    _buildRelativeWidth: function (t) {
      return parseInt(this.params.width) + parseInt(this.$popup.css("padding-left")) + parseInt(this.$popup.css("padding-right"));
    },
    _createFormItem: function () {
      return this.dom("<div>").addClass("arx-form-item");
    },
    _catch: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target),
        i = e.attr("data-command"),
        s = e.attr("data-name");
      this.app.api(i, t, s);
    }
  }), ArticleEditor.add("module", "template", {
    init: function () {
      this.key = !1, this.json = {}, this.button = this.app.create("button"), this.content = this.app.create("content");
    },
    observe: function (t, e) {
      return !!this._is() && t;
    },
    buildPopup: function (t) {
      this._isJson() ? this._buildPopupPreview(t) : this._buildPopupList(t);
    },
    insert: function (t) {
      var e = this.dom(t.target).closest(".arx-template-trigger").attr("data-template-key");
      if (this.json.hasOwnProperty(e)) {
        this.app.popup.close();
        var i = this.json[e].html;
        this.app.editor.content.set(i), this.app.editor.sync.invoke();
      }
    },
    _buildPopupList: function (t) {
      this.app.popup.build({
        name: "template-list",
        list: !0
      }), this.app.popup.open(t.$btn);
      var e = this.app.popup.getBody(),
        i = this.opts.templates.items;
      for (var s in i) {
        var r = {
          title: i[s],
          text: !0,
          icon: !1,
          command: "template._insertOld"
        };
        this.button.create(s, r, e, "popup");
      }
    },
    _buildPopupPreview: function (e) {
      this.opts.templates.hasOwnProperty("items") ? this._buildPopupList(e) : "string" == typeof this.opts.templates.json ? this.ajax.get({
        url: this.opts.templates.json,
        data: {
          d: new Date().getTime()
        },
        success: function (t) {
          this._buildPreview(e, t);
        }.bind(this)
      }) : this._buildPreview(e, this.opts.templates.json);
    },
    _buildPreview: function (t, e) {
      this.app.popup.build({
        name: "template",
        header: "## templates.templates ##",
        width: "100%"
      }), this.app.popup.open(t.$btn);
      var i = this.app.popup.getBody();
      for (var s in "string" == typeof (this.json = e) && (this.json = JSON.parse(e)), this.json) {
        var r = this.dom("<div>");
        r.addClass("arx-template-preview-container arx-template-trigger"), r.attr("data-template-key", s), r.on("click.arx-templates", this.insert.bind(this));
        var o = this.dom("<div>");
        if (o.html(this.json[s].html), o.addClass("arx-reset arx-content arx-template-preview"), r.append(o), this.json[s].hasOwnProperty("name")) {
          var a = this.dom("<div>");
          a.addClass("arx-template-preview-name"), a.text(this.json[s].name), r.append(a);
        }
        i.append(r);
      }
    },
    _is: function () {
      return this.opts.templates.url || this.opts.templates.json;
    },
    _isJson: function () {
      return this.opts.templates.json && !0 !== this.opts.templates.json;
    },
    _getUrl: function () {
      var t = this.opts.templates.url;
      return this.opts.templates.json || (t = t + this.key + ".html"), t;
    },
    _request: function () {
      this.ajax.get({
        url: this._getUrl(),
        data: {
          d: new Date().getTime()
        },
        success: this._insert.bind(this)
      });
    },
    _insertOld: function (t) {
      this.app.popup.close(), this.key = t.name, "object" == typeof this.opts.templates.json ? this._insert(this.opts.templates.json) : this._request();
    },
    _insert: function (t) {
      var e = !1;
      if (this.opts.templates.json) {
        for (var i in t) if (i === this.key) {
          e = t[i].html;
          break;
        }
      } else e = t;
      !1 !== e && (this.app.editor.content.set(e), this.app.editor.sync.invoke()), this.key = !1;
    }
  }), ArticleEditor.add("module", "shortcuts", {
    init: function () {
      this.button = this.app.create("button"), this.content = this.app.create("content"), this.shortcuts = this.opts.shortcuts, this.hotkeys = {
        8: "backspace",
        9: "tab",
        10: "return",
        13: "return",
        16: "shift",
        17: "ctrl",
        18: "alt",
        19: "pause",
        20: "capslock",
        27: "esc",
        32: "space",
        33: "pageup",
        34: "pagedown",
        35: "end",
        36: "home",
        37: "left",
        38: "up",
        39: "right",
        40: "down",
        45: "insert",
        46: "del",
        59: ";",
        61: "=",
        96: "0",
        97: "1",
        98: "2",
        99: "3",
        100: "4",
        101: "5",
        102: "6",
        103: "7",
        104: "8",
        105: "9",
        106: "*",
        107: "+",
        109: "-",
        110: ".",
        111: "/",
        112: "f1",
        113: "f2",
        114: "f3",
        115: "f4",
        116: "f5",
        117: "f6",
        118: "f7",
        119: "f8",
        120: "f9",
        121: "f10",
        122: "f11",
        123: "f12",
        144: "numlock",
        145: "scroll",
        173: "-",
        186: ";",
        187: "=",
        188: ",",
        189: "-",
        190: ".",
        191: "/",
        192: "`",
        219: "[",
        220: "\\",
        221: "]",
        222: "'"
      }, !(this.hotkeysShiftNums = {
        "`": "~",
        1: "!",
        2: "@",
        3: "#",
        4: "$",
        5: "%",
        6: "^",
        7: "&",
        8: "*",
        9: "(",
        0: ")",
        "-": "_",
        "=": "+",
        ";": ": ",
        "'": '"',
        ",": "<",
        ".": ">",
        "/": "?",
        "\\": "|"
      }) === this.opts.editor.multipleSelection && this.remove("meta+click");
    },
    add: function (t, e) {
      this.shortcuts[t] = e;
    },
    remove: function (t) {
      this.opts.shortcutsBase = this._remove(t, this.opts.shortcutsBase), this.opts.shortcuts = this._remove(t, this.opts.shortcuts);
    },
    handle: function (t) {
      if ((this.triggered = !1) === this.shortcuts) return !t.ctrlKey && !t.metaKey || 66 !== t.which && 73 !== t.which || t.preventDefault(), !0;
      if (t.ctrlKey || t.metaKey || t.shoftKey || t.altKey) for (var e in this.shortcuts) this._build(t, e, this.shortcuts[e]);
      return this.triggered;
    },
    observePopup: function (t, e) {
      return !!this.opts.editor.shortcutsPopup && t;
    },
    buildPopup: function (t) {
      this.app.popup.build({
        name: "shortcuts",
        width: "360px"
      });
      var e = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "<b>&#8984;</b>" : "ctrl";
      this._buildPopupItems(this.opts.shortcutsBase, e, "base"), this._buildPopupItems(this.opts.shortcuts, e), this.app.popup.open(t.$btn);
    },
    _buildPopupItems: function (t, e, i) {
      for (var s in t) {
        var r = this.dom("<div>"),
          o = "base" === i ? t[s] : t[s].title,
          a = this.dom('<span class="arx-shortcut-title">');
        a.html(this.lang.parse(o));
        for (var n = this.dom('<span class="arx-shortcut-kbd">'), l = ("base" === i ? s.replace("meta", e) : t[s].name.replace("meta", e)).split("+"), p = 0; p < l.length; p++) l[p] = "<span>" + l[p] + "</span>";
        n.html(l.join("+")), r.append(a), r.append(n), this.app.popup.getBody().append(r);
      }
    },
    _build: function (t, e, i) {
      for (var s = e.split(","), r = s.length, o = 0; o < r; o++) "string" != typeof s[o] || i.hasOwnProperty("trigger") || this._handler(t, s[o].trim(), i);
    },
    _handler: function (t, e, i) {
      e = e.toLowerCase().split(" ");
      for (var s = this.hotkeys[t.keyCode], r = 91 !== t.which && String.fromCharCode(t.which).toLowerCase(), o = "", a = {}, n = ["meta", "ctrl", "alt", "shift"], l = 0; l < n.length; l++) {
        var p = n[l];
        t[p + "Key"] && s !== p && (o += p + "+");
      }
      s && (a[o + s] = !0), r && (a[o + r] = !0, a[o + this.hotkeysShiftNums[r]] = !0, "shift+" === o && (a[this.hotkeysShiftNums[r]] = !0));
      var c = e.length;
      for (l = 0; l < c; l++) if (a[e[l]]) return t.preventDefault(), this.triggered = !0, void this.app.api(i.command, {
        e: t,
        params: i.params
      });
    },
    _remove: function (i, s) {
      return Object.keys(s).reduce(function (t, e) {
        return e !== i && (t[e] = s[e]), t;
      }, {});
    }
  }), ArticleEditor.add("module", "list", {
    init: function () {
      this.selection = this.app.create("selection");
    },
    indent: function () {
      var t = this.selection.get(),
        e = this.selection.getBlock(),
        i = this.dom(e),
        s = i.prevElement(),
        r = s.get(),
        o = t.collapsed && e && r && "LI" === r.tagName;
      if (this.selection.save(e), o) {
        var a = (s = this.dom(r)).children("ul, ol"),
          n = i.closest("ul, ol");
        if (0 !== a.length) a.append(i);else {
          var l = n.get().tagName.toLowerCase(),
            p = this.dom("<" + l + ">");
          p.append(i), s.append(p);
        }
      }
      this.selection.restore(e);
    },
    outdent: function () {
      var t = this.selection.get(),
        e = this.selection.getBlock(),
        i = this.dom(e);
      if (t.collapsed && e) {
        var s,
          r,
          o = i.parent(),
          a = o.closest("li", ".arx-editor"),
          n = i.prevElement(),
          l = i.nextElement(),
          p = n.get(),
          c = l.get(),
          h = !1 === p,
          d = !1 !== p && !1 !== c;
        if (this.selection.save(e), 0 !== a.length) if (d) {
          s = this._getAllNext(i.get()), r = this.dom("<" + o.get().tagName.toLowerCase() + ">");
          for (var u = 0; u < s.length; u++) r.append(s[u]);
          a.after(i), i.append(r);
        } else a.after(i), 0 === o.children().length ? o.remove() : h && i.append(o);
        this.selection.restore(e);
      }
    },
    _getAllNext: function (t) {
      for (var e = []; t;) {
        if (!(t = this.dom(t).nextElement().get())) return e;
        e.push(t);
      }
      return e;
    }
  }), ArticleEditor.add("module", "table", {
    popups: {
      config: {
        name: "table",
        width: "100%",
        form: {
          width: {
            type: "input",
            label: "## table.width ##"
          },
          nowrap: {
            type: "checkbox",
            label: "## table.nowrap ##"
          }
        },
        header: "Table Cell",
        footer: {
          insert: {
            title: "## table.save ##",
            command: "table.save",
            type: "primary"
          },
          cancel: {
            title: "## table.cancel ##",
            command: "popup.close"
          }
        }
      },
      head: {
        "add-head": {
          title: "",
          icon: !0,
          command: "table.addHead"
        },
        "remove-head": {
          title: "",
          icon: !0,
          command: "table.removeHead"
        }
      },
      row: {
        "row-below": {
          title: "",
          icon: !0,
          command: "table.addRow"
        },
        "row-above": {
          title: "",
          icon: !0,
          command: "table.addRow"
        },
        "remove-row": {
          title: "",
          icon: !0,
          command: "table.removeRow"
        }
      },
      cell: {
        "column-after": {
          title: "",
          icon: !0,
          command: "table.addColumn"
        },
        "column-before": {
          title: "",
          icon: !0,
          command: "table.addColumn"
        },
        "remove-column": {
          title: "",
          icon: !0,
          command: "table.removeColumn"
        }
      }
    },
    init: function () {
      this.content = this.app.create("content");
    },
    buildPopup: function (t) {
      return this.popups[t];
    },
    observeAdd: function (t, e) {
      return !!this.opts.table && t;
    },
    buildConfig: function (t) {
      this.app.popup.build(this.popups.config), this.app.popup.open(t.$btn);
      var e = this.app.block.get(),
        i = e.getWidth(),
        s = this.app.popup.getInput("width");
      s.css("max-width", "120px"), s.val(i), s.focus(), e.getNowrap() && this.app.popup.getInput("nowrap").attr("checked", !0);
    },
    save: function () {
      this.app.popup.close();
      var t = this.app.popup.getData(),
        e = this.app.block.get();
      "" !== t.width && e.setWidth(t.width), e.setNowrap(t.hasOwnProperty("nowrap"));
    },
    addBlock: function () {
      var t = this.content.parse(this.opts.table.template);
      instance = this.app.create("block.table", t), this.app.block.add(instance), this.app.popup.close();
    },
    addHead: function () {
      this.removeHead();
      var t = this.app.block.get(),
        e = t.$block.find("tr").first().children("td, th").length,
        i = this.dom("<thead>"),
        s = this._buildRow(e, "<th>");
      i.append(s), t.$block.prepend(i), this.app.block.set(s.children("td, th").first()), this.app.editor.rebuild();
    },
    addRow: function (t) {
      this.app.popup.close();
      var e = "row-below" === t.name ? "after" : "before",
        i = this.app.block.get(),
        s = i.$block.closest("tr"),
        r = i.$block.closest("thead"),
        o = s.children("td, th").length,
        a = this._buildRow(o, "<td>");
      0 !== r.length ? r.after(a) : s[e](a), this.app.block.set(a.find("td, th").first()), this.app.editor.rebuild();
    },
    addColumn: function (t) {
      this.app.popup.close();
      var o = t.name,
        i = this.app.block.get(),
        e = i.$block.closest("table"),
        s = i.$block.closest("tr"),
        a = 0;
      s.find("td, th").each(function (t, e) {
        t === i.$block.get() && (a = e);
      });
      var n,
        l = 0;
      e.find("tr").each(function (t, e) {
        t === s.get() && (l = e);
      }), e.find("tr").each(function (t, e) {
        var i = this.dom(t).find("td, th").get(a),
          s = this.dom(i),
          r = s.clone();
        r.html(""), l === e && (n = r), "column-after" === o ? s.after(r) : s.before(r);
      }.bind(this)), n && this.app.block.set(n), this.app.editor.rebuild();
    },
    removeHead: function () {
      this.app.popup.close();
      var t = this.app.block.get().$block.find("thead");
      0 !== t.length && t.remove();
    },
    removeRow: function () {
      this.app.popup.close();
      var t = this.app.block.get().$block.closest("tr");
      this.app.create("block.row", t).remove();
    },
    removeColumn: function () {
      this.app.popup.close();
      var i = this.app.block.get(),
        t = i.$block.closest("table"),
        e = i.$block.closest("tr"),
        s = 0;
      e.find("td, th").each(function (t, e) {
        t === i.$block.get() && (s = e);
      }), t.find("tr").each(function (t) {
        var e = this.dom(t).find("td, th").get(s);
        this.dom(e).remove();
      }.bind(this));
    },
    _buildRow: function (t, e) {
      var i = this.dom("<tr>");
      i.attr("data-arx-type", "row");
      for (var s = 0; s < t; s++) {
        var r = this.dom(e);
        r.attr("data-arx-type", "cell"), r.attr("contenteditable", !0), i.append(r);
      }
      return i;
    }
  }), ArticleEditor.add("module", "image", {
    popups: {
      config: {
        name: "image-config",
        width: "100%",
        form: {
          alt: {
            type: "input",
            label: "## image.alt-text ##"
          },
          url: {
            type: "input",
            label: "## image.link ##"
          },
          target: {
            type: "checkbox",
            label: "## image.link-in-new-tab ##"
          }
        },
        header: "Image",
        footer: {
          insert: {
            title: "## image.save ##",
            command: "image.save",
            type: "primary"
          },
          delete: {
            title: "## image.delete ##",
            command: "image.remove",
            type: "danger"
          },
          cancel: {
            title: "## image.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    init: function () {
      this.utils = this.app.create("utils"), this.upload = this.app.create("upload"), this.selection = this.app.create("selection"), this.insertion = this.app.create("insertion");
    },
    drop: function (t, e) {
      for (var i = [], s = 0; s < e.files.length; s++) {
        var r = e.files[s] || e.items[s].getAsFile();
        r && i.push(r);
      }
      var o = {
        url: this.opts.image.upload,
        name: this.opts.image.name,
        data: this.opts.image.data,
        multiple: this.opts.image.multiple,
        success: "image.insertByDrop",
        error: "image.error"
      };
      if (0 < i.length) {
        var a = this.dom(t.target).closest("[data-arx-type]");
        0 !== a.length && this.app.block.set(a), this.upload.send(t, i, o);
      }
    },
    paste: function (t, e) {
      var i = {
        url: this.opts.image.upload,
        name: this.opts.image.name,
        data: this.opts.image.data,
        multiple: !1,
        success: "image.insertFromBlob",
        error: "image.error"
      };
      this.upload.send(e, [t], i);
    },
    observeAdd: function (t, e) {
      var i = this.opts.image;
      return !!(i.select || i.url || i.upload) && t;
    },
    buildConfig: function (t) {
      var e = !!t && t.$btn,
        i = this.app.block.get(),
        s = !0;
      if (this.app.popup.build(this.popups.config), this.app.popup.open(e), this.opts.image.upload) {
        s = !1;
        var r = this.dom("<div>");
        r.addClass("form-item form-item-image-upload");
        var o = i.getImage().clone(),
          a = this.dom("<div>");
        a.addClass("form-item-image"), a.append(o), r.append(a), this.$uploaditem = this.dom("<div>"), this.$uploaditem.addClass("arx-popup-upload"), r.append(this.$uploaditem), this.app.popup.getBody().prepend(r), this._buildUpload(this.$uploaditem, "image.change"), this.upload.setPlaceholder(this.lang.get("image.upload-new-placeholder"));
      }
      var n = i.getAlt();
      (l = this.app.popup.getInput("alt")).val(n), s && l.focus();
      var l,
        p = i.getLink();
      p && ((l = this.app.popup.getInput("url")).val(p.url), p.target && this.app.popup.getInput("target").attr("checked", !0));
    },
    save: function () {
      this.app.popup.close();
      var t = this.app.popup.getData(),
        e = this.app.block.get();
      if ("" !== t.alt && e.setAlt(t.alt), "" !== t.url) {
        var i = {
          url: t.url,
          target: t.hasOwnProperty("target")
        };
        e.setLink(i);
      } else e.removeLink();
    },
    remove: function () {
      this.app.popup.close(), this.app.block.remove();
    },
    buildPopup: function (t) {
      this.app.popup.build({
        name: "image",
        type: "box",
        dark: !0,
        width: "100%"
      }), this.app.popup.open(t.$btn);
      var e = this.app.popup.getBody();
      if (this.opts.image.url) {
        this.$urlinput = this.dom("<input>"), this.$urlinput.addClass("arx-form-input arx-form-input-on-dark"), this.$urlinput.attr("placeholder", this.lang.get("image.url-placeholder")), this.$urlbutton = this.dom("<button>"), this.$urlbutton.addClass("arx-form-button arx-form-button-secondary"), this.$urlbutton.html(this.lang.get("image.insert")), this.$urlbutton.one("click", this.insertByUrl.bind(this));
        var i = this.dom("<div>");
        i.addClass("arx-form-item-flex"), i.append(this.$urlinput), i.append(this.$urlbutton), e.append(i), this.$urlinput.focus();
      }
      if (this.opts.image.url && (this.opts.image.upload || this.opts.image.select)) {
        var s = this.dom("<div>");
        s.addClass("arx-form-section-or"), s.html(this.lang.get("image.or")), e.append(s);
      }
      this.opts.image.upload && (this.$uploaditem = this.dom("<div>"), this.$uploaditem.addClass("arx-popup-upload"), e.append(this.$uploaditem)), this.opts.image.select && (this.$box = this.dom("<div>"), this.$box.addClass("arx-popup-images-box"), e.append(this.$box), "object" == typeof this.opts.image.select ? this._parseList(this.opts.image.select) : this.ajax.get({
        url: this.opts.image.select,
        data: {
          d: new Date().getTime()
        },
        success: this._parseList.bind(this)
      })), this._buildUpload(this.$uploaditem, "image.insert");
    },
    insertByUrl: function (t) {
      t.preventDefault();
      var e = this.app.block.get(),
        i = this.$urlinput.val();
      if ("" !== i.trim()) {
        var s = {
          file: {
            url: i
          }
        };
        this.selection.restore(e.$block), this.insert(s);
      } else this.app.popup.close();
    },
    insertByDrop: function (t, e) {
      var i;
      if (this.app.block.is()) {
        i = !1;
        var s = this.app.block.get();
        if (e && s.isEditable()) this.insertion.insertPoint(e);else if ("image" === s.getType()) return this.change(t);
      } else i = "start";
      this.insert(t, e, i);
    },
    insertFromBlob: function (t) {
      var e = this.app.block.get();
      this.selection.restore(e.$block), this.insert(t);
    },
    insertFromSelect: function (t) {
      t.preventDefault();
      var e = this.app.block.get(),
        i = {
          file: {
            url: this.dom(t.target).attr("data-url")
          }
        };
      this.selection.restore(e.$block), this.insert(i);
    },
    change: function (t) {
      this.app.popup.close();
      var e = this.app.block.get();
      for (var i in t) return e.setImage(t[i]), void this.app.broadcast("image.change", {
        response: t
      });
    },
    insert: function (t, e, i) {
      this.app.popup.close(), this.imageslen = 0, this.imagescount = 0;
      var s = this.opts.image.tag;
      for (var r in t) {
        var o = this.dom("<" + s + ">");
        if ("figure" === s) {
          var a = this.dom("<figcaption>");
          a.attr("data-arx-type", "figcaption"), a.attr("contenteditable", !0), a.attr("data-placeholder", this.lang.get("placeholders.figcaption"));
        }
        var n = this.dom("<img>");
        n.attr("src", t[r].url), t[r].hasOwnProperty("id") && n.attr("data-image", t[r].id), n.one("load", this._checkLoad.bind(this)), o.append(n), "figure" === s && o.append(a);
        var l = this.app.create("block.image", o);
        this.app.block.add(l, i), this.$last = l.$block, this.imageslen++;
      }
    },
    error: function (t) {
      this.app.broadcast("image.upload.error", {
        response: t
      });
    },
    _buildUpload: function (t, e, i) {
      if (this.opts.image.upload) {
        var s = {
          url: this.opts.image.upload,
          name: this.opts.image.name,
          data: this.opts.image.data,
          multiple: this.opts.image.multiple,
          success: e,
          error: "image.error"
        };
        this.upload.build(t, s);
      }
    },
    _checkLoad: function () {
      this.imagescount++, this.imagescount === this.imageslen && (this.app.block.unset(), this.app.block.set(this.$last), 1 < this.imagescount && this.utils.scrollToElement(this.$last));
    },
    _parseList: function (t) {
      for (var e in t) {
        var i = t[e];
        if ("object" == typeof i) {
          var s = this.dom("<img>"),
            r = i.thumb ? i.thumb : i.url;
          s.addClass("arx-popup-event"), s.attr("src", r), s.attr("data-url", i.url), s.on("click.arx-popup-event-" + this.uuid, this.insertFromSelect.bind(this)), this.$box.append(s);
        }
      }
    }
  }), ArticleEditor.add("module", "embed", {
    popups: {
      edit: {
        name: "embed",
        width: "100%",
        form: {
          embed: {
            type: "textarea",
            label: "## embed.description ##",
            attr: {
              rows: 6
            }
          },
          responsive: {
            type: "checkbox",
            label: "## embed.responsive-video ##"
          }
        },
        header: "## embed.embed ##",
        footer: {
          insert: {
            title: "## embed.insert ##",
            command: "embed.insert",
            type: "primary"
          },
          cancel: {
            title: "## embed.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    init: function () {
      this.utils = this.app.create("utils"), this.content = this.app.create("content");
    },
    start: function () {
      this.app.control.add("editembed", {
        iconname: "edit",
        command: "embed.edit",
        title: "## command.edit ##",
        blocks: ["embed"]
      });
    },
    observeAdd: function (t, e) {
      return !!this.opts.embed && t;
    },
    buildPopup: function () {
      this.app.popup.build(this.popups.edit), this.app.popup.open();
      var t = this.app.popup.getInput("embed");
      t.focus(), this.utils.createCodemirror(t);
    },
    edit: function () {
      this.app.popup.build(this.popups.edit), this.app.popup.open(), this.app.popup.setButton("insert", {
        name: "save",
        title: "## embed.save ##",
        command: "embed.save"
      });
      var t = this.app.block.get(),
        e = decodeURI(t.$block.attr("data-embed-code"));
      0 !== t.$block.find("." + this.opts.embed.responsive).length && this.app.popup.getInput("responsive").attr("checked", !0);
      var i = this.app.popup.getInput("embed");
      i.val(e), i.focus(), this.utils.createCodemirror(i);
    },
    save: function () {
      this._insert(!0);
    },
    insert: function () {
      this._insert();
    },
    _insert: function (t) {
      var e = this.app.popup.getData(),
        i = e.embed;
      if (i = this.utils.sanitize(i), "" === (i = this.utils.getCodemirrorValue(i))) return t && this.app.block.remove(), void this.app.popup.close();
      var s = this.app.block.get(),
        r = this._isHtmlString(i) ? this._parseUrl(i) : i;
      if (r) {
        if (e.hasOwnProperty("responsive") && (r = '<div class="' + this.opts.embed.responsive + '">' + r + "</div>"), t && s) {
          var o,
            a = s.$block.find("figcaption");
          0 !== a.length && (o = a.clone()), o && (r += o.get().outerHTML);
        }
        r = "<figure>" + r + "</figure>", this._addBlock(e, r, t);
      }
      this.app.popup.close();
    },
    _addBlock: function (t, e, i) {
      var s = this.content.parse(e),
        r = this.app.create("block.embed", s);
      i ? this.app.block.change(r) : this.app.block.add(r), this.app.editor.executeEmbed();
    },
    _parseUrl: function (t) {
      var e = '<iframe width="560" height="315" src="',
        i = '" frameborder="0" allowfullscreen></iframe>';
      return t.match(this.opts.regex.youtube) ? (parsed = t.replace(this.opts.regex.youtube, "//www.youtube.com/embed/$1"), e + parsed + i) : t.match(this.opts.regex.vimeo) ? (parsed = t.replace(this.opts.regex.vimeo, "//player.vimeo.com/video/$2"), e + parsed + i) : void 0;
    },
    _isHtmlString: function (t) {
      return !/^\s*<(\w+|!)[^>]*>/.test(t);
    },
    _isFigure: function (t) {
      return /^<figure/.test(t);
    }
  }), ArticleEditor.add("module", "grid", {
    init: function () {
      this.utils = this.app.create("utils"), this.button = this.app.create("button");
    },
    observeAdd: function (t, e) {
      return !!this.opts.grid && t;
    },
    buildPopup: function () {
      this.app.popup.build({
        name: "grid",
        type: "tile",
        box: !0,
        width: "294px",
        header: "## blocks.grid ##"
      }), this.app.popup.open();
      for (var t = this.app.popup.getBody(), e = this.opts.grid.patterns, i = 0; i < e.length; i++) {
        var s = {
          command: "grid.insert",
          icon: '<span class="arx-popup-s-grid">' + this._createPattern(e[i]) + "</span>",
          params: {
            pattern: e[i]
          }
        };
        this.button.create(i, s, t, "popup");
      }
    },
    transform: function () {
      this.app.popup.close();
      var i = this.app.block.get(),
        t = i.$block.find("[data-arx-type=column]");
      t.nodes.reverse(), t.each(function (t) {
        var e = this.dom(t);
        i.$block.after(e.contents());
      }.bind(this));
      var e = this.app.block.next();
      this.app.editor.rebuild(), i.$block.remove(), e && this.app.block.set(e.$block);
    },
    insert: function (t) {
      var e = t.params.pattern.split("|"),
        i = this.dom("<div>");
      i.attr("data-arx-type", "grid"), i.addClass(this.opts.grid.classname);
      for (var s = 0; s < e.length; s++) {
        var r = this.dom("<div>");
        r.addClass(this.opts.grid.columns.classname), r.addClass(this.opts.grid.columns.prefix + e[s]), r.attr("data-arx-type", "column"), i.append(r);
      }
      var o = this.app.create("block.grid", i);
      this.app.block.add(o), this.app.popup.close();
    },
    _createPattern: function (t) {
      for (var e = this.dom("<div>"), i = t.split("|"), s = 100 / this.utils.sumOfArray(i), r = 0; r < i.length; r++) {
        var o = this.dom("<span>");
        o.addClass("arx-popup-s-column"), o.css("width", i[r] * s + "%"), e.append(o);
      }
      return e.html();
    }
  }), ArticleEditor.add("module", "code", {
    popups: {
      edit: {
        name: "code",
        width: "100%",
        form: {
          code: {
            type: "textarea",
            attr: {
              rows: 8
            }
          }
        },
        header: "## code.code ##",
        footer: {
          insert: {
            title: "## code.insert ##",
            command: "code.insert",
            type: "primary"
          },
          cancel: {
            title: "## code.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    init: function () {
      this.utils = this.app.create("utils"), this.content = this.app.create("content");
    },
    start: function () {
      this.app.control.add("editcode", {
        iconname: "edit",
        command: "code.edit",
        title: "## command.edit ##",
        blocks: ["code"]
      });
    },
    observeAdd: function (t, e) {
      return !!this.opts.code && t;
    },
    buildPopup: function () {
      this.app.popup.build(this.popups.edit), this.app.popup.open();
      var t = this.app.popup.getInput("code");
      t.addClass("arx-popup-event"), t.on("keydown.arx-popup-event-" + this.uuid, this._handleTab.bind(this)), t.focus(), this.utils.createCodemirror(t, this.opts.code);
    },
    edit: function () {
      this.app.popup.build(this.popups.edit), this.app.popup.open(), this.app.popup.setButton("insert", {
        name: "save",
        title: "## code.save ##",
        command: "code.save"
      });
      var t = this.app.block.get(),
        e = t.$block.find("code"),
        i = 0 === e.length ? t.$block.html() : e.html();
      i = this.content.decodeEntities(i);
      var s = this.app.popup.getInput("code");
      s.addClass("arx-popup-event"), s.on("keydown.arx-popup-event-" + this.uuid, this._handleTab.bind(this)), s.val(i), s.focus(), this.utils.createCodemirror(s, this.opts.code);
    },
    save: function () {
      this._insert(!0);
    },
    insert: function () {
      this._insert();
    },
    _insert: function (t) {
      this.app.popup.close();
      var e = this.app.popup.getData().code;
      if ("" === (e = this.utils.getCodemirrorValue(e, this.opts.code)) && t) {
        var i = 0 === (s = (o = this.app.block.get()).$block.find("code")).length ? o.$block : s;
        return i.attr("data-placeholder", this.lang.get("placeholders.code")), i.html(""), void this.app.popup.close();
      }
      var s,
        r = this.dom(this.opts.code.template);
      s = 0 === (s = r.find("code")).length ? r : s, e = this.content.encodeEntities(e), s.html(e);
      var o = this.app.create("block.code", r);
      t ? this.app.block.change(o) : this.app.block.add(o);
    },
    _handleTab: function (t) {
      if (9 !== t.keyCode) return !0;
      t.preventDefault();
      var e = t.target,
        i = e.value,
        s = e.selectionStart;
      e.value = i.substring(0, s) + "    " + i.substring(e.selectionEnd), e.selectionStart = e.selectionEnd = s + 4;
    }
  }), ArticleEditor.add("module", "snippet", {
    init: function () {
      this.key = !1, this.json = {}, this.button = this.app.create("button"), this.content = this.app.create("content");
    },
    observeAdd: function (t, e) {
      return !!this._is() && t;
    },
    buildPopup: function (t) {
      this._isJson() ? this._buildPopupPreview(t) : this._buildPopupList(t);
    },
    insert: function (t) {
      var e = this.dom(t.target).closest(".arx-snippet-trigger").attr("data-snippet-key");
      if (this.json.hasOwnProperty(e)) {
        this.app.popup.close();
        var i = this.json[e].html,
          s = this.content.parse(i),
          r = this.app.create("block.snippet", s);
        this.app.block.add(r);
      }
    },
    _buildPopupList: function (t) {
      this.app.popup.build({
        name: "snippet-list",
        list: !0
      }), this.app.popup.open(t.$btn);
      var e = this.app.popup.getBody(),
        i = this.opts.snippets.items;
      for (var s in i) {
        var r = {
          title: i[s],
          text: !0,
          icon: !1,
          command: "snippet._insertOld"
        };
        this.button.create(s, r, e, "popup");
      }
    },
    _buildPopupPreview: function (e) {
      this.opts.snippets.hasOwnProperty("items") ? this._buildPopupList(e) : "string" == typeof this.opts.snippets.json ? this.ajax.get({
        url: this.opts.snippets.json,
        data: {
          d: new Date().getTime()
        },
        success: function (t) {
          this._buildPreview(e, t);
        }.bind(this)
      }) : this._buildPreview(e, this.opts.snippets.json);
    },
    _buildPreview: function (t, e) {
      this.app.popup.build({
        name: "snippet",
        header: "## snippets.snippets ##",
        width: "100%"
      }), this.app.popup.open(t.$btn);
      var i = this.app.popup.getBody();
      for (var s in "string" == typeof (this.json = e) && (this.json = JSON.parse(e)), this.json) {
        var r = this.dom("<div>");
        r.addClass("arx-snippet-preview-container arx-snippet-trigger"), r.attr("data-snippet-key", s), r.on("click.arx-snippets", this.insert.bind(this));
        var o = this.dom("<div>"),
          a = this.json[s].html;
        if (o.html(a), o.addClass("arx-reset arx-content arx-snippet-preview"), r.append(o), this.json[s].hasOwnProperty("name")) {
          var n = this.dom("<div>");
          n.addClass("arx-snippet-preview-name"), n.text(this.json[s].name), r.append(n);
        }
        i.append(r);
      }
    },
    _is: function () {
      return this.opts.snippets.url || this.opts.snippets.json;
    },
    _isJson: function () {
      return this.opts.snippets.json && !0 !== this.opts.snippets.json;
    },
    _getUrl: function () {
      var t = this.opts.snippets.url;
      return this.opts.snippets.json || (t = t + this.key + ".html"), t;
    },
    _request: function () {
      this.ajax.get({
        url: this._getUrl(),
        data: {
          d: new Date().getTime()
        },
        success: this._insert.bind(this)
      });
    },
    _insertOld: function (t) {
      this.app.popup.close(), this.key = t.name, "object" == typeof this.opts.snippets.json ? this._insert(this.opts.snippets.json) : this._request();
    },
    _insert: function (t) {
      var e = !1;
      if (this.opts.snippets.json) {
        for (var i in t) if (i === this.key) {
          e = t[i].html;
          break;
        }
      } else e = t;
      if (!1 !== e) {
        var s = this.content.parse(e),
          r = this.app.create("block.snippet", s);
        this.app.block.add(r);
      }
      this.key = !1;
    }
  }), ArticleEditor.add("service", "button", {
    create: function (t, e, i, s) {
      if (e = this._build(e)) return this.type = s || "toolbar", this._create(t, e, i);
    },
    set: function (t, e, i, s) {
      if (i = this._build(i)) return this.type = s, this._create(e, i, !1, t);
    },
    _build: function (t) {
      return t.hasOwnProperty("popup") && (t = this._buildPopupButtons(t)), t;
    },
    _buildPopupButtons: function (t) {
      var e = 0;
      if (t.command = "toolbar.popup", t.popup.hasOwnProperty("builder")) t.popup.buttons = this.app.api(t.popup.builder), e += Object.keys(t.popup.buttons).length;else for (var i in t.popup) {
        var s = t.popup[i];
        "object" == typeof s && (s.hasOwnProperty("builder") ? (t.popup[i].buttons = this.app.api(s.builder, i), t.popup[i].buttons && (e += Object.keys(t.popup[i].buttons).length)) : e++);
      }
      return 0 !== e && t;
    },
    _create: function (t, e, i, s) {
      var r = this._observe(e, t);
      if (!1 !== r) {
        this.obj = void 0 === r ? e : r, this.name = t, this._buildTitle(), this._buildElement(s), this.$button.attr({
          "data-name": this.name,
          "data-command": this.obj.command || !1
        }), this.$button.dataset("button", this.obj), this._has("params") && this.$button.dataset("params", this.obj.params), this.$button.on("dragstart.arx-button-" + this.uuid, function (t) {
          t.preventDefault();
        });
        var o = this._has("command") ? "_catch" : "_stop";
        return this.$button.on("click.arx-button-" + this.uuid, this[o].bind(this)), i && (this._buildTooltip(), this._buildHidden(), this._buildDivider(), this._buildSize(), this._buildIcon(), this._buildText(), this._buildPosition(i), this._buildActive()), this.$button;
      }
    },
    _buildTitle: function () {
      this.title = void 0 !== this.obj.title ? this.lang.parse(this.obj.title) : "";
    },
    _buildElement: function (t) {
      t ? this.$button = t : (this.$button = this.dom('<a href="#"></a>'), this.$button.addClass("arx-button"), this.$button.addClass("arx-button-" + this.type)), this.$button.addClass("arx-button-target");
    },
    _buildHidden: function () {
      var t = this._has("hidden") && !0 === this.obj.hidden ? "addClass" : "removeClass";
      this.$button[t]("arx-button-hidden");
    },
    _buildTooltip: function () {
      var t = !!this.title && this.title.replace(/(<([^>]+)>)/gi, "");
      t && "toolbar" === this.type && (this.$button.attr("data-tooltip", t), this.$button.on("mouseover.arx-button-" + this.uuid, this._showTooltip.bind(this)), this.$button.on("mouseout.arx-button-" + this.uuid, this._hideTooltip.bind(this)));
    },
    _buildDivider: function () {
      this._has("topdivider") && this.$button.addClass("arx-button-top-divider"), this._has("bottomdivider") && this.$button.addClass("arx-button-bottom-divider");
    },
    _buildSize: function () {
      this._has("size") && this.$button.css("font-size", this.obj.size + "px");
    },
    _buildIconElement: function () {
      return this.dom("<span>").addClass("arx-button-icon");
    },
    _buildIcon: function () {
      var t,
        e = this._has("icon");
      if (!e || e && !0 === this.obj.icon) {
        var i = this._has("iconname") ? this.obj.iconname : this.name;
        (t = this._buildIconElement()).append('<span class="arx-icon-' + i + '"></span>');
      } else if (e) {
        if (!1 === this.obj.icon) return;
        (t = this._buildIconElement()).append(this.obj.icon);
      }
      this.$button.append(t);
    },
    _buildText: function () {
      if (this._has("text") && !1 !== this.obj.text) {
        var t = this.dom("<span>").addClass("arx-button-title").html(this.title);
        this.$button.append(t);
      }
    },
    _buildPosition: function (t) {
      if (this._has("position")) {
        if ("first" === this.obj.position) t.prepend(this.$button);else if ("object" == typeof this.obj.position) {
          var e = this.obj.position.hasOwnProperty("after") ? "after" : "before",
            i = this.obj.position[e],
            s = t.find("[data-name=" + i + "]");
          0 !== s.length ? s[e](this.$button) : t.append(this.$button);
        }
      } else t.append(this.$button);
    },
    _buildActive: function () {
      this.obj.active && this.$button.addClass("active");
    },
    _showTooltip: function (t) {
      if (!this.app.popup.isOpen()) {
        var e = this.dom(t.target).closest(".arx-button-target"),
          i = e.hasClass("arx-button-control"),
          s = e.offset(),
          r = e.height(),
          o = e.width(),
          a = this.dom("<span>");
        a.addClass("arx-tooltip"), a.html(e.attr("data-tooltip")), i ? r = 0 : o = 0, a.css({
          top: s.top + r + "px",
          left: s.left + o + "px"
        }), this.opts.bsmodal && a.css("z-index", 1051), this.app.$body.append(a);
      }
    },
    _hideTooltip: function () {
      this.app.$body.find(".arx-tooltip").remove();
    },
    _observe: function (t, e) {
      return t.hasOwnProperty("observer") && (t = this.app.api(t.observer, t, e)), t;
    },
    _stop: function (t) {
      t.preventDefault(), t.stopPropagation();
    },
    _catch: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).closest(".arx-button-target");
      if (!e.hasClass("disable")) {
        var i = e.attr("data-command"),
          s = e.attr("data-name"),
          r = e.dataget("params"),
          o = e.dataget("button");
        this._hideTooltip(), this.app.buffer.add(), this.app.api(i, {
          e: t,
          name: s,
          $btn: e,
          button: o,
          params: r
        });
      }
    },
    _has: function (t) {
      return this.obj.hasOwnProperty(t);
    }
  }), ArticleEditor.add("service", "caret", {
    init: function (t) {},
    set: function (t, e) {
      var i = this.dom(e).get();
      if (!i) return !1;
      this._apply(t, i);
    },
    is: function (t, e) {
      var i = this.dom(e).get();
      if (!i) return !1;
      var s = window.getSelection();
      if (!s.isCollapsed) return !1;
      var r = s.anchorNode.parentNode,
        o = this._getCaretPosition(i),
        a = this._getNodeSize(i);
      if ("end" === t) return o === a;
      if ("start" !== t) return !1;
      var n = this.app.create("utils");
      return 1 === o && r && n.isInline(r) && (o = 0), 0 === o;
    },
    _apply: function (t, e) {
      if (this._isInPage(e)) {
        var i = document.createRange(),
          s = 3 !== e.nodeType && e.tagName.toLowerCase();
        if ("start" === t) {
          if (i.setStart(e, 0), i.collapse(!0), (o = this.app.create("utils")).isInline(e)) {
            var r = o.createInvisibleChar();
            i.insertNode(r), i.selectNodeContents(r), i.collapse(!1);
          }
        } else if ("end" === t) i.selectNodeContents(e), i.collapse(!1);else if ("before" === t) {
          if (i.setStartBefore(e), i.collapse(!0), (o = this.app.create("utils")).isInline(e)) {
            r = o.createInvisibleChar();
            e.parentNode.insertBefore(r, e), i.selectNodeContents(r), i.collapse(!1);
          }
        } else if ("after" === t && (i.setStartAfter(e), i.collapse(!0), "br" === s || "svg" === s || "span" === s)) {
          var o;
          r = (o = this.app.create("utils")).createInvisibleChar();
          i.insertNode(r), i.selectNodeContents(r), i.collapse(!1);
        }
        this.app.create("selection").setRange(i);
      }
    },
    _getNodeSize: function (t) {
      var e = 3 === t.nodeType ? t.textContent : t.innerHTML;
      return this._getTrimmedString(e).length;
    },
    _getCaretPosition: function (t) {
      var e = window.getSelection().getRangeAt(0),
        i = e.cloneRange(),
        s = document.createElement("div");
      i.selectNodeContents(t), i.setEnd(e.endContainer, e.endOffset), s.appendChild(i.cloneContents());
      var r = s.innerHTML;
      return (r = this._getTrimmedString(r)).length;
    },
    _getTrimmedString: function (t) {
      var e = this.opts.tags.inline.join("|");
      return t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = t.replace(/\xA0|\u00A0|\u2028|\u2029/g, "1")).replace(new RegExp("<[" + e + "][^>]*></[" + e + "]>", "gi"), "1")).replace(/<[^\/>][^>]*><\/[^>]+>/gi, "")).replace(/<\/?br\s?\/?>/g, "1")).replace(/<img(.*?)>/g, "1")).replace(/<[^\/>][^>]*>/gi, "")).replace(/<\/[^>]+>/gi, "")).replace(/&nbsp;/gi, "1")).replace(/\s+/g, " ")).replace(/\t/g, " ")).replace(/\n/g, "1")).trim();
    },
    _isInPage: function (t) {
      return !(!t || !t.nodeType) && t !== document.body && document.body.contains(t);
    }
  }), ArticleEditor.add("service", "content", {
    init: function () {
      this.blockTags = this._buildBlockTags(), this.blockListTags = this._buildBlockListTags(), this._stored = {}, this._storedIndex = 0;
    },
    isLineText: function (t) {
      var e = document.createElement("div");
      return e.innerHTML = t, 0 === this.dom(e).find(this.opts.tags.block.join(",")).length;
    },
    isPlainLine: function (t) {
      var e = document.createElement("div");
      e.innerHTML = t;
      var i = this.dom(e);
      return i.find("p, div." + this.opts.plaintext.classname).unwrap(), 0 === i.find(this.opts.tags.block.join(",")).length;
    },
    getPlainLine: function (t) {
      return t = t.replace(/<\/div>|<\/li>|<\/td>|<\/p>|<\/H[1-6]>/gi, "<br><br>"), "<br>" + (t = this.removeTags(t, this.opts.tags.block));
    },
    isClipboardPlainText: function (t) {
      var e = t.getData("text/plain"),
        i = t.getData("text/html");
      if (!e || !i) return null !== e;
      var s = document.createElement("div");
      return s.innerHTML = i, s.textContent === e ? !s.querySelector(":not(meta)") : void 0;
    },
    paragraphize: function (t) {
      return this.app.create("paragraphize").parse(t);
    },
    autolink: function (t) {
      if (!this.opts.paste.autolink) return t;
      for (var e = ["figure", "form", "pre", "iframe", "code", "a", "img"], i = [], s = 0, r = 0; r < e.length; r++) {
        var o = "img" === e[r] ? "<" + e[r] + "[^>]*>" : "<" + e[r] + "[^>]*>([\\w\\W]*?)</" + e[r] + ">",
          a = t.match(new RegExp(o, "gi"));
        if (null !== a) for (var n = 0; n < a.length; n++) t = t.replace(a[n], "#####replaceparse" + s + "#####"), i.push(a[n]), s++;
      }
      return (t = t.replace("&amp;", "&")).match(this.opts.regex.url) && !t.match(this.opts.regex.imageurl) && (t = this._formatLinks(t)), t = this._restoreReplaced(i, t), t = this._restoreReplaced(i, t);
    },
    clean: function (t) {
      t = (t = this.storeComponents(t)).replace(new RegExp("<!doctype[^>]*>", "gi"), ""), t = (t = this.removeTags(t, this.opts.tags.denied)).replace(new RegExp("<style([\\s\\S]+?)</style>", "gi"), "");
      var e = this._isHtmlMsWord(t);
      if (t = this._cleanGDocs(t), t = e ? this._cleanMsWord(t) : t, !this.opts.paste.clean) return t = this.restoreComponents(t);
      if (this.opts.paste.plaintext) return t = this.restoreComponents(t), this.parsePlainText(t);
      var i = this.opts.paste.blockTags.concat(this.opts.paste.inlineTags).concat(this.opts.paste.formTags);
      t = this.removeTagsExcept(t, i), t = this.opts.paste.links ? t : this.removeTags(t, ["a"]), t = this.opts.paste.images ? t : this.removeTags(t, ["img"]);
      var s = this.app.create("utils"),
        r = (a = s.createWrapper(t)).find("*"),
        o = 0 !== this.opts.paste.keepStyle.length ? "," + this.opts.paste.keepStyle.join(",") : "";
      r.not("[data-arx-style-cache]" + o).removeAttr("style"), this.opts.paste.links && !1 !== this.opts.paste.linkTarget && a.find("a").attr("target", this.opts.paste.linkTarget), a.find("[data-arx-style-cache]").each(function (t) {
        var e = t.getAttribute("data-arx-style-cache");
        t.setAttribute("style", e);
      }), a.find("span").each(this.removeEmptySpan.bind(this)), a.find(this.opts.tags.inline.join(",")).each(function (t) {
        0 === t.attributes.length && s.isEmptyHtml(t.innerHTML) && this.dom(t).unwrap();
      }.bind(this)), a.find("ul, ol").each(function (t) {
        var e = t.previousSibling;
        if (e && "LI" === e.tagName) {
          var i = this.dom(e);
          i.find("p").unwrap(), i.append(t);
        }
      }.bind(this)), a.find("li p").unwrap(), t = s.getWrapperHtml(a), t = (t = (t = this.autolink(t)).replace(/<p>&nbsp;<\/p>/gi, "<p></p>")).replace(/<p><br\s?\/?><\/p>/gi, "<p></p>"), e && (t = (t = t.replace(/<p><\/p>/gi, "")).replace(/<p>\s<\/p>/gi, "")), t = this.restoreComponents(t);
      var a = s.createWrapper(t),
        n = ["alt", "title", "src", "class", "width", "height", "srcset", "style"];
      return a.find("img").each(function (t) {
        if (0 < t.attributes.length) for (var e = t.attributes, i = e.length - 1; 0 <= i; i--) -1 === e[i].name.search(/^data\-/) && -1 === n.indexOf(e[i].name) && t.removeAttribute(e[i].name);
      }), t = (t = s.getWrapperHtml(a)).trim();
    },
    parse: function (t, e, i) {
      if (t = t.trim(), t = this.app.broadcast("editor.content.before.parse", {
        html: t
      }).get("html"), this.isEmptyHtml(t)) return this.app.block.create().$block.get().outerHTML;
      var s = this.app.create("utils");
      t = this.encodeCode(t), t = this.encodeScript(t), t = s.sanitize(t), t = this._trimLinks(t), t = this.storeMatched(t, "noteditable", ["." + this.opts.noneditable.classname]), t = this.storeMatched(t, "embedded", ["figure", "iframe"]), t = this.removeTags(t, this.opts.tags.denied);
      var r = s.createWrapper(t);
      r.find("script").remove(), r.find("span").each(this.removeEmptySpan.bind(this)), r.find("th, td, li").each(this.removeBlockTags.bind(this));
      var o = this.opts.tags.block.join(",") + ",a,img," + this.opts.tags.form.join(",");
      r.find(o).each(this.cacheBlocksStyle.bind(this));
      var a = s.removeFromArrayByValue(this.opts.tags.inline, "a");
      return r.find(a.join(",")).removeAttr("style"), r = this.app.parser.parse(r), t = s.getWrapperHtml(r), t = this.restoreMatched(t, "embedded"), r = s.createWrapper(t), r = this.app.parser.parseExclude(r), t = s.getWrapperHtml(r), t = this.restoreMatched(t, "noteditable"), r = s.createWrapper(t), (r = this.app.parser.parseNoneditable(r)).find("figure").each(function (t) {
        var e = this.dom(t),
          i = e.find("figcaption");
        0 === i.length && ((i = this.dom("<figcaption>")).attr("data-arx-type", "figcaption"), i.attr("contenteditable", !0), e.append(i)), i.attr("data-placeholder", this.lang.get("placeholders.figcaption"));
      }.bind(this)), i && r.find("[data-arx-type]").attr("data-arx-inserted", !0), e && this.opts.plaintext.markup && r.find("[data-arx-type=paragraph]").each(function (t) {
        var e = this.dom(t);
        e.attr("data-arx-type", "text"), e.addClass(this.opts.plaintext.classname), s.replaceToTag(e, "div");
      }.bind(this)), t = s.getWrapperHtml(r), this.app.broadcast("editor.content.parse", {
        html: t
      }).get("html");
    },
    unparse: function (t, e) {
      if (t = t.trim(), t = this.app.broadcast("editor.content.before.unparse", {
        html: t
      }).get("html"), this.isEmptyHtml(t)) return "";
      var i = this.app.create("utils"),
        s = i.createWrapper(t);
      s.find("[data-arx-type=embed]").each(function (t) {
        var e,
          i = this.dom(t),
          s = decodeURI(i.attr("data-embed-code")),
          r = i.find("." + this.opts.embed.responsive),
          o = i.find("figcaption");
        0 !== o.length && (e = o.clone(), o.remove()), 0 === r.length ? i.html(s) : r.html(s), e && i.append(e);
      }.bind(this)), s.find(".arx-plus-button").remove();
      var r = s.find("[data-arx-type]");
      r.removeAttr("data-arx-type data-arx-first-level data-arx-tmp-tag data-placeholder data-embed-code contenteditable data-gramm_editor tabindex"), r.removeClass(this.opts.arxclasses);
      var o = s.find("a");
      return this.opts.link && this.opts.link.nofollow && o.attr("rel", "nofollow"), r.each(function (t) {
        var e = this.dom(t);
        "FIGCAPTION" === t.tagName && "" === t.innerHTML.trim() && e.remove(), "" === e.attr("class") && e.removeAttr("class");
      }.bind(this)), s.find("[data-arx-style-cache]").each(function (t) {
        var e = t.getAttribute("data-arx-style-cache");
        t.setAttribute("style", e), t.removeAttribute("data-arx-style-cache");
      }), t = i.getWrapperHtml(s), t = this.encodeScript(t, !0), this.app.broadcast("editor.content.unparse", {
        html: t
      }).get("html");
    },
    copy: function (t, e, i) {
      var s = t.clipboardData,
        r = "string" == typeof e ? e : e.clone().get().outerHTML,
        o = !1 === i ? r : this.unparse(r);
      s.setData("text/html", o), s.setData("text/plain", this.removeHtml(o).replace(/\n$/, ""));
    },
    parsePlainText: function (t) {
      return t = this.opts.paste.links ? this.storeLinks(t) : t, t = this.opts.paste.images ? this.storeImages(t) : t, t = this.getPlainText(t), t = this._replaceNlToBr(t), t = this.opts.paste.links ? this.restoreLinks(t) : t, t = this.opts.paste.images ? this.restoreImages(t) : t;
    },
    getPlainText: function (t) {
      t = (t = (t = (t = (t = (t = t.replace(/<!--[\s\S]*?-->/gi, "")).replace(/<style[\s\S]*?style>/gi, "")).replace(/<p><\/p>/g, "")).replace(/<\/div>|<\/li>|<\/td>/gi, "\n")).replace(/<\/p>/gi, "\n\n")).replace(/<\/H[1-6]>/gi, "\n\n");
      var e = document.createElement("div");
      return e.innerHTML = t, (t = e.textContent || e.innerText).trim();
    },
    storeComponents: function (t) {
      return this.storeMatched(t, "components", ["svg", "figure", ".noneditable"]);
    },
    storeLinks: function (t) {
      return this.storeMatched(t, "links", ["a"]);
    },
    storeImages: function (t) {
      return this.storeMatched(t, "images", ["img"]);
    },
    restoreComponents: function (t) {
      return this.restoreMatched(t, "components");
    },
    restoreLinks: function (t) {
      return this.restoreMatched(t, "links");
    },
    restoreImages: function (t) {
      return this.restoreMatched(t, "images");
    },
    storeMatched: function (t, e, i) {
      for (var s = 0; s < i.length; s++) {
        var r = this._getElementsFromHtml(t, i[s]);
        t = this._storeMatched(t, e, r);
      }
      return t;
    },
    restoreMatched: function (t, e) {
      if (void 0 !== this._stored[e]) for (var i = 0; i < this._stored[e].length; i++) t = t.replace("####_" + e + i + "_####", this._stored[e][i]);
      return t;
    },
    _storeMatched: function (t, e, i) {
      if (i) {
        void 0 === this._stored[e] && (this._stored[e] = []);
        for (var s = 0; s < i.length; s++) this._stored[e][this._storedIndex] = i[s], t = t.replace(i[s], "####_" + e + this._storedIndex + "_####"), this._storedIndex++;
      }
      return t;
    },
    isEmptyHtml: function (t) {
      return "" === t || "<p></p>" === t || "<div></div>" === t;
    },
    removeEmptySpan: function (t) {
      0 === t.attributes.length && this.dom(t).unwrap();
    },
    removeHtml: function (t) {
      var e = document.createElement("div");
      return e.innerHTML = t, e.textContent || e.innerText || "";
    },
    escapeHtml: function (t) {
      return t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    },
    removeTags: function (t, i) {
      var e = i ? /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi : /(<([^>]+)>)/gi,
        s = i ? function (t, e) {
          return -1 === i.indexOf(e.toLowerCase()) ? t : "";
        } : "";
      return t.replace(e, s);
    },
    removeBlockTags: function (t) {
      var e = "LI" === t.tagName ? this.blockListTags : this.blockTags;
      this.dom(t).find(e.join(",")).append("<br>").unwrap();
    },
    removeTagsExcept: function (t, i) {
      if (void 0 === i) return t.replace(/(<([^>]+)>)/gi, "");
      return t.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, function (t, e) {
        return -1 === i.indexOf(e.toLowerCase()) ? "" : t;
      });
    },
    cacheBlocksStyle: function (t) {
      var e = this.dom(t),
        i = e.attr("style");
      i ? e.attr("data-arx-style-cache", i) : i && "" !== i || e.removeAttr("data-arx-style-cache");
    },
    encodeScript: function (t, e) {
      var i = t.match(/<script(|\\s+[^>]*)>([\w\W]*?)<\/script>/g);
      if (null !== i) for (var s = 0; s < i.length; s++) {
        var r = i[s].match(new RegExp("<script(.*?)>([\\w\\W]*?)<\/script>", "i")),
          o = e ? decodeURI(r[2]) : encodeURI(r[2]);
        t = t.replace(r[0], "<script" + r[1] + ">" + o + "<\/script>");
      }
      return t;
    },
    encodeCode: function (t) {
      t = (t = (t = (t = (t = t.replace(/<(.*?)>/gi, "xtagstartz$1xtagendz")).replace(/xtagstartzpre(.*?)xtagendz/g, "<pre$1>")).replace(/xtagstartzcode(.*?)xtagendz/g, "<code$1>")).replace(/xtagstartz\/codextagendz/g, "</code>")).replace(/xtagstartz\/prextagendz/g, "</pre>");
      var e = this.app.create("utils"),
        i = e.createWrapper(t);
      return i.find("pre code, pre, code").each(this._encodeNode.bind(this)), t = (t = (t = e.getWrapperHtml(i)).replace(/xtagstartz(.*?)xtagendz/g, "<$1>")).replace(/xtagstartz\/(.*?)xtagendz/g, "</$1>");
    },
    encodeEntities: function (t) {
      return this.decodeEntities(t).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
    },
    decodeEntities: function (t) {
      return String(t).replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, '"').replace(/&amp;/g, "&");
    },
    _encodeNode: function (t) {
      var e = t.firstChild,
        i = t.innerHTML;
      if ("PRE" !== t.tagName || !e || "CODE" !== e.tagName) {
        i = (i = i.replace(/xtagstartz/g, "<")).replace(/xtagendz/g, ">");
        var s = this.decodeEntities(i);
        s = s.replace(/&nbsp;/g, " ").replace(/<br\s?\/?>/g, "\n"), s = this.opts.pre.spaces ? s.replace(/\t/g, new Array(this.opts.pre.spaces + 1).join(" ")) : s, t.textContent = s;
      }
    },
    _buildBlockTags: function () {
      return this.opts.tags.block;
    },
    _buildBlockListTags: function () {
      var t = this.opts.tags.block.concat();
      return this.app.create("utils").removeFromArrayByValue(t, ["ul", "ol", "li"]);
    },
    _formatLinks: function (t) {
      for (var e = t.match(this.opts.regex.url), i = {}, s = 0; s < e.length; s++) {
        var r = e[s],
          o = r,
          a = null !== r.match(/(https?|ftp):\/\//i) ? "" : "http://",
          n = -1 !== ["/", "&", "="].indexOf(r.slice(-1)) ? "" : "\\b",
          l = !1 !== this.opts.paste.linkTarget ? ' target="' + this.opts.paste.linkTarget + '"' : "";
        o = -1 === (o = o.length > this.opts.link.size ? o.substring(0, this.opts.link.size) + "..." : o).search("%") ? decodeURIComponent(o) : o, i["(" + r.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") + n + ")"] = '<a href="' + a + r.trim() + '"' + l + ">" + o.trim() + "</a>";
      }
      for (var p in i) t = t.replace(new RegExp(p, "g"), i[p]);
      return t;
    },
    _restoreReplaced: function (t, e) {
      for (var i = 0; i < t.length; i++) e = e.replace("#####replaceparse" + i + "#####", t[i]);
      return e;
    },
    _getKeepClasses: function () {
      var t = [];
      for (var e in this.opts.plaintext && t.push("." + this.opts.plaintext.classname), this.opts.grid && (t.push("." + this.opts.grid.columns.classname), t.push("." + this.opts.grid.classname)), this.opts.snippets.items) t.push("." + e);
      return 0 !== t.length ? ", " + t.join(", ") : "";
    },
    _getElementsFromHtml: function (t, e) {
      var i = document.createElement("div");
      i.innerHTML = t;
      var s = i.querySelectorAll(e);
      return function (t, e) {
        if ("number" == typeof this.length && "function" == typeof t) {
          var i = [];
          if ("object" == typeof this) for (var s = 0; s < this.length; s++) {
            if (!(s in this)) return;
            i[s] = t.call(e || this, this[s], s, this);
          }
          return i;
        }
      }.call(s, function (t) {
        return t.outerHTML;
      });
    },
    _replaceNlToBr: function (t) {
      return t.replace(/\n/g, "<br />");
    },
    _trimLinks: function (t) {
      var e = this.app.create("utils"),
        i = e.createWrapper(t);
      return i.find("a").each(function (t) {
        var e = this.dom(t);
        e.html(e.html().trim());
      }.bind(this)), e.getWrapperHtml(i);
    },
    _isHtmlMsWord: function (t) {
      return t.match(/class="?Mso|style="[^"]*\bmso-|style='[^'']*\bmso-|w:WordDocument/i);
    },
    _cleanGDocs: function (t) {
      return t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = t.replace(/<b\sid="internal-source-marker(.*?)">([\w\W]*?)<\/b>/gi, "$2")).replace(/<b(.*?)id="docs-internal-guid(.*?)">([\w\W]*?)<\/b>/gi, "$3")).replace(/<br[^>]*>/gi, "<br>")).replace(/<span[^>]*(font-style:\s?italic;\s?font-weight:\s?bold|font-weight:\s?bold;\s?font-style:\s?italic)[^>]*>([\w\W]*?)<\/span>/gi, "<b><i>$2</i></b>")).replace(/<span[^>]*(font-style:\s?italic;\s?font-weight:\s?600|font-weight:\s?600;\s?font-style:\s?italic)[^>]*>([\w\W]*?)<\/span>/gi, "<b><i>$2</i></b>")).replace(/<span[^>]*(font-style:\s?italic;\s?font-weight:\s?700|font-weight:\s?700;\s?font-style:\s?italic)[^>]*>([\w\W]*?)<\/span>/gi, "<b><i>$2</i></b>")).replace(/<span[^>]*font-style:\s?italic[^>]*>([\w\W]*?)<\/span>/gi, "<i>$1</i>")).replace(/<span[^>]*font-weight:\s?bold[^>]*>([\w\W]*?)<\/span>/gi, "<b>$1</b>")).replace(/<span[^>]*font-weight:\s?700[^>]*>([\w\W]*?)<\/span>/gi, "<b>$1</b>")).replace(/<span[^>]*font-weight:\s?600[^>]*>([\w\W]*?)<\/span>/gi, "<b>$1</b>")).replace(/<p[^>]*>\s<\/p>/gi, "");
    },
    _cleanMsWord: function (t) {
      t = (t = (t = (t = (t = t.replace(/<!--[\s\S]+?-->/gi, "")).replace(/<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s\/>]))[^>]*>/gi, "")).replace(/<(\/?)s>/gi, "<$1strike>")).replace(/&nbsp;/gi, " ")).replace(/<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi, function (t, e) {
        return 0 < e.length ? e.replace(/./, " ").slice(Math.floor(e.length / 2)).split("").join(" ") : "";
      });
      var e = this.app.create("utils"),
        i = e.createWrapper(t);
      i.find("p").each(function (t) {
        var e = this.dom(t),
          i = e.attr("style"),
          s = /mso-list:\w+ \w+([0-9]+)/.exec(i);
        s && e.attr("data-listLevel", parseInt(s[1], 10));
      }.bind(this)), this._parseWordLists(i), i.find("[align]").removeAttr("align"), i.find("[name]").removeAttr("name"), i.find("span").each(function (t) {
        var e = this.dom(t),
          i = e.attr("style");
        /mso-list:Ignore/.exec(i) ? e.remove() : e.unwrap();
      }.bind(this)), i.find("[style]").removeAttr("style"), i.find("[class^='Mso']").removeAttr("class"), i.find("a").filter(function (t) {
        return !t.hasAttribute("href");
      }).unwrap();
      for (var s = "", r = (t = (t = (t = (t = (t = e.getWrapperHtml(i)).replace(/<p[^>]*><\/p>/gi, "")).replace(/<li>·/gi, "<li>")).trim()).replace(/\/(p|ul|ol|h1|h2|h3|h4|h5|h6|blockquote)>\s+<(p|ul|ol|h1|h2|h3|h4|h5|h6|blockquote)/gi, "/$1>\n<$2")).split(/\n/), o = 0; o < r.length; o++) {
        var a = "" !== r[o] && -1 === r[o].search(/>$/) ? " " : "\n";
        s += r[o] + a;
      }
      return s;
    },
    _parseWordLists: function (t) {
      var n = 0,
        l = null,
        p = null,
        c = null;
      t.find("p").each(function (t) {
        var e = this.dom(t),
          i = e.attr("data-listLevel");
        if (null === i && e.hasClass("MsoListParagraphCxSpMiddle") && (i = 1), null !== i) {
          var s = e.text(),
            r = /^\s*\w+\./.test(s) ? "<ol></ol>" : "<ul></ul>";
          if (e.hasClass("MsoListParagraphCxSpFirst") || e.hasClass("MsoNormal") ? (p = this.dom(r), e.before(p)) : n < i && 0 !== n && (c = this.dom(r), l.append(c), p = c), i < n) for (var o = n - i + 1, a = 0; a < o; a++) p = p.parent();
          e.find("span").first().unwrap(), l = this.dom("<li>" + e.html().trim() + "</li>"), null === p && (e.before(r), p = e.prev()), p.append(l), e.remove(), n = i;
        } else p = null, n = 0;
      }.bind(this));
    }
  }), ArticleEditor.add("service", "offset", {
    init: function (t) {},
    get: function (t) {
      t = t ? this.dom(t).get() : this.app.editor.$editor.get();
      var e = window.getSelection(),
        i = !1;
      if (0 < e.rangeCount) {
        var s = e.getRangeAt(0),
          r = 1 === this.dom(s.startContainer).closest(".arx-editor").length,
          o = t.contains(e.anchorNode);
        if (o && r) {
          if (o) {
            var a = s.cloneRange();
            a.selectNodeContents(t), a.setEnd(s.startContainer, s.startOffset);
            var n = a.toString().length;
            i = {
              start: n,
              end: n + s.toString().length
            };
          }
        } else i = !1;
      }
      return i;
    },
    set: function (t, e) {
      e = e ? this.dom(e).get() : this.app.editor.$editor.get();
      var i,
        s = 0,
        r = document.createRange(),
        o = [e],
        a = !1,
        n = !1;
      for (r.setStart(e, 0), r.collapse(!0); !n && (i = o.pop());) if (3 == i.nodeType) {
        var l = s + i.length;
        !a && t.start >= s && t.start <= l && (r.setStart(i, t.start - s), a = !0), a && t.end >= s && t.end <= l && (r.setEnd(i, t.end - s), n = !0), s = l;
      } else for (var p = i.childNodes.length; p--;) o.push(i.childNodes[p]);
      var c = window.getSelection();
      c.removeAllRanges(), c.addRange(r);
    }
  }), ArticleEditor.add("service", "insertion", {
    init: function () {},
    insertBreakline: function (t) {
      return this.insertNode(document.createElement("br"), t || "after");
    },
    insertChar: function (t, e) {
      return this.insertNode(t, e || "after");
    },
    insertHtml: function (t, e) {
      return this.insertNode(t, e || "after");
    },
    insertNode: function (t, e) {
      var i = this._buildFragment(t);
      return this._insertFragment(i), this._setCaretToFragment(i, e), i.nodes[0];
    },
    insertPoint: function (t) {
      var e,
        i = this.app.create("caret"),
        s = this.app.create("utils").createInvisibleChar(),
        r = t.clientX,
        o = t.clientY;
      if (document.caretPositionFromPoint) {
        var a = document.caretPositionFromPoint(r, o);
        (e = document.getSelection().getRangeAt(0)).setStart(a.offsetNode, a.offset), e.collapse(!0), e.insertNode(s);
      } else document.caretRangeFromPoint && (e = document.caretRangeFromPoint(r, o)).insertNode(s);
      i.set("after", s);
    },
    _setCaretToFragment: function (t, e) {
      e && this.app.create("caret").set(e, t.last);
    },
    _buildFragment: function (t) {
      var e = this.app.create("utils");
      return e.isFragment(t) ? t : e.createFragment(t);
    },
    _insertFragment: function (t) {
      var e = this.app.create("selection").get();
      if (e.range) {
        if (e.collapsed) {
          var i = e.range.startContainer;
          3 !== i.nodeType && "BR" === i.tagName && i.parentNode.removeChild(i);
        } else e.range.deleteContents();
        e.range.insertNode(t.frag);
      }
    }
  }), ArticleEditor.add("service", "selection", {
    init: function () {},
    get: function () {
      var t = this._getSelection(),
        e = this._getRange(t),
        i = this._getCurrent(t),
        s = this._isInEditor(i) || this.app.editor.is(i);
      return {
        is: s,
        selection: !!s && t,
        range: e,
        collapsed: this._getCollapsed(t, e),
        current: i,
        parent: this._getParent(i)
      };
    },
    getNodes: function (t) {
      for (var e, i, s = this._getSelection(), r = this._getRange(s), o = s && r ? this._getRangeNodes(r) : [], a = this.app.create("utils"), n = [], l = 0; l < o.length; l++) e = !0, t && (t.type && ("inline" !== t.type || a.isInlineTag(o[l].tagName) || (e = !1)), t.tags && ((i = void 0 !== o[l].tagName) || (e = !1), i && -1 === t.tags.indexOf(o[l].tagName.toLowerCase()) && (e = !1))), e && n.push(o[l]);
      return n;
    },
    getElement: function (t) {
      return this._getElement(t, "isElement");
    },
    getInline: function (t) {
      return this._getElement(t, "isInline");
    },
    getBlock: function (t) {
      return this._getElement(t, "isBlock");
    },
    getText: function (t, e) {
      var i = this.get(),
        s = !1;
      if (!i.is) return !1;
      if (t && i.range) {
        e = void 0 === e ? 1 : e;
        var r = this.app.editor.$editor.get(),
          o = i.range.cloneRange();
        "before" === t ? (o.collapse(!0), o.setStart(r, 0), s = o.toString().slice(-e)) : "after" === t && (o.selectNodeContents(r), o.setStart(i.range.endContainer, i.range.endOffset), s = o.toString().slice(0, e));
      } else s = i.selection ? i.selection.toString() : "";
      return s;
    },
    getHtml: function (t) {
      var e = "",
        i = this.get();
      if (!i.is) return !1;
      if (i.selection) {
        var s = i.range.cloneContents(),
          r = document.createElement("div");
        r.appendChild(s), e = (e = r.innerHTML).replace(/<p><\/p>$/i, "");
      }
      return e;
    },
    set: function (t) {
      t.selection && (t.selection.removeAllRanges(), t.selection.addRange(t.range));
    },
    setRange: function (t) {
      this.set({
        selection: window.getSelection(),
        range: t
      });
    },
    is: function (t) {
      if (void 0 === t) return this.get().is;
      for (var e = this.dom(t).get(), i = this.getNodes(), s = 0; s < i.length; s++) if (i[s] === e) return !0;
      return !1;
    },
    isAll: function (t) {
      var e = this.dom(t).get(),
        i = window.getSelection(),
        s = this._getRange(i);
      return !i.isCollapsed && !!this.is(e) && void 0 !== e.textContent && e.textContent.trim().length === s.toString().trim().length;
    },
    collapse: function (t) {
      t = t || "start";
      var e = this.get();
      e.selection && !e.collapsed && ("start" === t ? e.selection.collapseToStart() : e.selection.collapseToEnd());
    },
    save: function (t) {
      var e = this.app.create("offset");
      this.app.editor.savedSelection = e.get(t);
    },
    restore: function (t) {
      this.app.editor.savedSelection && (this.app.create("offset").set(this.app.editor.savedSelection, t), this.app.editor.savedSelection = !1);
    },
    _getSelection: function () {
      var t = window.getSelection();
      return 0 < t.rangeCount && t;
    },
    _getRange: function (t) {
      return !!t && 0 < t.rangeCount && t.getRangeAt(0);
    },
    _getCurrent: function (t) {
      return !!t && t.anchorNode;
    },
    _getParent: function (t) {
      var e = !!t && t.parentNode;
      return !this.app.editor.is(e) && e;
    },
    _getElement: function (t, e) {
      var i = this._getSelection(),
        s = this.app.create("utils");
      if (i) {
        var r = t || this._getCurrent(i);
        for (r = this.dom(r).get(); r;) {
          if (s[e](r) && this._isInEditor(r)) return r;
          r = r.parentNode;
        }
      }
      return !1;
    },
    _getCollapsed: function (t, e) {
      var i = !1;
      return t && t.isCollapsed ? i = !0 : e && 0 === e.toString().length && (i = !0), i;
    },
    _getNextNode: function (t) {
      if (t.firstChild) return t.firstChild;
      for (; t;) {
        if (t.nextSibling) return t.nextSibling;
        t = t.parentNode;
      }
    },
    _getRangeNodes: function (t) {
      var e,
        i = t.startContainer.childNodes[t.startOffset] || t.startContainer,
        s = t.endContainer.childNodes[t.endOffset] || t.endContainer,
        r = t.commonAncestorContainer,
        o = [];
      for (e = i.parentNode; e && !this.app.editor.is(e) && (o.push(e), e != r); e = e.parentNode);
      for (o.reverse(), e = i; e && (3 === e.nodeType || 0 !== this.dom(e.parentNode).closest(r).length) && (o.push(e), e != s); e = this._getNextNode(e));
      return o;
    },
    _isInEditor: function (t) {
      return 0 !== this.dom(t).closest(".arx-editor-" + this.uuid).length;
    }
  }), ArticleEditor.add("service", "input", {
    traverseTab: function (t, e, i) {
      var s = this.app.create("caret"),
        r = this.app.create("selection"),
        o = this.app.create("insertion"),
        a = r.getBlock(),
        n = "list" === i.getType(),
        l = n && "LI" === a.tagName && s.is("start", a),
        p = !1 !== this.opts.tab.spaces;
      if (!l && p) {
        var c = document.createTextNode(Array(this.opts.tab.spaces + 1).join(" "));
        return o.insertNode(c, "end"), !0;
      }
      if (n && !i.isStart()) return this.app.api("list.indent"), !0;
    },
    trimInvisibleChar: function (t, e, i) {
      var s,
        r = this.app.create("utils"),
        o = this.app.create("offset"),
        a = this.app.create("selection"),
        n = "before" === e ? "left" : "right",
        l = a.get();
      if (l.is) {
        var p = a.getText(e),
          c = l.current && 3 === l.current.nodeType && 0 === r.searchInvisibleChars(p);
        if (c && "left" == n) s = l.current, this.dom(s).replaceWith(s.textContent.replace(/\s+$/, ""));else if (c && i && l.current && l.current.nextSibling) s = l.current.nextSibling, this.dom(s).replaceWith(s.textContent.replace(/^\s+/, ""));else if (c && "right" == n) {
          t.preventDefault();
          var h = o.get();
          return o.set({
            start: h.start + 1,
            end: h.end + 1
          }), !0;
        }
      }
    },
    trimEmptyInlines: function (t) {
      var e = this.app.create("utils"),
        i = this.app.create("selection").get();
      if (i.collapsed && 0 === document.getSelection().anchorOffset && i.current && i.current.previousSibling && e.isInline(i.current.previousSibling) && "" === i.current.previousSibling.innerHTML) return t.preventDefault(), this.dom(i.current.previousSibling).remove(), !0;
    },
    removeUnwantedStyles: function (t) {
      t.isEditable() && setTimeout(function () {
        t.$block.find("*[style]").not("img, figure, iframe, [data-arx-style-cache], [data-arx-span]").removeAttr("style");
      }, 0);
    },
    removeEmptySpans: function (t) {
      if (t.isEditable()) {
        var e = this;
        setTimeout(function () {
          t.$block.find("span").each(function (t) {
            0 === t.attributes.length && e.dom(t).replaceWith(t.childNodes);
          });
        }, 0);
      }
    },
    insertNonBreakSpace: function () {
      return this.app.create("insertion").insertChar("&nbsp;"), !0;
    },
    insertBreakline: function () {
      return this.app.create("insertion").insertBreakline(), !0;
    },
    replaceToParagraph: function (t) {
      var e = t.getType(),
        i = this.app.block.create();
      return -1 !== ["column", "layer"].indexOf(e) && t.$block.hasClass("arx-empty-layer") ? (t.$block.removeClass("arx-empty-layer"), t.$block.html(""), this.app.block.add(i, "append")) : "column" !== e && (this.app.block.add(i, "after", !1), t.remove(!1), this.app.block.set(i.$block)), !0;
    },
    deleteContents: function (t) {
      return t.deleteContents(), !0;
    },
    deleteContentsAndCollapse: function (t) {
      return this.deleteContents(t), this.app.create("selection").collapse("end"), !0;
    },
    makeEmpty: function (t) {
      var e = t.empty();
      return this.app.create("caret").set("start", e), !0;
    },
    createBefore: function (t) {
      var e = this.app.block.create();
      return this.app.block.add(e, "before", t), this.app.control.rebuild(), !0;
    },
    createAfter: function (t) {
      var e = this.app.block.create();
      return this.app.block.add(e, "after", t), !0;
    }
  }), ArticleEditor.add("service", "paragraphize", {
    init: function () {
      this.stored = [], this.remStart = "#####replace", this.remEnd = "#####", this.paragraphizeTags = ["table", "div", "pre", "form", "ul", "ol", "h1", "h2", "h3", "h4", "h5", "h6", "dl", "blockquote", "figcaption", "address", "section", "header", "footer", "aside", "article", "object", "style", "script", "iframe", "select", "input", "textarea", "button", "option", "map", "area", "math", "hr", "fieldset", "legend", "hgroup", "nav", "figure", "details", "menu", "summary", "p"];
    },
    parse: function (t) {
      var e = this._isConverted(t);
      return !0 === e ? this._convert(t) : e;
    },
    _convert: function (t) {
      var e = [],
        i = (t = this._storeTags(t)).match(new RegExp("\x3c!--([\\w\\W]*?)--\x3e", "gi"));
      if (null !== i) for (var s = 0; s < i.length; s++) t = t.replace(i[s], "#####xstarthtmlcommentzz" + s + "xendhtmlcommentzz#####"), e.push(i[s]);
      t = (t = t.trim()).replace(/[\n]+/g, "\n"), t = (t = (t = "<p>" + (t = (t = (t = this._trimEmptyLines(t)).replace(/(?:\r\n|\r|\n)/g, "xparagraphmarkerz")).replace(/xparagraphmarkerz/gi, "</p>\n<p>")) + "</p>").replace(new RegExp("<p>#####", "gi"), "#####")).replace(new RegExp("#####</p>", "gi"), "#####"), t = this._restoreTags(t);
      for (s = 0; s < e.length; s++) t = t.replace("#####xstarthtmlcommentzz" + s + "xendhtmlcommentzz#####", e[s]);
      return t;
    },
    _storeTags: function (t) {
      var s = this,
        e = this.app.create("utils"),
        i = e.createWrapper(t);
      return i.find(this.paragraphizeTags.join(", ")).each(function (t, e) {
        var i = document.createTextNode("\n" + s.remStart + e + s.remEnd + "\n");
        s.stored.push(t.outerHTML), t.parentNode.replaceChild(i, t);
      }), e.getWrapperHtml(i);
    },
    _restoreTags: function (t) {
      for (var e = 0; e < this.stored.length; e++) this.stored[e] = this.stored[e].replace(/\$/g, "&#36;"), t = t.replace(this.remStart + e + this.remEnd, this.stored[e]);
      return t;
    },
    _trimEmptyLines: function (t) {
      for (var e = "", i = t.split("\n"), s = 0; s < i.length; s++) "" !== i[s].trim() && (e += i[s] + "\n");
      return e.replace(/\n$/, "");
    },
    _isConverted: function (t) {
      return !1 !== this.opts.paste.paragraphize && !this._isEmptyHtml(t) || t;
    },
    _isEmptyHtml: function (t) {
      return "" === t || "<p></p>" === t || "<div></div>" === t;
    }
  }), ArticleEditor.add("service", "upload", {
    init: function () {
      this.defaults = {
        url: !1,
        type: "image",
        name: "file",
        data: !1,
        multiple: !0,
        success: !1,
        error: !1
      };
    },
    stop: function () {
      this._hideProgress();
    },
    build: function (t, e) {
      this.$element = this.dom(t), this.params = ArticleEditor.extend(!0, this.defaults, e), this._buildInput(), this._buildPlaceholder(), this._buildEvents();
    },
    send: function (t, e, i) {
      this.params = ArticleEditor.extend(!0, this.defaults, i), this._send(t, e);
    },
    setPlaceholder: function (t) {
      this.$placeholder.html(this.lang.parse(t));
    },
    complete: function (t, e) {
      this._complete(t, e);
    },
    _buildInput: function () {
      this.$input = this.dom("<input>"), this.$input.attr("type", "file"), this.$input.attr("name", this._getUploadParam()), this.$input.hide(), this.params.multiple && this.$input.attr("multiple", "multiple"), "image" === this.params.type && this.$input.attr("accept", "image/*"), this.$element.before(this.$input);
    },
    _buildPlaceholder: function () {
      this.$placeholder = this.dom("<span>"), this.$placeholder.addClass("arx-popup-upload-placeholder"), this.$placeholder.html(this.lang.get("upload.placeholder")), this.$element.append(this.$placeholder);
    },
    _buildEvents: function () {
      this.$input.on("change.arx-upload-" + this.uuid, this._change.bind(this)), this.$element.on("click.arx-upload-" + this.uuid, this._click.bind(this)), this.$element.on("drop.arx-upload-" + this.uuid, this._drop.bind(this)), this.$element.on("dragover.arx-upload-" + this.uuid, this._dragover.bind(this)), this.$element.on("dragleave.arx-upload-" + this.uuid, this._dragleave.bind(this));
    },
    _click: function (t) {
      t.preventDefault(), this.$input.click();
    },
    _change: function (t) {
      this._send(t, this.$input.get().files);
    },
    _drop: function (t) {
      t.preventDefault(), this._send(t);
    },
    _dragover: function (t) {
      return t.preventDefault(), this._setStatus("hover"), !1;
    },
    _dragleave: function (t) {
      return t.preventDefault(), this._removeStatus(), !1;
    },
    _setStatus: function (t) {
      this._removeStatus(), this.$element.addClass("arx-upload-" + t);
    },
    _removeStatus: function () {
      for (var t = ["hover", "error"], e = 0; e < t.length; e++) this.$element.removeClass("arx-upload-" + t);
    },
    _getUploadParam: function () {
      return this.params.name;
    },
    _send: function (t, e) {
      e = e || t.dataTransfer.files;
      var i = new FormData(),
        s = this._getUploadParam(),
        r = this.app.create("utils");
      i = this._buildData(s, e, i), i = r.extendData(i, this.params.data), this._sendData(t, e, i);
    },
    _sendData: function (e, i, s) {
      "function" == typeof this.params.url ? this.params.url.call(this.app, this, {
        data: s,
        files: i,
        e: e
      }) : (this._showProgress(), this.ajax.post({
        url: this.params.url,
        data: s,
        before: function (t) {
          if (this.app.broadcast("upload.before.send", {
            xhr: t,
            data: s,
            files: i,
            e: e
          }).isStopped()) return this._hideProgress(), !1;
        }.bind(this),
        success: function (t) {
          this._complete(t, e);
        }.bind(this)
      }));
    },
    _buildData: function (t, e, i) {
      if (1 === e.length) i.append(t + "[]", e[0]);else if (1 < e.length && this.params.multiple) for (var s = 0; s < e.length; s++) i.append(t + "[]", e[s]);
      return i;
    },
    _complete: function (t, e) {
      t && t.error ? this.params.error && (this.app.broadcast("upload.error", {
        response: t
      }), this.app.api(this.params.error, t, e)) : this.params.success && (this.app.broadcast("upload.complete", {
        response: t
      }), this.app.api(this.params.success, t, e)), setTimeout(this._hideProgress.bind(this), 500), this.$element && (this.$input.off(".arx-upload-" + this.uuid), this.$element.off(".arx-upload-" + this.uuid));
    },
    _showProgress: function (t) {
      this._hideProgress(), this.$progress = this.dom("<div>"), this.$progress.attr("id", "article-progress"), this.$progress.addClass("article-editor-progress"), this.$progressBar = this.dom("<span>"), this.$progress.append(this.$progressBar), this.app.$body.append(this.$progress);
    },
    _hideProgress: function (t) {
      this.app.$body.find("#article-progress").remove();
    }
  }), ArticleEditor.add("service", "utils", {
    init: function () {
      this.scrolltop = !1, this.codemirror = !1;
    },
    isMobile: function () {
      return /(iPhone|iPod|Android)/.test(navigator.userAgent);
    },
    getCodemirror: function () {
      return this.codemirror;
    },
    createCodemirror: function (t, e, i) {
      var s = this._isCodemirror(e);
      s && (this.codemirror = CodeMirror.fromTextArea(t.get(), s), i && this.codemirror.setSize(null, i));
    },
    getCodemirrorValue: function (t, e) {
      return this._isCodemirror(e) && (t = this.codemirror.getValue(), this.codemirror.toTextArea()), t;
    },
    getCodemirrorValueSoft: function (t, e) {
      return this._isCodemirror(e) && (t = this.codemirror.getValue()), t;
    },
    createPlaceholder: function (e) {
      var t = this.app.create("button"),
        i = this.dom("<span>");
      i.addClass("arx-plus-button"), e.addClass("arx-empty-layer"), e.append(i);
      i.on("click.arx-button-plus-" + this.uuid, function (t) {
        t.preventDefault(), this.app.block.set(e);
      }.bind(this)), t.set(i, "addempty", {
        popup: {
          name: "addbar",
          width: "400px",
          builder: "toolbar.addbar"
        }
      }, "popup");
    },
    saveScroll: function () {
      this.scrolltop = this.getScrollTarget().scrollTop();
    },
    restoreScroll: function () {
      !1 !== this.scrolltop && (this.getScrollTarget().scrollTop(this.scrolltop), this.scrolltop = !1);
    },
    isScrollTarget: function () {
      return this.opts.editor.scrollTarget !== document;
    },
    getScrollTarget: function () {
      return this.dom(this.opts.editor.scrollTarget);
    },
    scrollToElement: function (t, e) {
      if (!this.isElementVisible(t)) {
        e = e || 60;
        var i = t.offset(),
          s = this.getScrollTarget(),
          r = i.top - e;
        s.scrollTop(r), setTimeout(function () {
          s.scrollTop(r);
        }.bind(this), 1);
      }
    },
    isElementVisible: function (t, e) {
      var i = this.dom(t),
        s = this.app.$win.scrollTop(),
        r = s + this.app.$win.height(),
        o = i.offset().top,
        a = o + i.height();
      return e = e || 0, s <= a && o <= r && a <= r + e && s <= o;
    },
    isEmpty: function (t) {
      return !!(t = this.dom(t).get()) && (3 === t.nodeType ? "" === t.textContent.trim().replace(/\n/, "") : "" === t.innerHTML);
    },
    isEmptyHtml: function (t, e) {
      return "" === (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = (t = this.removeInvisibleChars(t)).replace(/&nbsp;/gi, "")).replace(/<\/?br\s?\/?>/g, e ? "br" : "")).replace(/\s/g, "")).replace(/^<p>[^\W\w\D\d]*?<\/p>$/i, "")).replace(/^<div>[^\W\w\D\d]*?<\/div>$/i, "")).replace(/<hr(.*?[^>])>$/i, "hr")).replace(/<iframe(.*?[^>])>$/i, "iframe")).replace(/<source(.*?[^>])>$/i, "source")).replace(/<[^\/>][^>]*><\/[^>]+>/gi, "")).replace(/<[^\/>][^>]*><\/[^>]+>/gi, "")).trim());
    },
    isEmptyOrImageInline: function (t) {
      var e = this.dom(t).get();
      if (!e || 3 === e.nodeType) return !1;
      var i = e.tagName.toLowerCase(),
        s = "false" === e.getAttribute("contenteditable"),
        r = this.isInline(e);
      return !!(r && this.isEmpty(e) || r && s || -1 !== ["svg", "img"].indexOf(i));
    },
    isInlineTag: function (t, e) {
      return this._isTag(t) && this._isInlineTag(t, e);
    },
    isBlockTag: function (t, e) {
      return this._isTag(t) && this._isBlockTag(t, e);
    },
    isListTag: function (t) {
      return -1 !== ["ul", "ol"].indexOf(t.toLowerCase());
    },
    isHeadingTag: function (t) {
      return -1 !== ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(t.toLowerCase());
    },
    isText: function (t) {
      return "string" == typeof t && !/^\s*<(\w+|!)[^>]*>/.test(t) || this.isTextNode(t);
    },
    isTextNode: function (t) {
      var e = this.dom(t).get();
      return e && e.nodeType && 3 === e.nodeType;
    },
    isElement: function (t) {
      var e = this.dom(t).get();
      return this._isElement(e);
    },
    isInline: function (t) {
      var e = this.dom(t).get();
      return this._isElement(e) && this._isInlineTag(e.tagName);
    },
    isBlock: function (t) {
      var e = this.dom(t).get();
      return this._isElement(e) && this._isBlockTag(e.tagName);
    },
    createInvisibleChar: function () {
      return document.createTextNode(this.opts.markerChar);
    },
    searchInvisibleChars: function (t) {
      return t.search(/^\uFEFF$/g);
    },
    removeInvisibleChars: function (t) {
      return t.replace(/\uFEFF/g, "");
    },
    capitalize: function (t) {
      return (t = t.toLowerCase()).charAt(0).toUpperCase() + t.slice(1);
    },
    escapeRegExp: function (t) {
      return t.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
    },
    getRandomId: function () {
      for (var t = "", e = "abcdefghijklmnopqrstuvwxyz0123456789", i = 0; i < 12; i++) t += e.charAt(Math.floor(Math.random() * e.length));
      return t;
    },
    createWrapper: function (t) {
      var e = this.dom("<div>");
      return e.html(t), e;
    },
    getWrapperHtml: function (t) {
      var e = t.html();
      return t.remove(), e;
    },
    createTmpContainer: function (t) {
      var e = this.dom("<div>");
      return "string" == typeof t ? e.html(t) : e.append(this.dom(t).clone(!0)), e.get();
    },
    createFragment: function (t) {
      for (var e, i, s, r = this.createTmpContainer(t), o = document.createDocumentFragment(), a = [], n = 0; e = r.firstChild;) {
        n++;
        var l = o.appendChild(e);
        1 === n && (i = l), a.push(l), s = l;
      }
      return {
        frag: o,
        first: i,
        last: s,
        nodes: a
      };
    },
    isFragment: function (t) {
      return "object" == typeof t && t.frag;
    },
    removeEmptyAttr: function (t, e) {
      var i = this.dom(t);
      return void 0 === i.attr(e) || null === i.attr(e) || "" === i.attr(e) && (i.removeAttr(e), !0);
    },
    cloneAttributes: function (t, e) {
      t = this.dom(t).get(), e = this.dom(e);
      for (var i = t.attributes, s = i.length; s--;) {
        var r = i[s];
        e.attr(r.name, r.value);
      }
      return e;
    },
    replaceToTag: function (t, r) {
      var o = this;
      return this.dom(t).replaceWith(function (t) {
        var e = o.dom("<" + r + ">").append(o.dom(t).contents());
        if (t.attributes) for (var i = t.attributes, s = 0; s < i.length; s++) e.attr(i[s].nodeName, i[s].value);
        return e;
      });
    },
    splitNode: function (t) {
      var e = this.app.create("selection").get();
      e.collapsed || e.range.deleteContents();
      var i = this.dom(t),
        s = i.get(),
        r = s.tagName.toLowerCase(),
        o = this.extractHtmlFromCaret(s),
        a = this.dom("<" + r + " />");
      return a = this.cloneAttributes(s, a), i.after(a.append(o)), a;
    },
    extractHtmlFromCaret: function (t) {
      var e = this.dom(t).get(),
        i = this.app.create("selection").get();
      if (i.range) {
        var s = i.range.cloneRange();
        return s.selectNodeContents(e), s.setStart(i.range.endContainer, i.range.endOffset), s.extractContents();
      }
    },
    removeFromArrayByValue: function (t, e) {
      var i;
      e = Array.isArray(e) ? e : [e];
      for (var s = 0; s < e.length; s++) -1 < (i = t.indexOf(e[s])) && t.splice(i, 1);
      return t;
    },
    sumOfArray: function (t) {
      return t.reduce(function (t, e) {
        return parseInt(t) + parseInt(e);
      }, 0);
    },
    sanitize: function (t) {
      var e = this.dom("<div>");
      return e.html(t), e.find("[src]").each(function (t) {
        var e = t.getAttribute("src");
        -1 !== e.search(/^data:/i) && t.setAttribute("src", ""), -1 !== e.search(/^javascript:/i) && t.setAttribute("src", "");
      }), e.find("a").each(function (t) {
        var e = t.getAttribute("href");
        e && -1 !== e.search(/^javascript:/i) && t.setAttribute("href", "");
      }), e.find("svg, img").each(function (t) {
        t.removeAttribute("onload");
      }), t = e.html(), e.remove(), t;
    },
    escapeHtml: function (t) {
      return String(t).replace(/"/g, "&quot;").replace(/'/g, "&#39;").replace(/</g, "&lt;").replace(/>/g, "&gt;");
    },
    extendData: function (o, t) {
      for (var e in t) {
        if ("elements" === e) this.dom(t[e]).each(function (t) {
          var e = this.dom(t);
          if ("FORM" === t.tagName) {
            var i = e.serialize(!0);
            for (var s in i) o = this._setData(o, s, i[s]);
          } else {
            var r = e.attr("name") ? e.attr("name") : e.attr("id");
            o = this._setData(o, r, e.val());
          }
        }.bind(this));else o = this._setData(o, e, t[e]);
      }
      return o;
    },
    _isElement: function (t) {
      return t && t.nodeType && 1 === t.nodeType;
    },
    _isBlockTag: function (t, e) {
      return -1 !== this._extendTags(this.opts.tags.block, e).indexOf(t.toLowerCase());
    },
    _isInlineTag: function (t, e) {
      return -1 !== this._extendTags(this.opts.tags.inline, e).indexOf(t.toLowerCase());
    },
    _isTag: function (t) {
      return void 0 !== t && t;
    },
    _extendTags: function (t, e) {
      if (t = t.concat(t), e) for (var i = 0; i < e.length; i++) t.push(e[i]);
      return t;
    },
    _setData: function (t, e, i) {
      return t instanceof FormData ? t.append(e, i) : t[e] = i, t;
    },
    _isCodemirror: function (t) {
      var e;
      return t && t.hasOwnProperty("codemirror") || (t = this.opts), "object" == typeof t && t.hasOwnProperty("codemirror") && t.codemirror && (e = "object" == typeof t.codemirror ? t.codemirror : {}), e;
    }
  }), ArticleEditor.add("class", "block.cell", {
    mixins: ["block"],
    type: "cell",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          align: {
            type: "group",
            observer: "block.observe",
            builder: "block.buildPopup",
            title: "## buttons.align ##"
          }
        }
      },
      table: {
        title: "## buttons.table ##",
        popup: {
          name: "table",
          list: !0,
          cell: {
            type: "group",
            builder: "table.buildPopup",
            title: "## buttons.cell ##"
          },
          row: {
            type: "group",
            builder: "table.buildPopup",
            title: "## buttons.row ##"
          }
        }
      },
      config: {
        title: "## buttons.config ##",
        command: "table.buildConfig"
      }
    },
    getWidth: function () {
      var t = this.$block.attr("width");
      return t || "";
    },
    getNowrap: function () {
      return "nowrap" === this.$block.css("white-space");
    },
    setWidth: function (e) {
      this._eachCell(function (t) {
        "" === e ? t.removeAttr("width") : t.attr("width", e);
      });
    },
    setNowrap: function (e) {
      this._eachCell(function (t) {
        e = e ? "nowrap" : "", t.css("white-space", e);
      });
    },
    handleEnter: function (t, e, i, s) {
      return this.isSelectedAll() ? s.makeEmpty(this) : i.range && !i.collapsed ? s.deleteContents(i.range) : s.insertBreakline();
    },
    handleArrow: function (t, e, i, s) {
      var r = this.app.create("caret"),
        o = this.getParent("table"),
        a = r.is("start", o),
        n = r.is("end", o);
      if (a && e.is("up-left") || n && e.is("down-right")) return this.app.block.set(o);
      if (!a && this.isStart() && e.is("up-left")) {
        var l = this.app.block.prev();
        if (l) return this.app.block.set(l.$block);
        var p = this.$block.parent().prevElement();
        if (0 !== p.length) {
          var c = p.children("td, th").last();
          return this.app.block.set(c, "end");
        }
      } else if (!n && this.isEnd() && e.is("down-right")) {
        var h = this.app.block.next();
        if (h) return this.app.block.set(h.$block);
        var d = this.$block.parent().nextElement();
        if (0 !== d.length) {
          c = d.children("td, th").first();
          return this.app.block.set(c, "start");
        }
      }
    },
    _eachCell: function (s) {
      var r = 0,
        o = this.app.create("content"),
        t = this.$block.closest("table");
      this.$block.closest("tr").find("td, th").each(function (t, e) {
        t === this.$block.get() && (r = e);
      }.bind(this)), t.find("tr").each(function (t) {
        var e = this.dom(t).find("td, th").get(r),
          i = this.dom(e);
        s(i), o.cacheBlocksStyle(i);
      }.bind(this));
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<td>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.code", {
    mixins: ["block"],
    type: "code",
    buttons: {},
    _parse: function (t) {
      return void 0 === t ? this.dom(this.opts.code.template) : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.column", {
    mixins: ["block"],
    type: "column",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          align: {
            type: "group",
            observer: "block.observe",
            builder: "block.buildPopup",
            title: "## buttons.align ##"
          }
        }
      }
    },
    handleArrow: function (t, e, i, s) {
      var r = this.getParent("[data-arx-type=grid]");
      return this.app.block.set(r), !0;
    },
    _parse: function (t) {
      return this.dom(t);
    }
  }), ArticleEditor.add("class", "block.embed", {
    mixins: ["block"],
    type: "embed",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          }
        }
      }
    },
    _parse: function (t) {
      return this.dom(t);
    }
  }), ArticleEditor.add("class", "block.figcaption", {
    mixins: ["block"],
    type: "figcaption",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          align: {
            type: "group",
            observer: "block.observe",
            builder: "block.buildPopup",
            title: "## buttons.align ##"
          }
        }
      }
    },
    handleEnter: function (t, e, i, s) {
      return !(!this.isStart() && !this.isEnd()) || (i.collapsed || t.shiftKey || t.ctrlKey ? s.insertBreakline() : this.isSelectedAll() ? s.makeEmpty(this) : !i.range || s.deleteContents(i.range));
    },
    handleArrow: function (t, e, i, s) {
      if (e.is("up-left") && this.isStart() || e.is("down-right") && this.isEnd()) {
        var r = this.getParent("figure");
        return this.app.block.set(r), !0;
      }
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<figcaption>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.grid", {
    mixins: ["block"],
    type: "grid",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          valign: {
            type: "group",
            observer: "block.observe",
            builder: "block.buildPopup",
            title: "## buttons.valign ##"
          },
          removegrid: {
            title: "## buttons.transform-to-text ##",
            command: "grid.transform"
          }
        }
      }
    },
    _parse: function (t) {
      var e = this.dom(t);
      return this.opts.grid && this.opts.grid.overlay && e.addClass("arx-grid-overlay"), e;
    }
  }), ArticleEditor.add("class", "block.heading", {
    mixins: ["block"],
    type: "heading",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          block: {
            type: "list",
            observer: "block.observe",
            builder: "block.buildPopup"
          },
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          }
        }
      }
    },
    getName: function () {
      var t = "",
        e = this.getTag(),
        i = this.opts.format;
      return t = void 0 !== i[e] ? (t = i[e].title.replace(/(<([^>]+)>)/gi, ""), this.lang.parse(t)) : (i = this.lang.get("headings"))[e];
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<h1>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.image", {
    mixins: ["block"],
    type: "image",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          },
          outset: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.outset ##"
          }
        }
      },
      config: {
        title: "## buttons.config ##",
        command: "image.buildConfig"
      }
    },
    getAlt: function () {
      var t = this.getImage().attr("alt");
      return t || "";
    },
    getLink: function () {
      var t = !1,
        e = this._getLink();
      return e && (t = {
        url: e.attr("href"),
        target: e.attr("target")
      }), t;
    },
    setAlt: function (t) {
      this.getImage().attr("alt", t);
    },
    setLink: function (t) {
      var e = this._getLink();
      if (!e) {
        var i = this.getImage();
        e = this.dom("<a>"), i.wrap(e);
      }
      e.attr("href", t.url), !1 === t.target ? e.removeAttr("target") : e.attr("target", !0 === t.target ? "_blank" : t.target);
    },
    setImage: function (t) {
      var e = this.getImage();
      e.attr("src", t.url), t.hasOwnProperty("id") && e.attr("data-image", t.id);
    },
    removeLink: function () {
      var t = this._getLink();
      t && t.unwrap();
    },
    getImage: function () {
      return this.$block.find("img");
    },
    _getLink: function () {
      var t = this.getImage().parent();
      return "A" !== t.get().tagName && (t = !1), t;
    },
    _parse: function (t) {
      return this.dom(t);
    }
  }), ArticleEditor.add("class", "block.layer", {
    mixins: ["block"],
    type: "layer",
    buttons: {},
    _parse: function (t) {
      var e;
      if (void 0 === t) {
        var i = this.app.create("content").parse(this.opts.layer.template);
        (e = this.dom(i)).addClass("arx-empty-layer");
      } else e = this.dom(t);
      return e;
    }
  }), ArticleEditor.add("class", "block.line", {
    mixins: ["block"],
    type: "line",
    buttons: {},
    _parse: function (t) {
      return void 0 === t ? this.dom("<hr>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.list", {
    mixins: ["block"],
    type: "list",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          block: {
            type: "list",
            observer: "block.observe",
            builder: "block.buildPopup"
          },
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          }
        }
      },
      indent: {
        title: "&gt; ## list.indent ##",
        command: "list.indent"
      },
      outdent: {
        title: "&lt; ## list.outdent ##",
        command: "list.outdent"
      }
    },
    isEmpty: function () {
      var t = this.$block.children("li"),
        e = this.app.create("utils");
      return 1 === t.length && e.isEmptyHtml(t.eq(0).html());
    },
    empty: function () {
      var t = this.dom("<li>");
      return this.$block.html("").append(t), t;
    },
    createItem: function (t, e, i) {
      e = e || "after";
      var s = this.dom("<li></li>");
      t[e](s), !1 !== i && this.app.create("caret").set("start", s);
      return s;
    },
    splitItem: function (t) {
      var e = this.app.create("caret"),
        i = this.app.create("utils"),
        s = i.splitNode(t),
        r = s.clone();
      return r.find("ol, ul").remove(), i.isEmptyHtml(r.html()) && s.prepend("&nbsp;"), e.set("start", s), !0;
    },
    handleEnter: function (t, e, i, s) {
      var r = this.app.create("caret"),
        o = this.app.create("selection"),
        a = this.dom(o.getBlock()),
        n = (a.prev(), r.is("start", a)),
        l = r.is("end", a);
      if (t.shiftKey || t.ctrlKey) return s.insertBreakline();
      if (this.isSelectedAll()) return s.makeEmpty(this);
      if (this.isEnd() || l) {
        var p = this.app.create("utils"),
          c = this.createItem(a);
        if (this.isEnd()) {
          var h = c.prev(),
            d = c.parents("ul, ol", this.$block).last(),
            u = p.isEmptyHtml(c.html()) && p.isEmptyHtml(h.html());
          if (0 !== h.length && u) {
            if (0 === d.length) {
              c.remove(), h.remove();
              var m = this.app.block.create();
              return this.app.block.add(m), !0;
            }
            var f = c.parents("li", this.$block).first();
            return c.remove(), h.remove(), this.createItem(f);
          }
        }
        return !0;
      }
      return this.isStart() || n ? this.createItem(a, "before", !1) : i.collapsed ? this.splitItem(a) : !(i.range && !i.collapsed) || s.deleteContentsAndCollapse(i.range);
    },
    handleDelete: function (t, e, i, s) {
      var r = ["paragraph", "heading", "text"],
        o = this.app.block.next(),
        a = this.app.block.prev(),
        n = this.app.create("caret"),
        l = this.app.create("utils"),
        p = this.app.create("selection"),
        c = this.dom(p.getBlock());
      if (this.isSelectedAll()) return s.makeEmpty(this);
      if (!this.isStart() && !this.isEnd() && e.is("backspace") && l.isEmptyHtml(c.html())) {
        var h = c.prev();
        return n.set("end", h), c.remove(), !0;
      }
      if (a && -1 !== r.indexOf(a.getType()) && this.isStart() && e.is("backspace")) {
        n.set("end", a.$block), (u = this.$block.find("li").first()).find("ul, ol, li").unwrap();
        var d = u.html();
        return a.$block.append(d), u.remove(), this.app.block.set(a.$block), !0;
      }
      if (o && -1 !== r.indexOf(o.getType()) && this.isEnd() && e.is("delete")) {
        var u;
        d = o.$block.html();
        return (u = this.$block.find("li").last()).append(d), o.remove(!1), !0;
      }
    },
    _parse: function (t) {
      var e;
      return void 0 === t ? (e = this.dom("<ul>")).append("<li></li>") : e = this.dom(t), e;
    }
  }), ArticleEditor.add("class", "block.noneditable", {
    mixins: ["block"],
    type: "noneditable",
    buttons: {},
    _parse: function (t) {
      var e;
      return void 0 === t ? (e = this.dom("<div>")).addClass(this.opts.noneditable.classname) : e = this.dom(t), e;
    }
  }), ArticleEditor.add("class", "block.paragraph", {
    mixins: ["block"],
    type: "paragraph",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          block: {
            type: "list",
            observer: "block.observe",
            builder: "block.buildPopup"
          },
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          }
        }
      }
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<p>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.quote", {
    mixins: ["block"],
    type: "quote",
    buttons: {},
    _parse: function (t) {
      var e;
      if (void 0 === t) {
        var i = this.app.create("content").parse(this.opts.quote.template);
        e = this.dom(i);
      } else e = this.dom(t);
      return e;
    }
  }), ArticleEditor.add("class", "block.quoteitem", {
    mixins: ["block"],
    type: "quoteitem",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          align: {
            type: "group",
            builder: "block.buildPopup",
            observer: "block.observe",
            title: "## buttons.align ##"
          }
        }
      }
    },
    handleArrow: function (t, e, i, s) {
      var r = this.getParent("[data-arx-type=quote]");
      if (this.isStart() && e.is("up-left")) {
        var o = this.app.block.prev(),
          a = o ? o.$block : r;
        return this.app.block.set(a);
      }
      if (this.isEnd() && e.is("down-right")) {
        var n = this.app.block.next();
        a = n ? n.$block : r;
        return this.app.block.set(a);
      }
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<p>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.row", {
    mixins: ["block"],
    type: "row",
    buttons: {
      table: {
        title: "## buttons.table ##",
        popup: {
          name: "table",
          list: !0,
          row: {
            type: "group",
            builder: "table.buildPopup",
            title: "## buttons.row ##"
          }
        }
      }
    },
    handleEnter: function (t, e, i, s) {
      return !0;
    },
    handleArrow: function (t, e, i, s) {
      var r = this.getParent("table");
      return this.app.block.set(r), !0;
    },
    _parse: function (t) {
      return this.dom(t);
    }
  }), ArticleEditor.add("class", "block.snippet", {
    mixins: ["block"],
    type: "snippet",
    buttons: {},
    _parse: function (t) {
      return this.dom(t);
    }
  }), ArticleEditor.add("class", "block.table", {
    mixins: ["block"],
    type: "table",
    buttons: {
      table: {
        title: "## buttons.table ##",
        popup: {
          name: "table",
          list: !0,
          head: {
            type: "group",
            builder: "table.buildPopup",
            title: "## buttons.head ##"
          }
        }
      }
    },
    _parse: function (t) {
      return void 0 === t ? this.dom("<table>") : this.dom(t);
    }
  }), ArticleEditor.add("class", "block.text", {
    mixins: ["block"],
    type: "text",
    buttons: {
      format: {
        title: "## buttons.format ##",
        popup: {
          name: "format",
          list: !0,
          inline: {
            type: "list",
            observer: "inline.observe",
            builder: "inline.buildPopup"
          },
          link: {
            type: "list",
            observer: "link.observe",
            builder: "link.buildPopup"
          },
          block: {
            type: "list",
            observer: "block.observe",
            builder: "block.buildPopup"
          },
          align: {
            type: "group",
            observer: "block.observe",
            builder: "block.buildPopup",
            title: "## buttons.align ##"
          }
        }
      }
    },
    _parse: function (t) {
      var e;
      return void 0 === t ? (e = this.dom("<div>")).addClass(this.opts.plaintext.classname) : e = this.dom(t), e;
    }
  }), window.ArticleEditor = ArticleEditor, window.addEventListener("load", function () {
    ArticleEditor("[data-article-editor]");
  }), "object" == typeof module && module.exports && (module.exports = ArticleEditor, module.exports.ArticleEditor = ArticleEditor);
}();
export default ArticleEditor;