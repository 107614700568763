var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.embeded ? _c("div", {
    class: {
      "has-logo": _vm.showLogo
    }
  }, [_vm.showLogo ? _c("logo", {
    attrs: {
      collapse: _vm.isCollapse
    }
  }) : _vm._e(), _vm._v(" "), _c("el-scrollbar", {
    key: "k" + _vm.menuKey,
    attrs: {
      "wrap-class": "scrollbar-wrapper"
    }
  }, [_c("el-menu", {
    staticStyle: {
      height: "100%",
      position: "relative",
      "padding-bottom": "150px"
    },
    attrs: {
      "default-active": _vm.activeMenu,
      collapse: _vm.isCollapse,
      "background-color": _vm.variables.menuBg,
      "text-color": _vm.variables.menuText,
      "unique-opened": false,
      "active-text-color": _vm.variables.menuActiveText,
      "collapse-transition": false,
      mode: "vertical"
    }
  }, _vm._l(_vm.routerSchema, function (route, i) {
    return _vm.me && (_vm.me && _vm.me.profile == "guest" && route.isGuestView || _vm.me.profile != "guest") ? _c("sidebar-item", {
      key: i + route.path,
      class: route.class ? route.class : "",
      attrs: {
        item: route,
        "base-path": route.path
      }
    }) : _vm._e();
  }), 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };