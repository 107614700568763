import KvModal from '@/components/kiva/KvModal';
import KivaProxy from '@/utils/kivaProxy/src';
import { CannedResponseTransformer } from '@/transformers/CannedResponseTransformer';
export default {
  name: 'CannedResponseModal',
  components: {
    KvModal: KvModal
  },
  props: {
    value: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      filters: {
        limit: 50
      },
      currentPage: 1,
      mutableValue: this.value,
      cannedResponses: [],
      total: 0
    };
  },
  created: function created() {
    this.getCannedResponses();
  },
  methods: {
    handleSelect: function handleSelect(row) {
      this.$emit('select', row);
      this.mutableValue = false;
    },
    getCannedResponses: function getCannedResponses() {
      var _this = this;
      new KivaProxy({
        endpoint: 'inbox/canned_response',
        parameters: this.mappedParams
      }).setFields(['content', 'created_on', 'record_id', 'record_owner', 'subject']).all().then(function (response) {
        _this.loading = false;
        _this.total = response.total;
        _this.cannedResponses = CannedResponseTransformer.fetchCollection(response.records);
      });
    }
  },
  computed: {
    mappedParams: function mappedParams() {
      return {
        start: this.currentPage > 1 ? (this.currentPage - 1) * this.filters.limit : 0,
        limit: this.filters.limit
      };
    }
  },
  watch: {
    value: function value(val) {
      this.mutableValue = val;
    },
    mutableValue: function mutableValue(val) {
      if (!val) {
        this.$emit('close');
      }
      this.$emit('input', val);
    }
  }
};