import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _HELP_DRAWER$CURRENCI;
import { LOCALES, CURRENCIES, HELP_DRAWER, LANGUAGES, TIME_ZONES, TIME_ENTRIES, CRM_CURRENCIES, PROFILES, PREFERENCES, FAVORITES, GROUPS, INBOX_PREFERENCES, ACTIVITY_STATUS, MY_ACTIVITY_STATUS } from '@/store/modules/common/mutation-types';
export default (_HELP_DRAWER$CURRENCI = {}, _defineProperty(_HELP_DRAWER$CURRENCI, HELP_DRAWER, {
  visible: false,
  path: ''
}), _defineProperty(_HELP_DRAWER$CURRENCI, CURRENCIES, []), _defineProperty(_HELP_DRAWER$CURRENCI, TIME_ZONES, []), _defineProperty(_HELP_DRAWER$CURRENCI, TIME_ENTRIES, []), _defineProperty(_HELP_DRAWER$CURRENCI, LANGUAGES, []), _defineProperty(_HELP_DRAWER$CURRENCI, LOCALES, []), _defineProperty(_HELP_DRAWER$CURRENCI, CRM_CURRENCIES, []), _defineProperty(_HELP_DRAWER$CURRENCI, PROFILES, []), _defineProperty(_HELP_DRAWER$CURRENCI, PREFERENCES, null), _defineProperty(_HELP_DRAWER$CURRENCI, GROUPS, []), _defineProperty(_HELP_DRAWER$CURRENCI, ACTIVITY_STATUS, []), _defineProperty(_HELP_DRAWER$CURRENCI, INBOX_PREFERENCES, null), _defineProperty(_HELP_DRAWER$CURRENCI, FAVORITES, {
  1: [],
  2: [],
  3: []
}), _HELP_DRAWER$CURRENCI);