import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.find.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { CURRENT_USER, UX_PROFILE, MY_UX_PROFILE, USERS, USERTOTAL } from '@/store/modules/users/mutation-types';
import { UserTransformer } from '@/transformers/UserTransformer';
import Cookies from 'js-cookie';
import i18n from '../../../lang'; // internationalization

export default {
  getUserUxProfile: function getUserUxProfile(_ref) {
    var commit = _ref.commit,
      state = _ref.state;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'kvc/user/ux_profile',
        driver: 'base'
      }).setFilters({
        product: [{
          'product': ["EMPTY"]
        }, {
          'product': ["IN", ['sales', 'invoice', 'projects', 'desk']]
        }]
      }).all().then(function (response) {
        commit(UX_PROFILE, response.records);
        // console.log("response",response.records,state.CURRENT_USER.uxProfiles)
        var myUxProfile = response.records.find(function (k) {
          return k.record_id == state.CURRENT_USER.uxProfiles;
        });
        // let myUxProfile = response.records.find(k=> k.record_id == 1)
        // console.log("myUxProfile",myUxProfile)
        commit(MY_UX_PROFILE, myUxProfile);
        resolve(response.records);
      }).catch(reject);
    });
  },
  getCurrentUser: function getCurrentUser(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'kvc/user/me',
        driver: 'base'
      }).all().then(function (response) {
        var lang = response.language.substring(0, 2);
        i18n.locale = lang;
        //  console.log("response.language",lang)
        Cookies.set("language", lang);
        var user = UserTransformer.fetch(response);
        commit(CURRENT_USER, user);
        resolve(user);
      }).catch(reject);
    });
  },
  getUsers: function getUsers(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'kvc/user',
        driver: 'base'
      }).setLimit(1000).all().then(function (response) {
        commit(USERS, UserTransformer.fetchCollection(response.records));
        commit(USERTOTAL, response.total);
        resolve();
      });
    });
  },
  setCurrentUser: function setCurrentUser(_ref4, data) {
    var commit = _ref4.commit;
    commit(CURRENT_USER, data);
  }
};