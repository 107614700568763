import _toConsumableArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("transition", {
    attrs: {
      name: "modal"
    }
  }, [_c("div", {
    staticClass: "modal-mask",
    class: _vm.smallSizeBox ? "minimized" : "",
    style: {
      "z-index": this.$store.state.tasks.popup.zindex
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.smallSizeBox,
      expression: "smallSizeBox"
    }],
    staticClass: "margin-left-5",
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("a", {
    staticClass: "kv-badge cursor-pointer text-soft-gray",
    attrs: {
      title: _vm.$t("Open minimized window")
    },
    on: {
      click: function click($event) {
        _vm.smallSizeBox = false;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-expand-alt"
  })])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.smallSizeBox,
      expression: "!smallSizeBox"
    }],
    staticClass: "modal-wrapper",
    staticStyle: {
      position: "relative"
    }
  }, [_c("div", {
    key: _vm.modal_key,
    staticClass: "task-edit-modal pt-0 pos-rel",
    attrs: {
      id: "commentsContainer1"
    }
  }, [_c("div", {
    staticClass: "fixed_header"
  }, [_c("el-row", {
    attrs: {
      gutters: "20"
    }
  }, [_c("el-col", {
    staticClass: "mb-2",
    attrs: {
      span: 16
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_c("el-button", {
    staticStyle: {
      "min-width": "120px",
      "text-align": "left"
    },
    attrs: {
      plain: "",
      type: !_vm.hasChanged && _vm.taskData.record_id && (!_vm.failed || _vm.$store.state.response_alert.status) ? "info" : "primary",
      disabled: !_vm.hasChanged && _vm.taskData.record_id && !_vm.failed || !_vm.taskData.record_id && !_vm.taskData.subject,
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.hasChanged = true;
        _vm.save_task_first_time("standart");
      }
    }
  }, [!_vm.saving || _vm.hasChanged ? _c("span", {
    key: "fasave"
  }, [_c("i", {
    class: "fa fa-save"
  })]) : _vm._e(), _vm._v(" "), _vm.saving ? _c("span", {
    key: "iconloading"
  }, [_c("i", {
    class: "el-icon-loading fa-2x kv--color"
  })]) : _vm._e(), _vm._v("\n                       \n                      " + _vm._s(_vm.saving ? _vm.$t("Saving") : !_vm.saving && _vm.$store.state.response_alert.status || _vm.taskData.record_id && !_vm.hasChanged ? _vm.$t("Saved") : _vm.$t("Save")) + "\n                    ")]), _vm._v(" "), !_vm.taskData.record_id ? _c("div", {
    staticClass: "button_or"
  }, [_vm._v("or")]) : _vm._e(), _vm._v(" "), !_vm.taskData.record_id ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      disabled: !_vm.taskData.subject,
      plain: ""
    },
    on: {
      click: function click($event) {
        return _vm.save_task_first_time("saveAndCreateNew");
      }
    }
  }, [_c("li", {
    class: "fa fa-save"
  }), _vm._v("\n                        " + _vm._s(_vm.$t("Save & Create New")) + "\n                    ")]) : _vm._e(), _vm._v(" "), _vm.taskData.record_id && _vm.taskData.task_status && _vm.taskData.task_status.value != "3" ? _c("el-button", {
    attrs: {
      type: "success",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_completed();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-check-square"
  }), _vm._v("\n                       \n                      " + _vm._s(_vm.taskData.task_type && _vm.taskData.task_type.value == 1 ? _vm.$t("task.Complete") : _vm.taskData.task_type && _vm.taskData.task_type.value == 3 ? _vm.$t("task.Resolve") : _vm.$t("task.Approve")) + "\n                    ")]) : _vm._e(), _vm._v(" "), _vm.taskData.task_type && _vm.taskData.record_id && _vm.taskData.task_status && _vm.taskData.task_status.value != "3" && _vm.taskData.task_type.value == 2 && _vm.checkTimeEntry(_vm.all_time_entries, _vm.project_type) ? _c("el-button", {
    staticClass: "margin-left-10",
    attrs: {
      type: "warning",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_completed();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-edit"
  }), _vm._v("\n                        " + _vm._s(_vm.$t("task.Revise")) + "\n                    ")]) : _vm._e(), _vm._v(" "), _vm.taskData.task_type && _vm.taskData.record_id && _vm.taskData.task_status && _vm.taskData.task_status.value != "3" && _vm.taskData.task_type.value == 2 && _vm.checkTimeEntry(_vm.all_time_entries, _vm.project_type) ? _c("el-button", {
    staticClass: "margin-left-10",
    attrs: {
      type: "danger",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_completed();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-times"
  }), _vm._v("\n                        " + _vm._s(_vm.$t("task.Decline")) + "\n                    ")]) : _vm._e(), _vm._v(" "), !_vm.editable ? _c("el-button", {
    attrs: {
      type: "purple",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_open();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-check-square"
  }), _vm._v("\n                        " + _vm._s(_vm.$t("Re-Open")) + "\n                    ")]) : _vm._e(), _vm._v(" "), _vm.taskData.task_type ? _c("el-select", {
    staticClass: "margin-left-10 kv--input sub-border",
    attrs: {
      size: "mini",
      placeholder: _vm.$t("Select"),
      disabled: _vm.buttonsDisabled
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.task_type.value,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData.task_type, "value", $$v);
      },
      expression: "taskData.task_type.value"
    }
  }, _vm._l(_vm.typeOptions, function (item) {
    return _c("el-option", {
      key: "opt" + item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "mb-2 align-right text-right",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_c("div", {
    staticClass: "rightSide"
  }, [_vm.taskData.customer_comments_count || _vm.taskData.visible_on_cp ? _c("a", {
    key: "comment_icon",
    staticClass: "kv-badge cursor-pointer with-badge",
    class: {
      "kv-badge-icon": false,
      red: _vm.isOpen
    },
    attrs: {
      "badge-title": _vm.taskData.customer_comments_count ? _vm.taskData.customer_comments_done ? _vm.taskData.customer_comments_done + "/" + _vm.taskData.customer_comments_count : _vm.taskData.customer_comments_count : "-",
      title: _vm.$t("Customer Comments")
    },
    on: {
      click: function click($event) {
        return _vm.openCustomerComments();
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-comments"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-popover", {
    attrs: {
      placement: "bottom",
      width: "300",
      trigger: "click"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.taskData.thread && _vm.taskData.thread.value,
      expression: "taskData.thread && taskData.thread.value"
    }]
  }, [_c("h3", [_vm._v("\n                            #" + _vm._s(_vm.taskData.thread && _vm.taskData.thread.value) + "\n                          ")]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.taskData.thread && _vm.taskData.thread.label))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.editModal = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Change")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger"
    },
    on: {
      click: function click($event) {
        _vm.showMergeThreadModal = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Edit")))])], 1), _vm._v(" "), _vm.taskData.thread && _vm.taskData.thread.value ? _c("a", {
    staticClass: "kv-badge cursor-pointer tet",
    class: {
      "kv-badge-icon": false,
      "text-primary": _vm.taskData.thread && _vm.taskData.thread.value || _vm.taskData.thread && typeof _vm.taskData.thread == "number"
    },
    staticStyle: {
      height: "40px",
      "vertical-align": "middle"
    },
    attrs: {
      slot: "reference",
      title: _vm.taskData.thread.label
    },
    on: {
      click: function click($event) {
        _vm.showMergeThreadModal = true;
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-ring"
    }
  }), _vm._v(" "), _vm.taskData.task_type && _vm.taskData.task_type.value == 1 && _vm.projectData.approvalRequired == 1 && !_vm.taskData.thread.value ? _c("span", {
    staticClass: "req-badge"
  }, [_vm._v("!")]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), !_vm.taskData.thread || !_vm.taskData.thread.value ? _c("a", {
    staticClass: "kv-badge cursor-pointer text-soft-gray",
    attrs: {
      title: _vm.$t("Connect with a thread")
    },
    on: {
      click: function click($event) {
        _vm.showMergeThreadModal = true;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-ring"
    }
  }), _vm._v(" "), _vm.taskData.task_type && _vm.taskData.task_type.value == 1 && _vm.projectData.approvalRequired == 1 && !_vm.taskData.thread.value ? _c("span", {
    staticClass: "req-badge"
  }, [_vm._v("!")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.taskData.noteCount ? _c("el-badge", {
    staticClass: "item badge-item cursor-pointer",
    attrs: {
      value: _vm.taskData.noteCount,
      max: 9,
      type: "warning"
    }
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.scrollTo("comments");
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-comments-alt"
  })])]) : _vm._e(), _vm._v(" "), _vm.taskData.dependency_count && _vm.taskData.dependency_count != "0/0" ? _c("el-badge", {
    staticClass: "item badge-item cursor-pointer",
    attrs: {
      value: _vm.taskData.dependency_count,
      type: "warning"
    }
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.scrollTo("comments");
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-link"
  })])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "margin-left-5",
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("a", {
    staticClass: "kv-badge cursor-pointer text-soft-gray",
    attrs: {
      title: _vm.$t("Minimize task window")
    },
    on: {
      click: function click($event) {
        _vm.smallSizeBox = true;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-compress-alt"
  })])]), _vm._v(" "), _vm.WorkPermission() ? _c("div", {
    staticClass: "margin-left-5",
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("counter-action-button", {
    attrs: {
      row: _vm.taskData,
      type: "TASK",
      showTime: true,
      buttonClass: {
        "no-border": true
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "margin-left-5 pa-5"
  }, [_vm._v(_vm._s(_vm.taskData.record_id ? "#" + _vm.taskData.record_id : ""))]), _vm._v(" "), _c("span", {
    staticClass: "margin-left-5 pa-5 cursor-pointer",
    class: !_vm.saving && !_vm.$store.state.response_alert.status ? "" : "disabled",
    attrs: {
      size: "medium",
      type: "text"
    },
    on: {
      click: function click($event) {
        !_vm.saving ? _vm.set_updates() : "";
      }
    }
  }, [_c("i", {
    key: "fatimes",
    staticClass: "fa fa-lg kv--color fa-times"
  })])], 1)])])], 1)], 1), _vm._v(" "), _c("section", {
    staticClass: "taskForm"
  }, [_c("el-row", [_c("el-col", {
    staticStyle: {
      width: "650px"
    }
  }, [_c("div", {
    staticClass: "taskForm-main kv-scroll"
  }, [_c("div", {
    staticClass: "formItem"
  }, [_c("el-input", {
    staticClass: "kv--input hover-bg font-16 font-weight-600",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      "aria-describedby": "subjectHelp",
      placeholder: _vm.$t("task.Subject")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "subject", $$v);
      },
      expression: "taskData.subject"
    }
  })], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem",
    attrs: {
      row: "",
      wrap: ""
    }
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("labels") ? "" : "margin-bottom-5",
    attrs: {
      span: 24
    }
  }, [_vm._v(_vm._s(_vm.$t("Labels")) + "\n\n                        "), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editable,
      expression: "editable"
    }],
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        _vm.showField("labels");
        _vm.chooseRef("labelField");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more labels to this task")))])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.findVisibility("labels") ? 17 : 24
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.findVisibility("labels"),
      expression: "!findVisibility('labels')"
    }]
  }, [_c("kv-label-input", {
    staticClass: "width-full",
    attrs: {
      app: "act",
      model: "task",
      id: _vm.taskData.record_id,
      placeholder: _vm.$t("Labels")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      },
      isLabelEntered: function isLabelEntered($event) {
        _vm.fieldVisible.labels = $event;
      },
      setUnrecordedLabels: function setUnrecordedLabels($event) {
        _vm.unRecordedLabels = $event;
      }
    }
  })], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("description") ? "" : "margin-bottom-5",
    attrs: {
      span: _vm.findVisibility("description") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("task.Description")) + "\n                      ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.findVisibility("description") ? 17 : 24
    }
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.findVisibility("description") && _vm.editable,
      expression: "findVisibility('description') && editable"
    }],
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        return _vm.showField("description");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more information to this task")))]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.findVisibility("description"),
      expression: "!findVisibility('description')"
    }]
  }, [_c("redactor", {
    staticClass: "margin-top-5 margin-bottom-5 kv-border-default",
    attrs: {
      config: _vm.confingRedactorXTask
    },
    on: {
      input: function input($event) {
        _vm.hasChanged = true;
      }
    },
    model: {
      value: _vm.taskData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "description", $$v);
      },
      expression: "taskData.description"
    }
  })], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle margin-bottom-10",
    attrs: {
      span: _vm.findVisibility("sub_tasks") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("task.Sub Tasks")) + " " + _vm._s(_vm.taskData.sub_tasks) + "\n\t\t\t\t\t\t\t\t\t\t\t \n                      ")]), _vm._v(" "), _vm.taskData.record_owner && _vm.taskData.record_owner.value ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [this.task ? _c("div", {
    key: _vm.taskData.record_owner.value
  }, [_c("sub-tasks", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.subTasksLoading,
      expression: "subTasksLoading"
    }],
    key: "st" + this.task.record_id,
    attrs: {
      new_task: _vm.taskData,
      taskId: this.task.record_id,
      subTasksCreated: _vm.subTasks
    },
    on: {
      subTasks: function subTasks($event) {
        _vm.subTasks = $event;
      }
    }
  })], 1) : _vm._e()]) : _vm._e()], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("documents") ? "" : "margin-bottom-10",
    attrs: {
      span: _vm.findVisibility("documents") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("Documents")) + "\n                      ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.findVisibility("documents") ? 17 : 24
    }
  }, [_vm.findVisibility("documents") && _vm.editable ? _c("span", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        _vm.showField("documents");
        _vm.documents_key = Math.random(100);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more file")))]) : _vm._e()]), _vm._v(" "), _c("el-col", {
    key: "file" + _vm.taskData.record_id,
    staticClass: "ktw-mb-10",
    attrs: {
      span: 24
    }
  }, [_c("attachments", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    key: "f" + _vm.taskData.record_id,
    staticClass: "ktw-mb-10",
    attrs: {
      record_id: _vm.taskData.record_id,
      visibility: _vm.fieldVisible.documents,
      documents_key: _vm.documents_key,
      task: _vm.task,
      app: "act",
      model: "task",
      attachmentsList: _vm.attachmentsList
    },
    on: {
      showAttachments: function showAttachments($event) {
        _vm.fieldVisible.documents = $event;
      },
      updateAttachments: function updateAttachments($event) {
        _vm.attachmentsList = $event;
      }
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("dependency") ? "" : "margin-bottom-5",
    attrs: {
      span: 7
    }
  }, [_vm._v(_vm._s(_vm.$t("Dependency")) + "\n                        " + _vm._s(_vm.taskData.dependency_count > 0 ? _vm.taskData.dependency_count : "") + "\n                      ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 17
    }
  }, [_vm.taskData.record_id && _vm.editable ? _c("span", {
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        return _vm.showField("dependency");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more dependency to this task")))]) : _vm._e(), _vm._v(" "), !_vm.taskData.record_id ? _c("span", {
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        _vm.save_task_first_time_forced();
        _vm.showField("dependency");
        _vm.show_selected_dependency = {};
        _vm.is_show_selected_dependency = true;
        _vm.dependency_key = Math.random(100, 9999);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more dependency")))]) : _vm._e()]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", [_vm.taskData.record_id ? _c("task-form-dependency", {
    attrs: {
      task: _vm.show_selected_dependency,
      dependency_list: _vm.dependency_list,
      task_id: _vm.taskData.record_id,
      dependency_count: _vm.taskData.dependency_count,
      project_id: _vm.taskData.project.value,
      is_show: _vm.is_show_selected_dependency,
      dependency_key: _vm.dependency_key
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.is_show_selected_dependency = $event;
        _vm.time_entry_key = Math.random(9, 99999);
      },
      isDependencyFound: function isDependencyFound($event) {
        _vm.isDependencyFound = $event;
      },
      openTask: function openTask($event) {
        return _vm.$emit("openTask", $event);
      },
      getTimeEntry: function getTimeEntry($event) {
        return _vm.list_time_entries(_vm.taskData.record_id, null, null, "TASK");
      }
    }
  }) : _vm._e()], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "formItem",
    attrs: {
      id: "commentField1"
    }
  }, [_vm.taskData.record_id ? _c("note-list", {
    key: _vm.taskData.record_id,
    attrs: {
      endpoint: "act/task",
      id: _vm.taskData.record_id,
      showAvatar: true,
      formPosition: "bottom",
      formFixed: false,
      formRadiusClass: "only-left-bottom-radius",
      formStyle: {
        width: "100%"
      }
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.taskData && _vm.taskData.created_by && _vm.taskData.created_by.label ? _c("div", {
    staticClass: "footer font-11",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("Created on")) + "\n                        " + _vm._s(_vm._f("datetime")(_vm.taskData.created_on)) + " " + _vm._s(_vm.$t("by")) + "\n                        " + _vm._s(_vm.taskData.created_by.label))]), _vm._v(" "), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _vm._v(" "), _vm.taskData.last_modified_by && _vm.taskData.last_modified_by.label ? _c("span", [_vm._v(_vm._s(_vm.$t("Last Update on")) + "\n                        " + _vm._s(_vm._f("datetime")(_vm.taskData.last_modified_on)) + "\n                        " + _vm._s(_vm.$t("by")) + "\n                        " + _vm._s(_vm.taskData.last_modified_by.label))]) : _vm._e()], 1) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      width: "250px"
    }
  }, [_c("div", {
    staticClass: "taskForm-sidebar kv-scroll"
  }, [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("assignTo")))]), _vm._v(" "), _vm.taskData.record_owner && _vm.$store.state.users.USERTOTAL < 199 ? _c("div", {
    staticClass: "search-box"
  }, [_c("div", {
    staticClass: "search-box-container"
  }, [_c("user-record-owner", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: _vm.taskData.record_owner.label,
      iconPosition: "right",
      size: "small",
      showUserName: true,
      isFull: true
    },
    on: {
      updateData: function updateData($event) {
        _vm.taskData.record_owner.userId = $event.value;
        _vm.taskData.record_owner.value = $event.value;
        _vm.taskData.record_owner.label = $event.label;
        _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.record_owner.value,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData.record_owner, "value", $$v);
      },
      expression: "taskData.record_owner.value"
    }
  }), _vm._v(" "), _c("a", {
    staticClass: "arrow-button"
  }, [_c("i", {
    staticClass: "fal fa-chevron-down"
  })])], 1)]) : _c("kv-employee", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      "automatic-dropdown": "false"
    },
    on: {
      input: function input($event) {
        _vm.taskData.record_owner.value = $event.value;
        _vm.taskData.record_owner.label = $event.label;
        _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.record_owner,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "record_owner", $$v);
      },
      expression: "taskData.record_owner"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t("task.Deadline")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-calendar"
  })]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 6
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("date-picker", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      format: "DD MMM YYYY",
      lang: _vm.datePickerLang,
      "value-type": "YYYY-MM-DD",
      type: "date",
      placeholder: "Select date"
    },
    on: {
      change: function change($event) {
        return _vm.mergeTaskData($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(_ref) {
        var emit = _ref.emit;
        return [_c("button", {
          staticClass: "mx-btn mx-btn-text",
          on: {
            click: function click($event) {
              emit(new Date());
            }
          }
        }, [_vm._v("\n                                " + _vm._s(_vm.$t("Today")) + "\n                              ")]), _vm._v(" "), _c("button", {
          staticClass: "mx-btn mx-btn-text",
          on: {
            click: function click($event) {
              return emit(_vm.tomorrow);
            }
          }
        }, [_vm._v("\n                                " + _vm._s(_vm.$t("Tomorrow")) + "\n                              ")])];
      }
    }]),
    model: {
      value: _vm.taskData.end_date_dmy,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "end_date_dmy", $$v);
      },
      expression: "taskData.end_date_dmy"
    }
  }), _vm._v(" "), _vm.taskData.rescheduling_reason ? _c("div", {
    staticClass: "sidebar-item font-9 font-size-9"
  }, [_vm._v("\n                            " + _vm._s(_vm.$t("Rescheduling reason")) + ":\n                            " + _vm._s(_vm.taskData.rescheduling_reason) + "\n                            "), _c("a", {
    on: {
      click: function click($event) {
        return _vm.showPromptRescheduleReason();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-edit"
  })])]) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("task.Select Project")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fad fa-tasks-alt"
  })]), _vm._v(" "), _vm.taskData.project ? _c("kv-project", {
    staticClass: "width-full left-icon kv--input sub-border",
    on: {
      setAccount: function setAccount($event) {
        _vm.taskData.account.label = $event.label;
        _vm.taskData.account.value = $event.value;
      },
      change: function change($event) {
        _vm.triggerSaveTask();
        _vm.get_project_section(true);
      },
      input: function input($event) {
        _vm.taskData.project.label = $event.label;
        _vm.taskData.project.value = $event.value;
        _vm.taskData.section.value = null;
        _vm.triggerSaveTask();
        _vm.get_project_section(true);
      },
      allData: function allData($event) {
        _vm.projectData = $event;
      }
    },
    model: {
      value: _vm.taskData.project,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "project", $$v);
      },
      expression: "taskData.project"
    }
  }) : _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      value: ""
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Section")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-layer-group"
  })]), _vm._v(" "), _vm.taskData.section ? _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      placeholder: _vm.$t("Section"),
      filterable: ""
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask("section");
      }
    },
    model: {
      value: _vm.taskData.section.value,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData.section, "value", $$v);
      },
      expression: "taskData.section.value"
    }
  }, [_c("el-option", {
    key: 0,
    attrs: {
      value: "0",
      selected: "selected",
      label: _vm.$t("task.Select Section")
    }
  }), _vm._v(" "), _vm._l(_vm.project_section, function (ts) {
    return _c("el-option", {
      key: "section" + ts.record_id,
      attrs: {
        value: ts.record_id,
        label: ts.section_name
      }
    });
  })], 2) : _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      value: ""
    }
  }), _vm._v(" "), _c("el-button", {
    staticClass: "btnForm kv--color",
    attrs: {
      type: "text",
      icon: "fa fa-plus"
    },
    on: {
      click: function click($event) {
        _vm.fromVisible = {
          name: "section",
          id: "new",
          project: _vm.taskData.project ? _vm.taskData.project.value : null
        };
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Priority")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-sort-size-up-alt"
  })]), _vm._v(" "), _vm.taskData.priority && _vm.options["act_task_priority"] && _vm.options["act_task_priority"][0] ? _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      filterable: "",
      placeholder: _vm.$t("Priority")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.priority.value,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData.priority, "value", $$v);
      },
      expression: "taskData.priority.value"
    }
  }, _vm._l(_vm.options["act_task_priority"], function (obj, key) {
    return _c("el-option", {
      key: "priority" + key,
      attrs: {
        value: obj.id,
        label: obj.label
      }
    });
  }), 1) : _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      value: ""
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Status")) + "\n                        "), _c("a", {
    ref: "clickMeOutside",
    attrs: {
      href: "javascript:;alert('Hi')"
    }
  })]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-info-circle"
  })]), _vm._v(" "), _vm.taskData.task_status ? _c("el-select", {
    ref: "status_select",
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      filterable: "",
      placeholder: _vm.$t("Status")
    },
    on: {
      change: function change($event) {
        return _vm.checkStatusThenSave();
      }
    },
    model: {
      value: _vm.taskData.task_status.value,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData.task_status, "value", $$v);
      },
      expression: "taskData.task_status.value"
    }
  }, _vm._l(_vm.options["act_task_task_status"], function (obj, key) {
    return _c("el-option", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: obj.id == 3 || obj.id == 4 || obj.id == 6 || _vm.taskData.end_date_dmy != "",
        expression: "\n                            obj.id == 3 ||\n                            obj.id == 4 ||\n                            obj.id == 6 ||\n                            taskData.end_date_dmy != ''\n                          "
      }],
      key: "status" + key,
      attrs: {
        value: obj.id,
        label: obj.label
      }
    });
  }), 1) : _c("el-select", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      size: "medium",
      value: ""
    }
  }), _vm._v(" "), _vm.taskData.hold_reason ? _c("div", {
    staticClass: "sidebar-item font-9 font-size-9"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t("Hold reason:")) + " " + _vm._s(_vm.taskData.hold_reason) + "\n                        "), _c("a", {
    on: {
      click: function click($event) {
        return _vm.showPrompt(false);
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-edit"
  })])]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("div", {
    staticClass: "add-more-title"
  }, [_c("span", {
    staticClass: "icon-before-title"
  }, [_c("i", {
    staticClass: "fal fa-clock"
  })]), _vm._v(" "), _c("span", {
    staticClass: "title-text"
  }, [_vm._v("\n                          " + _vm._s(_vm.$t("Time Entry")) + "\n\n                          "), _vm.taskData.record_id && (_vm.taskData.task_status.customValue == "CLOSED" || _vm.taskData.task_status.value == 3) ? _c("a", [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      "popper-class": "red-tooltip",
      content: _vm.$t("You can not log time entry for a completed task. Please re-open first"),
      placement: "bottom-start"
    }
  }, [_c("a", {
    staticClass: "no-border icon-add-more"
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })])])], 1) : _vm.taskData.record_id ? _c("a", {
    staticClass: "no-border icon-add-more",
    attrs: {
      size: "text",
      plain: "",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.start_add_time_entry();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })]) : _vm._e()])])]), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 8
    },
    on: {
      click: function click($event) {
        _vm.visibleTimeSelector = true;
      }
    }
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("Estimated")))]), _vm._v(" "), _c("KvTimeSelect", {
    key: "expected" + _vm.taskData.expected_duration,
    attrs: {
      position: "top",
      mainClass: "small-sizes"
    },
    on: {
      input: function input($event) {
        _vm.taskData.expected_duration = $event;
        _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.expected_duration,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "expected_duration", $$v);
      },
      expression: "taskData.expected_duration"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("Spent")))]), _vm._v(" "), _vm.all_time_entries && _vm.all_time_entries[0] ? _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s("" + _vm.total_time() + "") + "\n                            \n                             "), _c("el-popover", {
    staticClass: "icon-list",
    attrs: {
      placement: "right",
      width: "550",
      trigger: "hover"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.all_time_entries,
      height: "300"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "100",
      property: "start_date",
      label: _vm.$t("Date")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "100",
      property: "duration_formatted",
      label: _vm.$t("Duration")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      property: "record_owner.label",
      label: _vm.$t("Owner")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "100",
      label: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_c("el-link", {
          on: {
            click: function click($event) {
              return _vm.start_add_time_entry_edit(row);
            }
          }
        }, [_c("i", {
          staticClass: "fa fa-edit"
        })])];
      }
    }], null, false, 1063639942)
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "no-border icon-list",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    key: "timeentry" + _vm.taskData.record_id,
    staticClass: "fal fa-search"
  })])], 1)], 1) : _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s(_vm.$t("-")))])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("My")))]), _vm._v(" "), _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s("" + _vm.total_time("my") + "") + "\n                            "), _vm.taskData.record_id && (_vm.taskData.task_status.customValue == "CLOSED" || _vm.taskData.task_status.value == 3) ? _c("a", [_c("el-tooltip", {
    attrs: {
      "popper-class": "red-tooltip",
      effect: "dark",
      content: _vm.$t("You can not log time entry for a completed task. Please re-open first"),
      placement: "bottom-start"
    }
  }, [_c("a", [_c("i", {
    staticClass: "fa fa-plus"
  })])])], 1) : _vm.taskData.record_id ? _c("a", {
    on: {
      click: function click($event) {
        return _vm.start_add_time_entry();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })]) : _vm._e()])])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("task.Followers")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-users"
  })]), _vm._v(" "), _vm.taskData.record_owner && _vm.users.length < 999 ? _c("KvUserSelectMultiple", {
    attrs: {
      size: 24,
      showUserName: false,
      dataType: "number",
      "stable-users": [_vm.projectData.recordOwner].concat(_toConsumableArray(_vm.projectData.followers))
    },
    on: {
      input: function input($event) {
        return _vm.triggerSaveTask("followers");
      }
    },
    model: {
      value: _vm.taskData.followers,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "followers", $$v);
      },
      expression: "taskData.followers"
    }
  }) : _c("kv-employee", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      isMultiple: true
    },
    on: {
      input: function input($event) {
        _vm.taskData.followers_array = _vm.taskData.followers.map(function (k) {
          return k.value;
        });
        _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.followers,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "followers", $$v);
      },
      expression: "taskData.followers"
    }
  }), _vm._v(" "), _c("p", {
    staticClass: "font-10 demonstration"
  }, [_vm._v("\n                        " + _vm._s(_vm.$t("Visible on Customer Portal")) + "\n                      ")]), _vm._v(" "), _c("el-switch", {
    attrs: {
      "active-color": "#13ce66",
      "inactive-color": "#ff4949"
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.visible_on_cp,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "visible_on_cp", $$v);
      },
      expression: "taskData.visible_on_cp"
    }
  })], 1), _vm._v(" "), _vm.taskData.record_id && _vm.editable ? _c("div", {
    staticClass: "sidebar-item delete-item text-right"
  }, [_c("el-link", {
    staticClass: "font-12 color-danger danger",
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        return _vm.deleteConfirm();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-trash color-danger"
  }), _vm._v("\n                        " + _vm._s(_vm.$t("task.Delete this task")) + "\n                      ")])], 1) : _vm.taskData.record_id && !_vm.editable ? _c("div", {
    staticClass: "sidebar-item delete-item text-right"
  }, [_c("el-link", {
    staticClass: "font-12 color-danger danger",
    attrs: {
      size: "small"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.$t("This task has been completed!")) + "\n                      ")])], 1) : _vm.taskData.record_id && _vm.taskData.status == 3 ? _c("div", {
    staticClass: "sidebar-item delete-item text-right"
  }, [_c("el-link", {
    staticClass: "font-12 color-danger danger",
    attrs: {
      size: "small"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.$t("This task has been deleted!")) + "\n                      ")])], 1) : _vm._e()])])], 1)], 1)])])])]), _vm._v(" "), _vm.editModal ? _c("inbox-modal", {
    attrs: {
      pushData: {
        record_id: _vm.taskData.thread ? _vm.taskData.thread.value : null
      }
    },
    model: {
      value: _vm.editModal,
      callback: function callback($$v) {
        _vm.editModal = $$v;
      },
      expression: "editModal"
    }
  }) : _vm._e(), _vm._v(" "), _vm.fromVisible && _vm.fromVisible.name == "section" ? _c("section-form", {
    attrs: {
      id: _vm.fromVisible.id,
      project: _vm.fromVisible.project
    },
    on: {
      setDialogFormVisible: function setDialogFormVisible($event) {
        _vm.fromVisible = $event;
        _vm.get_project_section(false);
      },
      insertedData: function insertedData($event) {
        _vm.taskData.section.value = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.editLabelsModal ? _c("edit-labels", {
    on: {
      close: function close($event) {
        _vm.editLabelsModal = false;
        _vm.get_labels();
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.showMergeThreadModal ? _c("merge-thread-modal", {
    attrs: {
      task: _vm.taskData,
      project: _vm.taskData.project,
      projectData: _vm.projectData
    },
    on: {
      success: function success($event) {
        _vm.taskData.thread = $event;
        _vm.triggerSaveTask();
      },
      close: function close($event) {
        _vm.showMergeThreadModal = false;
      }
    },
    model: {
      value: _vm.showMergeThreadModal,
      callback: function callback($$v) {
        _vm.showMergeThreadModal = $$v;
      },
      expression: "showMergeThreadModal"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };