import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_c("render", {
    attrs: {
      "is-app": "kiva"
    }
  }, [_c("sidebar", {
    staticClass: "sidebar-container"
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "main-container-outer"
  }, [_c("section", {
    staticClass: "rulesup-main"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("div", {
    staticClass: "external-app rulesup-app"
  }, [_c("header", [_c("div", {
    staticClass: "rulesup-logo-wrapper"
  }, [_c("router-link", {
    attrs: {
      to: {
        name: "rulesup.tasks.list"
      }
    }
  }, [_c("img", {
    attrs: {
      src: require("@rulesup/assets/rulesup.svg"),
      alt: ""
    }
  })])], 1), _vm._v(" "), _c("ul", {
    staticClass: "main-menu"
  }, [_c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "rulesup.tasks.list"
      },
      exact: ""
    }
  }, [_vm._v(_vm._s(_vm.$t("rulesup.menu.tasks")))])], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "rulesup.checklists.list"
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("rulesup.menu.checklists")))])], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "rulesup.templates.list"
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("rulesup.menu.templates")))])], 1)]), _vm._v(" "), _c("render", {
    attrs: {
      "is-app": "rulesup"
    }
  }, [_c("ul", {
    staticClass: "right-menu"
  }, [_c("li", [_c("a", {
    attrs: {
      to: {
        name: "activities"
      },
      event: ""
    },
    on: {
      click: function click($event) {
        _vm.activityDrawer = true;
      }
    }
  }, [_vm.config.paused == 1 || _vm.scheduledDoNotDisturb == true ? _c("font-awesome-icon", {
    key: _vm.config.paused + _vm.scheduledDoNotDisturb,
    attrs: {
      icon: ["fal", "bell-slash"]
    }
  }) : _c("font-awesome-icon", {
    key: _vm.config.paused + _vm.scheduledDoNotDisturb,
    attrs: {
      icon: ["fal", "bell"]
    }
  }), _vm._v(" "), _vm.unreadNotification > 0 ? _c("div", {
    staticClass: "notification"
  }, [_vm._v(_vm._s(_vm._f("max3chars")(_vm.unreadNotification)))]) : _vm._e()], 1)]), _vm._v(" "), _vm.$isRulesupAdmin ? _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "rulesup.settings"
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fal", "cog"]
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("li", [_c("el-dropdown", {
    attrs: {
      trigger: "click"
    }
  }, [_c("router-link", {
    staticClass: "with-image",
    attrs: {
      to: {
        name: "account.overview"
      },
      event: ""
    }
  }, [_c("figure", {
    staticClass: "image is-32x32"
  }, [_vm.getCurrentUser.photo ? _c("img", {
    staticClass: "is-rounded",
    attrs: {
      src: "".concat(_vm.$kvBaseUrl, "/").concat(_vm.getCurrentUser.photo),
      alt: ""
    }
  }) : _c("el-avatar", {
    attrs: {
      size: 32
    }
  }, [_vm._v(_vm._s(_vm.getCurrentUser.fullName.split(" ").map(function (s) {
    return s[0];
  }).join("")))])], 1)]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    staticClass: "user-menu-item"
  }, [_c("router-link", {
    attrs: {
      to: {
        name: "account.my-profile"
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fal", "user-circle"]
    }
  }), _vm._v("\n                          " + _vm._s(_vm.$t("rulesup.layout.my_profile")) + "\n                        ")], 1)], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "user-menu-item"
  }, [_c("router-link", {
    attrs: {
      to: {
        name: "account.change-password"
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fal", "key"]
    }
  }), _vm._v("\n                          " + _vm._s(_vm.$t("rulesup.layout.change_password")) + "\n                        ")], 1)], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "user-menu-item"
  }, [_c("a", {
    attrs: {
      href: "".concat(_vm.$kvBaseUrl, "/logout")
    },
    on: {
      click: _vm.doSignOut
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fal", "sign-out"]
    }
  }), _vm._v("\n                          " + _vm._s(_vm.$t("rulesup.layout.sign_out")) + "\n                        ")], 1)])], 1)], 1)], 1)])])], 1), _vm._v(" "), _c("main", [_vm._t("default")], 2)])])], 1)]), _vm._v(" "), _vm.tasksPopup.show && _vm.tasksPopup.task ? _c("task-modal", {
    staticStyle: {
      "z-index": "9999999999999999999999999999999999999999"
    },
    attrs: {
      task: _vm.tasksPopup.task
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.tasksPopup.show = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("el-drawer", {
    attrs: {
      size: "500px",
      "with-header": false,
      visible: _vm.activityDrawer
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.activityDrawer = $event;
      }
    }
  }, [_c("activity-list")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };