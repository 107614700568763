import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
export default {
  name: 'Render',
  render: function render(createElement, context) {
    if (this.renderable) {
      return this.$scopedSlots.default({
        exampleProp: 'universe'
      });
    }
  },
  props: {
    isApp: {
      type: [String, Array],
      required: true
    }
  },
  computed: {
    renderable: function renderable() {
      if (Array.isArray(this.isApp)) {
        return this.isApp.includes(this.$isApp);
      } else {
        return this.isApp === this.$isApp;
      }
    }
  }
};