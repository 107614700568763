import "core-js/modules/es.array.push.js";
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    short: {
      type: String,
      default: ''
    },
    nclass: {
      type: [String, Array],
      default: ''
    }
  },
  render: function render(h, context) {
    var _context$props = context.props,
      icon = _context$props.icon,
      title = _context$props.title,
      nclass = _context$props.nclass,
      short = _context$props.short;
    var vnodes = [];
    if (short && icon) {
      vnodes.push(h("div", {
        "class": nclass
      }, [h("i", {
        "class": icon
      }), " ", h("a", {
        "class": 'menu-bottom-text'
      }, [short])]));
    } else if (icon) {
      vnodes.push(h("div", {
        "class": nclass
      }, [h("i", {
        "class": icon
      })]));
    }
    if (title) {
      vnodes.push(h("span", {
        "slot": 'title',
        "style": 'margin-left:-4px;'
      }, [title]));
    }
    return vnodes;
  }
};