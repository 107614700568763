module.exports = {
  title: process.env.VUE_APP_APP_TITLE,
  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: false,
  /**
   * @type {boolean} true | false
   * @description Whether need tagsView
   */
  tagsView: true,
  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,
  /**
   * @type date format
   * @description format option for moment.js
   */
  dateFormat: 'DD/MM/YYYY',
  /**
   * @type Boolean
   * @description  is time entry required to complete task
   */
  timeEntryRequired: false
};