import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "kv-line-width kv-scroll"
  }, [_c("ul", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, _vm._l(_vm.attachments, function (att, i) {
    return _c("li", {
      key: i,
      staticClass: "el-upload-list__item is-success rel-pos",
      class: _vm.size,
      attrs: {
        tabindex: "0"
      }
    }, [_c("div", [_vm.isImage(att) ? _c("img", {
      staticClass: "objectFit",
      attrs: {
        src: _vm.getImageUrl(att.file_id)
      }
    }) : att.mime_type ? _c("span", [_c("i", {
      class: _vm.getFileIcon(att, "fad", "sm")
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__title"
    }, [_c("a", {
      attrs: {
        title: att.name
      }
    }, [_vm._v("  " + _vm._s(att.name) + "  ")])]), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-actions"
    }, [_vm.isImage(att) ? _c("span", {
      staticClass: "el-upload-list__item-preview",
      on: {
        click: function click($event) {
          return _vm.handleView(att);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-zoom-in"
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-download",
      on: {
        click: function click($event) {
          return _vm.openFileUrl(att.file_id);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-download"
    })]), _vm._v(" "), _vm.permitDelete ? _c("span", {
      staticClass: "el-upload-list__item-delete",
      on: {
        click: function click($event) {
          return _vm.deleteAttachment(att.file_id, _vm.record_id, _vm.app, _vm.model);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete"
    })]) : _vm._e()]), _vm._v(" "), _vm.isImage(att) ? [_c("div", {
      directives: [{
        name: "viewer",
        rawName: "v-viewer"
      }],
      staticClass: "viewer-images",
      class: "viewer-".concat(att.file_id)
    }, [_c("img", {
      attrs: {
        src: _vm.getImageUrl(att.file_id)
      }
    })])] : _vm._e()], 2)]);
  }), 0)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };