var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g(_vm._b({
    ref: "extendedClass",
    class: _vm.extendedClass,
    attrs: {
      "value-key": "recordId",
      clearable: "",
      filterable: "",
      loading: _vm.loading,
      autocomplete: "nope",
      placeholder: _vm.placeholder ? _vm.placeholder : _vm.$t("Select Project(s)"),
      remote: "",
      "reserve-keyword": "",
      "remote-method": _vm.getProjects
    },
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, "el-select", _vm.attrs, false), _vm.listeners), _vm._l(_vm.projects, function (project) {
    return _c("el-option", {
      key: project.recordId,
      attrs: {
        value: project.recordId,
        label: project.projectName
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };