import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { Sidebar } from "./components";
import { mapGetters } from "vuex";
import ActivityList from "@/views/activity/ActivityListDrawer";
import TaskModal from "@/apps/rulesup/components/taskModal";
import { Base64 } from 'js-base64';
import FontAwesomeIcon from '@/components/global/FontAwesomeIcon';
export default {
  name: "RulesupLayout",
  components: {
    ActivityList: ActivityList,
    Sidebar: Sidebar,
    TaskModal: TaskModal,
    FontAwesomeIcon: FontAwesomeIcon
  },
  created: function created() {
    this.handleIcon();
  },
  filters: {
    max3chars: function max3chars(value) {
      if (value > 99) return "+99";
      return value;
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    handleIcon: function handleIcon() {
      var favicon = document.getElementById("favicon");
      favicon.href = "/favicons/rulesup.png";
    },
    doSignOut: function doSignOut() {
      //
      if (this.$isRulesup) {
        window.location.href = "https://login.rulesup.com/logout";
      } else {
        window.location.href = "".concat(this.$kvApiBridgeUrl, "/logout");
      }
    }
  },
  computed: _objectSpread({
    activityDrawer: {
      get: function get() {
        return this.$store.state.app.activityDrawer;
      },
      set: function set(val) {
        this.$store.state.app.activityDrawer = val;
      }
    },
    config: {
      get: function get() {
        return this.$store.state.notification.config;
      },
      set: function set(val) {
        this.$store.state.notification.config = val;
      }
    },
    scheduledDoNotDisturb: {
      get: function get() {
        return this.$store.state.notification.scheduledDoNotDisturb;
      },
      set: function set(val) {
        this.$store.state.notification.scheduledDoNotDisturb = val;
      }
    },
    unreadNotification: function unreadNotification() {
      return this.$store.state.notification.unreadNotification;
    },
    noUnreadSuccess: function noUnreadSuccess() {
      return this.$store.state.notification.noUnreadSuccess;
    },
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    tasksPopup: {
      get: function get() {
        return this.$store.state.tasks.popup;
      },
      set: function set(val) {
        this.$store.state.tasks.popup = val;
      }
    }
  }, mapGetters("users", ["getCurrentUser"])),
  watch: {
    "$route.query.target": function $routeQueryTarget(q) {
      if (q) {
        var f = q.split("/");
        if (f[0] == "editRecord") {
          var data = Base64.decode(f[1]).split(",");
          if (data[0]) {
            data[0] = data[0].substring(1);
            this.$store.state.tasks.popup.task = {
              recordId: data[0],
              record_id: data[0]
            };
            this.$store.state.tasks.popup.show = true;
          }
        }
      }
    }
  }
};