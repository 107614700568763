import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { addExtendedRoutes } from '@/router';
import { checklistRoutes } from '@rulesup/routes/checklistRoutes';
import { tasksRoutes } from '@rulesup/routes/tasksRoutes';
import { templateRoutes } from '@rulesup/routes/templateRoutes';
import { calendarRoutes } from '@rulesup/routes/calendarRoutes';
import { settingsRoutes } from '@rulesup/routes/settingsRoutes';
export function createRoutes(namespace) {
  addExtendedRoutes([{
    path: namespace ? "/".concat(namespace) : '/',
    component: function component() {
      return import('@rulesup/views/RulesupView');
    },
    name: 'rulesUp',
    meta: {
      title: 'RulesUp',
      icon: 'fal fa-registered',
      hideTag: true,
      noCache: true,
      layout: 'RulesupLayout'
    },
    children: [{
      path: 'templates',
      name: 'rulesup.template',
      // component: () => import('@rulesup/views/dashboard/Dashboard'),
      redirect: {
        name: 'rulesup.templates.list'
      },
      meta: {
        title: 'Rulesup',
        icon: 'fal fa-bell'
      },
      hidden: true
    }, {
      path: 'checklists',
      component: function component() {
        return import('@rulesup/views/checklists/ChecklistView');
      },
      children: checklistRoutes,
      meta: {
        title: 'Checklists',
        icon: 'fal fa-registered'
      },
      hidden: true
    }, {
      path: '',
      component: function component() {
        return import('@rulesup/views/tasks/TasksView');
      },
      children: tasksRoutes,
      meta: {
        title: 'Tasks',
        icon: 'fal fa-registered'
      },
      hidden: true
    }, {
      path: 'templates',
      component: function component() {
        return import('@rulesup/views/templates/TemplatesView');
      },
      children: templateRoutes,
      meta: {
        title: 'Templates',
        icon: 'fal fa-registered'
      },
      hidden: true
    }, {
      path: 'calendar',
      component: function component() {
        return import('@rulesup/views/calendar/CalendarView');
      },
      children: calendarRoutes,
      meta: {
        title: 'Calendar',
        icon: 'fal fa-calendar'
      },
      hidden: true
    }, {
      path: 'settings',
      component: function component() {
        return import('@rulesup/views/settings/SettingsView');
      },
      children: settingsRoutes,
      meta: {
        title: 'Calendar',
        icon: 'fal fa-calendar'
      },
      hidden: true
    }]
  }]);
}