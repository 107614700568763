import _slicedToArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
export var messages = {
  _default: function _default(field) {
    return "".concat(field, " alan\u0131na ge\xE7erli bir de\u011Fer giriniz.");
  },
  after: function after(field, _ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      target = _ref2[0];
    return "".concat(field, " ").concat(target, " alan\u0131ndan ileri bir tarih olmal\u0131d\u0131r.");
  },
  alpha: function alpha(field) {
    return "".concat(field, " yaln\u0131zca harf i\xE7erebilir.");
  },
  alpha_dash: function alpha_dash(field) {
    return "".concat(field, " alan\u0131 harf ve tire (-) ya da alttan tire (_) i\xE7erebilir.");
  },
  alpha_num: function alpha_num(field) {
    return "".concat(field, " yaln\u0131zca harf ve rakam i\xE7erebilir.");
  },
  alpha_spaces: function alpha_spaces(field) {
    return "".concat(field, " yaln\u0131zca harf bo\u015Fluk (space) i\xE7erebilir.");
  },
  before: function before(field, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 1),
      target = _ref4[0];
    return "".concat(field, " ").concat(target, " alan\u0131ndan \xF6nce bir tarih olmal\u0131d\u0131r.");
  },
  between: function between(field, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      min = _ref6[0],
      max = _ref6[1];
    return "".concat(field, " ").concat(min, " ile ").concat(max, " aral\u0131\u011F\u0131nda olmal\u0131d\u0131r.");
  },
  confirmed: function confirmed(field) {
    return "".concat(field, " do\u011Frulamas\u0131 hatal\u0131.");
  },
  credit_card: function credit_card(field) {
    return "".concat(field, " numaras\u0131 hatal\u0131.");
  },
  date_between: function date_between(field, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      min = _ref8[0],
      max = _ref8[1];
    return "".concat(field, " ").concat(min, " ile ").concat(max, " tarihleri aras\u0131nda olmal\u0131d\u0131r.");
  },
  date_format: function date_format(field, _ref9) {
    var _ref10 = _slicedToArray(_ref9, 1),
      format = _ref10[0];
    return "".concat(field, " ").concat(format, " format\u0131nda olmal\u0131d\u0131r.");
  },
  decimal: function decimal(field) {
    var _ref11 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [],
      _ref12 = _slicedToArray(_ref11, 1),
      _ref12$ = _ref12[0],
      decimals = _ref12$ === void 0 ? '*' : _ref12$;
    return "".concat(field, " say\u0131sal").concat(decimals !== '*' ? " ve noktadan sonra ".concat(decimals, " basamakl\u0131") : '', " olmal\u0131d\u0131r.");
  },
  digits: function digits(field, _ref13) {
    var _ref14 = _slicedToArray(_ref13, 1),
      length = _ref14[0];
    return "".concat(field, " say\u0131sal ve ").concat(length, " basamakl\u0131 olmal\u0131d\u0131r.");
  },
  dimensions: function dimensions(field, _ref15) {
    var _ref16 = _slicedToArray(_ref15, 2),
      width = _ref16[0],
      height = _ref16[1];
    return "".concat(field, " alan\u0131 ").concat(width, " piksel ile ").concat(height, " piksel aras\u0131nda olmal\u0131d\u0131r.");
  },
  email: function email(field) {
    return "".concat(field, " alan\u0131n\u0131n ge\xE7erli bir e-posta olmas\u0131 gerekir.");
  },
  excluded: function excluded(field) {
    return "".concat(field, " alan\u0131na ge\xE7erli bir de\u011Fer giriniz.");
  },
  ext: function ext(field) {
    return "".concat(field, " alan\u0131 ge\xE7erli bir dosya olmal\u0131d\u0131r.");
  },
  image: function image(field) {
    return "".concat(field, " alan\u0131 resim dosyas\u0131 olmal\u0131d\u0131r.");
  },
  included: function included(field) {
    return "".concat(field, " alan\u0131na ge\xE7erli bir de\u011Fer giriniz.");
  },
  ip: function ip(field) {
    return "".concat(field, " alan\u0131 ge\xE7erli bir ip adresi olmal\u0131d\u0131r.");
  },
  max: function max(field, _ref17) {
    var _ref18 = _slicedToArray(_ref17, 1),
      length = _ref18[0];
    return "".concat(field, " alan\u0131 ").concat(length, " karakterden fazla olmamal\u0131d\u0131r.");
  },
  max_value: function max_value(field, _ref19) {
    var _ref20 = _slicedToArray(_ref19, 1),
      max = _ref20[0];
    return "".concat(field, " alan\u0131 ").concat(max, " ya da daha az bir de\u011Fer olmal\u0131d\u0131r.");
  },
  mimes: function mimes(field) {
    return "".concat(field, " ge\xE7erli bir dosya olmal\u0131d\u0131r.");
  },
  min: function min(field, _ref21) {
    var _ref22 = _slicedToArray(_ref21, 1),
      length = _ref22[0];
    return "".concat(field, " alan\u0131na en az ").concat(length, " karakter girilmelidir.");
  },
  min_value: function min_value(field, _ref23) {
    var _ref24 = _slicedToArray(_ref23, 1),
      min = _ref24[0];
    return "".concat(field, " alan\u0131 ").concat(min, " ya da daha fazla bir de\u011Fer olmal\u0131d\u0131r.");
  },
  numeric: function numeric(field) {
    return "".concat(field, " alan\u0131na say\u0131sal bir de\u011Fer giriniz.");
  },
  regex: function regex(field) {
    return "".concat(field, " format\u0131 ge\xE7ersiz.");
  },
  required: function required(field) {
    return "".concat(field, " alan\u0131 gereklidir.");
  },
  size: function size(field, _ref25) {
    var _ref26 = _slicedToArray(_ref25, 1),
      _size = _ref26[0];
    return "".concat(field, " alan\u0131 ").concat(_size, "'dan daha az olmal\u0131d\u0131r.");
  },
  url: function url(field) {
    return "".concat(field, " ge\xE7ersiz URL.");
  },
  kv_password: function kv_password(field, params, data) {
    return "Password criterias not matched";
  }
};