var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.profiles && _vm.profiles[0] && _vm.me.profile && _vm.me.profile == "Admin" ? _c("div", {
    staticClass: "change-profiles"
  }, [_c("el-dropdown", [_c("el-button", {
    attrs: {
      type: "none",
      size: "mini"
    }
  }, [_c("i", {
    staticClass: "far fa-eye"
  }), _vm._v(" "), _vm.levelLabel ? _c("span", [_vm._v("\n\n      " + _vm._s("".concat(_vm.levelLabel)) + " "), _c("a", {
    on: {
      click: function click($event) {
        _vm.levelLabel = "";
        _vm.level = 1;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-times"
  })])]) : _c("span", [_vm._v(_vm._s(_vm.$t("View")))]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.profiles, function (item) {
    return _c("el-dropdown-item", {
      key: item.record_id
    }, [_c("a", {
      staticClass: "width-full pull-left",
      on: {
        click: function click($event) {
          _vm.level = item.record_id;
          _vm.levelLabel = item.profile_name;
        }
      }
    }, [_vm._v(_vm._s(item.profile_name))])]);
  }), 1)], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };