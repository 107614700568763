import _objectWithoutProperties from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["input"];
import KivaProxy from '@/utils/kivaProxy/src';
import { CountryTransformer } from '@/transformers/CountryTransformer';
export default {
  name: 'KvCountrySelect',
  props: {
    value: {}
  },
  data: function data() {
    return {
      loading: false,
      countries: []
    };
  },
  created: function created() {
    this.getCountries();
  },
  methods: {
    getCountries: function getCountries() {
      var _this = this;
      this.loading = true;
      new KivaProxy({
        endpoint: 'addr/country'
      }).setParameter('limit', 500).all().then(function (response) {
        _this.countries = CountryTransformer.fetchCollection(response.records);
        _this.loading = false;
      });
    },
    input: function input(val) {
      this.$emit('input', val);
    }
  },
  computed: {
    attrs: function attrs() {
      return this.$attrs;
    },
    listeners: function listeners() {
      var _this$$listeners = this.$listeners,
        input = _this$$listeners.input,
        listeners = _objectWithoutProperties(_this$$listeners, _excluded);
      return listeners;
    }
  }
};