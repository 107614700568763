import _toConsumableArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import SvgIcon from "@/components/kiva/kvSvgIcon.vue";
import pinsMixin from "@/mixins/pins";
export default {
  mixins: [pinsMixin],
  components: {
    SvgIcon: SvgIcon
  },
  data: function data() {
    return {
      created: false,
      show: true,
      searchText: "",
      menu: [],
      keyMain: "keyMain",
      filteredList: _toConsumableArray(this.list)
    };
  },
  computed: {
    routes: function routes() {
      return this.$router.options.routes;
    }
  },
  methods: {
    goPath: function goPath(sub) {
      var _this = this;
      this.searchText = "";
      setTimeout(function () {
        _this.$router.push({
          path: sub.pathFull
        });
      }, 300);
      this.$emit("updateMegaMenu", false);
    },
    goPathNewWindow: function goPathNewWindow(sub) {
      var origin = window.location.origin;
      window.open(origin + '' + sub.pathFull, '_blank');
    },
    closeEvent: function closeEvent() {
      this.created = false;
      this.$emit("updateMegaMenu", false);
    }
  },
  props: {
    megaMenu: Boolean,
    list: [Array, Object]
  },
  mounted: function mounted() {
    var _this2 = this;
    this.searchText = "";
    setTimeout(function () {
      if (_this2.$refs && _this2.$refs.searchField) {
        _this2.$refs.searchField.focus();
      }
    }, 900);
  },
  watch: {
    megaMenu: function megaMenu(val) {
      var _this3 = this;
      if (val) {
        this.getPins({
          favorite_type: 2
        }, false);
        this.show = true;
        setTimeout(function () {
          _this3.created = true;
        }, 100);
        setTimeout(function () {
          if (_this3.$refs && _this3.$refs.searchField) {
            _this3.$refs.searchField.focus();
          }
        }, 800);
      }
    },
    searchText: function searchText(val) {
      var _this4 = this;
      var kw = val.toLowerCase();
      this.filteredList.forEach(function (element) {
        element.hide = true;
        if (element.subList) {
          element.subList.forEach(function (item) {
            var title = item.meta && item.meta.title ? _this4.$t(item.meta.title).toLowerCase() : "";
            var keywords = item.meta && item.meta.keywords ? item.meta.keywords.toLowerCase() : "";
            if (title.includes(kw) || keywords.includes(kw)) {
              item.show = true;
              element.hide = false;
            } else {
              item.show = false;
            }
          });
        }
      });
      this.keyMain = "mm" + new Date().getUTCMilliseconds();
    }
  }
};