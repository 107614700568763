var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("aside", {
    staticClass: "header-submenu"
  }, [_vm.$route.path == "/dashboard/myHome" || _vm.$route.path == "/dashboard/teamView" || _vm.$route.path == "/dashboard/projectPortfolio" || _vm.$route.path == "/dashboard/workloadView" || _vm.$route.path == "/dashboard/calendarView" || _vm.$route.path == "/dashboard/timesheetView" || _vm.$route.path == "/dashboard/planner" || _vm.$route.path == "/dashboard/resources" || _vm.$route.path == "/dashboard/taskPlanner" ? _c("section", [_vm._m(0), _vm._v(" "), _c("ul", [_c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.myHome"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-user"
  }), _vm._v("   " + _vm._s(_vm.$t("My Home")) + "\n        ")])], 1), _vm._v(" "), _c("li", {
    staticClass: "subList"
  }, [_vm.me && _vm.me.primaryTeam ? _c("router-link", {
    attrs: {
      to: {
        name: "dashboard.teamView",
        query: {
          team: _vm.me.primaryTeam
        }
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-users"
  }), _vm._v("   " + _vm._s(_vm.$t("Team View")))]) : _vm._e()], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.projectPortfolio"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-building"
  }), _vm._v("   " + _vm._s(_vm.$t("Project Portfolio")) + "\n        ")])], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.workloadView"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-file-alt"
  }), _vm._v("   " + _vm._s(_vm.$t("Workload View")) + "\n        ")])], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.calendarView"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-calendar"
  }), _vm._v("   " + _vm._s(_vm.$t("Calendar")) + "\n        ")])], 1), _vm._v(" "), _c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.timesheetView"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-clock"
  }), _vm._v("   " + _vm._s(_vm.$t("Timesheet")) + "\n        ")])], 1)])]) : _c("section", [_c("h3", [_c("i", {
    staticClass: "fal fa-analytics"
  }), _vm._v("   " + _vm._s(_vm.$t(" Analytics")))]), _vm._v(" "), _c("ul", [_c("li", [_c("router-link", {
    attrs: {
      to: {
        name: "dashboard.projectsManagement"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-tasks"
  }), _vm._v("   " + _vm._s(_vm.$t("Project Management")) + "\n        ")])], 1), _vm._v(" "), _c("li", [_vm.me && _vm.me.profile != "guest" ? _c("router-link", {
    attrs: {
      to: {
        name: "dashboard.timeMaterial"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-clock"
  }), _vm._v("   " + _vm._s(_vm.$t("Time & Material")) + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("li", [_vm.me && _vm.me.profile != "guest" ? _c("router-link", {
    attrs: {
      to: {
        name: "dashboard.invoicingPayments"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-file-alt"
  }), _vm._v("   " + _vm._s(_vm.$t("Invoicing & Payments")) + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("li", [_vm.me && _vm.me.profile != "guest" ? _c("router-link", {
    attrs: {
      to: {
        name: "dashboard.productsServices"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-database"
  }), _vm._v("   " + _vm._s(_vm.$t("Products & Services")) + "\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("li", [_vm.me && _vm.me.profile != "guest" ? _c("router-link", {
    attrs: {
      to: {
        name: "dashboard.salesFunnel"
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-filter"
  }), _vm._v("   " + _vm._s(_vm.$t("Sales Funnel")) + "\n        ")]) : _vm._e()], 1)])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("h3", [_c("i", {
    staticClass: "far fa-stream"
  }), _vm._v(" Overviews")]);
}];
render._withStripped = true;
export { render, staticRenderFns };