import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEvent,
      expression: "closeEvent"
    }],
    staticClass: "kvUserSelectMultiple",
    class: _vm.getExtraClass
  }, [_vm.stableUsers && _vm.stableUsers[0] || _vm.data && _vm.data[0] ? _c("div", {
    ref: "kvUserSelectBox" + _vm.key,
    staticClass: "kvUserDisplay"
  }, [_vm.stableUsers && _vm.stableUsers[0] ? _vm._l(_vm.stableUsers, function (dt, i) {
    return _c("div", {
      staticClass: "user-item mini-size"
    }, [_c("span", {
      staticClass: "kvAvatar"
    }, [dt.photo ? _c("el-avatar", {
      staticClass: "kv-shadow-hover-default avatarBox",
      attrs: {
        size: _vm.size,
        title: dt.label,
        src: "".concat(_vm.$store.state.IMAGE_PATH, "/").concat(dt.photo)
      }
    }, [_vm._v("\n            " + _vm._s(_vm.data.lenght == 1 ? dt.letters : dt))]) : _c("el-avatar", {
      staticClass: "kv-shadow-hover-default avatarBox",
      attrs: {
        size: _vm.size
      }
    }, [_vm._v(_vm._s(dt.letters))])], 1)]);
  }) : _vm._e(), _vm._v(" "), _vm._l(_vm.data, function (dt, i) {
    return [_c("div", {
      staticClass: "user-item",
      class: _vm.showUserName !== false ? "" : "small-size"
    }, [_c("span", {
      staticClass: "kvAvatar",
      on: {
        click: function click($event) {
          return _vm.start_search(i);
        }
      }
    }, [dt.photo ? _c("el-avatar", {
      staticClass: "kv-shadow-hover-default avatarBox",
      attrs: {
        size: _vm.size,
        title: dt.label,
        src: "".concat(_vm.$store.state.IMAGE_PATH, "/").concat(dt.photo)
      }
    }, [_vm._v("\n            " + _vm._s(dt.letters ? dt.letters : dt.firstname ? dt.firstname.charAt(0) + " " + dt.firstname.charAt(0) : ""))]) : _c("el-avatar", {
      staticClass: "kv-shadow-hover-default avatarBox",
      attrs: {
        size: _vm.size
      }
    }, [_vm._v(_vm._s(dt.letters ? dt.letters : dt.firstname ? dt.firstname.charAt(0) + " " + dt.firstname.charAt(0) : ""))])], 1), _vm._v(" "), _c("input", {
      staticStyle: {
        width: "0",
        height: "0",
        border: "0",
        overflow: "hidden",
        margin: "0",
        padding: "0"
      },
      attrs: {
        type: "text",
        tabIndex: "0",
        placeholder: "Search')"
      },
      on: {
        focus: function focus($event) {
          return _vm.start_search();
        }
      }
    }), _vm._v(" "), _vm.showUserName !== false ? _c("span", {
      staticClass: "text-avatar pull-left",
      class: ["font-" + _vm.fontSize, _vm.suffixText ? "twoLine" : ""],
      on: {
        click: function click($event) {
          return _vm.start_search(i);
        }
      }
    }, [_vm._v("\n          " + _vm._s(_vm._f("nameShorten2")(dt.label)) + "\n          "), _vm._v(" "), _vm.suffixText ? _c("span", [_c("br"), _vm._v("\n            " + _vm._s(_vm.suffixText) + "\n          ")]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "fa-remove",
      on: {
        click: function click($event) {
          return _vm.removeUser(i);
        }
      }
    }, [_c("a", [_c("svg-icon", {
      attrs: {
        icon: "kv-icon-times"
      }
    })], 1)])])];
  }), _vm._v(" "), _c("div", {
    staticClass: "add-avatar",
    on: {
      click: function click($event) {
        return _vm.start_search();
      }
    }
  }, [_vm._m(0)])], 2) : _c("div", {
    ref: "kvUserSelectBox" + _vm.key,
    staticClass: "kvUserDisplay anyone",
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        return _vm.start_search();
      }
    }
  }, [_vm._v("\n    " + _vm._s(_vm.placeholder ? _vm.placeholder : _vm.$t("Anyone")) + "\n  ")]), _vm._v(" "), _vm.show_users ? _c("kv-user-select-box-multiple", {
    staticStyle: {
      "z-index": "999"
    },
    attrs: {
      show: _vm.show_users,
      selectedNumRow: _vm.selectedNumRow,
      selectedData: _vm.data,
      disableUsers: _vm.disableUsers,
      size: _vm.size,
      boxStyle: _vm.boxStyle
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.show_users = $event;
        _vm.keyEventTab();
      },
      updateData: function updateData($event) {
        return _vm.updateData($event.data, $event.type, $event.singleSelect);
      }
    },
    model: {
      value: _vm.newData,
      callback: function callback($$v) {
        _vm.newData = $$v;
      },
      expression: "newData"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("span", {
    staticClass: "text-avatar"
  }, [_c("a", [_vm._v("+")])]);
}];
render._withStripped = true;
export { render, staticRenderFns };