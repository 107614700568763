import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.taskData ? _c("transition", {
    attrs: {
      name: "modal"
    }
  }, [_c("div", {
    staticClass: "modal-mask"
  }, [_c("div", {
    staticClass: "modal-wrapper",
    staticStyle: {
      position: "relative"
    }
  }, [_c("div", {
    key: _vm.modal_key,
    staticClass: "task-edit-modal pt-0 pos-rel",
    attrs: {
      id: "commentsContainer1"
    }
  }, [_vm.loading ? _c("kv-loading") : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "fixed_header"
  }, [_c("el-row", {
    attrs: {
      gutters: "20"
    }
  }, [_c("el-col", {
    staticClass: "mb-2",
    attrs: {
      span: 18
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [!_vm.taskData.record_id ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.save_task_first_time("standart");
      }
    }
  }, [_c("li", {
    class: "fa fa-save"
  }), _vm._v("\n                      " + _vm._s(_vm.$t("Save")) + "\n                  ")]) : _vm._e(), _vm._v(" "), _vm.taskData.task_status && _vm.taskData.task_status.value != "3" ? _c("el-button", {
    attrs: {
      type: "success",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_completed();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-check-square"
  }), _vm._v("\n                      " + _vm._s(_vm.$t("task.Complete")) + "\n                  ")]) : _vm._e(), _vm._v(" "), _vm.taskData.task_status && _vm.taskData.task_status.value == "3" ? _c("el-button", {
    attrs: {
      type: "purple",
      size: "small",
      plain: "",
      disabled: _vm.buttonsDisabled
    },
    on: {
      click: function click($event) {
        return _vm.change_task_to_open();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-check-square"
  }), _vm._v("\n                      " + _vm._s(_vm.$t("Re-Open")) + "\n                  ")]) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "mb-2 align-right text-right",
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "grid-content"
  }, [_vm.taskData.noteCount ? _c("el-badge", {
    staticClass: "item margin-left-5 margin-right-5 cursor-pointer",
    attrs: {
      value: _vm.taskData.noteCount,
      max: 9,
      type: "warning"
    }
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.scrollTo("comments");
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-comment-alt"
  })])]) : _vm._e(), _vm._v(" "), _vm.taskData.dependency_count && _vm.taskData.dependency_count != "0/0" ? _c("el-badge", {
    staticClass: "item margin-right-5 margin-left-5 cursor-pointer",
    attrs: {
      value: _vm.taskData.dependency_count,
      type: "warning"
    }
  }, [_c("a", {
    on: {
      click: function click($event) {
        return _vm.scrollTo("comments");
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-link"
  })])]) : _vm._e(), _vm._v(" "), _c("span", {
    staticStyle: {
      "line-height": "35px",
      height: "35px",
      width: "1px"
    }
  }, [_c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.saving,
      expression: "saving"
    }],
    staticClass: "no-border pa-0",
    attrs: {
      size: "medium",
      color: "default",
      circle: "",
      plain: "",
      type: "text"
    }
  }, [_c("i", {
    key: "iconloading",
    staticClass: "el-icon-loading fa-2x kv--color"
  })]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.saving && _vm.$store.state.response_alert.status,
      expression: "!saving && $store.state.response_alert.status"
    }],
    staticClass: "no-border pa-0",
    attrs: {
      size: "medium",
      color: "success",
      circle: "",
      plain: "",
      type: "text"
    }
  }, [_c("i", {
    key: "response_alerttrue",
    staticClass: "fa fa-2 fa-check"
  })]), _vm._v(" "), _c("span", {
    staticClass: "margin-left-5 pa-5 cursor-pointer",
    class: !_vm.saving && !_vm.$store.state.response_alert.status ? "" : "disabled",
    attrs: {
      size: "medium",
      type: "text"
    },
    on: {
      click: function click($event) {
        !_vm.saving ? _vm.set_updates() : "";
      }
    }
  }, [_c("i", {
    key: "fatimes",
    staticClass: "fa fa-lg kv--color fa-times"
  })])], 1)], 1)])], 1)], 1), _vm._v(" "), _c("section", {
    staticClass: "taskForm"
  }, [_c("el-row", [_c("el-col", {
    staticStyle: {
      width: "650px"
    }
  }, [_c("div", {
    staticClass: "taskForm-main kv-scroll"
  }, [_c("div", {
    staticClass: "formItem"
  }, [_c("el-input", {
    staticClass: "kv--input hover-bg font-16 font-weight-600",
    attrs: {
      type: "textarea",
      autosize: {
        minRows: 2,
        maxRows: 5
      },
      "aria-describedby": "subjectHelp",
      placeholder: _vm.$t("task.Subject")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "subject", $$v);
      },
      expression: "taskData.subject"
    }
  })], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("description") ? "" : "margin-bottom-5",
    attrs: {
      span: _vm.findVisibility("description") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("Description")) + "\n                    ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.findVisibility("description") ? 17 : 24
    }
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.findVisibility("description") && _vm.editable,
      expression: "findVisibility('description') && editable"
    }],
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        return _vm.showField("description");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add more information to this task")))]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.findVisibility("description"),
      expression: "!findVisibility('description')"
    }]
  }, [_c("redactor", {
    staticClass: "margin-top-5 margin-bottom-5 kv-border-default",
    attrs: {
      config: _vm.configOptions
    },
    model: {
      value: _vm.taskData.description,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "description", $$v);
      },
      expression: "taskData.description"
    }
  })], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle margin-bottom-10",
    attrs: {
      span: _vm.findVisibility("sub_tasks") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("task.Sub Tasks")) + " " + _vm._s(_vm.taskData.sub_tasks) + "\n                    ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("sub-tasks", {
    key: _vm.taskData.record_id,
    attrs: {
      new_task: _vm.taskData
    },
    on: {
      subTasks: function subTasks($event) {
        _vm.subTasks = $event;
      }
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "formItem"
  }, [_c("el-col", {
    staticClass: "formTitle",
    class: _vm.findVisibility("documents") ? "" : "margin-bottom-10",
    attrs: {
      span: _vm.findVisibility("documents") ? 7 : 24
    }
  }, [_vm._v(_vm._s(_vm.$t("task.Documents")) + "\n                    ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.findVisibility("documents") ? 17 : 24
    }
  }, [_vm.findVisibility("documents") && _vm.editable ? _c("span", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    staticClass: "ifAreaNull",
    on: {
      click: function click($event) {
        _vm.showField("documents");
        _vm.documents_key = Math.random(100);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("task.+ add file")))]) : _vm._e()]), _vm._v(" "), _c("el-col", {
    key: "file" + _vm.taskData.record_id,
    attrs: {
      span: 24
    }
  }, [_c("attachments", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    key: "f" + _vm.taskData.record_id,
    attrs: {
      record_id: _vm.taskData.record_id,
      visibility: _vm.fieldVisible.documents,
      documents_key: _vm.documents_key,
      task: _vm.task,
      app: "act",
      model: "task",
      attachmentsList: _vm.attachmentsList
    },
    on: {
      showAttachments: function showAttachments($event) {
        _vm.fieldVisible.documents = $event;
      },
      updateAttachments: function updateAttachments($event) {
        _vm.attachmentsList = $event;
      }
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "formItem",
    attrs: {
      id: "commentField1"
    }
  }, [_vm.taskData.record_id ? _c("note-list", {
    key: _vm.taskData.record_id,
    attrs: {
      endpoint: "act/task",
      id: _vm.taskData.record_id,
      showAvatar: true,
      formPosition: "bottom",
      formFixed: true,
      formRadiusClass: "only-left-bottom-radius",
      formStyle: {
        width: "650px",
        marginLeft: "-15px",
        bottom: "5vh"
      }
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.taskData && _vm.taskData.created_by && _vm.taskData.created_by.label ? _c("div", {
    staticClass: "footer font-11",
    staticStyle: {
      "margin-bottom": "50px"
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("Created on")) + " " + _vm._s(_vm._f("datetime")(_vm.taskData.created_on)) + " " + _vm._s(_vm.$t("by")) + " " + _vm._s(_vm.taskData.created_by.label))]), _vm._v(" "), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _vm._v(" "), _vm.taskData.last_modified_by && _vm.taskData.last_modified_by.label ? _c("span", [_vm._v(_vm._s(_vm.$t("Last Update on")) + "  " + _vm._s(_vm._f("datetime")(_vm.taskData.last_modified_on)) + " " + _vm._s(_vm.$t("by")) + " " + _vm._s(_vm.taskData.last_modified_by.label))]) : _vm._e()], 1) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      width: "250px"
    }
  }, [_c("div", {
    staticClass: "taskForm-sidebar kv-scroll"
  }, [_vm.taskData.thread && _vm.taskData.thread.value || _vm.taskData.thread && typeof _vm.taskData.thread == "number" ? _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "margin-right-5"
  }, [_c("i", {
    staticClass: "fal fa-inbox"
  }), _vm._v("\n                      " + _vm._s(_vm.taskData.thread && _vm.taskData.thread.value ? "#000" + _vm.taskData.thread.value : "") + "\n\n                      " + _vm._s(_vm.taskData.thread && typeof _vm.taskData.thread == "number" ? "#000" + _vm.taskData.thread : "") + "\n                    ")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("assignTo")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-user-circle"
  })]), _vm._v(" "), _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      filterable: "",
      multiple: "",
      "default-first-option": "",
      placeholder: _vm.$t("task.Assigned To")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.followers_array,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "followers_array", $$v);
      },
      expression: "taskData.followers_array"
    }
  }, _vm._l(_vm.users, function (item) {
    return _c("el-option", {
      key: item.userId,
      attrs: {
        label: item.firstname + " " + item.lastname,
        value: item.userId
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("task.Deadline")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-calendar"
  })]), _vm._v(" "), _c("el-date-picker", {
    staticClass: "width-full kv--input sub-border left-icon",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      "picker-options": _vm.$datepickerOptions,
      type: "date",
      format: "dd-MM-yyyy",
      "value-format": "yyyy-MM-dd",
      placeholder: _vm.$t("task.Pick a day")
    },
    on: {
      change: function change($event) {
        return _vm.triggerSaveTask();
      }
    },
    model: {
      value: _vm.taskData.end_date_dmy,
      callback: function callback($$v) {
        _vm.$set(_vm.taskData, "end_date_dmy", $$v);
      },
      expression: "taskData.end_date_dmy"
    }
  })], 1)])])], 1)], 1)], 1)])])]) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };