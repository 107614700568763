var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 10
    }
  }, _vm._l(_vm.data, function (d, index) {
    return _c("el-col", {
      key: index,
      staticClass: "card-panel-col",
      attrs: {
        xs: d.size.xs,
        sm: d.size.sm,
        lg: d.size.lg
      }
    }, [_c("div", {
      staticClass: "card-panel kv-widget-dashboard"
    }, [_c("div", {
      staticClass: "card-panel-description"
    }, [_c("div", {
      staticClass: "card-panel-title"
    }, [_c("i", {
      staticClass: "fal fa-tasks"
    }), _vm._v(" " + _vm._s(d.title))]), _vm._v(" "), _c("el-row", [_c("el-col", {
      staticClass: "mb-2",
      attrs: {
        span: 16
      }
    }, [_vm._v(_vm._s(d.title1))]), _vm._v(" "), _c("el-col", {
      staticClass: "text-right mb-2",
      attrs: {
        span: 8
      }
    }, [_vm._v("%" + _vm._s(d.value1))]), _vm._v(" "), _c("el-col", {
      staticClass: "mb-5",
      attrs: {
        span: 24
      }
    }, [_c("el-progress", {
      attrs: {
        percentage: d.value1 ? d.value1 : 0,
        "show-text": false
      }
    })], 1), _vm._v(" "), _c("el-col", {
      staticClass: "mb-2",
      attrs: {
        span: 24
      }
    }, [_c("el-progress", {
      attrs: {
        percentage: d.value2 ? d.value2 : 0,
        status: "warning",
        "show-text": false
      }
    })], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 16
      }
    }, [_vm._v(_vm._s(d.title2))]), _vm._v(" "), _c("el-col", {
      staticClass: "text-right",
      attrs: {
        span: 8
      }
    }, [_vm._v("%" + _vm._s(d.value2))])], 1)], 1)])]);
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };