import { state } from './state';
import actions from './actions';
import mutations from './mutations';
// import getters from './getters'

export var notification = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations
};