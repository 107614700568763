import "core-js/modules/es.json.stringify.js";
export function inboxMessageModel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign({
    thread: null,
    message: null,
    strippedMessage: null,
    sender: 2,
    sendDate: null,
    draft: 0,
    senderContact: null,
    senderName: null,
    senderEmail: null,
    createdOn: null,
    createdBy: null,
    recordOwner: null,
    recordId: null
  }, JSON.parse(JSON.stringify(data)));
}