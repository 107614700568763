var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: ["app-is-".concat(_vm.$isApp)],
    attrs: {
      id: "app"
    }
  }, [_c(_vm.routeMeta.layout, {
    tag: "component"
  }, [_c("router-view")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };