import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var checklistRoutes = [{
  path: '',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/checklists/ChecklistList');
  },
  name: 'rulesup.checklists.list',
  meta: {
    title: 'List',
    icon: 'fal fa-registered'
  },
  props: true
}, {
  path: 'detail/:id',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/checklists/ChecklistDetail');
  },
  name: 'rulesup.checklists.detail',
  meta: {
    title: 'Checklist Detail',
    icon: 'fal fa-registered'
  },
  props: true
}, {
  path: 'overview/id',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/checklists/ChecklistOverview');
  },
  name: 'rulesup.checklists.overview',
  meta: {
    title: 'View Overview',
    icon: 'fal fa-registered'
  },
  props: true
}];