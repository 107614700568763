import "core-js/modules/es.object.to-string.js";
import KivaProxy from '@/utils/kivaProxy/src';
import moment from 'moment';
import { DataQuery1 } from "@/transformers/DataQuery1";
import { STARRED_PROJECTS } from './mutation-types';
export default {
  getStarredProjects: function getStarredProjects(_ref) {
    var commit = _ref.commit,
      rootState = _ref.rootState;
    var me = rootState.users.CURRENT_USER;
    if (me) {
      return new Promise(function (resolve, reject) {
        new KivaProxy({
          endpoint: "kvc/data/query/24",
          driver: "base"
        }).setFilters({}).setLimit(200).setSort('project_name').setDir('ASC').all().then(function (response) {
          var projects = DataQuery1.fetchCollection(response.data);
          if (projects && projects[0]) {
            commit(STARRED_PROJECTS, projects);
            resolve();
          } else {
            commit(STARRED_PROJECTS, []);
            resolve();
          }
        }).catch(function (err) {
          console.log("err", err);
          commit(STARRED_PROJECTS, []);
          resolve();
        });
      });
    } else {
      commit(STARRED_PROJECTS, []);
      // resolve()
    }
  }
};