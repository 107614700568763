var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-select", _vm._g(_vm._b({
    attrs: {
      value: _vm.value,
      "value-key": "value",
      clearable: "",
      filterable: "",
      loading: _vm.loading,
      autocomplete: "nope"
    },
    on: {
      input: _vm.input
    }
  }, "el-select", _vm.attrs, false), _vm.listeners), _vm._l(_vm.countries, function (country) {
    return _c("el-option", {
      key: country.value,
      attrs: {
        value: country,
        label: country.countryName
      }
    });
  }), 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };