import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _USERS$USERTOTAL$CURR;
import { CURRENT_USER, USERS, USERTOTAL, UX_PROFILE, MY_UX_PROFILE, MY_ACTIVITY_STATUS } from '@/store/modules/users/mutation-types';
export default (_USERS$USERTOTAL$CURR = {}, _defineProperty(_USERS$USERTOTAL$CURR, USERS, function (state, payload) {
  state[USERS] = payload;
}), _defineProperty(_USERS$USERTOTAL$CURR, USERTOTAL, function (state, payload) {
  state[USERTOTAL] = payload;
}), _defineProperty(_USERS$USERTOTAL$CURR, CURRENT_USER, function (state, payload) {
  state[CURRENT_USER] = payload;
}), _defineProperty(_USERS$USERTOTAL$CURR, UX_PROFILE, function (state, payload) {
  state[UX_PROFILE] = payload;
}), _defineProperty(_USERS$USERTOTAL$CURR, MY_UX_PROFILE, function (state, payload) {
  state[MY_UX_PROFILE] = payload;
}), _defineProperty(_USERS$USERTOTAL$CURR, MY_ACTIVITY_STATUS, function (state, payload) {
  state[MY_ACTIVITY_STATUS] = payload;
}), _USERS$USERTOTAL$CURR);