RedactorX.add('plugin', 'imageposition', {
  translations: {
    en: {
      "imageposition": {
        "image-position": "Image position"
      }
    }
  },
  defaults: {
    icon: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m15 13c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-13c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm-5-12c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm5 8c.5522847 0 1 .44771525 1 1 0 .5522847-.4477153 1-1 1h-2c-.5522847 0-1-.4477153-1-1 0-.55228475.4477153-1 1-1zm-10-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm6 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1z"/></svg>',
    items: {
      none: 'none',
      left: 'float-left',
      center: 'align-center',
      right: 'float-right'
    },
    icons: {
      none: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m12 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm-5 7-1 1h2zm4-5h-6v5l2-2 3 3h1zm-2 1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1z"/></svg>',
      left: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m10 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm5 8c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.4477153-1-1s.4477153-1 1-1zm-10-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm6 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1zm-8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1h-2c-.5522847 0-1-.44771525-1-1s.4477153-1 1-1z"/></svg>',
      center: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m12 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm3 8c.5522847 0 1 .4477153 1 1s-.4477153 1-1 1-1-.4477153-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .4477153 1 1s-.44771525 1-1 1-1-.4477153-1-1 .44771525-1 1-1zm6-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm4 2c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm8-1c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1zm6-3c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-14 0c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1-1-.44771525-1-1 .44771525-1 1-1z"/></svg>',
      right: '<svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"><path d="m14 3c.5522847 0 1 .44771525 1 1v8c0 .5522847-.4477153 1-1 1h-8c-.55228475 0-1-.4477153-1-1v-8c0-.55228475.44771525-1 1-1zm-11 8c.55228475 0 1 .4477153 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.4477153-1-1s.44771525-1 1-1zm6-1-1 1h2zm4-5h-6v5l2-2 3 3h1zm-10 2c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1zm8-1c.5522847 0 1 .44771525 1 1s-.4477153 1-1 1-1-.44771525-1-1 .4477153-1 1-1zm-8-3c.55228475 0 1 .44771525 1 1s-.44771525 1-1 1h-2c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1z"/></svg>'
    }
  },
  start: function () {
    this.app.toolbar.add('imageposition', {
      title: '## imageposition.image-position ##',
      command: 'imageposition.popup',
      icon: this.opts.imageposition.icon,
      position: {
        after: 'image'
      },
      blocks: {
        types: ['image']
      }
    });
  },
  popup: function (params, button) {
    var segments = {};
    var obj = this.opts.imageposition.items;
    for (var key in obj) {
      if (!obj[key]) continue;
      segments[key] = {
        name: obj[key],
        icon: this.opts.imageposition.icons[key]
      };
    }

    // create
    this.app.popup.create('imageposition', {
      setter: 'imageposition.setFloat',
      getter: 'imageposition.getFloat',
      form: {
        "ifloat": {
          type: 'segment',
          label: '## imageposition.image-position ##',
          segments: segments
        }
      }
    });

    // open
    this.app.popup.open({
      button: button
    });
  },
  getFloat: function () {
    var obj = this.opts.imageposition.items;
    if (!obj) return false;
    var instance = this.app.block.get();
    var $block = instance.getBlock();
    var value = 'none';
    for (var key in obj) {
      if ($block.hasClass(obj[key])) {
        value = key;
      }
    }
    return {
      'ifloat': value
    };
  },
  setFloat: function (popup) {
    this.app.popup.close();

    // get data
    var data = popup.getData();
    var instance = this.app.block.get();
    instance.setClassFromObj(this.opts.imageposition.items, data.ifloat);
  }
});