import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.push.js";
import { VueEditor } from "vue2-editor";
import KivaProxy from "@/utils/kivaProxy/src";
import { sendMailModel } from "@/models/sendMailModel";
import emailMixin from "@/mixins/email.js";
export default {
  name: "InvoiceSendMail",
  components: {
    VueEditor: VueEditor,
    kvContacts: function kvContacts() {
      return import("@/components/kiva/remote-search/kvContacts");
    }
  },
  mixins: [emailMixin],
  props: {
    value: {},
    readyData: {
      type: Object,
      default: function _default() {
        return {
          to: ""
        };
      }
    },
    paddingClass: {
      type: String,
      default: "wide" //no-padding
    }
  },
  data: function data() {
    return {
      toData: [],
      fromData: [],
      loading: false,
      addCC: false,
      addBCC: false,
      filesVisibility: false,
      templateData: [],
      fileList: [],
      contactKeys: 'contactKeys',
      setRow: 2,
      configOptions: {
        plugins: ["alignment", "clips", "fontsize"],
        buttonsHide: ["align", "strikethrough"],
        buttons: ["redo", "undo", "format", "bold", "italic", "lists", "image", "file", "link", "clips"],
        editor: {
          focus: false
        }
      },
      model: sendMailModel({
        scheduleDate: this.value,
        appId: 'crm',
        modelId: 'contact'
      })
    };
  },
  methods: {
    sendMail: function sendMail() {
      var _this = this;
      var data = new FormData();

      // data.append('to',  this.model.to.target_email);
      Object.keys(this.model).forEach(function (key) {
        var value = Array.isArray(_this.model[key]) ? _this.model[key].join(",") : _this.model[key] && _this.model[key].target_email ? _this.model[key].target_email : _this.model[key];
        data.append(key, value);
      });
      if (this.$refs.files && this.$refs.files.uploadFiles.length) {
        this.$refs.files.uploadFiles.forEach(function (file) {
          if (!file.defaultTemplate) {
            data.append("attachment[]", file.raw);
          }
        });
      }
      new KivaProxy({
        endpoint: "kvc/message/email",
        driver: "base"
      }).submit("post", "kvc/message/email", data).then(function (response) {
        if (response.success == true) {
          _this.$notify({
            title: 'Success',
            message: _this.$t('Mail sent succesfully.'),
            type: 'success'
          });
          _this.model = sendMailModel({
            scheduleDate: _this.value,
            appId: 'crm',
            modelId: 'contact'
          });
        } else if (response.success == false) {
          _this.$notify({
            title: 'Failed',
            message: _this.$t('Mail could not send.'),
            type: 'warning'
          });
        }
      });
    },
    setTemplateHtml: function setTemplateHtml(val) {
      var _this2 = this;
      this.loading = true;
      new KivaProxy({
        endpoint: "kvc/template/message",
        driver: "base"
      }).find(val).then(function (response) {
        _this2.model.body_html = response.data.content;
        _this2.model.subject = response.data.label;
        _this2.loading = false;
      }).finally(function () {
        _this2.loading = false;
      });
    },
    handleRemove: function handleRemove(file) {
      if (file.defaultTemplate) {
        this.model.includeTemplate = false;
      }
    },
    closeDialog: function closeDialog() {
      this.$emit("close");
    }
  },
  watch: {
    value: function value(val) {
      this.model.scheduleDate = val;
    },
    "readyData.to": function readyDataTo(val) {
      this.model.to = [];
      if (val) {
        this.model.to.push(val);
      } else {
        this.model.to = [];
      }
    },
    readyData: function readyData(val) {
      this.model.to = [];
      if (val && val.to) {
        this.model.to.push(val.to);
      } else {
        this.model.to = [];
      }
    },
    "model.scheduleDate": function modelScheduleDate(val) {
      this.$emit("input", val);
    }
  }
};