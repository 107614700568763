export var state = {
  pageTypes: [],
  pages: [],
  pagesFormated: [],
  page: {},
  collections: [],
  categories: [],
  pins: {
    home: []
  },
  activeIndex: null,
  selectedPins: [],
  mutableSelectedPage: {},
  selectedPage: {},
  selectedCollection: {}
};