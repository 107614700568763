import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import KivaProxy from "@/utils/kivaProxy/src";
export default {
  props: {
    value: [Object, Number]
  },
  data: function data() {
    return {
      mutableValue: this.value,
      accountLoading: false,
      accounts: [],
      stable: {},
      counter: {
        duration: 300,
        interval: null
      }
    };
  },
  created: function created() {
    this.accountLoading = true;
    this.setValue();
  },
  methods: {
    startInterval: function startInterval(query) {
      var _this = this;
      if (this.counter.interval) {
        clearInterval(this.counter.interval);
      }
      this.counter.interval = setInterval(function () {
        _this.getAccounts(query);
        clearInterval(_this.counter.interval);
      }, this.counter.duration);
    },
    setValue: function setValue() {
      Object.assign(this.mutableValue, this.value);
      if (this.value && this.value != null && this.value.value && this.value.label) {
        this.stable = {
          record_id: this.value.value,
          account_name: this.value.label
        };
        this.accounts.push(this.stable);
      }
      this.accountLoading = false;
    },
    getAccounts: function getAccounts(query) {
      var _this2 = this;
      if (query && query.length > 2) {
        this.accountLoading = true;
        var search = JSON.stringify({
          account_name: ["LIKE", query]
        });
        new KivaProxy({
          endpoint: "crm/account"
        }).setFilters(search).all().then(function (response) {
          _this2.accounts = response.records;
          _this2.accounts.push(stable);
          _this2.accountLoading = false;
        }).catch(function (err) {
          _this2.accountLoading = false;
        });
      }
    }
  },
  watch: {
    mutableValue: function mutableValue(val) {
      this.$emit("input", {
        value: val.value
      });
    },
    "mutableValue.value": function mutableValueValue(val) {
      this.$emit("input", {
        value: val
      });
    },
    value: function value(val) {
      this.setValue();
    },
    "value.value": function valueValue(val) {
      this.setValue();
    }
  }
};