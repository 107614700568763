import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("kv-modal", {
    staticClass: "thread-modal thread-modal-widest inbox-detail",
    class: _vm.templatePopup.show ? "backSide thread-modal-widest" : "",
    attrs: {
      size: "wide-2",
      cols: 2
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c("div", {
          staticClass: "title-field"
        }, [_vm._v("\n        " + _vm._s(!_vm.model.threadId ? _vm.$t("Start New Message Thread") : _vm.$t("Edit Thread") + " #" + _vm.id) + "\n      ")]), _vm._v(" "), _vm.WorkPermission() && _vm.model.threadId ? _c("counter-action-button", {
          attrs: {
            row: _vm.model,
            type: "THREAD",
            buttonClass: {
              "no-border": true
            },
            isShowTime: true
          }
        }) : _vm._e(), _vm._v(" "), _c("div", {
          staticStyle: {
            display: "inline-flex"
          }
        }, [_vm.id ? _c("el-button", {
          staticStyle: {
            color: "#fff"
          },
          attrs: {
            type: "primary",
            title: _vm.$t("Reply")
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleReply(_vm.messages[_vm.messages.length - 1]);
            }
          }
        }, [_c("kv-svg-icon", {
          staticStyle: {
            color: "#fff"
          },
          attrs: {
            icon: "kv-icon-reply"
          }
        }), _vm._v("\n          " + _vm._s(_vm.$t("Reply")))], 1) : _vm._e(), _vm._v(" "), !_vm.model.requestType || _vm.model.requestType.value == 1 ? _c("div", {
          staticClass: "status-field"
        }, [_vm.model.caseStatus && _vm.options["inbox_thread_case_status"] && _vm.options["inbox_thread_case_status"][0] ? _c("el-select", {
          staticClass: "order-filter",
          attrs: {
            size: "medium"
          },
          on: {
            change: function change($event) {
              return _vm.save();
            }
          },
          model: {
            value: _vm.model.caseStatus.value,
            callback: function callback($$v) {
              _vm.$set(_vm.model.caseStatus, "value", $$v);
            },
            expression: "model.caseStatus.value"
          }
        }, _vm._l(_vm.options["inbox_thread_case_status"], function (status) {
          return _c("el-option", {
            key: status.value,
            attrs: {
              value: status.value,
              label: status.label
            }
          });
        }), 1) : _vm._e()], 1) : _vm.model.requestType && _vm.model.requestType.value == 2 ? _c("div", {
          staticClass: "status-field"
        }, [_vm.model.requestStatus && _vm.options["inbox_thread_request_status"] && _vm.options["inbox_thread_request_status"][0] ? _c("el-select", {
          staticClass: "order-filter",
          attrs: {
            size: "medium"
          },
          on: {
            change: function change($event) {
              return _vm.save();
            }
          },
          model: {
            value: _vm.model.requestStatus.value,
            callback: function callback($$v) {
              _vm.$set(_vm.model.requestStatus, "value", $$v);
            },
            expression: "model.requestStatus.value"
          }
        }, _vm._l(_vm.options["inbox_thread_request_status"], function (status) {
          return _c("el-option", {
            key: status.value,
            attrs: {
              value: status.value,
              label: status.label
            }
          });
        }), 1) : _vm._e()], 1) : _vm.model.requestType && _vm.model.requestType.value == 3 ? _c("div", {
          staticClass: "status-field"
        }, [_vm.model.salesInquiryStatus && _vm.options["inbox_thread_sales_inquiry_status"] && _vm.options["inbox_thread_sales_inquiry_status"][0] ? _c("el-select", {
          staticClass: "order-filter",
          attrs: {
            size: "medium"
          },
          model: {
            value: _vm.model.salesInquiryStatus.value,
            callback: function callback($$v) {
              _vm.$set(_vm.model.salesInquiryStatus, "value", $$v);
            },
            expression: "model.salesInquiryStatus.value"
          }
        }, _vm._l(_vm.options["inbox_thread_sales_inquiry_status"], function (status) {
          return _c("el-option", {
            key: status.value,
            attrs: {
              value: status.value,
              label: status.label
            }
          });
        }), 1) : _vm._e()], 1) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            icon: "fal fa-save"
          },
          on: {
            click: function click($event) {
              return _vm.createOrEditThread(false);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Save")))]), _vm._v(" "), !_vm.model.threadId && !_vm.id ? _c("el-button", {
          attrs: {
            type: "primary",
            icon: "fal fa-save"
          },
          on: {
            click: function click($event) {
              return _vm.createOrEditThread(true);
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Save & Create New")))]) : _vm._e(), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger",
            icon: "fal fa-times"
          },
          on: {
            click: function click($event) {
              _vm.mutableValue = false;
            }
          }
        }, [_vm._v(_vm._s(_vm.$t("Close")))])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, [_vm._v(" "), _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    key: _vm.model.threadId,
    staticClass: "padding-0",
    attrs: {
      "label-width": "130px",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "thread-container"
  }, [_c("div", {
    staticStyle: {
      "min-width": "240px"
    }
  }, [_c("div", {
    staticClass: "inbox-sidebar kv-scroll"
  }, [_c("el-row", {
    staticClass: "formItem",
    attrs: {
      row: "",
      wrap: ""
    }
  }, [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Company Name")) + "\n                ")]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-building"
  })]), _vm._v(" "), _c("kv-account", {
    key: _vm.model.id + "a" + _vm.skey,
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      allowCreate: false,
      defaultNewAccount: _vm.defaultNewAccount,
      width: "350",
      popup: false,
      skey: "a" + _vm.skey,
      multiple: false
    },
    on: {
      isFormOpen: function isFormOpen($event) {
        _vm.formOpen["account"] = $event;
      },
      save: function save($event) {
        _vm.model.newContact.account_name = _vm.model.accountName ? _vm.model.accountName.value : null;
        _vm.valuesave();
      }
    },
    model: {
      value: _vm.model.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "accountName", $$v);
      },
      expression: "model.accountName"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_vm.isNewContact == false ? _c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Contact")) + "\n                ")]) : _vm._e(), _vm._v(" "), _vm.isNewContact == false ? _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-user-circle"
  })]) : _vm._e(), _vm._v(" "), _c("kv-contacts", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isNewContact == false,
      expression: "isNewContact == false"
    }, {
      name: "validate",
      rawName: "v-validate",
      value: "required",
      expression: "'required'"
    }],
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      account: _vm.model.accountName,
      allowCreate: false,
      skey: _vm.skey + _vm.model.accountName.value,
      multiple: false
    },
    on: {
      input: function input($event) {
        return _vm.fillThreadForm($event);
      }
    },
    model: {
      value: _vm.model.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "contactName", $$v);
      },
      expression: "model.contactName"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Project")) + "\n                ")]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-building"
  })]), _vm._v(" "), _c("kv-project", {
    key: _vm.model.recordId,
    staticClass: "width-full left-icon kv--input sub-border",
    on: {
      setAccount: function setAccount($event) {
        _vm.model.accountName.label = $event.label;
        _vm.model.accountName.value = $event.value;
        _vm.model.newContact.account_name = $event.value ? $event.value : null;
      },
      change: function change($event) {
        _vm.model.section.label = "";
        _vm.model.section.value = null;
      }
    },
    model: {
      value: _vm.model.project,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "project", $$v);
      },
      expression: "model.project"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Section")) + "\n                ")]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-tasks"
  })]), _vm._v(" "), _c("keep-alive", [_c("kv-section", {
    key: "section" + _vm.model.project.value,
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      project: _vm.model.project.value
    },
    on: {
      input: function input($event) {
        _vm.model.section.label = $event.label;
        _vm.model.section.value = $event.value;
      }
    },
    model: {
      value: _vm.model.section,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "section", $$v);
      },
      expression: "model.section"
    }
  })], 1)], 1), _vm._v(" "), _vm.isNewContact == true ? [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Name")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-user-circle"
  })]), _vm._v(" "), _c("el-input", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("First Name")
    },
    model: {
      value: _vm.model.newContact.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.model.newContact, "firstname", $$v);
      },
      expression: "model.newContact.firstname"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Last Name")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-user-circle"
  })]), _vm._v(" "), _c("el-input", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Last Name")
    },
    model: {
      value: _vm.model.newContact.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.model.newContact, "lastname", $$v);
      },
      expression: "model.newContact.lastname"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("E-Mail")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-at"
  })]), _vm._v(" "), _c("el-input", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("E-Mail"),
      "value-key": "value"
    },
    model: {
      value: _vm.model.newContact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.model.newContact, "email", $$v);
      },
      expression: "model.newContact.email"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Phone")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-phone"
  })]), _vm._v(" "), _c("el-input", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Phone")
    },
    model: {
      value: _vm.model.newContact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.model.newContact, "phone", $$v);
      },
      expression: "model.newContact.phone"
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.model.id,
      expression: "model.id"
    }],
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(" " + _vm._s(_vm.$t("Time Entry")))]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-popover", {
    staticClass: "icon-list",
    attrs: {
      placement: _vm.$t("right"),
      width: "400",
      trigger: "hover"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.timeEntry.list
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "100",
      property: "start_date",
      label: _vm.$t("Date")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "100",
      property: "duration_formatted",
      label: _vm.$t("Duration")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      property: "record_owner.label",
      label: _vm.$t("Owner")
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "50",
      label: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("el-link", {
          on: {
            click: function click($event) {
              _vm.timeEntry.selected = _objectSpread({}, row);
              _vm.timeEntry.show = true;
              _vm.timeEntry.key = Math.random(100);
            }
          }
        }, [_c("i", {
          staticClass: "fa fa-edit"
        })])];
      }
    }])
  })], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("List")))]), _vm._v(" "), _c("span", {
    staticClass: "textBottomLine"
  }, [_c("a", {
    staticClass: "no-border icon-list"
  }, [_c("i", {
    staticClass: "fal fa-list"
  })])])])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("All Entries")))]), _vm._v(" "), _vm.timeEntry.list && _vm.timeEntry.list[0] ? _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s("" + _vm.total_time() + ""))]) : _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s(_vm.$t("-")))])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("span", {
    staticClass: "demonstrationCentered"
  }, [_vm._v(_vm._s(_vm.$t("My Spent")))]), _vm._v(" "), _c("span", {
    staticClass: "textBottomLine"
  }, [_vm._v(_vm._s("" + _vm.total_time("my") + "") + "\n                      "), _c("a", {
    on: {
      click: function click($event) {
        return _vm.showTimeEntryForm();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })])])])], 1)], 1)], 2)], 1)]), _vm._v(" "), !_vm.id && _vm.mutableValue || _vm.editPermission ? _c("div", {
    staticStyle: {
      width: "100%",
      height: "calc(80vh - 120px)",
      overflow: "auto"
    }
  }, [_c("el-row", {
    staticClass: "formItem padding-20",
    attrs: {
      row: "",
      wrap: ""
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticStyle: {
      "text-align": "center",
      width: "100%",
      "margin-top": "-7px",
      "margin-bottom": "7px"
    }
  }, [_vm.id && _vm.editPermission ? _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      icon: "fal fa-times"
    },
    on: {
      click: function click($event) {
        _vm.editPermission = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel  Editing")))]) : _vm._e()], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "margin-0"
  }, [_c("el-input", {
    directives: [{
      name: "validate",
      rawName: "v-validate",
      value: "required",
      expression: "'required'"
    }],
    attrs: {
      placeholder: _vm.$t("Subject")
    },
    on: {
      change: function change($event) {
        return _vm.save();
      }
    },
    model: {
      value: _vm.model.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "subject", $$v);
      },
      expression: "model.subject"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col"), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show.cc,
      expression: "show.cc"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("CC")
    }
  }, [_c("kv-contacts-email", {
    attrs: {
      skey: _vm.contactKeys,
      multiple: true
    },
    on: {
      input: function input($event) {
        _vm.model.cc = $event;
      }
    },
    model: {
      value: _vm.model.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "cc", $$v);
      },
      expression: "model.cc"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show.bcc,
      expression: "show.bcc"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("BCC")
    }
  }, [_c("kv-contacts-email", {
    attrs: {
      skey: _vm.contactKeys,
      multiple: true
    },
    on: {
      input: function input($event) {
        _vm.model.bcc = $event;
      }
    },
    model: {
      value: _vm.model.bcc,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "bcc", $$v);
      },
      expression: "model.bcc"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_vm.model.caseStatus == 3 ? _c("h4", [_vm._v(_vm._s(_vm.$t("Message")))]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "flex-toolbar"
  }, [!_vm.isEdit ? _c("div", {
    attrs: {
      id: "editor-toolbar"
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "redactor-toolbar"
  }, [_c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.show.cc = !_vm.show.cc;
      }
    }
  }, [_vm.show.cc ? _c("span", [_vm._v("x")]) : _c("span", [_vm._v("+")]), _vm._v("\n                    " + _vm._s(_vm.$t("CC")))]), _vm._v(" "), _c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.show.bcc = !_vm.show.bcc;
      }
    }
  }, [_vm.show.bcc ? _c("span", [_vm._v("x")]) : _c("span", [_vm._v("+")]), _vm._v("\n                    " + _vm._s(_vm.$t("BCC")))])])]), _vm._v(" "), _c("redactor", {
    ref: "redactor",
    attrs: {
      disabled: _vm.isEdit && _vm.model.createdBy && _vm.me && _vm.me.userId != _vm.model.createdBy.value,
      config: _vm.editorConfig
    },
    model: {
      value: _vm.messageModel.message,
      callback: function callback($$v) {
        _vm.$set(_vm.messageModel, "message", $$v);
      },
      expression: "messageModel.message"
    }
  })], 1), _vm._v(" "), _vm.model.caseStatus == 3 || _vm.model.caseStatus && _vm.model.caseStatus.value == 3 ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("h4", [_vm._v(_vm._s(_vm.$t("Solution")))]), _vm._v(" "), _c("div", {
    staticClass: "flex-toolbar"
  }, [_c("div", {
    attrs: {
      id: "editor-toolbar2"
    }
  })]), _vm._v(" "), _c("redactor", {
    ref: "redactor",
    attrs: {
      config: _vm.editorConfig2
    },
    model: {
      value: _vm.messageModel.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.messageModel, "solution", $$v);
      },
      expression: "messageModel.solution"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_vm.model.createdOn ? _c("div", {
    staticClass: "margin-10 font-11"
  }, [_vm.model.createdBy ? _c("span", [_vm._v("\n                  " + _vm._s(_vm.$t("Created By")) + "\n                  " + _vm._s(_vm.model.createdBy ? _vm.model.createdBy.label : "") + " on\n                  " + _vm._s(_vm._f("datetime")(_vm.model.createdOn, "DD.MM.YYYY HH:mm")) + "\n                ")]) : _vm._e(), _vm._v(" "), _vm.model.lastMessageDate ? _c("span", {
    staticClass: "margin-left-10 margin-right-5"
  }, [_vm._v("\n                  |\n                ")]) : _vm._e(), _vm._v(" "), _vm.model.lastMessageDate ? _c("span", [_vm._v("\n                  " + _vm._s(_vm.$t("Last message on")) + "\n                  "), _vm.model.lastMessageDate ? [_vm._v("\n                    " + _vm._s(_vm._f("datetime")(_vm.model.lastMessageDate, "DD.MM.YYYY HH:mm")) + "\n                  ")] : _vm._e()], 2) : _vm._e()]) : _vm._e()])], 1)], 1) : _c("div", {
    staticStyle: {
      width: "100%",
      height: "calc(80vh - 120px)",
      overflow: "auto"
    }
  }, [_c("main", {
    staticClass: "inbox-detail-wrapper overflow-visible"
  }, [_c("el-collapse", {
    model: {
      value: _vm.activeNames,
      callback: function callback($$v) {
        _vm.activeNames = $$v;
      },
      expression: "activeNames"
    }
  }, _vm._l(_vm.messages, function (message, i) {
    return _c("el-collapse-item", {
      key: message.record_id,
      staticClass: "message-collapse",
      attrs: {
        name: message.record_id
      }
    }, [_c("template", {
      slot: "title"
    }, [_c("div", {
      staticClass: "inbox-message-header",
      class: message.draft ? "message-draft" : ""
    }, [message.sender.value == 1 ? _c("kv-avatar", {
      attrs: {
        "is-rounded": "",
        size: "48x48",
        border: {
          size: "1px",
          color: _vm.getColor(message.sender_email)
        },
        "user-id": null
      }
    }, [_vm._v(_vm._s(_vm.getFirstChars(message.sender_name)) + "\n                    ")]) : _c("kv-avatar", {
      attrs: {
        "is-rounded": "",
        size: "48x48",
        border: {
          size: "1px",
          color: _vm.getColor(message.record_owner.value)
        },
        "user-id": message.record_owner.value
      }
    }), _vm._v(" "), _c("div", {
      staticClass: "inbox-message-content"
    }, [_c("div", {
      staticClass: "inbox-sender-info"
    }, [_c("div", {
      staticClass: "sender-name"
    }, [_vm._v("\n                          " + _vm._s(message.sender.value == 1 ? "".concat(message.sender_name, " ").concat(message.sender_email) : message.created_by.label) + "\n                          "), _c("span", {
      staticClass: "send-date"
    }, [_vm._v("\n                            On: " + _vm._s(_vm._f("datetime")(message.send_date)) + "\n                          ")])])]), _vm._v(" "), _c("div", {
      staticClass: "last-message"
    }, [_c("span", {
      staticClass: "last-message-content"
    }, [_c("span", {
      staticClass: "last-message-title"
    }, [_vm._v(_vm._s(_vm.model.subject))]), _vm._v(" "), !_vm.activeNames.includes(message.record_id) ? _c("span", {
      domProps: {
        innerHTML: _vm._s(_vm.getTruncatedText(message.stripped_message))
      }
    }) : _vm._e()])])]), _vm._v(" "), _c("div", {
      staticClass: "inbox-message-action"
    }, [!message.draft ? _c("a", {
      staticClass: "action-link",
      attrs: {
        title: _vm.$t("Reply")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.handleReply(message);
        }
      }
    }, [_c("kv-svg-icon", {
      attrs: {
        icon: "kv-icon-reply"
      }
    })], 1) : _vm._e(), _vm._v(" "), _vm.model.incomingChannel && _vm.model.incomingChannel.value != 2 || i != 0 ? _c("a", {
      staticClass: "action-link",
      attrs: {
        title: _vm.$t("Forward")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          return _vm.handleForward(message);
        }
      }
    }, [_c("kv-svg-icon", {
      attrs: {
        icon: "kv-icon-forward"
      }
    })], 1) : _c("a", {
      staticClass: "action-link",
      attrs: {
        title: _vm.$t("Edit")
      },
      on: {
        click: function click($event) {
          $event.stopPropagation();
          _vm.editPermission = true;
        }
      }
    }, [_c("kv-svg-icon", {
      attrs: {
        icon: "kv-icon-edit"
      }
    })], 1)])], 1)]), _vm._v(" "), _c("div", {
      staticClass: "message-content"
    }, [_vm._l(_vm.optimizeMessage(message.message), function (item, i) {
      return [item.html ? _c("span", {
        domProps: {
          innerHTML: _vm._s(item.html)
        }
      }) : _c("a", {
        attrs: {
          slot: ""
        },
        slot: "default"
      }, [_c("img", {
        directives: [{
          name: "viewer",
          rawName: "v-viewer"
        }],
        staticClass: "viewer-image",
        staticStyle: {
          cursor: "pointer",
          "max-width": "100%",
          height: "auto"
        },
        attrs: {
          loading: "lazy",
          src: item.img
        },
        on: {
          click: function click($event) {
            return _vm.handleView();
          }
        }
      })])];
    })], 2), _vm._v(" "), _c("div", {
      staticClass: "message-attachments"
    }, [message.attachments.length ? _c("kv-attachment-list", {
      attrs: {
        app: "inbox",
        model: "message",
        attachments: message.attachments,
        record_id: message.record_id
      }
    }) : _vm._e()], 1)], 2);
  }), 1)], 1)]), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "240px",
      "min-width": "240px"
    }
  }, [_c("div", {
    staticClass: "inbox-sidebar kv-scroll"
  }, [_c("el-row", {
    staticClass: "formItem",
    attrs: {
      row: "",
      wrap: ""
    }
  }, [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Assignee")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-user"
  })]), _vm._v(" "), _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Assignee"),
      clearable: "",
      filterable: "",
      "value-key": "userId"
    },
    model: {
      value: _vm.model.recordOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "recordOwner", $$v);
      },
      expression: "model.recordOwner"
    }
  }, _vm._l(_vm.users, function (user) {
    return _c("el-option", {
      key: user.userId,
      attrs: {
        value: user,
        label: user.fullName
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Request Type")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-tasks"
  })]), _vm._v(" "), _vm.model.requestType && _vm.options["inbox_thread_request_type"] && _vm.options["inbox_thread_request_type"][0] ? _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Type"),
      clearable: "",
      filterable: "",
      "value-key": "id"
    },
    model: {
      value: _vm.model.requestType.value,
      callback: function callback($$v) {
        _vm.$set(_vm.model.requestType, "value", $$v);
      },
      expression: "model.requestType.value"
    }
  }, _vm._l(_vm.options["inbox_thread_request_type"], function (team) {
    return _c("el-option", {
      key: team.id,
      attrs: {
        value: team.id,
        label: team.label
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.model.requestType && _vm.model.requestType.value == 2,
      expression: "model.requestType && model.requestType.value == 2"
    }],
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Estimated Start Date")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-calendar"
  })]), _vm._v(" "), _c("el-date-picker", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Est. Start Date"),
      format: "dd.MM.yyyy",
      "picker-options": _vm.$datepickerOptions
    },
    model: {
      value: _vm.model.estimatedStartDate,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "estimatedStartDate", $$v);
      },
      expression: "model.estimatedStartDate"
    }
  })], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.model.requestType && _vm.model.requestType.value == 2,
      expression: "model.requestType && model.requestType.value == 2"
    }],
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Est. Delivery Date")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-calendar"
  })]), _vm._v(" "), _c("el-date-picker", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Estimated Delivery Date"),
      format: "dd.MM.yyyy",
      "picker-options": _vm.$datepickerOptions
    },
    on: {
      change: function change($event) {
        return _vm.save();
      }
    },
    model: {
      value: _vm.model.estimatedDeliveryDate,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "estimatedDeliveryDate", $$v);
      },
      expression: "model.estimatedDeliveryDate"
    }
  })], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.model.requestType && _vm.model.requestType.value == 2,
      expression: "model.requestType && model.requestType.value == 2"
    }],
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Est. Effort")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-clock"
  })]), _vm._v(" "), _c("el-input", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Estimated Effort")
    },
    on: {
      change: function change($event) {
        return _vm.save();
      }
    },
    model: {
      value: _vm.model.estimatedEffort,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "estimatedEffort", $$v);
      },
      expression: "model.estimatedEffort"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Priority")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-star"
  })]), _vm._v(" "), _vm.model.priority && _vm.options["inbox_thread_priority"] && _vm.options["inbox_thread_priority"][0] ? _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Priority"),
      clearable: "",
      filterable: "",
      "value-key": "value"
    },
    on: {
      change: function change($event) {
        return _vm.save();
      }
    },
    model: {
      value: _vm.model.priority.value,
      callback: function callback($$v) {
        _vm.$set(_vm.model.priority, "value", $$v);
      },
      expression: "model.priority.value"
    }
  }, _vm._l(_vm.options["inbox_thread_priority"], function (item) {
    return _c("el-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.label
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Labels")) + "\n                ")]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-tags"
  })]), _vm._v(" "), _c("kv-label-input", {
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      app: "inbox",
      model: "thread",
      id: _vm.model.id,
      placeholder: _vm.$t("Labels")
    },
    on: {
      setUnrecordedLabels: function setUnrecordedLabels($event) {
        _vm.unRecordedLabels = $event;
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v("\n                  " + _vm._s(_vm.$t("Brand")) + "\n                ")]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-tags"
  })]), _vm._v(" "), _c("kv-selection", {
    key: "brand" + _vm.model.id,
    staticClass: "width-full kv--input sub-border left-icon",
    attrs: {
      app: "crm",
      clearable: "",
      model: "brand",
      label: "brand_name",
      list_value: "record_id",
      placeholder: _vm.$t("Type to filter"),
      searchOnStart: {
        do: true,
        search: {}
      },
      size: "small"
    },
    model: {
      value: _vm.model.brand,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "brand", $$v);
      },
      expression: "model.brand"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Category")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-stream"
  })]), _vm._v(" "), _vm.options["inbox_category_all"] && _vm.options["inbox_category_all"][0] ? _c("el-cascader", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Category"),
      options: _vm.options["inbox_category_all"],
      props: {
        expandTrigger: "hover",
        label: "categoryName",
        value: "recordId",
        emitPath: false
      },
      filterable: ""
    },
    on: {
      change: function change($event) {
        return _vm.checkCategoryStatus();
      }
    },
    model: {
      value: _vm.model.category,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "category", $$v);
      },
      expression: "model.category"
    }
  }) : _c("el-cascader", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Category"),
      props: {
        expandTrigger: "hover",
        label: "categoryName",
        value: "recordId",
        emitPath: false
      },
      filterable: ""
    },
    on: {
      change: function change($event) {
        return _vm.checkCategoryStatus();
      }
    }
  }), _vm._v(" "), _c("a", {
    staticClass: "form-selection-plus",
    on: {
      click: function click($event) {
        return _vm.openModal();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })])], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Channel")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-sign-in-alt"
  })]), _vm._v(" "), _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Channel"),
      clearable: "",
      filterable: "",
      "value-key": "value"
    },
    model: {
      value: _vm.model.incomingChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "incomingChannel", $$v);
      },
      expression: "model.incomingChannel"
    }
  }, _vm._l(_vm.incomingChannels, function (channel) {
    return _c("el-option", {
      key: channel.value,
      attrs: {
        value: channel,
        label: channel.label
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Team")))]), _vm._v(" "), _c("span", {
    staticClass: "icon-input-before"
  }, [_c("i", {
    staticClass: "fal fa-users"
  })]), _vm._v(" "), _c("el-select", {
    staticClass: "width-full left-icon kv--input sub-border",
    attrs: {
      placeholder: _vm.$t("Team"),
      clearable: "",
      filterable: "",
      "value-key": "teamId"
    },
    model: {
      value: _vm.model.team,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "team", $$v);
      },
      expression: "model.team"
    }
  }, _vm._l(_vm.teams, function (team) {
    return _c("el-option", {
      key: team.teamId,
      attrs: {
        value: team,
        label: team.teamName
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 8
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Sla")))]), _vm._v(" "), _vm.sla && _vm.sla[0] ? _c("el-select", {
    staticClass: "width-full kv--input sub-border px-0",
    attrs: {
      placeholder: _vm.$t("Sla"),
      clearable: "",
      filterable: "",
      "value-key": "id"
    },
    on: {
      change: function change($event) {
        return _vm.getSlaLevels();
      }
    },
    model: {
      value: _vm.model.sla,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "sla", $$v);
      },
      expression: "model.sla"
    }
  }, _vm._l(_vm.sla, function (s) {
    return _c("el-option", {
      key: s.value,
      attrs: {
        value: s,
        label: s.label
      }
    });
  }), 1) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sidebar-item"
  }, [_c("span", {
    staticClass: "demonstration"
  }, [_vm._v(_vm._s(_vm.$t("Level")))]), _vm._v(" "), _c("el-select", {
    staticClass: "width-full kv--input sub-border px-0",
    attrs: {
      disabled: !_vm.model.sla.value,
      placeholder: _vm.$t("Level"),
      clearable: "",
      filterable: "",
      "value-key": "id"
    },
    model: {
      value: _vm.model.slaLevel,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "slaLevel", $$v);
      },
      expression: "model.slaLevel"
    }
  }, _vm._l(_vm.slaLevels, function (sla) {
    return _c("el-option", {
      key: sla.value,
      attrs: {
        value: sla,
        label: sla.label
      }
    });
  }), 1)], 1)])], 1)], 1)], 1)])])]), _vm._v(" "), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.$t("Time Entry"),
      width: "350px",
      "append-to-body": "",
      visible: _vm.timeEntry.show
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.timeEntry, "show", $event);
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "fade",
      mode: "in-out"
    }
  }, [_vm.model.record_id && _vm.timeEntry.show ? _c("timeEntry", {
    key: _vm.timeEntry.key,
    staticStyle: {
      width: "300px"
    },
    attrs: {
      data: _vm.timeEntry.selected,
      is_show: _vm.timeEntry.show,
      task: _vm.model,
      time_entry_key: _vm.timeEntry.key
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.timeEntry.show = $event;
      },
      all_time_entries: function all_time_entries($event) {
        _vm.timeEntry.list = $event;
      },
      all_time_entries_key: function all_time_entries_key($event) {
        _vm.timeEntry.listKey = $event;
      }
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _vm.id ? _c("reply-drawer", {
    attrs: {
      isForward: _vm.isForward,
      model: _vm.model,
      excerpt: _vm.excerpt,
      threadId: _vm.id,
      thread: _vm.id
    },
    on: {
      success: _vm.getMessages,
      setStatus: function setStatus($event) {
        _vm.model.caseStatus.value = $event;
      },
      setRequestStatus: function setRequestStatus($event) {
        _vm.model.requestStatus.value = $event;
      },
      setSalesInquiryStatus: function setSalesInquiryStatus($event) {
        _vm.model.salesInquiryStatus.value = $event;
      }
    },
    model: {
      value: _vm.sendMessageAction,
      callback: function callback($$v) {
        _vm.sendMessageAction = $$v;
      },
      expression: "sendMessageAction"
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };