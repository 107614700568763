import KivaProxy from '@/utils/kivaProxy/src';
import { sendMailModel } from '@/models/sendMailModel';
export default {
  data: function data() {
    return {
      fromEmail: null
    };
  },
  created: function created() {
    this.getFromData();
    this.getTemplateData();
  },
  methods: {
    getFromData: function getFromData() {
      var _this = this;
      var storage = localStorage.getItem('fromData');
      var max = 600;
      var inside = true;
      if (storage && storage.charAt(0) == '{') {
        var oldData = JSON.parse(storage);
        var diff = (new Date().getTime() - oldData.time) / 1000;
        if (diff < max && diff > 0) {
          inside = false;
          this.fromData = JSON.parse(Base64.decode(oldData.options));
        }
      }
      if (inside) {
        console.log("getFromData inside");
        new KivaProxy({
          endpoint: "kvc/message/sender",
          driver: 'base'
        }).all().then(function (response) {
          _this.fromData = response.data;
          if (!_this.fromEmail) {
            _this.model.from = response.data && response.data[0] ? response.data[0].id : null;
          } else {
            _this.model.from = response.data && response.data[0] ? response.data[0].id : null;
          }
        });
      }
    },
    getTemplateData: function getTemplateData() {
      var _this2 = this;
      new KivaProxy({
        endpoint: "kvc/template/message",
        driver: 'base'
      }).all().then(function (response) {
        _this2.templateData = response.data;
      });
    }
  }
};