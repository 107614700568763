import "core-js/modules/es.json.stringify.js";
export function preferencesModel() {
  var user = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign({
    baseCurrency: null,
    currencyFormat: null,
    defaultCapacity: null,
    defaultDailyWorkingHours: null,
    fiscalYear: null,
    longDateFormat: null,
    locale: null,
    numberFormat: null,
    shortDateFormat: null,
    startWeekOn: null,
    success: null,
    timeFormat: null,
    timeRounding: null,
    timeZone: null,
    workingHourEndTime: null,
    system: null,
    workingHoursStartTime: null
  }, JSON.parse(JSON.stringify(user)));
}