import _slicedToArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.concat.js";
export var messages = {
  _default: function _default(field) {
    return "The ".concat(field, " value is not valid.");
  },
  after: function after(field, _ref) {
    var _ref2 = _slicedToArray(_ref, 2),
      target = _ref2[0],
      inclusion = _ref2[1];
    return "The ".concat(field, " must be after ").concat(inclusion ? 'or equal to ' : '').concat(target, ".");
  },
  alpha: function alpha(field) {
    return "The ".concat(field, " field may only contain alphabetic characters.");
  },
  alpha_dash: function alpha_dash(field) {
    return "The ".concat(field, " field may contain alpha-numeric characters as well as dashes and underscores.");
  },
  alpha_num: function alpha_num(field) {
    return "The ".concat(field, " field may only contain alpha-numeric characters.");
  },
  alpha_spaces: function alpha_spaces(field) {
    return "The ".concat(field, " field may only contain alphabetic characters as well as spaces.");
  },
  before: function before(field, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      target = _ref4[0],
      inclusion = _ref4[1];
    return "The ".concat(field, " must be before ").concat(inclusion ? 'or equal to ' : '').concat(target, ".");
  },
  between: function between(field, _ref5) {
    var _ref6 = _slicedToArray(_ref5, 2),
      min = _ref6[0],
      max = _ref6[1];
    return "The ".concat(field, " field must be between ").concat(min, " and ").concat(max, ".");
  },
  confirmed: function confirmed(field) {
    return "The ".concat(field, " confirmation does not match.");
  },
  credit_card: function credit_card(field) {
    return "The ".concat(field, " field is invalid.");
  },
  date_between: function date_between(field, _ref7) {
    var _ref8 = _slicedToArray(_ref7, 2),
      min = _ref8[0],
      max = _ref8[1];
    return "The ".concat(field, " must be between ").concat(min, " and ").concat(max, ".");
  },
  date_format: function date_format(field, _ref9) {
    var _ref10 = _slicedToArray(_ref9, 1),
      format = _ref10[0];
    return "The ".concat(field, " must be in the format ").concat(format, ".");
  },
  decimal: function decimal(field) {
    var _ref11 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [],
      _ref12 = _slicedToArray(_ref11, 1),
      _ref12$ = _ref12[0],
      decimals = _ref12$ === void 0 ? '*' : _ref12$;
    return "The ".concat(field, " field must be numeric and may contain ").concat(!decimals || decimals === '*' ? '' : decimals, " decimal points.");
  },
  digits: function digits(field, _ref13) {
    var _ref14 = _slicedToArray(_ref13, 1),
      length = _ref14[0];
    return "The ".concat(field, " field must be numeric and exactly contain ").concat(length, " digits.");
  },
  dimensions: function dimensions(field, _ref15) {
    var _ref16 = _slicedToArray(_ref15, 2),
      width = _ref16[0],
      height = _ref16[1];
    return "The ".concat(field, " field must be ").concat(width, " pixels by ").concat(height, " pixels.");
  },
  email: function email(field) {
    return "The ".concat(field, " field must be a valid email.");
  },
  excluded: function excluded(field) {
    return "The ".concat(field, " field must be a valid value.");
  },
  ext: function ext(field) {
    return "The ".concat(field, " field must be a valid file.");
  },
  image: function image(field) {
    return "The ".concat(field, " field must be an image.");
  },
  included: function included(field) {
    return "The ".concat(field, " field must be a valid value.");
  },
  integer: function integer(field) {
    return "The ".concat(field, " field must be an integer.");
  },
  ip: function ip(field) {
    return "The ".concat(field, " field must be a valid ip address.");
  },
  length: function length(field, _ref17) {
    var _ref18 = _slicedToArray(_ref17, 2),
      _length = _ref18[0],
      max = _ref18[1];
    if (max) {
      return "The ".concat(field, " length must be between ").concat(_length, " and ").concat(max, ".");
    }
    return "The ".concat(field, " length must be ").concat(_length, ".");
  },
  max: function max(field, _ref19) {
    var _ref20 = _slicedToArray(_ref19, 1),
      length = _ref20[0];
    return "The ".concat(field, " field may not be greater than ").concat(length, " characters.");
  },
  max_value: function max_value(field, _ref21) {
    var _ref22 = _slicedToArray(_ref21, 1),
      max = _ref22[0];
    return "The ".concat(field, " field must be ").concat(max, " or less.");
  },
  mimes: function mimes(field) {
    return "The ".concat(field, " field must have a valid file type.");
  },
  min: function min(field, _ref23) {
    var _ref24 = _slicedToArray(_ref23, 1),
      length = _ref24[0];
    return "The ".concat(field, " field must be at least ").concat(length, " characters.");
  },
  min_value: function min_value(field, _ref25) {
    var _ref26 = _slicedToArray(_ref25, 1),
      min = _ref26[0];
    return "The ".concat(field, " field must be ").concat(min, " or more.");
  },
  numeric: function numeric(field) {
    return "The ".concat(field, " field may only contain numeric characters.");
  },
  regex: function regex(field) {
    return "The ".concat(field, " field format is invalid.");
  },
  required: function required(field) {
    return "The ".concat(field, " field is required.");
  },
  size: function size(field, _ref27) {
    var _ref28 = _slicedToArray(_ref27, 1),
      _size = _ref28[0];
    return "The ".concat(field, " size must be less than ").concat(_size, ".");
  },
  url: function url(field) {
    return "The ".concat(field, " field is not a valid URL.");
  },
  kv_password: function kv_password(field, params, data) {
    return "Password criterias not matched";
  }
};