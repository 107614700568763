import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.join.js";
import { getType } from './getType';
import { IDS_LIMIT } from '../constants';
var allowedTypes = ['Array', 'String', 'Number'];
export function validateAndParseIds(ids) {
  var type = getType(ids);
  if (allowedTypes.includes(type)) {
    if (type === 'Array') {
      if (ids.length > IDS_LIMIT) {
        throw new Error("You can give maximum ".concat(IDS_LIMIT, " id at one request"));
      } else {
        return ids.join(',');
      }
    } else {
      return ids;
    }
  } else {
    throw new Error("".concat(type, " is not supported argument"));
  }
}