import "core-js/modules/es.json.stringify.js";
import KivaProxy from '@/utils/kivaProxy/src';
import Cookies from 'js-cookie';
export default {
  setPanelView: function setPanelView(_ref, params) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch,
      state = _ref.state;
    Cookies.set('focusShowPanel', JSON.stringify(params));
  }
};