var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    key: _vm.skey
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      filterable: "",
      remote: "",
      multiple: "",
      clearable: "",
      placeholder: _vm.$t("Please select"),
      "remote-method": _vm.getParticipants,
      loading: _vm.loading,
      "allow-create": ""
    },
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, _vm._l(_vm.participants, function (item) {
    return _c("el-option", {
      key: item.target_record_id,
      attrs: {
        value: item.target_app_id + "_" + item.target_model_id + "_" + item.target_record_id + "_" + item.target_email + "_" + item.target_name,
        label: item.target_name + " " + item.target_email
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };