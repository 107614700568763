import "core-js/modules/es.object.to-string.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { TEAM_MEMBERS, TEAMS } from '@/store/modules/team/mutation-types';
import { TeamTransformer } from '@/transformers/TeamTransformer';
import { TeamMemberTransformer } from '@/transformers/TeamMemberTransformer';
export default {
  getTeams: function getTeams(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'team/team'
      }).setFields(['group', 'default_assignee', 'email', 'email_template', 'member_list', 'record_id', 'record_owner', 'team_id', 'team_name', 'team_role', 'time_tracking_required']).all().then(function (response) {
        commit(TEAMS, TeamTransformer.fetchCollection(response.records));
        resolve();
      }).catch(reject);
    });
  },
  getTeamMembers: function getTeamMembers(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'team/member'
      }).all().then(function (response) {
        commit(TEAM_MEMBERS, TeamMemberTransformer.fetchCollection(response.records));
        resolve();
      });
    });
  }
};