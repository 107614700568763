import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import Vue from 'vue';
import store from '@/store';
Vue.filter('currency', function (value) {
  var currency = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : store.getters['common/projectCurrency'];
  var locale = (store.getters['users/currentUserLocale'] || store.getters['common/projectLocale']).replace('_', '-');
  if (currency && locale && value) {
    currency = currency == "EURO" ? 'EUR' : currency;
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(value);
  } else {
    return value;
  }
});