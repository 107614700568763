import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    staticClass: "text-center"
  }, [_vm.visibility ? _c("el-upload", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingFiles,
      expression: "loadingFiles"
    }],
    key: _vm.documents_key,
    ref: "inputUpload",
    staticClass: "width-full",
    attrs: {
      action: "",
      drag: "",
      "on-change": _vm.handleChange,
      "file-list": _vm.newFiles,
      multiple: "",
      limit: 3,
      "auto-upload": false
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__text"
  }, [_c("em", [_vm._v(" " + _vm._s(_vm.$t("Drop file here or click to upload")))])]), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v("\n      " + _vm._s(_vm.$t("files with a size less than 20MB")) + "\n    ")])]) : _vm._e(), _vm._v(" "), _vm.attachments && _vm.attachments[0] || _vm.task && _vm.task.attachment ? _c("div", {
    staticClass: "kv-line-width kv-scroll",
    class: _vm.scrollDir == "x" ? "scrollx" : "scrolly"
  }, [_vm.listStyle == "standard" ? _c("ul", {
    staticClass: "el-upload-list el-upload-list--picture-card"
  }, [_vm.attachments && _vm.attachments[0] ? _vm._l(_vm.attachments, function (att, i) {
    return _c("li", {
      key: i,
      staticClass: "el-upload-list__item is-success rel-pos",
      attrs: {
        tabindex: "0"
      }
    }, [_c("div", [_vm.isImage(att) ? _c("img", {
      staticClass: "objectFit",
      attrs: {
        src: _vm.getImageUrl(att, _vm.record_id)
      }
    }) : att.mime_type ? _c("span", [_c("i", {
      class: _vm.getFileIcon(att, "fad", "sm")
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__title"
    }, [_c("a", {
      attrs: {
        title: att.name
      }
    }, [_vm._v(" " + _vm._s(att.name) + " ")])]), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-actions"
    }, [_vm.isImage(att) ? _c("span", {
      staticClass: "el-upload-list__item-preview",
      on: {
        click: function click($event) {
          return _vm.handleView(att);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-zoom-in"
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-download",
      on: {
        click: function click($event) {
          return _vm.openFileUrl(att, _vm.record_id);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-download"
    })]), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-delete",
      on: {
        click: function click($event) {
          return _vm.up_delete_attachment(att);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete"
    })])]), _vm._v(" "), _vm.isImage(att) ? [_c("div", {
      directives: [{
        name: "viewer",
        rawName: "v-viewer"
      }],
      staticClass: "viewer-images",
      class: "viewer-".concat(att.file_id)
    }, [_c("img", {
      attrs: {
        src: _vm.getImageUrl(att, _vm.record_id)
      }
    })])] : _vm._e()], 2)]);
  }) : _vm._e(), _vm._v(" "), _vm.task && _vm.task.attachment && !(_vm.attachments && _vm.attachments[0]) ? _c("li", {
    key: 111,
    staticClass: "el-upload-list__item is-success rel-pos",
    attrs: {
      tabindex: "111"
    }
  }, [_vm.task.attachment ? _c("img", {
    staticClass: "objectFit",
    attrs: {
      src: _vm.task.attachment
    }
  }) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "el-upload-list__item-actions"
  }, [_c("span", {
    staticClass: "el-upload-list__item-preview",
    on: {
      click: function click($event) {
        return _vm.handleView2(_vm.att);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-zoom-in"
  })])]), _vm._v(" "), _vm.task.attachment ? [_c("div", {
    directives: [{
      name: "viewer",
      rawName: "v-viewer"
    }],
    staticClass: "viewer-images",
    class: "viewer-111"
  }, [_c("img", {
    attrs: {
      src: _vm.task.attachment
    }
  })])] : _vm._e()], 2) : _vm._e()], 2) : _vm.listStyle == "table" ? _c("div", {
    staticClass: "limitedHeight kv-scroll",
    class: _vm.view == "light" ? "light" : "full"
  }, [_vm.attachments && _vm.attachments[0] ? [_vm._l(_vm.attachments, function (att, i) {
    return _c("div", {
      key: "attachment" + i,
      staticClass: "full-width fileList"
    }, [_c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "card-title"
    }, [_c("span", [att.mime_type ? _c("span", [_c("i", {
      class: _vm.getFileIcon(att, "fad", "sm")
    })]) : _vm._e()]), _vm._v(" "), _c("div", {
      staticClass: "text-title"
    }, [_vm._v(_vm._s(att.name))])]), _vm._v(" "), _c("div", {
      staticClass: "card-detail"
    }, [_vm.isImage(att) ? _c("span", {
      staticClass: "el-upload-list__item-preview",
      on: {
        click: function click($event) {
          return _vm.handleView(att);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-zoom-in"
    })]) : _vm._e(), _vm._v(" "), _c("span", {
      staticClass: "el-upload-list__item-download",
      on: {
        click: function click($event) {
          return _vm.openFileUrl(att, _vm.record_id);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-download"
    })]), _vm._v(" "), _c("span", {
      on: {
        click: function click($event) {
          return _vm.up_delete_attachment(att);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete"
    })])])])]);
  }), _vm._v(" "), _vm.view == "light" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("full");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("More")) + "...")]) : _vm._e(), _vm._v(" "), _vm.view == "full" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("light");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Close")))]) : _vm._e()] : _vm._e()], 2) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.imageDialog.visible
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.imageDialog, "visible", $event);
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.imageDialog.url,
      alt: ""
    }
  })])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };