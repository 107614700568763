var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.accountLoading,
      expression: "accountLoading"
    }],
    class: _vm.extendedClass
  }, [_c("el-select", {
    ref: "extendedClass",
    staticClass: "width-full",
    attrs: {
      filterable: "",
      remote: "",
      clearable: "",
      size: _vm.size,
      placeholder: _vm.$t("task.Please select project"),
      "remote-method": _vm.startInterval,
      multiple: _vm.isMultiple,
      "collapse-tags": _vm.collapseTags,
      "value-key": "value"
    },
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, _vm._l(_vm.list, function (item) {
    return _c("el-option", {
      attrs: {
        value: item,
        label: item.project_name
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };