import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var templateRoutes = [{
  path: '',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/templates/ChecklistTemplateList');
  },
  name: 'rulesup.templates.list',
  meta: {
    title: 'Templates',
    icon: 'fal fa-registered'
  },
  props: true
}, {
  path: ':type(library)?/create',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/templates/ChecklistCreateTemplate');
  },
  name: 'rulesup.templates.create',
  meta: {
    title: 'Create Template',
    icon: 'fal fa-registered',
    disableScroll: true
  },
  props: true
}, {
  path: ':type(library)?/:id/:uuid?',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/templates/ChecklistTemplateDetail');
  },
  name: 'rulesup.templates.detail',
  meta: {
    title: 'View Template',
    icon: 'fal fa-registered',
    disableScroll: true
  },
  props: true
}, {
  path: 'edit/:type(library)?/:id/:uuid?',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/templates/ChecklistCreateTemplate');
  },
  name: 'rulesup.templates.edit',
  meta: {
    title: 'Edit Template',
    icon: 'fal fa-registered',
    disableScroll: true
  },
  props: true
}];