import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import acitivty from "@/views/activity/ActivityListDrawer";
import KivaProxy from "@/utils/kivaProxy/src";
import popupsReady from "@/components/global/popupsReady";
import { mapGetters } from "vuex";
export default {
  name: "DefaultLayout",
  components: {
    TagsView: TagsView,
    Sidebar: Sidebar,
    popupsReady: popupsReady,
    rightDrawer: function rightDrawer() {
      return import("@/components/tasks/rightDrawer");
    },
    acitivty: acitivty
  },
  created: function created() {
    this.handleIcon();
  },
  mixins: [ResizeMixin],
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  computed: _objectSpread(_objectSpread({
    drawer: function drawer() {
      return this.$store.state.app.drawer;
    },
    embeded: function embeded() {
      return this.$route.query.embeded;
    },
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    activityDrawer: {
      get: function get() {
        return this.$store.state.app.activityDrawer;
      },
      set: function set(val) {
        this.$store.state.app.activityDrawer = val;
      }
    },
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    }
  }, mapGetters({
    profile: "users/MY_UX_PROFILE"
  })), {}, {
    device: function device() {
      return this.$store.state.app.device;
    },
    fixedHeader: function fixedHeader() {
      return this.$store.state.settings.fixedHeader;
    },
    needTagsView: function needTagsView() {
      return this.$store.state.settings.tagsView;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    key: function key() {
      return this.$route.path;
    }
  }),
  methods: {
    handleIcon: function handleIcon() {
      var favicon = document.getElementById("favicon");
      favicon.href = "/favicon.svg";
    },
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("app/closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};