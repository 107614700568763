import "core-js/modules/es.array.push.js";
export var mutations = {
  savingStart: function savingStart(state, data) {
    state.saving = data.status;
  },
  savingEnd: function savingEnd(state, data) {
    setTimeout(function () {
      state.saving = data.status;
      state.saveHistory.push(data.result);
    }, 400);
    setTimeout(function () {
      state.saving = 0;
    }, 2500);
    // Object.assign(state.appointment, appointment);
  },
  setWindowWidth: function setWindowWidth(state, width) {
    state.windowWidth = width;
  }
};