import _objectWithoutProperties from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["input"];
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import KivaProxy from "@/utils/kivaProxy/src";
import { ProjectTransformer } from "@/transformers/ProjectTransformer";
export default {
  name: "KvProjectSelect",
  props: {
    value: [Number, Boolean, Array, Object],
    account: {},
    placeholder: {
      type: String
    },
    extendedClass: {
      type: String,
      default: ""
    },
    focused: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      mutableValue: this.value,
      loading: false,
      projects: []
    };
  },
  mounted: function mounted() {
    // console.log("this.projects",this.projects)
    if (!this.projects) {
      this.getProjects();
    }
    if (this.focused) {
      this.focusDo();
    }
  },
  methods: {
    focusDo: function focusDo() {
      //  console.log("extendedClass",this.$refs.extendedClass.$el)
      this.$refs.extendedClass.$el.getElementsByTagName("input")[0].focus();
    },
    getProjects: function getProjects(query) {
      var _this = this;
      this.loading = true;
      query = query ? query : '';
      var proxy = new KivaProxy({
        endpoint: "act/project"
      });
      if (this.account) {
        proxy.setFilters({
          account_name: ["=", this.account]
        });
      }
      proxy.setFields(["record_id", "avatar_image", "approval_required", "account_name", "project_color", "project_name", "project_status", "project_group", "start_date", "description", "due_date", "follow_up_on", "record_owner", "team", "use_threads", "approval_required", "use_sections", "section_required", "time_entry_required", "avatar_icon", "project_color"]).setKeyword(query).all().then(function (response) {
        // approval_required
        // project_name
        // section_required
        // use_sections
        // use_threads
        _this.projects = ProjectTransformer.fetchCollection(response.records);
        if (_this.mutableValue) {
          var projectExist = _this.projects.find(function (p) {
            return p.recordId === _this.mutableValue;
          });
          if (!projectExist) {
            _this.mutableValue = null;
          }
        }
        _this.loading = false;
      });
    },
    input: function input() {
      var _this2 = this;
      if (this.mutableValue != null) {
        this.$emit("input", this.mutableValue);
        if (this.projects) {
          var projectExist = this.projects.find(function (p) {
            return p.recordId === _this2.mutableValue;
          });
          projectExist = projectExist ? projectExist : {};
          this.$emit("data", projectExist);
        } else {
          this.$emit("data", {});
        }
      }
    }
  },
  computed: {
    attrs: function attrs() {
      return this.$attrs;
    },
    listeners: function listeners() {
      var _this$$listeners = this.$listeners,
        input = _this$$listeners.input,
        listeners = _objectWithoutProperties(_this$$listeners, _excluded);
      return listeners;
    }
  },
  watch: {
    value: function value(val) {
      this.mutableValue = val;
    },
    focused: function focused(val) {
      if (val) {
        this.focusDo();
      }
    },
    account: {
      immediate: true,
      handler: function handler(val) {
        this.getProjects();
      }
    },
    mutableValue: function mutableValue() {
      this.input();
    }
  }
};