import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.item.hidden ? _c("div", {
    class: _vm.item.primaryClass,
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.mouseEnter.apply(null, arguments);
      },
      click: _vm.mouseEnter
    }
  }, [_vm.item.menu == "MEGAMENU" ? _c("el-submenu", {
    ref: "subMenu",
    staticStyle: {
      "background-color": "none"
    },
    attrs: {
      "popper-class": "custom-sidebar",
      index: "MegaMenu",
      "popper-append-to-body": true
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.item.meta.title)
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("megaMenuComp", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item && _vm.item.MEGAMENU && _vm.megaMenu,
      expression: "item && item.MEGAMENU && megaMenu"
    }],
    staticClass: "megaMenu",
    attrs: {
      list: _vm.item.MEGAMENU,
      megaMenu: _vm.megaMenu
    },
    on: {
      updateMegaMenu: function updateMegaMenu($event) {
        _vm.megaMenu = false;
        _vm.mouseLeave();
      }
    }
  })], 2) : _vm.item.main == "help" || _vm.item.name == "help" || _vm.item.name == "help.open-customer-portal" ? _c("el-submenu", {
    ref: "helpMenu",
    staticStyle: {
      "background-color": "none"
    },
    attrs: {
      index: _vm.item.pathFull ? _vm.resolvePath(_vm.item.pathFull) : "item.pathFull",
      "popper-class": "custom-sidebar",
      "popper-append-to-body": true
    },
    on: {
      click: function click($event) {
        _vm.$store.state.app.helpMenuModal = true;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "minimize-text",
    on: {
      click: function click($event) {
        _vm.$store.state.app.helpMenuModal = true;
      }
    }
  }, [_c("SvgIcon", {
    attrs: {
      icon: _vm.item.meta.icon
    }
  }), _vm._v(" "), _c("a", {
    staticClass: "menu-bottom-text",
    on: {
      click: function click($event) {
        _vm.$store.state.app.helpMenuModal = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.item.meta.title))])], 1)])], 2) : _vm.item.main == "invite" || _vm.item.name == "invite" ? _c("el-submenu", {
    ref: "subMenu",
    staticStyle: {
      "background-color": "none"
    },
    attrs: {
      index: _vm.item.pathFull ? _vm.resolvePath(_vm.item.pathFull) : "item.pathFull",
      "popper-class": "custom-sidebar"
    },
    on: {
      click: function click($event) {
        _vm.$store.state.app.invitePeopleModal = true;
      }
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("div", {
    staticClass: "minimize-text",
    on: {
      click: function click($event) {
        _vm.$store.state.app.invitePeopleModal = true;
      }
    }
  }, [_c("SvgIcon", {
    attrs: {
      icon: _vm.item.meta.icon
    }
  }), _vm._v(" "), _c("a", {
    staticClass: "menu-bottom-text",
    on: {
      click: function click($event) {
        _vm.$store.state.app.invitePeopleModal = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.item.meta.title))])], 1)])], 2) : _vm.hasOneShowingChild(_vm.item.children, _vm.item) && (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) && !_vm.item.alwaysShow ? [_vm.onlyOneChild.meta ? _c("app-link", {
    attrs: {
      to: _vm.resolvePath(_vm.item.pathFull)
    }
  }, [_c("el-menu-item", {
    class: {
      "submenu-title-noDropdown": !_vm.isNest
    },
    attrs: {
      index: _vm.item.pathFull ? _vm.resolvePath(_vm.item.pathFull) : "item.pathFull"
    }
  }, [_c("item", {
    attrs: {
      icon: _vm.onlyOneChild.meta.icon || _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.onlyOneChild.meta.title),
      short: _vm.isNest ? _vm.$t(_vm.item.meta.title) : _vm.item.meta.short ? _vm.$t(_vm.item.meta.short) : _vm.$t(_vm.item.meta.title),
      nclass: [_vm.isNest ? "" : "minimize-text"]
    }
  }), _vm._v(" "), _vm.unreadNotification > 0 && _vm.item.path == "/activity" ? _c("div", {
    staticClass: "notification"
  }, [_vm._v(_vm._s(_vm._f("max3chars")(_vm.unreadNotification)))]) : _vm.item.path == "/activity" && _vm.noUnreadSuccess == true ? _c("div", {
    staticClass: "notification notificationSuccess"
  }, [_c("i", {
    staticClass: "fal fa-check"
  })]) : _vm._e()], 1)], 1) : _vm._e()] : _vm.item.path == "/" || _vm.item.path == "/dashboard" ? _c("el-submenu", {
    ref: "subMenu",
    staticStyle: {
      "background-color": "none"
    },
    attrs: {
      "popper-class": "custom-sidebar",
      index: _vm.item.path ? _vm.resolvePath(_vm.item.path) : "item.pathFull"
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.item.meta.title),
      nclass: "minimize-text",
      short: _vm.$t(_vm.item.meta.title)
    }
  }) : _vm._e()], 1), _vm._v(" "), !_vm.item.hidden ? _c("sidebarDashboard", {
    staticClass: "homeMenu"
  }) : _vm._e()], 2) : _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.item.path ? _vm.resolvePath(_vm.item.path) : "item.pathFull",
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.$t(_vm.item.meta.title),
      nclass: "minimize-text",
      short: _vm.$t(_vm.item.meta.title)
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm._l(_vm.item.children, function (child, i) {
    return _c("sidebar-item", {
      key: child.path + i,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        index: _vm.item.pathFull ? _vm.resolvePath(_vm.item.path) : "item.pathFull",
        "base-path": _vm.resolvePath(child.path)
      }
    });
  })], 2)], 2) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };