export var state = function state() {
  return {
    IMAGE_PATH: process.env.VUE_APP_BASE_URL,
    BASE_URL: process.env.VUE_APP_BASE_URL,
    users: [],
    layout: 'DefaultLayout',
    currency: [],
    accounts: [],
    me: {},
    saving: 0,
    saveHistory: [],
    project_section: [],
    project_groups: [{
      view: true,
      name: 'Favorites',
      value: 'starred'
    }],
    task_status: [],
    list_type: 'user_status_base',
    users_formated: [],
    lastUpdate: {
      model: '',
      app: '',
      work: '',
      // update or create or delete
      id: 0,
      time: 0
    },
    default_response_alert: {
      name: 'Misafimiz',
      title: 'Üzgünüz',
      text: 'Kaydınız alınırken bir sorunla karşılaştık.',
      status: false,
      modal: false
    },
    response_alert: {
      name: '...',
      title: '...',
      text: '...',
      status: false,
      modal: false
    },
    windowWidth: document.documentElement.clientWidth
  };
};