var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_vm.device === "mobile" && _vm.sidebar.opened ? _c("div", {
    staticClass: "drawer-bg",
    on: {
      click: _vm.handleClickOutside
    }
  }) : _vm._e(), _vm._v(" "), _c("render", {
    attrs: {
      "is-app": ["kiva"]
    }
  }, [_c("sidebar", {
    staticClass: "sidebar-container",
    attrs: {
      id: "sidebar-container"
    }
  })], 1), _vm._v(" "), _c("div", {
    class: !_vm.embeded ? "main-container-outer" : ""
  }, [_c("div", {
    staticClass: "main-container",
    class: {
      hasTagsView: _vm.needTagsView
    }
  }, [_c("render", {
    attrs: {
      "is-app": "kiva"
    }
  }, [_c("div", [_c("div", {
    class: {
      "fixed-header": _vm.fixedHeader
    }
  }, [_vm.needTagsView ? _c("tags-view") : _vm._e()], 1), _vm._v(" "), _vm.drawer ? _c("right-drawer", {
    attrs: {
      drawer: _vm.drawer
    }
  }) : _vm._e(), _vm._v(" "), _c("el-drawer", {
    attrs: {
      "with-header": false,
      visible: _vm.activityDrawer,
      size: "500px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.activityDrawer = $event;
      }
    }
  }, [_c("div", [_c("acitivty", {
    class: "inDrawer"
  })], 1)])], 1)]), _vm._v(" "), _c("section", {
    class: !_vm.embeded ? "app-main" : "height-100vh"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm._t("default")], 2)], 1)], 1), _vm._v(" "), _c("popups-ready")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };