import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("kv-modal", {
    staticClass: "deal-modal",
    attrs: {
      size: "wide",
      cols: 2
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c("div", [_c("i", {
          staticClass: "fal fa-bullseye-arrow"
        }), _vm._v("\n     " + _vm._s(_vm.data.recordId ? _vm.$t("Edit Deal") : _vm.$t("Create a Deal")) + "\n     ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.dialogFormVisible,
      callback: function callback($$v) {
        _vm.dialogFormVisible = $$v;
      },
      expression: "dialogFormVisible"
    }
  }, [_vm._v(" "), _vm.data && _vm.data.accountName ? _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "dataForm",
    attrs: {
      inline: false,
      "label-width": _vm.formLabelWidth,
      size: "small",
      "label-position": "top",
      model: _vm.data,
      rules: _vm.rules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 16
    }
  }, [_c("el-col", {
    staticClass: "right-border",
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("Subject"),
      required: "",
      prop: "subject"
    }
  }, [_c("el-input", {
    attrs: {
      size: "small",
      autocomplete: "off",
      placeholder: _vm.$t("Subject")
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveForm();
      }
    },
    model: {
      value: _vm.data.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "subject", $$v);
      },
      expression: "data.subject"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Tags")
    }
  }, [_c("kv-label-input", {
    staticClass: "width-full",
    attrs: {
      app: "crm",
      model: "deal",
      width: 300,
      id: _vm.data.recordId,
      placeholder: _vm.$t("Labels")
    },
    on: {
      change: function change($event) {
        return _vm.saveForm();
      },
      setUnrecordedLabels: function setUnrecordedLabels($event) {
        _vm.unRecordedLabels = $event;
      }
    },
    model: {
      value: _vm.data.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "tags", $$v);
      },
      expression: "data.tags"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Expected Revenue")
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 6
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-input", {
    attrs: {
      size: "small",
      autocomplete: "off",
      placeholder: _vm.$t("Expected Revenue")
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.saveForm();
      }
    },
    model: {
      value: _vm.data.expectedRevenue,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "expectedRevenue", $$v);
      },
      expression: "data.expectedRevenue"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Currency")
    },
    model: {
      value: _vm.data.expectedRevenueCur,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "expectedRevenueCur", $$v);
      },
      expression: "data.expectedRevenueCur"
    }
  }, _vm._l(_vm.currencies, function (currency) {
    return _c("el-option", {
      key: currency.value,
      attrs: {
        label: currency.code,
        value: currency.value
      }
    }, [_vm._v("\n                   " + _vm._s(currency.code) + "\n                 ")]);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Expected Closing Date"),
      prop: "expectedClosingDate",
      required: ""
    }
  }, [_c("el-date-picker", {
    staticClass: "width-full",
    attrs: {
      type: "date",
      placeholder: _vm.$t("Pick a day")
    },
    model: {
      value: _vm.data.expectedClosingDate,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "expectedClosingDate", $$v);
      },
      expression: "data.expectedClosingDate"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Assigned To"),
      prop: "recordOwner",
      required: ""
    }
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Assigned To"),
      filterable: "",
      width: "100%"
    },
    model: {
      value: _vm.data.recordOwner,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "recordOwner", $$v);
      },
      expression: "data.recordOwner"
    }
  }, _vm._l(_vm.$store.state.users.USERS, function (ts) {
    return _c("el-option", {
      key: ts.userId,
      attrs: {
        value: ts.userId,
        label: ts.fullName
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Follow Up On")
    }
  }, [_c("el-date-picker", {
    staticClass: "width-full",
    attrs: {
      type: "date",
      placeholder: _vm.$t("Pick a day")
    },
    model: {
      value: _vm.data.followUpOn,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "followUpOn", $$v);
      },
      expression: "data.followUpOn"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Followers")
    }
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Followers"),
      multiple: "",
      filterable: "",
      width: "100%"
    },
    model: {
      value: _vm.data.followers,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "followers", $$v);
      },
      expression: "data.followers"
    }
  }, _vm._l(_vm.$store.state.users.USERS, function (ts) {
    return _c("el-option", {
      key: ts.userId,
      attrs: {
        value: ts.userId,
        label: ts.fullName
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm.data.stage == 5 || _vm.data.stage == 6 || _vm.data.stage == 7 ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("Stage")
    }
  }, [_c("el-col", [_vm.options["crm_deal_stage"] && _vm.options["crm_deal_stage"][0] ? _c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      filterable: "",
      placeholder: _vm.$t("Please select reason")
    },
    model: {
      value: _vm.data.stage,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "stage", $$v);
      },
      expression: "data.stage"
    }
  }, _vm._l(_vm.options["crm_deal_stage"], function (type) {
    return _c("el-option", {
      key: type.value,
      attrs: {
        label: type.label,
        value: type.value
      }
    });
  }), 1) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _vm.data.stage == 6 || _vm.data.stage == 7 ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("Reason")
    }
  }, [_c("el-col", [_vm.closedReasons && _vm.closedReasons[0] ? _c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      filterable: "",
      placeholder: _vm.$t("Please select reason")
    },
    model: {
      value: _vm.data.closedReason,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "closedReason", $$v);
      },
      expression: "data.closedReason"
    }
  }, _vm._l(_vm.findClosedReason(_vm.data), function (type) {
    return _c("el-option", {
      key: type.value,
      attrs: {
        label: type.label,
        value: type.value
      }
    });
  }), 1) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _vm.data.stage == 6 || _vm.data.stage == 7 || _vm.data.stage == 8 ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("Reason Note")
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: "",
      placeholder: _vm.$t("Please type reason")
    },
    model: {
      value: _vm.data.closingNotes,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "closingNotes", $$v);
      },
      expression: "data.closingNotes"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Company"),
      prop: "accountName.value"
    }
  }, [_c("kv-accounts", {
    key: _vm.data.recordId + "" + _vm.changeKey,
    attrs: {
      allowCreate: false,
      width: "350",
      popup: false,
      skey: _vm.changeKey,
      multiple: false,
      defaultNewAccount: _vm.defaultNewAccount
    },
    on: {
      isFormOpen: function isFormOpen($event) {
        _vm.formOpen["account"] = $event;
      },
      save: function save($event) {
        _vm.defaultNewContact.accountName = _vm.data.accountName ? _vm.data.accountName.value : 0;
      }
    },
    model: {
      value: _vm.data.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "accountName", $$v);
      },
      expression: "data.accountName"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Customer Type"),
      prop: "data.type"
    }
  }, [_vm.options["crm_deal_type"] && _vm.options["crm_deal_type"][0] ? _c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      filterable: "",
      placeholder: _vm.$t("Please select reason")
    },
    model: {
      value: _vm.data.type,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "type", $$v);
      },
      expression: "data.type"
    }
  }, _vm._l(_vm.options["crm_deal_type"], function (type) {
    return _c("el-option", {
      key: type.value,
      attrs: {
        label: type.label,
        value: type.value
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Contact Person")
    }
  }, [_c("kv-contacts", {
    key: _vm.data.recordId + "" + _vm.changeKey,
    attrs: {
      allowCreate: false,
      popup: false,
      skey: _vm.changeKey,
      account: _vm.data.accountName,
      multiple: false,
      width: "350",
      defaultNewContact: _vm.defaultNewContact
    },
    on: {
      isFormOpen: function isFormOpen($event) {
        _vm.formOpen["contact"] = $event;
      },
      input: function input($event) {
        _vm.data.contactName = $event.value;
        _vm.data.contactNameLabel = $event.label;
        _vm.data.contact.value = $event.value;
        _vm.data.contact.label = $event.label;
      },
      setAccount: function setAccount($event) {
        _vm.data.accountName.label = $event.label;
        _vm.data.accountName.value = $event.value;
      }
    },
    model: {
      value: _vm.data.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "contact", $$v);
      },
      expression: "data.contact"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Description")
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: _vm.$t("Description")
    },
    model: {
      value: _vm.data.description,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "description", $$v);
      },
      expression: "data.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.$t("Hot Prospect")
    }
  }, [_c("el-col", [_c("el-switch", {
    attrs: {
      "active-color": "#f64e60"
    },
    on: {
      change: function change($event) {
        return _vm.saveDirect();
      }
    },
    model: {
      value: _vm.data.hotProspect,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "hotProspect", $$v);
      },
      expression: "data.hotProspect"
    }
  })], 1)], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      plain: "",
      size: "small",
      icon: "fal fa-save",
      type: "primary",
      disabled: _vm.formOpen["account"] || _vm.formOpen["contact"]
    },
    on: {
      click: function click($event) {
        return _vm.saveForm();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Save")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      plain: "",
      size: "small",
      icon: "fal fa-times",
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.setDialogFormVisible("", true);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Cancel")))]), _vm._v(" "), this.id && this.id != "new" ? _c("el-link", {
    staticClass: "pull-right",
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      plain: "",
      size: "small",
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.removeDeal();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-trash"
  }), _vm._v("\n         " + _vm._s(_vm.$t("Remove")) + "\n     ")]) : _vm._e()], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };