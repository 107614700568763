import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.json.stringify.js";
export function inboxThreadModel() {
  var _Object$assign;
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign((_Object$assign = {
    subject: null,
    message: null,
    messageStatus: null,
    incomingChannel: null,
    messageCount: null,
    caseStatus: {
      value: 1
    },
    requestType: {
      value: 1
    },
    requestStatus: {
      value: 1
    },
    estimatedStartDate: null,
    estimatedDeliveryDate: null
  }, _defineProperty(_Object$assign, "requestStatus", {
    value: 1
  }), _defineProperty(_Object$assign, "category", null), _defineProperty(_Object$assign, "sender", 2), _defineProperty(_Object$assign, "urgent", null), _defineProperty(_Object$assign, "star", null), _defineProperty(_Object$assign, "closedOn", null), _defineProperty(_Object$assign, "followUpOn", null), _defineProperty(_Object$assign, "expectedClosingDate", null), _defineProperty(_Object$assign, "team", null), _defineProperty(_Object$assign, "project", {
    value: null,
    label: null
  }), _defineProperty(_Object$assign, "sla", {
    value: null,
    label: null
  }), _defineProperty(_Object$assign, "slaLevel", {
    value: null,
    label: null
  }), _defineProperty(_Object$assign, "sla_remaining_time_calculated", null), _defineProperty(_Object$assign, "priority", {
    id: 3,
    value: 3,
    label: "Medium"
  }), _defineProperty(_Object$assign, "accountName", {
    recordId: null,
    label: ''
  }), _defineProperty(_Object$assign, "brand", {
    value: null,
    label: ''
  }), _defineProperty(_Object$assign, "lastMessage", null), _defineProperty(_Object$assign, "contactName", null), _defineProperty(_Object$assign, "senderName", null), _defineProperty(_Object$assign, "senderEmail", null), _defineProperty(_Object$assign, "cc", []), _defineProperty(_Object$assign, "bcc", []), _defineProperty(_Object$assign, "senderPhone", null), _defineProperty(_Object$assign, "notifyOn", null), _defineProperty(_Object$assign, "notifyMsg", null), _defineProperty(_Object$assign, "firstAssignedTime", null), _defineProperty(_Object$assign, "timeSinceLastReply", null), _defineProperty(_Object$assign, "lastModifiedOn", null), _defineProperty(_Object$assign, "lastModifiedBy", null), _defineProperty(_Object$assign, "newContact", {
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    account_name: {
      recordId: null,
      label: ''
    }
  }), _defineProperty(_Object$assign, "recordOwner", {
    userId: null,
    label: null
  }), _Object$assign), JSON.parse(JSON.stringify(data)));
}