// import actions from './actions'
// import mutations from './mutations'
// import getters from './getters'
import moment from 'moment';
export var timesheet = {
  namespaced: true,
  state: {
    filter: {
      project: {
        value: []
      },
      dateRange: [],
      listType: 'week',
      team: null,
      daytoday: false,
      weekends: true,
      week: moment().startOf('isoWeek'),
      month: moment().startOf('isoMonth')
    },
    modal: false
  }
};