// import actions from './actions'
// import mutations from './mutations'
// import getters from './getters'
var date = new Date();
var day = 60 * 60 * 24 * 1000;
// Add a day
var start = new Date(date.setDate(date.getDate() - 15));
var end = new Date(date.setDate(date.getDate() + 115));
export var planner = {
  namespaced: true,
  state: {
    selectedPlan: {
      recordId: null
    },
    modal: false,
    filter: {
      project: [],
      showAs: "23",
      dateRange: [start, end],
      team: null,
      hideSections: true
    }
  }
};