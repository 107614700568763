import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { mapGetters } from "vuex";
import svgIcon from "@/components/kiva/kvSvgIcon.vue";
export default {
  name: "KvAvatar",
  data: function data() {
    return {
      userName: "",
      fit: 'contain'
    };
  },
  components: {
    svgIcon: svgIcon
  },
  props: {
    userId: {
      type: [Number, String]
    },
    border: {
      type: Object
    },
    MY_ACTIVITY_STATUS: {
      type: [Object, String, Boolean]
    },
    background: {
      type: String
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: "64x64"
    },
    hasBorder: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    className: {
      type: String
    }
  },
  computed: _objectSpread({
    classes: function classes() {
      var classes = ["is-".concat(this.size)];
      if (this.isRounded) {
        classes.push("is-rounded");
      }
      if (this.hasBorder) {
        classes.push("has-border");
      }
      return classes;
    },
    getSize: function getSize() {
      var size = this.size.substring(0, 2);
      return size == 16 ? 16 : size == 18 ? 18 : size == 20 ? 20 : size == 24 ? 24 : size == 32 ? 32 : size == 36 ? 36 : size == 38 ? 38 : size == 40 ? 40 : size == 48 ? 48 : 30;
    },
    avatarUrl: function avatarUrl() {
      // let url = require('@/assets/avatar/dummy-avatar.svg')
      var url = "";
      var user = this.findUserById(parseInt(this.userId));
      if (user) {
        if (user.photo) {
          return "".concat(this.$kvBaseUrl, "/").concat(user.photo);
        } else {
          this.userName = this.getFirstChars(user.fullName);
        }
      }
      return url;
    }
  }, mapGetters("users", ["findUserById"])),
  methods: {
    notEmpty: function notEmpty() {
      return this.userId !== 0 && this.userId !== "" && this.userId !== null;
    },
    getBorderStyle: function getBorderStyle() {
      if (this.border && this.border.size && this.border.color) {
        return {
          borderWidth: this.border.size,
          borderColor: this.border.color,
          borderStyle: "solid"
        };
      }
      if (this.background) {
        return {
          backgroundColor: this.background
        };
      }
      return {};
    },
    getFirstChars: function getFirstChars(name) {
      if (name) {
        var ex = name.split(" ");
        var f = name.charAt(0);
        var all = ex.length - 1;
        var l = "";
        if (f == '"') {
          f = name.charAt(1);
        }
        if (all != 0 && ex[all]) {
          l = ex[all].charAt(0);
        }
        return (f + l).toLocaleUpperCase("tr-TR");
      }
      return "--";
    }
  }
};