import { REDIRECTS, setRouterIndex } from '@/router/routerSchema';
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'Kiva Suite',
      homeRoute: '',
      logo: '/logo-kv-blue-44.svg',
      logo_beta: '/beta.svg'
    };
  },
  computed: {
    myuxp: function myuxp() {
      return this.$store.state.users.MY_UX_PROFILE;
    }
  },
  created: function created() {},
  methods: {
    setHomeRoute: function setHomeRoute() {
      var newRoute = setRouterIndex(REDIRECTS, this.$store.state.users.MY_UX_PROFILE);
      // console.log("this.$store.state.users.MY_UX_PROFILE",this.$store.state.users.MY_UX_PROFILE);
      if (newRoute) {
        this.homeRoute = newRoute;
      } else {
        this.homeRoute = '/focus';
      }
    }
  },
  watch: {
    myuxp: function myuxp() {
      this.setHomeRoute();
    }
  }
};