import "core-js/modules/es.json.stringify.js";
export function sendMailModel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign({
    appId: null,
    modelId: null,
    from: null,
    to: [],
    cc: [],
    bcc: [],
    subject: null,
    body_html: null,
    includeTemplate: true,
    scheduleDate: null
  }, JSON.parse(JSON.stringify(data)));
}