import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { TEAM_MEMBERS } from '@/store/modules/team/mutation-types';
export default {
  findTeamByUserId: function findTeamByUserId(state) {
    return function (userId) {
      var team = state[TEAM_MEMBERS].find(function (member) {
        return member.recordOwner.value === userId;
      });
      if (team) {
        return team.team;
      }
    };
  }
};