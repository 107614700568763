export var fileTypeMixin = {
  methods: {
    fileTypeIcon: function fileTypeIcon(type) {
      if (type === 'docx') {
        return 'fal fa-file-word';
      } else if (type === 'pdf') {
        return 'fal fa-file-pdf';
      } else if (type === 'png' || type === 'jpg' || type === 'jpeg') {
        return 'fal fa-file-image';
      }
    }
  }
};