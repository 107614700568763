var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-input", _vm._g(_vm._b({
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, "el-input", _vm.attrs, false), _vm.listeners))], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };