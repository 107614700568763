import moment from 'moment';
export var state = {
  list: [],
  threadPopup: {
    data: {},
    show: false
  },
  filters: {
    keyword: null,
    sort: 'last_message_date:desc',
    dates: [],
    users: [],
    team: [],
    project: [],
    category: [],
    contact: [],
    account: [],
    limit: 50,
    brand: [],
    currentPage: 1,
    star: false,
    hasChanged: false
  }
};