var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.plusButton.show ? _c("div", {
    staticClass: "darkBg",
    class: _vm.plusButton.show ? "opacityHigh" : "opacityLow",
    on: {
      click: function click($event) {
        return _vm.closeEvent();
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.plusButton.show ? _c("div", {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEvent,
      expression: "closeEvent"
    }],
    staticClass: "plusButton kv-shadow-default"
  }, [_vm._l(_vm.menu, function (item) {
    return [_vm.hasProduct(item.license) ? _c("div", {
      key: item.value,
      staticClass: "menu",
      attrs: {
        id: "m" + item.value
      }
    }, [_c("div", {
      staticClass: "menuTitle"
    }, [_c("kv-svg-icon", {
      attrs: {
        icon: item.icon
      }
    }), _vm._v("\n          " + _vm._s(_vm.$t(item.label)) + "\n        ")], 1), _vm._v(" "), _c("div", {
      staticClass: "subItems"
    }, [_vm._l(item.subList, function (sub) {
      return [!sub.license || _vm.hasProduct(sub.license) ? _c("div", {
        key: sub.value,
        staticClass: "item",
        attrs: {
          id: "m" + item.value + "_" + sub.value
        },
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          }
        }
      }, [_c("kv-svg-icon", {
        attrs: {
          icon: sub.icon
        }
      }), _vm._v("\n              " + _vm._s(_vm.$t(sub.label)) + "\n            ")], 1) : _vm._e()];
    })], 2)]) : _vm._e()];
  })], 2) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };