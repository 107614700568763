var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.isCustom ? _c("svg", {
    staticClass: "svgicon",
    class: {
      "icon-spin": _vm.spin
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "".concat(_vm.iconPath)
    }
  })]) : _c("i", {
    class: _vm.iconPath
  });
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };