var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.view == "full",
      expression: "view == 'full'"
    }],
    staticClass: "padding-top-5",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    staticClass: "searchProject",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Type keyword"),
      clearable: "",
      plain: ""
    },
    on: {
      blur: function blur($event) {
        return _vm.getData();
      }
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getData();
      }
    },
    model: {
      value: _vm.searchKeyword,
      callback: function callback($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  })], 1)], 1), _vm._v(" "), _vm.items && _vm.items.length > 0 ? _c("div", {
    staticClass: "limitedHeight kv-scroll",
    class: _vm.view == "light" ? "light" : "full"
  }, [_vm._l(_vm.items, function (item, i) {
    return _c("div", {
      key: "deal" + i,
      staticClass: "full-width dealList"
    }, [_c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "card-title",
      on: {
        click: function click($event) {
          return _vm.editData(item.record_id);
        }
      }
    }, [_c("span", [_vm._v("  " + _vm._s(item.subject) + "   ")])]), _vm._v(" "), _c("div", {
      staticClass: "card-detail"
    }, [item.project ? _c("span", {
      staticClass: "title2"
    }, [_vm._v("\n              " + _vm._s(item.project.label) + "\n          ")]) : _vm._e(), _vm._v(" "), item.project ? _c("span", {
      staticClass: "title2"
    }, [_vm._v("-")]) : _vm._e(), _vm._v(" "), item.task_status ? _c("span", {
      staticClass: "title2"
    }, [_c("a", {
      style: {
        color: item.relatedData.task_status ? "#" + item.relatedData.task_status.color : ""
      }
    }, [_vm._v(" " + _vm._s(item.task_status.label) + " ")])]) : _vm._e(), _vm._v(" "), item.task_status ? _c("span", {
      staticClass: "title2"
    }, [_vm._v("-")]) : _vm._e(), _vm._v(" "), item.start_date ? _c("span", {
      staticClass: "title2"
    }, [_vm._v("\n            " + _vm._s(_vm._f("date")(item.end_date, "DD MMM YYYY")) + " \n          ")]) : _vm._e()])])]);
  }), _vm._v(" "), _vm.view == "light" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("full");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("More")) + "...")]) : _vm._e(), _vm._v(" "), _vm.view == "full" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("light");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Close")))]) : _vm._e()], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };