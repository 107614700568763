import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _TEMPLATE_CATEGORIES$;
import "core-js/modules/es.array.sort.js";
import { TEMPLATE_CATEGORIES, TEMPLATE_STATUSES } from '@rulesup/store/checklist/mutation-types';
export default (_TEMPLATE_CATEGORIES$ = {}, _defineProperty(_TEMPLATE_CATEGORIES$, TEMPLATE_CATEGORIES, function (state, payload) {
  state[TEMPLATE_CATEGORIES] = payload.sort(function (a, b) {
    return a.sort_order - b.sort_order;
  });
}), _defineProperty(_TEMPLATE_CATEGORIES$, TEMPLATE_STATUSES, function (state, payload) {
  state[TEMPLATE_STATUSES] = payload;
}), _TEMPLATE_CATEGORIES$);