import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import moment from 'moment';
Vue.filter('timeDiff', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD MMM YY hh:ss';
  var formattedDate = moment(date).format(formatStr);
  if (date) {
    var diff = moment().diff(moment(date), "minutes");
    if (diff > -1 && diff < 2) {
      return "Now";
    } else if (diff > -1 && diff < 60) {
      return diff + " mins. ago";
    } else if (diff > 60 && diff < 1440) {
      var ndiff = Math.round(diff / 60);
      return ndiff + " hours ago";
    } else if (diff >= 1440) {
      return formattedDate;
    }
  }
  return "";
});
Vue.filter('dateDiff', function (date) {
  if (date) {
    var diff = moment().diff(moment(date), "days");
    if (diff == 0) {
      return "Today";
    } else if (diff == 1) {
      return "Yesterday";
    } else if (diff >= 2) {
      return diff + "d. ago";
    } else if (diff == -1) {
      return "Tomorrow";
    } else if (diff < -1) {
      return -1 * diff + "d. later";
    } else {
      return diff + "days";
    }
  }
  return "";
});
Vue.filter('onlyDateAndText', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD MMM YY';
  var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "en";
  moment.locale(locale);
  var formattedDate = moment(date).format(formatStr);
  var today = moment().format(formatStr);
  var yesterday = moment().add(-1, 'days').format(formatStr);
  if (date) {
    if (yesterday == formattedDate) {
      return locale == 'tr' ? 'Dün ' : "Yesterday";
    } else if (today == formattedDate) {
      return locale == 'tr' ? 'Bugün ' : "Today";
    } else {
      return formattedDate;
    }
  }
  return "";
});
Vue.filter('durationText', function (duration) {
  var time = duration * 3600;
  var hour = Math.floor(duration);
  var min = Math.floor((duration - hour) * 100 / 10 * 6);
  return (hour > 0 ? hour + "h" : '') + (min > 0 ? min + "m" : '');
});