import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import VeeValidate, { ValidationObserver, ValidationProvider, Validator } from 'vee-validate';
Validator.extend('kv_password', {
  getMessage: function getMessage(field, params, data) {
    return "Password criterias not matched";
  },
  validate: function validate(password) {
    var criterias = {
      moreThan8: password.length >= 8,
      containsNumber: /\d/.test(password),
      containsUppercase: /[A-Z]/.test(password),
      containsSpecialChar: /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)
    };
    return Object.keys(criterias).every(function (key) {
      return criterias[key];
    });
  }
});
import i18n from '@/lang';
import en from '@/lang/validations/en';
import tr from '@/lang/validations/tr';
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  // customize the root path for validation messages.
  i18n: i18n,
  events: '',
  dictionary: {
    en: en,
    tr: tr
  },
  errorBagName: 'errors',
  // change if property conflicts 
  fieldsBagName: 'fields_all',
  locale: 'en',
  classes: true,
  aria: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});