var BRIDGE_URL = process.env.VUE_APP_API_BRIDGE;
var BASE_URL = process.env.VUE_APP_BASE_URL;
var IDS_LIMIT = 50;
var SORT_TYPES = [{
  label: 'Last updated ascending',
  value: 'last_updated:asc'
}, {
  label: 'Last updated descending',
  value: 'last_updated:desc'
}, {
  label: 'Created at ascending',
  value: 'created_at:asc'
}, {
  label: 'Created at descending',
  value: 'created_at:desc'
}, {
  label: 'Last updated ascending',
  value: 'last_message_date:asc'
}, {
  label: 'Last message descending',
  value: 'last_message_date:desc'
}];
export { BRIDGE_URL, BASE_URL, IDS_LIMIT, SORT_TYPES };