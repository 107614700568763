import { render, staticRenderFns } from "./taskModal.vue?vue&type=template&id=3f366a00&scoped=true&"
import script from "./taskModal.vue?vue&type=script&lang=js&"
export * from "./taskModal.vue?vue&type=script&lang=js&"
import style0 from "@/components/tasks/style/taskEditModal.scss?vue&type=style&index=0&id=3f366a00&scoped=true&lang=scss&"
import style1 from "./taskModal.vue?vue&type=style&index=1&id=3f366a00&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f366a00",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/kivateknoloji/kivacloud/corona-ux/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3f366a00')) {
      api.createRecord('3f366a00', component.options)
    } else {
      api.reload('3f366a00', component.options)
    }
    module.hot.accept("./taskModal.vue?vue&type=template&id=3f366a00&scoped=true&", function () {
      api.rerender('3f366a00', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/apps/rulesup/components/taskModal.vue"
export default component.exports