export var state = {
  unreadNotification: 0,
  config: {
    paused: false,
    pausedAtDays: [],
    pausedBetweenTime: []
  },
  timeToRefresh: 20000,
  alwaysUpToDate: true,
  noUnreadSuccess: false,
  scheduledDoNotDisturb: false
};