import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { CURRENT_USER, USERS } from '@/store/modules/users/mutation-types';
export default {
  findUserById: function findUserById(state) {
    return function (userId) {
      return state[USERS].find(function (user) {
        return user.userId === userId;
      });
    };
  },
  getCurrentUser: function getCurrentUser(state) {
    return state[CURRENT_USER];
  },
  currentUserLocale: function currentUserLocale(state) {
    return state[CURRENT_USER] && state[CURRENT_USER].locale;
  }
};