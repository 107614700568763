import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.find-index.js";
var defaultOptions = {
  elementKey: 'id',
  parentKey: 'parentId',
  childKey: 'children'
};
export function makeNest(data) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _objectSpread({}, defaultOptions);
  var options = _objectSpread(_objectSpread({}, defaultOptions), config);
  var root = [];
  var map = {};
  var flat = JSON.parse(JSON.stringify(data));
  flat.forEach(function (node) {
    if (!node[options.parentKey]) return root.push(node);
    var parentIndex = map[node[options.parentKey]];
    if (typeof parentIndex !== 'number') {
      parentIndex = flat.findIndex(function (el) {
        return el[options.elementKey] === node[options.parentKey];
      });
      map[node[options.parentKey]] = parentIndex;
    }
    if (flat[parentIndex]) {
      if (!flat[parentIndex][options.childKey]) {
        return flat[parentIndex][options.childKey] = [node];
      }
      flat[parentIndex][options.childKey].push(node);
    }
  });
  return root;
}