import { render, staticRenderFns } from "./dealsForm.vue?vue&type=template&id=efe02dfa&"
import script from "./dealsForm.vue?vue&type=script&lang=js&"
export * from "./dealsForm.vue?vue&type=script&lang=js&"
import style0 from "./dealsForm.vue?vue&type=style&index=0&id=efe02dfa&scope=true&lang=css&"
import style1 from "./dealsForm.vue?vue&type=style&index=1&id=efe02dfa&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/kivateknoloji/kivacloud/corona-ux/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('efe02dfa')) {
      api.createRecord('efe02dfa', component.options)
    } else {
      api.reload('efe02dfa', component.options)
    }
    module.hot.accept("./dealsForm.vue?vue&type=template&id=efe02dfa&", function () {
      api.rerender('efe02dfa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/deals/dealsForm.vue"
export default component.exports