if (void 0 === CodeMirror) var CodeMirror = void 0;
!function () {
  var Ajax = {
      settings: {},
      post: function (t) {
        return new AjaxRequest("post", t);
      },
      get: function (t) {
        return new AjaxRequest("get", t);
      },
      request: function (t, e) {
        return new AjaxRequest(t, e);
      }
    },
    AjaxRequest = function (t, e) {
      var i = {
        method: t,
        url: "",
        before: function () {},
        success: function () {},
        error: function () {},
        data: !1,
        async: !0,
        headers: {}
      };
      this.p = this.extend(i, e), this.p = this.extend(this.p, Ajax.settings), this.p.method = this.p.method.toUpperCase(), this.prepareData(), this.xhr = new XMLHttpRequest(), this.xhr.open(this.p.method, this.p.url, this.p.async), this.setHeaders(), !1 !== ("function" != typeof this.p.before || this.p.before(this.xhr)) && this.send();
    };
  AjaxRequest.prototype = {
    extend: function (t, e) {
      if (e) for (var i in e) t[i] = e[i];
      return t;
    },
    prepareData: function () {
      if (-1 === ["POST", "PUT"].indexOf(this.p.method) || this.isFormData() || (this.p.headers["Content-Type"] = "application/x-www-form-urlencoded"), "object" != typeof this.p.data || this.isFormData() || (this.p.data = this.toParams(this.p.data)), "GET" === this.p.method) {
        var t = -1 !== this.p.url.search(/\?/) ? "&" : "?";
        this.p.url = this.p.data ? this.p.url + t + this.p.data : this.p.url;
      }
    },
    setHeaders: function () {
      for (var t in this.xhr.setRequestHeader("X-Requested-With", this.p.headers["X-Requested-With"] || "XMLHttpRequest"), this.p.headers) this.xhr.setRequestHeader(t, this.p.headers[t]);
    },
    isFormData: function () {
      return void 0 !== window.FormData && this.p.data instanceof window.FormData;
    },
    isComplete: function () {
      return !(this.xhr.status < 200 || 300 <= this.xhr.status && 304 !== this.xhr.status);
    },
    send: function () {
      this.p.async ? (this.xhr.onload = this.loaded.bind(this), this.xhr.send(this.p.data)) : (this.xhr.send(this.p.data), this.loaded.call(this));
    },
    loaded: function () {
      if (this.isComplete()) {
        var t = this.xhr.response,
          e = this.parseJson(t);
        t = e || t, "function" == typeof this.p.success && this.p.success(t, this.xhr);
      } else "function" == typeof this.p.error && this.p.error(this.xhr.statusText);
    },
    parseJson: function (t) {
      try {
        var e = JSON.parse(t);
        if (e && "object" == typeof e) return e;
      } catch (t) {
        return !1;
      }
      return !1;
    },
    toParams: function (e) {
      return Object.keys(e).map(function (t) {
        return encodeURIComponent(t) + "=" + encodeURIComponent(e[t]);
      }).join("&");
    }
  };
  var DomCache = [0],
    DomExpando = "data" + new Date().getTime(),
    Dom = function (t, e) {
      return this.parse(t, e);
    };
  Dom.ready = function (t) {
    document.addEventListener("DOMContentLoaded", t);
  }, Dom.prototype = {
    get length() {
      return this.nodes.length;
    },
    parse: function (t, e) {
      var i;
      if (t) {
        if (t instanceof Dom) return this.nodes = t.nodes, t;
        i = /^\s*<(\w+|!)[^>]*>/.test(t) ? this.create(t) : "string" != typeof t ? t.nodeType && 11 === t.nodeType ? t.childNodes : t.nodeType || this._isWindowNode(t) ? [t] : t : this._query(t, e);
      } else i = [];
      this.nodes = this._slice(i);
    },
    create: function (t) {
      if (/^<(\w+)\s*\/?>(?:<\/\1>|)$/.test(t)) return [document.createElement(RegExp.$1)];
      var e = [],
        i = document.createElement("div");
      i.innerHTML = t;
      for (var s = 0, n = i.childNodes.length; s < n; s++) e.push(i.childNodes[s]);
      return e;
    },
    dataset: function (e, i) {
      return this.each(function (t) {
        DomCache[this.dataindex(t.get())][e] = i;
      });
    },
    dataget: function (t) {
      return DomCache[this.dataindex(this.get())][t];
    },
    dataindex: function (t) {
      var e = t[DomExpando],
        i = DomCache.length;
      return e || (e = i, t && (t[DomExpando] = i), DomCache[e] = {}), e;
    },
    add: function (t) {
      return this.nodes = this.nodes.concat(this._array(t)), this;
    },
    get: function (t) {
      return this.nodes[t || 0] || !1;
    },
    getAll: function () {
      return this.nodes;
    },
    eq: function (t) {
      return new Dom(this.nodes[t]);
    },
    first: function () {
      return new Dom(this.nodes[0]);
    },
    last: function () {
      return new Dom(this.nodes[this.nodes.length - 1]);
    },
    contents: function () {
      return this.get().childNodes;
    },
    each: function (t) {
      for (var e = this.nodes.length, i = 0; i < e; i++) t.call(this, new Dom(this.nodes[i]), i);
      return this;
    },
    is: function (t) {
      return 0 < this.filter(t).length;
    },
    filter: function (e) {
      var t;
      return void 0 === e ? this : (t = "function" == typeof e ? function (t) {
        return e(new Dom(t));
      } : function (t) {
        return e && e.nodeType || e instanceof Node ? e === t : (t.matches = t.matches || t.msMatchesSelector || t.webkitMatchesSelector, 1 === t.nodeType && t.matches(e || "*"));
      }, new Dom(this.nodes.filter.call(this.nodes, t)));
    },
    not: function (e) {
      return this.filter(function (t) {
        return !new Dom(t).is(e || !0);
      });
    },
    find: function (n) {
      var a = [];
      return this.each(function (t) {
        for (var e = t.get(), i = this._query(n, e), s = 0; s < i.length; s++) a.push(i[s]);
      }), new Dom(a);
    },
    children: function (t) {
      var n = [];
      return this.each(function (t) {
        var e = t.get();
        if (e.children) for (var i = e.children, s = 0; s < i.length; s++) n.push(i[s]);
      }), new Dom(n).filter(t);
    },
    parent: function (t) {
      var e = this.get(),
        i = !!e.parentNode && e.parentNode;
      return i ? new Dom(i).filter(t) : new Dom();
    },
    parents: function (i, s) {
      s = this._context(s);
      var n = [];
      return this.each(function (t) {
        for (var e = t.get().parentNode; e && e !== s;) i ? new Dom(e).is(i) && n.push(e) : n.push(e), e = e.parentNode;
      }), new Dom(n);
    },
    closest: function (i, s) {
      s = this._context(s);
      var n = [],
        a = i && i.nodeType;
      return this.each(function (t) {
        var e = t.get();
        do {
          if (e && (a && e === i || new Dom(e).is(i))) return n.push(e);
        } while ((e = e.parentNode) && e !== s);
      }), new Dom(n);
    },
    next: function (t) {
      return this._sibling(t, "nextSibling");
    },
    nextElement: function (t) {
      return this._sibling(t, "nextElementSibling");
    },
    prev: function (t) {
      return this._sibling(t, "previousSibling");
    },
    prevElement: function (t) {
      return this._sibling(t, "previousElementSibling");
    },
    css: function (n, a) {
      if (void 0 !== a || "object" == typeof n) return this.each(function (t) {
        var e = t.get(),
          i = {};
        for (var s in "object" == typeof n ? i = n : i[n] = a, i) e.style && (e.style[s] = i[s]);
      });
      var t = this.get();
      return "width" === n || "height" === n ? t.style ? this._getHeightOrWidth(n) + "px" : void 0 : t.style ? getComputedStyle(t, null)[n] : void 0;
    },
    attr: function (n, a, o) {
      if (o = o ? "data-" : "", void 0 !== a || "object" == typeof n) return this.each(function (t) {
        var e = t.get(),
          i = {};
        for (var s in "object" == typeof n ? i = n : i[n] = a, i) 3 !== e.nodeType && ("checked" === s ? e.checked = i[s] : e.setAttribute(o + s, i[s]));
      });
      var t = this.get();
      return t && 3 !== t.nodeType ? "checked" === n ? t.checked : this._boolean(t.getAttribute(o + n)) : void 0;
    },
    data: function (t, e) {
      if (void 0 !== t) return this.attr(t, e, !0);
      function i(t) {
        return t[1].toUpperCase();
      }
      var s = /^data-(.+)$/,
        n = this.get().attributes,
        a = {};
      for (var o in n) if (n[o] && s.test(n[o].nodeName)) {
        var r = n[o].nodeName.match(s)[1],
          p = n[o].value;
        r = r.replace(/-([a-z])/g, i), p = -1 !== p.search(/^{/) ? this._object(p) : this._number(p) ? parseFloat(p) : this._boolean(p), a[r] = p;
      }
      return a;
    },
    val: function (i) {
      if (void 0 !== i) return this.each(function (t) {
        var e = t.get();
        e.type && "checkbox" === e.type ? e.checked = i : e.value = i;
      });
      var t = this.get();
      return t.type && "checkbox" === t.type ? t.checked : t.value;
    },
    removeAttr: function (i) {
      return this.each(function (t) {
        var e = t.get();
        i.split(" ").forEach(function (t) {
          3 !== e.nodeType && e.removeAttribute(t);
        });
      });
    },
    addClass: function (t) {
      return this._eachClass(t, "add");
    },
    removeClass: function (t) {
      return this._eachClass(t, "remove");
    },
    toggleClass: function (t) {
      return this._eachClass(t, "toggle");
    },
    hasClass: function (t) {
      var e = this.get();
      return !!e.classList && e.classList.contains(t);
    },
    empty: function () {
      return this.each(function (t) {
        t.get().innerHTML = "";
      });
    },
    html: function (t) {
      return void 0 === t ? this.get().innerHTML || "" : this.empty().append(t);
    },
    text: function (e) {
      return void 0 === e ? this.get().textContent || "" : this.each(function (t) {
        t.get().textContent = e;
      });
    },
    after: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t) e.insertAdjacentHTML("afterend", t);else if (null !== e.parentNode) for (var i = t instanceof Node ? [t] : this._array(t).reverse(), s = 0; s < i.length; s++) e.parentNode.insertBefore(i[s], e.nextSibling);
        return e;
      });
    },
    before: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t) e.insertAdjacentHTML("beforebegin", t);else for (var i = t instanceof Node ? [t] : this._array(t), s = 0; s < i.length; s++) e.parentNode.insertBefore(i[s], e);
        return e;
      });
    },
    append: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t || "number" == typeof t) e.insertAdjacentHTML("beforeend", t);else for (var i = t instanceof Node ? [t] : this._array(t), s = 0; s < i.length; s++) e.appendChild(i[s]);
        return e;
      });
    },
    prepend: function (t) {
      return this._inject(t, function (t, e) {
        if ("string" == typeof t || "number" == typeof t) e.insertAdjacentHTML("afterbegin", t);else for (var i = t instanceof Node ? [t] : this._array(t).reverse(), s = 0; s < i.length; s++) e.insertBefore(i[s], e.firstChild);
        return e;
      });
    },
    wrap: function (t) {
      return this._inject(t, function (t, e) {
        var i = "string" == typeof t || "number" == typeof t ? this.create(t)[0] : t instanceof Node ? t : this._array(t)[0];
        return e.parentNode && e.parentNode.insertBefore(i, e), i.appendChild(e), i;
      });
    },
    unwrap: function () {
      return this.each(function (t) {
        for (var e = t.get(), i = document.createDocumentFragment(); e.firstChild;) {
          var s = e.removeChild(e.firstChild);
          i.appendChild(s);
        }
        e.parentNode.replaceChild(i, e);
      });
    },
    replaceWith: function (t) {
      return this._inject(t, function (t, e) {
        for (var i = document.createDocumentFragment(), s = "string" == typeof t || "number" == typeof t ? this.create(t) : t instanceof Node ? [t] : this._array(t), n = 0; n < s.length; n++) i.appendChild(s[n]);
        var a = i.childNodes[0];
        return e.parentNode && e.parentNode.replaceChild(i, e), a;
      });
    },
    remove: function () {
      return this.each(function (t) {
        var e = t.get();
        e.parentNode && e.parentNode.removeChild(e);
      });
    },
    clone: function (s) {
      var n = [];
      return this.each(function (t) {
        var e = t.get(),
          i = this._clone(e);
        s && (i = this._cloneEvents(e, i)), n.push(i);
      }), new Dom(n);
    },
    show: function () {
      return this.each(function (t) {
        var e = t.get();
        if (e.style && this._hasDisplayNone(e)) {
          var i = e.getAttribute("domTargetShow");
          e.style.display = i || "block", e.removeAttribute("domTargetShow");
        }
      }.bind(this));
    },
    hide: function () {
      return this.each(function (t) {
        var e = t.get();
        if (e.style && !this._hasDisplayNone(e)) {
          var i = e.style.display;
          "block" !== i && e.setAttribute("domTargetShow", i), e.style.display = "none";
        }
      });
    },
    scrollTop: function (t) {
      var e = this.get(),
        i = this._isWindowNode(e),
        s = 9 === e.nodeType ? e.scrollingElement || e.body.parentNode || e.body || e.documentElement : e;
      return void 0 !== t ? (t = parseInt(t), void (i ? e.scrollTo(0, t) : s.scrollTop = t)) : i ? e.pageYOffset : s.scrollTop;
    },
    offset: function () {
      return this._getPos("offset");
    },
    position: function () {
      return this._getPos("position");
    },
    width: function (t) {
      return void 0 !== t ? this.css("width", parseInt(t) + "px") : this._getSize("width", "Width");
    },
    height: function (t) {
      return void 0 !== t ? this.css("height", parseInt(t) + "px") : this._getSize("height", "Height");
    },
    outerWidth: function () {
      return this._getSize("width", "Width", "outer");
    },
    outerHeight: function () {
      return this._getSize("height", "Height", "outer");
    },
    innerWidth: function () {
      return this._getSize("width", "Width", "inner");
    },
    innerHeight: function () {
      return this._getSize("height", "Height", "inner");
    },
    click: function () {
      return this._trigger("click");
    },
    focus: function () {
      return this._trigger("focus");
    },
    blur: function () {
      return this._trigger("blur");
    },
    on: function (o, r, p) {
      return this.each(function (t) {
        for (var e = t.get(), i = o.split(" "), s = 0; s < i.length; s++) {
          var n = this._getEventName(i[s]),
            a = this._getEventNamespace(i[s]);
          r = p ? this._getOneHandler(r, o) : r, e.addEventListener(n, r), e._e = e._e || {}, e._e[a] = e._e[a] || {}, e._e[a][n] = e._e[a][n] || [], e._e[a][n].push(r);
        }
      });
    },
    one: function (t, e) {
      return this.on(t, e, !0);
    },
    off: function (o, r) {
      function p(t, e, i) {
        return t === i;
      }
      function l(t, e, i, s) {
        return e === s;
      }
      function h(t, e, i, s) {
        return t === i && e === s;
      }
      function e() {
        return !0;
      }
      return void 0 === o ? this.each(function (t) {
        this._offEvent(t.get(), !1, !1, r, e);
      }) : this.each(function (t) {
        for (var e = t.get(), i = o.split(" "), s = 0; s < i.length; s++) {
          var n = this._getEventName(i[s]),
            a = this._getEventNamespace(i[s]);
          "_events" === a ? this._offEvent(e, n, a, r, p) : n || "_events" === a ? this._offEvent(e, n, a, r, h) : this._offEvent(e, n, a, r, l);
        }
      });
    },
    serialize: function (t) {
      for (var e = {}, i = this.get().elements, s = 0; s < i.length; s++) {
        var n = i[s];
        if ((!/(checkbox|radio)/.test(n.type) || n.checked) && n.name && !n.disabled && "file" !== n.type) {
          if ("select-multiple" === n.type) for (var a = 0; a < n.options.length; a++) {
            var o = n.options[a];
            o.selected && (e[n.name] = o.value);
          }
          e[n.name] = this._number(n.value) ? parseFloat(n.value) : this._boolean(n.value);
        }
      }
      return t ? e : this._params(e);
    },
    scroll: function () {
      this.get().scrollIntoView({
        behavior: "smooth"
      });
    },
    fadeIn: function (t, e) {
      var i = this._anim(t, e, 500);
      return this.each(function (t) {
        t.css({
          display: "block",
          opacity: 0,
          animation: "fadeIn " + i.speed + "s ease-in-out"
        }), t.one("animationend", function () {
          t.css({
            opacity: "",
            animation: ""
          }), i.fn && i.fn(t);
        });
      });
    },
    fadeOut: function (t, e) {
      var i = this._anim(t, e, 300);
      return this.each(function (t) {
        t.css({
          opacity: 1,
          animation: "fadeOut " + i.speed + "s ease-in-out"
        }), t.one("animationend", function () {
          t.css({
            display: "none",
            opacity: "",
            animation: ""
          }), i.fn && i.fn(t);
        });
      });
    },
    slideUp: function (t, e) {
      var i = this._anim(t, e, 300);
      return this.each(function (t) {
        t.height(t.height()), t.css({
          overflow: "hidden",
          animation: "slideUp " + i.speed + "s ease-out"
        }), t.one("animationend", function () {
          t.css({
            display: "none",
            height: "",
            animation: ""
          }), i.fn && i.fn(t);
        });
      });
    },
    slideDown: function (t, e) {
      var i = this._anim(t, e, 400);
      return this.each(function (t) {
        t.height(t.height()), t.css({
          display: "block",
          overflow: "hidden",
          animation: "slideDown " + i.speed + "s ease-in-out"
        }), t.one("animationend", function () {
          t.css({
            overflow: "",
            height: "",
            animation: ""
          }), i.fn && i.fn(t);
        });
      });
    },
    _queryContext: function (t, e) {
      return 3 !== (e = this._context(e)).nodeType && "function" == typeof e.querySelectorAll ? e.querySelectorAll(t) : [];
    },
    _query: function (t, e) {
      var i = document;
      if (e) return this._queryContext(t, e);
      if (/^[.#]?[\w-]*$/.test(t)) {
        if ("#" !== t[0]) return "." === t[0] ? i.getElementsByClassName(t.slice(1)) : i.getElementsByTagName(t);
        var s = i.getElementById(t.slice(1));
        return s ? [s] : [];
      }
      return i.querySelectorAll(t);
    },
    _context: function (t) {
      return t ? "string" == typeof t ? document.querySelector(t) : t : document;
    },
    _sibling: function (i, s) {
      var n,
        a = i && i.nodeType;
      return this.each(function (t) {
        var e = t.get();
        do {
          if ((e = e[s]) && (a && e === i || new Dom(e).is(i))) return void (n = e);
        } while (e);
      }), new Dom(n);
    },
    _slice: function (t) {
      return t && 0 !== t.length ? t.length ? [].slice.call(t.nodes || t) : [t] : [];
    },
    _array: function (t) {
      if (void 0 === t) return [];
      if (t instanceof NodeList) {
        for (var e = [], i = 0; i < t.length; i++) e[i] = t[i];
        return e;
      }
      return t instanceof Dom ? t.nodes : t;
    },
    _object: function (t) {
      return new Function("return " + t)();
    },
    _params: function (t) {
      var e = "";
      for (var i in t) e += "&" + this._encodeUri(i) + "=" + this._encodeUri(t[i]);
      return e.replace(/^&/, "");
    },
    _boolean: function (t) {
      return "true" === t || "false" !== t && t;
    },
    _number: function (t) {
      return !isNaN(t) && !isNaN(parseFloat(t));
    },
    _inject: function (t, e) {
      for (var i = this.nodes.length, s = []; i--;) {
        var n = "function" == typeof t ? t.call(this, this.nodes[i]) : t,
          a = 0 === i ? n : this._clone(n),
          o = e.call(this, a, this.nodes[i]);
        o && (o.dom ? s.push(o.get()) : s.push(o));
      }
      return new Dom(s);
    },
    _clone: function (t) {
      if (void 0 !== t) return "string" == typeof t ? t : t instanceof Node || t.nodeType ? t.cloneNode(!0) : "length" in t ? [].map.call(this._array(t), function (t) {
        return t.cloneNode(!0);
      }) : void 0;
    },
    _cloneEvents: function (t, e) {
      var i = t._e;
      if (i) for (var s in (e._e = i)._events) for (var n = 0; n < i._events[s].length; n++) e.addEventListener(s, i._events[s][n]);
      return e;
    },
    _trigger: function (t) {
      var e = this.get();
      return e && 3 !== e.nodeType && e[t](), this;
    },
    _encodeUri: function (t) {
      return encodeURIComponent(t).replace(/!/g, "%21").replace(/'/g, "%27").replace(/\(/g, "%28").replace(/\)/g, "%29").replace(/\*/g, "%2A").replace(/%20/g, "+");
    },
    _getSize: function (t, e) {
      var i = this.get(),
        s = 0;
      return s = 3 === i.nodeType ? 0 : 9 === i.nodeType ? this._getDocSize(i, e) : this._isWindowNode(i) ? window["inner" + e] : this._getHeightOrWidth(t), Math.round(s);
    },
    _getDocSize: function (t, e) {
      var i = t.body,
        s = t.documentElement;
      return Math.max(i["scroll" + e], i["offset" + e], s["client" + e], s["scroll" + e], s["offset" + e]);
    },
    _getPos: function (t) {
      var e = this.get(),
        i = {
          top: 0,
          left: 0
        };
      if (3 === e.nodeType || this._isWindowNode(e) || 9 === e.nodeType) return i;
      if ("position" === t) return {
        top: e.offsetTop,
        left: e.offsetLeft
      };
      if ("offset" !== t) return i;
      var s = e.getBoundingClientRect(),
        n = e.ownerDocument,
        a = n.documentElement,
        o = n.defaultView;
      return {
        top: s.top + o.pageYOffset - a.clientTop,
        left: s.left + o.pageXOffset - a.clientLeft
      };
    },
    _getHeightOrWidth: function (t, e) {
      var i = t.charAt(0).toUpperCase() + t.slice(1),
        s = e || "offset",
        n = 0,
        a = this.get(),
        o = getComputedStyle(a, null),
        r = this.parents().filter(function (t) {
          var e = t.get();
          return 1 === e.nodeType && "none" === getComputedStyle(e, null).display && e;
        });
      if ("none" === o.display && r.add(a), 0 !== r.length) {
        var p = "visibility: hidden !important; display: block !important;",
          l = [];
        r.each(function (t) {
          var e = t.attr("style");
          null !== e && l.push(e), t.attr("style", null !== e ? e + ";" + p : p);
        }), n = a[s + i], r.each(function (t, e) {
          void 0 === l[e] ? t.removeAttr("style") : t.attr("style", l[e]);
        });
      } else n = a[s + i];
      return n;
    },
    _eachClass: function (i, s) {
      return this.each(function (t) {
        if (i) {
          var e = t.get();
          i.split(" ").forEach(function (t) {
            e.classList && e.classList[s](t);
          });
        }
      });
    },
    _getOneHandler: function (t, e) {
      var i = this;
      return function () {
        t.apply(this, arguments), i.off(e);
      };
    },
    _getEventNamespace: function (t) {
      var e = t.split("."),
        i = e[1] ? e[1] : "_events";
      return e[2] ? i + e[2] : i;
    },
    _getEventName: function (t) {
      return t.split(".")[0];
    },
    _offEvent: function (t, e, i, s, n) {
      for (var a in t._e) for (var o in t._e[a]) if (n(o, a, e, i)) for (var r = t._e[a][o], p = 0; p < r.length; p++) void 0 !== s && r[p].toString() !== s.toString() || (t.removeEventListener(o, r[p]), t._e[a][o].splice(p, 1), 0 === t._e[a][o].length && delete t._e[a][o], 0 === Object.keys(t._e[a]).length && delete t._e[a]);
    },
    _hasDisplayNone: function (t) {
      return "none" === t.style.display || "none" === (t.currentStyle ? t.currentStyle.display : getComputedStyle(t, null).display);
    },
    _anim: function (t, e, i) {
      return t = "function" == typeof t ? (e = t, i) : t || i, {
        fn: e,
        speed: t / 1e3
      };
    },
    _isWindowNode: function (t) {
      return t === window || t.parent && t.parent === window;
    }
  };
  var re_uuid = 0,
    RedactorX = function (t, e) {
      return RedactorXInit(t, e);
    },
    RedactorXInit = function (t, e) {
      var i;
      return $R.dom(t).each(function (t) {
        (i = t.dataget($R.namespace)) || (i = new App(t, e, re_uuid), t.dataset($R.namespace, i), $R.instances[re_uuid] = i, re_uuid++);
      }), i;
    },
    $R = RedactorX;
  $R.dom = function (t, e) {
    return new Dom(t, e);
  }, $R.ajax = Ajax, $R.instances = [], $R.namespace = "redactorx", $R.prefix = "rx", $R.version = "1.2.3", $R.settings = {}, $R.lang = {}, $R._mixins = {}, $R._repository = {}, $R._subscribe = {}, $R.keycodes = {
    BACKSPACE: 8,
    DELETE: 46,
    UP: 38,
    DOWN: 40,
    ENTER: 13,
    SPACE: 32,
    ESC: 27,
    TAB: 9,
    CTRL: 17,
    META: 91,
    SHIFT: 16,
    ALT: 18,
    RIGHT: 39,
    LEFT: 37
  }, $R.add = function (t, e, i) {
    if (i.translations && ($R.lang = $R.extend(!0, $R.lang, i.translations)), i.defaults) {
      var s = {};
      s[e] = i.defaults, $R.opts = $R.extend(!0, $R.opts, s);
    }
    if ("mixin" === t) $R._mixins[e] = i;else {
      if (i.subscribe) for (var n in i.subscribe) for (var a = n.split(","), o = 0; o < a.length; o++) {
        var r = a[o].trim();
        void 0 === $R._subscribe[r] && ($R._subscribe[r] = []), $R._subscribe[r].push({
          module: e,
          func: i.subscribe[n]
        });
      }
      function p() {}
      if ((p.prototype = i).mixins) for (var l = 0; l < i.mixins.length; l++) $R.inherit(p, $R._mixins[i.mixins[l]]);
      $R._repository[e] = {
        type: t,
        proto: p,
        obj: i
      };
    }
  }, $R.extend = function () {
    var i = {},
      s = !1,
      t = 0,
      e = arguments.length;
    "[object Boolean]" === Object.prototype.toString.call(arguments[0]) && (s = arguments[0], t++);
    function n(t) {
      for (var e in t) Object.prototype.hasOwnProperty.call(t, e) && (s && "[object Object]" === Object.prototype.toString.call(t[e]) ? i[e] = $R.extend(!0, i[e], t[e]) : i[e] = t[e]);
    }
    for (; t < e; t++) {
      n(arguments[t]);
    }
    return i;
  }, $R.error = function (t) {
    throw t;
  }, $R.inherit = function (t, e) {
    function i() {}
    i.prototype = e;
    var s = new i();
    for (var n in t.prototype) t.prototype.__lookupGetter__(n) ? s.__defineGetter__(n, t.prototype.__lookupGetter__(n)) : s[n] = t.prototype[n];
    return t.prototype = s, t.prototype.super = e, t;
  }, $R.addLang = function (t, e) {
    void 0 === $R.lang[t] && ($R.lang[t] = {}), $R.lang[t] = $R.extend(!0, $R.lang[t], e);
  }, RedactorX.opts = {
    plugins: [],
    content: !1,
    placeholder: !1,
    classes: !1,
    draggable: !1,
    editor: {
      classname: "content",
      focus: !1,
      sync: !0,
      drop: !0,
      lang: "en",
      add: "top",
      https: !1,
      scrollTarget: window,
      direction: "ltr",
      spellcheck: !0,
      grammarly: !1,
      notranslate: !1,
      reloadmarker: !0,
      minHeight: "40px",
      maxHeight: !1
    },
    codemirrorSrc: !1,
    codemirror: !1,
    source: !0,
    autosave: {
      url: !1,
      name: !1,
      data: !1,
      method: "post"
    },
    state: {
      limit: 100
    },
    clean: {
      comments: !1,
      enter: !0,
      enterinline: !1
    },
    tab: {
      key: !0,
      spaces: !1
    },
    link: {
      size: 30,
      nofollow: !1,
      target: !1
    },
    topbar: !0,
    context: !1,
    control: !1,
    reorder: !1,
    buttons: {
      addbar: ["text", "image", "embed", "table", "quote", "pre", "line"],
      context: ["bold", "italic", "deleted", "code", "link"],
      topbar: ["shortcut"],
      editor: {
        add: {
          title: "## buttons.add ##",
          command: "addbar.popup"
        },
        html: {
          title: "## buttons.html ##",
          command: "source.toggle"
        },
        format: {
          title: "## buttons.format ##",
          command: "format.popup"
        }
      },
      tags: {
        b: ["bold"],
        strong: ["bold"],
        i: ["italic"],
        em: ["italic"],
        del: ["deleted"],
        a: ["link"]
      },
      types: !1
    },
    addbar: {
      add: {},
      hide: []
    },
    toolbar: {
      hide: [],
      sticky: !0,
      stickyMinHeight: 200,
      stickyTopOffset: 0
    },
    paste: {
      clean: !0,
      autoparse: !0,
      paragraphize: !0,
      plaintext: !1,
      linkTarget: !1,
      images: !0,
      links: !0,
      keepClass: [],
      keepStyle: [],
      keepAttrs: ["td", "th"],
      formTags: ["form", "input", "button", "select", "textarea", "legend", "fieldset"],
      blockTags: ["pre", "h1", "h2", "h3", "h4", "h5", "h6", "table", "tbody", "thead", "tfoot", "th", "tr", "td", "ul", "ol", "li", "blockquote", "p", "hr", "figure", "iframe", "figcaption", "address"],
      inlineTags: ["a", "svg", "img", "br", "strong", "ins", "code", "del", "span", "samp", "kbd", "sup", "sub", "mark", "var", "cite", "small", "b", "u", "em", "i", "abbr"]
    },
    image: {
      states: !0,
      upload: !1,
      url: !0,
      select: !1,
      name: "file",
      data: !1,
      drop: !0,
      multiple: !0,
      clipboard: !0,
      types: ["image/*"],
      tag: "figure",
      newtab: !1,
      link: !0
    },
    noneditable: {
      classname: "noneditable"
    },
    embed: {
      responsive: "embed-responsive"
    },
    pre: {
      template: "<pre></pre>",
      spaces: 4
    },
    line: !0,
    table: {
      template: "<table><tr><td></td><td></td></tr><tr><td></td><td></td></tr></table>",
      nowrap: "nowrap"
    },
    quote: {
      template: "<blockquote><p>Quote...</p><p><cite>Author Attribution</cite></p></blockquote>"
    },
    format: ["p", "h1", "h2", "h3", "ul", "ol"],
    shortcutsBase: {
      "meta+z": "## shortcuts.meta-z ##",
      "meta+shift+z": "## shortcuts.meta-shift-z ##",
      "meta+a": "## shortcuts.meta-a ##"
    },
    shortcuts: {
      "ctrl+shift+o, meta+shift+o": {
        title: "## shortcuts.meta-shift-o ##",
        name: "meta+shift+o",
        command: "addbar.popup"
      },
      "ctrl+shift+d, meta+shift+d": {
        title: "## shortcuts.meta-shift-d ##",
        name: "meta+shift+d",
        command: "block.duplicate"
      },
      "ctrl+shift+up, meta+shift+up": {
        title: "## shortcuts.meta-shift-up ##",
        name: "meta+shift+&uarr;",
        command: "block.moveUp"
      },
      "ctrl+shift+down, meta+shift+down": {
        title: "## shortcuts.meta-shift-down ##",
        name: "meta+shift+&darr;",
        command: "block.moveDown"
      },
      "ctrl+shift+m, meta+shift+m": {
        title: "## shortcuts.meta-shift-m ##",
        name: "meta+shift+m",
        command: "inline.removeFormat"
      },
      "ctrl+b, meta+b": {
        title: "## shortcuts.meta-b ##",
        name: "meta+b",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      "ctrl+i, meta+i": {
        title: "## shortcuts.meta-i ##",
        name: "meta+i",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      "ctrl+u, meta+u": {
        title: "## shortcuts.meta-u ##",
        name: "meta+u",
        command: "inline.set",
        params: {
          tag: "u"
        }
      },
      "ctrl+h, meta+h": {
        title: "## shortcuts.meta-h ##",
        name: "meta+h",
        command: "inline.set",
        params: {
          tag: "sup"
        }
      },
      "ctrl+l, meta+l": {
        title: "## shortcuts.meta-l ##",
        name: "meta+l",
        command: "inline.set",
        params: {
          tag: "sub"
        }
      },
      "ctrl+alt+0, meta+alt+0": {
        title: "## shortcuts.meta-alt-0 ##",
        name: "meta+alt+0",
        command: "block.format",
        params: {
          tag: "p"
        }
      },
      "ctrl+alt+1, meta+alt+1": {
        title: "## shortcuts.meta-alt-1 ##",
        name: "meta+alt+1",
        command: "block.format",
        params: {
          tag: "h1"
        }
      },
      "ctrl+alt+2, meta+alt+2": {
        title: "## shortcuts.meta-alt-2 ##",
        name: "meta+alt+2",
        command: "block.format",
        params: {
          tag: "h2"
        }
      },
      "ctrl+alt+3, meta+alt+3": {
        title: "## shortcuts.meta-alt-3 ##",
        name: "meta+alt+3",
        command: "block.format",
        params: {
          tag: "h3"
        }
      },
      "ctrl+alt+4, meta+alt+4": {
        title: "## shortcuts.meta-alt-4 ##",
        name: "meta+alt+4",
        command: "block.format",
        params: {
          tag: "h4"
        }
      },
      "ctrl+alt+5, meta+alt+5": {
        title: "## shortcuts.meta-alt-5 ##",
        name: "meta+alt+5",
        command: "block.format",
        params: {
          tag: "h5"
        }
      },
      "ctrl+alt+6, meta+alt+6": {
        title: "## shortcuts.meta-alt-6 ##",
        name: "meta+alt+6",
        command: "block.format",
        params: {
          tag: "h6"
        }
      },
      "ctrl+shift+7, meta+shift+7": {
        title: "## shortcuts.meta-shift-7 ##",
        name: "meta+shift+7",
        command: "block.format",
        params: {
          tag: "ol"
        }
      },
      "ctrl+shift+8, meta+shift+8": {
        title: "## shortcuts.meta-shift-8 ##",
        name: "meta+shift+8",
        command: "block.format",
        params: {
          tag: "ul"
        }
      },
      "ctrl+], meta+]": {
        title: "## shortcuts.meta-indent ##",
        name: "meta+]",
        command: "list.indent"
      },
      "ctrl+[, meta+[": {
        title: "## shortcuts.meta-outdent ##",
        name: "meta+[",
        command: "list.outdent"
      },
      "ctrl+k, meta+k": {
        title: "## shortcuts.meta-k ##",
        name: "meta+k",
        command: "link.format"
      }
    },
    buttonsObj: {
      duplicate: {
        title: "## buttons.duplicate ##",
        command: "block.duplicate"
      },
      trash: {
        title: "## buttons.delete ##",
        command: "block.remove"
      },
      undo: {
        title: "## buttons.undo ##",
        command: "state.undo"
      },
      redo: {
        title: "## buttons.redo ##",
        command: "state.redo"
      },
      shortcut: {
        title: "## buttons.shortcuts ##",
        command: "shortcut.popup"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      code: {
        title: "## buttons.code ##",
        command: "inline.set",
        params: {
          tag: "code"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.format"
      },
      mark: {
        title: "## buttons.mark ##",
        command: "inline.set",
        params: {
          tag: "mark"
        }
      },
      sub: {
        title: "## buttons.subscript ##",
        command: "inline.set",
        params: {
          tag: "sub"
        }
      },
      sup: {
        title: "## buttons.superscript ##",
        command: "inline.set",
        params: {
          tag: "sup"
        }
      },
      kbd: {
        title: "## buttons.shortcut ##",
        command: "inline.set",
        params: {
          tag: "kbd"
        }
      },
      text: {
        title: "## blocks.text ##",
        command: "block.add"
      },
      image: {
        title: "## blocks.image ##",
        command: "image.popup",
        observer: "image.observe"
      },
      embed: {
        title: "## blocks.embed ##",
        command: "embed.popup",
        observer: "embed.observe"
      },
      line: {
        title: "## blocks.line ##",
        command: "block.add",
        observer: "block.observe"
      },
      table: {
        title: "## blocks.table ##",
        command: "table.add",
        observer: "table.observe"
      },
      quote: {
        title: "## blocks.quote ##",
        command: "block.add",
        observer: "block.observe"
      },
      pre: {
        title: "## blocks.pre ##",
        command: "block.add",
        observer: "block.observe"
      }
    },
    formatObj: {
      p: {
        title: "## format.p ##",
        type: "paragraph",
        shortcut: "Ctrl+Alt+0"
      },
      h1: {
        title: '<span style="font-size: 20px; font-weight: bold;">## format.h1 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+1"
      },
      h2: {
        title: '<span style="font-size: 16px; font-weight: bold;">## format.h2 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+2"
      },
      h3: {
        title: '<span style="font-weight: bold;">## format.h3 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+3"
      },
      h4: {
        title: '<span style="font-weight: bold;">## format.h4 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+4"
      },
      h5: {
        title: '<span style="font-weight: bold;">## format.h5 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+5"
      },
      h6: {
        title: '<span style="font-weight: bold;">## format.h6 ##</span>',
        type: "heading",
        shortcut: "Ctrl+Alt+6"
      },
      ul: {
        title: "&bull; ## format.ul ##",
        type: "list",
        shortcut: "Ctrl+Shift+7"
      },
      ol: {
        title: "1. ## format.ol ##",
        type: "list",
        shortcut: "Ctrl+Shift+8"
      },
      address: {
        title: "<em>## format.address ##</em>",
        type: "address"
      }
    },
    markerChar: "\ufeff",
    containers: {
      main: ["toolbar", "editor", "source", "statusbar"]
    },
    tags: {
      denied: ["font", "html", "head", "link", "title", "body", "meta", "applet", "marquee"],
      incode: ["!DOCTYPE", "!doctype", "html", "head", "link", "title", "body", "meta", "textarea", "style"],
      form: ["form", "input", "button", "select", "textarea", "legend", "fieldset"],
      inline: ["a", "svg", "span", "strong", "strike", "b", "u", "em", "i", "code", "del", "ins", "samp", "kbd", "sup", "sub", "mark", "var", "cite", "small", "abbr"],
      block: ["pre", "hr", "ul", "ol", "li", "p", "h1", "h2", "h3", "h4", "h5", "h6", "dl", "dt", "dd", "div", "table", "tbody", "thead", "tfoot", "tr", "th", "td", "blockquote", "output", "figcaption", "figure", "address", "main", "section", "header", "footer", "aside", "article", "iframe"],
      parser: ["pre", "hr", "ul", "ol", "p", "h1", "h2", "h3", "h4", "h5", "h6", "table", "address", "blockquote", "figure", "iframe", "form", "dl", "div", "section", "header", "footer", "article", "main", "aside"]
    },
    bsmodal: !1,
    regex: {
      youtube: /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube\.com\S*[^\w-\s])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi,
      vimeo: /(http|https)?:\/\/(?:www.|player.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:\/[a-zA-Z0-9_-]+)?/gi,
      imageurl: /((https?|www)[^\s]+\.)(jpe?g|png|gif)(\?[^\s-]+)?/gi,
      url: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z\u00F0-\u02AF0-9()!@:%_+.~#?&//=]*)/gi,
      aurl1: /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
      aurl2: /(^|[^\/])(www\.[\S]+(\b|$))/gim
    }
  }, RedactorX.lang.en = {
    accessibility: {
      "help-label": "Rich text editor"
    },
    placeholders: {
      figcaption: "Type caption (optional)"
    },
    popup: {
      back: "Back",
      link: "Link",
      add: "Add",
      image: "Image",
      "add-image": "Add Image"
    },
    shortcuts: {
      "meta-a": "Select all",
      "meta-z": "Undo",
      "meta-shift-z": "Redo",
      "meta-shift-m": "Remove inline format",
      "meta-b": "Bold",
      "meta-i": "Italic",
      "meta-u": "Underline",
      "meta-h": "Superscript",
      "meta-l": "Subscript",
      "meta-k": "Link",
      "meta-alt-0": "Normal text",
      "meta-alt-1": "Heading 1",
      "meta-alt-2": "Heading 2",
      "meta-alt-3": "Heading 3",
      "meta-alt-4": "Heading 4",
      "meta-alt-5": "Heading 5",
      "meta-alt-6": "Heading 6",
      "meta-shift-7": "Ordered List",
      "meta-shift-8": "Unordered List",
      "meta-indent": "Indent",
      "meta-outdent": "Outdent",
      "meta-shift-backspace": "Delete",
      "meta-shift-o": "Add",
      "meta-shift-d": "Duplicate",
      "meta-shift-up": "Move up",
      "meta-shift-down": "Move down"
    },
    buttons: {
      add: "Add",
      html: "HTML",
      format: "Format",
      bold: "Bold",
      italic: "Italic",
      deleted: "Deleted",
      link: "Link",
      list: "List",
      image: "Image",
      indent: "Indent",
      outdent: "Outdent",
      embed: "Embed",
      table: "Table",
      insert: "Insert",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      duplicate: "Duplicate",
      shortcut: "Shortcuts",
      underline: "Underline",
      undo: "Undo",
      redo: "Redo",
      code: "Code",
      mark: "Mark",
      subscript: "Subscript",
      superscript: "Superscript",
      kbd: "Shortcut",
      "image-settings": "Image settings",
      "column-settings": "Column settings"
    },
    blocks: {
      text: "Text",
      paragraph: "Paragraph",
      image: "Image",
      embed: "Embed",
      line: "Line",
      table: "Table",
      quote: "Quote",
      pre: "Code",
      address: "Address"
    },
    format: {
      p: "Normal Text",
      h1: "Large Heading",
      h2: "Medium Heading",
      h3: "Small Heading",
      h4: "Heading 4",
      h5: "Heading 5",
      h6: "Heading 6",
      address: "Address",
      ul: "Unordered List",
      ol: "Ordered List"
    },
    embed: {
      embed: "Embed",
      caption: "Caption",
      description: "Paste any embed/html code or enter the url (vimeo or youtube video only)",
      "responsive-video": "Responsive video"
    },
    image: {
      or: "or",
      "alt-text": "Alt Text",
      link: "Link",
      caption: "Caption",
      "link-in-new-tab": "Open link in new tab",
      "url-placeholder": "Paste url of image...",
      "upload-new-placeholder": "Drag to upload a new image<br>or click to select"
    },
    link: {
      link: "Link",
      "edit-link": "Edit Link",
      unlink: "Unlink",
      "link-in-new-tab": "Open link in new tab",
      text: "Text",
      url: "URL"
    },
    table: {
      width: "Width",
      nowrap: "Nowrap",
      column: "Column",
      "add-head": "Add head",
      "remove-head": "Remove head",
      "add-row-below": "Add row below",
      "add-row-above": "Add row above",
      "remove-row": "Remove row",
      "add-column-after": "Add column after",
      "add-column-before": "Add column before",
      "remove-column": "Remove column",
      "delete-table": "Delete table"
    }
  };
  var App = function (t, e, i) {
    for (var s = ["keycodes", "prefix", "dom", "ajax", "_repository", "_subscribe"], n = 0; n < s.length; n++) this[s[n]] = $R[s[n]];
    this.uuid = i, this.$win = this.dom(window), this.$doc = this.dom(document), this.$body = this.dom("body"), this.$element = t, (this.app = this).initialSettings = e, this._initer = ["setting", "lang"], this._priority = ["container", "editor", "state", "accessibility"], this._plugins = [], this.started = !1, this.start();
  };
  App.prototype = {
    start: function (t) {
      this.isTextarea() && (this.isStarted() || (t && (this.initialSettings = t), this._initCore(), this._plugins = this.setting.get("plugins"), this.broadcast("app.before.start"), this._initModules(), this._initPlugins(), this._startPriority(), this._startModules(), this._startPlugins(), this.started = !0, this.broadcast("app.start"), this._loadModulesAndPlugins()));
    },
    isStarted: function () {
      return this.started;
    },
    isTextarea: function () {
      return "TEXTAREA" === this.$element.get().tagName;
    },
    stop: function () {
      this.isStopped() || (this.broadcast("app.before.stop"), this._stopPriority(), this._stopModules(), this._stopPlugins(), this.started = !1, this.broadcast("app.stop"));
    },
    isStopped: function () {
      return !this.started;
    },
    broadcast: function (t, e) {
      var i = e instanceof App.Event ? e : new App.Event(t, e);
      if (void 0 !== this._subscribe[t]) for (var s = this._subscribe[t], n = 0; n < s.length; n++) {
        var a = this[s[n].module];
        a && s[n].func.call(a, i);
      }
      var o = this.setting.has("subscribe") ? this.setting.get("subscribe") : {};
      return "function" == typeof o[t] && o[t].call(this, i), i;
    },
    broadcastParams: function (t, e) {
      return this.broadcast(t, e).getAll();
    },
    broadcastHtml: function (t, e) {
      return this.broadcast(t, {
        html: e
      }).get("html");
    },
    create: function (t) {
      void 0 === this._repository[t] && $R.error('The class "' + t + '" does not exist.');
      var e = [].slice.call(arguments, 1),
        i = new this._repository[t].proto();
      i._name = t, i.app = this;
      for (var s, n = ["uuid", "prefix", "dom", "ajax"], a = 0; a < n.length; a++) i[n[a]] = this[n[a]];
      return this.lang && (i.lang = this.lang), this.opts && (i.opts = this.opts), i.init && (s = i.init.apply(i, e)), s || i;
    },
    api: function (t) {
      for (var e = [].slice.call(arguments, 1), i = t.split("."), s = i.pop(), n = this, a = 0; a < i.length; a++) n = n[i[a]];
      if (n && "function" == typeof n[s]) return n[s].apply(n, e);
    },
    _initCore: function () {
      for (var t = 0; t < this._initer.length; t++) this[this._initer[t]] = this.create(this._initer[t]);
      this.setting && (this.opts = this.setting.dump());
    },
    _initModules: function () {
      for (var t in this._repository) "module" === this._repository[t].type && -1 === this._initer.indexOf(t) && (this[t] = this.create(t));
    },
    _initPlugins: function () {
      var t = this.setting.get("plugins");
      for (var e in this._repository) "plugin" === this._repository[e].type && -1 !== t.indexOf(e) && (this[e] = this.create(e));
    },
    _startPriority: function () {
      for (var t = 0; t < this._priority.length; t++) this._call(this[this._priority[t]], "start");
    },
    _startModules: function () {
      this._iterate("module", "start");
    },
    _startPlugins: function () {
      this._iterate("plugin", "start");
    },
    _stopPriority: function () {
      for (var t = this._priority.slice().reverse(), e = 0; e < t.length; e++) this._call(this[t[e]], "stop");
    },
    _stopModules: function () {
      this._iterate("module", "stop");
    },
    _stopPlugins: function () {
      this._iterate("plugin", "stop");
    },
    _loadModulesAndPlugins: function () {
      this._iterate("module", "load"), this._iterate("plugin", "load");
    },
    _iterate: function (t, e) {
      for (var i in this._repository) {
        var s = "module" === t ? "load" === e || -1 === this._priority.indexOf(i) : -1 !== this._plugins.indexOf(i);
        this._repository[i].type === t && s && this._call(this[i], e);
      }
    },
    _call: function (t, e) {
      "function" == typeof t[e] && t[e].apply(t);
    }
  }, App.Event = function (t, e) {
    this.name = t, this.params = void 0 === e ? {} : e, this.stopped = !1;
  }, App.Event.prototype = {
    is: function (t) {
      if (!Array.isArray(t)) return this.get(t);
      for (var e = 0; e < t.length; e++) if (this.params[t[e]]) return !0;
    },
    has: function (t) {
      return void 0 !== this.params[t];
    },
    getAll: function () {
      return this.params;
    },
    get: function (t) {
      return this.params[t];
    },
    set: function (t, e) {
      this.params[t] = e;
    },
    stop: function () {
      this.stopped = !0;
    },
    isStopped: function () {
      return this.stopped;
    }
  }, RedactorX.add("mixin", "block", {
    defaults: {
      id: {
        getter: "getId",
        setter: "setId"
      },
      html: {
        getter: "getHtml",
        setter: "setHtml"
      }
    },
    init: function (t, e) {
      this.$block = t ? this.dom(t) : this.create(e), this._build(e), this._buildData(), this._render();
    },
    isBlock: function () {
      return !0;
    },
    isEditable: function () {
      return void 0 !== this.editable && !0 === this.editable;
    },
    isFigcaption: function () {
      return "figcaption" === this.type;
    },
    isSecondLevel: function () {
      return -1 !== ["quoteitem", "row", "cell"].indexOf(this.type);
    },
    isNested: function () {
      return -1 !== ["quote", "table"].indexOf(this.type);
    },
    isFirstLevel: function () {
      return this.$block.attr("data-" + this.prefix + "-first-level");
    },
    isAllSelected: function () {
      return !this.isEditable() || this.app.selection.isAll(this.$block);
    },
    isEmpty: function () {
      var t = this.$block.text();
      return "" === (t = this._cleanEmpty(t));
    },
    isCaretStart: function () {
      return "pre" === this.getType() ? this.app.caret.is(this.$block, "start", !1, !1) : !this.isEditable() || this.app.caret.is(this.$block, "start");
    },
    isCaretEnd: function () {
      return "pre" === this.getType() ? this.app.caret.is(this.$block, "end", !1, !1) : "address" === this.getType() ? this.app.caret.is(this.$block, "end", !1, !0, !1) : !this.isEditable() || this.app.caret.is(this.$block, "end");
    },
    isAllowedButton: function (t, e) {
      var i = this.getType();
      if (void 0 === e.blocks) return !0;
      var s = e.blocks;
      if (s.except && -1 !== s.except.indexOf(i)) return !1;
      if (s.all) {
        if (!0 === s.all || "all" === s.all) return !0;
        if ("editable" === s.all && -1 !== ["heading", "paragraph", "list", "address", "quote", "table"].indexOf(i)) return !0;
        if ("first-level" === s.all && this.isFirstLevel()) return !0;
        if ("noneditable" === s.all && -1 !== ["image", "embed", "layer", "line"].indexOf(i)) return !0;
      }
      return !(!Array.isArray(s.types) || -1 === s.types.indexOf(i));
    },
    getData: function (t) {
      var e = {};
      for (var i in this.data) e[i] = this[this.data[i].getter].apply(this);
      return t ? e[t] : e;
    },
    getOffset: function () {
      return this.$block.offset();
    },
    getType: function () {
      return this.type;
    },
    getTag: function () {
      return !!this.$block && this.$block.get().tagName.toLowerCase();
    },
    getBlock: function () {
      return this.$block;
    },
    getHtml: function () {
      return this.$block.html();
    },
    getPlainText: function () {
      var t = this.$block.html();
      return this.app.content.getTextFromHtml(t, {
        nl: !0
      });
    },
    getOuterHtml: function () {
      return this.$block.get().outerHTML;
    },
    getFirstLevel: function () {
      var t = this.$block.closest("[data-" + this.prefix + "-first-level]");
      return 0 !== t.length && t.dataget("instance");
    },
    getParent: function (t) {
      t = t ? "=" + t : "";
      var e = this.$block.parent().closest("[data-" + this.prefix + "-type" + t + "]");
      return 0 !== e.length && e.dataget("instance");
    },
    getNext: function (t) {
      t = t ? "=" + t : "";
      var e = this.$block.nextElement();
      return !(0 === e.length || !e.is("[data-" + this.prefix + "-type" + t + "]")) && e.dataget("instance");
    },
    getPrev: function (t) {
      t = t ? "=" + t : "";
      var e = this.$block.prevElement();
      return !(0 === e.length || !e.is("[data-" + this.prefix + "-type" + t + "]")) && e.dataget("instance");
    },
    getChildFirst: function (t) {
      t = t ? "=" + t : "";
      var e = this.$block.find("[data-" + this.prefix + "-type" + t + "]").first();
      return 0 !== e.length && e.dataget("instance");
    },
    getChildLast: function (t) {
      t = t ? "=" + t : "";
      var e = this.$block.find("[data-" + this.prefix + "-type" + t + "]").last();
      return 0 !== e.length && e.dataget("instance");
    },
    getId: function () {
      return this.$block.attr("id");
    },
    getCaption: function () {
      var t = this.$block.find("figcaption");
      return 0 !== t.length ? t.html().trim() : "";
    },
    setData: function (t) {
      for (var e in t) this.data[e] && this[this.data[e].setter].call(this, t[e]);
    },
    setEmpty: function () {
      this.$block.html("");
    },
    setSelectAll: function () {
      this.isEditable() && this.app.selection.select(this.$block);
    },
    setHtml: function (t) {
      this.$block.html(t);
    },
    setId: function (t) {
      "" === t ? this.$block.removeAttr("id") : this.$block.attr("id", t);
    },
    setCaption: function (t) {
      if ("" === t) this.$block.find("figcaption").remove();else {
        var e = this.$block.find("figcaption");
        0 === e.length && ((e = this.dom("<figcaption>")).attr("data-placeholder", this.lang.get("placeholders.figcaption")), this.$block.append(e), this.app.create("block.figcaption", e)), e.html(t);
      }
    },
    setClassFromObj: function (t, e) {
      this._removeObjClasses(t);
      var i = t[e];
      "none" === i && !1 === i || this.$block.addClass(i);
    },
    setCaret: function (t) {
      this.app.caret.set(this.$block, t);
    },
    moveUp: function () {
      var t = this.getPrev();
      t && this._move(t, "before");
    },
    moveDown: function () {
      var t = this.getNext();
      t && this._move(t, "after");
    },
    remove: function (t) {
      var e = this.getType();
      this.$block.remove(), t && this.app.broadcast("block.remove", {
        type: e
      });
    },
    appendNext: function () {
      var t = this.getNext();
      if (t.isEmpty()) t.remove();else {
        if (this.isEmpty()) return this.remove(), void this.app.block.set(t, "start");
        var e = t.getHtml(),
          i = this.getType(),
          s = t.getType(),
          n = !0,
          a = !0;
        if ("pre" === i && "pre" !== s && (e = t.getPlainText()), "list" === s) if ("list" === i) {
          var o = t.getBlock().children();
          this.$block.append(o), a = !(n = !1);
        } else e = this._appendListHtml(t.getBlock(), e), a = t.isEmpty();
        if (n) {
          var r = this.app.selection.getTopInline();
          r && this.app.caret.set(r, "after"), this.app.insertion.insertHtml(e, "start");
        }
        a && t.remove();
      }
    },
    appendToPrev: function () {
      var t = this.getPrev();
      if (this.isEmpty()) return this.remove(), void this.app.block.set(t, "end");
      if (t.isEmpty()) t.remove();else {
        var e = t.getType(),
          i = this.getHtml(),
          s = this.getType(),
          n = !0,
          a = !0;
        if ("pre" !== s && "pre" === e && (i = this.getPlainText()), "list" === s) if ("list" === e) {
          var o = this.getBlock().children();
          this.app.block.set(t, "end"), t.getBlock().append(o), a = !(n = !1);
        } else i = this._appendListHtml(this.getBlock(), i), a = this.isEmpty();
        if (n) {
          this.app.block.set(t, "end");
          var r = this.app.selection.getTopInline();
          r && this.app.caret.set(r, "after"), this.app.insertion.insertHtml(i, "start");
        }
        a && this.remove();
      }
    },
    addEmpty: function (t) {
      return (t = t || {}).instance = this.app.block.create(), this.add(t);
    },
    add: function (t) {
      var e = $R.extend({}, {
          instance: !1,
          position: "after",
          caret: !1,
          remove: !0
        }, t),
        i = e.instance.getBlock();
      return "list" === e.instance.getType() && "list" === this.getType() ? this.app.insertion.insertListToList(i, this.$block, e.caret) : (this.$block[e.position](i), e.remove && this.isEditable() && this.isEmpty() && this.remove()), this.app.editor.build(), e.caret && this.app.block.set(e.instance, e.caret), this.app.toolbar.observe(), this.app.context.observe(), this.app.broadcast("block.add", {
        instance: e.instance
      }), e.instance;
    },
    change: function (t, e) {
      var i = t.getBlock();
      this.$block.after(i), this.$block.remove(), this.app.editor.build(), this.app.block.set(t), !1 !== e && this.app.broadcast("block.change", {
        instance: t
      });
    },
    duplicate: function () {
      var t = this.getType(),
        e = this.$block.clone();
      return e.removeClass(this.prefix + "-block-focus"), this.app.create("block." + t, e);
    },
    _build: function (t) {
      this.build && this.build(t);
    },
    _buildData: function () {
      this.data || (this.data = {}), this.data = $R.extend({}, !0, this.defaults, this.data);
    },
    _buildItems: function (t, e) {
      var i = this.$block.find(t);
      0 !== i.length && i.each(function (t) {
        this.app.create("block." + e, t);
      }.bind(this));
    },
    _buildCaption: function () {
      "figure" === this.getTag() && this.$block.find("figcaption").attr("data-placeholder", this.lang.get("placeholders.figcaption"));
    },
    _render: function () {
      this._renderEdit();
    },
    _renderEdit: function () {
      this.$block.dataset("instance", this), this.$block.attr("data-" + this.prefix + "-type", this.getType()), void 0 !== this.editable && !1 === this.editable ? this.$block.attr("contenteditable", !1) : "figcaption" === this.type && this.$block.attr("contenteditable", !0);
    },
    _cleanEmpty: function (t) {
      return t = (t = -1 !== (t = this.app.utils.removeInvisibleChars(t)).search(/^<br\s?\/?>$/) ? "" : t).replace(/\n/g, "");
    },
    _appendListHtml: function (t, e) {
      var i = t.find("li").first();
      return e = (e = (e = i.html().trim()).replace(/<\/li>/gi, "</li><br>")).replace(/<(ul|ol)/gi, "<br><$1"), e = (e = (e = this.app.content.removeTags(e, ["ul", "ol", "li"])).trim()).replace(/<br\s?\/?>$/gi, ""), i.remove(), e;
    },
    _move: function (t, e) {
      var i = this;
      i.isNested() && (i = i.getFirst()), (this.isEditable() || this.isNested()) && this.app.selection.save(i.getBlock()), t.getBlock()[e](this.$block), this.app.block.set(i, !1, !0), (this.isEditable() || this.isNested()) && this.app.selection.restore(!1);
    },
    _removeObjClasses: function (t) {
      var e = this._buildObjClasses(t);
      this.$block.removeClass(e.join(" ")), this.app.element.removeEmptyAttrs(this.$block, ["class"]);
    },
    _buildObjClasses: function (t) {
      var e = [];
      for (var i in t) t[i] && e.push(t[i]);
      return e;
    }
  }), RedactorX.add("mixin", "tool", {
    init: function (t, e, i, s) {
      this.name = t, this.setter = i.get("setter"), this.popup = i, this.data = s, this.obj = this._observe(e), this.obj && this._build();
    },
    getElement: function () {
      return this.$tool;
    },
    getInput: function () {
      return this.$input;
    },
    getValue: function () {
      return this.$input.val().trim();
    },
    setValue: function (t) {
      this.$input.val(t);
    },
    setFocus: function () {
      this.$input.focus();
    },
    trigger: function (t) {
      this.setValue(t), this.setter && this.app.api(this.setter, this.popup);
    },
    _build: function () {
      this._buildTool(), this._buildLabel(), this._buildInputElement(), this._buildInput(), this._buildEvent(), this._has("placeholder") && this.$input.attr("placeholder", this.lang.parse(this.obj.placeholder)), this._has("width") && this.$input.css("width", this.obj.width), this._has("classname") && this.$input.addClass(this.obj.classname);
    },
    _buildInputElement: function () {
      this.$input = this.dom("<" + this._getInputParam("tag") + ">").addClass(this.prefix + this._getInputParam("classname")), this.$input.attr({
        name: this.name,
        type: this._getInputParam("type"),
        "data-type": this.type
      }), this.$input.dataset("instance", this);
    },
    _buildInput: function () {},
    _buildEvent: function () {
      if (-1 === ["segment"].indexOf(this.type) && this.setter) {
        var t = "checkbox" === this.type || "select" === this.type ? "change" : "keydown blur";
        this.$input.on(t, this._catchSetter.bind(this));
      }
    },
    _buildTool: function () {
      this.$tool = this.dom("<div>").addClass(this.prefix + "-form-item").dataset("instance", this);
    },
    _buildLabel: function () {
      "checkbox" !== this.type && this._has("label") && (this.$label = this.dom("<label>").addClass(this.prefix + "-form-label").html(this.lang.parse(this.obj.label)), this.$tool.append(this.$label));
    },
    _getInputParam: function (t) {
      return this.input && void 0 !== this.input[t] ? this.input[t] : "";
    },
    _get: function (t) {
      return this.obj[t];
    },
    _has: function (t) {
      return Object.prototype.hasOwnProperty.call(this.obj, t);
    },
    _observe: function (t) {
      return Object.prototype.hasOwnProperty.call(t, "observer") && (t = this.app.api(t.observer, t, this.name)), t;
    },
    _catchSetter: function (t) {
      "keydown" === t.type && 13 !== t.which || ("keydown" === t.type && t.preventDefault(), this.app.api(this.setter, this.popup));
    }
  }), RedactorX.add("module", "accessibility", {
    start: function () {
      this._buildRole(), this._buildLabel();
    },
    _buildRole: function () {
      this.app.editor.getEditor().attr({
        "aria-labelledby": this.prefix + "-voice",
        role: "presentation"
      });
    },
    _buildLabel: function () {
      var t = this.lang.get("accessibility.help-label"),
        e = this._createLabel(t);
      this.app.container.get("main").prepend(e);
    },
    _createLabel: function (t) {
      var e = this.dom("<span />").addClass(this.prefix + "-voice-label");
      return e.attr({
        id: this.prefix + "-voice-" + this.uuid,
        "aria-hidden": !1
      }), e.html(t), e;
    }
  }), RedactorX.add("module", "lang", {
    init: function () {
      this.langKey = this.app.setting.get("editor.lang"), this.vars = this._build();
    },
    get: function (t) {
      var e = this._get(t, this.vars);
      return void 0 === e && "en" !== this.langKey && (e = this._get(t, $R.lang.en)), void 0 === e ? "" : e;
    },
    parse: function (t) {
      if ("string" != typeof t) return t;
      var e = t.match(/## (.*?) ##/g);
      if (e) for (var i = 0; i < e.length; i++) {
        var s = e[i].replace(/^##\s/g, "").replace(/\s##$/g, "");
        t = t.replace(e[i], this.get(s));
      }
      return t;
    },
    _get: function (t, e) {
      var i = t.split(".");
      return 1 === i.length ? e[t] : void 0 !== e[i[0]] ? e[i[0]][i[1]] : void 0;
    },
    _build: function () {
      var t = $R.lang.en;
      return "en" !== this.langKey && (t = "undefined" !== $R.lang[this.langKey] ? $R.lang[this.langKey] : t), t;
    }
  }), RedactorX.add("module", "setting", {
    init: function () {
      this.opts = this._build();
    },
    dump: function () {
      return this.opts;
    },
    has: function (t) {
      var e = t.split(".");
      return 1 === e.length ? void 0 !== this.opts[t] : void 0 !== this.opts[e[0]] && void 0 !== this.opts[e[1]];
    },
    set: function (t, e, i) {
      void 0 === this.opts[t] && (this.opts[t] = {}), void 0 === i ? this.opts[t] = e : this.opts[t][e] = i;
    },
    get: function (t) {
      var e = t.split(".");
      return 1 === e.length ? this.opts[t] : void 0 !== this.opts[e[0]] ? this.opts[e[0]][e[1]] : void 0;
    },
    _build: function () {
      var t = $R.extend(!0, {}, $R.opts, this.app.initialSettings);
      return t = $R.extend(!0, t, $R.settings);
    }
  }), RedactorX.add("module", "element", {
    is: function (t, e, i) {
      var s = !1,
        n = "text" === e ? t : this._getNode(t);
      return "inline" === e ? s = this._isElement(n) && this._isInlineTag(n.tagName, i) : "blocks" === e ? s = this._isElement(n) && n.hasAttribute("data-" + this.prefix + "-type") : "blocks-first" === e ? s = this._isElement(n) && n.hasAttribute("data-" + this.prefix + "-first-level") : "block" === e ? s = this._isElement(n) && this._isBlockTag(n.tagName, i) : "element" === e ? s = this._isElement(n) : "text" === e ? s = "string" == typeof n && !/^\s*<(\w+|!)[^>]*>/.test(n) || this.isTextNode(n) : "list" === e ? s = this._isElement(n) && -1 !== ["ul", "ol"].indexOf(n.tagName.toLowerCase()) : "heading" === e && (s = this._isElement(n) && -1 !== ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(n.tagName.toLowerCase())), s;
    },
    isEmptyOrImageInline: function (t) {
      var e = this.dom(t).get();
      if (!e || 3 === e.nodeType) return !1;
      var i = e.tagName.toLowerCase(),
        s = "false" === e.getAttribute("contenteditable"),
        n = this.is(e, "inline");
      return !!(n && this.isEmpty(e) || n && s || -1 !== ["svg", "img"].indexOf(i));
    },
    isEmpty: function (t) {
      var e = this._getNode(t);
      return !!e && (3 === e.nodeType ? "" === e.textContent.trim().replace(/\n/, "") : "" === e.innerHTML);
    },
    isTag: function (t, e) {
      return this._getNode(t).tagName.toLowerCase() === e;
    },
    isTextNode: function (t) {
      var e = this._getNode(t);
      return e && e.nodeType && 3 === e.nodeType;
    },
    isVisible: function (t) {
      var e = this._getNode(t);
      return !!(e.offsetWidth || e.offsetHeight || e.getClientRects().length);
    },
    isScrollVisible: function (t) {
      var e = this.app.scroll.getTarget(),
        i = this.dom(t),
        s = e.scrollTop() + e.height();
      return i.offset().top <= s;
    },
    getFirstLevel: function (t) {
      return this.dom(t).closest("[data-" + this.prefix + "-first-level]");
    },
    getDataBlock: function (t) {
      return this.dom(t).closest("[data-" + this.prefix + "-type]");
    },
    getType: function (t) {
      return this.dom(t).attr("data-" + this.prefix + "-type");
    },
    getAllInlines: function (t) {
      for (var e = [], i = t; i;) this.is(i, "inline") && e.push(i), i = i.parentNode;
      return e;
    },
    getClosest: function (t, e) {
      return this.dom(t).closest(this.getTypesSelector(e));
    },
    getParents: function (t, e) {
      return this.dom(t).parents(this.getTypesSelector(e));
    },
    getChildren: function (t, e) {
      return this.dom(t).find(this.getTypesSelector(e));
    },
    getTypesSelector: function (t) {
      return "[data-" + this.prefix + "-type=" + t.join("],[data-" + this.prefix + "-type=") + "]";
    },
    scrollTo: function (t, e) {
      if (!this.isScrollVisible(t)) {
        e = e || 60;
        var i = t.offset(),
          s = this.app.scroll.getTarget(),
          n = i.top - e;
        s.scrollTop(n), setTimeout(function () {
          s.scrollTop(n);
        }, 1);
      }
    },
    replaceToTag: function (t, n, a) {
      return this.dom(t).replaceWith(function (t) {
        var e = this.dom("<" + n + ">");
        if (a || e.append(t.innerHTML), t.attributes) for (var i = t.attributes, s = 0; s < i.length; s++) e.attr(i[s].nodeName, i[s].value);
        if (a) for (; 0 < t.childNodes.length;) e.append(this.dom(t.firstChild));
        return e;
      }.bind(this));
    },
    split: function (t) {
      var e = this.dom(t),
        i = (t = e.get()).tagName.toLowerCase(),
        s = this.app.content.extractHtmlFromCaret(t);
      s.nodeType && 11 === s.nodeType && (s = this.dom(s.childNodes));
      var n = this.dom("<" + i + " />");
      (n = this.cloneAttrs(t, n)).append(s), e.after(n);
      var a = e.children().last();
      if (this.is(a, "inline")) {
        var o = a.html();
        "" === (o = this.app.utils.removeInvisibleChars(o)) && a.remove();
      }
      var r = this.getType(n);
      return r && this.app.create("block." + r, n, !0), "" === e.html() && e.remove(), n;
    },
    cloneEmpty: function (t) {
      var e = this.dom(t).get().tagName.toLowerCase();
      return this.dom("<" + e + ">");
    },
    cloneAttrs: function (t, e) {
      for (var i = this.dom(e), s = this._getNode(t).attributes, n = s.length; n--;) {
        var a = s[n];
        i.attr(a.name, a.value);
      }
      return i;
    },
    getAttrs: function (t) {
      var e = this._getNode(t),
        i = {};
      if (null != e.attributes && e.attributes.length) for (var s = 0; s < e.attributes.length; s++) {
        var n = e.attributes[s].nodeValue;
        n = this._isNumber(n) ? parseFloat(n) : this._getBooleanFromStr(n), i[e.attributes[s].nodeName] = n;
      }
      return i;
    },
    removeEmptyAttrs: function (t, e) {
      var i = this.dom(t),
        s = e.join(" "),
        n = !1;
      return void 0 === i.attr(s) || null === i.attr(s) ? n = !0 : "" === i.attr(s) && (i.removeAttr(s), n = !0), n;
    },
    getBlocks: function (t, e, i) {
      var s = this._getNode(t).childNodes,
        n = [],
        a = e || this.opts.tags.parser;
      i && (a = this.app.utils.extendArray(a, i));
      for (var o = 0; o < s.length; o++) 1 === s[o].nodeType && -1 !== a.indexOf(s[o].tagName.toLowerCase()) && n.push(s[o]);
      return n;
    },
    hasBlocks: function (t) {
      return 0 !== this.getBlocks(t).length;
    },
    hasTextSiblings: function (t) {
      var e = this._getNode(t),
        i = e.previousSibling && 3 === e.previousSibling.nodeType && !this.isEmpty(e.previousSibling),
        s = e.nextSibling && 3 === e.nextSibling.nodeType && !this.isEmpty(e.nextSibling);
      return i || s;
    },
    _getNode: function (t) {
      return this.dom(t).get();
    },
    _getBooleanFromStr: function (t) {
      return "true" === t || "false" !== t && t;
    },
    _isBlockTag: function (t, e) {
      return -1 !== this.app.utils.extendArray(this.opts.tags.block, e).indexOf(t.toLowerCase());
    },
    _isInlineTag: function (t, e) {
      return -1 !== this.app.utils.extendArray(this.opts.tags.inline, e).indexOf(t.toLowerCase());
    },
    _isElement: function (t) {
      return t && t.nodeType && 1 === t.nodeType;
    },
    _isTag: function (t) {
      return void 0 !== t && t;
    },
    _isNumber: function (t) {
      return !isNaN(t) && !isNaN(parseFloat(t));
    }
  }), RedactorX.add("module", "paragraphizer", {
    init: function () {
      this.remStart = "#####replace", this.remEnd = "#####";
      this.tags = this.opts.tags.parser.concat(["form", "audio", "figcaption", "object", "style", "script", "iframe", "select", "input", "textarea", "button", "option", "map", "area", "math", "fieldset", "legend", "hgroup", "nav", "details", "menu", "summary"]);
    },
    paragraphize: function (t) {
      var e = [],
        i = [];
      t = this._storeTags(t, e), t = (t = this.app.content.storeComments(t, i)).trim();
      for (var s = "", n = (t = (t = (t = (t = (t = (t = this._trimLinks(t)).replace(/xparagraphmarkerz(?:\r\n|\r|\n)$/g, "")).replace(/xparagraphmarkerz$/g, "")).replace(/xparagraphmarkerz(?:\r\n|\r|\n)/g, "\n")).replace(/xparagraphmarkerz/g, "\n")).replace(/[\n]+/g, "\n")).split("\n"), a = 0; a < n.length; a++) s += "<p>" + n[a].trim() + "</p>\n";
      return t = (t = (t = (t = s.replace(/\n$/, "")).replace(new RegExp("<p>\\s+#####", "gi"), "#####")).replace(new RegExp("<p>#####", "gi"), "#####")).replace(new RegExp("#####</p>", "gi"), "#####"), t = this._restoreTags(t, e), t = (t = (t = (t = (t = this.app.content.restoreComments(t, i)).replace(/<p(.*?)><\/?br\s?\/?><\/p>/gi, "<p$1></p>")).replace(/<div(.*?)><\/?br\s?\/?><\/div>/gi, "<div$1></div>")).replace(/<\/?br\s?\/?><\/div>/gi, "</div>")).replace(/<\/?br\s?\/?><\/li>/gi, "</li>");
    },
    _storeTags: function (t, i) {
      return this.app.utils.wrap(t, function (t) {
        t.find(this.tags.join(", ")).each(function (t, e) {
          this._replaceTag(t, e, i);
        }.bind(this));
      }.bind(this));
    },
    _restoreTags: function (t, e) {
      for (var i = 0; i < e.length; i++) {
        var s = e[i].replace(/\$/gi, "&#36;");
        t = t.replace(this.remStart + i + this.remEnd, s);
      }
      return t;
    },
    _replaceTag: function (t, e, i) {
      var s = t.get(),
        n = document.createTextNode(this.remStart + e + this.remEnd + "xparagraphmarkerz");
      i.push(s.outerHTML), s.parentNode.replaceChild(n, s);
    },
    _trimLinks: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("a").each(this._trimLink.bind(this));
      }.bind(this));
    },
    _trimLink: function (t) {
      t.html(t.html().trim());
    }
  }), RedactorX.add("module", "utils", {
    isMobile: function () {
      return /(iPhone|iPad|iPod|Android)/.test(navigator.userAgent);
    },
    createInvisibleChar: function () {
      return document.createTextNode(this.opts.markerChar);
    },
    searchInvisibleChars: function (t) {
      return t.search(/^\uFEFF$/g);
    },
    removeInvisibleChars: function (t) {
      return t.replace(/\uFEFF/g, "");
    },
    wrap: function (t, e) {
      var i = this.dom("<div>").html(t);
      return e(i), t = i.html(), i.remove(), t;
    },
    extendArray: function (t, e) {
      if (t = t.concat(t), e) for (var i = 0; i < e.length; i++) t.push(e[i]);
      return t;
    },
    removeFromArrayByValue: function (t, e) {
      var i;
      e = Array.isArray(e) ? e : [e];
      for (var s = 0; s < e.length; s++) -1 < (i = t.indexOf(e[s])) && t.splice(i, 1);
      return t;
    },
    sumOfArray: function (t) {
      return t.reduce(function (t, e) {
        return parseInt(t) + parseInt(e);
      }, 0);
    },
    getObjectIndex: function (t, e) {
      return Object.keys(t).indexOf(e);
    },
    insertToObject: function (s, n, a, o) {
      return Object.keys(a).reduce(function (t, e, i) {
        return i === o && (t[s] = n), t[e] = a[e], t;
      }, {});
    },
    getRandomId: function () {
      for (var t = "", e = "abcdefghijklmnopqrstuvwxyz0123456789", i = 0; i < 12; i++) t += e.charAt(Math.floor(Math.random() * e.length));
      return t;
    },
    escapeRegExp: function (t) {
      return t.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
    },
    capitalize: function (t) {
      return (t = t.toLowerCase()).charAt(0).toUpperCase() + t.slice(1);
    },
    extendData: function (t, e) {
      for (var i in e) t = "elements" === i ? this._extendDataElements(t, e[i]) : this._setData(t, i, e[i]);
      return t;
    },
    _extendDataElements: function (n, t) {
      return this.dom(t).each(function (t) {
        if ("FORM" === t.get().tagName) {
          var e = t.serialize(!0);
          for (var i in e) n = this._setData(n, i, e[i]);
        } else {
          var s = t.attr("name") ? t.attr("name") : t.attr("id");
          n = this._setData(n, s, t.val());
        }
      }.bind(this)), n;
    },
    _setData: function (t, e, i) {
      return t instanceof FormData ? t.append(e, i) : t[e] = i, t;
    }
  }), RedactorX.add("module", "scroll", {
    init: function () {
      this.scrolltop = !1;
    },
    save: function () {
      this.scrolltop = this.getTarget().scrollTop();
    },
    restore: function () {
      !1 !== this.scrolltop && (this.getTarget().scrollTop(this.scrolltop), this.scrolltop = !1);
    },
    isTarget: function () {
      return this.opts.editor.scrollTarget !== window;
    },
    getTarget: function () {
      return this.dom(this.opts.editor.scrollTarget);
    }
  }), RedactorX.add("module", "autosave", {
    send: function () {
      this.opts.autosave.url && this._sending();
    },
    _getName: function () {
      return this.opts.autosave.name ? this.opts.autosave.name : this.app.$element.attr("name") || "content" + this.uuid;
    },
    _sending: function () {
      var e = this._getName(),
        i = {};
      i[e] = this.app.$element.val(), i = this.app.utils.extendData(i, this.opts.autosave.data), this.ajax.request(this.opts.autosave.method, {
        url: this.opts.autosave.url,
        data: i,
        before: function (t) {
          if (this.app.broadcast("autosave.before.send", {
            xhr: t,
            name: e,
            data: i
          }).isStopped()) return !1;
        }.bind(this),
        success: function (t) {
          this._complete(t, e, i);
        }.bind(this)
      });
    },
    _complete: function (t, e, i) {
      var s = t && t.error ? "autosave.error" : "autosave.send";
      this.app.broadcast(s, {
        name: e,
        data: i,
        response: t
      });
    }
  }), RedactorX.add("module", "progress", {
    stop: function () {
      this.hide();
    },
    show: function () {
      this.hide(), this.$progress = this.dom("<div>").addClass(this.prefix + "-editor-progress"), this.$progress.attr("id", this.prefix + "-progress"), this.$progressBar = this.dom("<span>"), this.$progress.append(this.$progressBar), this.app.$body.append(this.$progress);
    },
    hide: function () {
      this.app.$body.find("#" + this.prefix + "-progress").remove();
    }
  }), RedactorX.add("module", "clipboard", {
    getContent: function (t) {
      var e = this.isPlainText(t) ? "text/plain" : "text/html",
        i = t.getData(e);
      return "text/plain" == e ? this.app.content.escapeHtml(i) : i;
    },
    setContent: function (t, e, i) {
      var s = t.clipboardData;
      e = this.app.parser.unparse(e), e = '<meta type="' + this.prefix + '-editor"/>' + e, i = i || this.app.content.getTextFromHtml(e, {
        nl: !0
      }), s.setData("text/html", e), s.setData("text/plain", i);
    },
    isPlainText: function (t) {
      var e = t.getData("text/plain"),
        i = t.getData("text/html");
      return (!i || "" === i.trim()) && null !== e;
    }
  }), RedactorX.add("module", "fragment", {
    build: function (t) {
      return this.is(t) ? t : this.create(t);
    },
    insert: function (t) {
      var e = this.app.selection.get();
      if (e.range) {
        if (e.collapsed) {
          var i = e.range.startContainer;
          3 !== i.nodeType && "BR" === i.tagName && i.parentNode.removeChild(i);
        } else e.range.deleteContents();
        t.frag ? e.range.insertNode(t.frag) : e.range.insertNode(t);
      }
    },
    createContainer: function (t) {
      var e = this.dom("<div>");
      return "string" == typeof t ? e.html(t) : e.append(this.dom(t).clone(!0)), e.get();
    },
    create: function (t) {
      for (var e, i, s, n = "string" == typeof t ? this.createContainer(t) : t, a = document.createDocumentFragment(), o = [], r = 0; e = n.firstChild;) {
        r++;
        var p = a.appendChild(e);
        1 === r && (i = p), o.push(p), s = p;
      }
      return {
        frag: a,
        first: i,
        last: s,
        nodes: o
      };
    },
    is: function (t) {
      return "object" == typeof t && t.frag;
    }
  }), RedactorX.add("module", "button", {
    init: function (t, e, i, s) {
      if ("object" == typeof t) this.name = t.name, this.obj = e, this._buildFromElement(t.element);else if (t) {
        this.type = s || !1, this.name = t;
        var n = this._observe(e);
        this.obj = void 0 === n ? e : n, this.obj && this._build(t, i);
      }
    },
    setColor: function (t, e) {
      var i = t.getName();
      if ("background" === i || "text-color" === i) {
        var s = "background" === i ? "background-color" : "color";
        this.setBackground(e[s]);
      }
    },
    isButton: function () {
      return !0;
    },
    isControl: function () {
      return this._has("control");
    },
    getName: function () {
      return this.name;
    },
    getTitle: function () {
      return this.title;
    },
    getParams: function () {
      return !!this._has("params") && this.obj.params;
    },
    getOffset: function () {
      return this.$button.offset();
    },
    getDimension: function () {
      return {
        width: this.$button.width(),
        height: this.$button.height()
      };
    },
    getElement: function () {
      return this.$button;
    },
    setBackground: function (t) {
      this._background("add", t);
    },
    resetBackground: function () {
      this._background("remove", "");
    },
    _has: function (t) {
      return Object.prototype.hasOwnProperty.call(this.obj, t);
    },
    _observe: function (t) {
      return Object.prototype.hasOwnProperty.call(t, "observer") && (t = this.app.api(t.observer, t, this.name)), t;
    },
    _background: function (t, e) {
      var i = "remove" === t ? "removeClass" : "addClass";
      this.$icon[i](this.prefix + "-button-icon-color").css({
        "background-color": e,
        color: "" !== e ? this.app.color.invert(e) : ""
      });
    },
    _buildFromElement: function (t) {
      this.$button = this.dom(t), this.$button.addClass(this.prefix + "-button-target"), this._buildData();
    },
    _build: function (t, e) {
      this._buildTitle(), this._buildElement(), this._buildIcon(), this._buildData(e);
    },
    _buildData: function (t) {
      this.$button.attr({
        tabindex: "-1",
        "data-name": this.name,
        "data-command": this.obj.command || !1
      }), this.$button.dataset("instance", this);
      var e = this._has("command") ? "_catch" : "_stop";
      this.$button.on("click." + this.prefix + "-button", this[e].bind(this)), this.$button.on("dragstart." + this.prefix + "-button", function (t) {
        t.preventDefault();
      }), t && (this._buildTooltip(), this._buildBackground(), this._buildPosition(t));
    },
    _buildTitle: function () {
      this.title = void 0 !== this.obj.title ? this.lang.parse(this.obj.title) : "";
    },
    _buildElement: function () {
      this.$button = this.dom('<a href="#"></a>'), this.$button.addClass(this.prefix + "-button " + this.prefix + "-button-target"), this.type && this.$button.addClass(this.prefix + "-button-" + this.type), this._has("classname") && this.$button.addClass(this.obj.classname);
    },
    _buildIcon: function () {
      var t = this._has("icon"),
        e = '<span class="' + this.prefix + "-icon-" + this.name + '"></span>';
      this.$icon = this._buildIconElement(), t && !0 !== this.obj.icon && (e = -1 !== this.obj.icon.search(/</) ? this.obj.icon : '<span class="' + this.prefix + "-icon-" + this.obj.icon + '"></span>'), this.$icon.append(e), this.$button.append(this.$icon);
    },
    _buildIconElement: function () {
      return this.dom("<span>").addClass(this.prefix + "-button-icon");
    },
    _buildTooltip: function () {
      "toolbar" === this.type && this.app.tooltip.build(this.$button, this.title);
    },
    _buildBackground: function () {
      this._has("background") && this.setBackground(this.obj.background);
    },
    _buildPosition: function (t) {
      if (this._has("position")) {
        var e = this.obj.position;
        if ("first" === e) t.prepend(this.$button);else if ("object" == typeof e) {
          var i = Object.prototype.hasOwnProperty.call(e, "after") ? "after" : "before",
            s = e[i],
            n = this._findPositionElement(s, t);
          n ? n[i](this.$button) : t.append(this.$button);
        }
      } else t.append(this.$button);
    },
    _findPositionElement: function (t, e) {
      var i;
      if (Array.isArray(t)) for (var s = 0; s < t.length && 0 === (i = e.find("[data-name=" + t[s] + "]")).length; s++);else i = e.find("[data-name=" + t + "]");
      return 0 !== i.length ? i : 0;
    },
    _stop: function (t) {
      t.preventDefault(), t.stopPropagation();
    },
    _catch: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).closest("." + this.prefix + "-button-target");
      if (!e.hasClass("disable")) {
        this.app.editor.setFocus();
        var i = e.attr("data-command"),
          s = e.attr("data-name"),
          n = e.dataget("instance");
        this.app.api(i, this.getParams(), n, s, t), this.app.tooltip.close();
      }
    }
  }), RedactorX.add("module", "tooltip", {
    init: function () {
      this.classname = this.prefix + "-tooltip", this.eventname = this.prefix + "-button-" + this.uuid;
    },
    stop: function () {
      this.close();
    },
    build: function (t, e) {
      (e = this._cleanTitle(e)) && (t.attr("data-tooltip", e), t.on("mouseover." + this.eventname, this.open.bind(this)), t.on("mouseout." + this.eventname, this.close.bind(this)));
    },
    open: function (t) {
      var e = this._getButton(t);
      this.app.popup.isOpen() || e.hasClass("disable") || (this.$tooltip = this._create(e), this._setPosition(e), this._fixBSModal(), this.app.$body.append(this.$tooltip));
    },
    close: function () {
      this.app.$body.find("." + this.classname).remove();
    },
    _create: function (t) {
      return this.dom("<span>").addClass(this.classname).html(t.attr("data-tooltip"));
    },
    _cleanTitle: function (t) {
      return !!t && t.replace(/(<([^>]+)>)/gi, "");
    },
    _setPosition: function (t) {
      var e = t.offset(),
        i = t.height();
      this.$tooltip.css({
        top: e.top + i + "px",
        left: e.left + "px"
      });
    },
    _fixBSModal: function () {
      this.opts.bsmodal && this.$tooltip.css("z-index", 1051);
    },
    _getButton: function (t) {
      return this.dom(t.target).closest("." + this.prefix + "-button-target");
    }
  }), RedactorX.add("module", "codemirror", {
    init: function () {
      this.cm = !1;
    },
    create: function (t) {
      if (this.is()) {
        var e = "object" == typeof this.opts.codemirror ? this.opts.codemirror : {},
          i = this.opts.codemirrorSrc ? this.opts.codemirrorSrc : CodeMirror;
        return this.cm = i.fromTextArea(this.dom(t.el).get(), e), t.height && this.cm.setSize(null, t.height), t.focus && this.cm.focus(), this.cm;
      }
    },
    destroy: function () {
      this.cm && (this.cm.toTextArea(), this.cm = !1);
    },
    is: function () {
      return this.opts.codemirror;
    },
    val: function (t) {
      return this.is() && this.cm && (t = this.cm.getValue()), t;
    }
  }), RedactorX.add("class", "upload", {
    defaults: {
      type: "image",
      box: !1,
      url: !1,
      cover: !0,
      name: "file",
      data: !1,
      multiple: !0,
      placeholder: !1,
      hidden: !0,
      target: !1,
      success: !1,
      error: !1,
      remove: !1,
      trigger: !1,
      input: !1
    },
    init: function (t, e, i) {
      this.eventname = this.prefix + "-upload", t && this._build(t, e, i);
    },
    send: function (t, e, i, s) {
      this.p = this._buildParams(i, s), this._send(t, e);
    },
    complete: function (t, e) {
      this._complete(t, e);
    },
    setImage: function (t) {
      this.p.input || (this.$image && this.$image.remove(), this.$remove && this.$remove.remove(), "" === t ? this.$placeholder.show() : (this.$placeholder.hide(), this._buildImage(t), this.p.remove && this._buildRemove()));
    },
    _build: function (t, e, i) {
      this.p = this._buildParams(e, i), this.$element = this.dom(t), "INPUT" === this.$element.get().tagName ? this._buildByInput() : this._buildByBox();
    },
    _buildImage: function (t) {
      this.$image = this.dom("<img>"), this.$image.attr("src", t), this.$box.append(this.$image), !1 === this.p.input && (this.$box.off("click." + this.eventname), this.$image.on("click." + this.eventname, this._click.bind(this)));
    },
    _buildRemove: function () {
      this.$remove = this.dom("<span>"), this.$remove.addClass(this.prefix + "-upload-remove"), this.$remove.on("click", this._removeImage.bind(this)), this.$box.append(this.$remove);
    },
    _buildParams: function (t, e) {
      return t = $R.extend(!0, this.defaults, t), e && (t.trigger = e), t;
    },
    _buildByInput: function () {
      this.$input = this.$element, this.p.box ? (this._buildBox(), this._buildPlaceholder()) : this.p.input = !0, this._buildAccept(), this._buildMultiple(), this._buildEvents();
    },
    _buildByBox: function () {
      this._buildInput(), this._buildAccept(), this._buildMultiple(), this._buildBox(), this._buildPlaceholder(), this._buildEvents();
    },
    _buildBox: function () {
      this.$box = this.dom("<div>").addClass(this.prefix + "-form-upload-box"), this.$element.before(this.$box), !1 === this.p.cover && this.$box.addClass(this.prefix + "-form-upload-cover-off"), this.p.hidden && this.$element.hide();
    },
    _buildPlaceholder: function () {
      this.p.placeholder && (this.$placeholder = this.dom("<span>").addClass(this.prefix + "-form-upload-placeholder"), this.$placeholder.html(this.p.placeholder), this.$box.append(this.$placeholder));
    },
    _buildInput: function () {
      this.$input = this.dom("<input>"), this.$input.attr("type", "file"), this.$input.attr("name", this._getUploadParam()), this.$input.hide(), this.$element.before(this.$input);
    },
    _buildAccept: function () {
      if ("image" === this.p.type) {
        var t = this.opts.image.types.join(",");
        this.$input.attr("accept", t);
      }
    },
    _buildMultiple: function () {
      "image" === this.p.type && (this.p.multiple ? this.$input.attr("multiple", "multiple") : this.$input.removeAttr("multiple"));
    },
    _buildEvents: function () {
      this.$input.on("change." + this.eventname + "-" + this.uuid, this._change.bind(this)), !1 === this.p.input && (this.$box.on("click." + this.eventname, this._click.bind(this)), this.$box.on("drop." + this.eventname, this._drop.bind(this)), this.$box.on("dragover." + this.eventname, this._dragover.bind(this)), this.$box.on("dragleave." + this.eventname, this._dragleave.bind(this)));
    },
    _buildData: function (t, e, i) {
      if ("single" === this.p.multiple) i.append(t, e[0]);else if (this.p.multiple) for (var s = 0; s < e.length; s++) i.append(t + "[]", e[s]);else i.append(t + "[]", e[0]);
      return i;
    },
    _removeImage: function (t) {
      t && (t.preventDefault(), t.stopPropagation()), this.$image && this.$image.remove(), this.$remove && this.$remove.remove(), this.$placeholder.show(), !1 === this.p.input && this.$box.on("click." + this.eventname, this._click.bind(this)), t && this.app.api(this.p.remove, t);
    },
    _getUploadParam: function () {
      return this.p.name;
    },
    _click: function (t) {
      t.preventDefault(), this.$input.click();
    },
    _change: function (t) {
      this._send(t, this.$input.get().files);
    },
    _drop: function (t) {
      t.preventDefault(), this._send(t);
    },
    _dragover: function (t) {
      return t.preventDefault(), this._setStatus("hover"), !1;
    },
    _dragleave: function (t) {
      return t.preventDefault(), this._removeStatus(), !1;
    },
    _setStatus: function (t) {
      !this.p.input && this.p.box && (this._removeStatus(), this.$box.addClass(this.prefix + "-form-upload-" + t));
    },
    _removeStatus: function () {
      if (!this.p.input && this.p.box) for (var t = ["hover", "error"], e = 0; e < t.length; e++) this.$box.removeClass(this.prefix + "-form-upload-" + t[e]);
    },
    _send: function (t, e) {
      e = e || t.dataTransfer.files;
      var i = new FormData(),
        s = this._getUploadParam();
      i = this._buildData(s, e, i), i = this.app.utils.extendData(i, this.p.data), this._sendData(t, e, i);
    },
    _sendData: function (e, i, s) {
      "function" == typeof this.p.url ? this.p.url.call(this.app, this, {
        data: s,
        files: i,
        e: e
      }) : (this.app.progress.show(), this.ajax.post({
        url: this.p.url,
        data: s,
        before: function (t) {
          if (this.app.broadcast("upload.before.send", {
            xhr: t,
            data: s,
            files: i,
            e: e
          }).isStopped()) return this.app.progress.hide(), !1;
        }.bind(this),
        success: function (t) {
          this._complete(t, e);
        }.bind(this)
      }));
    },
    _complete: function (t, e) {
      t && t.error ? (this._setStatus("error"), this.p.error && (this.app.broadcast("upload.error", {
        response: t
      }), this.app.api(this.p.error, t, e))) : (this._removeStatus(), this._trigger(t), this.p.success && (this.app.broadcast("upload.complete", {
        response: t
      }), this.app.api(this.p.success, t, e))), setTimeout(function () {
        this.app.progress.hide();
      }.bind(this), 500);
    },
    _trigger: function (t) {
      if (this.p.trigger && t && t.url) {
        var e = this.p.trigger.instance;
        e[this.p.trigger.method].call(e, t.url);
      }
    }
  }), RedactorX.add("module", "statusbar", {
    init: function () {
      this.classname = this.prefix + "-statusbar";
    },
    start: function () {
      this._build();
    },
    add: function (t, e) {
      return this.update(t, e);
    },
    update: function (t, e) {
      var i = this.get(t);
      return 0 === i.length && (i = this._buildItem(t)), i.html(e);
    },
    get: function (t) {
      var e = t ? "[data-name=" + t + "]" : "[data-name]";
      return this.$statusbar.find(e);
    },
    remove: function (t) {
      this.get(t).remove();
    },
    clear: function () {
      this.$statusbar.html("");
    },
    _build: function () {
      this.$statusbar = this.dom("<div>").attr("dir", this.opts.editor.direction), this.$statusbar.addClass(this.classname + " " + this.classname + "-" + this.uuid), this.app.container.get("statusbar").append(this.$statusbar);
    },
    _buildItem: function (t) {
      var e = this.dom("<span>").addClass(this.classname + "-item");
      return e.attr("data-name", t), this.$statusbar.append(e), e;
    }
  }), RedactorX.add("module", "container", {
    init: function () {
      this.blurclass = this.prefix + "-in-blur", this.focusclass = this.prefix + "-in-focus";
    },
    start: function () {
      this._buildMain(), this._buildContainers(this.$main, this.opts.containers.main), this._buildBSModal();
    },
    stop: function () {
      this.$main.remove();
    },
    get: function (t) {
      return this["$" + t];
    },
    setFocus: function () {
      this.$main.removeClass(this.blurclass).addClass(this.focusclass);
    },
    setBlur: function () {
      this.$main.removeClass(this.focusclass).addClass(this.blurclass);
    },
    isFocus: function () {
      return this.$main.hasClass(this.focusclass);
    },
    _buildMain: function () {
      this.$main = this.dom("<div>").attr(this.prefix + "-uuid", this.uuid), this.$main.addClass(this.prefix + "-container " + this.prefix + "-container-" + this.uuid), this.app.$element.after(this.$main);
    },
    _buildContainers: function (t, e) {
      for (var i = 0; i < e.length; i++) {
        var s = e[i],
          n = "$" + s;
        this[n] = this._createContainer(s), void 0 !== this.opts.containers[s] && this._buildContainers(this[n], this.opts.containers[s]), t.append(this[n]);
      }
    },
    _buildBSModal: function () {
      this.opts.bsmodal = 0 !== this.$main.closest(".modal-dialog").length;
    },
    _createContainer: function (t) {
      return this.dom("<div>").addClass(this.prefix + "-" + t + "-container");
    }
  }), RedactorX.add("module", "shortcut", {
    init: function () {
      this.shortcuts = this.opts.shortcuts, this.hotkeys = {
        8: "backspace",
        9: "tab",
        10: "return",
        13: "return",
        16: "shift",
        17: "ctrl",
        18: "alt",
        19: "pause",
        20: "capslock",
        27: "esc",
        32: "space",
        33: "pageup",
        34: "pagedown",
        35: "end",
        36: "home",
        37: "left",
        38: "up",
        39: "right",
        40: "down",
        45: "insert",
        46: "del",
        59: ";",
        61: "=",
        96: "0",
        97: "1",
        98: "2",
        99: "3",
        100: "4",
        101: "5",
        102: "6",
        103: "7",
        104: "8",
        105: "9",
        106: "*",
        107: "+",
        109: "-",
        110: ".",
        111: "/",
        112: "f1",
        113: "f2",
        114: "f3",
        115: "f4",
        116: "f5",
        117: "f6",
        118: "f7",
        119: "f8",
        120: "f9",
        121: "f10",
        122: "f11",
        123: "f12",
        144: "numlock",
        145: "scroll",
        173: "-",
        186: ";",
        187: "=",
        188: ",",
        189: "-",
        190: ".",
        191: "/",
        192: "`",
        219: "[",
        220: "\\",
        221: "]",
        222: "'"
      }, this.hotkeysShiftNums = {
        "`": "~",
        1: "!",
        2: "@",
        3: "#",
        4: "$",
        5: "%",
        6: "^",
        7: "&",
        8: "*",
        9: "(",
        0: ")",
        "-": "_",
        "=": "+",
        ";": ": ",
        "'": '"',
        ",": "<",
        ".": ">",
        "/": "?",
        "\\": "|"
      };
    },
    add: function (t, e) {
      this.shortcuts[t] = e;
    },
    remove: function (t) {
      this.opts.shortcutsBase = this._remove(t, this.opts.shortcutsBase), this.opts.shortcuts = this._remove(t, this.opts.shortcuts);
    },
    popup: function (t, e) {
      var i = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "<b>&#8984;</b>" : "ctrl",
        s = {},
        n = 0;
      n = this._buildPopupItems(s, n, this.opts.shortcutsBase, i, "base"), this._buildPopupItems(s, n, this.opts.shortcuts, i), this.app.popup.create("shortcuts", {
        width: "360px",
        items: s
      }), this.app.popup.open({
        button: e
      });
    },
    handle: function (t) {
      if ((this.triggered = !1) === this.shortcuts) return !t.ctrlKey && !t.metaKey || 66 !== t.which && 73 !== t.which || t.preventDefault(), !0;
      if (t.ctrlKey || t.metaKey || t.shoftKey || t.altKey) for (var e in this.shortcuts) this._build(t, e, this.shortcuts[e]);
      return this.triggered;
    },
    _buildPopupItems: function (t, e, i, s, n) {
      for (var a in i) {
        for (var o = this.dom("<div>").addClass(this.prefix + "-popup-shortcut-item"), r = "base" === n ? i[a] : i[a].title, p = this.dom("<span>").addClass(this.prefix + "-popup-shortcut-title").html(this.lang.parse(r)), l = this.dom("<span>").addClass(this.prefix + "-popup-shortcut-kbd"), h = ("base" === n ? a.replace("meta", s) : i[a].name.replace("meta", s)).split("+"), c = 0; c < h.length; c++) h[c] = "<span>" + h[c] + "</span>";
        l.html(h.join("+")), o.append(p), o.append(l), t[e] = {
          html: o
        }, e++;
      }
      return e;
    },
    _build: function (t, e, i) {
      for (var s = e.split(","), n = s.length, a = 0; a < n; a++) "string" != typeof s[a] || Object.prototype.hasOwnProperty.call(i, "trigger") || this._handler(t, s[a].trim(), i);
    },
    _handler: function (t, e, i) {
      e = e.toLowerCase().split(" ");
      for (var s = this.hotkeys[t.keyCode], n = 91 !== t.which && String.fromCharCode(t.which).toLowerCase(), a = "", o = {}, r = ["meta", "ctrl", "alt", "shift"], p = 0; p < r.length; p++) {
        var l = r[p];
        t[l + "Key"] && s !== l && (a += l + "+");
      }
      93 === t.keyCode && (a += "meta+"), s && (o[a + s] = !0), n && (o[a + n] = !0, o[a + this.hotkeysShiftNums[n]] = !0, "shift+" === a && (o[this.hotkeysShiftNums[n]] = !0));
      for (var h = e.length, c = 0; c < h; c++) if (o[e[c]]) return t.preventDefault(), this.triggered = !0, void this.app.api(i.command, i.params, t);
    },
    _remove: function (i, s) {
      return Object.keys(s).reduce(function (t, e) {
        return e !== i && (t[e] = s[e]), t;
      }, {});
    }
  }), RedactorX.add("module", "offset", {
    start: function () {
      this.win = this.app.editor.getWinNode(), this.doc = this.app.editor.getDocNode();
    },
    get: function (t) {
      t = this._getEl(t);
      var e = this.win.getSelection(),
        i = !1;
      if (e && 0 < e.rangeCount) {
        var s = e.getRangeAt(0);
        if (t.contains(e.anchorNode)) {
          var n = s.cloneRange();
          n.selectNodeContents(t), n.setEnd(s.startContainer, s.startOffset);
          var a = n.toString().length;
          i = {
            start: a,
            end: a + s.toString().length
          };
        }
      }
      return i;
    },
    set: function (t, e) {
      !1 === e && (e = {
        start: 0,
        end: 0
      }), t = this._getEl(t);
      var i,
        s = 0,
        n = this.doc.createRange(),
        a = [t],
        o = !1,
        r = !1;
      for (n.setStart(t, 0), n.collapse(!0); !r && (i = a.pop());) if (3 === i.nodeType) {
        var p = s + i.length;
        !o && e.start >= s && e.start <= p && (n.setStart(i, e.start - s), o = !0), o && e.end >= s && e.end <= p && (n.setEnd(i, e.end - s), r = !0), s = p;
      } else for (var l = i.childNodes.length; l--;) a.push(i.childNodes[l]);
      var h = this.win.getSelection();
      h.removeAllRanges(), h.addRange(n);
    },
    _getEl: function (t) {
      return t ? this.dom(t).get() : this.app.editor.getLayout().get();
    }
  }), RedactorX.add("module", "marker", {
    start: function () {
      this.win = this.app.editor.getWinNode(), this.doc = this.app.editor.getDocNode();
    },
    build: function (t) {
      var e = this.dom("<span>").attr("id", "selection-marker-" + t);
      return e.addClass(this.prefix + "-selection-marker"), e.html(this.opts.markerChar), e.get();
    },
    insert: function () {
      this.remove();
      var t = this.app.selection.get(),
        e = !t.collapsed;
      if (t.range) {
        var i = this.build("start"),
          s = this.build("end"),
          n = t.range.cloneRange();
        e && (n.collapse(!1), n.insertNode(s)), n.setStart(t.range.startContainer, t.range.startOffset), n.collapse(!0), n.insertNode(i), t.range.setStartAfter(i), e && t.range.setEndBefore(s), this.app.selection.setRange(t.range);
      }
    },
    restore: function () {
      var t = this.find("start"),
        e = this.find("end"),
        i = this.app.selection.get(),
        s = i.range ? i.range : this.doc.createRange();
      if (t) {
        var n = !!e && e.previousSibling,
          a = t.nextSibling;
        a = (!a || 3 !== a.nodeType || "" !== a.textContent.replace(/[\n\t]/g, "")) && a, e ? a && "selection-marker-end" === a.id ? this._restoreInject(s, t) : n && a ? (s.selectNodeContents(n), s.collapse(!1), s.setStart(a, 0)) : n && !a ? (s.selectNodeContents(n), s.collapse(!1), s.setStartAfter(t)) : (s.setStartAfter(t), s.setEndBefore(e)) : a ? (s.selectNodeContents(a), s.collapse(!0)) : this._restoreInject(s, t), this.app.selection.setRange(s);
        var o = t && e ? 2 : 1,
          r = this.app.offset.get();
        r = {
          start: r.start - o,
          end: r.end - o
        }, t && t.parentNode.removeChild(t), e && e.parentNode.removeChild(e), this.app.editor.setWinFocus(), this.app.offset.set(!1, r);
      }
    },
    find: function (t) {
      var e = this.app.editor.getEditor().find("#selection-marker-" + t);
      return 0 !== e.length && e.get();
    },
    remove: function () {
      var t = this.find("start"),
        e = this.find("end");
      t && t.parentNode.removeChild(t), e && e.parentNode.removeChild(e);
    },
    _restoreInject: function (t, e) {
      var i = this.app.utils.createInvisibleChar();
      this.dom(e).after(i), t.selectNodeContents(i), t.collapse(!1);
    }
  }), RedactorX.add("module", "state", {
    init: function () {
      this.started = !1, this.storage = !1, this.state = !1, this.passed = !0, this.undoStorage = [], this.redoStorage = [];
    },
    load: function () {
      this.clear(), this.trigger(!0);
    },
    stop: function () {
      this.clear();
    },
    clear: function () {
      this.storage = !1, this.state = !1, this.passed = !0, this.undoStorage = [], this.redoStorage = [];
    },
    get: function () {
      return this.undoStorage;
    },
    add: function (t) {
      t && (t.ctrlKey || t.metaKey || this._isUndo(t) || this._isRedo(t)) || !this.app.observer.trigger || (this.state = this._createState(), !1 === this.started && (this._setState(this.state, 0), this.started = !0));
    },
    trigger: function (t) {
      if (this.passed) {
        var e = this._createState();
        this.state ? e = this.state : this.state || t || (e = this.storage, this.started = !0), this._addState(e), this.storage = this._createState(), this.state = !1;
      }
    },
    listen: function (t) {
      return this._isUndo(t) ? (t.preventDefault(), this.undo(), !0) : this._isRedo(t) ? (t.preventDefault(), this.redo(), !0) : void (this.passed = !0);
    },
    undo: function () {
      if (this._hasUndo()) {
        this.passed = !1;
        var t = this._getUndo();
        this._setRedo();
        var e = this.app.parser.parse(t[0]);
        this.app.editor.getLayout().html(e.children()), this._rebuild(t, "undo");
        var i = this.app.block.get(),
          s = !(!i || !i.isEditable()) && i.getBlock();
        this.app.offset.set(s, t[1]);
      }
    },
    redo: function () {
      if (this._hasRedo()) {
        this.passed = !1;
        var t = this.redoStorage.pop();
        this._addState(t);
        var e = this.app.parser.parse(t[0]);
        this.app.editor.getLayout().html(e.children()), this._rebuild(t, "redo");
        var i = this.app.block.get(),
          s = !(!i || !i.isEditable()) && i.getBlock();
        this.app.offset.set(s, t[1]);
      }
    },
    _rebuild: function (t, e) {
      this.app.editor.build(), this.app.editor.getLayout().find("." + this.prefix + "-block-state").each(function (t) {
        this.app.block.set(t);
      }.bind(this)), this.app.broadcast("state." + e, {
        html: t[0],
        offset: t[1]
      });
    },
    _isUndo: function (t) {
      var e = t.which;
      return (t.ctrlKey || t.metaKey) && 90 === e && !t.shiftKey && !t.altKey;
    },
    _isRedo: function (t) {
      var e = t.which;
      return (t.ctrlKey || t.metaKey) && (90 === e && t.shiftKey || 89 === e && !t.shiftKey) && !t.altKey;
    },
    _hasUndo: function () {
      return 0 !== this.undoStorage.length;
    },
    _hasRedo: function () {
      return 0 !== this.redoStorage.length;
    },
    _getUndo: function () {
      return 1 === this.undoStorage.length ? this.undoStorage[0] : this.undoStorage.pop();
    },
    _createState: function () {
      var t = this.app.editor.getLayout().html();
      t = this.app.utils.wrap(t, function (t) {
        t.find("." + this.prefix + "-block-focus").addClass(this.prefix + "-block-state");
      }.bind(this));
      var e = this.app.block.get(),
        i = !(!e || !e.isEditable()) && e.getBlock();
      return {
        html: this.app.parser.unparse(t, !0),
        offset: this.app.offset.get(i)
      };
    },
    _setState: function (t, e) {
      this.undoStorage[e] = [t.html, t.offset];
    },
    _addState: function (t) {
      var e = this.undoStorage[this.undoStorage.length - 1];
      void 0 === e || e[0] !== t.html ? (this.undoStorage.push([t.html, t.offset]), this._removeOverStorage()) : e[1] = t.offset;
    },
    _setRedo: function () {
      var t = this._createState();
      this.redoStorage.push([t.html, t.offset]), this.redoStorage = this.redoStorage.slice(0, this.opts.state.limit);
    },
    _removeOverStorage: function () {
      this.undoStorage.length > this.opts.state.limit && (this.undoStorage = this.undoStorage.slice(0, this.undoStorage.length - this.opts.state.limit));
    }
  }), RedactorX.add("module", "sync", {
    build: function () {
      this.syncedHtml = this.app.$element.val();
    },
    trigger: function () {
      if (this.opts.editor.sync) {
        var t = this._getHtml();
        this.is(t) && (this.timeout && clearTimeout(this.timeout), this.timeout = setTimeout(function () {
          this._sync(t);
        }.bind(this), 200));
      }
    },
    invoke: function () {
      var t = this._getHtml();
      this.syncedHtml = t, this._sync(t);
    },
    is: function (t) {
      var e = !1;
      return this.syncedHtml !== t && (this.syncedHtml = t, e = !0), e;
    },
    _getHtml: function () {
      var t = this.app.editor.getLayout().html();
      return this.app.parser.unparse(t);
    },
    _sync: function (t) {
      var e = this.app.broadcast("editor.before.change", {
        html: t
      });
      e.isStopped() || (this.app.$element.val(e.get("html")), this.app.autosave.send(), this.app.state.trigger(), this.app.broadcast("editor.change", e));
    }
  }), RedactorX.add("module", "placeholder", {
    start: function () {
      this.placeholder = !1, this.$layout = this.app.editor.getLayout(), this._build();
    },
    handleClick: function (t) {
      this.dom(t.target).hasClass(this.prefix + "-placeholder") && (t.preventDefault(), t.stopPropagation(), this.app.editor.setFocus("start"));
    },
    toggle: function () {
      return this.placeholder && this.app.editor.isEmpty(!0) ? this.show() : this.hide();
    },
    show: function () {
      this.$layout.addClass(this.prefix + "-placeholder");
    },
    hide: function () {
      this.$layout.removeClass(this.prefix + "-placeholder");
    },
    _build: function () {
      var t = this.opts.placeholder,
        e = this.app.$element.attr("placeholder");
      !1 === t && !e || (this.$layout.attr("placeholder", !1 !== t ? t : e), this.placeholder = !0, this.toggle());
    }
  }), RedactorX.add("module", "list", {
    indent: function () {
      var t = this.app.selection.get(),
        e = this.app.selection.getBlock(),
        i = this.dom(e),
        s = i.prevElement(),
        n = s.get(),
        a = t.collapsed && e && n && "LI" === n.tagName;
      if (this.app.selection.save(e), a) {
        var o = (s = this.dom(n)).children("ul, ol"),
          r = i.closest("ul, ol");
        if (0 !== o.length) o.append(i);else {
          var p = r.get().tagName.toLowerCase(),
            l = this.dom("<" + p + ">");
          l.append(i), s.append(l);
        }
      }
      return this.app.selection.restore(), a;
    },
    outdent: function () {
      var t = this.app.selection.get(),
        e = this.app.selection.getBlock(),
        i = this.dom(e),
        s = !1;
      if (t.collapsed && e) {
        var n,
          a,
          o = i.parent(),
          r = o.closest("li"),
          p = i.prevElement(),
          l = i.nextElement(),
          h = p.get(),
          c = l.get(),
          d = !1 === h,
          u = !1 !== h && !1 !== c;
        if (this.app.selection.save(e), 0 !== r.length) {
          if (u) {
            n = this._getAllNext(i.get()), a = this.dom("<" + o.get().tagName.toLowerCase() + ">");
            for (var f = 0; f < n.length; f++) a.append(n[f]);
            r.after(i), i.append(a);
          } else r.after(i), 0 === o.children().length ? o.remove() : d && i.append(o);
          s = !0;
        }
        this.app.selection.restore();
      }
      return s;
    },
    _getAllNext: function (t) {
      for (var e = []; t;) {
        if (!(t = this.dom(t).nextElement().get())) return e;
        e.push(t);
      }
      return e;
    }
  }), RedactorX.add("module", "cleaner", {
    cleanHtml: function (t) {
      t = this.app.broadcastHtml("editor.before.clean", t);
      var e = {},
        i = this.opts.paste.blockTags.concat(this.opts.paste.inlineTags).concat(this.opts.paste.formTags),
        s = this._isPages(t),
        n = this._isHtmlMsWord(t),
        a = this._isEditor(t);
      if (t = this.app.content.store(t, "embed", e, 0), t = this.app.content.removeDoctype(t), t = (t = this.app.content.removeTags(t, this.opts.tags.denied)).trim(), t = this.app.content.removeComments(t), t = this.app.content.removeTagsWithContent(t, ["script", "style"]), t = s ? this._cleanPages(t) : t, t = this._cleanGDocs(t), t = this._encodePhp(t), t = this.app.content.removeTagsExcept(t, i), !a) {
        var o = 0 !== this.opts.paste.keepClass.length ? this.opts.paste.keepClass.join(",") : "",
          r = 0 !== this.opts.paste.keepAttrs.length ? this.opts.paste.keepAttrs.join(",") : "";
        t = this.app.utils.wrap(t, function (t) {
          var e = t.find("*");
          e.not(o).removeAttr("class"), e.not(r).each(function (t) {
            for (var e = t.get(), i = e.attributes, s = i.length - 1; 0 <= s; s--) {
              var n = i[s].name;
              "class" !== n && "dir" !== n && -1 === n.search(/^data-/) && ("IMG" === e.tagName && "src" === n || ("A" !== e.tagName || "href" !== n && "target" !== n) && e.removeAttribute(n));
            }
          });
        });
      }
      return t = this.app.content.restore(t, "embed", e), t = n ? this._cleanMsWord(t) : t, t = a ? this.app.content.cacheStyle(t) : this.app.content.removeStyleAttr(t), t = (t = (t = (t = (t = this.app.content.removeEmptyInlines(t)).replace(/<p>&nbsp;<\/p>/gi, "<p></p>")).replace(/<p><br\s?\/?><\/p>/gi, "<p></p>")).replace(/^<li/gi, "<ul><li")).replace(/<\/li>$/gi, "</li></ul>"), (n || s) && (t = (t = t.replace(/<p><\/p>/gi, "")).replace(/<p>\s<\/p>/gi, "")), t = this.app.utils.wrap(t, function (t) {
        t.find(".Apple-converted-space").unwrap(), t.find("ul, ol").each(this._placeListToItem.bind(this)), t.find("li p").unwrap();
      }.bind(this)), this.app.broadcastHtml("editor.clean", t);
    },
    _encodePhp: function (t) {
      return t = (t = (t = t.replace("<?php", "&lt;?php")).replace("<?", "&lt;?")).replace("?>", "?&gt;");
    },
    _isEditor: function (t) {
      return t.match(new RegExp('meta\\stype="' + this.prefix + '-editor"', "i"));
    },
    _isHtmlMsWord: function (t) {
      return t.match(/class="?Mso|style="[^"]*\bmso-|style='[^'']*\bmso-|w:WordDocument/i);
    },
    _isPages: function (t) {
      return t.match(/name="Generator"\scontent="Cocoa\sHTML\sWriter"/i);
    },
    _placeListToItem: function (t) {
      var e = t.get(),
        i = e.previousSibling;
      if (i && "LI" === i.tagName) {
        var s = this.dom(i);
        s.find("p").unwrap(), s.append(e);
      }
    },
    _cleanPages: function (t) {
      return t = (t = t.replace(/\sclass="s[0-9]"/gi, "")).replace(/\sclass="p[0-9]"/gi, "");
    },
    _cleanGDocs: function (t) {
      return t = (t = (t = (t = (t = t.replace(/<b\sid="internal-source-marker(.*?)">([\w\W]*?)<\/b>/gi, "$2")).replace(/<b(.*?)id="docs-internal-guid(.*?)">([\w\W]*?)<\/b>/gi, "$3")).replace(/<span[^>]*(font-style:\s?italic;\s?font-weight:\s?(bold|600|700)|font-weight:\s?(bold|600|700);\s?font-style:\s?italic)[^>]*>([\w\W]*?)<\/span>/gi, "<b><i>$4</i></b>")).replace(/<span[^>]*font-style:\s?italic[^>]*>([\w\W]*?)<\/span>/gi, "<i>$1</i>")).replace(/<span[^>]*font-weight:\s?(bold|600|700)[^>]*>([\w\W]*?)<\/span>/gi, "<b>$2</b>");
    },
    _cleanMsWord: function (t) {
      t = (t = (t = (t = (t = (t = t.replace(/<!--[\s\S]+?-->/gi, "")).trim()).replace(/<(!|script[^>]*>.*?<\/script(?=[>\s])|\/?(\?xml(:\w+)?|img|meta|link|style|\w:\w+)(?=[\s/>]))[^>]*>/gi, "")).replace(/<(\/?)s>/gi, "<$1strike>")).replace(/&nbsp;/gi, " ")).replace(/<span\s+style\s*=\s*"\s*mso-spacerun\s*:\s*yes\s*;?\s*"\s*>([\s\u00a0]*)<\/span>/gi, function (t, e) {
        return 0 < e.length ? e.replace(/./, " ").slice(Math.floor(e.length / 2)).split("").join(" ") : "";
      });
      for (var e = "", i = (t = (t = (t = (t = (t = this.app.utils.wrap(t, function (t) {
          t.find("p").each(function (t) {
            var e = /mso-list:\w+ \w+([0-9]+)/.exec(t.attr("style"));
            e && t.attr("data-listLevel", parseInt(e[1], 10));
          }), this._parseWordLists(t), t.find("[align]").removeAttr("align"), t.find("[name]").removeAttr("name"), t.find("span").each(function (t) {
            var e = t.attr("style");
            /mso-list:Ignore/.exec(e) ? t.remove() : t.unwrap();
          }), t.find("[style]").removeAttr("style"), t.find("[class^='Mso']").removeAttr("class"), t.find("a").filter(function (t) {
            return !t.attr("href");
          }).unwrap();
        }.bind(this))).replace(/<p[^>]*><\/p>/gi, "")).replace(/<li>·/gi, "<li>")).trim()).replace(/\/(p|ul|ol|h1|h2|h3|h4|h5|h6|blockquote)>\s+<(p|ul|ol|h1|h2|h3|h4|h5|h6|blockquote)/gi, "/$1>\n<$2")).split(/\n/), s = 0; s < i.length; s++) {
        var n = "" !== i[s] && -1 === i[s].search(/>$/) ? " " : "\n";
        e += i[s] + n;
      }
      return e = e.trim();
    },
    _parseWordLists: function (t) {
      var o = 0,
        r = null,
        p = null,
        l = null;
      t.find("p").each(function (t) {
        var e = t.attr("data-listLevel");
        if (null === e && t.hasClass("MsoListParagraphCxSpMiddle") && (e = 1), null !== e) {
          var i = t.text(),
            s = /^\s*\w+\./.test(i) ? "<ol></ol>" : "<ul></ul>";
          if (t.hasClass("MsoListParagraphCxSpFirst") || t.hasClass("MsoNormal") ? (p = this.dom(s), t.before(p)) : o < e && 0 !== o && (l = this.dom(s), r.append(l), p = l), e < o) for (var n = o - e + 1, a = 0; a < n; a++) p = p.parent();
          t.find("span").first().unwrap(), r = this.dom("<li>" + t.html().trim() + "</li>"), null === p && (t.before(s), p = t.prev()), p.append(r), t.remove(), o = e;
        } else p = null, o = 0;
      }.bind(this));
    }
  }), RedactorX.add("module", "tidy", {
    init: function () {},
    parse: function (t) {
      t = this.app.content.encodeAttrSings(t);
      var e = ["li"],
        i = ["li"];
      this.lineBefore = new RegExp("^<(/?" + e.join("|/?") + "|" + i.join("|") + ")[ >]"), this.lineAfter = new RegExp("^<(/?" + e.join("|/?") + "|/" + i.join("|/") + ")[ >]"), this.newLevel = new RegExp("^</?(" + ["p", "ul", "ol", "li", "div", "h1", "h2", "h3", "h4", "h5", "h6", "blockquote", "figure", "figcaption", "table", "thead", "tbody", "tfoot", "tr", "td", "th"].join("|") + ")[ >]");
      var s = 0,
        n = t.length,
        a = 0,
        o = null,
        r = null,
        p = "",
        l = "",
        h = "";
      for (this.cleanlevel = 0; s < n; s++) {
        if (a = s, -1 === t.substr(s).indexOf("<")) return l += t.substr(s), this.finish(l);
        for (; a < n && "<" !== t.charAt(a);) a++;
        for (s !== a && ((h = t.substr(s, a - s)).match(/^\s{2,}$/g) || ("\n" === l.charAt(l.length - 1) ? l += this.getTabs() : "\n" === h.charAt(0) && (l += "\n" + this.getTabs(), h = h.replace(/^\s+/, "")), l += h)), o = a; a < n && ">" !== t.charAt(a);) a++;
        var c;
        if (s = a, "!--" === (p = t.substr(o, a - o)).substr(1, 3)) {
          if (!p.match(/--$/)) {
            for (; "--\x3e" !== t.substr(a, 3);) a++;
            a += 2, p = t.substr(o, a - o), s = a;
          }
          "\n" !== l.charAt(l.length - 1) && (l += "\n"), l += this.getTabs(), l += p + ">\n";
        } else "!" === p[1] ? l = this.placeTag(p + ">", l) : "?" === p[1] ? l += p + ">\n" : c === p.match(/^<(script|style|pre)/i) ? (c[1] = c[1].toLowerCase(), p = this.cleanTag(p), l = this.placeTag(p, l), (r = String(t.substr(s + 1)).toLowerCase().indexOf("</" + c[1])) && (h = t.substr(s + 1, r), s += r, l += h)) : (p = this.cleanTag(p), l = this.placeTag(p, l));
      }
      return this.finish(l);
    },
    getTabs: function () {
      for (var t = "", e = 0; e < this.cleanlevel; e++) t += "    ";
      return t;
    },
    finish: function (t) {
      return t = (t = (t = (t = (t = (t = t.replace(/\n\s*\n/g, "\n")).replace(/^[\s\n]*/, "")).replace(/[\s\n]*$/, "")).replace(/<li(.*?)>[\s\n]*/gi, "<li$1>")).replace(/[\s\n]*<\/li>/gi, "</li>")).replace(/<script(.*?)>\n<\/script>/gi, "<script$1><\/script>"), t = this.app.content.decodeAttrSings(t), this.cleanlevel = 0, t;
    },
    cleanTag: function (t) {
      var e,
        i = "",
        s = "";
      for ((t = (t = (t = t.replace(/\n/g, " ")).replace(/\s{2,}/g, " ")).replace(/^\s+|\s+$/g, " ")).match(/\/$/) && (s = "/", t = t.replace(/\/+$/, "")); null !== (e = /\s*([^= ]+)(?:=((['"']).*?\3|[^ ]+))?/.exec(t)) && (e[2] ? i += e[1].toLowerCase() + "=" + e[2] : e[1] && (i += e[1].toLowerCase()), i += " ", t = t.substr(e[0].length)), e;);
      return i.replace(/\s*$/, "") + s + ">";
    },
    placeTag: function (t, e) {
      var i = t.match(this.newLevel);
      return (t.match(this.lineBefore) || i) && (e = e.replace(/\s*$/, ""), e += "\n"), i && "/" === t.charAt(1) && this.cleanlevel--, "\n" === e.charAt(e.length - 1) && (e += this.getTabs()), i && "/" !== t.charAt(1) && this.cleanlevel++, e += t, (t.match(this.lineAfter) || t.match(this.newLevel)) && (e = e.replace(/ *$/, ""), e += "\n"), e;
    }
  }), RedactorX.add("module", "source", {
    start: function () {
      this.eventname = this.prefix + "-source-events", this._build();
    },
    toggle: function () {
      this.is() ? this.close() : this.open();
    },
    is: function () {
      return "none" === this.app.container.get("editor").css("display");
    },
    open: function () {
      this.app.broadcast("source.before.open");
      var t = this.app.editor.getContent();
      t = this.app.tidy.parse(t);
      var e = this.app.container.get("editor").height();
      this.$source.height(e), this.$source.val(t), this.$source.on("input." + this.eventname, this._handleChanges.bind(this)), this.$source.on("keydown." + this.eventname, this.app.input.handleTextareaTab.bind(this)), this.app.editor.unselectAll(), this.app.container.get("editor").hide(), this.app.container.get("source").show();
      var i = this.app.codemirror.create({
        el: this.$source,
        height: e,
        focus: !0
      });
      i && i.on("change", this._handleChanges.bind(this)), this.app.editor.disableUI(), this.app.toolbar.setToggled("html"), this.app.broadcast("source.open");
    },
    close: function () {
      this.app.broadcast("source.before.close");
      var t = this.getContent();
      this.app.codemirror.destroy(), this.$source.off("." + this.eventname), this.app.container.get("source").hide(), this.app.container.get("editor").show(), this.app.editor.setContent({
        html: t,
        caret: !1
      }), this.app.editor.enableUI(), this.app.toolbar.unsetToggled("html"), this.app.broadcast("source.close");
    },
    update: function (t) {
      var e = this.app.editor.isTextarea() ? "val" : "html";
      this.app.$element[e](t);
    },
    getContent: function () {
      var t = this.$source.val();
      return t = this.app.codemirror.val(t);
    },
    _build: function () {
      this.opts.source && (this.$source = this.dom("<textarea>").addClass(this.prefix + "-source"), this.$source.attr("data-gramm_editor", !1), this.app.container.get("source").append(this.$source));
    },
    _handleChanges: function (t) {
      var e = this.getContent();
      this.update(e), this.app.broadcast("source.change", {
        e: t
      });
    }
  }), RedactorX.add("module", "content", {
    init: function () {
      this._selectors = {
        code: ["pre", "code"],
        embed: ["figure"],
        noneditable: ["." + this.opts.noneditable.classname],
        images: ["img"],
        links: ["a"]
      };
    },
    paragraphize: function (t) {
      return this.app.paragraphizer.paragraphize(t);
    },
    encodeEntities: function (t) {
      return this.decodeEntities(t).replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;");
    },
    encodeCode: function (t) {
      return t = (t = (t = (t = (t = (t = this.encodeAttrSings(t)).replace(/<(.*?)>/gi, "xtagstartz$1xtagendz")).replace(/xtagstartzpre(.*?)xtagendz/g, "<pre$1>")).replace(/xtagstartzcode(.*?)xtagendz/g, "<code$1>")).replace(/xtagstartz\/codextagendz/g, "</code>")).replace(/xtagstartz\/prextagendz/g, "</pre>"), t = (t = (t = this._encodeCode(t)).replace(/xtagstartz(.*?)xtagendz/g, "<$1>")).replace(/xtagstartz\/(.*?)xtagendz/g, "</$1>"), t = this.decodeAttrSings(t);
    },
    encodeAttrSings: function (t) {
      var e = t.match(/"(.*?)"/g);
      if (null !== e) for (var i = 0; i < e.length; i++) {
        var s = e[i].replace(">", "xmoresignz");
        s = s.replace("<", "xlesssignz"), t = t.replace(e[i], s);
      }
      return t;
    },
    decodeAttrSings: function (t) {
      return t = (t = t.replace(/xmoresignz/gi, ">")).replace(/xlesssignz/gi, "<");
    },
    decodeEntities: function (t) {
      return String(t).replace(/&lt;/g, "<").replace(/&gt;/g, ">").replace(/&quot;/g, '"').replace(/&amp;/g, "&");
    },
    decodeHref: function (t) {
      var e = t.match(new RegExp('(href=".*?)(&amp;)(.*?">)', "g"));
      if (null !== e) for (var i = 0; i < e.length; i++) t = t.replace(e[i], e[i].replace(/&amp;/g, "&"));
      return t;
    },
    sanitize: function (t) {
      return t = this.app.utils.wrap(t, function (t) {
        t.find("[src]").each(this._sanitizeSrc), t.find("a").each(this._sanitizeHref), t.find("a,b,i,strong,em,svg,img,details,audio").each(this._sanitizeEvents);
      }.bind(this));
    },
    escapeHtml: function (t) {
      return t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(/"/g, "&quot;").replace(/'/g, "&#039;");
    },
    store: function (t, e, i, s) {
      for (var n = this._selectors[e], a = 0; a < n.length; a++) {
        var o = this._getElementsFromHtml(t, n[a]);
        t = this._store(t, e, o, i, s);
      }
      return t;
    },
    restore: function (t, e, i) {
      if (void 0 === i[e]) return t;
      for (var s = 0; s < i[e].length; s++) t = t.replace("####_" + e + s + "_####", i[e][s]);
      return t;
    },
    storeComments: function (t, e) {
      var i = t.match(new RegExp("\x3c!--([\\w\\W]*?)--\x3e", "gi"));
      if (null === i) return t;
      for (var s = 0; s < i.length; s++) t = t.replace(i[s], "#####xstarthtmlcommentzz" + s + "xendhtmlcommentzz#####"), e.push(i[s]);
      return t;
    },
    restoreComments: function (t, e) {
      for (var i = 0; i < e.length; i++) {
        var s = e[i].replace(/\$/gi, "&#36;");
        t = t.replace("#####xstarthtmlcommentzz" + i + "xendhtmlcommentzz#####", s);
      }
      return t;
    },
    cacheStyle: function (t) {
      var e = this.opts.tags.block.join(",") + ",img," + this.opts.tags.inline.join(",");
      return this.app.utils.wrap(t, function (t) {
        t.find(e).each(this._cacheStyle.bind(this));
      }.bind(this));
    },
    recacheStyle: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("[data-" + this.prefix + "-style-cache]").each(this._recacheStyle.bind(this));
      }.bind(this));
    },
    fixListMargin: function (t) {
      var e = parseInt(t.css("margin-left"));
      if (0 !== e) {
        var i = parseInt(t.css("padding-left"));
        t.css({
          "margin-left": 0,
          "padding-left": i + e + "px"
        }), t.attr(this.prefix + "-list-left", e);
      }
    },
    unfixListMargin: function (t) {
      t.attr(this.prefix + "-list-left") && (t.css({
        "padding-left": "",
        "margin-left": ""
      }), t.removeAttr(this.prefix + "-list-left"));
    },
    addNofollow: function (t) {
      return this.opts.link.nofollow ? this.app.utils.wrap(t, function (t) {
        t.find("a").attr("rel", "nofollow");
      }) : t;
    },
    addHttps: function (t) {
      return this.opts.editor.https ? t = (t = (t = t.replace('href="http://', 'href="https://')).replace('src="http://', 'src="https://')).replace('srcset="http://', 'srcset="https://') : t;
    },
    addSpaceToBlocks: function (t) {
      return t.replace(/<\/(div|li|dt|dd|td|p|H[1-6])>\n?/gi, "</$1> ");
    },
    addBrToBlocks: function (t) {
      return t.replace(/<\/(div|li|dt|dd|td|p|H[1-6])>\n?/gi, "</$1><br>");
    },
    addPredefinedTagClass: function (t) {
      var e = t.get().tagName.toLowerCase(),
        i = void 0 !== this.opts.classes.tags ? this.opts.classes.tags : this.opts.classes;
      void 0 !== i[e] && t.addClass(i[e]);
    },
    addPredefinedBlockClass: function (t) {
      var e = t.attr("data-" + this.prefix + "-type"),
        i = this.opts.classes.blocks;
      void 0 !== i[e] && t.addClass(i[e]);
    },
    getPredefinedBlocks: function () {
      var t = [];
      for (var e in this.opts.classes.blocks) t.push(e);
      return t;
    },
    getPredefinedTags: function () {
      var t = [],
        e = void 0 !== this.opts.classes.tags ? this.opts.classes.tags : this.opts.classes;
      for (var i in e) t.push(i);
      return t;
    },
    getText: function (t) {
      var e = "";
      if (3 === t.nodeType) e = t.nodeValue;else {
        for (var i = 0; i < t.childNodes.length; i++) e += this.getText(t.childNodes[i]);
        var s = 1 === t.nodeType ? getComputedStyle(t).getPropertyValue("display") : "";
        (s.match(/^block/) || s.match(/list/) || "BR" === t.tagName || "HR" === t.tagName) && (e += "\n");
      }
      return e;
    },
    getTextFromHtml: function (t, e) {
      var i = {};
      e = $R.extend({}, {
        br: !1,
        nl: !1,
        trimlines: !0,
        images: !1,
        links: !1
      }, e), t = this.store(t, "code", i, 0), t = e.links ? this.store(t, "links", i, 0) : t, t = (t = (t = (t = (t = (t = (t = (t = (t = e.images ? this.store(t, "images", i, 0) : t).replace(/<(ul|ol)>\s+<li>/gi, "<$1><li>")).replace(/<li[^>]*>\n/gi, "<li$1>")).replace(/<p[^>]*>(\s+|)<\/p>/gi, "xemptyz")).replace(/<!--[\s\S]*?-->/gi, "")).replace(/<style[\s\S]*?style>/gi, "")).replace(/<script[\s\S]*?script>/gi, "")).replace(/<\/(div|li|dt|dd|td|p|H[1-6])>\n?/gi, "</$1>\n")).replace(/&(lt|gt);/gi, "x$1z");
      var s = this.dom("<div>").html(t);
      if (t = this.getText(s.get()), e.trimlines) {
        for (var n = "", a = t.split("\n"), o = 0; o < a.length; o++) n += a[o].trim() + "\n";
        t = n;
      }
      return t = (t = (t = t.replace(/[\n]+/g, "\n")).replace("xemptyz", "\n")).replace(/x(lt|gt)z/gi, "&$1;"), t = e.br ? (t = t.replace(/\n/g, "<br>\n")).replace(/<br\s?\/?>\n?$/gi, "") : e.nl ? t : t.replace(/\n/gi, " "), t = this.restore(t, "code", i), t = e.links ? this.restore(t, "links", i) : t, t = (t = (t = (t = (t = e.images ? this.restore(t, "images", i) : t).replace(/<pre[^>]*>/g, "")).replace(/<code[^>]*>/g, "")).replace(/<\/pre>\n?/g, "")).replace(/<\/code>/g, ""), e.images || (t = (t = t.replace(/<img[\s\S]*?>/gi, "")).replace(/<a[^>]*>(\s+|)<\/a>/gi, "")), t.trim();
    },
    extractHtmlFromCaret: function (t) {
      var e = this.dom(t).get(),
        i = this.app.selection.getRange();
      if (i) {
        var s = i.cloneRange();
        return s.selectNodeContents(e), s.setStart(i.endContainer, i.endOffset), s.extractContents();
      }
    },
    isEmptyHtml: function (t, e) {
      return t = t.trim(), t = (t = (t = (t = (t = (t = (t = (t = (t = this.app.utils.removeInvisibleChars(t)).replace(/^&nbsp;$/gi, "1")).replace(/&nbsp;/gi, "")).replace(/<\/?br\s?\/?>/g, "")).replace(/\s/g, "")).replace(/^<p>\s\S<\/p>$/i, "")).replace(/<hr(.*?[^>])>$/i, "hr")).replace(/<iframe(.*?[^>])>$/i, "iframe")).replace(/<source(.*?[^>])>$/i, "source"), t = this.removeComments(t), "" === (t = (t = (t = (t = e ? t.replace(/<p[^>]*><\/p>/gi, "") : t).replace(/<[^/>]><\/[^>]+>/gi, "")).replace(/<[^/>]><\/[^>]+>/gi, "")).trim());
    },
    isLine: function (t) {
      var e = document.createElement("div");
      return e.innerHTML = t, 0 === this.dom(e).find(this.opts.tags.block.join(",") + ",img").length;
    },
    removeDoctype: function (t) {
      return t.replace(new RegExp("<!doctype[^>]*>", "gi"), "");
    },
    removeComments: function (t) {
      return t.replace(/<!--[\s\S]*?-->\n?/g, "");
    },
    removeTags: function (t, i) {
      var e = i ? /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi : /(<([^>]+)>)/gi,
        s = i ? function (t, e) {
          return -1 === i.indexOf(e.toLowerCase()) ? t : "";
        } : "";
      return t.replace(e, s);
    },
    removeTagsExcept: function (t, i) {
      if (void 0 === i) return t.replace(/(<([^>]+)>)/gi, "");
      return t.replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, function (t, e) {
        return -1 === i.indexOf(e.toLowerCase()) ? "" : t;
      });
    },
    removeTagsWithContent: function (t, e) {
      return this.app.utils.wrap(t, function (t) {
        t.find(e.join(",")).remove();
      });
    },
    removeMarkers: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("." + this.prefix + "-plus-button").remove(), t.find("." + this.prefix + "pastemarker").removeClass(this.prefix + "pastemarker"), t.find("." + this.prefix + "pasteitems").removeClass(this.prefix + "pasteitems"), t.find("." + this.prefix + "-selection-marker").remove();
      }.bind(this));
    },
    removeEmptySpans: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("span").each(this._removeEmptySpan.bind(this));
      }.bind(this));
    },
    removeEmptyInlines: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find(this.opts.tags.inline.join(",")).each(this._removeEmptyTag.bind(this));
      }.bind(this));
    },
    removeEmptyAttrs: function (t, i) {
      return this.app.utils.wrap(t, function (t) {
        for (var e = 0; e < i.length; e++) t.find("[" + i[e] + '=""]').removeAttr(i[e]);
      });
    },
    removeBlockTags: function (t, e, i) {
      var s = this.opts.tags.block.concat();
      return i && (s = this.app.utils.removeFromArrayByValue(s, i)), e = e && (e ? this.app.utils.extendArray(s, e) : s), this.removeTags(t, e);
    },
    removeBlockTagsInside: function (t, e) {
      return this.blockListTags = this.app.utils.removeFromArrayByValue(this.opts.tags.block.concat(), ["ul", "ol", "li"]), this.app.utils.wrap(t, function (t) {
        t.find(e.join(",")).each(this._removeBlockTagsInside.bind(this));
      }.bind(this));
    },
    removeInlineStyles: function (t) {
      var e = this.app.utils.removeFromArrayByValue(this.opts.tags.inline, "a");
      return this.app.utils.wrap(t, function (t) {
        t.find(e.join(",")).removeAttr("style");
      });
    },
    removeStyleAttr: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("*").removeAttr("style");
      });
    },
    _cacheStyle: function (t) {
      var e = "data-" + this.prefix + "-style-cache",
        i = t.attr("style");
      i ? (i = i.replace(/"/g, ""), t.attr(e, i)) : i && "" !== i || t.removeAttr(e);
    },
    _recacheStyle: function (t) {
      var e = "data-" + this.prefix + "-style-cache",
        i = t.attr(e);
      t.attr("style", i).removeAttr(e);
    },
    _cleanEmpty: function (t) {
      return t = t.trim(), t = (t = (t = this.app.utils.removeInvisibleChars(t)).replace(/<\/?br\s?\/?>/g, "")).replace(/\s/g, "");
    },
    _removeEmptySpan: function (t) {
      0 === t.get().attributes.length && t.unwrap();
    },
    _removeEmptyTag: function (t) {
      var e = t.html().trim();
      0 === t.get().attributes.length && "" === e && t.unwrap();
    },
    _removeBlockTagsInside: function (t) {
      var e = "LI" === t.get().tagName ? this.blockListTags : this.opts.tags.block;
      t.find(e.join(",")).append("<br>").unwrap();
    },
    _store: function (t, e, i, s, n) {
      if (!i) return t;
      void 0 === s[e] && (s[e] = []);
      for (var a = 0; a < i.length; a++) s[e][n] = i[a], t = t.replace(i[a], "####_" + e + n + "_####"), n++;
      return t;
    },
    _getElementsFromHtml: function (t, e) {
      var i = [];
      return this.dom("<div>").html(t).find(e).each(function (t) {
        i.push(t.get().outerHTML);
      }), i;
    },
    _sanitizeSrc: function (t) {
      var e = t.get();
      -1 !== e.getAttribute("src").search(/^data|javascript:/i) && e.setAttribute("src", "");
    },
    _sanitizeHref: function (t) {
      var e = t.get(),
        i = e.getAttribute("href");
      i && -1 !== i.search(/^javascript:/i) && e.setAttribute("href", "");
    },
    _sanitizeEvents: function (t) {
      t.removeAttr("onload onerror ontoggle onwheel onmouseover oncopy");
    },
    _encodeCode: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("pre code, pre, code").each(this._encodeNode.bind(this));
      }.bind(this));
    },
    _encodeNode: function (t) {
      var e = t.get(),
        i = e.firstChild,
        s = e.innerHTML;
      if ("PRE" !== e.tagName || !i || "CODE" !== i.tagName) {
        s = (s = s.replace(/xtagstartz/g, "<")).replace(/xtagendz/g, ">");
        var n = this.decodeEntities(s);
        e.textContent = this._encodeNodeHtml(n);
      }
    },
    _encodeNodeHtml: function (t) {
      return t = t.replace(/&nbsp;/g, " ").replace(/<br\s?\/?>/g, "\n"), t = this.opts.pre.spaces ? t.replace(/\t/g, new Array(this.opts.pre.spaces + 1).join(" ")) : t;
    }
  }), RedactorX.add("module", "autoparse", {
    parse: function (t) {
      if (!this.opts.paste.autoparse) return t;
      var e = this.app.block.get(),
        i = [];
      t = this.app.content.storeComments(t, i), t = this.app.content.removeDoctype(t);
      for (var s = ["figure", "html", "form", "pre", "div", "span", "iframe", "code", "a", "img", "link", "script"], n = ["div", "img", "html", "span"], a = [], o = 0, r = 0; r < s.length; r++) {
        var p = -1 !== n.indexOf(s[r]) ? "<" + s[r] + "[^>]*>" : "<" + s[r] + "[^>]*>([\\w\\W]*?)</" + s[r] + ">",
          l = t.match(new RegExp(p, "gi"));
        if (null !== l) for (var h = 0; h < l.length; h++) t = t.replace(l[h], "#####replaceparse" + o + "#####"), a.push(l[h]), o++;
      }
      if (!(t = t.replace("&amp;", "&")).match(this.opts.regex.aurl1) && !t.match(this.opts.regex.aurl2) || t.match(this.opts.regex.imageurl) || (t = this._formatLinks(t)), t.match(this.opts.regex.imageurl)) for (var c = t.match(this.opts.regex.imageurl), d = 0; d < c.length; d++) t = t.replace(c[d], this._splitBlock(e, '<img src="' + c[d] + '">'));
      return t = this._restoreReplaced(a, t), t = this.app.content.restoreComments(t, i), t = this._restoreReplaced(a, t);
    },
    _splitBlock: function (t, e) {
      return t ? e = "\n" + e + "\n" : e;
    },
    _formatLinks: function (t) {
      var s = !1 !== this.opts.paste.linkTarget ? ' target="' + this.opts.paste.linkTarget + '"' : "",
        n = this.opts.editor.https ? "https" : "http",
        a = this;
      return t = (t = t.replace(this.opts.regex.aurl1, function (t) {
        return '<a href="' + t + '"' + s + ">" + a._subLinkText(t) + "</a>";
      })).replace(this.opts.regex.aurl2, function (t, e, i) {
        return e + '<a href="' + n + "://" + i + '"' + s + ">" + a._subLinkText(i) + "</a>";
      });
    },
    _subLinkText: function (t) {
      return t = -1 === (t = t.length > this.opts.link.size ? t.substring(0, this.opts.link.size) + "..." : t).search("%") ? decodeURIComponent(t) : t;
    },
    _restoreReplaced: function (t, e) {
      for (var i = 0; i < t.length; i++) e = e.replace("#####replaceparse" + i + "#####", t[i]);
      return e;
    }
  }), RedactorX.add("module", "caret", {
    start: function () {
      this.win = this.app.editor.getWinNode(), this.doc = this.app.editor.getDocNode();
    },
    set: function (t, e) {
      var i = this.dom(t).get(),
        s = this.doc.createRange();
      i && this._isInPage(i) && (this.app.editor.setWinFocus(), this._isInline(i) && this._isNon(i) && ("start" === e ? e = "before" : "end" === e && (e = "after")), this[{
        start: "_setStart",
        end: "_setEnd",
        before: "_setBefore",
        after: "_setAfter"
      }[e]](s, i), this._setRange(s));
    },
    is: function (t, e, i, s, n) {
      var a = this.dom(t).get(),
        o = this.doc.getSelection(),
        r = !1;
      if (!a || !o.isCollapsed) return r;
      var p = this._getPosition(a, s, n),
        l = this._getSize(a, i, s);
      return "end" === e ? r = p === l : "start" === e && (r = 0 === p), r;
    },
    _setStart: function (t, e) {
      t.setStart(e, 0), t.collapse(!0);
      var i = this._getInlineInside(e);
      i && (t = this._setStartInline(t, i)), this._isInline(e) && this._insertInvisibleNode(t);
    },
    _setStartInline: function (t, e) {
      var i = this.app.element.getAllInlines(e)[0];
      t.selectNodeContents(i), t.collapse(!0);
    },
    _setEnd: function (t, e) {
      var i = 1 === e.nodeType && e.lastChild;
      i && this._isInline(i) && (e = i), t.selectNodeContents(e), t.collapse(!1);
    },
    _setBefore: function (t, e) {
      t.setStartBefore(e), t.collapse(!0), this._isInline(e) && this._insertInvisibleNode(t, e);
    },
    _setAfter: function (t, e) {
      t.setStartAfter(e), t.collapse(!0);
      var i = 3 !== e.nodeType && e.tagName.toLowerCase();
      !this._isInline(e) && "br" !== i && "svg" !== i || this._insertInvisibleNode(t);
    },
    _setRange: function (t) {
      var e = this.win.getSelection();
      e.removeAllRanges(), e.addRange(t);
    },
    _insertInvisibleNode: function (t, e) {
      var i = this.app.utils.createInvisibleChar();
      return e ? e.parentNode.insertBefore(i, e) : t.insertNode(i), t.selectNodeContents(i), t.collapse(!1), i;
    },
    _getInlineInside: function (t) {
      var e = t.firstChild;
      if (this._isInline(e)) {
        for (var i = e.firstChild; i;) {
          if (this._isInline(i)) return i;
          i = i.firstChild;
        }
        return e;
      }
    },
    _getSize: function (t, e, i) {
      var s,
        n = 3 === t.nodeType;
      if (e && 0 !== e.length) {
        var a = this.dom(t).clone();
        a.find(e.join(",")).remove(), s = a.html().trim();
      } else s = n ? t.textContent : t.innerHTML, s = n || !1 === i ? s : s.trim();
      return this._trimmed(s, n, i).length;
    },
    _getPosition: function (t, e, i) {
      var s = this.win.getSelection().getRangeAt(0),
        n = s.cloneRange(),
        a = document.createElement("div"),
        o = 3 === t.nodeType;
      n.selectNodeContents(t), n.setEnd(s.endContainer, s.endOffset), a.appendChild(n.cloneContents());
      var r = o || !1 === e ? a.innerHTML : a.innerHTML.trim(),
        p = -1 !== r.search(/<\/?br\s?\/?>$/g) ? 1 : 0;
      return !1 === i && (p = 0), (r = this._trimmed(r, o, e)).length + p;
    },
    _trimmed: function (t, e, i) {
      return !1 === i ? t = t.replace(/\n$/g, "") : ("" === (t = (t = (t = this.app.utils.removeInvisibleChars(t)).replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, "")).replace(/\s+/g, " ")) || e || (t = t.replace(/\s$/, "")), t);
    },
    _isInline: function (t) {
      return this.app.element.is(t, "inline");
    },
    _isInPage: function (t) {
      var e = !1;
      return t && t.nodeType && (e = t !== this.doc.body && this.doc.body.contains(t)), e;
    },
    _isNon: function (t) {
      return "false" === t.getAttribute("contenteditable");
    }
  }), RedactorX.add("module", "selection", {
    init: function () {
      this.savedSelection = !1, this.savedMarker = !1;
    },
    start: function () {
      this.win = this.app.editor.getWinNode(), this.doc = this.app.editor.getDocNode();
    },
    get: function () {
      var t = this._getSelection(),
        e = this._getRange(t),
        i = this._getCurrent(t);
      return {
        selection: t,
        range: e,
        collapsed: this._getCollapsed(t, e),
        current: i,
        parent: this._getParent(i)
      };
    },
    getRange: function () {
      return this._getRange(this.get().selection);
    },
    getNodes: function (t) {
      var e = this.get(),
        i = t && (t.type && "inline" === t.type || t.tags && -1 !== t.tags.indexOf("a")),
        s = i ? "_getAllRangeNodes" : "_getRangeNodes",
        n = [];
      return 0 < (n = this.app.editor.isAllSelected() ? this.app.editor.getLayout().children().getAll() : e.selection && e.range ? this[s](e.range) : n).length ? this._filterNodes(n, e.range, i, t) : n;
    },
    getCurrent: function () {
      var t = this._getSelection();
      return this._getCurrent(t);
    },
    getParent: function () {
      var t = this.getCurrent();
      return this._getParent(t);
    },
    getElement: function (t) {
      return this._getElement(t, "element");
    },
    getInline: function (t) {
      return this._getElement(t, "inline");
    },
    getTopInline: function (t) {
      for (var e = t ? this.dom(t).get() : this.getCurrent(), i = []; e && this._getElement(e, "inline");) i.push(e), e = e.parentNode;
      return i[i.length - 1];
    },
    getDataBlock: function (t) {
      var e = this._getSelection(),
        i = t || this._getCurrent(e);
      if (i) for (i = this.dom(i).get(); i;) {
        if (1 === i.nodeType && i.getAttribute("data-" + this.prefix + "-type")) return this.dom(i);
        i = i.parentNode;
      }
      return this.dom();
    },
    getBlock: function (t) {
      return this._getElement(t, "block");
    },
    getText: function (t, e) {
      var i = this.get(),
        s = !1;
      if (!i.selection) return !1;
      if (t && i.range) {
        e = void 0 === e ? 1 : e;
        var n = this.app.editor.getEditor().get(),
          a = i.range.cloneRange();
        "before" === t ? (a.collapse(!0), a.setStart(n, 0), s = a.toString().slice(-e)) : "after" === t && (a.selectNodeContents(n), a.setStart(i.range.endContainer, i.range.endOffset), s = a.toString().slice(0, e));
      } else s = i.selection ? i.selection.toString() : "";
      return s;
    },
    getHtml: function () {
      var t = "",
        e = this.get();
      if (e.selection) {
        var i = e.range.cloneContents(),
          s = document.createElement("div");
        s.appendChild(i), t = (t = s.innerHTML).replace(/<p><\/p>$/i, "");
      }
      return t;
    },
    getPosition: function () {
      var t = this.getRange(),
        e = {
          top: 0,
          left: 0,
          width: 0,
          height: 0
        };
      if (this.win.getSelection && t.getBoundingClientRect) {
        var i = (t = t.cloneRange()).startOffset - 1;
        t.setStart(t.startContainer, i < 0 ? 0 : i);
        var s = t.getBoundingClientRect();
        e = {
          top: s.top,
          bottom: s.bottom,
          left: s.left,
          width: s.right - s.left,
          height: s.bottom - s.top
        };
      }
      return e;
    },
    set: function (t, e) {
      t && (t.removeAllRanges(), t.addRange(e));
    },
    setRange: function (t) {
      this.set(this.win.getSelection(), t);
    },
    is: function (t) {
      if (void 0 === t) return this.get().selection;
      for (var e = this.dom(t).get(), i = this.getNodes(), s = 0; s < i.length; s++) if (i[s] === e) return !0;
      return !1;
    },
    isCollapsed: function () {
      var t = this.get();
      return this._getCollapsed(t.selection, t.range);
    },
    isIn: function (t) {
      var e = this.dom(t).get(),
        i = this.getCurrent();
      return !(!i || !e) && e.contains(i);
    },
    isAll: function (t) {
      var e = !t,
        i = t ? this.dom(t).get() : this.app.editor.getLayout().get(),
        s = this.win.getSelection(),
        n = this._getRange(s),
        a = e || this.is(i);
      return !s.isCollapsed && !!a && void 0 !== i.textContent && i.textContent.trim().length === n.toString().trim().length;
    },
    select: function (t) {
      var e = t ? this.dom(t).get() : this.app.editor.getLayout().get(),
        i = this.doc.createRange();
      i.selectNodeContents(e), this.setRange(i);
    },
    removeAllRanges: function () {
      var t = this._getSelection();
      t && t.removeAllRanges();
    },
    deleteContents: function () {
      var t = this.getRange();
      !this.isCollapsed() && t && t.deleteContents();
    },
    collapse: function (t) {
      t = t || "start";
      var e = this.get();
      e.selection && !e.collapsed && ("start" === t ? e.selection.collapseToStart() : e.selection.collapseToEnd());
    },
    save: function (t) {
      if (!t) {
        var e = this.app.block.get();
        t = e ? e.getBlock() : this.app.editor.getEditor();
      }
      this.savedSelection = {
        el: t,
        offset: this.app.offset.get(t)
      };
    },
    restore: function (t) {
      if (!this.savedMarker && this.savedSelection) {
        this.app.editor.setWinFocus();
        var e = this.savedSelection.el;
        this.dom(e).dataget("instance") && !1 !== t && this.app.block.set(e), e && (e.focus(), this.app.offset.set(e, this.savedSelection.offset)), this.savedSelection = !1;
      }
    },
    saveMarker: function () {
      this.savedMarker = !0, this.app.marker.insert();
    },
    restoreMarker: function () {
      this.app.marker.restore(), this.savedMarker = !1, this.savedSelection = !1;
    },
    _getSelection: function () {
      return this.app.editor.getSelection();
    },
    _getRange: function (t) {
      return !!t && 0 < t.rangeCount && t.getRangeAt(0);
    },
    _getCurrent: function (t) {
      return !!t && t.anchorNode;
    },
    _getParent: function (t) {
      return !!t && t.parentNode;
    },
    _getElement: function (t, e) {
      var i = this._getSelection();
      if (i) {
        var s = t || this._getCurrent(i);
        for (s = this.dom(s).get(); s;) {
          if (this.app.element.is(s, e)) return s;
          s = s.parentNode;
        }
      }
      return !1;
    },
    _getCollapsed: function (t, e) {
      var i = !1;
      return t && t.isCollapsed ? i = !0 : e && 0 === e.toString().length && (i = !0), i;
    },
    _getNextNode: function (t) {
      if (t.firstChild) return t.firstChild;
      for (; t;) {
        if (t.nextSibling) return t.nextSibling;
        t = t.parentNode;
      }
    },
    _getRangeNodes: function (t, e) {
      var i,
        s = t.startContainer.childNodes[t.startOffset] || t.startContainer,
        n = t.endContainer.childNodes[t.endOffset] || t.endContainer,
        a = t.commonAncestorContainer,
        o = [];
      if (e) {
        for (this.app.editor.isLayout(s) || o.push(s), i = s.parentNode; i && !this.app.editor.isLayout(i) && (o.push(i), i !== a); i = i.parentNode);
        for (o.reverse(), i = s; i && (3 === i.nodeType || 0 !== this.dom(i.parentNode).closest(a).length) && (o.push(i), i !== n); i = this._getNextNode(i));
      } else for (3 === s.nodeType && o.push(this.getBlock(s)), i = s; i && i !== a && (3 === i.nodeType || 0 !== this.dom(i.parentNode).closest(a).length) && (o.push(i), i !== n); i = this._getNextNode(i));
      return o;
    },
    _getAllRangeNodes: function (t) {
      return this._getRangeNodes(t, !0);
    },
    _filterNodes: function (t, e, i, s) {
      var n = this.getText();
      n = n ? n.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&") : "";
      for (var a = [], o = 0; o < t.length; o++) {
        var r = !0;
        this.app.editor.isLayout(t[o]) && (r = !1), s && (r = s.types ? this._filterByTypes(r, s, t[o]) : r, r = s.selected ? this._filterBySelected(r, s, t[o], e, n) : r, r = s.type ? this._filterByType(r, s, t[o], i) : r, r = s.tags ? this._filterByTags(r, s, t[o]) : r), r && a.push(t[o]);
      }
      var p = [];
      if (s && ("blocks" === s.type || "blocks-first" === s.type)) {
        for (var l = 0; l < a.length; l++) {
          var h;
          "blocks-first" === s.type ? h = this.app.element.is(a[l], "blocks-first") ? a[l] : this.app.element.getFirstLevel(a[l]).get() : "blocks" === s.type && (h = this.app.element.is(a[l], "blocks") ? a[l] : this.app.element.getDataBlock(a[l]).get()), this._isInNodesArray(p, h) || p.push(h);
        }
        a = p;
      }
      return a;
    },
    _filterByTypes: function (t, e, i) {
      var s;
      return !0 === e.types ? (s = this.app.element.getType(i)) || (t = !1) : (s = this.app.element.getType(i), -1 === e.types.indexOf(s) && (t = !1)), t;
    },
    _filterByType: function (t, e, i, s) {
      var n = e.type;
      return "blocks" !== n && "blocks-first" !== n || (n = "block"), s ? e.links ? this.app.element.is(i, n) || (t = !1) : (1 !== i.nodeType || "A" !== i.tagName) && this.app.element.is(i, n) || (t = !1) : this.app.element.is(i, n) || (t = !1), t;
    },
    _filterByTags: function (t, e, i) {
      var s = void 0 !== i.tagName;
      return s ? s && -1 === e.tags.indexOf(i.tagName.toLowerCase()) && (t = !1) : t = !1, t;
    },
    _filterBySelected: function (t, e, i, s, n) {
      return !0 !== e.selected || this._containsNodeText(s, i) ? "inside" === e.selected && (1 === i.nodeType && "A" === i.tagName ? t = !0 : this._isTextSelected(i, n) || (t = !1)) : t = !1, t;
    },
    _isTextSelected: function (t, e) {
      var i = 9 !== t.nodeType ? this.app.utils.removeInvisibleChars(t.textContent) : "";
      return e === i || -1 !== i.search(e) || -1 !== e.search(new RegExp("^" + this.app.utils.escapeRegExp(i) + "$"));
    },
    _isBackwards: function () {
      var t = !1,
        e = this.get();
      if (e && !e.collapsed) {
        var i = this.doc.createRange();
        i.setStart(e.selection.anchorNode, e.selection.anchorOffset), i.setEnd(e.selection.focusNode, e.selection.focusOffset), t = i.collapsed, i.detach();
      }
      return t;
    },
    _containsNodeText: function (t, e) {
      for (var i, s, n, a = this.doc.createTreeWalker(e, NodeFilter.SHOW_TEXT, {
          acceptNode: function (t) {
            return NodeFilter.FILTER_ACCEPT;
          }
        }, !1); n = a.nextNode();) i = i || n, s = n;
      var o = t.cloneRange();
      return i ? (o.setStart(i, 0), o.setEnd(s, s.length)) : o.selectNodeContents(e), t.compareBoundaryPoints(Range.START_TO_START, o) < 1 && -1 < t.compareBoundaryPoints(Range.END_TO_END, o);
    },
    _isInNodesArray: function (t, e) {
      return -1 !== t.indexOf(e);
    }
  }), RedactorX.add("module", "popup", {
    init: function () {
      this.stack = !1, this.stacks = [], this.name = !1, this.control = !1;
    },
    start: function () {
      this._build(), this._buildDepth();
    },
    stop: function () {
      this._stopEvents(), this._stop();
    },
    isOpen: function (t) {
      var e = this.$popup.hasClass("open");
      return t ? this._getName() === t && e : e;
    },
    create: function (t, e) {
      return this.isOpen(t) || (this._reset(), this.name = t, this.stack = this._createStack(t, e)), this.stack;
    },
    add: function (t, e) {
      return this._createStack(t, e, !0);
    },
    setStack: function (t) {
      this.stack = t;
    },
    setData: function (t) {
      this.stack.setData(t);
    },
    setFocus: function (t) {
      this.stack.setFocus(t);
    },
    setWidth: function (t) {
      this.stack.setWidth(t);
    },
    getName: function () {
      return this.name;
    },
    getElement: function () {
      return this.$popup;
    },
    getButton: function () {
      return this.button;
    },
    getStack: function (t) {
      return t ? this.stacks[t] : this.stack;
    },
    getBody: function () {
      return this.stack.getBody();
    },
    getItems: function () {
      return this.stack.getItems();
    },
    getFooter: function () {
      return this.stack.getFooter();
    },
    getFooterPrimary: function () {
      return this.stack.getFooterPrimary();
    },
    getTool: function (t) {
      return this.stack.getTool(t);
    },
    getInput: function (t) {
      return this.stack.getInput(t);
    },
    getFormItem: function (t) {
      return this.stack.getFormItem(t);
    },
    getData: function (t) {
      return this.stack.getData(t);
    },
    open: function (t) {
      this.isOpen() ? this.isOpen(this.name) ? this.close(!1) : (this.close(!1), this._open(t, !1)) : this._open(t);
    },
    openStack: function (t) {
      var e = this.getStack(t),
        i = {};
      this.stack && this.stack.isCollapsed() && (i = {
        collapse: !0
      }, this.removeStack(this.stack)), e.open(i);
    },
    close: function (t) {
      this.isOpen() && (t && this._isPopupTarget(t) || (this._stopEvents(), this._resetToolbarToggledButton(), !1 !== t && (this.app.scroll.save(), this.app.selection.restore(), this.app.scroll.restore()), this.$popup.hide(), this._closed()));
    },
    closeStacks: function () {
      for (var t in this.stacks) "object" == typeof this.stacks[t] && this.stacks[t].close();
    },
    removeStack: function (t) {
      var e = t.getName();
      delete this.stacks[e], this.$popup.find("[data-" + this.prefix + "-stack-name=" + e + "]").remove();
    },
    updatePosition: function (t) {
      this._buildPosition(t), this._buildHeight();
    },
    resize: function () {
      var t = this.$popup.attr("data-width"),
        e = this.app.editor.getWidth();
      if ("100%" !== t && parseInt(t) < e) return;
      this.$popup.css("width", e + "px");
    },
    _build: function () {
      this.$popup = this.dom("<div>").addClass(this.prefix + "-popup " + this.prefix + "-popup-" + this.uuid), this.$popup.hide(), this.$popup.attr("dir", this.opts.editor.direction), this.app.$body.append(this.$popup);
    },
    _buildDepth: function () {
      this.opts.bsmodal && this.$popup.css("z-index", 1052);
    },
    _buildButton: function (t) {
      t && (this.button = !!Object.prototype.hasOwnProperty.call(t, "button") && t.button);
    },
    _buildControl: function (t) {
      t && (this.control = !!Object.prototype.hasOwnProperty.call(t, "control") && t.control);
    },
    _buildName: function () {
      this.$popup.attr("data-" + this.prefix + "-popup-name", this.name), this.$popup.addClass(this.prefix + "-popup-" + this.name);
    },
    _buildHeader: function () {
      this.header = this.app.create("popup.header", this);
    },
    _buildHeight: function () {
      var t,
        e = this.app.scroll.getTarget(),
        i = this.$popup.offset(),
        s = (this.app.scroll.isTarget() ? (t = i.top - e.offset().top, e.height() - parseInt(e.css("border-bottom-width"))) : (t = i.top - e.scrollTop(), e.height())) - t - 10;
      this.$popup.css("max-height", s + "px");
    },
    _buildPosition: function () {
      var t;
      t = this._isButton() && this.button.isControl() || this._isControl() ? this._buildPositionControl() : this._isButton() ? this._buildPositionButton() : this._buildPositionModal(), this.$popup.css({
        top: t.top - 1 + "px",
        left: t.left + "px"
      });
    },
    _buildPositionButton: function () {
      var t = this.app.editor.getRect(),
        e = this.button.getOffset(),
        i = this.button.getDimension(),
        s = this.$popup.width(),
        n = {};
      return this._isToolbarButton() || this._isTopbarButton() ? (n = {
        top: e.top + i.height,
        left: e.left
      }).left + s > t.right && (n.left = e.left + i.width - s) : (n = {
        top: e.top + t.top + i.height,
        left: e.left + t.left + i.width / 2 - s / 2
      }).left + s > t.right && (n.left = t.left + t.width - s), (n.left < t.left || n.left < 0) && (n.left = t.left), n;
    },
    _buildPositionControl: function () {
      var t = this.app.block.get();
      t.isSecondLevel() && (t = t.getFirstLevel());
      var e = t.getBlock().offset();
      return {
        top: e.top,
        left: e.left
      };
    },
    _buildPositionModal: function () {
      var t, e, i;
      if (this.opts.toolbar) {
        var s = this.app.container.get("toolbar"),
          n = s.height();
        e = (t = s.offset()).top + n, i = t.left;
      } else {
        var a = this.app.block.get();
        a.isSecondLevel() && (a = a.getFirstLevel()), e = (t = a.getBlock().offset()).top, i = t.left;
      }
      return {
        top: e,
        left: i
      };
    },
    _getName: function () {
      return this.$popup.attr("data-" + this.prefix + "-popup-name");
    },
    _setToolbarToggledButton: function () {
      if (this.app.toolbar.unsetToggled(), this._isToolbarButton()) {
        var t = this.button.getName();
        this.app.toolbar.setToggled(t);
      }
    },
    _createStack: function (t, e, i) {
      Object.prototype.hasOwnProperty.call(e, "collapse") && !1 === e.collapse && (i = !1);
      var s = this.app.create("popup.stack", t, e, i, this);
      return this.stacks[t] = s;
    },
    _open: function (t, e) {
      this._buildButton(t), this._buildControl(t), this._buildName(), this._buildHeader(), this.app.broadcast("popup.before.open").isStopped() || (this._setToolbarToggledButton(), this._startEvents(), !1 !== e && this.app.editor.isPopupSelection() && this.app.selection.save(), this.stack = this._findActiveStack(), this.stack.open(t), this._buildPosition(), !1 === e ? (this.$popup.show(), this._opened()) : this.$popup.fadeIn(100, this._opened.bind(this)));
    },
    _opened: function () {
      this._buildHeight(), this.$popup.addClass("open"), this.app.broadcast("popup.open"), this.stack.renderFocus();
    },
    _closed: function () {
      this.$popup.removeAttr("data-" + this.prefix + "-popup-name"), this.$popup.removeClass("open"), this.app.broadcast("popup.close");
    },
    _isPopupTarget: function (t) {
      return 0 !== this.dom(t.target).closest("." + this.prefix + "-popup").length;
    },
    _isButton: function () {
      return this.button;
    },
    _isControl: function () {
      return this.control;
    },
    _isToolbarButton: function () {
      return this.button && "toolbar" === this.button.type;
    },
    _isTopbarButton: function () {
      return this.button && "topbar" === this.button.type;
    },
    _findActiveStack: function () {
      for (var t in this.stacks) "object" == typeof this.stacks[t] && this.stacks[t].isActive() && (this.stack = this.stacks[t]);
      return this.stack;
    },
    _reset: function () {
      this.button = !1, this.control = !1, this.stack = !1, this.stacks = [], this.$popup.html(""), this.$popup.removeClass("has-footer has-items has-form " + this.prefix + "-popup-" + this.name);
    },
    _resetToolbarToggledButton: function () {
      if (this.button) {
        var t = this.button.getName();
        this.app.toolbar.unsetToggled(t);
      }
    },
    _startEvents: function () {
      var t = this.prefix + "-popup";
      this.app.scroll.getTarget().on("resize." + t + " scroll." + t, this.updatePosition.bind(this));
    },
    _stopEvents: function () {
      this.app.scroll.getTarget().off("." + this.prefix + "-popup");
    },
    _stop: function () {
      this.$popup && this.$popup.remove();
    }
  }), RedactorX.add("class", "popup.item", {
    defaults: {
      container: !1,
      title: !1,
      html: !1,
      toggle: !0,
      active: !1,
      divider: !1,
      remover: !1,
      classname: !1,
      params: !1,
      instance: !1,
      observer: !1,
      command: !1
    },
    init: function (t, e, i) {
      this.popup = t, this.name = e, this.params = this._buildParams(i), this._build(), this._buildContainer(), this._buildIcon(), this._buildTitle(), this._buildImage(), this._buildShortcut(), this._buildActive(), this._buildHidden(), this._buildDivider(), this._buildCommand(), this._buildRemover();
    },
    getPopup: function () {
      return this.popup;
    },
    getName: function () {
      return this.name;
    },
    getParams: function () {
      return this.params.params;
    },
    getElement: function () {
      return this.$item;
    },
    getInstance: function () {
      return this.params.instance;
    },
    isControl: function () {
      return this.params.control;
    },
    _build: function () {
      this.$item = this.params.html ? this.dom(this.params.html) : this.dom("<div>"), this.$item.addClass(this.prefix + "-popup-item " + this.prefix + "-popup-stack-item"), this.$item.attr({
        "data-name": this.name
      });
    },
    _buildContainer: function () {
      this.params.container && this.$item.addClass(this.prefix + "-popup-item-container");
    },
    _buildTitle: function () {
      this.params.title && (this.$title = this.dom("<span>").addClass(this.prefix + "-popup-item-title"), this.$title.html(this.lang.parse(this.params.title)), this.$item.append(this.$title));
    },
    _buildImage: function () {
      this.params.image && (this.$image = this.dom("<span>").addClass(this.prefix + "-popup-item-image"), this.$image.html(this.params.image), this.$item.append(this.$image));
    },
    _buildIcon: function () {
      this.params.icon && (this.$icon = this.dom("<span>").addClass(this.prefix + "-popup-item-icon"), !0 === this.params.icon ? this.$icon.addClass(this.prefix + "-icon-" + this.name) : -1 !== this.params.icon.search(/</) ? this.$icon.html(this.params.icon) : this.$icon.addClass(this.prefix + "-icon-" + this.params.icon), this.$item.append(this.$icon));
    },
    _buildShortcut: function () {
      if (this.params.shortcut) {
        var t = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? "<b>&#8984;</b>" : "ctrl";
        t = this.params.shortcut.replace("Ctrl", t), this.$shortcut = this.dom("<span>").addClass(this.prefix + "-popup-item-shortcut"), this.$shortcut.html(t), this.$item.append(this.$shortcut);
      }
    },
    _buildParams: function (t) {
      return $R.extend({}, !0, this.defaults, t);
    },
    _buildActive: function () {
      this.params.active && this.$item.addClass("active");
    },
    _buildHidden: function () {
      this.params.hidden && this.$item.addClass(this.prefix + "-popup-item-hidden");
    },
    _buildDivider: function () {
      this.params.divider && this.$item.addClass(this.prefix + "-popup-item-divider-" + this.params.divider);
    },
    _buildCommand: function () {
      this.params.command && (this.$item.on("click." + this.prefix + "-popup-item-" + this.uuid, this._catch.bind(this)), this.$item.attr("data-command", this.params.command));
    },
    _buildRemover: function () {
      if (this.params.title && this.params.remover) {
        var t = this.dom("<span>").addClass(this.prefix + "-popup-item-trash " + this.prefix + "-icon-trash");
        t.attr("data-command", this.params.remover), t.on("click." + this.prefix + "-popup-item-" + this.uuid, this._catchRemover.bind(this)), this.$item.append(t);
      }
    },
    _catchRemover: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).closest("." + this.prefix + "-popup-stack-item"),
        i = this.dom(t.target).closest("." + this.prefix + "-popup-item-trash").attr("data-command"),
        s = e.attr("data-name");
      this.app.api(i, this, s), e.fadeOut(200, function (t) {
        t.remove();
      });
    },
    _catch: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).closest("." + this.prefix + "-popup-stack-item"),
        i = e.attr("data-name"),
        s = e.attr("data-command");
      this.popup.$items.find("." + this.prefix + "-popup-stack-item").removeClass("active"), this.params.toggle && e.addClass("active"), this.app.api(s, this.getParams(), this, i, t);
    }
  }), RedactorX.add("class", "popup.stack", {
    defaults: {
      active: !1,
      title: !1,
      type: !1,
      width: !1,
      setter: !1,
      getter: !1,
      builder: !1,
      observer: !1,
      instance: !1,
      collapse: !1,
      form: !1,
      items: !1,
      focus: !1,
      footer: !1
    },
    init: function (t, e, i, s) {
      this.defaultWidth = "240px", this.popup = s, this.name = t, this.tools = {}, this.data = !1, this.items = !1, this.formitems = !1, this.params = $R.extend({}, !0, this.defaults, e), i && (this.params.collapse = !0), this._build(), this._observe();
    },
    set: function (t, e) {
      this.params[t] = e;
    },
    setData: function (t) {
      this.data = t;
    },
    setFocus: function (t) {
      void 0 !== this.tools[t] && this.tools[t].setFocus();
    },
    setWidth: function (t) {
      var e = this.app.popup.getElement();
      e.attr("data-width", t), "100%" === t && (t = this.app.editor.getWidth() + "px"), e.css("width", t), this.app.$win.on("resize." + this.prefix + "-popup-" + this.uuid, this.popup.resize.bind(this.popup)), this.popup.resize();
    },
    get: function (t) {
      return this.params[t];
    },
    getElement: function () {
      return this.$stack;
    },
    getName: function () {
      return this.name;
    },
    getBody: function () {
      return this.$body;
    },
    getInstance: function () {
      return this.get("instance");
    },
    getItems: function () {
      return this.$items;
    },
    getFooter: function () {
      return this.$footer;
    },
    getFooterPrimary: function () {
      return this.$footer.find("." + this.prefix + "-form-button-primary");
    },
    getTool: function (t) {
      return void 0 !== this.tools[t] && this.tools[t];
    },
    getInput: function (t) {
      var e = this.getTool(t);
      return e ? e.getInput() : this.dom();
    },
    getFormItem: function (t) {
      var e = this.getTool(t);
      return e ? e.getInput().closest("." + this.prefix + "-form-item") : this.dom();
    },
    getData: function (t) {
      var e;
      if (t) void 0 !== this.tools[t] && (e = this.tools[t].getValue());else for (var i in e = {}, this.tools) e[i] = this.tools[i].getValue();
      return e;
    },
    hasForm: function () {
      return this.formitems;
    },
    hasFooter: function () {
      return 0 !== this.footerbuttons;
    },
    hasItems: function () {
      return !1 !== this.items;
    },
    isCollapsed: function () {
      return this.get("collapse");
    },
    isActive: function () {
      return this.get("active");
    },
    open: function (t, e) {
      t && t.focus && this.set("focus", t.focus), this.popup.closeStacks(), t && t.collapse ? (this._buildItems(), this._renderItems()) : this.render(), this.popup.header.render(this.popup.stacks), this.popup.header.setActive(this), this.$stack.show(), this._renderWidth(), !1 !== e && this.renderFocus(), this.app.popup.setStack(this);
    },
    close: function () {
      this.$stack.hide();
    },
    collapse: function () {
      var t = this._getPrev();
      this.isCollapsed() && this.popup.removeStack(this), t.open({
        collapse: !0
      });
    },
    render: function () {
      this._renderType(), this._renderItems(), this._renderForm(), this._renderFooter(), this._renderEnv();
    },
    renderFocus: function () {
      this.get("focus") && this.setFocus(this.get("focus"));
    },
    _observe: function () {
      this.params.observer && this.app.api(this.params.observer, this);
    },
    _getPrev: function () {
      var t;
      for (var e in this.popup.stacks) {
        if (e === this.name) return t;
        t = this.popup.stacks[e];
      }
    },
    _build: function () {
      this._buildElement(), this._buildBody(), this._buildFooter(), this._buildParams();
    },
    _buildElement: function () {
      this.$stack = this.dom("<div>").addClass(this.prefix + "-popup-stack " + this.prefix + "-popup-stack-" + this.name), this.$stack.hide(), this.$stack.attr("data-" + this.prefix + "-stack-name", this.name), this.popup.getElement().append(this.$stack);
    },
    _buildBody: function () {
      this.$body = this.dom("<div>").addClass(this.prefix + "-popup-body"), this.$stack.append(this.$body);
    },
    _buildFooter: function () {
      this.$footer = this.dom("<div>").addClass(this.prefix + "-popup-footer"), this.$stack.append(this.$footer);
    },
    _buildParams: function () {
      this.params.width = this.params.width ? this.params.width : this.defaultWidth, this.params.setter = !!this.params.setter && this.params.setter, this.params.getter = !!this.params.getter && this.params.getter, this.data = !!this.params.getter && this.app.api(this.params.getter, this), this._buildItems();
    },
    _buildItems: function () {
      this.params.builder ? this.items = this.app.api(this.params.builder, this) : this.params.items && (this.items = this.params.items);
    },
    _renderWidth: function () {
      this.setWidth(this.get("width"));
    },
    _renderType: function () {
      this.$stack.removeClass(this.prefix + "-popup-type-grid");
      var t = this.get("type");
      t && this.$stack.addClass(this.prefix + "-popup-type-" + t);
    },
    _renderItems: function () {
      if (this.items) for (var t in this.$items ? this.$items.html("") : (this.$items = this.dom("<div>").addClass(this.prefix + "-popup-items"), this.$body.append(this.$items)), this.items) {
        if (Object.prototype.hasOwnProperty.call(this.items[t], "observer") && this.items[t].observer) {
          var e = this.app.api(this.items[t].observer, this.items[t], t, this);
          void 0 !== e && (this.items[t] = e);
        }
        if (!1 !== this.items[t]) {
          var i = this.app.create("popup.item", this, t, this.items[t]).getElement();
          this._renderItemPosition(this.$items, i, this.items[t]);
        }
      }
    },
    _renderItemPosition: function (t, e, i) {
      if (i.position) {
        var s = i.position;
        if ("first" === s) t.prepend(e);else if ("object" == typeof s) {
          var n = Object.prototype.hasOwnProperty.call(s, "after") ? "after" : "before",
            a = s[n],
            o = this._findPositionElement(a, t);
          o ? o[n](e) : t.append(e);
        }
      } else t.append(e);
    },
    _renderEnv: function () {
      var t = this.popup.getElement();
      t.removeClass("has-footer has-items has-form"), this.hasForm() && t.addClass("has-form"), this.hasFooter() && t.addClass("has-footer"), this.hasItems() && t.addClass("has-items");
    },
    _renderForm: function () {
      this.formitems = this.get("form"), this.formitems && (this.$form ? this.$form.html("") : (this.$form = this.dom("<form>").addClass(this.prefix + "-popup-form"), this.$body.append(this.$form)), this._renderTools(), this._renderData());
    },
    _renderTools: function () {
      for (var t in this.formitems) this._renderTool(t, this.formitems[t]);
    },
    _renderTool: function (t, e) {
      var i = this.app.create("tool." + e.type, t, e, this, this.data),
        s = i.getElement();
      s && (this.tools[t] = i, this.$form.append(s));
    },
    _renderData: function () {
      if (this.data) for (var t in this.data) void 0 !== this.tools[t] && this.tools[t].setValue(this.data[t]);
    },
    _renderFooter: function () {
      this.footerbuttons = 0;
      var t = this.get("footer");
      if (t) for (var e in this.$footer.html(""), t) if (!1 !== t[e]) {
        var i = this.app.create("popup.button", e, this, t[e]);
        this.$footer.append(i.getElement()), this.footerbuttons++;
      }
    },
    _findPositionElement: function (t, e) {
      var i;
      if (Array.isArray(t)) for (var s = 0; s < t.length && 0 === (i = e.find("[data-name=" + t[s] + "]")).length; s++);else i = e.find("[data-name=" + t + "]");
      return 0 !== i.length ? i : 0;
    }
  }), RedactorX.add("class", "popup.button", {
    init: function (t, e, i) {
      this.name = t, this.obj = i, this.popup = e, this.$button = this.dom("<button>").addClass(this.prefix + "-form-button"), this.$button.attr("data-name", this.name), this.$button.html(this.lang.parse(this.obj.title)), this.$button.dataset("instance", this), this._has("type") && this.$button.addClass(this.prefix + "-form-button-" + this.obj.type), this._has("classname") && this.$button.addClass(this.obj.classname), this._has("fullwidth") && this.$button.addClass(this.prefix + "-form-button-fullwidth"), this._has("right") && this.$button.addClass(this.prefix + "-form-button-push-right"), this.$button.on("click." + this.prefix + "-popup-button" + this.uuid, this._catch.bind(this));
    },
    getName: function () {
      return this.name;
    },
    getElement: function () {
      return this.$button;
    },
    invokeCommand: function () {
      this._invoke();
    },
    _has: function (t) {
      return Object.prototype.hasOwnProperty.call(this.obj, t);
    },
    _catch: function (t) {
      t.preventDefault(), t.stopPropagation(), this._has("command") ? this._invoke(t) : this._has("close") && this.app.popup.close();
    },
    _invoke: function (t) {
      this.app.api(this.obj.command, this.popup, this.name, t);
    }
  }), RedactorX.add("class", "popup.header", {
    init: function (t) {
      this.popup = t, this._build();
    },
    setActive: function (t) {
      this.$headerbox.find("." + this.prefix + "-popup-header-item").removeClass("active"), this.$headerbox.find("[data-" + this.prefix + "-name=" + t.getName() + "]").addClass("active");
    },
    render: function (t) {
      this._reset(), 0 < this._buildItems(t) && this._buildClose();
    },
    _build: function () {
      this.$header = this.dom("<div>").addClass(this.prefix + "-popup-header"), this.$headerbox = this.dom("<div>").addClass(this.prefix + "-popup-header-box"), this.$header.append(this.$headerbox), this.popup.getElement().prepend(this.$header);
    },
    _buildClose: function () {
      var t = this.dom("<span>").addClass(this.prefix + "-popup-close");
      t.one("click", this._catchClose.bind(this)), this.$header.append(t);
    },
    _buildItems: function (t) {
      var e = Object.keys(t).length,
        i = 0,
        s = 0;
      for (var n in t) if ("object" == typeof t[n]) {
        s++;
        var a = t[n].get("title");
        a ? (i++, this._buildItem(t[n], a, e)) : 1 === s && 1 < e && (i++, this._buildItem(t[n], "## popup.back ##", e));
      }
      return i;
    },
    _buildItem: function (t, e, i) {
      var s = 1 < i,
        n = s ? this.dom("<a>").attr("href", "#") : this.dom("<span>");
      s && (n.dataset("stack", t), n.addClass(this.prefix + "-popup-header-item-link"), n.on("click", this._catchStack.bind(this))), n.attr("data-" + this.prefix + "-name", t.getName()), n.addClass(this.prefix + "-popup-header-item"), n.html(this.lang.parse(e)), this.$headerbox.append(n);
    },
    _reset: function () {
      this.$headerbox.html(""), this.$header.find("." + this.prefix + "-popup-close").remove();
    },
    _catchStack: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).dataget("stack"),
        i = this.app.popup.getStack();
      i.isCollapsed() && this.app.popup.removeStack(i), e.open();
    },
    _catchClose: function (t) {
      t.preventDefault(), t.stopPropagation(), this.popup.close();
    }
  }), RedactorX.add("module", "editor", {
    start: function () {
      this._buildEditor(), this._buildBlurClass(), this._buildOptions(), this._buildContent();
    },
    stop: function () {
      this.app.$element.show();
    },
    load: function () {
      this._setFocusOnStart();
    },
    build: function () {
      this.app.blocks.build(), this.app.embed.build(), this.app.image.observeStates(), this.app.parser.buildPredefinedClasses(), this.app.broadcast("editor.build");
    },
    isEditor: function (t) {
      return this.dom(t).get() === this.$editor.get();
    },
    isLayout: function (t) {
      return this.isEditor(t);
    },
    isTextarea: function () {
      return !1 === this.opts.content;
    },
    isFocus: function () {
      return this.app.container.isFocus();
    },
    isAllSelected: function () {
      return this._classSelect("has");
    },
    isEmpty: function (t) {
      return this.app.content.isEmptyHtml(this.$editor.html(), t);
    },
    isPopupSelection: function () {
      return !this.app.blocks.isMeta();
    },
    addButton: function (t, e) {
      this.opts.buttons.editor[t] = e;
    },
    selectAll: function () {
      if (!this.isAllSelected()) {
        this._classSelect("add"), this.app.block.unset(), this.app.blocks.unset(), this.app.toolbar.build(), this.app.control.close(), this.app.context.close();
        var t = this.app.blocks.getLast(),
          e = !1;
        t && "layer" === t.getType() && (e = t.getBlock()).attr("contenteditable", !0), this.app.selection.select(), e && setTimeout(function () {
          e.attr("contenteditable", !1);
        }, 0);
      }
    },
    unselectAll: function () {
      this.isAllSelected() && (this.unsetSelectAll(), this.app.block.unset(), this.app.blocks.unset());
    },
    unsetSelectAll: function () {
      this._classSelect("remove");
    },
    observeBlocks: function (t) {
      this.blocks = [];
      var e = this.app.blocks.getSelectedBlock(t);
      this.app.editor.setFocus();
      var i = this.app.blocks.getSelectedBlocks();
      1 === i.length ? this.app.block.set(e) : this.app.selection.isAll() ? this.app.editor.selectAll() : 1 < i.length ? (this.app.block.unset(), this.app.blocks.set(i), this.app.toolbar.build()) : this.app.block.set(e);
    },
    insertContent: function (t) {
      this.app.insertion.insertContent(t);
    },
    setContent: function (t) {
      this.app.insertion.setContent(t);
    },
    setEmpty: function () {
      this.app.insertion.setContent({
        html: ""
      });
    },
    setWinFocus: function () {},
    setFocus: function (t) {
      if (t) {
        var e = !0 === t ? "start" : t,
          i = "start" === e ? this.app.blocks.getFirst() : this.app.blocks.getLast();
        this.app.block.set(i, e);
      } else {
        if (this.isFocus()) return;
        for (var s = 0; s < $R.instances.length; s++) $R.instances[s] !== this.app && $R.instances[s].editor.setBlur();
        this.app.container.setFocus(), this.app.broadcast("editor.focus");
      }
    },
    setBlur: function (t) {
      this.isFocus() && (this.app.broadcast("editor.before.blur", {
        e: t
      }).isStopped() ? t && t.preventDefault() : (this.app.container.setBlur(), this.app.selection.removeAllRanges(), this.app.block.unset(), this.app.blocks.unset(), this.app.popup.close(!1), this.app.context.close(), this.app.control.close(), this.app.toolbar.build(), this.app.broadcast("editor.blur", {
        e: t
      })));
    },
    getContent: function (t) {
      var e = "";
      return e = this.app.source.is() ? this.app.source.getContent() : (e = this._getContent(), t ? this.app.tidy.parse(e) : e), e = this.app.content.decodeHref(e);
    },
    getSelection: function () {
      var t = this.getWinNode().getSelection();
      if (0 < t.rangeCount) {
        var e = t.anchorNode;
        return 0 !== this.dom(e).closest("." + this.prefix + "-container-" + this.uuid).length && t;
      }
      return !1;
    },
    getWinNode: function () {
      return this.app.$win.get();
    },
    getDocNode: function () {
      return this.app.$doc.get();
    },
    getEditor: function () {
      return this.$editor;
    },
    getLayout: function () {
      return this.getEditor();
    },
    getWidth: function () {
      var t = this.getEditor(),
        e = parseInt(t.css("padding-left")),
        i = parseInt(t.css("padding-right"));
      return t.width() - e - i;
    },
    getRect: function () {
      var t = this.$editor.offset(),
        e = this.$editor.width(),
        i = this.$editor.height(),
        s = Math.round(t.top),
        n = Math.round(t.left);
      return {
        top: s,
        left: n,
        bottom: s + i,
        right: n + e,
        width: e,
        height: i
      };
    },
    enableUI: function () {
      this.app.toolbar.enable(), this.app.toolbar.enableSticky();
    },
    disableUI: function () {
      this.app.popup.close(), this.app.control.close(), this.app.context.close(), this.app.toolbar.disable(), this.app.toolbar.disableSticky();
    },
    _buildEditor: function () {
      this.app.$element.hide(), this.$editor = this.dom("<div>"), this.$editor.addClass(this.prefix + "-editor " + this.prefix + "-reset " + this.prefix + "-" + this.opts.editor.classname), this.$editor.attr("contenteditable", !0), this.app.container.get("editor").append(this.$editor);
    },
    _buildBlurClass: function () {
      this.app.container.setBlur();
    },
    _buildOptions: function () {
      var t = this.$editor,
        e = this.opts.editor;
      t.attr("dir", e.direction), e.minHeight && t.css("min-height", e.minHeight), e.maxHeight && t.css("max-height", e.maxHeight), e.notranslate && t.addClass("notranslate"), e.spellcheck || t.attr("spellcheck", !1), e.grammarly || t.attr("data-gramm_editor", !1);
    },
    _buildContent: function () {
      var t = this._getContentValue();
      t = this.app.broadcastHtml("editor.before.load", t);
      var e = this.app.parser.parse(t);
      this.$editor.html(e.get().childNodes);
      var i = this.app.parser.unparse(this.$editor.html());
      this.app.$element.val(i), this._load();
    },
    _buildDraggable: function () {
      this.app.$body.find("[data-" + this.prefix + "-drop-id]").each(function (t) {
        t.attr("draggable", !0), t.on("dragstart", function (t) {
          var e = this.dom(t.target).attr("data-" + this.prefix + "-drop-id");
          t.dataTransfer.setData("item", e);
        }.bind(this));
      }.bind(this));
    },
    _load: function () {
      this.app.blocks.build(), this.app.event.build(), this.app.observer.build(), this.app.embed.build(), this.app.sync.build(), this.app.image.observeStates(), this._buildDraggable(), this.app.broadcast("editor.load");
    },
    _getContent: function () {
      var t = this.$editor.html();
      return t = this.app.parser.unparse(t);
    },
    _getContentValue: function () {
      return this.opts.content ? this.opts.content : this.app.$element.val();
    },
    _setFocusOnStart: function () {
      this.opts.editor.focus && (this.setFocus(), this.setFocus(this.opts.editor.focus));
    },
    _classSelect: function (t) {
      return this.$editor[t + "Class"](this.prefix + "-select-all");
    }
  }), RedactorX.add("module", "parser", {
    build: function (t) {
      return this.$layout = this.dom("<div>"), this.$layout.html(t), this.$layout.find("[data-" + this.prefix + "-type]").each(this._build.bind(this)), this.$layout;
    },
    buildPredefinedClasses: function (t) {
      if (this.opts.classes) {
        t = t || this.app.editor.getEditor();
        var e = this.app.content,
          i = !0,
          s = !1;
        if (void 0 !== this.opts.classes.blocks && (s = !0, void 0 === this.opts.classes.tags && (i = !1)), i && t.find(e.getPredefinedTags().join(",")).each(e.addPredefinedTagClass.bind(this)), s) {
          var n = e.getPredefinedBlocks(),
            a = "data-" + this.prefix + "-type",
            o = "[" + a + "=" + n.join("],[" + a + "=") + "]";
          t.find(o).each(e.addPredefinedBlockClass.bind(this));
        }
      }
    },
    parse: function (t, e) {
      return t = t.trim(), t = this.app.broadcastHtml("editor.before.parse", t), t = this.app.content.isEmptyHtml(t) ? this.app.block.createHtml() : (t = this._clean(t), this._parse(t)), t = this.app.broadcastHtml("editor.parse", t), !1 !== e ? this.build(t) : t;
    },
    parseLine: function (t, e) {
      return t = " " === t ? "&nbsp;" : (t = (t = this.app.broadcastHtml("editor.before.parse", t)).replace(/\r?\n/g, "<br>"), t = this.app.content.encodeCode(t), t = this.app.content.sanitize(t), t = this.app.content.removeEmptySpans(t), t = this.app.content.addHttps(t), this.app.broadcastHtml("editor.parse", t)), !1 !== e ? this.build(t) : t;
    },
    unparse: function (t, e) {
      var i = {},
        s = this.app.content;
      return t = t.trim(), t = this.app.broadcastHtml("editor.before.unparse", t), s.isEmptyHtml(t) ? "" : (t = this._revertForms(t), t = this._revertFrames(t), t = s.store(t, "embed", i, 0), t = s.addNofollow(t), t = s.removeMarkers(t), t = s.recacheStyle(t), t = s.restore(t, "embed", i), t = s.removeEmptyAttrs(t, ["style", "class", "rel", "alt", "title"]), t = this._unparseAllTags(t), t = this._unparseDataType(t, e), t = s.removeEmptyAttrs(t, ["style", "class", "rel", "alt", "title"]), this.opts.classes && (t = this.app.utils.wrap(t, this.buildPredefinedClasses.bind(this))), "<p></p>" === t && (t = ""), this.app.broadcastHtml("editor.unparse", t));
    },
    _build: function (t) {
      var e = t.attr("data-" + this.prefix + "-type");
      this.app.create("block." + e, t);
    },
    _clean: function (t) {
      var e = {},
        i = [],
        s = this.app.content;
      return t = s.storeComments(t, i), this.app.editor.isTextarea() && (t = s.encodeCode(t)), t = s.sanitize(t), t = this._convertForms(t), t = this._convertFrames(t), t = s.store(t, "embed", e, 0), t = s.removeTags(t, this.opts.tags.denied), t = s.removeDoctype(t), t = s.removeTagsWithContent(t, ["script", "style"]), t = s.removeEmptySpans(t), t = s.addHttps(t), t = s.removeBlockTagsInside(t, ["th", "td", "li", "dt", "dd", "address"]), t = s.cacheStyle(t), t = s.restore(t, "embed", e), t = s.restoreComments(t, i), this.opts.clean.comments && (t = s.removeComments(t)), t = s.isEmptyHtml(t) ? this.app.block.createHtml() : s.paragraphize(t);
    },
    _parse: function (t) {
      return this.app.utils.wrap(t, function (t) {
        for (var e = this.app.element.getBlocks(t), i = 0; i < e.length; i++) this._parseHtml(e[i]);
        this.buildPredefinedClasses(t);
      }.bind(this));
    },
    _parseHtml: function (t) {
      var e = t.tagName.toLowerCase(),
        i = this.dom(t),
        s = this._parseType(i, e);
      s && i.attr("data-" + this.prefix + "-type", s);
    },
    _parseType: function (t, e) {
      return t.attr("data-" + this.prefix + "-type") ? t.attr("data-" + this.prefix + "-type") : this._parseTypeByTag(t, e);
    },
    _parseTypeByTag: function (t, e) {
      var i;
      switch (e) {
        case "p":
          i = "paragraph", this._isImageBlock(t, "p") && (i = "image");
          break;
        case "figure":
          i = "embed", this._isImageBlock(t, "figure") ? i = "image" : this._hasChild(t, "pre") ? i = "pre" : this._hasChild(t, "blockquote") && (i = "quote");
          break;
        case "div":
          i = "layer", this._isImageBlock(t, "div") && (i = "image");
          break;
        case "h1":
        case "h2":
        case "h3":
        case "h4":
        case "h5":
        case "h6":
          i = "heading";
          break;
        case "blockquote":
          i = "quote";
          break;
        case "table":
          i = "table";
          break;
        case "pre":
          i = "pre";
          break;
        case "hr":
          i = "line";
          break;
        case "address":
          i = "address";
          break;
        case "ul":
        case "ol":
          i = "list";
          break;
        default:
          i = "layer";
      }
      return i;
    },
    _isImageBlock: function (t, e) {
      var i = t.find("img");
      if (0 !== i.length && ("div" !== e || 0 === i.closest("figure").length)) {
        var s = i,
          n = i.parent(),
          a = 0 !== n.length && n.get().tagName;
        if (!a || "A" !== a && "SPAN" !== a) {
          if (a && n.get() !== t.get()) return;
        } else s = n;
        if (0 === s.prevElement().length && ("figure" === e || 0 === s.nextElement().length)) return !0;
      }
    },
    _hasChild: function (t, e) {
      if ("pre" === e) {
        if (0 !== t.find("pre").length) return !0;
      } else if ("blockquote" === e) {
        var i = t.find("blockquote");
        if (0 === t.find("script").length && 0 !== i.length) return !0;
      }
    },
    _unparseAllTags: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("*").removeAttr("contenteditable data-gramm_editor"), this.opts.image.states || t.find("img").removeAttr("data-image");
      }.bind(this));
    },
    _unparseDataType: function (t, i) {
      return this.app.utils.wrap(t, function (t) {
        var e = t.find("[data-" + this.prefix + "-type]");
        !0 !== i && e.removeClass(this.prefix + "-block-state"), e.removeAttr("tabindex data-" + this.prefix + "-parsed data-" + this.prefix + "-first-level"), e.removeClass(this.prefix + "-block-focus " + this.prefix + "-block-multiple-focus " + this.prefix + "-block-multiple-hover " + this.prefix + "-editable-pause"), e.removeClass(this.prefix + "-nowrap"), e.each(this._unparseByType.bind(this)), e.removeAttr("data-" + this.prefix + "-type"), t.find("figcaption").removeAttr("data-" + this.prefix + "-type data-placeholder").each(this.app.content._removeEmptyTag.bind(this));
      }.bind(this));
    },
    _unparseByType: function (t) {
      var e = t.attr("data-" + this.prefix + "-type");
      "embed" === e ? this._unparseEmbed(t) : "list" === e && this._unparseList(t);
    },
    _unparseEmbed: function (t) {
      var e,
        i = decodeURI(t.attr("data-embed-code")),
        s = t.find("." + this.opts.embed.responsive),
        n = t.find("figcaption");
      0 !== n.length && (e = n.clone(), n.remove()), 0 === s.length ? t.html(i) : s.html(i), e && t.append(e), t.removeAttr("data-embed-code");
    },
    _unparseList: function (t) {
      this.app.content.unfixListMargin(t);
    },
    _convertFrames: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("iframe").each(this._convertFrame.bind(this));
      }.bind(this));
    },
    _convertFrame: function (t) {
      0 === t.closest("figure").length && (t.wrap("<figure>"), t.parent().addClass(this.prefix + "-figure-iframe"));
    },
    _convertForms: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("form").each(this._convertForm.bind(this));
      }.bind(this));
    },
    _convertForm: function (t) {
      this.app.element.replaceToTag(t, "div").addClass(this.prefix + "-div-form");
    },
    _revertFrames: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("." + this.prefix + "-figure-iframe").each(this._revertFrame.bind(this));
      }.bind(this));
    },
    _revertFrame: function (t) {
      0 !== t.find("figcaption").length ? t.removeClass(this.prefix + "-figure-iframe") : t.unwrap();
    },
    _revertForms: function (t) {
      return this.app.utils.wrap(t, function (t) {
        t.find("." + this.prefix + "-div-form").each(this._revertForm.bind(this));
      }.bind(this));
    },
    _revertForm: function (t) {
      this.app.element.replaceToTag(t, "form").removeClass(this.prefix + "-div-form");
    }
  }), RedactorX.add("module", "blocks", {
    init: function () {
      this.selected = [], this.focusclass = this.prefix + "-block-meta-focus";
    },
    start: function () {
      this.$editor = this.app.editor.getEditor();
    },
    build: function () {
      this._buildFirstLevel();
    },
    is: function () {
      return 0 < this.selected.length;
    },
    isMeta: function () {
      return 0 < this.getSelected().length;
    },
    set: function (t) {
      this.selected = t;
    },
    setMeta: function (t) {
      t = t.closest("[data-" + this.prefix + "-first-level]"), this.app.block.unset(), this._classFocus(t, "add"), this.app.toolbar.build(), setTimeout(function () {
        this.app.selection.removeAllRanges();
      }.bind(this), 0);
    },
    unset: function () {
      this._classFocus(this.getFirstLevel(), "remove"), this.selected = [];
    },
    getBlocks: function () {
      return this.$editor.find("[data-" + this.prefix + "-type]");
    },
    getFirstLevel: function () {
      return this.$editor.find("[data-" + this.prefix + "-first-level]");
    },
    getFirst: function () {
      return this.getBlocks().first().dataget("instance");
    },
    getLast: function () {
      return this.getBlocks().last().dataget("instance");
    },
    getFirstSelected: function () {
      return this.getSelected().first().dataget("instance");
    },
    getLastSelected: function () {
      return this.getSelected().last().dataget("instance");
    },
    getSelected: function () {
      return this.$editor.find("." + this.focusclass);
    },
    getSelectedBlocks: function (t) {
      var e = this.app.selection.getNodes({
        type: "blocks-first"
      });
      if ("editable" === t) {
        for (var i = [], s = 0; s < e.length; s++) {
          var n = this.dom(e[s]).dataget("instance").getType();
          -1 !== ["paragraph", "heading", "list", "address"].indexOf(n) && i.push(e[s]);
        }
        e = i;
      }
      return e;
    },
    getSelectedBlock: function (t) {
      var e = this.app.selection.getDataBlock();
      return t && 0 === e.length && (e = this.app.element.getDataBlock(t.target)), e;
    },
    getFirstSelectedBlock: function () {
      return 0 < this.selected.length && this.selected[0];
    },
    getLastSelectedBlock: function () {
      return 0 < this.selected.length && this.selected[this.selected.length - 1];
    },
    removeSelected: function (t) {
      var e,
        i = this.getLastSelected();
      !1 !== t && i && (e = i.getNext()), this.getSelected().each(this._removeSelectedBlock.bind(this)), e && this.app.block.set(e, "start");
    },
    _buildFirstLevel: function () {
      var t = "data-" + this.prefix + "-first-level",
        e = this.app.editor.getEditor();
      e.find("[" + t + "]").removeAttr(t), e.children("[data-" + this.prefix + "-type]").attr(t, !0);
    },
    _removeSelectedBlock: function (t) {
      t.dataget("instance").remove({
        traverse: !1
      });
    },
    _classFocus: function (t, e) {
      return t[e + "Class"](this.focusclass);
    }
  }), RedactorX.add("module", "event", {
    init: function () {
      this.trigger = !0, this.imageDrag = !1, this.dragoverEvent = !1, this.isPopupMouseUp = !1, this.isEditorMouseUp = !1, this.events = {
        editor: ["click", "touchstart", "mouseover", "mouseup", "mousedown", "keydown", "keyup", "drop", "dragstart", "dragover", "dragleave"],
        doc: ["keydown", "mousedown", "mouseup", "click", "paste", "cut", "copy"],
        win: ["focus"]
      };
    },
    build: function () {
      this.$editor = this.app.editor.getEditor(), this._buildPreventLinks(), this._buildEvents();
    },
    onmouseover: function (t) {
      this.app.broadcast("editor.mouseover", {
        e: t
      });
    },
    ontouchstart: function (t) {
      this.app.state.add(t);
    },
    onclick: function (t) {
      this.app.broadcast("editor.click", {
        e: t
      }), 3 === t.detail && setTimeout(function () {
        this._setBlockFocus(t, !0);
      }.bind(this), 0);
    },
    onmouseup: function (t) {
      !this.isEditorMouseUp && this._isEditorClick(t) || setTimeout(function () {
        this.app.selection.isCollapsed() ? this._setBlockFocus(t) : this.app.editor.observeBlocks(t), this.app.toolbar.observe(), this.app.state.add(t), this.isEditorMouseUp = !1, this.app.broadcast("editor.mouseup", {
          e: t
        });
      }.bind(this), 0);
    },
    onmousedown: function (t) {
      this.app.placeholder.handleClick(t), this._isEditorClick(t) || (this._setCaretInline(t), this.isEditorMouseUp = !0, this.app.state.add(t), this.app.broadcast("editor.mousedown", {
        e: t
      }));
    },
    onkeydown: function (t) {
      if (this.app.broadcast("editor.keydown", this._buildEventKeysObj(t)).isStopped()) return t.preventDefault();
      this.app.state.listen(t) || this._isEsc(t) && (this.app.block.unset(), this.app.selection.removeAllRanges());
    },
    onkeyup: function (t) {
      if (this.app.broadcast("editor.keyup", this._buildEventKeysObj(t)).isStopped()) return t.preventDefault();
      var e = t.which;
      if (e === this.app.keycodes.DOWN || e === this.app.keycodes.UP) {
        var i = this.app.selection.getDataBlock();
        0 === i.length || this.app.block.is(i) || this.app.block.set(i);
      }
      e !== this.app.keycodes.TAB || this.app.block.is() || this._setBlock(t);
    },
    ondrop: function (t) {
      if (!this.opts.editor.drop) return t.preventDefault();
      var e;
      if (this.app.broadcast("editor.drop", {
        e: t
      }).isStopped()) return t.preventDefault();
      var i = t.dataTransfer,
        s = i.getData("item");
      if ("" !== s) t.preventDefault(), (e = this.opts.draggable && void 0 !== this.opts.draggable[s] ? this.opts.draggable[s] : (e = this.dom("[data-" + this.prefix + "-drop-item=" + s + "]").html()).trim()) && this._drop(t, e, "after", !1);else if (this.opts.image && this.opts.image.upload && null !== i.files && 0 < i.files.length) t.preventDefault(), this.app.image.drop(t, i);else {
        e = "" === (e = i.getData("text/html")).trim() ? i.getData("Text") : e;
        var n = this._drop(t, e);
        if (this.imageDrag && 0 !== n.instances.length) n.instances[0].change(this.imageDrag, !1);
      }
      this._removeDragPlaceholder(), this.imageDrag = !1, this.app.observer.trigger = !0;
    },
    ondragstart: function (t) {
      var e = this._getBlock(t.target);
      0 !== e.length && "image" === this.app.element.getType(e) && (this.imageDrag = e.dataget("instance")), this.app.broadcast("editor.dragstart", {
        e: t
      });
    },
    ondragover: function (t) {
      if (t.preventDefault(), this.dragoverEvent = !0, this.app.observer.trigger = !1, this._removeDragPlaceholder(), -1 !== t.dataTransfer.types.indexOf("item")) {
        var e = this._getBlockFirst(t.target);
        if (0 !== e.length) {
          var i = this.dom("<div>").addClass(this.prefix + "-draggable-placeholder");
          e.after(i);
        }
      }
      this.app.broadcast("editor.dragover", {
        e: t
      });
    },
    ondragleave: function (t) {
      t.preventDefault(), this.dragoverEvent = !0, this.app.observer.trigger = !0, this._removeDragPlaceholder(), this.app.broadcast("editor.dragleave", {
        e: t
      });
    },
    onwinfocus: function () {
      var t = this.app.block.get();
      !t || t.isEditable() || setTimeout(function () {
        this.app.selection.removeAllRanges();
      }.bind(this), 0);
    },
    ondocpaste: function (t) {
      this._isFocusEditor() && this._paste(t);
    },
    ondoccopy: function (t) {
      this._isFocusEditor() && this._copy(t);
    },
    ondoccut: function (t) {
      this._isFocusEditor() && this._cut(t);
    },
    ondockeydown: function (t) {
      if (this.app.popup.isOpen()) {
        if (this._isEnter(t)) if (!1 !== this.app.popup.hasForm() && "TEXTAREA" !== t.target.tagName) return t.preventDefault(), void this.app.popup.getFooterPrimary().dataget("instance").invokeCommand();
        this._isEsc(t) && this.app.popup.close(!1);
      }
      var e = this.app.broadcast("editor.dockeydown", this._buildEventKeysObj(t));
      if (this.app.editor.isFocus() && !this.app.popup.isOpen() && !this.app.source.is()) {
        if (this.app.shortcut.handle(t)) return;
        this.app.input.handle(e);
      }
    },
    ondocmousedown: function (t) {
      this.isPopupMouseUp = 0 !== this.dom(t.target).closest("." + this.prefix + "-popup-" + this.uuid).length, this.app.broadcast("editor.docmousedown", {
        e: t
      });
    },
    ondocmouseup: function (t) {
      this.app.broadcast("editor.docmouseup", {
        e: t
      });
    },
    ondocclick: function (t) {
      if (!this.app.popup.isOpen() || !this._isEditorContainer(t)) return !this._isOutsideEditor(t) || !1 === this.trigger || void (this.isEditorMouseUp ? this.isEditorMouseUp = !1 : (this.app.popup.isOpen() ? !1 === this.isPopupMouseUp && this.app.popup.close(!1) : this.app.editor.setBlur(t), this.app.broadcast("editor.docclick", {
        e: t
      })));
      this.app.popup.close(!1);
    },
    _buildPreventLinks: function () {
      var t = this.prefix + "-prevent-events";
      this.$editor.on("click." + t + " dblclick." + t, this._preventLinks.bind(this));
    },
    _buildEventKeysObj: function (t) {
      var e = t.which,
        i = [this.app.keycodes.UP, this.app.keycodes.DOWN, this.app.keycodes.LEFT, this.app.keycodes.RIGHT],
        s = !t.ctrlKey && !t.metaKey && (48 <= e && e <= 57 || 65 <= e && e <= 90),
        n = this.app.keycodes;
      return {
        e: t,
        key: e,
        ctrl: t.ctrlKey || t.metaKey,
        shift: t.shiftKey,
        alt: t.altKey,
        select: (t.ctrlKey || t.metaKey) && !t.altKey && 65 === e,
        enter: e === n.ENTER,
        space: e === n.SPACE,
        esc: e === n.ESC,
        tab: !(e !== n.TAB || t.shiftKey || t.altKey || t.ctrlKey || t.metaKey),
        delete: e === n.DELETE,
        backspace: e === n.BACKSPACE,
        alpha: s,
        arrow: -1 !== i.indexOf(e),
        left: e === n.LEFT,
        right: e === n.RIGHT,
        up: e === n.UP,
        down: e === n.DOWN,
        "left-right": e === n.LEFT || e === n.RIGHT,
        "up-left": e === n.UP || e === n.LEFT,
        "down-right": e === n.DOWN || e === n.RIGHT
      };
    },
    _buildEvents: function () {
      var t = this.prefix + "-events";
      this._buildTargetEvents(this.$editor, this.events.editor, t, ""), this._buildTargetEvents(this.app.$doc, this.events.doc, t, "doc"), this._buildTargetEvents(this.app.$win, this.events.win, t, "win");
    },
    _buildTargetEvents: function (t, e, i, s) {
      for (var n = 0; n < e.length; n++) t.on(e[n] + "." + i, this["on" + s + e[n]].bind(this));
    },
    _preventLinks: function (t) {
      0 !== this.dom(t.target).closest("a").length && t.preventDefault();
    },
    _isEditorClick: function (t) {
      if (this.app.editor.isEditor(t.target)) return t.preventDefault(), !0;
    },
    _isEsc: function (t) {
      return t.which === this.app.keycodes.ESC;
    },
    _isEnter: function (t) {
      return t.which === this.app.keycodes.ENTER;
    },
    _isEditorContainer: function (t) {
      return 0 !== this.dom(t.target).closest("." + this.prefix + "-container-" + this.uuid).length;
    },
    _isOutsideEditor: function (t) {
      return 0 === this.dom(t.target).closest("." + this.prefix + ["-container-", "-popup-", "-toolbar-", "-control-"].join(this.uuid + ",." + this.prefix) + this.uuid).length;
    },
    _isFocusEditor: function () {
      return !this.app.popup.isOpen() && !this.app.source.is() && (this.app.block.is() || this.app.blocks.is() || this.app.blocks.isMeta() || this.app.editor.isAllSelected());
    },
    _removeDragPlaceholder: function () {
      this.app.editor.getEditor().find("." + this.prefix + "-draggable-placeholder").remove();
    },
    _getBlock: function (t) {
      return this.dom(t).closest("[data-" + this.prefix + "-type]");
    },
    _getBlockFirst: function (t) {
      return this.dom(t).closest("[data-" + this.prefix + "-first-level]");
    },
    _setBlock: function (t) {
      this.app.editor.setFocus();
      var e = t ? this._getBlock(t.target) : this.app.selection.getDataBlock(),
        i = !1;
      0 === e.length && (e = this.app.blocks.getFirst(), i = "start"), this.app.block.set(e, i);
    },
    _setBlockFocus: function (t, e) {
      var i = this._getBlock(t.target);
      this.app.editor.setFocus(), e && this.app.selection.select(i), this.app.block.set(i);
    },
    _setCaretInline: function (e) {
      var t = this.app.block.get(),
        i = !1;
      t && t.isEditable() && (this.app.element.isEmptyOrImageInline(e.target) ? this.app.caret.set(e.target, "after") : this.app.selection.isCollapsed() && "CODE" === e.target.tagName && (i = !0, setTimeout(function () {
        var t = this.app.selection.getElement();
        t && i && "CODE" !== t.tagName && (this.app.caret.set(e.target, "start"), i = !1);
      }.bind(this), 1)));
    },
    _drop: function (t, e, i, s) {
      var n = "after" === i ? "getFirstLevel" : "getDataBlock",
        a = this.app.element[n](t.target);
      a = 0 === a.length ? this.app.blocks.getFirst() : a, this.app.block.set(a), i || this.app.insertion.insertPoint(t);
      var o = !0,
        r = !0,
        p = this.app.block.get(),
        l = this.app.editor.isAllSelected();
      if (p && "pre" === p.getType() && !l && (r = o = !1, e = this.app.content.getTextFromHtml(e, {
        nl: !0,
        trimlines: !1
      })), !1 === s && (o = !1, e = this.app.autoparse.parse(e)), "" !== e) return e = o ? this.app.autoparse.parse(e) : e, this.app.insertion.insertContent({
        html: e,
        clean: o,
        parse: r,
        position: i
      });
    },
    _paste: function (t) {
      t.preventDefault();
      var e = t.clipboardData;
      if (!this.app.image.insertFromClipboard(e)) {
        var i = e.getData("URL"),
          s = this.app.clipboard.getContent(e),
          n = this.app.broadcast("editor.before.paste", {
            e: t,
            html: s
          });
        if (!n.isStopped()) {
          s = n.get("html"), s = i && "" !== i ? i : s;
          var a = this.app.block.get(),
            o = !0,
            r = !0,
            p = this.app.editor.isAllSelected();
          if (this.opts.paste.plaintext ? (r = o = !1, s = this.app.content.getTextFromHtml(s, {
            br: !0
          })) : a && "pre" === a.getType() && !p && (r = o = !1, s = this.app.content.getTextFromHtml(s, {
            nl: !0,
            trimlines: !1
          })), "" !== s) {
            s = o ? this.app.autoparse.parse(s) : s;
            var l = this.app.insertion.insertContent({
              html: s,
              clean: o,
              parse: r
            });
            this.app.broadcast("editor.paste", l);
          }
        }
      }
    },
    _copy: function (t) {
      this._action(t, "copy");
    },
    _cut: function (t) {
      this._action(t, "cut");
    },
    _action: function (t, e) {
      var i = !1,
        s = {},
        n = this.app.block.get();
      if (!(n && n.isEditable() && this.app.selection.isCollapsed())) {
        this.app.blocks.isMeta() && (n = this.app.blocks.getLastSelected()), t.preventDefault(), this.app.editor.isAllSelected() ? s = {
          html: this.app.editor.getLayout().html(),
          remove: "all"
        } : this.app.blocks.is() ? s = {
          html: this.app.selection.getHtml(),
          remove: "content"
        } : n && n.isEditable() ? s = this._copyFromEditable(e, n) : n && (s = this._copyFromNonEditable(e, n));
        var a = this.app.broadcast("editor.before." + e, {
          e: t,
          html: s.html
        });
        a.isStopped() || ("cut" === e && this._cutDeleteContent(s), i = a.get("html"), i = this.app.clipboard.setContent(t, i), this.app.broadcastHtml("editor." + e, i));
      }
    },
    _cutDeleteContent: function (t) {
      "instance" === t.remove ? (t.instance.remove(!0), this.app.control.close()) : "all" === t.remove ? this.app.editor.setEmpty() : !1 !== t.remove && this.app.selection.deleteContents();
    },
    _copyFromEditable: function (t, e) {
      var i = e.getType(),
        s = this.app.selection.getHtml(),
        n = "content";
      if ("figcaption" === i || "cell" === i) n = "content";else if (e.isAllSelected()) s = e.getOuterHtml(), n = "instance";else if ("list" === i) {
        var a = e.getTag();
        -1 !== (s = this.app.selection.getHtml()).search(/<li/gi) && (-1 === s.search(/^<li/g) && (s = "<li>" + s + "</li>"), s = "<" + a + ">" + s + "</" + a + ">");
      }
      return {
        html: s,
        remove: n,
        instance: e
      };
    },
    _copyFromNonEditable: function (t, e) {
      var i = e.getOuterHtml(),
        s = !1;
      return "cut" !== t || e.isSecondLevel() || (s = "instance"), {
        html: i,
        remove: s,
        instance: e
      };
    }
  }), RedactorX.add("module", "block", {
    init: function () {
      this.instance = !1, this.$block = !1;
    },
    create: function (t) {
      var e = this.app.create("block.paragraph");
      return t && e.getBlock().html(t), e;
    },
    createHtml: function (t) {
      return this.create(t).getOuterHtml();
    },
    is: function (t) {
      return t ? this._isBlockActive(t) : this.get();
    },
    get: function () {
      return this.instance;
    },
    set: function (t, e, i) {
      t.isBlock && (t = t.isNested() ? e && "end" === e ? t.getLast().getBlock() : t.getFirst().getBlock() : t.getBlock()), this.app.blocks.unset(), this.app.editor.unsetSelectAll(), !0 !== i && this._isBlockActive(t) || (this.unset(), this.instance = this._getInstance(t), this.$block = this.instance.getBlock(), this.$block.addClass(this.prefix + "-block-focus"), this._setCaret(e), this.app.toolbar.build(), this.app.control.build(), this.app.broadcast("block.set"));
    },
    unset: function () {
      this.instance && (this.$block.removeClass(this.prefix + "-block-focus"), this.instance = !1, this.$block = !1, this.app.popup.close(), this.app.control.close(), this.app.context.close(), this.app.broadcast("block.unset"));
    },
    observe: function (t, e) {
      if (-1 !== ["line", "quote", "pre"].indexOf(e) && !this.opts[e]) return !1;
    },
    format: function (t) {
      this.app.format.set(t);
    },
    add: function (t) {
      this.app.popup.close();
      var e = this.get();
      this.app.blocks.isMeta() && (e = this.app.blocks.getLastSelected());
      var i,
        s = "after";
      if (t && t.template) return e || (s = this.opts.editor.add), void this.app.insertion.insertContent({
        html: t.template,
        position: s
      });
      i = t.instance ? t.instance : this.app.create("block." + t.name, t.source), e ? e.isSecondLevel() && (e = e.getFirstLevel()) : s = "top" === this.opts.editor.add ? (e = this.app.blocks.getFirst(), "before") : (e = this.app.blocks.getLast(), "after");
      var n = !0;
      if (e) {
        var a = e.getType();
        "paragraph" === t.name && -1 !== ["paragraph", "heading"].indexOf(a) && (n = !1);
      }
      return e.add({
        instance: i,
        caret: t.caret ? t.caret : "end",
        position: s,
        remove: n
      }), i;
    },
    change: function (t) {
      var e = this._getCurrentInstance();
      e && e.change(t);
    },
    duplicate: function () {
      if (this.app.popup.close(), this.is()) {
        var t = this.get();
        t.isSecondLevel() && (t = t.getFirstLevel());
        var e = t.duplicate(),
          i = t.add({
            instance: e,
            caret: "start"
          });
        return this.app.broadcast("block.duplicate", {
          instance: i
        }), i;
      }
    },
    remove: function (t) {
      this.app.popup.close();
      var e = this.get();
      if (e) {
        e.isSecondLevel() && (e = e.getFirstLevel());
        var i = e.getType();
        if ("image" === i) var s = {
          url: e.getSrc(),
          id: e.getId()
        };
        if (!t || void 0 === t.tarverse || !1 !== t.tarverse) {
          var n = e.getNext(),
            a = e.getPrev();
          e.remove(), n ? this.app.block.set(n, "start") : a ? this.app.block.set(a, "end") : this.unset();
        } else this.unset(), e.remove();
        "image" === i && this.app.broadcast("image.remove", s), this.app.broadcast("block.remove", {
          type: i
        }), this.app.editor.isEmpty() && this.app.editor.setEmpty();
      }
    },
    moveUp: function () {
      var t = this._getCurrentInstance();
      t && (t.isSecondLevel() && (t = t.getFirstLevel()), t.moveUp());
    },
    moveDown: function () {
      var t = this._getCurrentInstance();
      t && (t.isSecondLevel() && (t = t.getFirstLevel()), t.moveDown());
    },
    getData: function () {
      if (this.is()) return this.get().getData();
    },
    setData: function (t) {
      if (this.is()) {
        var e = t.getData();
        this.get().setData(e);
      }
    },
    _isBlockActive: function (t) {
      return this.instance && this.dom(t).get() === this.$block.get();
    },
    _getCurrentInstance: function () {
      var t;
      return this.app.blocks.isMeta() ? t = this.app.blocks.getLastSelected() : this.is() && (t = this.get()), t;
    },
    _getInstance: function (t) {
      return this.dom(t).dataget("instance");
    },
    _setCaret: function (t) {
      var e = this.instance.getType(),
        i = this.get();
      -1 !== ["embed", "image", "line", "layer"].indexOf(e) ? (this.app.scroll.save(), this.$block.attr("tabindex", "-1"), this.$block.focus(), setTimeout(function () {
        this.app.selection.removeAllRanges();
      }.bind(this), 1), this.app.scroll.restore()) : i.isEditable() && t && this.instance.setCaret(t);
    }
  }), RedactorX.add("module", "observer", {
    init: function () {
      this.observer = !1, this.trigger = !0;
    },
    build: function () {
      if (window.MutationObserver) {
        var t = this.app.editor.getEditor().get();
        this.observer = this._build(t), this.observer.observe(t, {
          attributes: !0,
          subtree: !0,
          childList: !0,
          characterData: !0,
          characterDataOldValue: !0
        });
      }
    },
    stop: function () {
      this.observer && this.observer.disconnect(), this.trigger = !0;
    },
    isButtons: function () {
      return !this.app.blocks.isMeta() && !this.app.editor.isAllSelected() && !(!this.opts.buttons.tags && !this.opts.buttons.types);
    },
    buildButtons: function (t, e) {
      for (var i, s = this.app.block.get(), n = !!s && s.getType(), a = !!s && s.getTag(), o = this.app.selection.getNodes({
          type: "inline",
          selected: "inside",
          links: !0
        }), r = this._getObservedTags(a, o), p = [], l = 0; l < r.length; l++) (i = t[r[l]]) && (p = p.concat(i));
      return n && (i = e[n]) && (p = p.concat(i)), p;
    },
    buildActiveButtons: function (t) {
      var e = {};
      for (var i in e.tags = this.opts.buttons.tags ? this.opts.buttons.tags : {}, e.types = this.opts.buttons.types ? this.opts.buttons.types : {}, t) {
        var s = t[i].active;
        s && (this._buildActiveButton(i, s.tags, e.tags), this._buildActiveButton(i, s.types, e.types));
      }
      return e;
    },
    _build: function (e) {
      var i = this;
      return new MutationObserver(function (t) {
        i._observe(t[t.length - 1], e);
      });
    },
    _observe: function (t, e) {
      "attributes" === t.type && t.target === e || this.trigger && (this.app.broadcast("observer.change"), this.app.placeholder.toggle(), this.app.sync.trigger());
    },
    _buildActiveButton: function (t, e, i) {
      if (e) {
        for (var s = 0; s < e.length; s++) {
          var n = e[s];
          i[n] ? i[n].push(t) : i[n] = [t];
        }
        return i;
      }
    },
    _getObservedTags: function (t, e) {
      var i = [];
      if (t && i.push(t), 0 < e.length) for (var s = 0; s < e.length; s++) i.push(e[s].tagName.toLowerCase());
      return i;
    }
  }), RedactorX.add("module", "input", {
    handle: function (t) {
      var e = t.get("e"),
        i = t.get("key");
      if (!this._doSelectAll(e, t)) if (t.is("enter") && t.is("shift")) this.handleShiftEnter(e, i, t);else if (t.is("enter")) this.handleEnter(e, i, t);else if (t.is("space") && t.is("shift")) this.handleShiftSpace(e, i, t);else if (t.is("space")) this.handleSpace(e, i, t);else if (t.is("tab") && this.opts.tab.key) this.handleTab(e, i, t);else if (t.is("arrow")) {
        if (t.is(["shift", "alt", "ctrl"])) return;
        this.handleArrow(e, i, t);
      } else t.is(["delete", "backspace"]) && this.handleDelete(e, i, t);
    },
    handleDelete: function (t, e, i) {
      var s = this.app.block.get(),
        n = i.is("backspace"),
        a = i.is("delete");
      if (this.app.editor.isEmpty(!0)) t.preventDefault();else {
        if (this.app.blocks.isMeta()) return t.preventDefault(), void this.app.blocks.removeSelected();
        if (this.app.blocks.is()) {
          t.preventDefault();
          var o = this.app.blocks.getFirstSelectedBlock(),
            r = this.dom(o);
          return this.app.selection.deleteContents(), void this.app.caret.set(r, "end");
        }
        if (!(this._deleteInsideSelection(t) || s && s.isEditable() && this._trimInvisibleChar(t, i.is("backspace") ? "left" : "right", a))) {
          var p = this.app.selection.getInline();
          if (p && 1 === p.innerHTML.length) return t.preventDefault(), void (p.innerHTML = "");
          if (!s.handleDelete || !s.handleDelete(t, e, i)) {
            var l = s.getNext(),
              h = s.getPrev();
            if (s.isEditable()) {
              if (s.isEditable()) {
                if (s.isAllSelected()) return t.preventDefault(), void s.setEmpty();
                if (s.isSecondLevel() || s.isFigcaption()) return;
                if (a && l && s.isCaretEnd()) return t.preventDefault(), void (l.isEditable() ? "pre" === l.getType() ? (this.app.blocks.setMeta(l.getBlock()), s.isEmpty() && s.remove(!0)) : s.appendNext() : (l.isNested() ? this.app.blocks.setMeta(l.getBlock()) : this.app.block.set(l), s.isEmpty() && s.remove(!0)));
                if (n && h && s.isCaretStart()) return t.preventDefault(), void (h.isEditable() ? "pre" === h.getType() ? (this.app.blocks.setMeta(h.getBlock()), s.isEmpty() && s.remove(!0)) : s.appendToPrev() : (h.isNested() ? this.app.blocks.setMeta(h.getBlock()) : this.app.block.set(h), s.isEmpty() && s.remove(!0)));
              }
            } else {
              if (t.preventDefault(), "image" === s.getType()) var c = {
                url: s.getSrc(),
                id: s.getId()
              };
              s.remove(!0), "image" === s.getType() && this.app.broadcast("image.remove", c), l ? this.app.block.set(l, "start") : h ? this.app.block.set(h, "end") : this.app.editor.isEmpty() ? this.app.editor.setEmpty() : this.app.block.unset();
            }
          }
        }
      }
    },
    handleArrow: function (t, e, i) {
      var s = this.app.block.get();
      if (this.app.editor.isAllSelected()) {
        t.preventDefault();
        var n = i.is("down-right") ? this.app.blocks.getLast() : this.app.blocks.getFirst(),
          a = i.is("down-right") ? "end" : "start";
        return this.app.editor.unselectAll(), this.app.selection.removeAllRanges(), void this.app.block.set(n, a);
      }
      if (!this.app.blocks.is()) {
        if (this.app.blocks.isMeta()) return s = this.app.blocks.getLastSelected(), void this._doArrow(t, i, s);
        if (s.isEditable()) {
          var o = this.app.selection.getTopInline();
          if (i.is("left") && o && "CODE" === o.tagName) {
            var r = this.app.offset.get(o),
              p = this.app.caret.is(o, "start");
            if (!p && 1 === r.start && 1 === r.end) return t.preventDefault(), void this.app.caret.set(o, "start");
            if (p) return t.preventDefault(), void this.app.caret.set(o, "before");
          }
        }
        s.isEditable() && this._trimInvisibleChar(t, i.is("left") ? "left" : "right") || s.handleArrow && s.handleArrow(t, e, i) || this._doArrow(t, i, s);
      }
    },
    handleTab: function (t, e, i) {
      var s,
        n = this.app.block.get();
      if (this.app.blocks.isMeta()) return t.preventDefault(), void ((s = (n = this.app.blocks.getLastSelected()).getNext()) && this.app.block.set(s, "start"));
      if (this.app.blocks.is()) {
        var a = this.app.blocks.getLastSelectedBlock();
        (n = this.dom(a).dataget("instance")).isSecondLevel() && (n = n.getFirstLevel());
      }
      if (!n.handleTab || !n.handleTab(t, e, i)) if (this.opts.tab.spaces && n.isEditable()) {
        t.preventDefault();
        var o = this.opts.tab.spaces,
          r = document.createTextNode(Array(o + 1).join(" "));
        this.app.insertion.insertNode(r, "end");
      } else t.preventDefault(), (s = n.getNext()) && this.app.block.set(s, "start");
    },
    handleShiftSpace: function (t) {
      var e = this.app.block.get();
      if (this.app.blocks.isMeta()) t.preventDefault();else {
        if (this.app.blocks.is()) {
          t.preventDefault();
          var i = this.app.blocks.getFirstSelectedBlock(),
            s = this.dom(i);
          return this.app.selection.deleteContents(), this.app.caret.set(s, "end"), void this.app.insertion.insertHtml("&nbsp;", "end");
        }
        if (e.isEditable()) {
          if (e.isAllSelected()) return t.preventDefault(), void e.setEmpty();
          if ("pre" !== e.getType()) return t.preventDefault(), void this.app.insertion.insertHtml("&nbsp;", "end");
        }
      }
    },
    handleSpace: function (t, e, i) {
      var s = this.app.block.get();
      if (this.app.blocks.isMeta()) return t.preventDefault(), this.app.blocks.getLastSelected().addEmpty({
        position: "after",
        caret: "start"
      }), void this.app.blocks.removeSelected(!1);
      if (this.app.blocks.is()) {
        var n = this.app.blocks.getFirstSelectedBlock(),
          a = this.dom(n);
        return this.app.selection.deleteContents(), void this.app.caret.set(a, "end");
      }
      return s.handleSpace && s.handleSpace(t, e, i) ? void 0 : s.isEditable() && s.isAllSelected() ? (t.preventDefault(), void s.setEmpty()) : void (s.isEditable() || (t.preventDefault(), s.addEmpty({
        position: "after",
        caret: "start"
      }), s.remove(!0)));
    },
    handleShiftEnter: function (t) {
      if (this.app.blocks.isMeta()) t.preventDefault();else {
        if (this.app.blocks.is()) {
          t.preventDefault();
          var e = this.app.blocks.getFirstSelectedBlock(),
            i = this.dom(e);
          return this.app.selection.deleteContents(), this.app.caret.set(i, "end"), void this.app.insertion.insertBreakline();
        }
        if (!this._deleteInsideSelection(t)) {
          var s = this.app.block.get();
          s.isEditable() ? (t.preventDefault(), this.app.insertion.insertBreakline()) : (t.preventDefault(), s.addEmpty({
            position: "after",
            caret: "start"
          }));
        }
      }
    },
    handleEnter: function (t, e, i) {
      if (this.app.blocks.isMeta()) return t.preventDefault(), void this.app.blocks.getLastSelected().addEmpty({
        position: "after",
        caret: "start"
      });
      if (this.app.blocks.is()) return t.preventDefault(), void this.app.selection.deleteContents();
      if (!this._deleteInsideSelection(t)) {
        var s = this.app.block.get();
        if (s.isEditable()) {
          if (s.isAllSelected()) return t.preventDefault(), void s.setEmpty();
          if (!this.app.selection.isCollapsed()) return t.preventDefault(), void ("pre" === s.getType() ? this.app.insertion.insertNewline() : this.app.insertion.insertBreakline());
        }
        s.isEditable() || (t.preventDefault(), s.addEmpty({
          position: "after",
          caret: "start"
        })), s.handleEnter && s.handleEnter(t, e, i);
      }
    },
    handleTextareaTab: function (t) {
      if (9 !== t.keyCode) return !0;
      t.preventDefault();
      var e = t.target,
        i = e.value,
        s = e.selectionStart;
      e.value = i.substring(0, s) + "    " + i.substring(e.selectionEnd), e.selectionStart = e.selectionEnd = s + 4;
    },
    _deleteInsideSelection: function (t) {
      if (!this.app.selection.isCollapsed()) {
        var e = this.app.selection.getNodes({
          type: "blocks"
        });
        if (1 < e.length) return t.preventDefault(), this.app.selection.deleteContents(), this.app.caret.set(e[0], "end"), !0;
      }
      return !1;
    },
    _doSelectAll: function (t, e) {
      return this._isAllSelected(e) ? (this._setEditorEmpty(t, e), !0) : e.is("select") ? (t.preventDefault(), this.app.editor.selectAll(), !0) : void 0;
    },
    _doArrow: function (t, e, i) {
      var s,
        n,
        a = i.getType(),
        o = ["pre", "line", "image", "embed", "layer"];
      if (e.is("up-left") && i.isCaretStart()) {
        if (n = "end", !(s = i.getPrev()) && -1 !== o.indexOf(a)) return void this.app.insertion.insertEmptyBlock({
          position: "before",
          caret: "start"
        });
      } else if (e.is("down-right") && i.isCaretEnd() && (n = "start", !(s = i.getNext()) && -1 !== o.indexOf(a))) return void this.app.insertion.insertEmptyBlock({
        position: "after",
        caret: "start"
      });
      s && (t.preventDefault(), this.app.block.set(s, n));
    },
    _isAllSelected: function (t) {
      return this.app.editor.isAllSelected() && t.is(["enter", "delete", "backspace", "alpha", "space"]);
    },
    _setEditorEmpty: function (t, e) {
      e.is(["alpha", "space"]) || t.preventDefault(), this.app.editor.setEmpty();
    },
    _trimInvisibleChar: function (t, e, i) {
      var s,
        n = "left" === e ? "before" : "after",
        a = this.app.selection.get(),
        o = this._isInvisibleChar(n);
      if (o && "left" === e) s = a.current, this.dom(s).replaceWith(s.textContent.replace(/\s+$/, ""));else if (o && i && a.current && a.current.nextSibling) s = a.current.nextSibling, this.dom(s).replaceWith(s.textContent.replace(/^\s+/, ""));else if (o && "right" === e) {
        t.preventDefault();
        var r = this.app.offset.get();
        return this.app.offset.set(!1, {
          start: r.start + 1,
          end: r.end + 1
        }), !0;
      }
    },
    _isInvisibleChar: function (t) {
      var e = this.app.selection.get(),
        i = this.app.selection.getText(t);
      return e.current && 3 === e.current.nodeType && 0 === this.app.utils.searchInvisibleChars(i);
    }
  }), RedactorX.add("module", "insertion", {
    init: function () {
      this._clear();
    },
    start: function () {
      this.win = this.app.$win.get(), this.doc = this.app.$doc.get();
    },
    getFirstInserted: function () {
      return this.inserted.instances[0];
    },
    getLastInserted: function () {
      var t = this.inserted.instances.length,
        e = this.inserted.instances[t - 1];
      return e && e.isFigcaption() && (e = e.getFigure()), e;
    },
    getInserted: function () {
      return this.inserted;
    },
    setContent: function (t) {
      this._insert(t, "set");
      var e = this.getInserted();
      return this.inserted = !1, e;
    },
    insertContent: function (t) {
      this._insert(t, "insert");
      var e = this.getInserted();
      return this.inserted = !1, e;
    },
    insertEmptyBlock: function (t) {
      (t = t || {}).html = this.app.block.createHtml(), this._insert(t, "insert");
      var e = this.getInserted();
      return this.inserted = !1, e;
    },
    insertNewline: function (t, e) {
      var i = e ? "\n\n" : "\n";
      return this._insertFragment({
        node: document.createTextNode(i)
      }, t || "after");
    },
    insertPoint: function (t) {
      var e,
        i = this.app.utils.createInvisibleChar(),
        s = t.clientX,
        n = t.clientY;
      if (this.doc.caretPositionFromPoint) {
        var a = this.doc.caretPositionFromPoint(s, n);
        (e = this.doc.getSelection().getRangeAt(0)).setStart(a.offsetNode, a.offset), e.collapse(!0), e.insertNode(i);
      } else this.doc.caretRangeFromPoint && (e = this.doc.caretRangeFromPoint(s, n)).insertNode(i);
      this.app.caret.set(i, "after");
    },
    insertBreakline: function (t) {
      var e = this.app.selection.getNodes({
        type: "inline"
      });
      return this.app.selection.isCollapsed() && 0 !== e.length ? this._splitInline(e, document.createElement("br")) : this._insertFragment({
        node: document.createElement("br")
      }, t || "after");
    },
    insertNode: function (t, e, i) {
      if (i) {
        var s = this.app.selection.getNodes({
          type: "inline"
        });
        if (0 !== s.length) return this._splitInline(s, t);
      }
      return this._insertFragment({
        node: this.dom(t).get()
      }, e);
    },
    insertHtml: function (t, e) {
      return this._insertFragment({
        html: t
      }, e);
    },
    insertText: function (t, e) {
      var i = this.app.block.get();
      if (!(i && !i.isEditable() || this.app.blocks.isMeta())) {
        var s,
          n = this.win.getSelection();
        if (n.getRangeAt && n.rangeCount) {
          t = this.app.content.getTextFromHtml(t, {
            nl: !0
          }), s = document.createTextNode(t);
          var a = n.getRangeAt(0);
          a.deleteContents(), a.insertNode(s), e = e || "end", this.app.caret.set(s, e);
        }
        return s;
      }
      this.insertContent({
        html: t,
        caret: e
      });
    },
    insertListToList: function (t, e, i) {
      var s = t.find("li"),
        n = s.last();
      s.addClass(this.prefix + "-pasteitems"), n.addClass(this.prefix + "-pastemarker");
      var a = t.children(),
        o = this.dom(this.app.selection.getBlock()),
        r = this.app.caret.is(e, "start"),
        p = this.app.caret.is(e, "end"),
        l = this.app.caret.is(o, "start"),
        h = this.app.caret.is(o, "end", ["ul", "ol"]);
      if (r) e.prepend(a);else if (p) e.append(a);else if (this.app.content.isEmptyHtml(o.html())) o.after(a), o.remove();else if (l) o.before(a);else if (h) {
        var c = o.find("ul, ol");
        0 !== c.length ? c.prepend(a) : o.after(a);
      } else this.app.element.split(o).before(a);
      var d = this.prefix + "-pastemarker",
        u = this.prefix + "-pasteitems";
      return i && (n = this.app.editor.getEditor().find("." + d).removeClass(d), this.app.caret.set(n, "end")), this.app.editor.getEditor().find("." + u).removeClass(u);
    },
    detectPosition: function (t, e) {
      if (e) return e;
      var i = this.app.caret.is(t, "start");
      return e = this.app.caret.is(t, "end") ? "after" : i ? "before" : "split";
    },
    _insert: function (t, e) {
      this.html = t.html, this.html = this.app.broadcastHtml("editor.before.insert", this.html), this.isParse = void 0 === t.parse || t.parse, this.isClean = void 0 !== t.clean && t.clean, this.isCaret = void 0 === t.caret || t.caret, this.isPosition = void 0 !== t.position && t.position, this.isCurrent = void 0 !== t.current && t.current, "set" === e || this.app.editor.isAllSelected() ? this._setContent() : this._insertContent(), this.app.broadcast("editor.insert", this.inserted);
    },
    _insertContent: function () {
      var t,
        e,
        i = this.isCurrent ? this.isCurrent : this.app.block.get(),
        s = !1,
        n = !1;
      if (this._checkEmpty(), this._checkLine(), this.app.blocks.isMeta() || this.app.blocks.is()) {
        if (this.isEmpty) return;
        var a;
        if (this.isLine && (this.html = this.app.block.createHtml(this.html)), this._clean(), this._parse(), this._parseBuild(), t = this._buildParsedNodes(), this.app.blocks.is()) {
          if (!(a = this.app.blocks.getLastSelectedBlock())) return;
          i = (e = this.dom(a)).dataget("instance"), this.app.selection.deleteContents(), this._insertToEditable(i, e, t);
        } else {
          (a = this.app.blocks.getLastSelected()).getBlock().after(t), this.app.blocks.removeSelected(!1);
        }
      } else if (!i || this.isPosition) {
        if (this.isEmpty) return;
        this.isLine && (this.html = this.app.block.createHtml(this.html)), this._clean(), this._parse(), this._parseBuild(), t = this._buildParsedNodes();
        s = "top" === this.isPosition || !this.isPosition && "top" === this.opts.editor.add ? (i = this.app.blocks.getFirst(), "before") : i && -1 !== ["after", "before", "append"].indexOf(this.isPosition) ? this.isPosition : (i = this.app.blocks.getLast(), "after"), (e = i.getBlock())[s](t);
      } else if (this._isListToList(i)) {
        this.app.selection.deleteContents(), this._clean(), this._parse(), this._parseBuild(), e = i.getBlock();
        var o = this.$parsed.children().first();
        this.$nodes = this.insertListToList(o, e, "end"), this.isCaret = !1;
      } else {
        if (!i) return;
        if (i.isEditable()) {
          if (this.isEmpty) return;
          this._clean(), this._cleanSpecial(i), this.isLine ? this._parseLine() : this._parse(), this._parseBuild(), i.isEmpty() ? (n = !0, s = "after") : this.app.selection.deleteContents(), t = this._buildParsedNodes(), e = i.getBlock(), this._insertToEditable(i, e, t, s, n);
        } else {
          if (s = "after", this.isEmpty) return;
          this.isLine && (this.html = this.app.block.createHtml(this.html)), this._clean(), this.isLine ? this._parseLine() : this._parse(), this._parseBuild(), t = this._buildParsedNodes(), (e = i.getBlock())[s](t);
        }
      }
      this._buildInserted(), this._buildEditor(), this._buildCaret();
    },
    _insertToEditable: function (t, e, i, s, n) {
      this.isLine ? (this.$nodes = this._insertFragment({
        fragment: this.$parsed.get()
      }, "end"), this.isCaret = !1) : (this.app.content.isEmptyHtml(e.html()) ? (s = "after", n = !0) : s = this.detectPosition(e, s), "split" === s ? this.app.element.split(e).before(i) : e[s](i), n && t.remove());
    },
    _insertFragment: function (t, e) {
      if (t.html || t.fragment) {
        var i = this.app.fragment.build(t.html || t.fragment);
        this.app.fragment.insert(i);
      } else this.app.fragment.insert(t.node);
      if (e) {
        var s = t.node ? t.node : "start" === e ? i.first : i.last;
        this.app.caret.set(s, e);
      }
      return t.node ? this.dom(t.node) : this.dom(i.nodes);
    },
    _setContent: function () {
      this._checkEmpty(), this._checkLine(), this.isEmpty ? this.html = this.app.block.createHtml() : this.isLine && (this.html = this.app.block.createHtml(this.html)), this._clean(), this._parse(), this._parseBuild();
      var t = this._buildParsedNodes();
      this.app.editor.unsetSelectAll(), this.app.editor.getEditor().html("").append(t), this.isEmpty && this.app.broadcast("editor.empty"), this._buildInserted(), this._buildEditor(), this._buildCaret(), this.app.broadcast("editor.set", this.inserted);
    },
    _splitInline: function (t, e) {
      var i = this.app.element.split(t[0]);
      return i.before(e), "" === i.html() && i.remove(), this.dom(e);
    },
    _buildEditor: function () {
      this.app.editor.build(), this.app.editor.setFocus(), this.app.toolbar.observe(), this.app.context.observe();
    },
    _buildCaret: function () {
      if (this.isCaret) {
        var t,
          e = "end";
        "start" === this.isCaret ? (t = this.getFirstInserted(), e = "start") : t = this.getLastInserted(), t && this.app.block.set(t, e);
      }
    },
    _buildInserted: function () {
      this.inserted = {
        $nodes: this.$nodes,
        instances: []
      }, this.inserted.$nodes.each(this._buildInstance.bind(this));
    },
    _buildInstance: function (t) {
      var e = t.dataget("instance");
      e && this.inserted.instances.push(e);
    },
    _buildParsedNodes: function () {
      return this.$parsed.get().childNodes;
    },
    _clear: function () {
      this.html = !1, this.isLine = !1, this.isEmpty = !1, this.isSplit = !1, this.isClean = !1, this.isParse = !0, this.isCaret = !0, this.isCurrent = !1, this.isPosition = !1;
    },
    _clean: function () {
      this.isClean && (this.html = this.app.cleaner.cleanHtml(this.html));
    },
    _cleanSpecial: function (t) {
      var e,
        i,
        s = t.getType();
      -1 !== ["cell", "address", "figcaption", "quoteitem"].indexOf(s) ? e = !0 : "list" === s && (e = !0, i = ["ul", "ol", "li"]), e && (this.isLine = !0, this.html = this.app.content.addBrToBlocks(this.html), this.html = this.app.content.removeBlockTags(this.html, void 0, i), this.html = this.html.replace(/<br\s?\/?>\n?$/gi, ""));
    },
    _parse: function () {
      this.isParse && (this.html = this.app.parser.parse(this.html, !1));
    },
    _parseLine: function () {
      this.isParse && (this.html = this.app.parser.parseLine(this.html, !1));
    },
    _parseBuild: function () {
      this.$parsed = this.app.parser.build(this.html), this.$nodes = this.$parsed.children();
    },
    _checkEmpty: function () {
      this.isEmpty = this.app.content.isEmptyHtml(this.html);
    },
    _checkLine: function () {
      this.isLine = this.app.content.isLine(this.html);
    },
    _isListToList: function (t) {
      var e = t.getBlock().attr("data-" + this.prefix + "-type"),
        i = this.dom("<div>").html(this.html);
      return i.find("b").unwrap(), i = i.children().first(), "list" === e && 0 !== i.length && -1 !== ["ul", "ol"].indexOf(i.get().tagName.toLowerCase());
    }
  }), RedactorX.add("module", "toolbar", {
    init: function () {
      this.eventname = this.prefix + "-toolbar", this.activeClass = "active", this.toggledClass = "toggled", this.disableClass = "disable", this.customButtons = {}, this.aTags = {}, this.aTypes = {};
    },
    start: function () {
      this.$container = this.app.container.get("toolbar"), this.opts.toolbar && (this._build(), this._buildSticky());
    },
    load: function () {
      this.opts.toolbar && (this._buildActiveButtons(), this.app.block.get() || (this.$toolbar.html(""), this._buildButtons()));
    },
    stop: function () {
      this.$toolbar.remove(), this.customButtons = {}, this.editorButtons = {};
    },
    build: function () {
      if (this.opts.toolbar) {
        var t = this.app.block.get();
        t && t.isSecondLevel() && (t = t.getFirstLevel());
        var e = this.opts.buttons.editor;
        t && t.toolbar && (e = t.toolbar), this.$toolbar.html("");
        var i = this._createButtons(e, t);
        t || this._checkIntialToolbar(i);
      }
    },
    observe: function () {
      if (this.opts.toolbar && (this.unsetActive(), this.app.observer.isButtons())) {
        var t = this.app.observer.buildButtons(this.aTags, this.aTypes);
        this._setActiveKeys(t);
      }
    },
    getElement: function () {
      return this.$toolbar;
    },
    get: function (t) {
      return this._findButton(t);
    },
    add: function (t, e) {
      this.customButtons[t] = e;
    },
    setActive: function (t) {
      this.opts.toolbar && (this._findButtons().removeClass(this.activeClass), this._findButton(t).removeClass(this.disableClass).addClass(this.activeClass));
    },
    setToggled: function (t) {
      this.opts.toolbar && (this._findButtons().removeClass(this.toggledClass), this._findButton(t).removeClass(this.disableClass).addClass(this.toggledClass));
    },
    unsetActive: function (t) {
      this.opts.toolbar && (t ? this._findButton(t) : this._findButtons()).removeClass(this.activeClass);
    },
    unsetToggled: function (t) {
      this.opts.toolbar && (t ? this._findButton(t) : this._findButtons()).removeClass(this.toggledClass);
    },
    enable: function () {
      this.opts.toolbar && this._findButtons().removeClass(this.disableClass);
    },
    disable: function () {
      this.opts.toolbar && this._findButtons().removeClass(this.toggledClass).removeClass(this.activeClass).addClass(this.disableClass);
    },
    disableSticky: function () {
      var t = this.app.container.get("toolbar");
      t.removeClass(this.prefix + "-toolbar-sticky"), t.css("top", "");
    },
    enableSticky: function () {
      if (this.opts.toolbar.sticky) {
        var t = this.app.container.get("toolbar");
        t.addClass(this.prefix + "-toolbar-sticky"), t.css("top", this.opts.toolbar.stickyTopOffset + "px");
      }
    },
    isSticky: function () {
      var t = this.app.container.get("toolbar"),
        e = this.app.container.get("main"),
        i = e.offset().top + parseInt(e.css("border-top-width")),
        s = t.offset().top;
      return i < s || s < i;
    },
    _build: function () {
      this.$toolbar = this.dom("<div>").addClass(this.prefix + "-toolbar"), this.$container.append(this.$toolbar), this.$container.addClass("is-" + this.prefix + "-toolbar");
    },
    _buildSticky: function () {
      this.opts.toolbar.sticky && (this._toggleSticky("add", this.opts.toolbar.stickyTopOffset + "px"), this._startEvent());
    },
    _buildButtons: function () {
      var t = this._createButtons(this.opts.buttons.editor);
      this._checkIntialToolbar(t);
    },
    _buildActiveButtons: function () {
      var t = this.app.observer.buildActiveButtons(this.customButtons);
      this.aTags = t.tags, this.aTypes = t.types;
    },
    _checkIntialToolbar: function (t) {
      if (0 === t && !this.opts.topbar) {
        var e = this.app.blocks.getFirst(),
          i = e.toolbar;
        this.$toolbar.html(""), this._createButtons(i, e), this.disable();
      }
    },
    _findButtons: function () {
      return this.$toolbar.find("." + this.prefix + "-button-toolbar");
    },
    _findButton: function (t) {
      return this.$toolbar.find("[data-name=" + t + "]");
    },
    _createButtons: function (t, e) {
      var i = t;
      e && (i = $R.extend(!0, {}, t, this.customButtons));
      var s = 0;
      for (var n in i) "add" === n && !this.opts.addbar || "html" === n && !this.opts.source || "format" === n && !this.opts.format || e && !e.isAllowedButton(n, i[n]) || this._isHidden(n) || (this.app.create("button", n, i[n], this.$toolbar, "toolbar"), s++);
      return s;
    },
    _isHidden: function (t) {
      return -1 !== this.opts.toolbar.hide.indexOf(t);
    },
    _setActiveKeys: function (t) {
      for (var e = 0; e < t.length; e++) this._findButton(t[e]).addClass(this.activeClass);
    },
    _getObservedTags: function (t, e) {
      var i = [];
      if (t && i.push(t), 0 < e.length) for (var s = 0; s < e.length; s++) i.push(e[s].tagName.toLowerCase());
      return i;
    },
    _toggleSticky: function (t, e) {
      var i = "remove" === t ? "removeClass" : "addClass";
      this.$container[i](this.prefix + "-toolbar-sticky"), this.$container.css("top", e);
    },
    _startEvent: function () {
      this.app.scroll.getTarget().on("scroll." + this.eventname, this._observeSticky.bind(this));
    },
    _stopEvent: function () {
      this.app.scroll.getTarget().off("." + this.eventname);
    },
    _observeSticky: function () {
      if (this.app.source.is()) this.$container.css("top", 0);else {
        var t = this.app.scroll.getTarget(),
          e = this.app.scroll.isTarget() ? parseInt(t.css("padding-top")) : 0;
        this.$container.css("top", 0 - e + this.opts.toolbar.stickyTopOffset + "px"), this.isSticky() ? this.app.broadcast("toolbar.sticky") : this.app.broadcast("toolbar.static");
      }
    }
  }), RedactorX.add("module", "addbar", {
    init: function () {
      this.custom = {};
    },
    popup: function (t, e) {
      if (this.opts.addbar) {
        this.app.popup.create("addbar", {
          width: "380px",
          items: this.buildItems()
        });
        var i = e.isButton || e.isControl ? {
          button: e
        } : {};
        this.app.popup.open(i);
      }
    },
    add: function (t, e) {
      this.custom[t] = e, this.custom[t].container = !0, this.custom[t].command = e.command ? e.command : "block.add", e.template && (this.custom[t].params = {
        template: e.template
      });
    },
    buildItems: function () {
      for (var t = {}, e = this.opts.buttons.addbar, i = this.opts.buttonsObj, s = 0; s < e.length; s++) {
        var n = e[s],
          a = "text" === n ? "paragraph" : n;
        this._isHidden(a) || (t[n] = i[n], t[n].container = !0, t[n].icon = !0, t[n].params = {
          name: a
        });
      }
      for (var o in this.opts.addbar.add) this._isHidden(o) || (t[o] = this.opts.addbar.add[o], t[o].container = !0, t[o].command = "block.add", t[o].params = {
        template: this.opts.addbar.add[o].template
      });
      return t = $R.extend(!0, {}, t, this.custom);
    },
    _isHidden: function (t) {
      return -1 !== this.opts.addbar.hide.indexOf(t);
    }
  }), RedactorX.add("module", "format", {
    popup: function (t, e) {
      for (var i = this.app.block.get(), s = !!i && i.getTag(), n = this.opts.format, a = {}, o = 0; o < n.length; o++) {
        var r = n[o];
        a[r] = {
          title: this.opts.formatObj[r].title,
          params: {
            tag: r
          },
          command: "block.format",
          shortcut: this.opts.formatObj[r].shortcut,
          active: r === s
        };
      }
      this.app.popup.create("format", {
        width: "300px",
        items: a
      }), this.app.popup.open({
        button: e
      });
    },
    set: function (t) {
      if (this.app.popup.close(), !this.app.blocks.isMeta()) {
        var e = {
            type: this.opts.formatObj[t.tag].type,
            tag: t.tag
          },
          i = this.app.blocks.getSelectedBlocks("editable"),
          s = this.app.block.get();
        s ? this.setSingle(s, e) : 0 !== i.length && this.setMultiple(i, e);
      }
    },
    setSingle: function (t, e) {
      var i,
        s = t.isEmpty(),
        n = !!s && "start";
      if (this.tag = t.getTag(), this.type = t.getType(), this.$block = t.getBlock(), s || this.app.selection.saveMarker(), this._isSameTag(e) && (e = this._checkSameFormat(e)), e && (this._isListToText(e, "list") ? i = this._formatListToText(e) : this._isTextToList(e, "list") ? this._formatTextToList(e, n) : this._replaceTo(t, e, n)), s || this.app.selection.restoreMarker(), i) {
        var a = this.app.selection.getDataBlock();
        this.app.block.set(a, n);
      }
      t = this.app.block.get(), this.app.editor.build(), this.app.broadcast("block.format", {
        instance: t
      });
    },
    setMultiple: function (t, i) {
      var e, s, n, a;
      this.app.selection.saveMarker(), this._isListTag(i.tag) && this._isMultipleParagraphs() && (a = (e = this.app.create("block." + i.type, !1, {
        tag: i.tag
      })).getBlock(), this.dom(t[0]).before(a));
      for (var o = 0; o < t.length; o++) if ((n = t[o].tagName.toLowerCase()) !== i.tag) if (a) {
        var r = this.dom("<li>").html(t[o].innerHTML);
        a.append(r), this.dom(t[o]).remove();
      } else if (!this._isListTag(n) && this._isListTag(i.tag)) {
        s = (e = this.app.create("block." + i.type, !1, {
          tag: i.tag
        })).getBlock();
        r = this.dom("<li>").html(t[o].innerHTML);
        s.append(r), (p = this.dom(t[o])).after(s), p.remove();
      } else if (this._isListTag(n) && !this._isListTag(i.tag)) {
        var p,
          l = (p = this.dom(t[o])).find("li");
        l.find("ul, ol").each(function (t) {
          t.parent().after(t);
        }), l.find("ul, ol").unwrap(), l.each(function (t) {
          var e = this.dom("<" + i.tag + ">");
          e.html(t.html()), t.remove(), this.app.create("block." + i.type, e), p.before(e);
        }.bind(this)), p.remove();
      } else (s = this.app.element.replaceToTag(t[o], i.tag)).removeAttr("style class data-" + this.prefix + "-style-cache"), e = this.app.create("block." + i.type, s);
      this.app.selection.restoreMarker(), this.app.editor.build(), this.app.editor.unsetSelectAll(), this.app.broadcast("block.format", {
        instance: e
      });
    },
    _isListTag: function (t) {
      return -1 !== ["ul", "ol"].indexOf(t);
    },
    _isSameTag: function (t) {
      return this.tag === t.tag && this.type === t.type;
    },
    _isMultipleParagraphs: function () {
      var t = this.app.blocks.getSelectedBlocks(),
        e = ["P", "ADDRESS", "H1", "H2", "H3", "H4", "H5", "H6"];
      if (1 < t.length) {
        for (var i = 0, s = 0; s < t.length; s++) -1 === e.indexOf(t[s].tagName) && i++;
        if (0 === i) return !0;
      }
      return !1;
    },
    _checkSameFormat: function (t) {
      return -1 !== ["heading", "list", "address"].indexOf(this.type) ? t = this._buildDefaultFormat() : "paragraph" === this.type && (t = !1), t;
    },
    _buildDefaultFormat: function () {
      return {
        type: "paragraph",
        tag: "p"
      };
    },
    _formatListToText: function (t) {
      var e = this._getListItems();
      return this._createItems(e, t), this.$block.remove(), e;
    },
    _formatTextToList: function (t, e) {
      var i = this.app.create("block." + t.type, "<" + t.tag + ">").getBlock(),
        s = this.dom("<li>").html(this.$block.html());
      i.append(s), this.app.create("block." + t.type, i), this.$block.after(i), this.$block.remove(), this.app.block.set(i, e);
    },
    _replaceTo: function (t, e, i) {
      var s = t.getBlock(),
        n = this.app.element.replaceToTag(s, e.tag);
      n.removeAttr("style class data-" + this.prefix + "-style-cache"), this.app.create("block." + e.type, n), this.app.block.set(n, i);
    },
    _createItems: function (t, i) {
      t.each(function (t) {
        var e = this.dom("<" + i.tag + ">");
        e.html(t.html()), t.remove(), this.app.create("block." + i.type, e), this.$block.before(e);
      }.bind(this));
    },
    _isListToText: function (t, e) {
      return this.type === e && -1 !== ["heading", "address", "paragraph"].indexOf(t.type);
    },
    _isTextToList: function (t, e) {
      return t.type === e && -1 !== ["heading", "address", "paragraph"].indexOf(this.type);
    },
    _getListItems: function () {
      var t = this.$block.find("li");
      return t.find("ul, ol").each(function (t) {
        t.parent().after(t);
      }), t.find("ul, ol").unwrap(), t;
    }
  }), RedactorX.add("module", "embed", {
    popups: {
      add: {
        title: "## embed.embed ##",
        width: "100%",
        form: {
          embed: {
            type: "textarea",
            label: "## embed.description ##",
            rows: 6
          },
          caption: {
            type: "input",
            label: "## embed.caption ##"
          },
          responsive: {
            type: "checkbox",
            text: "## embed.responsive-video ##"
          }
        },
        footer: {
          insert: {
            title: "## buttons.insert ##",
            command: "embed.insert",
            type: "primary"
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      },
      edit: {
        title: "## embed.embed ##",
        width: "100%",
        form: {
          embed: {
            type: "textarea",
            label: "## embed.description ##",
            rows: 6
          },
          caption: {
            type: "input",
            label: "## embed.caption ##"
          },
          responsive: {
            type: "checkbox",
            text: "## embed.responsive-video ##"
          }
        },
        footer: {
          save: {
            title: "## buttons.save ##",
            command: "embed.save",
            type: "primary"
          },
          remove: {
            title: "## buttons.delete ##",
            command: "embed.remove",
            type: "danger",
            right: !0
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    build: function (t) {
      t ? this._callScripts(t) : this._findScripts();
    },
    observe: function () {
      if (!this.opts.embed) return !1;
    },
    popup: function () {
      this.app.popup.create("embed", this.popups.add), this.app.popup.open({
        focus: "embed"
      }), this._buildCodemirror();
    },
    edit: function (t) {
      var e = this.app.block.get(),
        i = {
          embed: e.getEmbedCode(),
          caption: e.getCaption(),
          responsive: e.isResponsive()
        };
      this.app.popup.create("embed", this.popups.edit), this.app.popup.setData(i), this.app.popup.open({
        focus: "embed"
      }), this._buildCodemirror();
    },
    insert: function () {
      this.app.popup.close();
      var t = this.app.popup.getData(),
        e = this._getEmbedCode(t);
      if ("" !== e) {
        var i = this._createInstance(t, e);
        this.app.block.add({
          instance: i
        });
      }
    },
    save: function () {
      this.app.popup.close();
      var t = this.app.block.get(),
        e = this.app.popup.getData(),
        i = this._getEmbedCode(e);
      if ("" !== i) {
        var s = this._createInstance(e, i, t);
        this._isNeedToChange(e, s, t) && this.app.block.change(s);
      } else this.app.block.remove();
    },
    remove: function () {
      this.app.popup.close(), this.app.block.remove();
    },
    _buildCodemirror: function () {
      var t = this.app.popup.getInput("embed");
      this.app.codemirror.create({
        el: t,
        height: "200px",
        focus: !0
      }), this.app.popup.updatePosition();
    },
    _findScripts: function () {
      var t = this.app.editor.getEditor().find("[data-" + this.prefix + "-type=embed]").find("script").getAll();
      this.build.call(this, t);
    },
    _callScripts: function (scripts) {
      for (var i = 0; i < scripts.length; i++) if ("" !== scripts[i].src) {
        var src = scripts[i].src;
        this.app.$doc.find('head script[src="' + src + '"]').remove();
        var $script = this.dom("<script>").attr({
          src: src,
          async: !0,
          defer: "true"
        });
        $script.on("load", function () {
          if (-1 !== src.search("instagram")) {
            var t = this.app.$win.get();
            t.instgrm && t.instgrm.Embeds.process();
          }
          this.build(scripts.slice(i + 1));
        }.bind(this));
        var head = this.app.$doc.get().getElementsByTagName("head")[0];
        head && head.appendChild($script.get());
      } else try {
        eval(scripts[i].innerHTML);
      } catch (t) {}
    },
    _getEmbedCode: function (t) {
      var e = t.embed.trim();
      return e = this.app.codemirror.val(e), e = this.app.content.sanitize(e), e = this._isHtmlString(e) || "" === e ? e : this._parseUrl(e);
    },
    _createInstance: function (t, e, i) {
      var s;
      i ? (s = i.duplicate().getBlock()).html(e) : s = this._isFigure(e) ? e : "<figure>" + e + "</figure>";
      var n = this.app.create("block.embed", s);
      return n.setCaption(t.caption), t.responsive && n.addResponsive(), n;
    },
    _parseUrl: function (t) {
      var e = '<iframe width="560" height="315" src="',
        i = '" frameborder="0" allowfullscreen></iframe>';
      return t.match(this.opts.regex.youtube) ? e + t.replace(this.opts.regex.youtube, "//www.youtube.com/embed/$1") + i : t.match(this.opts.regex.vimeo) ? e + t.replace(this.opts.regex.vimeo, "//player.vimeo.com/video/$2") + i : t;
    },
    _isNeedToChange: function (t, e, i) {
      return i.getEmbedCode() !== e.getEmbedCode() || t.responsive !== i.isResponsive() || t.caption !== i.getCaption() || void 0;
    },
    _isHtmlString: function (t) {
      return /^\s*<(\w+|!)[^>]*>/.test(t);
    },
    _isFigure: function (t) {
      return /^<figure/.test(t);
    }
  }), RedactorX.add("module", "image", {
    popups: {
      add: {
        title: "## popup.add-image ##",
        width: "100%"
      },
      edit: {
        title: "## popup.image ##",
        width: "100%",
        getter: "block.getData",
        setter: "block.setData",
        form: {
          alt: {
            type: "input",
            label: "## image.alt-text ##"
          },
          caption: {
            type: "input",
            label: "## image.caption ##",
            observer: "image.observeImageCaption"
          },
          link: {
            type: "input",
            label: "## image.link ##",
            observer: "image.observeImageLink"
          },
          target: {
            type: "checkbox",
            text: "## image.link-in-new-tab ##",
            observer: "image.observeImageLink"
          }
        },
        footer: {
          save: {
            title: "## buttons.save ##",
            command: "image.save",
            type: "primary"
          },
          remove: {
            title: "## buttons.delete ##",
            command: "image.remove",
            type: "danger",
            right: !0
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    init: function () {
      this.dataStates = [];
    },
    popup: function () {
      this.app.popup.create("image", this.popups.add);
      var t = this.app.popup.getBody();
      this._createImageByUrl(t), this._createOrSection(t), this._createUploadBox(t), this._createSelectBox(t), this.app.popup.open();
    },
    edit: function (t) {
      this.app.popup.create("image-edit", this.popups.edit), this._buildEditUpload(), this.app.popup.open();
    },
    observe: function () {
      if (!this.isImagePopup()) return !1;
    },
    observeStates: function () {
      this._findImages().each(this._addImageState.bind(this));
    },
    observeImageLink: function (t) {
      return !!this.opts.image.link && t;
    },
    observeImageCaption: function (t) {
      var e = this.app.block.get();
      return !(!e || "figure" !== e.getTag()) && t;
    },
    isImagePopup: function () {
      return !1 !== this.opts.image;
    },
    paste: function (t, e) {
      var i = {
        url: this.opts.image.upload,
        name: this.opts.image.name,
        data: this.opts.image.data,
        multiple: this.opts.image.multiple,
        success: "image.insertFromBlob",
        error: "image.error"
      };
      this.app.create("upload").send(e, [t], i);
    },
    drop: function (t, e) {
      for (var i = [], s = 0; s < e.files.length; s++) {
        var n = e.files[s] || e.items[s].getAsFile();
        n && i.push(n);
      }
      var a = {
        url: this.opts.image.upload,
        name: this.opts.image.name,
        data: this.opts.image.data,
        multiple: this.opts.image.multiple,
        success: "image.insertByDrop",
        error: "image.error"
      };
      if (0 < i.length) {
        var o = this.dom(t.target).closest("[data-" + this.prefix + "-type]");
        0 !== o.length && this.app.block.set(o), this.app.create("upload").send(t, i, a);
      }
    },
    insertFromClipboard: function (t) {
      var e = t.getData("text/plain") || t.getData("text/html");
      if ("" === (e = e.trim())) {
        for (var i = t.items, s = null, n = 0; n < i.length; n++) 0 === i[n].type.indexOf("image") && (s = i[n].getAsFile());
        return null !== s ? (this.paste(s), !0) : void 0;
      }
    },
    insertFromBlob: function (t) {
      this.insert(t);
    },
    insertByDrop: function (t, e) {
      if (this.app.block.is()) {
        var i = this.app.block.get(),
          s = e.target,
          n = i.getType();
        if ("card" === n && s && "IMG" === s.tagName && i.hasImage() || "image" === n) return void this.change(t);
        e && "card" !== n && i.isEditable() && this.app.insertion.insertPoint(e);
      }
      this.insert(t);
    },
    insertByUpload: function (t) {
      this.insert(t);
    },
    insertByUrl: function (t) {
      t.preventDefault();
      var e = this.$urlinput.val();
      if ("" !== e.trim()) {
        var i = {
          file: {
            url: e,
            id: this.app.utils.getRandomId()
          }
        };
        this.insert(i);
      }
    },
    insertFromSelect: function (t) {
      t.preventDefault();
      var e = this.dom(t.target),
        i = {
          url: e.attr("data-url")
        },
        s = e.attr("data-id");
      null !== s && (i.id = s), this.insert({
        file: i
      }, !0);
    },
    changeClone: function (t) {
      for (var e in t) {
        this.$imageclone.attr("src", t[e].url);
        break;
      }
      this.change(t, !1);
    },
    change: function (t, e) {
      !1 !== e && this.app.popup.close();
      var i = this.app.block.get();
      for (var s in t) return i.setImage(t[s]), this.app.broadcast("image.change", t[s]), void this.app.broadcast("image.upload", {
        instance: i,
        data: t[s]
      });
      this.app.parser.buildPredefinedClasses();
    },
    save: function (t) {
      this.app.popup.close(), this.app.block.setData(t);
    },
    insert: function (t, e) {
      this.app.popup.close(), this.imageslen = 0, this.imagescount = 0;
      var i = this.opts.image.tag;
      for (var s in t) {
        var n = this.dom("<" + i + ">"),
          a = this._createImageFromResponseItem(t[s]);
        n.append(a);
        var o = this.app.create("block.image", n);
        this.app.block.add({
          instance: o
        });
        var r = e ? "select" : "upload";
        this.app.broadcast("image." + r, {
          instance: o,
          data: t[s]
        }), this.$last = o.getBlock(), this.imageslen++;
      }
    },
    remove: function () {
      this.app.popup.close(), this.app.block.remove();
    },
    error: function (t) {
      this.app.broadcast("image.upload.error", {
        response: t
      });
    },
    getStates: function () {
      var t = this._findImages();
      for (var e in this.dataStates) {
        var i = this.dataStates[e],
          s = t.is('[data-image="' + i.id + '"]');
        this._setImageState(i.id, s);
      }
      return this.dataStates;
    },
    createUploadBox: function (t, e) {
      if (t) {
        var i = this.dom("<div>");
        return e.append(i), i;
      }
    },
    createSelectBox: function (t, e, i) {
      if (t) if (this.$selectbox = this._createImagesBox(e), "object" == typeof t) this._parseList(t, i);else {
        var s = this.opts.editor.reloadmarker ? {
          d: new Date().getTime()
        } : {};
        this.ajax.get({
          url: t,
          data: s,
          success: function (t) {
            this._parseList(t, i);
          }.bind(this)
        });
      }
    },
    _findImages: function () {
      return this.app.editor.getEditor().find("[data-image]");
    },
    _addImageState: function (t) {
      var e = t.attr("data-image");
      this.dataStates[e] = {
        type: "image",
        status: !0,
        url: t.attr("src"),
        $img: t,
        id: e
      };
    },
    _setImageState: function (t, e) {
      this.dataStates[t].status = e;
    },
    _checkImageLoad: function () {
      this.imagescount++, this.imagescount === this.imageslen && (this.app.block.unset(), this.app.block.set(this.$last));
    },
    _buildEditUpload: function () {
      if (this.opts.image.upload) {
        var t = this.app.block.get(),
          e = this.app.popup.getBody(),
          i = this._createFormItem();
        i.addClass(this.prefix + "-form-item-edit-image-box"), this.$imageclone = t.getImage().clone();
        var s = this.dom("<div>").addClass(this.prefix + "-form-item-image");
        s.append(this.$imageclone), i.append(s), this.$upload = this.dom("<div>"), i.append(this.$upload), e.prepend(i), this._buildUpload(this.$upload, "image.changeClone");
      }
    },
    _buildUpload: function (t, e) {
      if (this.opts.image.upload) {
        var i = {
          box: !0,
          placeholder: this.lang.get("image.upload-new-placeholder"),
          url: this.opts.image.upload,
          name: this.opts.image.name,
          data: this.opts.image.data,
          multiple: this.opts.image.multiple,
          success: e,
          error: "image.error"
        };
        this.app.create("upload", t, i);
      }
    },
    _createSelectBox: function (t) {
      this.createSelectBox(this.opts.image.select, t, "image.insertFromSelect");
    },
    _createUploadBox: function (t) {
      this.$upload = this.createUploadBox(this.opts.image.upload, t), this._buildUpload(this.$upload, "image.insertByUpload");
    },
    _createImageFromResponseItem: function (t) {
      var e = this.dom("<img>").attr("src", t.url).one("load", this._checkImageLoad.bind(this));
      return Object.prototype.hasOwnProperty.call(t, "id") && e.attr("data-image", t.id), Object.prototype.hasOwnProperty.call(t, "2x") && e.attr("srcset", t["2x"] + " 2x"), e;
    },
    _createImagesBox: function (t) {
      var e = this.dom("<div>").addClass(this.prefix + "-popup-images-box");
      return t.append(e), e;
    },
    _createOrSection: function (t) {
      if (this.opts.image.url && (this.opts.image.upload || this.opts.image.select)) {
        var e = this.dom("<div>").addClass(this.prefix + "-popup-image-section-or");
        e.html(this.lang.get("image.or")), t.append(e);
      }
    },
    _createImageByUrl: function (t) {
      if (this.opts.image.url) {
        var e = this._createFormItem();
        this.$urlinput = this._createUrlInput(), this.$urlbutton = this._createUrlButton(), e.append(this.$urlinput), e.append(this.$urlbutton), t.append(e), this.$urlinput.focus();
      }
    },
    _createFormItem: function () {
      return this.dom("<div>").addClass(this.prefix + "-form-container-flex");
    },
    _createUrlInput: function () {
      var t = this.dom("<input>").addClass(this.prefix + "-form-input");
      return t.attr("placeholder", this.lang.get("image.url-placeholder")), t;
    },
    _createUrlButton: function () {
      var t = this.dom("<button>").addClass(this.prefix + "-form-button " + this.prefix + "-form-button-primary");
      return t.html(this.lang.get("buttons.insert")), t.one("click", this.insertByUrl.bind(this)), t;
    },
    _parseList: function (t, e) {
      for (var i in t) {
        var s = t[i];
        if ("object" == typeof s) {
          var n = this.dom("<img>"),
            a = s.thumb ? s.thumb : s.url;
          n.addClass(this.prefix + "-popup-event"), n.attr("src", a), n.attr("data-url", s.url), n.attr("data-callback", e), Object.prototype.hasOwnProperty.call(s, "id") && n.attr("data-id", s.id), n.on("click." + this.prefix + "-popup-event-" + this.uuid, function (t) {
            var e = this.dom(t.target).attr("data-callback");
            this.app.api(e, t);
          }.bind(this)), this.$selectbox.append(n);
        }
      }
    }
  }), RedactorX.add("module", "inline", {
    removeFormat: function () {
      this.app.popup.close();
      var t = this.app.block.get().getBlock();
      this.app.selection.save(t);
      for (var e = this.app.selection.getNodes({
          type: "inline"
        }), i = 0; i < e.length; i++) {
        var s = this.dom(e[i]);
        s.attr("data-" + this.prefix + "-type") || s.unwrap();
      }
      this.app.selection.restore(), this.app.toolbar.observe(), this.app.context.observe();
    },
    set: function (t) {
      if (this.app.popup.isOpen() && this.app.popup.close(), !(this.app.blocks.isMeta() || this.app.blocks.is() || this.app.editor.isAllSelected())) {
        this.params = t;
        var e = [];
        return e = this.app.selection.get().collapsed ? this.formatCollapsed() : this.formatUncollapsed(), this.app.toolbar.observe(), this.app.context.observe(), this.app.broadcast("inline.format", {
          nodes: e
        }), e;
      }
    },
    formatCollapsed: function () {
      var t,
        e = this.app.selection.getInline(),
        i = this.dom(e),
        s = this._getParamsTags(),
        n = this._isSameTag(e, s),
        a = !(!this.params || !this.params.caret) && this.params.caret;
      if (e) {
        if (this.app.content.isEmptyHtml(e.innerHTML)) {
          if (n) this.app.caret.set(e, a || "after"), i.remove();else {
            var o = this.app.element.replaceToTag(e, this.params.tag);
            this.app.caret.set(o, a || "start");
          }
        } else if (n) {
          var r = this.app.content.extractHtmlFromCaret(e),
            p = this.dom("<" + this.params.tag + " />");
          p = this.app.element.cloneAttrs(e, p), i.after(p.append(r)), this.app.caret.set(p, a || "before");
        } else t = this._insertInline(this.params.tag, a);
      } else t = this._insertInline(this.params.tag, a);
      if (t && this.params && void 0 !== this.params.attr) {
        var l = this.dom(t);
        for (var h in this.params.attr) l.attr(h, this.params.attr[h]);
      }
      return t || [];
    },
    formatUncollapsed: function () {
      var t = this.app.block.get(),
        e = t.getBlock();
      this.app.selection.save(e), this._convertTags("u", t), this._convertTags("del", t), this.app.selection.restore();
      var i = this.app.selection.getNodes({
        type: "inline"
      });
      this.app.selection.save(e), this._convertToStrike(i), this.app.selection.restore(), this.app.selection.save(e), document.execCommand("strikethrough");
      var s = this._revertToInlines(t);
      this.app.selection.restore();
      for (var n = [], a = this.app.selection.getText(), o = 0; o < s.length; o++) this._isInSelection(s[o], a) && n.push(s[o]);
      if (this._clearEmptyStyle(), this.params && void 0 !== this.params.attr) for (var r = 0; r < n.length; r++) for (var p in this.params.attr) n[r].setAttribute(p, this.params.attr[p]);
      if (this.app.selection.save(e), e.get().normalize(), this._revertTags("u", t), this._revertTags("del", t), this.app.selection.restore(), this.params && this.params.caret) {
        var l = n[n.length - 1];
        this.app.caret.set(l, this.params.caret);
      }
      return n;
    },
    _clearEmptyStyle: function () {
      for (var t = this.app.selection.getNodes({
          type: "inline"
        }), e = 0; e < t.length; e++) {
        this._clearEmptyStyleAttr(t[e]);
        var i = t[e].childNodes;
        if (i) for (var s = 0; s < i.length; s++) this._clearEmptyStyleAttr(i[s]);
      }
    },
    _clearEmptyStyleAttr: function (t) {
      3 !== t.nodeType && "" === t.getAttribute("style") && t.removeAttribute("style");
    },
    _isSameTag: function (t, e) {
      return t && -1 !== e.indexOf(t.tagName.toLowerCase());
    },
    _isInSelection: function (t, e) {
      var i = this.app.utils.removeInvisibleChars(t.textContent);
      return -1 !== e.search(new RegExp(this.app.utils.escapeRegExp(i)));
    },
    _insertInline: function (t, e) {
      return this.app.insertion.insertNode(document.createElement(t), e || "start");
    },
    _convertTags: function (e, t) {
      this.params.tag !== e && t.getBlock().find(e).each(function (t) {
        this.app.element.replaceToTag(t, "span").addClass(this.prefix + "-convertable-" + e);
      }.bind(this));
    },
    _revertTags: function (i, t) {
      t.getBlock().find("span." + this.prefix + "-convertable-" + i).each(function (t) {
        var e = this.app.element.replaceToTag(t, i);
        e.removeClass(this.prefix + "-convertable-" + i), this.app.element.removeEmptyAttrs(e, ["class"]) && e.removeAttr("class");
      }.bind(this));
    },
    _convertToStrike: function (t) {
      for (var e = this._getParamsTags(), i = 0; i < t.length; i++) {
        var s = t[i],
          n = this.dom(s),
          a = t[i].tagName.toLowerCase();
        -1 !== e.indexOf(a) && this._replaceToStrike(n);
      }
    },
    _getParamsTags: function () {
      var t = [this.params.tag];
      return "b" === this.params.tag || "strong" === this.params.tag ? t = ["b", "strong"] : "i" !== this.params.tag && "em" !== this.params.tag || (t = ["i", "em"]), t;
    },
    _replaceToStrike: function (t) {
      t.replaceWith(function () {
        return this.dom("<strike>").append(t.html());
      }.bind(this));
    },
    _revertToInlines: function (t) {
      var i = [];
      return t.getBlock().find("strike").each(function (t) {
        var e = this.app.element.replaceToTag(t, this.params.tag);
        i.push(e.get());
      }.bind(this)), i;
    }
  }), RedactorX.add("module", "link", {
    popups: {
      format: {
        items: {
          format: {
            title: "## link.link ##",
            command: "link.format",
            shortcut: "Ctrl+k"
          },
          unlink: {
            title: "## link.unlink ##",
            command: "link.unlink"
          }
        }
      },
      change: {
        items: {
          edit: {
            title: "## link.edit-link ##",
            command: "link.format",
            shortcut: "Ctrl+k"
          },
          unlink: {
            title: "## link.unlink ##",
            command: "link.unlink"
          }
        }
      },
      create: {
        title: "## popup.link ##",
        width: "600px",
        form: {
          text: {
            type: "input",
            label: "## link.text ##"
          },
          url: {
            type: "input",
            label: "## link.url ##"
          },
          target: {
            type: "checkbox",
            text: "## link.link-in-new-tab ##"
          }
        },
        footer: {
          insert: {
            title: "## buttons.insert ##",
            command: "link.insert",
            type: "primary"
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      },
      edit: {
        title: "## popup.link ##",
        width: "600px",
        form: {
          text: {
            type: "input",
            label: "## link.text ##"
          },
          url: {
            type: "input",
            label: "## link.url ##"
          },
          target: {
            type: "checkbox",
            text: "## link.link-in-new-tab ##"
          }
        },
        footer: {
          save: {
            title: "## buttons.save ##",
            command: "link.save",
            type: "primary"
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    popup: function (t, e) {
      var i = 0 === this.getLink().length ? "format" : "change";
      this.app.popup.create("link-items", this.popups[i]), this.app.popup.open({
        button: e
      });
    },
    format: function (t) {
      this.app.selection.restore();
      var e = this.getLink(),
        i = 0 !== e.length,
        s = this.app.selection.getText();
      this.app.selection.save();
      var n = i ? "edit" : "create";
      if (this.app.popup.create("link", this.popups[n]), i) {
        var a = {
          text: e.text(),
          url: e.attr("href"),
          target: e.attr("target") || this.opts.link.target
        };
        a = this._encodeUrl(a), this.app.popup.setData(a);
      } else this.app.popup.setData({
        text: s
      });
      var o = i ? {
        focus: "url"
      } : {
        focus: s ? "url" : "text"
      };
      this.app.popup.open(o);
    },
    insert: function () {
      this.app.popup.close();
      var t = this.app.inline.set({
          tag: "a",
          caret: "after"
        }),
        e = this.dom(t);
      this._save(e, "add");
    },
    save: function () {
      this.app.popup.close();
      var t = this.getLink();
      this._save(t, "change");
    },
    unlink: function () {
      this.app.popup.close();
      var t = this.app.selection.getNodes({
        tags: ["a"]
      });
      if (0 !== t.length) {
        for (var e = 0; e < t.length; e++) {
          var i = this.dom(t[e]);
          this.app.broadcast("link.remove", {
            url: i.attr("href"),
            text: i.text()
          }), i.unwrap();
        }
        this.app.toolbar.observe(), this.app.context.observe();
      }
    },
    getLink: function () {
      var t = this.app.selection.getNodes({
        tags: ["a"]
      });
      return 0 !== t.length ? this.dom(t[0]) : this.dom();
    },
    _save: function (t, e) {
      var i = this.app.popup.getData();
      i = this._cleanUrl(i), "" !== (i = this._encodeUrl(i)).url ? (i = this._setUrl(t, i), 1 === t.length && (i = this._setText(t, i)), i = this._setTarget(t, i), this.app.broadcast("link." + e, i)) : t.unwrap();
    },
    _cleanUrl: function (t) {
      return t.url = this.app.content.escapeHtml(t.url), t.url = -1 !== t.url.search(/^javascript:/i) ? "" : t.url, t;
    },
    _encodeUrl: function (t) {
      return t.url = t.url.replace(/&amp;/g, "&"), t;
    },
    _setUrl: function (t, e) {
      return t.attr("href", e.url), e;
    },
    _setText: function (t, e) {
      return e.text = "" === e.text ? e.url : e.text, t.text(e.text), e;
    },
    _setTarget: function (t, e) {
      return e.target ? t.attr("target", "_blank") : t.removeAttr("target"), e;
    }
  }), RedactorX.add("module", "table", {
    popups: {
      cell: {
        title: "## table.column ##",
        width: "300px",
        form: {
          width: {
            type: "input",
            label: "## table.width ##"
          },
          nowrap: {
            type: "checkbox",
            text: "## table.nowrap ##"
          }
        },
        footer: {
          insert: {
            title: "## buttons.save ##",
            command: "table.save",
            type: "primary"
          },
          cancel: {
            title: "## buttons.cancel ##",
            command: "popup.close"
          }
        }
      }
    },
    items: {
      addhead: {
        title: "## table.add-head ##",
        command: "table.addHead"
      },
      addcolumnafter: {
        title: "## table.add-column-after ##",
        command: "table.addColumnAfter"
      },
      addcolumnbefore: {
        title: "## table.add-column-before ##",
        command: "table.addColumnBefore"
      },
      addrowbelow: {
        title: "## table.add-row-below ##",
        command: "table.addRowBelow"
      },
      addrowabove: {
        title: "## table.add-row-above ##",
        command: "table.addRowAbove"
      },
      removehead: {
        title: "## table.remove-head ##",
        command: "table.removeHead",
        divider: "top"
      },
      removecolumn: {
        title: "## table.remove-column ##",
        command: "table.removeColumn"
      },
      removerow: {
        title: "## table.remove-row ##",
        command: "table.removeRow"
      },
      removetable: {
        title: "## table.delete-table ##",
        command: "table.removeTable",
        divider: "top"
      }
    },
    add: function () {
      var t = this.app.block.add({
        name: "table",
        source: this.opts.table.template,
        caret: "start"
      }).getFirstCell();
      t && this.app.caret.set(t.getBlock(), "start");
    },
    observe: function () {
      if (!this.opts.table) return !1;
    },
    popup: function (t, e) {
      this.app.popup.create("table", {
        items: this.items
      }), this.app.popup.open({
        button: e
      });
    },
    addHead: function () {
      var t = this.app.block.get().getTable().getBlock();
      this.removeHead();
      var e = t.find("tr").first().children("td, th").length,
        i = this.dom("<thead>"),
        s = this._buildRow(!1, e, "<th>");
      i.append(s), t.prepend(i), this.app.block.set(s.children("td, th").first(), "start");
    },
    addRowBelow: function () {
      this._addRow("below");
    },
    addRowAbove: function () {
      this._addRow("above");
    },
    addColumnBefore: function () {
      this._addColumn("before");
    },
    addColumnAfter: function () {
      this._addColumn("after");
    },
    removeTable: function () {
      this.app.popup.close(), this.app.block.remove();
    },
    removeHead: function () {
      this.app.popup.close();
      var t = this.app.block.get().getTable(),
        e = t.getBlock().find("thead");
      0 !== e.length && e.remove(), t.getFirst().setCaret("start");
    },
    removeRow: function () {
      this.app.popup.close(), this.app.control.close(), this.app.block.get().getRow().remove();
    },
    removeColumn: function () {
      this.app.popup.close(), this.app.control.close();
      var i = this.app.block.get().getBlock(),
        t = i.closest("table"),
        e = i.closest("tr"),
        s = 0;
      e.find("td, th").each(function (t, e) {
        t.get() === i.get() && (s = e);
      }), t.find("tr").each(function (t) {
        var e = t.find("td, th").get(s);
        this.dom(e).remove();
      }.bind(this));
    },
    cellSetting: function (t, e) {
      var i = this.app.block.get();
      this.app.popup.create("cell", this.popups.cell).setData({
        width: i.getWidth(),
        nowrap: i.getNowrap()
      }), this.app.popup.open({
        button: e,
        focus: "width"
      });
    },
    save: function (t) {
      this.app.popup.close();
      var e = t.getData(),
        i = this.app.block.get();
      "" !== e.width && i.setWidth(e.width), i.setNowrap(e.nowrap);
    },
    _addColumn: function (a) {
      this.app.popup.close();
      var i = this.app.block.get().getBlock(),
        t = i.closest("table"),
        s = i.closest("tr"),
        o = 0;
      s.find("td, th").each(function (t, e) {
        t.get() === i.get() && (o = e);
      });
      var r,
        p = 0;
      t.find("tr").each(function (t, e) {
        t.get() === s.get() && (p = e);
      }), t.find("tr").each(function (t, e) {
        var i = t.find("td, th").get(o),
          s = this.dom(i),
          n = s.clone();
        n.html(""), this.app.create("block.cell", n), p === e && (r = n), s[a](n);
      }.bind(this)), r && this.app.block.set(r, "start");
    },
    _addRow: function (t) {
      this.app.popup.close();
      var e = "below" === t ? "after" : "before",
        i = this.app.block.get().getBlock(),
        s = i.closest("tr"),
        n = i.closest("thead"),
        a = s.children("td, th").length,
        o = this._buildRow(s, a, "<td>");
      0 !== n.length ? n.after(o) : s[e](o), this.app.block.set(o.find("td, th").first(), "start");
    },
    _buildRow: function (t, e, i) {
      if (!1 === t) {
        t = this.dom("<tr>");
        for (var s = 0; s < e; s++) {
          var n = this.dom(i);
          this.app.create("block.cell", n), t.append(n);
        }
      } else (t = t.clone()).find("td, th").html("");
      return this.app.create("block.row", t), t.find("td, th").each(function (t) {
        this.app.create("block.cell", t);
      }.bind(this)), t;
    }
  }), RedactorX.add("module", "context", {
    init: function () {
      this.customButtons = {}, this.activeClass = "active", this.eventname = "." + this.prefix + "-context", this.aTags = {}, this.aTypes = {};
    },
    start: function () {
      this.opts.context && this._build();
    },
    stop: function () {
      this.app.scroll.isTarget() && this.app.scroll.getTarget().off(this.eventname);
    },
    isOpen: function () {
      this.$context.hasClass("open");
    },
    close: function () {
      this.opts.context && this._close();
    },
    add: function (t, e) {
      this.customButtons[t] = e;
    },
    observe: function () {
      this.opts.context && this._observe();
    },
    _doOpen: function (t) {
      !1 === this.opts.context || this.app.utils.isMobile() || setTimeout(function () {
        this._isSelection() ? this._open(t) : this._close();
      }.bind(this), 0);
    },
    _open: function (t) {
      this.$context.html(""), this._buildButtons(), this._buildCustomButtons(), this._buildActiveButtons(), this._buildPosition(t), this._buildEvents(), this._observe();
    },
    _close: function () {
      this.$context.removeClass("open"), this.$context.hide(), this.app.editor.getEditor().off(this.eventname), this.app.scroll.isTarget() && this.app.scroll.getTarget().off(this.eventname);
    },
    _isSelection: function () {
      if (this.app.blocks.is()) return !1;
      var t = this.app.block.get(),
        e = t && t.isEditable(),
        i = t && "pre" !== t.getType();
      return e && i && this.app.selection.is() && !this.app.selection.isCollapsed();
    },
    _scroll: function () {
      var t = this.app.selection.getPosition().bottom + this.app.$doc.scrollTop();
      if (this.$context.css("top", t + 2 + "px"), this.app.scroll.isTarget()) {
        var e = this.app.scroll.getTarget(),
          i = e.offset().top + e.height(),
          s = e.offset().top;
        i < t + this.$context.height() || t < s ? this.$context.hide() : this.$context.show();
      }
    },
    _build: function () {
      this.$context = this.dom("<div>").addClass(this.prefix + "-context " + this.prefix + "-context-" + this.uuid).hide(), this.app.$body.append(this.$context), this.app.editor.getEditor().on("mouseup." + this.prefix + "-context-up", this._doOpen.bind(this));
    },
    _buildButtons: function () {
      for (var t = this.opts.buttons.context, e = 0; e < t.length; e++) {
        var i = t[e],
          s = this.opts.buttonsObj[i];
        this.app.create("button", i, s, this.$context, "context");
      }
    },
    _buildCustomButtons: function () {
      var t = this.customButtons;
      for (var e in t) this.app.create("button", e, t[e], this.$context, "context");
    },
    _buildPosition: function (t) {
      var e = this.app.selection.getPosition(),
        i = this.$context.width(),
        s = e.bottom + this.app.$doc.scrollTop(),
        n = this.app.editor.getRect(),
        a = t.pageX - i / 2;
      a < n.left && (a = n.left + 4), a + i > n.right && (a = n.right - i - 4), this.$context.css({
        left: a + "px",
        top: s + 2 + "px"
      }), this.$context.addClass("open"), this.$context.show();
    },
    _buildEvents: function () {
      var t = this.app.editor.getEditor();
      t.on("mousedown" + this.eventname, this._close.bind(this)), t.on("keydown" + this.eventname, this._close.bind(this)), this.app.scroll.isTarget() && this.app.scroll.getTarget().on("scroll" + this.eventname, this._scroll.bind(this));
    },
    _buildActiveButtons: function () {
      var t = this.app.observer.buildActiveButtons(this.customButtons);
      this.aTags = t.tags, this.aTypes = t.types;
    },
    _observe: function () {
      if (this._unsetActive(), this.app.observer.isButtons()) {
        var t = this.app.observer.buildButtons(this.aTags, this.aTypes);
        this._setActiveKeys(t);
      }
    },
    _setActiveKeys: function (t) {
      for (var e = 0; e < t.length; e++) this._findButton(t[e]).addClass(this.activeClass);
    },
    _unsetActive: function () {
      this._findButtons().removeClass(this.activeClass);
    },
    _findButtons: function () {
      return this.$context.find("." + this.prefix + "-button-context");
    },
    _findButton: function (t) {
      return this.$context.find("[data-name=" + t + "]");
    }
  }), RedactorX.add("module", "topbar", {
    init: function () {
      this.activeClass = "active", this.toggledClass = "disable", this.disableClass = "disable", this.customButtons = {};
    },
    start: function () {
      this._isTopbar() && this._build();
    },
    load: function () {
      this._buildButtons();
    },
    get: function (t) {
      return this._findButton(t);
    },
    add: function (t, e) {
      this.customButtons[t] = e;
    },
    setToggled: function (t) {
      this._isTopbar() && (this._findButtons().removeClass(this.toggledClass), this._findButton(t).addClass(this.toggledClass));
    },
    unsetToggled: function (t) {
      this._isTopbar() && (t ? this._findButton(t) : this._findButtons()).removeClass(this.toggledClass);
    },
    enable: function () {
      this._isTopbar() && this._findButtons().removeClass(this.disableClass);
    },
    disable: function () {
      this._isTopbar() && this._findButtons().removeClass(this.toggledClass).removeClass(this.activeClass).addClass(this.disableClass);
    },
    _isTopbar: function () {
      return this.opts.topbar;
    },
    _build: function () {
      this.$topbar = this.dom("<div>").addClass(this.prefix + "-topbar"), this.app.container.get("toolbar").append(this.$topbar);
    },
    _buildButtons: function () {
      for (var t = {}, e = this.opts.buttons.topbar, i = 0; i < e.length; i++) {
        var s = e[i];
        t[s] = this.opts.buttonsObj[s];
      }
      for (var n in t = $R.extend(!0, {}, t, this.customButtons), this.opts.topbar.add && (t = $R.extend(!0, {}, t, this.opts.topbar.add)), t) this.opts.topbar.hide && -1 !== this.opts.topbar.hide.indexOf(n) || this.app.create("button", n, t[n], this.$topbar, "topbar");
    },
    _findButtons: function () {
      return this.$topbar.find("." + this.prefix + "-button-topbar");
    },
    _findButton: function (t) {
      return this.$topbar.find("[data-name=" + t + "]");
    }
  }), RedactorX.add("module", "control", {
    init: function () {
      this.instance = !1, this.customItems = {}, this.eventName = this.prefix + "-control";
    },
    start: function () {
      this._build();
    },
    stop: function () {
      this.$control.remove(), this.instance = !1;
    },
    getElement: function () {
      return this.$control;
    },
    add: function (t, e) {
      this.customItems[t] = e;
    },
    build: function () {
      if (this._isControl()) {
        var t = this.app.block.get();
        t.isFigcaption() || (t.isSecondLevel() && (t = t.getFirstLevel()), t ? this.open(t) : this.close());
      }
    },
    open: function (t) {
      if (this._isControl()) if (this.instance = t, this.instance) {
        this.$control.show(), this.updatePosition();
        var e = this.app.scroll.getTarget();
        e.on("resize." + this.eventName, this.updatePosition.bind(this)), e.on("scroll." + this.eventName, this.updatePosition.bind(this)), this.$button.off("." + this.prefix + "-control-button"), this.app.$win.off("." + this.prefix + "-control-button"), this.opts.control && this.$button.on("click." + this.prefix + "-control-button", this._click.bind(this)), this.opts.reorder && this.$button.on("mousedown." + this.prefix + "-control-button touchstart." + this.prefix + "-control-button", this._press.bind(this));
      } else this.close();
    },
    close: function () {
      if (this._isControl()) {
        if (this.$control.hide(), this.instance) {
          var t = this.instance.getBlock();
          this.app.content.unfixListMargin(t);
        }
        this.app.scroll.getTarget().off("." + this.eventName), this.instance = !1;
      }
    },
    updatePosition: function () {
      if (this._isControl()) if (this.instance) {
        if ("list" === this.instance.getType()) {
          var t = this.instance.getBlock();
          this.app.content.fixListMargin(t);
        }
        var e = this.instance.isEditable(),
          i = this.instance.getOffset(),
          s = this.$control.width(),
          n = e ? -3 : 4,
          a = i.top - n - 0,
          o = i.left - s - 0;
        if (this.$control.show(), this.app.scroll.isTarget()) {
          var r = this.app.scroll.getTarget(),
            p = r.offset().top + r.height(),
            l = r.offset().top;
          (p < a + this.$control.height() || a < l) && this.$control.hide();
        }
        this.$control.css({
          top: a + "px",
          left: o + "px"
        });
      } else this.close();
    },
    _isControl: function () {
      return this.opts.control || this.opts.reorder;
    },
    _click: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = {},
        i = {};
      e = this.instance.control;
      for (var s in e = $R.extend(!0, {}, e, this.customItems)) this.instance.isAllowedButton(s, e[s]) && (i[s] = e[s], i[s].icon = !e[s].icon || e[s].icon, i[s].control = !0);
      this.app.popup.create("control", {
        items: i
      }), this.app.context.close(), this.app.popup.open({
        control: this.$button
      });
    },
    _press: function (t) {
      t.preventDefault(), t.stopPropagation(), setTimeout(function () {
        this.app.$win.on("mouseup." + this.prefix + "-control-button touchend." + this.prefix + "-control-button", this._release.bind(this)), this.app.$win.on("mousemove." + this.prefix + "-control-button touchmove." + this.prefix + "-control-button", this._move.bind(this));
      }.bind(this), 0);
    },
    _release: function (t) {
      this.$button.removeClass(this.prefix + "-handle"), this.app.$win.off("." + this.prefix + "-control-button"), this.app.observer.trigger = !0, this.app.event.trigger = !1, this.oldY = 0, this.dragging = !1, this._trashDragItem(), this.updatePosition(), this.$control.show(), setTimeout(function () {
        this.app.event.trigger = !0;
      }.bind(this), 1);
    },
    _move: function (t) {
      if (t.preventDefault(), !this.$button.hasClass(this.prefix + "-handle")) {
        var e = this.instance.getBlock().get();
        this.$button.addClass(this.prefix + "-handle"), this.dragging = !0, this.$dragItem = this._makeDragItem(e, t.target), this.$control.hide();
      }
      var i = !1,
        s = 0 === this.oldY ? 0 : this.oldY - t.pageY;
      0 < s ? i = "up" : s < 0 && (i = "down"), this._moveItem(this.$dragItem, s), this.oldY = t.pageY;
      var n = t.pageY,
        a = this.app.editor.getRect(),
        o = this.app.$doc.scrollTop(),
        r = o > a.top ? o + 40 : a.top + 40,
        p = this.app.$win.height() + o - 40,
        l = a.top,
        h = a.top + this.app.editor.getEditor().height();
      if (this.app.scroll.isTarget()) {
        var c = this.app.scroll.getTarget(),
          d = c.offset();
        l = d.top, r = o > a.top ? d.top + 40 : r, p = (h = d.top + c.height()) - 40;
      }
      "up" === i && n < r && l < n ? this._scroll(-10) : "down" === i && p < n && n < h && this._scroll(10);
      for (var u = this.app.editor.getEditor().children(), f = u.length, m = 0; m < f; m++) {
        var g = u.eq(m).get();
        g !== this.$clickItem.get() && this._isOver(this.dom(g)) && this._swapItems(g);
      }
    },
    _build: function () {
      this.$control = this.dom("<div>").addClass(this.prefix + "-control " + this.prefix + "-control-" + this.uuid).hide(), this.$button = this.dom("<span>").addClass(this.prefix + "-icon-sort " + this.prefix + "-button " + this.prefix + "-button-control"), this.$control.append(this.$button), this.opts.bsmodal && this.$control.css("z-index", 1051), this.app.$body.append(this.$control);
    },
    _isOver: function (t) {
      var e = this.$dragItem.offset().top,
        i = t.offset(),
        s = t.height();
      return e > i.top && e < i.top + s;
    },
    _scroll: function (t) {
      var e = this.app.scroll.isTarget() ? this.app.scroll.getTarget() : this.app.$win,
        i = e.scrollTop();
      e.scrollTop(i + t);
    },
    _swapItems: function (t) {
      var e = this.$dragItem.offset().top,
        i = this.$clickItem,
        s = this.dom(t),
        n = s.offset(),
        a = s.height() / 2;
      s[a + n.top > e ? "before" : "after"](i);
    },
    _moveItem: function (t, e) {
      var i = t.offset().top;
      i -= e, t.css("top", i + "px"), this.$control.css("top", i + "px");
    },
    _makeDragItem: function (t) {
      this._trashDragItem();
      var e = this.dom(t),
        i = e.offset();
      this.$clickItem = e, this.$clickItem.addClass(this.prefix + "-drag-active");
      var s = e.clone();
      s.removeClass(this.prefix + "-drag-active " + this.prefix + "-element-active"), s.css({
        "font-family": e.css("font-family"),
        "font-size": e.css("font-size"),
        "line-height": e.css("line-height"),
        margin: 0,
        padding: 0
      });
      var n = this.dom("<div>").addClass(this.prefix + "-dragging");
      return n.append(s), n.css({
        opacity: .95,
        position: "absolute",
        "z-index": 999,
        left: i.left + "px",
        top: i.top + "px",
        width: e.width() + "px"
      }), this.app.$body.append(n), n;
    },
    _trashDragItem: function () {
      this.$dragItem && this.$clickItem && (this.$clickItem.removeClass(this.prefix + "-drag-active"), this.$clickItem = null, this.$dragItem.remove(), this.$dragItem = null);
    }
  }), RedactorX.add("class", "tool.checkbox", {
    mixins: ["tool"],
    type: "checkbox",
    input: {
      tag: "input",
      type: "checkbox",
      classname: "-form-checkbox"
    },
    getValue: function () {
      return this.$input.val();
    },
    _buildInput: function () {
      if (this.$box = this.dom("<label>").addClass(this.prefix + "-form-checkbox-item"), this.$box.append(this.$input), this._has("text")) {
        var t = this.dom("<span>").html(this.lang.parse(this.obj.text));
        this.$box.append(t);
      }
      this.$tool.append(this.$box);
    }
  }), RedactorX.add("class", "tool.input", {
    mixins: ["tool"],
    type: "input",
    input: {
      tag: "input",
      type: "text",
      classname: "-form-input"
    },
    _buildInput: function () {
      this.$tool.append(this.$input);
    }
  }), RedactorX.add("class", "tool.number", {
    mixins: ["tool"],
    type: "number",
    input: {
      tag: "input",
      type: "number",
      classname: "-form-input"
    },
    _buildInput: function () {
      this.$input.attr("min", 0).css("max-width", "65px"), this.$tool.append(this.$input);
    }
  }), RedactorX.add("class", "tool.segment", {
    mixins: ["tool"],
    type: "segment",
    input: {
      tag: "input",
      type: "hidden",
      classname: "-form-input"
    },
    setValue: function (t) {
      this.$segment.find("." + this.prefix + "-form-segment-item").removeClass("active"), this.$segment.find("[data-segment=" + t + "]").addClass("active"), this.$input.val(t);
    },
    _buildInput: function () {
      this.$segment = this.dom("<div>").addClass(this.prefix + "-form-segment").css("max-width", "200px");
      var t = this.obj.segments;
      for (var e in t) {
        var i = this.dom("<span>").addClass(this.prefix + "-form-segment-item");
        i.attr("data-segment", e).on("click", this._catchSegment.bind(this)), Object.prototype.hasOwnProperty.call(t[e], "icon") ? i.html(t[e].icon) : i.addClass(this.prefix + "-icon-" + t[e].prefix + "-" + e), this.$segment.append(i);
      }
      this.$segment.append(this.$input), this.$tool.append(this.$segment);
    },
    _catchSegment: function (t) {
      t.preventDefault(), t.stopPropagation();
      var e = this.dom(t.target).closest("." + this.prefix + "-form-segment-item"),
        i = e.attr("data-segment");
      this.$segment.find("." + this.prefix + "-form-segment-item").removeClass("active"), e.addClass("active"), this.$input.val(i), this.app.api(this.setter, this.popup);
    }
  }), RedactorX.add("class", "tool.select", {
    mixins: ["tool"],
    type: "select",
    input: {
      tag: "select",
      classname: "-form-select"
    },
    _buildInput: function () {
      for (var t in this.obj.options) {
        var e = this.dom("<option>");
        e.val(t), e.html(this.lang.parse(this.obj.options[t])), this.$input.append(e);
      }
      this.$tool.append(this.$input);
    }
  }), RedactorX.add("class", "tool.textarea", {
    mixins: ["tool"],
    type: "textarea",
    input: {
      tag: "textarea",
      classname: "-form-textarea"
    },
    setFocus: function () {
      this.$input.focus(), this.$input.get().setSelectionRange(0, 0), this.$input.scrollTop(0);
    },
    _buildInput: function () {
      this._has("rows") && this.$input.attr("rows", this._get("rows")), this.$input.attr("data-gramm_editor", !1), this.$tool.append(this.$input);
    }
  }), RedactorX.add("class", "tool.upload", {
    mixins: ["tool"],
    type: "upload",
    input: {
      tag: "input",
      type: "hidden",
      classname: "-form-input"
    },
    setValue: function (t) {
      t = t || "", this.upload && this.upload.setImage(t), this.$input.val(t);
    },
    _buildInput: function () {
      this.$tool.append(this.$input), this._buildUpload();
    },
    _buildUpload: function () {
      this.$upload = this.dom("<input>").attr("type", "file"), this.$tool.append(this.$upload);
      var t = {};
      this._has("trigger") && this._get("trigger") && (t = {
        instance: this,
        method: "trigger"
      }), this.upload = this.app.create("upload", this.$upload, this.obj.upload, t);
    }
  }), RedactorX.add("block", "block.address", {
    mixins: ["block"],
    type: "address",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      format: {
        title: "## buttons.format ##",
        command: "format.popup"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      format: {
        command: "format.popup",
        icon: !0,
        title: "## buttons.format ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<address>");
    },
    handleEnter: function (t) {
      if (t.preventDefault(), this.isEmpty() || this.isCaretEnd()) {
        var e = this.getBlock(),
          i = e.children(),
          s = i.length,
          n = i.eq(s - 1),
          a = i.eq(s - 2),
          o = e.html().trim();
        if (-1 !== (o = this.app.utils.removeInvisibleChars(o)).search(/<br\s?\/?>$/)) return a.remove(), n.remove(), void this.addEmpty({
          position: "after",
          caret: "start"
        });
      }
      return this.app.insertion.insertBreakline(), !0;
    }
  }), RedactorX.add("block", "block.cell", {
    mixins: ["block"],
    type: "cell",
    editable: !0,
    create: function () {
      return this.dom("<td>");
    },
    getTable: function () {
      return this.getParent("table");
    },
    getRow: function () {
      return this.getParent("row");
    },
    getNextCell: function () {
      var t = this.getNext();
      if (!t) {
        var e = this.getParent("row");
        if (e) {
          var i = e.getNextRow();
          i && (t = i.getChildFirst("cell"));
        }
      }
      return t;
    },
    getPrevCell: function () {
      var t = this.getPrev();
      if (!t) {
        var e = this.getParent("row");
        if (e) {
          var i = e.getPrevRow();
          i && (t = i.getChildLast("cell"));
        }
      }
      return t;
    },
    getWidth: function () {
      var t = this.$block.attr("width");
      return t || "";
    },
    getNowrap: function () {
      return this.$block.hasClass(this.prefix + "-nowrap");
    },
    setWidth: function (e) {
      this._eachCell(function (t) {
        "" === e ? t.removeAttr("width") : t.attr("width", e);
      });
    },
    setNowrap: function (i) {
      this._eachCell(function (t) {
        var e = this.opts.table.nowrap + " " + this.prefix + "-nowrap";
        i ? t.addClass(e) : t.removeClass(e);
      }.bind(this));
    },
    handleArrow: function (t, e, i) {
      var s,
        n,
        a = this.getTable(),
        o = a.getBlock(),
        r = this.app.caret.is(o, "start"),
        p = this.app.caret.is(o, "end");
      return i.is("up-left") && r ? (t.preventDefault(), (s = a.getPrev()) ? this.app.block.set(s, "end") : this.app.insertion.insertEmptyBlock({
        current: a,
        position: "before",
        caret: "start"
      }), !0) : i.is("down-right") && p ? (t.preventDefault(), (n = a.getNext()) ? this.app.block.set(n, "start") : this.app.insertion.insertEmptyBlock({
        current: a,
        position: "after",
        caret: "start"
      }), !0) : i.is("up-left") && this.isCaretStart() ? (t.preventDefault(), (s = this.getPrevCell()) ? this.app.block.set(s, "end") : (s = this.getFirstLevel().getPrev()) && this.app.block.set(s, "end"), !0) : i.is("down-right") && this.isCaretEnd() ? (t.preventDefault(), (n = this.getNextCell()) ? this.app.block.set(n, "start") : (n = this.getFirstLevel().getNext()) && this.app.block.set(n, "start"), !0) : void 0;
    },
    handleTab: function (t) {
      t.preventDefault();
      var e = this.getNextCell();
      return e ? this.app.block.set(e, "start") : (e = this.getFirstLevel().getNext()) && this.app.block.set(e, "start"), !0;
    },
    handleEnter: function (t) {
      return t.preventDefault(), this.app.insertion.insertBreakline(), !0;
    },
    _eachCell: function (s) {
      var n = 0,
        t = this.$block.closest("table");
      this.$block.closest("tr").find("td, th").each(function (t, e) {
        t.get() === this.$block.get() && (n = e);
      }.bind(this)), t.find("tr").each(function (t) {
        var e = t.find("td, th").get(n),
          i = this.dom(e);
        s(i);
      }.bind(this));
    }
  }), RedactorX.add("block", "block.pre", {
    mixins: ["block"],
    type: "pre",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom(this.opts.pre.template);
    },
    build: function () {
      this._buildCaption(), this._buildItems("figcaption", "figcaption");
    },
    handleTab: function (t) {
      t.preventDefault();
      var e = this.opts.pre.spaces,
        i = document.createTextNode(Array(e + 1).join(" "));
      return this.app.insertion.insertNode(i, "end"), !0;
    },
    handleEnter: function (t) {
      t.preventDefault();
      var e = this.$block.html().search(/\n$/);
      return this.isCaretEnd() && -1 === e ? this.app.insertion.insertNewline("after", !0) : this.app.insertion.insertNewline(), !0;
    }
  }), RedactorX.add("block", "block.embed", {
    mixins: ["block"],
    type: "embed",
    editable: !1,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      embed: {
        command: "embed.edit",
        icon: !0,
        title: "## buttons.embed ##"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      embed: {
        command: "embed.edit",
        icon: !0,
        title: "## buttons.embed ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<figure>");
    },
    build: function () {
      this._buildCaption(), this._buildItems("figcaption", "figcaption"), this._buildEmbedCode();
    },
    addResponsive: function () {
      var t = this.dom("<div>").addClass(this.opts.embed.responsive),
        e = this.$block.find("figcaption"),
        i = e.clone(),
        s = this.getEmbedCode();
      e.remove(), t.html(s), this.$block.html("").append(t), 0 !== i.length && (this.app.create("block.figcaption", i), this.$block.append(i));
    },
    removeResponsive: function () {
      this.$block.find("." + this.opts.embed.responsive).unwrap();
    },
    getEmbedCode: function () {
      return decodeURI(this.$block.attr("data-embed-code"));
    },
    isResponsive: function () {
      return 0 !== this.$block.find("." + this.opts.embed.responsive).length;
    },
    _buildEmbedCode: function () {
      var t = this.$block.clone();
      t.find("." + this.opts.embed.responsive).unwrap(), t.find("figcaption").remove();
      var e = t.html().trim();
      this.$block.attr("data-embed-code", encodeURI(e));
    }
  }), RedactorX.add("block", "block.figcaption", {
    mixins: ["block"],
    type: "figcaption",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      }
    },
    create: function () {
      return this.dom("<figcaption>");
    },
    getFigure: function () {
      return this.$block.closest("figure").dataget("instance");
    },
    handleArrow: function (t, e, i) {
      if (i.is("up-left") && this.isCaretStart() || i.is("down-right") && this.isCaretEnd()) {
        t.preventDefault();
        var s = this.getFigure();
        return this.app.block.set(s), !0;
      }
    },
    handleTab: function (t) {
      t.preventDefault();
      var e = this.getFigure();
      return this.app.block.set(e), !0;
    },
    handleEnter: function (t) {
      return t.preventDefault(), this.isEmpty() || this.isCaretEnd() || this.isCaretStart() || this.app.insertion.insertBreakline(), !0;
    }
  }), RedactorX.add("block", "block.heading", {
    mixins: ["block"],
    type: "heading",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      format: {
        title: "## buttons.format ##",
        command: "format.popup"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      format: {
        command: "format.popup",
        icon: !0,
        title: "## buttons.format ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<h2>");
    },
    getTitle: function () {
      var t = this.lang.get("headings"),
        e = this.getTag(),
        i = this.$block.attr("data-title");
      return void 0 !== t[e] ? t[e] : i;
    },
    handleEnter: function (t) {
      if (t.preventDefault(), this.isEmpty() || this.isCaretEnd()) this.addEmpty({
        position: "after",
        caret: "start",
        remove: !1
      });else if (this.isCaretStart()) this.add({
        instance: this.duplicateEmpty(),
        position: "before"
      });else {
        var e = this.getBlock(),
          i = this.app.element.split(e);
        this.app.block.set(i, "start");
      }
      return !0;
    }
  }), RedactorX.add("block", "block.image", {
    mixins: ["block"],
    type: "image",
    editable: !1,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      image: {
        command: "image.edit",
        icon: !0,
        title: "## buttons.image-settings ##"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      image: {
        command: "image.edit",
        icon: !0,
        title: "## buttons.image-settings ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<" + this.opts.image.tag + ">");
    },
    build: function () {
      this._buildCaption(), this._buildItems("figcaption", "figcaption"), this.data = {
        alt: {
          getter: "getAlt",
          setter: "setAlt"
        },
        link: {
          getter: "getLinkUrl",
          setter: "setLinkUrl"
        },
        target: {
          getter: "getTarget",
          setter: "setTarget"
        },
        caption: {
          getter: "getCaption",
          setter: "setCaption"
        }
      };
    },
    getImage: function () {
      return this.$block.find("img").eq(0);
    },
    getSrc: function () {
      return this.getImage().attr("src");
    },
    getId: function () {
      return this.getImage().attr("data-image");
    },
    getLink: function () {
      var t = this.getImage().parent();
      return t = "A" === t.get().tagName && t;
    },
    getAlt: function () {
      var t = this.getImage().attr("alt");
      return t || "";
    },
    getLinkUrl: function () {
      var t = this.getLink();
      return t ? t.attr("href") : "";
    },
    getTarget: function () {
      var t = this.getLink();
      return t ? t.attr("target") : this.opts.image.newtab;
    },
    setAlt: function (t) {
      this.getImage().attr("alt", t);
    },
    setTarget: function (t) {
      var e = this.getLink();
      e && (t ? e.attr("target", "_blank") : e.removeAttr("target"));
    },
    setLinkUrl: function (t) {
      var e = this.getLink();
      if ("" === t && e) this.removeLink();else {
        if (!e) {
          var i = this.getImage();
          e = this.dom("<a>"), i.wrap(e);
        }
        e.attr("href", t);
      }
    },
    setImage: function (t) {
      var e = this.getImage();
      e.attr("src", t.url), Object.prototype.hasOwnProperty.call(t, "id") && e.attr("data-image", t.id), Object.prototype.hasOwnProperty.call(t, "2x") && e.attr("srcset", t["2x"] + " 2x");
    },
    removeLink: function () {
      var t = this.getLink();
      t && t.unwrap();
    }
  }), RedactorX.add("block", "block.line", {
    mixins: ["block"],
    type: "line",
    editable: !1,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<hr>");
    }
  }), RedactorX.add("block", "block.list", {
    mixins: ["block"],
    type: "list",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      format: {
        title: "## buttons.format ##",
        command: "format.popup"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      },
      indent: {
        title: "## buttons.indent ##",
        command: "list.indent"
      },
      outdent: {
        title: "## buttons.outdent ##",
        command: "list.outdent"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      format: {
        command: "format.popup",
        icon: !0,
        title: "## buttons.format ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function (t) {
      return t && t.tag ? this.dom("<" + t.tag + ">") : this.dom("<ul>");
    },
    setCaret: function (t) {
      var e = this.$block;
      "start" === t ? e = this.$block.find("li").first() : "end" === t && (e = this.$block.find("li").last()), this.app.caret.set(e, t);
    },
    setEmpty: function () {
      this.$block.html("");
      var t = this.dom("<li>");
      this.$block.append(t), this.app.caret.set(t, "start");
    },
    isEmpty: function () {
      var t = this.$block.html();
      t = this._cleanEmpty(t);
      var e = this.$block.find("li");
      return 0 === e.length ? "" === (t = t.trim()) : 1 === e.length && (t = e.eq(0).html(), "" === (t = this._cleanEmpty(t)));
    },
    handleTab: function (t) {
      var e = this.app.selection.getBlock(),
        i = this.app.caret.is(e, "start");
      if (this.app.element.isEmpty(e) && (t.preventDefault(), this.app.list.indent())) return !0;
      if (!this.isCaretStart() && !this.isCaretEnd()) {
        if (this.opts.tab.spaces && !i) return;
        return t.preventDefault(), this.app.list.indent(), !0;
      }
      var s = this.getNext();
      if (s) return t.preventDefault(), this.app.block.set(s, "start"), !0;
    },
    handleEnter: function (t) {
      var e, i, s, n;
      if (t.preventDefault(), this.isEmpty() || this.isCaretEnd()) {
        if (s = this.app.selection.getBlock(), i = this.dom(s), n = this.app.content.isEmptyHtml(s.innerHTML)) return i.remove(), this.addEmpty({
          position: "after",
          caret: "start"
        }), !0;
        e = this.dom("<li>"), this.app.element.cloneAttrs(s, e), this.dom(s).after(e), this.app.caret.set(e, "start");
      } else if (this.isCaretStart()) e = this.dom("<li>"), s = this.app.selection.getBlock(), this.app.element.cloneAttrs(s, e), this.dom(s).before(e);else {
        s = this.app.selection.getBlock(), i = this.dom(s), n = this.app.content.isEmptyHtml(s.innerHTML);
        var a = this.app.caret.is(s, "start"),
          o = this.app.caret.is(s, "end", ["ul", "ol"]);
        if (e = this.dom("<li>"), this.app.element.cloneAttrs(s, e), n) i.after(e), this.app.caret.set(e, "start");else if (a) i.before(e);else if (o) {
          var r = i.find("ul, ol").first();
          0 !== r.length && (e.append(this.app.utils.createInvisibleChar()), e.append(r)), i.after(e), this.app.caret.set(e, "start");
        } else {
          var p = this.app.element.split(s);
          this.app.caret.set(p, "start");
        }
      }
      return !0;
    }
  }), RedactorX.add("block", "block.paragraph", {
    mixins: ["block"],
    type: "paragraph",
    editable: !0,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      format: {
        title: "## buttons.format ##",
        command: "format.popup"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      format: {
        command: "format.popup",
        icon: !0,
        title: "## buttons.format ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom("<p>");
    },
    handleEnter: function (t) {
      if (t.preventDefault(), this.isEmpty() || this.isCaretEnd()) {
        var e = this.app.block.create();
        if (this.opts.clean.enter || (e = this.duplicateEmpty()), !this.opts.clean.enterinline) {
          var i = this.app.selection.getInline();
          if (i) {
            for (var s, n = this.app.element.getAllInlines(i), a = 0; a < n.length; a++) if (0 === a) (s = n[a].cloneNode()).innerHTML = "";else {
              var o = n[a].cloneNode();
              o.innerHTML = "", s.appendChild(o);
            }
            e = this.app.block.create(s.outerHTML);
          }
        }
        this.add({
          instance: e,
          position: "after",
          caret: "start",
          remove: !1
        });
      } else if (this.isCaretStart()) {
        (e = this.duplicate()).getBlock().html(""), this.add({
          instance: e,
          position: "before"
        });
      } else {
        var r = this.getBlock(),
          p = this.app.element.split(r);
        this.app.block.set(p, "start");
      }
      return !0;
    }
  }), RedactorX.add("block", "block.quote", {
    mixins: ["block"],
    type: "quote",
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom(this.opts.quote.template);
    },
    build: function () {
      this._buildCaption(), this._buildItems("p", "quoteitem"), this._buildItems("figcaption", "figcaption");
    },
    getLast: function () {
      return this._getLast();
    },
    getFirst: function () {
      return this._getFirst();
    },
    getFirstElement: function () {
      return this._getFirst().getBlock();
    },
    _getFirst: function () {
      return this.$block.find("p").first().dataget("instance");
    },
    _getLast: function () {
      return this.$block.find("p").last().dataget("instance");
    }
  }), RedactorX.add("block", "block.quoteitem", {
    mixins: ["block"],
    type: "quoteitem",
    editable: !0,
    create: function () {
      return this.dom("<p>");
    },
    getBlockquote: function () {
      return this.$block.closest("blockquote").dataget("instance");
    },
    handleArrow: function (t, e, i) {
      var s = this.getBlockquote(),
        n = s.getBlock();
      if (0 !== n.length) {
        var a = this.app.caret.is(n, "start"),
          o = this.app.caret.is(n, "end");
        if (i.is("up-left") && a) {
          t.preventDefault();
          var r = this.getFirstLevel().getPrev();
          return r ? this.app.block.set(r, "end") : this.app.insertion.insertEmptyBlock({
            current: s,
            position: "before",
            caret: "start"
          }), !0;
        }
        if (i.is("down-right") && o) {
          t.preventDefault();
          var p = this.getFirstLevel().getNext();
          return p ? this.app.block.set(p, "start") : this.app.insertion.insertEmptyBlock({
            current: s,
            position: "after",
            caret: "start"
          }), !0;
        }
      }
    },
    handleTab: function (t) {
      t.preventDefault();
      var e = this.getNext();
      return e ? this.app.block.set(e, "start") : (e = this.getFirstLevel().getNext()) && this.app.block.set(e, "start"), !0;
    },
    handleEnter: function (t) {
      t.preventDefault();
      var e = this.app.create("block.quoteitem");
      if (this.isEmpty() || this.isCaretEnd()) this.add({
        instance: e,
        position: "after",
        caret: "start"
      });else if (this.isCaretStart()) this.add({
        instance: e,
        position: "before"
      });else {
        var i = this.getBlock(),
          s = this.app.element.split(i);
        this.app.block.set(s, "start");
      }
      return !0;
    }
  }), RedactorX.add("block", "block.row", {
    mixins: ["block"],
    type: "row",
    create: function () {
      return this.dom("<tr>");
    },
    getNextRow: function () {
      var t = this.getNext(),
        e = this.$block.parent();
      return t || "TABLE" === e.get().tagName || (t = e.nextElement().find("tr").first().dataget("instance")), t;
    },
    getPrevRow: function () {
      var t = this.getPrev(),
        e = this.$block.parent();
      return t || "TABLE" === e.get().tagName || (t = e.prevElement().find("tr").last().dataget("instance")), t;
    }
  }), RedactorX.add("block", "block.table", {
    mixins: ["block"],
    type: "table",
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      },
      bold: {
        title: "## buttons.bold ##",
        command: "inline.set",
        params: {
          tag: "b"
        }
      },
      italic: {
        title: "## buttons.italic ##",
        command: "inline.set",
        params: {
          tag: "i"
        }
      },
      deleted: {
        title: "## buttons.deleted ##",
        command: "inline.set",
        params: {
          tag: "del"
        }
      },
      link: {
        title: "## buttons.link ##",
        command: "link.popup"
      },
      table: {
        command: "table.popup",
        title: "## buttons.table ##"
      },
      tune: {
        command: "table.cellSetting",
        title: "## buttons.column-settings ##"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      table: {
        command: "table.popup",
        icon: !0,
        title: "## buttons.table ##"
      },
      tune: {
        command: "table.cellSetting",
        icon: !0,
        title: "## buttons.column-settings ##"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    },
    create: function () {
      return this.dom(this.opts.table.template);
    },
    build: function () {
      this._buildItems("tr", "row"), this._buildItems("td, th", "cell"), this._buildNowrap();
    },
    getLast: function () {
      return this.getLastCell();
    },
    getFirst: function () {
      return this.getFirstCell();
    },
    getFirstElement: function () {
      return this.getFirstCell().getBlock();
    },
    getFirstCell: function () {
      var t = this.$block.find("th, td").first();
      if (0 !== t.length) return t.dataget("instance");
    },
    getLastCell: function () {
      var t = this.$block.find("th, td").last();
      if (0 !== t.length) return t.dataget("instance");
    },
    _buildNowrap: function () {
      this.$block.find("th, td").each(function (t) {
        t.hasClass(this.opts.table.nowrap) && t.addClass(this.prefix + "-nowrap");
      }.bind(this));
    }
  }), RedactorX.add("block", "block.layer", {
    mixins: ["block"],
    type: "layer",
    editable: !1,
    toolbar: {
      add: {
        title: "## buttons.add ##",
        command: "addbar.popup"
      },
      html: {
        title: "## buttons.html ##",
        command: "source.toggle"
      }
    },
    control: {
      add: {
        command: "addbar.popup",
        icon: !0,
        title: "## buttons.add ## ...",
        divider: "bottom"
      },
      duplicate: {
        command: "block.duplicate",
        icon: !0,
        title: "## buttons.duplicate ##",
        divider: "bottom"
      },
      trash: {
        command: "block.remove",
        icon: !0,
        title: "## buttons.delete ##"
      }
    }
  }), window.RedactorX = RedactorX, window.addEventListener("load", function () {
    RedactorX("[data-redactorx]");
  }), "object" == typeof module && module.exports && (module.exports = RedactorX, module.exports.RedactorX = RedactorX);
}();