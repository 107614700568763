import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _TEAMS$TEAM_MEMBERS$S;
import { TEAM_MEMBERS, TEAMS, SELECTED_TEAMS, FILTERS, SELECTED_VIEW } from '@/store/modules/team/mutation-types';
export default (_TEAMS$TEAM_MEMBERS$S = {}, _defineProperty(_TEAMS$TEAM_MEMBERS$S, TEAMS, function (state, payload) {
  state[TEAMS] = payload;
}), _defineProperty(_TEAMS$TEAM_MEMBERS$S, TEAM_MEMBERS, function (state, payload) {
  state[TEAM_MEMBERS] = payload;
}), _defineProperty(_TEAMS$TEAM_MEMBERS$S, SELECTED_TEAMS, function (state, payload) {
  state[SELECTED_TEAMS] = payload;
}), _defineProperty(_TEAMS$TEAM_MEMBERS$S, FILTERS, function (state, payload) {
  state[FILTERS] = payload;
}), _defineProperty(_TEAMS$TEAM_MEMBERS$S, SELECTED_VIEW, function (state, payload) {
  state[SELECTED_VIEW] = payload;
}), _TEAMS$TEAM_MEMBERS$S);