import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementTrLocale from 'element-ui/lib/locale/lang/tr-TR'; // element-ui lang
import trLocale from './tr/index';
import enLocale from './en/index';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  tr: _objectSpread(_objectSpread({}, trLocale), elementTrLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) {
    var lg = chooseLanguage.split("_");
    lg = lg[0];
    return lg;
  }
  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
export default i18n;