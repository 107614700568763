import moment from "moment";
export var state = {
  updates: [],
  project_group: [],
  projects: [],
  selectedProjects: [],
  selectedView: {
    id: 'my_active_projects',
    label: 'My Active Projects',
    icon: "fal fa-tasks",
    selected: ''
  },
  project: {},
  projectMembers: [],
  statsVisibility: true,
  projects_formated: [],
  sections: {},
  allSections: [],
  filters: {
    viewId: null,
    keyword: '',
    sort: null,
    team: [],
    member: 0,
    group: null,
    status: 0,
    limit: 25,
    page: 0,
    viewType: 'card',
    relationType: {
      value: "manager",
      label: "Manager"
    },
    project_status: []
  },
  filter_task: {
    priority: [],
    dates: [null, moment().endOf("week").format("YYYY-MM-DD")],
    task_status: [1, 2, 10, 9, 5],
    relation_type: {
      value: "relatedto",
      label: "Related To"
    },
    relation: [],
    relation_type2: {
      value: "createdby",
      label: "Created By"
    },
    relation2: [],
    sections: [],
    projectId: ''
  },
  STARRED_PROJECTS: []
};