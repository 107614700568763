var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.view == "full",
      expression: "view == 'full'"
    }],
    staticClass: "padding-top-5",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    staticClass: "searchProject",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Search deals"),
      clearable: "",
      plain: ""
    },
    on: {
      blur: function blur($event) {
        return _vm.getData();
      }
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getData();
      }
    },
    model: {
      value: _vm.searchKeyword,
      callback: function callback($$v) {
        _vm.searchKeyword = $$v;
      },
      expression: "searchKeyword"
    }
  })], 1)], 1), _vm._v(" "), _vm.deals && _vm.deals.length > 0 ? _c("div", {
    staticClass: "limitedHeight kv-scroll",
    class: _vm.view == "light" ? "light" : "full"
  }, [_vm._l(_vm.deals, function (deal, i) {
    return _c("div", {
      key: "deal" + i,
      staticClass: "full-width dealList"
    }, [_c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "card-title",
      on: {
        click: function click($event) {
          return _vm.editData(deal.recordId);
        }
      }
    }, [_c("span", [_vm._v("  " + _vm._s(deal.subject) + "   ")]), _vm._v("  - \n          "), deal.hotProspect ? _c("i", {
      staticClass: "fa fa-flame text-danger"
    }) : _vm._e(), _vm._v(" \n          " + _vm._s(deal.expectedRevenue + " " + deal.expectedRevenueCur) + "\n        ")]), _vm._v(" "), _c("div", {
      staticClass: "card-detail"
    }, [deal.tags_TEXT ? _c("span", {
      staticClass: "title2"
    }, [_vm._v("\n            " + _vm._s(_vm.$t("Closing Date")) + ": " + _vm._s(_vm._f("date")(deal.follow_up_on, "DD MMM YYYY")) + "\n          ")]) : _vm._e(), _vm._v(" "), deal.tags_TEXT ? _c("span", {
      staticClass: "title2"
    }, [_c("i", {
      staticClass: "fa fa-tags"
    }), _vm._v(" " + _vm._s(deal.tags_TEXT) + "\n          ")]) : _vm._e()]), _vm._v(" "), _vm.options["crm_deal_stage"] ? _c("div", {
      staticClass: "card-stage"
    }, _vm._l(_vm.options["crm_deal_stage"], function (st) {
      return st.special == deal.relatedData.stage.customValue ? _c("div", {
        staticClass: "stage",
        class: st.value == deal.stage ? "selected-stage" : "",
        style: {
          background: st.value == deal.stage ? "#" + st.color : ""
        }
      }, [_vm._v("  " + _vm._s(st.label) + "\n               "), _c("div", {
        staticClass: "triangle"
      }, [_c("svg", {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 18 18"
        }
      }, [_c("polygon", {
        attrs: {
          points: "11,3 4,18 18,18"
        }
      }), _vm._v("\n                    " + _vm._s(_vm.$t("Sorry, your browser does not support inline SVG.")) + "\n                ")])])]) : _vm._e();
    }), 0) : _vm._e()])]);
  }), _vm._v(" "), _vm.view == "light" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("full");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("More")) + "...")]) : _vm._e(), _vm._v(" "), _vm.view == "full" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("light");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Close")))]) : _vm._e()], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };