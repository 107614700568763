import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
var moment = require("moment");
import timeCounterMixin from "@/mixins/timeCounter.js";
export default {
  data: function data() {
    return {
      isVisible: false,
      isVisibleSave: false,
      formKey: "formKey"
    };
  },
  methods: {},
  components: {
    timeEntryForm: function timeEntryForm() {
      return import("@/components/tasks/timeEntry/timeEntryFormDrawer");
    }
  },
  mixins: [timeCounterMixin],
  computed: {
    drawer: {
      get: function get() {
        return this.$store.state.app.drawer;
      },
      set: function set(val) {
        this.$store.state.app.drawer = val;
      }
    },
    dialogTableVisible: {
      get: function get() {
        return this.$store.state.app.timeEntryForm.show;
      },
      set: function set(val) {
        this.$store.state.app.timeEntryForm.show = val;
      }
    },
    saveStatus: function saveStatus() {
      return this.$store.state.saving;
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.getCounterFirstTime(true).then(function (k) {
      _this.getAllWaitingEntries();
      // console.log("is.timeCounter.isRunning", this.timeCounter.isRunning);
      if (_this.timeCounter.isRunning) {} else {
        _this.timeCounter.time_show = moment("1900-01-01 00:00:00").add(_this.timeCounter.time, "seconds").format("HH:mm:ss");
        _this.setCounter(false);
      }
      setInterval(_this.incrementTime, 1000);
    });
  },
  watch: {
    toggleRunning: function toggleRunning(val) {
      if (val == "yes" || val == "no") {
        this.toggleTimer(val);
      } else if (val == "stop") {
        this.toggleTimer(val);
      }
      this.toggleRunning = "na";
    }
  }
};