import KvModal from '@/components/kiva/KvModal';
import KvImageCrop from '@/components/kiva/kv-image-crop/KvImageCrop';
export default {
  name: 'KvImageCropModal',
  components: {
    KvImageCrop: KvImageCrop,
    KvModal: KvModal
  },
  data: function data() {
    return {
      isVisible: true,
      loading: false,
      quality: 0.70,
      blobType: 'image/png'
    };
  },
  methods: {
    getBlobAndClose: function getBlobAndClose() {
      var _this$$refs$cropCompo,
        _this = this;
      (_this$$refs$cropCompo = this.$refs.cropComponent) === null || _this$$refs$cropCompo === void 0 ? void 0 : _this$$refs$cropCompo.getBlob().then(function (imageData) {
        if (_this.resolve) {
          _this.resolve(imageData);
        }
        _this.isVisible = false;
        return imageData;
      });
    },
    handleClose: function handleClose() {
      if (this.reject) {
        this.reject(false);
      }
      this.isVisible = false;
    }
  }
};