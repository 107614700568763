var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    key: _vm.sectionKey
  }, [_vm.view == "full" ? _c("el-row", {
    staticClass: "padding-top-5",
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("Type to search"),
      "prefix-icon": "el-icon-search"
    },
    nativeOn: {
      keyup: function keyup($event) {
        return _vm.filterContacts();
      }
    },
    model: {
      value: _vm.searchContactText,
      callback: function callback($$v) {
        _vm.searchContactText = $$v;
      },
      expression: "searchContactText"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.filteredContacts && _vm.filteredContacts.length > 0 ? _c("div", {
    staticClass: "limitedHeight kv-scroll",
    class: _vm.view == "light" ? "light" : "full"
  }, [_vm._l(_vm.filteredContacts, function (contact, i) {
    return _c("div", {
      key: "contact" + i,
      staticClass: "full-width contactList"
    }, [_c("div", {
      staticClass: "pull-left"
    }, [!contact.profile_photo ? _c("div", {
      staticClass: "card-icon",
      on: {
        click: function click($event) {
          return _vm.selectContact(contact.recordId);
        }
      }
    }, [_c("div", {
      staticClass: "fa fa-user-circle"
    })]) : _vm._e(), _vm._v(" "), contact.profile_photo ? _c("div", {
      staticClass: "card-photo",
      on: {
        click: function click($event) {
          return _vm.selectContact(contact.recordId);
        }
      }
    }, [_c("img", {
      attrs: {
        src: _vm.getContactImagePath({
          recordId: contact.recordId,
          id: contact.profile_photo
        }, true)
      }
    })]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "card-content"
    }, [_c("div", {
      staticClass: "card-title",
      on: {
        click: function click($event) {
          return _vm.selectContact(contact.recordId);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(contact.fullName))]), _vm._v(" - ( #" + _vm._s(contact.recordId) + " )\n            "), _c("br"), _vm._v(" "), _c("span", {
      staticClass: "title2"
    }, [_vm._v(_vm._s(contact.title))])]), _vm._v(" "), contact.email ? _c("span", {
      staticClass: "title1",
      on: {
        click: function click($event) {
          return _vm.setEvent("email", contact);
        }
      }
    }, [_vm._v(_vm._s(contact.email))]) : _vm._e(), _vm._v(" "), contact.mobile ? _c("span", {
      staticClass: "title1",
      on: {
        click: function click($event) {
          return _vm.callPhone(contact.mobile);
        }
      }
    }, [_vm._v(_vm._s(contact.mobile))]) : _vm._e()])])]);
  }), _vm._v(" "), _vm.view == "light" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("full");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("More")))]) : _vm._e(), _vm._v(" "), _vm.view == "full" ? _c("a", {
    staticClass: "moreIcon",
    on: {
      click: function click($event) {
        return _vm.setView("light");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Close")))]) : _vm._e()], 2) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };