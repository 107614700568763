var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper",
    class: _vm.classObj
  }, [_c("div", {
    staticClass: "main-container full-page"
  }, [_c("section", {
    staticClass: "app-main full-page"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm._t("default")], 2)], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };