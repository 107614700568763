var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  token: function token(state) {
    return state.auth.token;
  },
  isBiggerThan1280: function isBiggerThan1280(state) {
    return state.windowWidth > 1280;
  }
};
export default getters;