import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.error.cause.js";
import { getType } from './getType';
var allowedTypes = ['Array', 'Object'];
export function validateAndTransformRecords(records) {
  var type = getType(records);
  if (allowedTypes.includes(type)) {
    if (type === 'Object') {
      return [records];
    } else {
      return records;
    }
  } else {
    throw new Error("".concat(type, " is not supported argument"));
  }
}