import moment from 'moment';
export var state = {
  member_group: [],
  members: [],
  member: {},
  member_formated: [],
  statsVisibility: true,
  search: {
    project: {},
    member: {},
    team_member: {},
    team: {},
    timeEntry: {
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    }
  }
};