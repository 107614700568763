import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.function.name.js";
import attachmentMixin from "@/mixins/attachment.js";
export default {
  name: 'KvAttachmentList',
  mixins: [attachmentMixin],
  props: {
    attachments: {
      type: Array
    },
    record_id: {
      required: true
    },
    size: {
      type: String,
      default: "autoSize"
    },
    app: {
      type: String,
      required: true,
      default: 'inbox'
    },
    model: {
      type: String,
      required: true,
      default: 'message'
    },
    permitDelete: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isImage: function isImage(att) {
      return att.mime_type && att.mime_type.substring(0, 5) == 'image' ? true : false;
    },
    getImageUrl: function getImageUrl(fileId) {
      return "".concat(this.$kvApiBridgeUrl, "/data/").concat(this.app, "/").concat(this.model, "/").concat(this.record_id, "/attachment/").concat(fileId);
    },
    getFileIcon: function getFileIcon(att, design, size) {
      var nm = att && att.name ? att.name.split('.') : '';
      var t = nm.length - 1;
      if (nm[t]) {
        att.file_type = nm[t];
        if (nm[t] == 'txt') {
          att.show_format = 'iframe';
          return design + ' fa-file-alt fa-' + size;
        }
        if (nm[t] == 'pdf') {
          att.show_format = 'iframe';
          return design + ' fa-file-pdf  fa-' + size;
        }
        if (nm[t] == 'docx' || nm[t] == 'doc') {
          att.show_format = 'iframe';
          return design + ' fa-file-word  fa-' + size;
        }
        if (nm[t] == 'ppt' || nm[t] == 'pptx') {
          att.show_format = 'iframe';
          return design + ' fa-file-powerpoint  fa-' + size;
        }
        if (nm[t] == 'xls' || nm[t] == 'xlsx') {
          att.show_format = 'iframe';
          return design + ' fa-file-excel  fa-' + size;
        }
        if (nm[t] == 'zip' || nm[t] == 'rar') {
          att.show_format = 'iframe';
          return design + ' fa-file-archive  fa-' + size;
        }
        if (nm[t] == 'csv') {
          att.show_format = 'iframe';
          return design + ' fa-file-csv  fa-' + size;
        }
        if (nm[t] == 'mp4' || nm[t] == 'webm' || nm[t] == 'mpeg' || nm[t] == 'avi' || nm[t] == 'wmv' || nm[t] == 'mov' || nm[t] == 'flv') {
          att.show_format = 'iframe';
          return design + ' fa-file-video  fa-' + size;
        }
        if (nm[t] == 'jpg' || nm[t] == 'jpeg' || nm[t] == 'png' || nm[t] == 'gif') {
          att.show_format = 'img';
          return 'fa-file-image';
        }
      }
    },
    handleView: function handleView(att) {
      var viewer = this.$el.querySelector(".viewer-".concat(att.file_id)).$viewer;
      viewer.show();
    },
    openFileUrl: function openFileUrl(fileId) {
      var r = "".concat(this.$kvApiBridgeUrl, "/data/").concat(this.app, "/").concat(this.model, "/").concat(this.record_id, "/attachment/").concat(fileId);
      window.open(r);
    }
  }
};