import Cookies from 'js-cookie';
var state = {
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  secondSidebar: {
    opened: Cookies.get('secondSidebarStatus') ? !!+Cookies.get('secondSidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  drawer: false,
  activityDrawer: false,
  invitePeopleModal: false,
  helpMenuModal: false,
  quickTaskModal: false,
  activeDrawer: {
    tab: 'timeEntry',
    options: {}
  },
  templatePopup: {
    show: false,
    showView: false,
    data: {}
  },
  searchGlobal: {
    show: false,
    addGroup: ''
  },
  openPagePopup: {
    show: false,
    data: {}
  },
  openPageTemplatePopup: {
    show: false,
    data: {}
  },
  plusButton: {
    show: false
  },
  timeEntryForm: {
    show: false
  },
  optionsList: {}
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  TOGGLE_SECOND_SIDEBAR: function TOGGLE_SECOND_SIDEBAR(state) {
    state.secondSidebar.opened = !state.secondSidebar.opened;
    state.secondSidebar.withoutAnimation = false;
    if (state.secondSidebar.opened) {
      Cookies.set('secondSidebarStatus', 1);
    } else {
      Cookies.set('secondSidebarStatus', 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    Cookies.set('sidebarStatus', 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  ACTIVATE_DRAWER: function ACTIVATE_DRAWER(state, props) {
    state.drawer = props && props.show ? props.show : false;
    state.activeDrawer.tab = props && props.tab ? props.tab : 'timeEntry';
    state.activeDrawer.options = props && props.options ? props.options : {};
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit('TOGGLE_SIDEBAR');
  },
  activateDrawer: function activateDrawer(_ref2, props) {
    var commit = _ref2.commit;
    commit('ACTIVATE_DRAWER', props);
  },
  toggleSecondSideBar: function toggleSecondSideBar(_ref3) {
    var commit = _ref3.commit;
    commit('TOGGLE_SECOND_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref4, _ref5) {
    var commit = _ref4.commit;
    var withoutAnimation = _ref5.withoutAnimation;
    commit('CLOSE_SIDEBAR', withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref6, device) {
    var commit = _ref6.commit;
    commit('TOGGLE_DEVICE', device);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};