export var PRODUCTS = [{
  value: 'kivasuite',
  label: 'kivasuite'
}, {
  value: 'projects',
  label: 'Projects'
}, {
  value: 'invoice',
  label: 'Invoice'
}, {
  value: 'desk',
  label: 'Desk'
}, {
  value: 'sales',
  label: 'Sales'
}, {
  value: 'attornaid',
  label: 'Attornaid'
}, {
  value: 'rulesup',
  label: 'RulesUp'
}];

// MY_UX_PROFILE

export var VERSIONS = [{
  value: 'FREE',
  label: 'FREE'
}, {
  value: 'LIMITED',
  label: 'LIMITED'
}, {
  value: 'PRO',
  label: 'PRO'
}];
export var PROFILE_LEVEL = [{
  value: 'ADMIN',
  label: 'ADMIN'
}, {
  value: 'MANAGER',
  label: 'MANAGER'
}, {
  value: 'MEMBER',
  label: 'MEMBER'
}];
export var MODULES = [];

// Mega Menu
// 	Support Desk
// 		Inbox
// 		SLA
// 		Categories
// 		Rules
// 		Teams
// 	Project Management
// 		Team View
// 		Project Portfolio
// 		Tasks
// 		Workload
// 		Planner
// 		Timesheet
// 		Teams
// 		Leaves
// 		Overtimes
// 	Invoices & Subscriptions
// 		Accounts
// 		Contracts
// 		Invoices
// 		Estimates
// 		Payments
// 		Expenses
// 		Hourly Rate Lists
// 		Time & Expense Reports
// 	Sales CRM
// 		Deals
// 		Accounts
// 		Contacts
// 		Contracts
// 		Sales Inbox
// 		Products & Services
// Analytics
// Start Page
// 	Administrator -> Analytics
// 	Project Manager -> Team View
// 	Project Member -> Tasks
// 	Sales Manager -> Deals
// 	Sales Representative -> Deals
// 	Support Manager -> Inbox
// 	Support Representative -> Inbox
// 	Invoicing Manager -> Invoices
// 	Invoicing Member -> Invoices
// Contacts

// Administrator
// 	Calendar
// 	Tasks
// 	Deals
// 	Inbox
// 	Documents
// 	Pages
// 	Analytics
//     ...

export var MEGAMENU = {
  "kivasuite": {
    id: "kivasuite",
    label: "Kiva Suite",
    icon: "fal fa-user-headset",
    color: "rgb(27, 197, 189)",
    subList: [],
    includes: [{
      packageAppId: "projects"
    }, {
      packageAppId: "sales"
    }, {
      packageAppId: "invoice"
    }, {
      packageAppId: "desk"
    }, {
      packageAppId: "analytics"
    }
    // {packageAppId:"settings"},
    ]
    // SLA 
    // Teams	
  },

  "settings": {
    id: "settings",
    label: "Settings",
    icon: "kv-icon-settings",
    color: "#c0062b",
    subList: [{
      children: 'settings.account-information',
      main: "settings"
    }, {
      children: 'settings.billing-information',
      main: "settings"
    }, {
      children: 'settings.users',
      main: "settings"
    },
    // { children: 'settings.groups', main: "settings" }, 
    {
      children: 'settings.subscription-information',
      main: "settings"
    }, {
      children: 'settings.invoice-payments',
      main: "settings"
    }, {
      children: 'settings.currency',
      main: "settings"
    }, {
      children: 'settings.preferences',
      main: "settings"
    }, {
      children: 'settings.integrations',
      main: "settings"
    },
    // { children: 'settings.export-data', main: "settings" },  
    {
      children: 'settings.email-settings',
      main: "settings"
    }, {
      children: 'settings.customer-portal-settings',
      main: "settings"
    }]
    // SLA 
    // Teams	 
  },

  "analytics": {
    id: "analytics",
    label: "Analytics",
    icon: "kv-icon-chart",
    color: "#1e44a6",
    subList: [{
      main: "ReportsPage"
    }, {
      children: 'dashboard.projectsManagement',
      main: "dashboard"
    }, {
      children: 'dashboard.customerSupport',
      main: "dashboard"
    }, {
      children: 'dashboard.invoicingPayments',
      main: "dashboard"
    }, {
      children: 'dashboard.productsServices',
      main: "dashboard"
    }, {
      children: 'dashboard.salesFunnel',
      main: "dashboard"
    }]
    // SLA 
    // Teams	
  },

  "desk": {
    id: "desk",
    label: "Support Desk",
    icon: "fal fa-user-headset",
    color: "#1bc5bd",
    subList: [{
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "inbox.sla"
    }, {
      main: "inbox",
      children: "inbox.category"
    }, {
      main: "inbox.rules"
    }, {
      main: "manage",
      children: "manage.teams"
    }]
    // SLA 
    // Teams	
  },

  "projects": {
    id: "projects",
    label: "Project Management",
    icon: "fal fa-tasks-alt",
    color: "#2887eb",
    subList: [
    // { main: "mywork"  },
    // { main: "dashboard", children: "dashboard.teamConsole" },
    // { main: "dashboard", children: "dashboard.projectPortfolio" },
    {
      menu: "Team Console",
      meta: {
        title: "Team Console",
        icon: 'fal fa-user-clock'
      },
      pathFull: "/team/all/teamconsole",
      show: true
    }, {
      main: "project"
    }, {
      menu: "Workload",
      meta: {
        title: "Workload",
        icon: 'kv-icon-chart'
      },
      pathFull: "/team/all/workload",
      show: true
    },
    // { main: "dashboard", children: "dashboard.workloadView" },
    // { main: "dashboard", children: "planner" },
    // { main: "dashboard", children:"resources" },
    {
      menu: "Time Sheet",
      meta: {
        title: "Time Sheet",
        icon: 'fal fa-user-clock'
      },
      pathFull: "/team/all/timesheet",
      show: true
    }, {
      main: "team"
    }, {
      main: "manage",
      children: "manage.timeoff"
    }, {
      main: "manage",
      children: "manage.overtime"
    }]
  },
  "sales": {
    id: "sales",
    label: "Sales CRM",
    icon: "kv-icon-deals",
    color: "#f64e60",
    subList: [{
      main: "crm",
      children: "crm.leads"
    }, {
      main: "crm",
      children: "crm.deals"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "crm",
      children: "crm.contacts"
    }, {
      main: "crm",
      children: "crm.contract"
    }, {
      menu: "Sales Inbox",
      meta: {
        title: "Sales Inbox",
        icon: "fal fa-inbox"
      },
      pathFull: '/inbox',
      show: true
    }, {
      main: "products",
      children: "products.list"
    },
    // { main: "products", children: "products.category"  },
    {
      main: "crm",
      children: "crm.tasks"
    }]
  },
  "account": {
    id: "account",
    label: "CRM",
    icon: "kv-icon-company",
    color: "#f64e60",
    subList: [
    // { main: "crm", children: "crm.leads" },
    // { main: "crm", children: "crm.deals" },
    {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "crm",
      children: "crm.contacts"
    }
    // { main: "crm", children: "crm.contract" },
    // { menu: "Sales Inbox", meta:{ title:"Sales Inbox", icon:"fal fa-inbox" }, pathFull:'/inbox' },
    // { main: "products", children: "products.list"  },
    // { main: "products", children: "products.category"  },
    // { main: "crm", children: "crm.tasks"}
    ]
  },

  "invoice": {
    id: "invoice",
    label: "Invoices & Subscriptions",
    icon: "fal fa-file-invoice ",
    color: "#ffa800",
    subList: [{
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "crm",
      children: "crm.contract"
    }, {
      main: "invoice"
    }, {
      main: "invoice",
      children: "invoice.payments"
    }, {
      main: "invoice",
      children: "invoice.expenses"
    }, {
      main: "invoice",
      children: "settings.hourly-rate-lists"
    }, {
      main: "invoice",
      children: "invoice.time-and-expense-reports"
    }, {
      main: "estimate",
      children: "estimate.estimates"
    }]
  },
  "rulesup": {
    id: "rulesup",
    label: "RulesUp",
    icon: "fal fa-registered",
    subList: [{
      main: "rulesUp"
    }]
  }
};
export function setRouterIndex(redirects, uxProfile) {
  if (uxProfile && uxProfile.record_id) {
    if (uxProfile.record_id == "1" && uxProfile.profile_level == "ADMIN") {
      return redirects["ADMIN"][uxProfile.profile_level];
    } else if (uxProfile.product && redirects[uxProfile.product]) {
      return redirects[uxProfile.product][uxProfile.profile_level];
    } else {
      return "/dashboard/myHome";
    }
  } else {
    return "/dashboard/myHome";
  }

  // return "/tasks"
}

export var pathAuthority = [{
  products: ["projects"],
  main: "mywork",
  primaryClass: 'menu-item-console'
}, {
  products: ["projects"],
  main: "dashboard",
  children: "dashboard.calendarView"
}, {
  products: ["projects"],
  main: "project"
}, {
  products: ["sales", "desk", "projects", 'invoice'],
  main: "team"
}, {
  products: ["desk"],
  main: "inbox",
  children: "inbox.list"
}, {
  products: ["sales"],
  main: "crm",
  children: "crm.deals"
}, {
  products: ["all"],
  main: "alldocuments",
  children: "Docs",
  children_level2: "Documents"
}, {
  products: ["all"],
  main: "alldocuments",
  children: "pages"
}, {
  products: ["all"],
  main: "dashboard",
  children: 'dashboard.home'
}, {
  products: ["projects"],
  children: "dashboard.teamConsole"
},
// { products:["projects"], children: "dashboard.projectPortfolio"},
{
  products: ["projects"],
  children: "dashboard.workloadView"
}, {
  products: ["projects"],
  children: "dashboard.timesheetView"
}, {
  products: ["all"],
  children: "manage.teams"
}, {
  products: ["sales"],
  main: "crm",
  children: "crm.leads"
}, {
  products: ["sales"],
  main: "crm",
  children: "crm.deals"
}, {
  products: ["sales", "desk", "projects"],
  main: "crm",
  children: "crm.accounts"
}, {
  products: ["sales", "desk", "projects"],
  main: "crm",
  children: "crm.contacts"
}, {
  products: ["all"],
  main: "invite"
}, {
  products: ["all"],
  main: "help",
  children: "help.open-customer-portal"
}];
export var REDIRECTS = {
  "ADMIN": {
    "ADMIN": '/team/all/teamconsole'
  },
  "kivasuite": {
    "ADMIN": '/team/all/teamconsole',
    "MANAGER": '/team/all/teamconsole',
    "MEMBER": '/mywork'
  },
  "projects": {
    "ADMIN": '/team/all/teamconsole',
    "MANAGER": '/team/all/teamconsole',
    "MEMBER": '/mywork'
  },
  "sales": {
    "ADMIN": '/crm/deals',
    "MANAGER": '/crm/deals',
    "MEMBER": '/crm/deals'
  },
  "desk": {
    "ADMIN": '/inbox',
    "MANAGER": '/inbox',
    "MEMBER": '/inbox'
  },
  "invoice": {
    "ADMIN": '/invoices',
    "MANAGER": '/invoices',
    "MEMBER": '/invoices'
  },
  "rulesup": {
    "ADMIN": '/',
    "MANAGER": '/',
    "MEMBER": '/'
  },
  "attornaid": {
    "ADMIN": '/attornaid',
    "MANAGER": '/attornaid',
    "MEMBER": '/attornaid'
  }
};
export var SCHEMA = {
  "default": {
    "default": [{
      main: "mywork",
      primaryClass: 'menu-item-console'
    }, {
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "project"
    },
    // { main: "settings" }, 
    {
      main: "invite"
    }, {
      main: "team"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        short: 'Go to',
        icon: 'kv-icon-union',
        class: 'roundCircle'
      },
      primaryClass: 'highlightBox'
    }]
  },
  "ADMIN": {
    "ADMIN": [{
      main: "mywork",
      primaryClass: 'menu-item-console'
    }, {
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "project"
    }, {
      main: "team"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "crm",
      children: "crm.deals"
    }, {
      main: "alldocuments",
      children: "Docs",
      children_level2: "Documents"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }]
  },
  "projects": {
    "MANAGER": [{
      main: "mywork",
      primaryClass: 'menu-item-console'
    }, {
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "project"
    }, {
      main: "team"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "Docs",
      children_level2: "Documents"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    },
    // { main: "settings" }, 
    {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }],
    "ADMIN": [{
      main: "mywork",
      primaryClass: 'menu-item-console'
    }, {
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "project"
    }, {
      main: "team"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "Docs",
      children_level2: "Documents"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    },
    // { main: "settings" }, 
    {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }],
    "MEMBER": [{
      main: "mywork",
      primaryClass: 'menu-item-console'
    }, {
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "project"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "Docs",
      children_level2: "Documents"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }]
  },
  "sales": {
    "MANAGER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "team"
    }, {
      main: "crm",
      children: "crm.leads"
    }, {
      main: "crm",
      children: "crm.deals"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "crm",
      children: "crm.contacts"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }],
    "MEMBER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "crm",
      children: "crm.leads"
    }, {
      main: "crm",
      children: "crm.deals"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "crm",
      children: "crm.contacts"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }]
  },
  "desk": {
    "MANAGER": [{
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "team"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }],
    "MEMBER": [{
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }]
  },
  "rulesup": {
    "ADMIN": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "rulesUp"
    },
    //{ main: "dashboard" }, 
    {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "team"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }],
    "MANAGER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "rulesUp"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      }
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }],
    "MEMBER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "rulesUp"
    }, {
      main: "inbox",
      children: "inbox.list"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      }
    },
    // { main: "settings" }, 
    {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }]
  },
  "invoice": {
    "MANAGER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "estimate",
      children: "estimate.estimates"
    }, {
      main: "invoice"
    }, {
      main: "invoice",
      children: "invoice.expenses"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      main: "dashboard",
      children: 'dashboard.home',
      meta: {
        title: 'Analytics',
        short: 'Analytics',
        icon: 'kv-icon-chart'
      }
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    }, {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }],
    "MEMBER": [{
      main: "dashboard",
      children: "dashboard.calendarView"
    }, {
      main: "crm",
      children: "crm.accounts"
    }, {
      main: "estimate",
      children: "estimate.estimates"
    }, {
      main: "invoice"
    }, {
      main: "invoice",
      children: "invoice.expenses"
    }, {
      main: "alldocuments",
      children: "pages"
    }, {
      menu: "MEGAMENU",
      meta: {
        title: 'Mega Menu',
        icon: 'kv-icon-union'
      },
      primaryClass: 'highlightBox'
    },
    //{ main: "dashboard" },  
    // { main: "settings" }, 
    {
      main: "invite"
    }, {
      main: "help",
      children: "help.open-customer-portal"
    }]
  }
};
// Desk
// 	MANAGER
// 		Inbox
// 		Pages
// 		Accounts
// 		Analytics
// 		...
// 	Member
// 		Inbox
// 		Pages
// 		Accounts
// 		...
// Invoice
// 	MANAGER
// 		Calendar
// 		Accounts
// 		Estimates
// 		Invoices
// 		Expenses
// 		Analytics
// 		...
// 	Member
// 		Calendar
// 		Accounts
// 		Estimates
// 		Invoices
// 		Expenses
// 		Analytics
// 		...