import CrmAccountDetail from './components/CrmAccountDetail';
export default {
  name: 'AccountDetail',
  components: {
    CrmAccountDetail: CrmAccountDetail
  },
  data: function data() {
    return {};
  },
  methods: {}
};