import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { GROUPS, INBOX_PREFERENCES, PREFERENCES } from './mutation-types';
export default {
  projectLocale: function projectLocale(state) {
    return state[PREFERENCES] && state[PREFERENCES].locale;
  },
  projectCurrency: function projectCurrency(state) {
    return state[PREFERENCES] && state[PREFERENCES].baseCurrency;
  },
  groups: function groups(state) {
    return state[GROUPS];
  },
  findGroupById: function findGroupById(state) {
    return function (groupId) {
      return state[GROUPS].find(function (group) {
        return group.value === groupId;
      });
    };
  },
  inboxPreferences: function inboxPreferences(state) {
    return state[INBOX_PREFERENCES];
  },
  activityStatuses: function activityStatuses(state) {
    return state[ACTIVITY_STATUS];
  }
};