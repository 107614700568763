import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { crmContactModel } from "@/models/crmContactModel";
import { CrmContactTransformer } from "@/transformers/CrmContactTransformer";
export default {
  data: function data() {
    return {
      contacts: [],
      filteredContacts: [],
      searchContactText: "",
      crmContactDialog: false,
      sectionKey: 'sectionKey',
      selectedContact: crmContactModel()
    };
  },
  computed: {
    lastUpdate: function lastUpdate() {
      return this.$store.state.lastUpdate;
    }
  },
  created: function created() {
    this.getCrmContacts();
  },
  props: {
    account: Object,
    view: {
      type: String,
      default: "light"
    }
  },
  components: {},
  watch: {
    view: function view(val) {
      this.getCrmContacts();
      this.sectionKey = 'kk' + Math.random(99, 99999);
    },
    "lastUpdate.time": function lastUpdateTime(val) {
      if (this.lastUpdate.model) {
        this.getCrmContacts();
      }
    }
  },
  methods: {
    setView: function setView(val) {
      this.$emit("setView", val);
    },
    setEvent: function setEvent(val, obj) {
      this.$emit("setEvent", {
        name: val,
        to: obj.email,
        toId: obj.recordId
      });
    },
    callPhone: function callPhone(val) {
      // this.$emit("setEvent",{name:val,to:obj.email,toId:obj.recordId})
      window.open("tel:" + val);
    },
    selectContact: function selectContact(id) {
      // this.$store.state.app.templatePopup.data = {
      //   record_id: id,
      //   model: "contact",
      //   openSearchBox: false,
      // };
      // this.$store.state.app.templatePopup.show = true;
      this.$store.dispatch("app/activateDrawer", {
        show: true,
        tab: "contact",
        options: {
          record_id: id
        }
      });
    },
    getCrmContacts: function getCrmContacts() {
      var _this = this;
      var filter = {};
      if (this.account && this.account.recordId) {
        filter.account_name = ["=", this.account.record_id];
      }
      var limit = this.view == "light" ? 2 : 100;
      new KivaProxy({
        endpoint: "crm/contact"
      }).setFilters(filter).setLimit(limit).all().then(function (response) {
        _this.contacts = CrmContactTransformer.fetchCollection(response.records);
        var total = response.total;
        if (_this.view == "light") {
          _this.$emit("setTotal", total);
        }
        _this.filterContacts();
      }).catch(function (err) {
        console.log("err", err);
      });
    },
    filterContacts: function filterContacts() {
      var _this2 = this;
      this.filteredContacts = this.contacts;
      this.filteredContacts = this.filteredContacts.filter(function (k) {
        var res = k.fullName.toLocaleLowerCase("tr").search(_this2.searchContactText.toLocaleLowerCase("tr"));
        return res >= 0;
      });
    }
  }
};