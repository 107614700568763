import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var settingsRoutes = [{
  path: '',
  hidden: true,
  name: 'rulesup.settings',
  redirect: {
    name: 'rulesup.users'
  },
  props: true
}, {
  path: 'users',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/settings/UserList');
  },
  name: 'rulesup.users',
  meta: {
    title: 'Users',
    icon: 'fal fa-sliders-h-square'
  },
  props: true
}, {
  path: 'groups',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/settings/GroupList');
  },
  name: 'rulesup.groups',
  meta: {
    title: 'Groups',
    icon: 'fal fa-sliders-h-square'
  },
  props: true
}];