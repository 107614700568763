import "core-js/modules/es.number.constructor.js";
export default {
  props: {
    background: {
      type: String,
      default: "efefef"
    },
    border: {
      type: String,
      default: "efefef"
    },
    color: {
      type: String,
      default: "efefef"
    },
    number: {
      type: [String, Number],
      default: ""
    },
    icon: {
      type: String,
      default: "fa fa-check"
    },
    times: {
      type: Boolean
    },
    tagClass: {
      type: String,
      default: "large"
    },
    width: {
      type: String,
      default: "auto"
    },
    label: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      sColor: {
        font: "",
        bg: ""
      },
      baseStyles: {
        width: this.width,
        color: "",
        backgroundColor: "",
        borderColor: ""
      }
    };
  },
  created: function created() {
    this.findStatusColor();
  },
  methods: {
    getClasses: function getClasses() {
      var cls = this.tagClass;
      var cls2 = this.withNumber ? this.number : "";
      return cls + " " + cls2;
    },
    findStatusColor: function findStatusColor() {
      var color = this.color;
      if (color.charAt(0) == "#") {
        color = this.color.substring(1);
      }
      var background = this.background ? this.background : color;
      if (background.charAt(0) == "#") {
        background = this.background.substring(1);
      }
      var border = this.border ? this.border : color;
      if (border.charAt(0) == "#") {
        border = this.border.substring(1);
      }
      if (color == "success") {
        this.sColor["font"] = "rgb(27, 197, 189)";
        this.sColor["border"] = "rgb(27, 197, 189)";
        this.sColor["bg"] = "rgb(27, 197, 189, .15)";
      } else if (color == "warning") {
        this.sColor["font"] = "rgb(255, 168, 0) ";
        this.sColor["border"] = "rgb(255, 168, 0) ";
        this.sColor["bg"] = "rgb(255, 168, 0, .15)";
      } else if (color == "danger") {
        this.sColor["font"] = "rgb(246, 78, 96) ";
        this.sColor["border"] = "rgb(246, 78, 96) ";
        this.sColor["bg"] = "rgb(246, 78, 96, .15)";
      } else if (color == "primary") {
        this.sColor["font"] = "rgb(40, 135, 235)";
        this.sColor["border"] = "rgb(40, 135, 235)";
        this.sColor["bg"] = "rgb(40, 135, 235, .15)";
      } else {
        this.sColor["border"] = border ? "#" + border : "#efefef";
        this.sColor["font"] = color ? "#" + color : "#000";
        this.sColor["bg"] = color == background ? "#" + background + "26" : background ? "#" + background : "#545554";
      }
      this.baseStyles.backgroundColor = this.sColor.bg;
      this.baseStyles.color = this.sColor.font;
      this.baseStyles.borderColor = this.sColor.border;
      this.baseStyles.width = this.width;
    }
  }
};