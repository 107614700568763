import "core-js/modules/es.json.stringify.js";
export function crmDealModel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign({
    recordId: null,
    accountName: {
      value: null
    },
    type: null,
    // liste nereden cekilecek 
    contactName: null,
    contact: {
      value: null
    },
    subject: null,
    expectedRevenue: null,
    expectedRevenueCur: null,
    tags: null,
    leadSource: {},
    hotProspect: false,
    followers: null,
    followUpOn: null,
    expectedClosingDate: null,
    stage: null,
    recordOwner: null,
    assignedToPhoto: null,
    description: null,
    lastActivityNotes: null,
    lastActivityType: null,
    lastActivityDate: null,
    createdOn: null,
    createdBy: null,
    lastModifiedOn: null,
    lastModifiedBy: null
  }, JSON.parse(JSON.stringify(data)));
}