export default {
  name: 'SvgIcon',
  props: {
    icon: {
      default: 'fa fa-plus'
    },
    spin: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      iconPath: '',
      isCustom: false
    };
  },
  created: function created() {
    if (this.icon) {
      var icon = this.icon.split("kv-icon-");
      if (icon && icon[1]) {
        this.isCustom = true;
        this.iconPath = '#icon-' + icon[1];
      } else {
        this.isCustom = false;
        this.iconPath = this.icon;
      }
    } else {
      this.isCustom = false;
      this.iconPath = this.icon;
    }
  }
};