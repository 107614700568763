var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.mutableValue ? _c("div", {
    staticClass: "kv-modal"
  }, [_c("section", {
    staticClass: "kv-modal-content",
    class: _vm.setClass
  }, [_vm.isHeaderShow ? _c("header", {
    staticClass: "kv-modal-header"
  }, [_c("div", {
    staticClass: "kv-modal-title"
  }, [_vm._t("header", function () {
    return [_vm._v("\n          " + _vm._s(_vm.title) + "\n        ")];
  })], 2), _vm._v(" "), _vm.closeButton ? _c("div", {
    staticClass: "kiv-modal-header-action"
  }, [_c("div", {
    staticClass: "close-action",
    on: {
      click: function click($event) {
        _vm.mutableValue = false;
      }
    }
  }, [_c("i", {
    staticClass: "fas fa-times"
  })])]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("main", {
    staticClass: "kv-modal-body"
  }, [_vm._t("default")], 2), _vm._v(" "), _vm.$scopedSlots.footer ? _c("footer", {
    staticClass: "kv-modal-footer"
  }, [_vm._t("footer", function () {
    return [_vm._v("\n        " + _vm._s(_vm.$t("footer")) + "\n      ")];
  })], 2) : _vm._e()])]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };