import "core-js/modules/es.object.to-string.js";
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
export default {
  name: 'KvImageCrop',
  props: {
    image: {
      required: true
    },
    quality: {
      default: 0.70
    },
    blobType: {
      default: 'image/png'
    }
  },
  data: function data() {
    return {
      $cropper: null,
      cropperOptions: {
        autoCropArea: 1,
        viewMode: 0,
        center: true
      }
    };
  },
  mounted: function mounted() {
    this.setCropper();
  },
  methods: {
    setCropper: function setCropper() {
      this.$cropper = new Cropper(this.$refs.image, this.cropperOptions);
    },
    reset: function reset() {
      var _this$$cropper;
      (_this$$cropper = this.$cropper) === null || _this$$cropper === void 0 ? void 0 : _this$$cropper.reset();
    },
    zoom: function zoom(val) {
      var _this$$cropper2;
      (_this$$cropper2 = this.$cropper) === null || _this$$cropper2 === void 0 ? void 0 : _this$$cropper2.zoom(val);
    },
    rotate: function rotate(deg) {
      var _this$$cropper3;
      (_this$$cropper3 = this.$cropper) === null || _this$$cropper3 === void 0 ? void 0 : _this$$cropper3.rotate(deg);
    },
    toggleScaleX: function toggleScaleX() {
      var _this$$cropper4;
      var _this$$cropper$getDat = (_this$$cropper4 = this.$cropper) === null || _this$$cropper4 === void 0 ? void 0 : _this$$cropper4.getData(),
        scaleX = _this$$cropper$getDat.scaleX,
        scaleY = _this$$cropper$getDat.scaleY;
      this.scale(scaleX === 1 ? -1 : 1, scaleY);
    },
    toggleScaleY: function toggleScaleY() {
      var _this$$cropper5;
      var _this$$cropper$getDat2 = (_this$$cropper5 = this.$cropper) === null || _this$$cropper5 === void 0 ? void 0 : _this$$cropper5.getData(),
        scaleX = _this$$cropper$getDat2.scaleX,
        scaleY = _this$$cropper$getDat2.scaleY;
      this.scale(scaleX, scaleY === 1 ? -1 : 1);
    },
    scale: function scale(x, y) {
      var _this$$cropper6;
      (_this$$cropper6 = this.$cropper) === null || _this$$cropper6 === void 0 ? void 0 : _this$$cropper6.scale(x, y);
    },
    move: function move(x, y) {
      var _this$$cropper7;
      (_this$$cropper7 = this.$cropper) === null || _this$$cropper7 === void 0 ? void 0 : _this$$cropper7.move(x, y);
    },
    getBlob: function getBlob() {
      var _this = this;
      return new Promise(function (resolve, reject) {
        var _this$$cropper8;
        (_this$$cropper8 = _this.$cropper) === null || _this$$cropper8 === void 0 ? void 0 : _this$$cropper8.getCroppedCanvas().toBlob(resolve, _this.blobType, _this.quality);
      });
    }
  }
};