var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("main", {
    staticClass: "image-content-wrapper"
  }, [_c("div", {
    staticClass: "image-wrapper"
  }, [_c("figure", [_c("img", {
    ref: "image",
    attrs: {
      src: _vm.image,
      alt: ""
    }
  })])]), _vm._v(" "), _c("footer", {
    staticClass: "toolbar"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Zoom in",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.zoom(0.1);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "search-plus"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Zoom out",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.zoom(-0.1);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "search-minus"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Rotate left",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.rotate(-90);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "undo"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Rotate Right",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.rotate(90);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "redo"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Flip horizontally",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.toggleScaleY();
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrows-alt-v"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Flip vertically",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.toggleScaleX();
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrows-alt-h"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Move left",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.move(-1, 0);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrow-left"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Move right",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.move(1, 0);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrow-right"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Move up",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.move(0, -1);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrow-up"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Move down",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.move(0, 1);
      }
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "arrow-down"]
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Reset",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      circle: ""
    },
    on: {
      click: _vm.reset
    }
  }, [_c("font-awesome-icon", {
    attrs: {
      icon: ["fas", "sync-alt"]
    }
  })], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };