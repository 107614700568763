var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "newEvent",
    staticClass: "editForm text-left",
    class: _vm.focus ? "formWexit" : "",
    attrs: {
      model: _vm.newEvent,
      "label-width": "110px",
      size: "small"
    }
  }, [_c("el-link", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    staticStyle: {
      position: "absolute",
      top: "22px",
      right: "25px"
    },
    on: {
      click: function click($event) {
        _vm.$emit("updateFocus", false);
        _vm.setStandardMeeting();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-times"
  }), _vm._v(" " + _vm._s(_vm.$t("Close Form")) + "\n    ")]), _vm._v(" "), _vm.focus ? _c("el-form-item", {
    attrs: {
      label: _vm.$t("Subject"),
      prop: "subject"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: "",
      size: "small",
      placeholder: _vm.focus ? _vm.$t("Subject") : _vm.$t("Start to type new meeting subject")
    },
    on: {
      focus: function focus($event) {
        return _vm.$emit("updateFocus", true);
      }
    },
    model: {
      value: _vm.newEvent.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "subject", $$v);
      },
      expression: "newEvent.subject"
    }
  })], 1)], 1) : _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      autosize: "",
      size: "small",
      placeholder: _vm.focus ? _vm.$t("Subject") : _vm.$t("Start to type new meeting subject")
    },
    on: {
      focus: function focus($event) {
        return _vm.$emit("updateFocus", true);
      }
    },
    model: {
      value: _vm.newEvent.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "subject", $$v);
      },
      expression: "newEvent.subject"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Project"),
      prop: "project"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("kv-project", {
    on: {
      input: function input($event) {
        _vm.newEvent.project = $event;
      }
    },
    model: {
      value: _vm.newEvent.project,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "project", $$v);
      },
      expression: "newEvent.project"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Date"),
      prop: "date"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-date-picker", {
    staticClass: "width-full",
    attrs: {
      type: "date",
      placeholder: "Pick a date",
      clearable: false
    },
    model: {
      value: _vm.newEvent.date,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "date", $$v);
      },
      expression: "newEvent.date"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-time-select", {
    staticClass: "width-full",
    attrs: {
      clearable: false,
      "picker-options": {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      placeholder: _vm.$t("Start time")
    },
    model: {
      value: _vm.newEvent.startTime,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "startTime", $$v);
      },
      expression: "newEvent.startTime"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      span: 6
    }
  }, [_c("el-time-select", {
    staticClass: "width-full",
    attrs: {
      clearable: false,
      "picker-options": {
        start: "00:00",
        step: "00:15",
        end: "23:45"
      },
      placeholder: "End time"
    },
    model: {
      value: _vm.newEvent.endTime,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "endTime", $$v);
      },
      expression: "newEvent.endTime"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Location")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: _vm.$t("Type something")
    },
    model: {
      value: _vm.newEvent.location,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "location", $$v);
      },
      expression: "newEvent.location"
    }
  }, [_c("i", {
    staticClass: "fas fa-md fa-el-input__prefix fa-map-marker",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Status")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      size: "small",
      placeholder: _vm.$t("Activity Status"),
      filterable: ""
    },
    on: {
      change: function change($event) {
        return _vm.saveDirect();
      }
    },
    model: {
      value: _vm.newEvent.eventStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent.eventStatus, "value", $$v);
      },
      expression: "newEvent.eventStatus.value"
    }
  }, _vm._l(_vm.eventStatus, function (ts) {
    return _c("el-option", {
      key: ts.value,
      attrs: {
        value: ts.value,
        label: ts.label
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Participants")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("kv-participant", {
    key: _vm.allParticipantsKey,
    on: {
      input: function input($event) {
        _vm.allParticipants = $event;
      }
    },
    model: {
      value: _vm.allParticipants,
      callback: function callback($$v) {
        _vm.allParticipants = $$v;
      },
      expression: "allParticipants"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      label: _vm.$t("Participants")
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.newEvent.createTimeEntry,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "createTimeEntry", $$v);
      },
      expression: "newEvent.createTimeEntry"
    }
  }, [_vm._v(_vm._s(_vm.$t("Create time entries for participants")))])], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    attrs: {
      span: 24
    }
  }, [_c("p", [_vm._v(_vm._s(_vm.$t("Meeting Notes")))]), _vm._v(" "), _c("redactor", {
    staticClass: "margin-top-5 margin-bottom-5 kv-border-default",
    attrs: {
      config: _vm.configOptions
    },
    model: {
      value: _vm.newEvent.activityNotes,
      callback: function callback($$v) {
        _vm.$set(_vm.newEvent, "activityNotes", $$v);
      },
      expression: "newEvent.activityNotes"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "small",
      primary: "",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.$emit("saveEvent", {
          event: _vm.newEvent,
          participants: _vm.allParticipants
        });
        _vm.any_change = true;
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-save"
  }), _vm._v("\n        " + _vm._s(_vm.$t("Save Meeting")) + "\n      ")]), _vm._v(" "), _c("el-button", {
    staticClass: "mt-4 mb-2",
    attrs: {
      size: "small",
      icon: "fas fa-eraser"
    },
    on: {
      click: function click($event) {
        return _vm.setStandardMeeting();
      }
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      size: "small",
      primary: "",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.$emit("updateFocus", false);
        _vm.setStandardMeeting();
        _vm.any_change = true;
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-times"
  }), _vm._v("\n        " + _vm._s(_vm.$t("Close")) + "\n      ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };