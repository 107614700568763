import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
export default {
  data: function data() {
    return {
      list_types: {
        "project_status_base": this.$t("task.GroupByStatus"),
        "project_user_base": this.$t("task.GroupByUser"),
        "project_date_base": this.$t("task.GroupByDate"),
        "project_section_base": this.$t("task.GroupBySection"),
        "user_status_base": this.$t("task.GroupByStatus"),
        "user_project_base": this.$t("task.GroupByProject"),
        "user_date_base": this.$t("task.GroupByDate"),
        "user_priority_base": this.$t("task.GroupByPriority"),
        "project_priority_base": this.$t("task.GroupByPriority")
      },
      relations: [{
        id: "all",
        label: this.$t("task.relations.All")
      }, {
        id: 'record_owner',
        label: this.$t("task.relations.Assigned To")
      }, {
        id: 'followers',
        label: this.$t("task.relations.Followers")
      }, {
        id: 'support_assignees',
        label: this.$t("task.relations.Dependencies")
      }]
    };
  },
  computed: {
    task_status: function task_status() {
      var _this = this;
      var stored_task_status = this.$store.state.task_status;
      var task_status = stored_task_status && stored_task_status[0] ? stored_task_status : [];
      if (task_status && task_status[0]) {
        task_status.forEach(function (k) {
          if (_this.show_add_new[k.id]) {
            _this.show_add_new[k.id] = k.special == "OPEN" ? true : false;
          }
        });
      }
      return task_status;
    }
  }
};