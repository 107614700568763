import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { USERS } from '@/store/modules/users/mutation-types';
import { mapState } from 'vuex';
import { UserTransformer } from "@/transformers/UserTransformer";
import { SubscriptionInformationTransformer } from "@/transformers/SubscriptionInformationTransformer";
export default {
  data: function data() {
    return {
      users_formated: [],
      search_name: '',
      currentItem: 0,
      roles: [],
      subscriptions: []
    };
  },
  computed: _objectSpread({}, mapState('users', {
    users: USERS
  })),
  watch: {},
  methods: {
    getLicense: function getLicense() {
      var _this = this;
      if (this.userModel.userId) {
        new KivaProxy({
          endpoint: "kvc/user",
          driver: "base"
        }).setFields(["licenses"]).find(this.userModel.userId).then(function (response) {
          _this.userModel = UserTransformer.fetch(response);
        });
      }
    },
    getUserRole: function getUserRole() {
      var _this2 = this;
      this.loading = true;
      new KivaProxy({
        endpoint: "kvc/user/role",
        driver: "base"
      }).all().then(function (response) {
        _this2.loading = false;
        _this2.roles = response.records;
      });
    },
    getSubscriptionData: function getSubscriptionData() {
      var _this3 = this;
      this.loading = true;
      new KivaProxy({
        endpoint: "kvc/account/license",
        driver: "base"
      }).setFilters().all().then(function (response) {
        _this3.subscriptions = SubscriptionInformationTransformer.fetchCollection(response.data);
        if (_this3.$isRulesup) {
          _this3.userModel.licensesKey = _this3.subscriptions && _this3.subscriptions[0] ? _this3.subscriptions[0].id : "";
        }
        _this3.loading = false;
      });
    },
    handleSubmit: function handleSubmit(data, cretaeNew) {
      var _this4 = this;
      var method = "post";
      var endpoint = "";
      if (this.userModel.userId) {
        method = "patch";
        endpoint = "/" + this.userModel.userId;
      }
      this.$validator.validateAll().then(function (isValid) {
        if (isValid) {
          var _data = UserTransformer.send(_this4.userModel);
          var dataPost = _data.records ? _data.records[0] : _data;
          new KivaProxy({
            endpoint: "kvc/user" + endpoint,
            driver: "base"
          }).submit(method, "kvc/user" + endpoint, dataPost).then(function (response) {
            // this.$notify({
            //   title: this.$t("Success"),
            //   message: this.$t("Successfully saved"),
            //   type: "success"
            // });
            if (cretaeNew) {
              _this4.resetModal();
              _this4.$emit("updated");
              _this4.$emit("formNotify", true);
            } else {
              _this4.resetModal();
              _this4.$emit("formNotify", true);
              _this4.close();
            }
          }).catch(function (err) {
            _this4.$notify({
              title: _this4.$t("Error"),
              message: err.msg,
              type: "success"
            });
          });
        }
      });
    },
    get_user_avatar: function get_user_avatar(id) {
      var user = this.users.find(function (k) {
        return k.user_id == id;
      });
      if (user && user.photo) {
        return "".concat(this.$kvBaseUrl, "/").concat(user.photo);
      } else {
        return user && user.image ? user.image : user && user.firstname ? user.firstname.substring(0, 1) + ' ' + user.lastname.substring(0, 1) : '??';
      }
    },
    filter_users: function filter_users() {
      var _this5 = this;
      this.filtered_users = this.users.filter(function (k) {
        var fullname = k.firstname + ' ' + k.lastname;
        fullname = fullname.toLocaleUpperCase('tr');
        var search_name = _this5.search_name.toLocaleUpperCase('tr');
        return fullname.search(search_name) > -1;
      });
    }
  }
};