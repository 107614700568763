import _typeof from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import userMixin from "@/mixins/users.js";
import ClickOutside from "vue-click-outside";
export default {
  name: "KvUserSelectBox",
  mixins: [userMixin],
  props: {
    show: Boolean,
    outsideClickEvent: {
      type: Boolean,
      default: false
    },
    value: [Number, String, Object],
    selectedData: [Array, Object],
    disableUsers: [Array, Object],
    boxStyle: Object,
    size: String,
    selectedNumRow: Number
  },
  data: function data() {
    return {
      filtered_users: [],
      data: {},
      typeOfArray: false,
      created: false,
      counted: false,
      currentItem: 0,
      uniq: Math.random(9, 99999)
    };
  },
  methods: {
    setDisabled: function setDisabled(fields) {
      if (this.disableUsers && this.disableUsers[0]) {
        var disableUsers = this.disableUsers;
        if (typeof this.disableUsers[0] == "number") {} else {
          disableUsers = this.disableUsers.map(k = k.userId);
        }
        disableUsers.forEach(function (a) {
          fields.disabled = fields.find(function (u) {
            return u.userId == a;
          }) ? true : false;
        });
      }
    },
    focusInput: function focusInput() {
      var _this = this;
      setTimeout(function () {
        if (_this.$refs["sn" + _this.uniq]) {
          _this.$refs["sn" + _this.uniq].focus();
        }
      }, 400);
    },
    closeEvent: function closeEvent() {
      if (this.outsideClickEvent && this.created && this.show) {
        this.$emit("updateShow", false);
      }
    },
    setBlurAction: function setBlurAction() {
      var _this2 = this;
      setTimeout(function () {
        _this2.$emit("updateShow", false);
      }, 500);
    },
    set_user_record_owner: function set_user_record_owner(user, type, singleSelect) {
      var _this3 = this;
      if (_typeof(this.value) == "object") {
        this.data = {
          value: user.userId,
          label: user.firstname + " " + user.lastname,
          photo: user.photo ? user.photo : "",
          letters: user.firstname.substring(0, 1) + user.lastname.substring(0, 1)
        };
        this.$emit("input", this.data);
        this.$emit("updateData", {
          data: this.data,
          type: type,
          singleSelect: singleSelect
        });
        this.$emit("updateShow", false);
      } else {
        this.$emit("input", user.userId);
        this.$emit("updateData", {
          data: this.data,
          type: type,
          singleSelect: singleSelect
        });
        setTimeout(function () {
          _this3.$emit("updateShow", false);
        }, 30);
      }
    },
    nextItem: function nextItem(event) {
      console.log(event.keyCode);
      var max = this.filtered_users.length;
      if (event.keyCode == 38 && this.currentItem > 0) {
        this.currentItem--;
      } else if (event.keyCode == 40 && this.currentItem < max - 1) {
        this.currentItem++;
      }
      var liH = 38;
      this.$refs.kvUserSelect.scrollTop = liH * this.currentItem;
    }
  },
  directives: {
    ClickOutside: ClickOutside
  },
  created: function created() {
    var _this4 = this;
    var ss = this.selectedData ? this.selectedData.map(function (k) {
      return k.value ? k.value : k.userId;
    }) : [];
    var du = this.disableUsers ? this.disableUsers.map(function (k) {
      return k.value ? k.value : k.userId;
    }) : [];
    this.filtered_users = this.users.filter(function (k) {
      return (!ss || !ss.includes(k.userId)) && (!du || !du.includes(k.userId));
    });
    setTimeout(function () {
      _this4.created = true;
    }, 100);
  },
  mounted: function mounted() {},
  watch: {
    search_name: function search_name() {
      this.currentItem = 0;
    },
    show: function show(val) {
      this.focusInput();
      if (this.show == false) {
        this.search_name = "";
      }
    },
    created: function created(val) {
      this.focusInput();
    }
  }
};