import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var _mixins$props$created;
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import KivaProxy from "@/utils/kivaProxy/src";
import showUsers from "@/components/tasks/user/show_users.vue";
import activitiesMixin from "@/mixins/task/activities";
import moment from "moment";
import kvProject from "@/components/kiva/remote-search/kvProject";
import kvParticipant from "@/components/kiva/remote-search/kvParticipant";
export default (_mixins$props$created = {
  mixins: [activitiesMixin],
  props: {
    project: [Object],
    event: [Object],
    newEventTrigger: [Object],
    newEventKey: String,
    isFocus: Boolean,
    title: {
      type: String,
      default: ""
    },
    showAvatar: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    col: {
      type: [Array, Object],
      default: function _default() {
        return {
          form: 24,
          list: 24,
          gutter: 6
        };
      }
    }
  },
  created: function created() {
    this.getEventStatus();
    moment.locale(this.$locale);
    if (this.event && this.event.recordId) {
      this.newEvent = this.event;
    } else {
      this.setStandardMeeting();
    }
  },
  components: {
    vueMention: function vueMention() {
      return import("@/components/tasks/main/widgets/vue_mention");
    },
    showUsers: showUsers,
    kvProject: kvProject,
    kvParticipant: kvParticipant
  },
  computed: {},
  data: function data() {
    return {
      show_mention: false,
      any_change: false,
      newEvent: {},
      standardNewEvent: {
        subject: "",
        recordId: "0",
        calendar: 1,
        startTime: "11:00",
        endTime: "13:00",
        date: moment().format("YYYY-MM-DD"),
        defaultDate: moment().format("YYYY-MM-DD"),
        activityNotes: "",
        location: "",
        project: {
          value: null,
          label: null
        },
        eventStatus: {
          value: 1,
          label: null
        }
      },
      configOptions: {
        plugins: ["clips", "fontsize", "fullscreen", "table", "inlinestyle"],
        buttonsHide: ["html", "align", "strikethrough"],
        buttons: ["redo", "undo", "format", "bold", "italic", "lists", "image", "file", "link", "clips"],
        editor: {
          focus: false,
          height: "200px"
        }
      },
      show: {
        notes: false
      },
      setRow: 1,
      customToolbar: [["bold", "italic", "underline", "code-block"], [{
        list: "ordered"
      }, {
        list: "bullet"
      }]],
      loading: false
    };
  }
}, _defineProperty(_mixins$props$created, "computed", {
  users: function users() {
    return this.$store.state.users.USERS;
  }
}), _defineProperty(_mixins$props$created, "methods", {
  setStandardMeeting: function setStandardMeeting() {
    this.newEvent = _objectSpread({}, this.standardNewEvent);
  }
}), _defineProperty(_mixins$props$created, "watch", {
  newEventKey: function newEventKey() {
    if (this.newEventTrigger.subject) {
      this.newEvent = _objectSpread({}, this.newEventTrigger);
      this.allParticipants = [];
      this.getParticipants(this.newEvent.recordId);
      this.focus = true;
    } else {
      this.newEvent = _objectSpread({}, this.standardNewEvent);
      this.allParticipants = [];
      this.allParticipantsKey = "new";
      this.focus = false;
    }
  }
}), _mixins$props$created);