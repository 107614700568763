var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "time-counter"
  }, [_c("div", {
    staticClass: "counter-wrapper"
  }, [_c("div", {
    staticClass: "top-part"
  }, [_c("div", {
    staticClass: "time"
  }, [_c("div", {
    staticStyle: {
      width: "140px"
    }
  }, [_c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isRunning,
      expression: "!isRunning"
    }],
    staticClass: "small-button blue",
    on: {
      click: function click($event) {
        _vm.toggleRunning = "yes";
      }
    }
  }, [_c("i", {
    staticClass: "fa font-10 fa-play font-10"
  })]), _vm._v(" "), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isRunning,
      expression: "isRunning"
    }],
    staticClass: "small-button red",
    on: {
      click: function click($event) {
        _vm.toggleRunning = "no";
      }
    }
  }, [_c("i", {
    staticClass: "fa font-10 fa-pause"
  })]), _vm._v(" "), _c("a", {
    staticStyle: {
      "margin-left": "12px"
    },
    attrs: {
      title: "#" + (_vm.timeCounter.task ? _vm.timeCounter.task + " " + _vm.timeCounter.subject : "")
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.open("timeEntry");
      }
    }
  }, [_c("span", {
    key: _vm.timeCounter.time,
    staticClass: "time-show"
  }, [_vm._v("\n              " + _vm._s(_vm.timeCounter.time_show))])])]), _vm._v(" "), _c("div", {
    staticClass: "watchReference"
  }, [_vm.timeCounter.time == 0 ? _c("span", {
    key: "stopwatch",
    staticClass: "down-arrow",
    on: {
      click: function click($event) {
        return _vm.open();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-chevron-down"
  })]) : _vm.timeCounter.time > 0 ? _c("span", {
    key: "stopsave"
  }, [_c("el-popover", {
    attrs: {
      placement: "top",
      width: "220"
    },
    model: {
      value: _vm.isVisibleSave,
      callback: function callback($$v) {
        _vm.isVisibleSave = $$v;
      },
      expression: "isVisibleSave"
    }
  }, [_c("div", [_c("p", [_vm._v(_vm._s(_vm.$t("Are you sure to stop timer ?")))]), _vm._v(" "), _c("div", {
    staticStyle: {
      margin: "0",
      display: "inline-flex"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.isVisibleSave = false;
        _vm.showTimeForm(_vm.timeCounter);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Save Now")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.renewTimer();
        _vm.isVisibleSave = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Save Later")))])], 1)]), _vm._v(" "), _c("span", {
    staticClass: "small-button orange",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_c("i", {
    key: "save",
    staticClass: "fas fa-stop"
  })])])], 1) : _vm._e()])])])])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };