var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.sColor.bg ? _c("span", {
    key: _vm.color,
    staticClass: "kv-tags",
    class: _vm.getClasses(),
    style: {
      width: _vm.width,
      backgroundColor: _vm.sColor.bg,
      color: _vm.sColor.font,
      borderColor: _vm.sColor.border
    },
    on: {
      click: function click($event) {
        return _vm.$emit("clickEvent", true);
      }
    }
  }, [_vm.icon ? _c("i", {
    class: _vm.icon
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.label) + "\n  "), _vm.number ? _c("span", {
    staticClass: "number"
  }, [_vm._v(_vm._s(_vm.number))]) : _vm._e(), _vm._v(" "), _vm.times ? _c("a", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.$emit("close", true);
      }
    }
  }, [_c("i", {
    class: "fa fa-times"
  })]) : _vm._e()]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };