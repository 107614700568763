import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export default {
  computed: {
    teams: function teams() {
      return this.$store.state.teams.TEAMS;
    },
    me: {
      get: function get() {
        return _objectSpread({}, this.$store.state.users.CURRENT_USER);
      }
    }
  }
};