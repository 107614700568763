import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show && _vm.list && _vm.list[0],
      expression: "show &&  list && list[0]"
    }],
    staticClass: "darkBg",
    class: _vm.show ? "opacityHigh" : "opacityLow",
    on: {
      click: function click($event) {
        return _vm.closeEvent();
      }
    }
  }), _vm._v(" "), _vm.show && _vm.list && _vm.list[0] ? _c("div", {
    staticClass: "megaMenuInside kv-shadow-default"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "pinField"
  }, [_c("div", {
    staticClass: "pin_icon"
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-pin"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    ref: "searchField",
    attrs: {
      "prefix-icon": "el-icon-search",
      placeholder: _vm.$t("Type keyword"),
      clearable: true
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "subMenu"
  }, [_vm.favoriteMenu[2] ? _c("div", {
    key: _vm.keyfavorites,
    staticClass: "subItems smaller"
  }, [_vm._l(_vm.favoriteMenu[2], function (sub) {
    return [sub.meta ? _c("div", {
      key: sub.name,
      staticClass: "menuitem no-bg",
      class: "textcolor-" + sub.part,
      on: {
        click: function click($event) {
          return _vm.goPath(sub);
        }
      }
    }, [_c("a", {
      staticClass: "pinIcon",
      on: {
        click: function click($event) {
          $event.preventDefault();
          return _vm.removeToMenu(sub.pin.record_id);
        }
      }
    }, [_c("svg-icon", {
      attrs: {
        icon: "kv-icon-pin_selected"
      }
    })], 1), _vm._v(" "), _c("a", {
      on: {
        mouseup: function mouseup($event) {
          if ("button" in $event && $event.button !== 1) return null;
          return _vm.goPathNewWindow(sub);
        }
      }
    }, [_c("svg-icon", {
      attrs: {
        icon: sub.meta.icon
      }
    })], 1), _vm._v(" "), _c("span", {
      class: "textcolor-gray",
      on: {
        click: function click($event) {
          return _vm.goPath(sub);
        },
        mouseup: function mouseup($event) {
          if ("button" in $event && $event.button !== 1) return null;
          return _vm.goPathNewWindow(sub);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t(sub.meta.title)))])]) : _vm._e()];
  })], 2) : _vm._e()])], 1), _vm._v(" "), _c("div", {
    key: _vm.keyMain
  }, _vm._l(_vm.filteredList, function (item) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.hide,
        expression: "!item.hide"
      }],
      key: item.id + item.label,
      class: "subMenu " + "color-" + item.id
    }, [_c("div", {
      staticClass: "menuTitle"
    }, [_c("svg-icon", {
      attrs: {
        icon: item.icon
      }
    }), _vm._v("\n          " + _vm._s(_vm.$t(item.label)) + "         \n        ")], 1), _vm._v(" "), _c("div", {
      staticClass: "subItems"
    }, [_vm._l(item.subList, function (sub) {
      return [sub.meta ? _c("div", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: sub.show,
          expression: "sub.show"
        }],
        key: sub.name,
        staticClass: "menuitem"
      }, [_vm.allMenuIdsPinned.indexOf(sub.name) > -1 ? _c("a", {
        staticClass: "pinIcon pinVisible",
        on: {
          click: function click($event) {
            return _vm.removeToMenuFromPath(sub.name, item.id);
          }
        }
      }, [_c("svg-icon", {
        key: "k" + item.id + sub.name,
        attrs: {
          icon: "kv-icon-pin_selected"
        }
      })], 1) : _c("a", {
        staticClass: "pinIcon",
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.addPinToMenu(sub.name, item.id);
          }
        }
      }, [_c("svg-icon", {
        key: item.id + sub.name,
        attrs: {
          icon: "kv-icon-pin"
        }
      })], 1), _vm._v(" "), _c("a", {
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          },
          mouseup: function mouseup($event) {
            if ("button" in $event && $event.button !== 1) return null;
            return _vm.goPathNewWindow(sub);
          }
        }
      }, [_c("svg-icon", {
        attrs: {
          icon: sub.meta.icon
        },
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          }
        }
      })], 1), _vm._v(" "), _c("span", {
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          }
        }
      }, [_vm._v("  " + _vm._s(_vm.$t(sub.meta.title)) + "\n\n                \n              ")]), _vm._v(" "), _c("div", {
        staticClass: "clickField",
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          },
          mouseup: function mouseup($event) {
            if ("button" in $event && $event.button !== 1) return null;
            return _vm.goPathNewWindow(sub);
          }
        }
      })]) : sub.children && sub.children[0] && sub.children[0].meta ? _c("div", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: sub.show,
          expression: "sub.show"
        }],
        key: sub.name,
        staticClass: "menuitem",
        on: {
          click: function click($event) {
            return _vm.goPath(sub);
          },
          mouseup: function mouseup($event) {
            if ("button" in $event && $event.button !== 1) return null;
            return _vm.goPathNewWindow(sub);
          }
        }
      }, [_c("i", {
        class: sub.children[0].meta.icon.replace(/fal?\s/, "fad ")
      }), _vm._v(" "), _c("span", [_vm._v("   " + _vm._s(sub.children[0].meta.title))])]) : _vm._e()];
    })], 2)]);
  }), 0)]) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "arrow"
  }, [_c("i", {
    staticClass: "fas fa-caret-left"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };