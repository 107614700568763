import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import Vue from 'vue';
Vue.filter('max30WithDots', function (text) {
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 33;
  if (text && text.length > max) {
    var first = text.substr(0, 20);
    var last = text.substr(-10, 10);
    var newName = first + "..." + last;
    return newName;
  } else {
    return text;
  }
});
Vue.filter('max20WithDots', function (text) {
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 33;
  if (text && text.length > max) {
    var first = text.substr(0, 13);
    var last = text.substr(-7, 7);
    var newName = first + "..." + last;
    return newName;
  } else {
    return text;
  }
});
Vue.filter("onlyFirstChars", function (value) {
  value = value ? value.trim() : value;
  return value && value != '' && value.split(" ").map(function (c, i) {
    return c[0] + '';
  }).join("");
});
Vue.filter("nameShorten", function (value) {
  value = value ? value.trim() : value;
  return value && value != '' && value.split(" ").map(function (c, i) {
    return i <= 1 ? c[0] + '' : '';
  }).join(" ");
});
Vue.filter("nameShorten2", function (value) {
  value = value.trim();
  return value && value != '' && value.split(" ").map(function (c, i) {
    return i >= 1 ? c[0] + '.' : c;
  }).join(" ");
});