import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../lang'; // internationalization

import getters from './getters';
import app from './modules/app';
import tagsView from './modules/tagsView';
import settings from './modules/settings';
import { users } from './modules/users';
import { teams } from '@/store/modules/team';
import { project } from '@/store/modules/project';
import { section } from '@/store/modules/section';
import { auth } from './modules/auth';
import { member } from '@/store/modules/member';
import { page } from '@/store/modules/page';
import { tasks } from '@/store/modules/task/index.js';
import { documents } from '@/store/modules/documents';
import permission from './modules/permission';
import { activities } from '@/store/modules/activities';
import { common } from '@/store/modules/common';
import { dashboard } from '@/store/modules/dashboard';
import { notification } from '@/store/modules/notification';
import { sla } from '@/store/modules/sla';
import { planner } from '@/store/modules/planner';
import { timesheet } from '@/store/modules/timesheet';
import { focus } from '@/store/modules/focus';
import { inbox } from '@/store/modules/inbox';
import { timeEntry } from '@/store/modules/timeEntry';
import { calendar } from '@/store/modules/calendar';
Vue.use(Vuex);
import { state } from './states';
import { mutations } from './mutations';
import { debounce } from '@/utils/debounce';
var store = new Vuex.Store({
  modules: {
    app: app,
    settings: settings,
    auth: auth,
    teams: teams,
    tagsView: tagsView,
    page: page,
    permission: permission,
    users: users,
    project: project,
    section: section,
    tasks: tasks,
    documents: documents,
    member: member,
    activities: activities,
    common: common,
    dashboard: dashboard,
    notification: notification,
    sla: sla,
    planner: planner,
    timesheet: timesheet,
    focus: focus,
    inbox: inbox,
    timeEntry: timeEntry,
    calendar: calendar
  },
  state: state,
  getters: getters,
  mutations: mutations
});
window.addEventListener('resize', debounce(function () {
  store.commit('setWindowWidth', document.documentElement.clientWidth);
}, 100));
var init = false;
export function prepareStore() {
  // console.log("prepareStore");
  if (!init) {
    Promise.all([
    // store.dispatch('users/getUserUxProfile'),
    store.dispatch('notification/getConfig'), store.dispatch('notification/findUnreadNotification'),
    // store.dispatch('documents/getCategories'),
    // store.dispatch('documents/getLabels'),
    store.dispatch('users/getUsers'), store.dispatch('teams/getTeams'), store.dispatch('common/getCurrencies'), store.dispatch('common/getTimezones'), store.dispatch('common/getLanguages'), store.dispatch('common/getCountryLocales'),
    // store.dispatch('common/getCrmCurrencies'),
    store.dispatch('common/getProfiles'), store.dispatch('common/getPreferences'), store.dispatch('common/getGroups'), store.dispatch('common/getInboxPreferences'), store.dispatch('project/getStarredProjects'), store.dispatch('common/getActivityStatus')
    // store.dispatch('common/getActiveTimeEntry')
    ]).then(function () {
      init = true;
    });
  }
}
export default store;