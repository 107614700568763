import { render, staticRenderFns } from "./InboxModal.vue?vue&type=template&id=10f4cbe6&"
import script from "./InboxModal.vue?vue&type=script&lang=js&"
export * from "./InboxModal.vue?vue&type=script&lang=js&"
import style0 from "../styles/InboxDetail.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./InboxModal.scss?vue&type=style&index=1&lang=scss&"
import style2 from "./InboxModal.vue?vue&type=style&index=2&id=10f4cbe6&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/kivateknoloji/kivacloud/corona-ux/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('10f4cbe6')) {
      api.createRecord('10f4cbe6', component.options)
    } else {
      api.reload('10f4cbe6', component.options)
    }
    module.hot.accept("./InboxModal.vue?vue&type=template&id=10f4cbe6&", function () {
      api.rerender('10f4cbe6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/inbox/components/InboxModal.vue"
export default component.exports