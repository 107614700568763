import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
export default {
  data: function data() {
    return {
      levelLabel: ''
    };
  },
  computed: {
    profiles: function profiles() {
      return this.$store.state.users.UX_PROFILE;
    },
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    profile: {
      get: function get() {
        return this.$store.state.users.MY_UX_PROFILE;
      },
      set: function set(val) {
        this.$store.state.users.MY_UX_PROFILE = val;
      }
    },
    level: {
      get: function get() {
        return this.$store.state.users.CURRENT_USER.uxProfiles;
      },
      set: function set(val) {
        var myUxProfile = this.profiles.find(function (k) {
          return k.record_id == val;
        });
        this.profile = myUxProfile;
        this.$store.state.users.CURRENT_USER.uxProfiles = val;
      }
    }
  }
};