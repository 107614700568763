import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.user_list.length > 2 ? "divnarrow" : ""
  }, _vm._l(_vm.user_list, function (l, i) {
    return _c("div", {
      key: i,
      staticClass: "badge",
      on: {
        click: function click($event) {
          return _vm.pushRoute(l.userId);
        }
      }
    }, [_c("el-avatar", {
      class: _vm.user_list.length > 2 ? "narrow" : "",
      attrs: {
        title: l.firstname + " " + l.lastname,
        size: _vm.media_size,
        src: "".concat(_vm.$store.state.IMAGE_PATH, "/").concat(l.photo)
      }
    }, [_vm._v(_vm._s(l.firstname.substring(0, 1) + l.lastname.substring(0, 1)))])], 1);
  }), 0);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };