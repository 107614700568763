import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import ResizeMixin from './mixin/ResizeHandler';
export default {
  name: 'GuestLayout',
  components: {
    rightDrawer: function rightDrawer() {
      return import('@/components/tasks/rightDrawer');
    }
  },
  mixins: [ResizeMixin],
  computed: {
    classObj: function classObj() {
      return {
        mobile: this.device === 'mobile'
      };
    }
  }
};