import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import ClickOutside from "vue-click-outside";
import KvSvgIcon from "@/components/kiva/kvSvgIcon.vue";
export default {
  directives: {
    ClickOutside: ClickOutside
  },
  components: {
    KvSvgIcon: KvSvgIcon
  },
  data: function data() {
    var _this = this;
    return {
      created: false,
      menu: [{
        label: "Activities",
        value: "activities",
        path: "/activities",
        icon: "kv-icon-plus-o",
        license: ["projects", "sales", "invoice", "desk", "kivasuite"],
        subList: [{
          label: this.$t("Task"),
          value: "task",
          license: ["projects", "kivasuite"],
          path: function path() {
            if (_this.hasProduct('kivasuite') || _this.hasProduct('projects') || _this.hasProduct('desk') || _this.hasProduct('invoice')) {
              _this.$store.state.tasks.popup.task = {
                record_id: null
              };
              _this.$store.state.tasks.popup.show = true;
            } else {
              _this.$store.dispatch("app/activateDrawer", {
                show: true,
                tab: "task",
                options: {}
              });
            }
          },
          icon: "kv-icon-task"
        }, {
          label: this.$t("Task"),
          value: "task2",
          license: ["projects", "kivasuite"],
          path: function path() {
            _this.$store.state.app.quickTaskModal = true;
          },
          icon: "fal fa-layer-plus"
        }, {
          label: this.$t("Event"),
          value: "event",
          icon: "kv-icon-event",
          path: function path() {
            //  this.$store.dispatch("app/activateDrawer",{show: true, tab:'activity' ,options:{}})
            _this.$store.state.app.templatePopup.data = {
              record_id: null,
              model: "event",
              openSearchBox: false,
              subject: ""
            };
            _this.$store.state.app.templatePopup.show = true;
          }
        }, _defineProperty({
          label: "Thread",
          value: "thread",
          path: "/thread",
          icon: "kv-icon-inbox"
        }, "path", function path() {
          _this.$store.state.app.templatePopup.data = {
            record_id: null,
            model: "thread",
            openSearchBox: false,
            subject: ""
          };
          _this.$store.state.app.templatePopup.show = true;
        }), {
          label: this.$t("Email"),
          value: "email",
          icon: "fal fa-envelope",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "email",
              options: {}
            });
          }
        }, {
          label: this.$t("Expense"),
          value: "expense",
          icon: "kv-icon-expense",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "expense",
              options: {}
            });
          }
        }, {
          label: this.$t("Time Entry"),
          value: "timeEntry",
          icon: "kv-icon-time-entry",
          path: function path() {
            var state = _this.$store.state.app;
            state.drawer = true;
            state.activeDrawer.tab = "logTimeEntry";
            state.activeDrawer.options = {};
          }
        }, {
          label: this.$t("Feature Request"),
          value: "featureRequest",
          icon: "kv-icon-new_change_request",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "featureRequest",
              mode: 'all',
              options: {
                record_id: null,
                mode: 'all',
                project: {
                  value: null,
                  label: ''
                }
              }
            });
          }
        }
        // {
        //   label: "Page",
        //   value: "page",
        //   icon: "fal fa-books",
        //   path: () => {
        //     this.$store.state.app.templatePopup.data = {
        //       record_id: null,
        //       model: "page",
        //       openSearchBox: false,
        //     };
        //     this.$store.state.app.templatePopup.show = true;
        //   },
        // },
        // {
        //   label: "File",
        //   value: "file",
        //   icon: "fal fa-archive",
        //   path: () => {
        //     this.$store.dispatch("app/activateDrawer", {
        //       show: true,
        //       tab: "files",
        //       options: {},
        //     });
        //   },
        // },
        ]
      }, {
        label: "CRM",
        value: "crm",
        icon: "fal fa-bullseye-arrow ",
        license: ["sales", "kivasuite"],
        subList: [{
          label: this.$t("Lead"),
          value: "lead",
          // path: ()=>{ this.$router.push({ path:"/crm/deals", query: {modal:'new'}}) },
          path: function path() {
            _this.$store.state.app.templatePopup.data = {
              record_id: null,
              model: "lead",
              openSearchBox: false
            };
            _this.$store.state.app.templatePopup.show = true;
          },
          icon: "kv-icon-lead"
        }, {
          label: this.$t("Deal"),
          value: "deal",
          // path: ()=>{ this.$router.push({ path:"/crm/deals", query: {modal:'new'}}) },
          path: function path() {
            _this.$store.state.app.templatePopup.data = {
              record_id: null,
              model: "deal",
              openSearchBox: false
            };
            _this.$store.state.app.templatePopup.show = true;
          },
          icon: "kv-icon-deals"
        }, {
          label: this.$t("Account"),
          value: "account",
          // path: ()=>{ this.$router.push({ path:"/crm/accounts", query: {modal:'new'}}) },
          path: function path() {
            // this.$store.state.app.templatePopup.data = {
            //   record_id: null,
            //   model: "account",
            //   openSearchBox: false,
            // };
            // this.$store.state.app.templatePopup.show = true;
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "account",
              options: {}
            });
          },
          icon: "fal fa-building"
        }, {
          label: this.$t("Contact"),
          value: "contact",
          // path: ()=>{ this.$router.push({ path:"/crm/contacts", query: {modal:'new'}}) },
          path: function path() {
            // this.$store.state.app.templatePopup.data = {
            //   record_id: null,
            //   model: "contact",
            //   openSearchBox: false,
            // };
            // this.$store.state.app.templatePopup.show = true;
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "contact",
              options: {}
            });
          },
          icon: "fal fa-id-card"
        }]
      }, {
        label: this.$t("Projects & Docs"),
        value: "projects",
        icon: "kv-icon-projects",
        license: ["projects", "kivasuite"],
        subList: [{
          label: this.$t("Project"),
          value: "project",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "project",
              options: {}
            });
            // this.$store.state.app.templatePopup.data = {
            //   record_id: null,
            //   model: "project",
            //   openSearchBox: false,
            // };
            // this.$store.state.app.templatePopup.show = true;
          },

          icon: "kv-icon-projects"
        }, {
          label: this.$t("Page"),
          value: "page",
          icon: "kv-icon-page",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "page",
              options: {}
            });
          }
        }, {
          label: this.$t("File"),
          value: "file",
          icon: "kv-icon-file",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "files",
              options: {}
            });
          }
        }
        // {
        //   label: "Collection",
        //   value: "collection",
        //   path: "/collection",
        //   icon: "fal fa-layer-group",
        // },
        // {
        //   label: "Time Entry",
        //   value: "timeentry",
        //   path: () => {
        //     this.$store.state.app.timeEntryForm.show = true;
        //   },
        //   icon: "fal fa-clock",
        // },
        ]
      }, {
        label: this.$t("Team & HR"),
        value: "team",
        icon: "fal fa-users",
        license: ["projects", "kivasuite"],
        subList: [{
          label: this.$t("Users"),
          value: "users",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "user",
              options: {}
            });
          },
          icon: "fal fa-user"
        }, {
          label: this.$t("Team"),
          value: "team",
          icons: "",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "team",
              options: {}
            });
          },
          icon: "fal fa-users"
        },
        // {
        //   label: "Group",
        //   value: "group",
        //   icons: "",
        //   path: "/manage/group",
        //   icon: "fal fa-user-friends",
        // },
        {
          label: this.$t("Leave"),
          value: "leave",
          icons: "",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "timeoff",
              options: {}
            });
          },
          icon: "fal fa-island-tropical "
        }, {
          label: this.$t("Overtime"),
          value: "overtime",
          icons: "",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "overtime",
              options: {}
            });
          },
          icon: "fal fa-calendar-plus"
        }]
      }, {
        label: this.$t("Invoice"),
        value: "invoice",
        icon: "fal fa-file-invoice",
        license: ["invoice", "kivasuite"],
        subList: [{
          label: this.$t("Estimate"),
          value: "estimate",
          path: "/invoices/time-expense-reports",
          icon: "fal fa-shopping-cart"
        }, {
          label: this.$t("Product"),
          value: "product",
          icons: "",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "product",
              options: {}
            });
          },
          icon: "fal fa-cube"
        }, {
          label: this.$t("Invoice"),
          value: "invoice",
          path: function path() {
            _this.$router.push({
              path: "/invoices/create"
            });
          },
          icon: "fal fa-file-invoice"
        }, {
          label: this.$t("Payment"),
          value: "payment",
          path: function path() {
            // this.$router.push({
            //   path: "/invoices/payments",
            //   query: { modal: "new" },
            // });

            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "payment",
              options: {}
            });
          },
          icon: "fal fa-money-bill-wave"
        }, {
          label: this.$t("Contract"),
          value: "contract",
          path: function path() {
            _this.$store.dispatch("app/activateDrawer", {
              show: true,
              tab: "contract",
              options: {}
            });
          },
          icon: "fal fa-file-contract"
        }]
      }]
    };
  },
  methods: {
    goPath: function goPath(sub) {
      // this.$router.push({ path: sub.path });
      sub.path();
      this.closeEvent();
    },
    closeEvent: function closeEvent() {
      if (this.created) {
        this.plusButton.show = false;
        this.created = false;
      }
    }
  },
  watch: {
    "plusButton.show": function plusButtonShow(val) {
      var _this2 = this;
      if (val) {
        setTimeout(function () {
          _this2.created = true;
        }, 300);
      }
    }
  },
  computed: {
    plusButton: {
      get: function get() {
        return this.$store.state.app.plusButton;
      },
      set: function set(val) {
        this.$store.state.app.plusButton = val;
      }
    }
  }
};