import _typeof from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/typeof.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import userMixin from "@/mixins/users.js";
import ClickOutside from "vue-click-outside";
import kvUserSelectBoxMultiple from "./kvUserSelectBoxMultiple";
import SvgIcon from "@/components/kiva/kvSvgIcon.vue";

// SAMPLE
// { "userId": 28648, "status": 1, "role": { "value": "1", "label": "CEO" }, "profile": { "value": "Admin", "label": "Admin" }, "photo": "accounts/kvacc7374/userfiles/u28648/profile/picture_957b0b31.jpg", "mobile": "0", "lastname": "Beyazoğlu", "lastLogin": "2020-04-16T00:34:20+03:00", "gender": "Male", "firstname": "Anıl", "extension": 0, "email": "anil@timenexpense.com", "fullName": "Anıl Beyazoğlu" }
export default {
  name: "RecordOwnerSelector",
  mixins: [userMixin],
  components: {
    kvUserSelectBoxMultiple: kvUserSelectBoxMultiple,
    SvgIcon: SvgIcon
  },
  props: {
    size: [String, Number],
    label: [String, Number],
    value: [Number, String, Object, Array],
    showUserName: Boolean,
    extraClass: String,
    placeholder: String,
    stableUsers: {
      type: [Object, Array]
    },
    disableUsers: {
      type: [Object, Array]
    },
    dataType: {
      type: String,
      default: "object"
    },
    suffixText: {
      type: String,
      default: ""
    },
    fontSize: {
      type: Number,
      default: 14
    },
    iconPosition: {
      type: String,
      default: "left"
    },
    clickEvent: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    ClickOutside: ClickOutside
  },
  data: function data() {
    return {
      show_users: false,
      data: [],
      newData: {},
      posL: 0,
      posT: 0,
      boxStyle: {
        top: "42px"
      },
      selectedNumRow: null
    };
  },
  computed: {
    avatarUrl: function avatarUrl() {
      var url = "@/assets/avatar/dummy-avatar.svg";
      return url;
    },
    key: function key() {
      return Math.random(9, 9999999);
    },
    getExtraClass: function getExtraClass() {
      var e = this.extraClass;
      if (this.show_users) {
        e = e + " zindex1";
      }
      return e;
    }
  },
  methods: {
    removeUser: function removeUser(num) {
      this.data = this.data.filter(function (k, i) {
        return i != num;
      });
      this.$emit("updateData", this.data);
      this.$emit("input", this.data);
    },
    keyEventTab: function keyEventTab() {},
    setData: function setData() {
      var _this = this;
      var selected = {
        value: 0,
        photo: this.avatarUrl
      };
      if (_typeof(this.value) == "object") {
        this.data = this.value;
      } else if (this.value) {
        var selection = this.users.filter(function (k) {
          return k.userId == _this.value;
        });
        selected = selection && selection[0] ? selection[0] : {
          value: 0
        };
      }
      if (selected.userId) {
        this.data.push({
          value: selected.userId,
          photo: selected.photo ? selected.photo : "",
          label: selected.firstname ? selected.firstname + " " + selected.lastname : "Not selected",
          letters: selected.firstname ? selected.firstname.substring(0, 1) + selected.lastname.substring(0, 1) : this.label ? this.get_avatar_name(this.label) : null
        });
      }
    },
    get_avatar_name: function get_avatar_name(name) {
      var r = "";
      var arr = name.split(" ");
      arr.forEach(function (k) {
        r = r + k.charAt(0);
      });
      return r;
    },
    updateData: function updateData(val, type, singleSelect) {
      // if (singleSelect) {
      //   this.data = [val];
      // } else {
      //   this.data.push(val);
      // }
      // console.log("val,val",val,type,this.data,this.selectedNumRow)
      if (this.selectedNumRow == null || !this.data[this.selectedNumRow]) {
        this.data.push(val);
      } else {
        this.data[this.selectedNumRow] = val;
      }
      this.$emit("input", this.data);
      if (type == "tab") {
        this.$emit("updateTab", this.data);
      } else {
        this.$emit("updateData", this.data);
      }
    },
    start_search: function start_search() {
      var i = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var ref = "kvUserSelectBox" + this.key;
      this.posL = this.$refs[ref].getBoundingClientRect().left;
      this.posT = this.$refs[ref].getBoundingClientRect().top;
      this.selectedNumRow = i;
      this.getPositions();
    },
    getPositions: function getPositions() {
      var style = {};
      var fw = window.innerWidth;
      var fh = window.innerHeight;
      var diffW = fw - this.posL;
      var diffH = fh - this.posT;
      if (diffW < 240) {
        style.left = "-200px";
      }
      if (diffW > 220) {
        // style.left = "110px";
      }
      if (diffH < 300) {
        style.top = "-300px";
      }
      if (diffH > 220) {
        style.top = 18 + (Math.floor(this.data.length / 2) + 1) * 35 + "px";
      }
      // console.log(style, diffW, fw, this.posL);
      this.boxStyle = style;
      this.show_users = !this.show_users;
    },
    closeEvent: function closeEvent() {
      //this.show_users = false;
    }
  },
  created: function created() {
    this.setData();
  },
  watch: {
    clickEvent: function clickEvent(val) {
      if (val) {
        this.start_search();
      }
    },
    value: function value(val) {
      this.setData();
    }
  }
};