import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var tasksRoutes = [{
  path: '',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/tasks/TasksList');
  },
  name: 'rulesup.tasks.list',
  meta: {
    title: 'Tasks',
    icon: 'fal fa-registered'
  },
  props: true
}];