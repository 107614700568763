import SvgIcon from "@/components/kiva/kvSvgIcon.vue";
import pinsMixin from "@/mixins/pins";
export default {
  mixins: [pinsMixin],
  components: {
    SvgIcon: SvgIcon
  },
  data: function data() {
    return {
      created: false
    };
  },
  computed: {
    routes: function routes() {
      return this.$router.options.routes;
    }
  }
};