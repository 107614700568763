import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import Vue from 'vue';
import moment from 'moment';
Vue.filter('datetime', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD MMM YY HH:mm';
  var formattedDate = moment(date).format(formatStr);
  if (formattedDate !== 'Invalid date') {
    return formattedDate;
  }
});
Vue.filter('date', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DD MMM YY';
  var formattedDate = moment(date).format(formatStr);
  if (formattedDate !== 'Invalid date') {
    return formattedDate;
  }
});
Vue.filter('totalTime', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm:ss';
  var h = Math.floor(date);
  var m = Math.round((date - h) * 60);
  var s = Math.floor((date * 60 - (h * 60 - m)) * 60);
  return "".concat(h, "h ").concat(m, "m");
});
Vue.filter('totalTimeMinutes', function (date) {
  var formatStr = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'HH:mm:ss';
  var add = "";
  if (date < 0) {
    date = date * -1;
    add = "-";
  }
  // date = date*60;
  var h = Math.floor(date / 60);
  var m = Math.round(date - h * 60);
  var s = Math.floor((date * 60 - (h * 60 - m)) * 60);
  if (formatStr == "HH:mm") {
    return "".concat(add).concat(h, ":").concat(add).concat(m);
  } else {
    return "".concat(add).concat(h, "h ").concat(m, "m");
  }
});