import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c("div", {
    ref: "kvUserSelectBox",
    staticClass: "kvUserSelectBox kv-scroll kv-xtest",
    style: _vm.boxStyle
  }, [_c("div", {
    staticClass: "search_area mx-2 mb-2"
  }, [_c("div", {
    staticClass: "my el-input el-input--small",
    attrs: {
      "data-v-7308be86": ""
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.search_name,
      expression: "search_name"
    }],
    ref: "sn" + _vm.uniq,
    staticClass: "el-input__inner",
    attrs: {
      type: "text",
      autocomplete: "off",
      tabindex: "0"
    },
    domProps: {
      value: _vm.search_name
    },
    on: {
      keydown: [function ($event) {
        return _vm.filter_users();
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) return null;
        return _vm.set_user_record_owner(_vm.filtered_users[_vm.currentItem], "tab");
      }],
      keyup: [function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.set_user_record_owner(_vm.filtered_users[_vm.currentItem], "data");
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        return _vm.$emit("updateShow", false);
      }, _vm.nextItem],
      blur: function blur($event) {
        return _vm.setBlurAction();
      },
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.search_name = $event.target.value;
      }
    }
  })])]), _vm._v(" "), _c("ul", {
    ref: "kvUserSelect",
    staticClass: "kv-scroll"
  }, _vm._l(_vm.filtered_users, function (user, i) {
    return _c("li", {
      key: user.user_id,
      staticClass: "cursor-pointer pt-2",
      class: _vm.currentItem == i ? "active-item" : "",
      on: {
        click: function click($event) {
          return _vm.set_user_record_owner(user, "data", true);
        }
      }
    }, [_c("a", {
      staticClass: "pull-left",
      attrs: {
        href: "#"
      }
    }, [_c("el-avatar", {
      attrs: {
        size: "small",
        src: "".concat(_vm.$store.state.IMAGE_PATH, "/").concat(user.photo)
      }
    }, [_vm._v(_vm._s(user.firstname.substring(0, 1) + user.lastname.substring(0, 1)))])], 1), _vm._v(" "), _c("span", {
      staticClass: "text-avatar"
    }, [_vm._v(_vm._s(user.firstname) + " " + _vm._s(user.lastname))])]);
  }), 0)]) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };