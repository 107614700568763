var CURRENCIES = 'CURRENCIES';
var TIME_ZONES = 'TIME_ZONES';
var TIME_ENTRIES = 'TIME_ENTRIES';
var LANGUAGES = 'LANGUAGES';
var LOCALES = 'LOCALES';
var CRM_CURRENCIES = 'CRM_CURRENCIES';
var PROFILES = 'PROFILES';
var PREFERENCES = 'PREFERENCES';
var GROUPS = 'GROUPS';
var INBOX_PREFERENCES = 'INBOX_PREFERENCES';
var FAVORITES = 'FAVORITES';
var ACTIVITY_STATUS = 'ACTIVITY_STATUS';
var MY_ACTIVITY_STATUS = 'MY_ACTIVITY_STATUS';
var HELP_DRAWER = 'HELP_DRAWER';
export { CURRENCIES, TIME_ZONES, TIME_ENTRIES, LANGUAGES, LOCALES, CRM_CURRENCIES, PROFILES, PREFERENCES, GROUPS, INBOX_PREFERENCES, FAVORITES, ACTIVITY_STATUS, MY_ACTIVITY_STATUS, HELP_DRAWER };