var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "invoice-send-mail-view",
    class: _vm.paddingClass
  }, [_c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("div", {
    staticClass: "columns vertical-align"
  }, [_c("div", {
    staticClass: "column is-narrow w-150"
  }, [_vm._v(_vm._s(_vm.$t("From")))]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("el-select", {
    staticClass: "width-full",
    attrs: {
      placeholder: _vm.$t("From"),
      clearable: ""
    },
    model: {
      value: _vm.model.from,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "from", $$v);
      },
      expression: "model.from"
    }
  }, _vm._l(_vm.fromData, function (data) {
    return _c("el-option", {
      key: data.id,
      attrs: {
        label: data.label,
        value: data.id
      }
    }, [_vm._v(_vm._s(data.label) + "\n            ")]);
  }), 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns vertical-align"
  }, [_c("div", {
    staticClass: "column is-narrow w-150"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("To")) + "\n          "), _c("span", {
    staticClass: "table-bottom-text margin-left-10"
  }, [_c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        _vm.addCC = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("+CC")))]), _vm._v("\n            /\n            "), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        _vm.addBCC = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("BCC")))])])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    attrs: {
      multiple: false
    },
    on: {
      input: function input($event) {
        _vm.model.to = $event;
      }
    },
    model: {
      value: _vm.model.to,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "to", $$v);
      },
      expression: "model.to"
    }
  })], 1)]), _vm._v(" "), _vm.addCC ? _c("div", {
    staticClass: "columns vertical-align"
  }, [_c("div", {
    staticClass: "column is-narrow w-150"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("CC")) + "\n          "), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        _vm.addCC = false;
      }
    }
  }, [_vm._v("x")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    staticClass: "width-full",
    attrs: {
      multiple: true,
      placeholder: _vm.$t("CC"),
      clearable: ""
    },
    on: {
      input: function input($event) {
        _vm.model.cc = $event;
      }
    },
    model: {
      value: _vm.model.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "cc", $$v);
      },
      expression: "model.cc"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.addBCC ? _c("div", {
    staticClass: "columns vertical-align"
  }, [_c("div", {
    staticClass: "column is-narrow w-150"
  }, [_vm._v("\n          " + _vm._s(_vm.$t("BCC")) + "\n          "), _c("span", {
    staticClass: "pointer",
    on: {
      click: function click($event) {
        _vm.addBCC = false;
      }
    }
  }, [_vm._v("x")])]), _vm._v(" "), _c("div", {
    staticClass: "column"
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    staticClass: "width-full",
    attrs: {
      multiple: true,
      placeholder: _vm.$t("CC"),
      clearable: ""
    },
    on: {
      input: function input($event) {
        _vm.model.bcc = $event;
      }
    },
    model: {
      value: _vm.model.bcc,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "bcc", $$v);
      },
      expression: "model.bcc"
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("el-input", {
    staticClass: "width-full min-height-32",
    attrs: {
      type: "textarea",
      autosize: "",
      placeholder: _vm.$t("Subject")
    },
    model: {
      value: _vm.model.subject,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "subject", $$v);
      },
      expression: "model.subject"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "column is-narrow"
  }, [_c("el-dropdown", [_c("el-button", {
    attrs: {
      type: "primary"
    }
  }, [_c("i", {
    staticClass: "fa fa-brush"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.templateData, function (data, i) {
    return _c("el-dropdown-item", {
      key: i
    }, [_c("a", {
      on: {
        click: function click($event) {
          _vm.model.template = data;
          _vm.setTemplateHtml(data.id);
        }
      }
    }, [_vm._v("\n                  " + _vm._s(data.label) + "\n                ")])]);
  }), 1)], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("redactor", {
    staticClass: "margin-top-5 margin-bottom-5 kv-border-default",
    attrs: {
      config: _vm.configOptions
    },
    model: {
      value: _vm.model.body_html,
      callback: function callback($$v) {
        _vm.$set(_vm.model, "body_html", $$v);
      },
      expression: "model.body_html"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "columns"
  }, [_c("div", {
    staticClass: "column"
  }, [_c("el-link", {
    on: {
      click: function click($event) {
        _vm.filesVisibility = !_vm.filesVisibility;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("+add more file")))]), _vm._v(" "), _vm.filesVisibility ? _c("el-upload", {
    ref: "files",
    staticClass: "width-full ktw-mb-10",
    attrs: {
      action: "",
      "auto-upload": false,
      "on-remove": _vm.handleRemove,
      "file-list": _vm.fileList,
      multiple: "",
      drag: ""
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("\n              " + _vm._s(_vm.$t("Drop file here or")) + "\n              "), _c("em", [_vm._v(_vm._s(_vm.$t("click to upload")))])])]) : _vm._e()], 1)])])]), _vm._v(" "), _c("div", {
    staticClass: "columns-bottom"
  }, [_c("div", {
    staticClass: "column text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.sendMail
    }
  }, [_c("i", {
    staticClass: "fal fa-paper-plane"
  }), _vm._v(" " + _vm._s(_vm.$t("Send Now")) + "\n      ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      icon: "fa fa-eraser"
    },
    on: {
      click: _vm.closeDialog
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };