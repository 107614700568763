var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.embeded ? _c("div", {
    staticClass: "tags-view-container",
    attrs: {
      id: "tags-view-container"
    }
  }, [_c("search-bar"), _vm._v(" "), _c("div", {
    staticClass: "right-side-header"
  }, [_vm.user && _vm.user.userId ? _c("div", {
    staticClass: "viewItem"
  }, [_c("el-dropdown", {
    attrs: {
      trigger: "click",
      placement: "top-start",
      size: "large"
    },
    on: {
      command: _vm.handleCommand
    }
  }, [_c("kv-avatar", {
    staticClass: "cursor-pointer",
    attrs: {
      "user-id": _vm.user.userId,
      size: "34x34",
      "is-rounded": "",
      "has-border": "",
      MY_ACTIVITY_STATUS: _vm.MY_ACTIVITY_STATUS
    }
  }), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    staticStyle: {
      "border-bottom": "1px solid #efefef",
      "padding-bottom": "2px"
    },
    attrs: {
      icon: "fal fa-chevron-left"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "right",
      width: "200",
      trigger: "hover"
    }
  }, [_c("ul", {
    staticClass: "act_status"
  }, _vm._l(_vm.activityStatus, function (ast) {
    return _c("li", {
      on: {
        click: function click($event) {
          return _vm.changeActivityStatus(ast);
        }
      }
    }, [_c("div", {
      staticClass: "icon-circle",
      style: {
        backgroundColor: "#" + ast.color,
        color: "#" + ast.icon_color
      }
    }, [_c("svg-icon", {
      attrs: {
        icon: ast.icon
      }
    })], 1), _vm._v(" "), _c("a", [_vm._v(" " + _vm._s(ast.status_name))])]);
  }), 0), _vm._v(" "), _c("a", {
    staticStyle: {
      "padding-left": "10px",
      width: "140px",
      display: "inline-block",
      "min-width": "140px",
      position: "static"
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v(_vm._s(_vm.$t("Status")))])])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "margin-right-5",
    attrs: {
      icon: "fal fa-user-circle",
      command: "/account/my-profile"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("My Profile")))]), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "margin-right-5",
    attrs: {
      icon: "fal fa-bell",
      command: "/account/notifications"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("Notifications")))]), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "margin-right-5",
    attrs: {
      icon: "fal fa-key",
      command: "/account/change-password"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("Change Password")))]), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "margin-right-5",
    attrs: {
      icon: "fal fa-sign-out",
      command: "/account/sign-out"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t("Sign Out")))])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "viewItem"
  }, [_vm.unreadNotification ? _c("el-badge", {
    staticClass: "bageItem",
    attrs: {
      value: _vm.unreadNotification
    }
  }, [_c("el-button", {
    key: "badgebtn" + _vm.config.paused + _vm.scheduledDoNotDisturb,
    staticClass: "activity-button",
    attrs: {
      size: "mini",
      icon: _vm.config.paused == 1 || _vm.scheduledDoNotDisturb == true ? "fal fa-bell-slash" : "fal fa-bell",
      circle: ""
    },
    on: {
      click: function click($event) {
        _vm.activityDrawer = true;
      }
    }
  })], 1) : _c("el-button", {
    key: "btn" + _vm.config.paused + _vm.scheduledDoNotDisturb,
    staticClass: "activity-button",
    attrs: {
      size: "mini",
      icon: _vm.config.paused == 1 || _vm.scheduledDoNotDisturb == true ? "fal fa-bell-slash" : "fal fa-bell",
      type: "success",
      plain: "",
      circle: ""
    },
    on: {
      click: function click($event) {
        _vm.activityDrawer = true;
      }
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "viewItem separator"
  }), _vm._v(" "), _c("div", {
    staticClass: "viewItem secondary"
  }, [_c("el-button", {
    attrs: {
      type: _vm.saveStatus == 0 ? "success" : _vm.saveStatus == 1 ? "warning" : _vm.saveStatus == 2 ? "success" : _vm.saveStatus == 3 ? "danger" : "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.startPlusButton(true);
      }
    }
  }, [_c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.saveStatus == 0,
      expression: "saveStatus == 0"
    }],
    key: "status0"
  }, [_c("i", {
    staticClass: "fa fa-plus"
  })]), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.saveStatus == 1,
      expression: "saveStatus == 1"
    }],
    key: "status1"
  }, [_c("i", {
    staticClass: "far fa-spinner-third"
  })]), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.saveStatus == 2,
      expression: "saveStatus == 2"
    }],
    key: "status2"
  }, [_c("i", {
    staticClass: "far fa-check"
  })]), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.saveStatus == 3,
      expression: "saveStatus == 3"
    }],
    key: "status3"
  }, [_c("i", {
    staticClass: "fa fa-times"
  })])])], 1), _vm._v(" "), _c("plusButton"), _vm._v(" "), _c("time-counter"), _vm._v(" "), _c("profile")], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };