import _objectWithoutProperties from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["input"];
import KivaProxy from "@/utils/kivaProxy/src";
import { TownTransformer } from "@/transformers/TownTransformer";
export default {
  name: "KvTownSelect",
  props: {
    value: String,
    city: String
  },
  data: function data() {
    return {
      mutableValue: this.value,
      loading: false,
      towns: []
    };
  },
  methods: {
    input: function input() {
      this.$emit("input", this.mutableValue);
    }
  },
  computed: {
    attrs: function attrs() {
      return this.$attrs;
    },
    listeners: function listeners() {
      var _this$$listeners = this.$listeners,
        input = _this$$listeners.input,
        listeners = _objectWithoutProperties(_this$$listeners, _excluded);
      return listeners;
    }
  },
  watch: {
    value: function value(val) {
      this.mutableValue = val;
    },
    city: {
      immediate: true,
      handler: function handler(val) {
        this.input();
        if (val) {} else {
          this.mutableValue = null;
        }
      }
    },
    mutableValue: function mutableValue() {
      this.input();
    }
  }
};