import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "flex-container kv-note",
    class: [_vm.formPosition == "top" ? "toTop" : "column-reverse toBottom", _vm.formBorder ? "wBorder" : "", _vm.formFixed ? "fixed" : "relative", _vm.focus ? "highHeight" : "lowHeight", _vm.fixedHeight],
    style: {
      maxHeight: _vm.maxHeight
    },
    attrs: {
      id: "NoteKv"
    }
  }, [_c("div", {
    staticClass: "width-full"
  }, [_vm.title ? _c("h4", {}, [_vm._v(_vm._s(_vm.title))]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "commentField",
    style: _vm.formStyle,
    attrs: {
      id: "commentField"
    }
  }, [_c("div", {
    staticClass: "comments",
    class: _vm.formRadiusClass
  }, [_c("vue-mention", {
    key: _vm.NewNoteKey,
    attrs: {
      show_mention: _vm.show_mention,
      data: _vm.new_note.message,
      focus: _vm.focus,
      position: _vm.position,
      showAvatar: _vm.showAvatar
    },
    on: {
      updateVisible: function updateVisible($event) {
        _vm.show_mention = $event;
      },
      setData: function setData($event) {
        _vm.new_note.message = $event;
      },
      setPosition: function setPosition($event) {
        _vm.$refs.new_note_comments = $event;
      },
      setFocus: function setFocus($event) {
        _vm.focus = $event;
      },
      saveDirect: function saveDirect($event) {
        _vm.saveNote(_vm.new_note, _vm.files);
        _vm.any_change = true;
      },
      uploadDirect: function uploadDirect($event) {
        _vm.files = $event;
        _vm.any_change = true;
      }
    }
  }), _vm._v(" "), _vm.files && _vm.files[0] ? _c("div", {
    staticClass: "margin-top-30 pull-left margin-left-5"
  }, _vm._l(_vm.files, function (fl, i) {
    return fl.name ? _c("span", {
      key: fl.uid,
      staticClass: "imageItem",
      attrs: {
        title: fl.name
      }
    }, [_c("i", {
      staticClass: "fa-w-14",
      class: _vm.getFileIcon(fl, "fa", "sm")
    }), _vm._v("\n            " + _vm._s(_vm.optimizeFileName(fl.name)) + "\n            "), _c("el-button", {
      attrs: {
        type: "text",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.removeAttachment(_vm.files[i]);
        }
      }
    }, [_c("i", {
      staticClass: "fa fa-trash"
    })])], 1) : _vm._e();
  }), 0) : _vm._e(), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.focus,
      expression: "focus"
    }],
    staticClass: "mt-3 pull-right"
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "no-border",
    attrs: {
      type: "primary",
      size: "small",
      primary: "",
      plain: ""
    },
    on: {
      click: function click($event) {
        _vm.saveNote(_vm.new_note, _vm.files);
        _vm.any_change = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Comment")))]), _vm._v(" "), _c("span", {
    staticClass: "mini-info",
    staticStyle: {
      "margin-left": "10px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Shift + Enter")))])], 1)], 1)])]), _vm._v(" "), _vm.notes && _vm.notes[0] ? _c("div", {
    staticClass: "width-full overflow-auto"
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "kv-notes"
  }, _vm._l(_vm.notes, function (note, i) {
    return _c("div", {
      key: "notes" + i,
      staticClass: "note-item",
      attrs: {
        placement: "top"
      }
    }, [_c("span", {
      staticClass: "kv_notes_info"
    }, [note.sender_id ? _c("showUsers", {
      staticStyle: {
        display: "block"
      },
      attrs: {
        selected_users: [note.sender_id],
        nameHidden: true,
        media_size: "small"
      }
    }) : _vm._e(), _vm._v(" "), note.note_id != _vm.selectedNote.note_id && note.sender_id == _vm.$store.state.users.CURRENT_USER.userId ? _c("div", [_c("div", {
      staticClass: "miniIcon"
    }, [_c("i", {
      staticClass: "el-icon-edit font-14",
      attrs: {
        medium: ""
      },
      on: {
        click: function click($event) {
          return _vm.handleCommand2({
            command: "edit",
            i: i
          });
        }
      }
    })]), _vm._v(" "), _c("div", {
      staticClass: "miniIcon"
    }, [_c("i", {
      staticClass: "el-icon-delete font-14",
      attrs: {
        medium: ""
      },
      on: {
        click: function click($event) {
          return _vm.handleCommand2({
            command: "delete",
            i: i
          });
        }
      }
    })])]) : _vm._e(), _vm._v(" "), note.note_id == _vm.selectedNote.note_id ? _c("div", {
      key: "note_times_" + i,
      staticClass: "miniIcon",
      on: {
        click: function click($event) {
          _vm.selectedNote = {};
        }
      }
    }, [_c("i", {
      staticClass: "fa fa-sm fa-times"
    })]) : _vm._e()], 1), _vm._v(" "), _c("div", {
      staticClass: "commentArea"
    }, [_c("div", {
      staticClass: "header"
    }, [_c("span", {
      staticClass: "user w500"
    }, [_vm._v(_vm._s(note.sender))]), _vm._v(" "), _c("span", {
      staticClass: "date"
    }, [_vm._v(_vm._s(_vm._f("timeDiff")(note.created_on)))])]), _vm._v(" "), _vm.selectedNote.note_id != note.note_id ? _c("span", {
      staticClass: "kv_notes_body",
      domProps: {
        innerHTML: _vm._s(note.message)
      }
    }) : _vm.selectedNote.note_id == note.note_id && note.sender_id == _vm.$store.state.users.CURRENT_USER.userId ? _c("span", {
      staticClass: "kv_notes_body"
    }, [_c("vue-mention", {
      staticClass: "notFixed",
      attrs: {
        show_mention: _vm.show_mention,
        data: _vm.selectedNote.message,
        position: "position",
        focus: true
      },
      on: {
        updateVisible: function updateVisible($event) {
          _vm.show_mention = $event;
        },
        setData: function setData($event) {
          _vm.selectedNote.message = $event;
        },
        setPosition: function setPosition($event) {
          _vm.$refs.new_note_message = $event;
        },
        saveDirect: function saveDirect($event) {
          _vm.saveNote();
          _vm.any_change = true;
        },
        uploadDirect: function uploadDirect($event) {
          _vm.uploadAttachment(_vm.selectedNote, $event);
          _vm.any_change = true;
        }
      }
    }), _vm._v(" "), _c("el-button", {
      staticClass: "mt-3 pull-right",
      attrs: {
        type: "primary",
        size: "mini",
        project: ""
      },
      on: {
        click: function click($event) {
          _vm.saveNote();
          _vm.any_change = true;
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("Save")))])], 1) : _vm._e(), _vm._v(" "), _c("ul", {
      staticClass: "el-upload-list el-upload-list--picture-card kv-scroll"
    }, _vm._l(note.attachments, function (att) {
      return att.name ? _c("li", {
        key: "key" + att.record_id,
        staticClass: "el-upload-list__item is-success rel-pos",
        attrs: {
          tabindex: "0"
        }
      }, [_vm.isImageType(att) ? _c("img", {
        staticClass: "objectFile",
        attrs: {
          src: _vm.getAttachmentUrl(_objectSpread(_objectSpread({}, att), {}, {
            model: _vm.model,
            app: _vm.app,
            record_id: _vm.id
          }))
        }
      }) : _vm._e(), _vm._v(" "), _c("span", [_c("i", {
        staticClass: "font-sm",
        class: _vm.getFileIcon(att, "fad", "sm")
      })]), _vm._v(" "), _c("span", {
        staticClass: "el-upload-list__title"
      }, [_c("a", {
        attrs: {
          title: att.name
        }
      }, [_vm._v(_vm._s(att.name))])]), _vm._v(" "), _c("span", {
        staticClass: "el-upload-list__item-actions"
      }, [_vm.isImageType(att) ? _c("span", {
        staticClass: "el-upload-list__item-preview",
        on: {
          click: function click($event) {
            return _vm.handleView(att);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-zoom-in"
      })]) : _vm._e(), _vm._v(" "), _c("span", {
        staticClass: "el-upload-list__item-download",
        on: {
          click: function click($event) {
            return _vm.openFileUrl(att, att.record_id);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-download"
      })]), _vm._v(" "), _vm.selectedNote.note_id == note.note_id && note.sender_id == _vm.$store.state.users.CURRENT_USER.userId ? _c("span", {
        staticClass: "el-upload-list__item-delete",
        on: {
          click: function click($event) {
            return _vm.delete_attachment(att);
          }
        }
      }, [_c("i", {
        staticClass: "el-icon-delete"
      })]) : _vm._e()]), _vm._v(" "), _vm.isImageType(att) ? [_c("div", {
        directives: [{
          name: "viewer",
          rawName: "v-viewer"
        }],
        staticClass: "viewer-images",
        class: "viewer-".concat(att.file_id)
      }, [_c("img", {
        attrs: {
          src: _vm.getAttachmentUrl(_objectSpread(_objectSpread({}, att), {}, {
            model: _vm.model,
            app: _vm.app,
            record_id: _vm.id
          }))
        }
      })])] : _vm._e()], 2) : _vm._e();
    }), 0)])]);
  }), 0)]) : _vm._e()]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };