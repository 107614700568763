var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeEvent,
      expression: "closeEvent"
    }],
    staticClass: "label-field",
    class: _vm.viewstyle
  }, [_c("div", {
    staticClass: "tag-area",
    on: {
      click: function click($event) {
        return _vm.addNew();
      }
    }
  }, [_vm._l(_vm.selectedTags, function (tag, i) {
    return _c("kv-tag", {
      key: "tg" + i,
      staticClass: "margin-right-5",
      attrs: {
        closable: "",
        "disable-transitions": false,
        icon: "",
        times: true,
        tagClass: "medium",
        color: "#" + tag.relatedData.label.label_color,
        background: "#FFFFFF",
        label: tag.label ? tag.label.label : tag.record_id
      },
      on: {
        clickEvent: function clickEvent($event) {
          return _vm.handleRemove(tag);
        }
      }
    });
  }), _vm._v(" "), _c("el-input", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    ref: "searchField",
    staticClass: "input-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.filterLabels();
      },
      blur: function blur($event) {
        return _vm.filterLabels();
      }
    },
    nativeOn: {
      keyup: [function ($event) {
        return _vm.nextItem.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.choose(_vm.filteredLabels[_vm.currentItem]);
      }]
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  }), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.visible,
      expression: "!visible"
    }],
    staticClass: "button-new-tag",
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.addNew();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("+ New Tag")))])], 2), _vm._v(" "), _c("el-popover", {
    attrs: {
      placement: "top-start",
      width: _vm.width,
      tabindex: 1,
      trigger: "manual"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "custom-list"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.newData.record_id,
      expression: "!newData.record_id"
    }],
    staticClass: "el-scrollbar2",
    class: !_vm.newAddVisible ? "h180" : "h0"
  }, [_c("div", {
    staticClass: "el-select-dropdown__wrap el-scrollbar__wrap",
    staticStyle: {
      "margin-bottom": "-6px",
      "margin-right": "-6px"
    }
  }, [_c("draggable", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    ref: "kvUserSelect",
    staticClass: "el-scrollbar__view el-select-dropdown__list",
    attrs: {
      tag: "ul",
      group: "li",
      draggable: ".dragItems",
      handle: ".handle"
    },
    on: {
      sort: function sort($event) {
        return _vm.saveSort();
      }
    },
    model: {
      value: _vm.filteredLabels,
      callback: function callback($$v) {
        _vm.filteredLabels = $$v;
      },
      expression: "filteredLabels"
    }
  }, _vm._l(_vm.filteredLabels, function (item, i) {
    return _c("li", {
      staticClass: "el-select-dropdown__item dragItems",
      class: {
        selected: item.selected,
        "active-item": _vm.currentItem == i
      }
    }, [_c("div", {
      staticClass: "d-box"
    }, [_c("div", {
      staticClass: "handle d-block"
    }, [_c("svg-icon", {
      attrs: {
        icon: "kv-icon-sort"
      }
    })], 1), _vm._v(" "), _c("div", {
      staticClass: "font-13 d-block margin-left-5 d-content-label",
      style: {
        color: "#" + item.label_color
      },
      on: {
        click: function click($event) {
          item.selected ? "" : _vm.choose(item);
        }
      }
    }, [_vm._v("\n                  " + _vm._s(item.label_name) + "\n                ")])]), _vm._v(" "), _c("div", {
      staticClass: "d-box"
    }, [_c("div", {
      staticClass: "buttons"
    }, [_c("div", {
      staticClass: "color-item d-block",
      style: {
        background: "#" + item.label_color
      },
      on: {
        click: function click($event) {
          item.selected ? "" : _vm.choose(item);
        }
      }
    })]), _vm._v(" "), _c("div", {
      staticClass: "d-block margin-left-5"
    }, [_c("a", {
      on: {
        click: function click($event) {
          $event.preventDefault();
          _vm.newData = item;
          _vm.newAddVisible = true;
        }
      }
    }, [_c("svg-icon", {
      attrs: {
        icon: "kv-icon-edit"
      }
    })], 1), _vm._v(".\n                ")])])]);
  }), 0)], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.newAddVisible,
      expression: "newAddVisible"
    }],
    staticClass: "new-add-input"
  }, [_c("el-input", {
    staticClass: "width-full-input",
    attrs: {
      type: "text",
      placeholder: _vm.$t("New label")
    },
    model: {
      value: _vm.newData.label_name,
      callback: function callback($$v) {
        _vm.$set(_vm.newData, "label_name", $$v);
      },
      expression: "newData.label_name"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "icons"
  }, [_c("div", {
    staticClass: "icon-times",
    on: {
      click: function click($event) {
        _vm.setNewData();
        _vm.newAddVisible = false;
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-times"
  })]), _vm._v(" "), _c("div", {
    staticClass: "icon-times",
    on: {
      click: function click($event) {
        return _vm.saveLabel(_vm.newData);
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-check"
  })]), _vm._v(" "), _c("div", {
    staticClass: "icon-trash",
    on: {
      click: function click($event) {
        return _vm.handleDelete(_vm.newData);
      }
    }
  }, [_c("i", {
    staticClass: "fas fa-trash-alt"
  })])]), _vm._v(" "), _c("div", {
    staticClass: "color-selectors"
  }, _vm._l(_vm.colors, function (color) {
    return _c("div", {
      staticClass: "color-item",
      class: _vm.newData.label_color == color ? "selected" : "",
      style: {
        background: "#" + color
      },
      on: {
        click: function click($event) {
          _vm.newData.label_color = color;
        }
      }
    });
  }), 0)], 1), _vm._v(" "), _c("div", {
    staticClass: "bottomField relative",
    class: _vm.newAddVisible ? "disabled" : "",
    style: {
      width: _vm.width + "px"
    }
  }, [_c("div", {
    staticClass: "new_add",
    on: {
      click: function click($event) {
        _vm.newAddVisible = true;
      }
    }
  }, [_vm._v("\n          " + _vm._s(_vm.$t("+ Add a new label")) + "\n        ")])])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };