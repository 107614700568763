var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    key: "modal" + _vm.threadId,
    staticClass: "reply-wrapper"
  }, [_c("canned-response-modal", {
    on: {
      select: _vm.updateContent
    },
    model: {
      value: _vm.cannedResponseModal,
      callback: function callback($$v) {
        _vm.cannedResponseModal = $$v;
      },
      expression: "cannedResponseModal"
    }
  }), _vm._v(" "), _c("el-drawer", {
    class: _vm.isVisible ? "large-wrapper" : "small-wrapper",
    attrs: {
      title: !_vm.isForward ? _vm.$t("Reply") : _vm.$t("Forward"),
      visible: _vm.mutableValue,
      direction: "btt",
      size: _vm.isVisible ? "70vh" : "30px",
      "custom-class": _vm.isVisible ? "reply-drawer" : "reply-drawer-small",
      "destroy-on-close": false,
      wrapperClosable: false,
      "append-to-body": true,
      modal: false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.mutableValue = $event;
      }
    }
  }, [_vm.isVisible ? _c("a", {
    key: "openW",
    staticClass: "next-to-close",
    on: {
      click: function click($event) {
        _vm.isVisible = false;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-compress-alt"
  })]) : _vm._e(), _vm._v(" "), !_vm.isVisible ? _c("a", {
    key: "closeW",
    staticClass: "next-to-close",
    on: {
      click: function click($event) {
        _vm.isVisible = true;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-expand-alt"
  })]) : _vm._e(), _vm._v(" "), _vm.model && _vm.threadId ? _c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "label-width": "130px",
      "label-position": "left"
    }
  }, [_c("el-row", {
    attrs: {
      wrap: ""
    }
  }, [_vm.model.senderEmail ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("To")
    }
  }, [_vm._v("\n             \n               " + _vm._s(_vm.model.senderEmail) + "\n           ")])], 1) : _vm._e(), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isForward,
      expression: "isForward"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("From")
    }
  }, [_vm.fromData ? _c("el-select", {
    staticClass: "width-full",
    attrs: {
      placeholder: _vm.$t("From"),
      clearable: ""
    },
    model: {
      value: _vm.forward.from,
      callback: function callback($$v) {
        _vm.$set(_vm.forward, "from", $$v);
      },
      expression: "forward.from"
    }
  }, _vm._l(_vm.fromData, function (data) {
    return _c("el-option", {
      key: data.id,
      attrs: {
        label: data.label,
        value: data.id
      }
    }, [_vm._v(_vm._s(data.label) + "\n               ")]);
  }), 1) : _c("div", [_vm._v("\n               " + _vm._s(_vm.$t("You don't have any active email address to.")) + "\n             ")])], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isForward,
      expression: "isForward"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("To")
    }
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    attrs: {
      multiple: true
    },
    on: {
      input: function input($event) {
        _vm.forward.to = $event;
      }
    },
    model: {
      value: _vm.forward.to,
      callback: function callback($$v) {
        _vm.$set(_vm.forward, "to", $$v);
      },
      expression: "forward.to"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show.cc,
      expression: "show.cc"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("CC")
    }
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    attrs: {
      multiple: true
    },
    on: {
      input: function input($event) {
        _vm.modelThread.cc = $event;
      }
    },
    model: {
      value: _vm.modelThread.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.modelThread, "cc", $$v);
      },
      expression: "modelThread.cc"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show.bcc,
      expression: "show.bcc"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("BCC")
    }
  }, [_c("kv-contacts", {
    key: _vm.contactKeys,
    attrs: {
      multiple: true
    },
    on: {
      input: function input($event) {
        _vm.modelThread.bcc = $event;
      }
    },
    model: {
      value: _vm.modelThread.bcc,
      callback: function callback($$v) {
        _vm.$set(_vm.modelThread, "bcc", $$v);
      },
      expression: "modelThread.bcc"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.timeEnrtyField,
      expression: "timeEnrtyField"
    }],
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.$t("Save Time Entry")
    }
  }, [_c("KvTimeSelect", {
    attrs: {
      border: "full",
      hours: _vm.quickSaveonComplete.hours,
      minutes: _vm.quickSaveonComplete.minutes
    },
    on: {
      input: function input($event) {
        _vm.quickSaveonComplete.hours = $event.hours;
        _vm.quickSaveonComplete.minutes = $event.minutes;
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("redactor", {
    ref: "redactor",
    attrs: {
      config: _vm.editorConfig
    },
    model: {
      value: _vm.messageModel.message,
      callback: function callback($$v) {
        _vm.$set(_vm.messageModel, "message", $$v);
      },
      expression: "messageModel.message"
    }
  }), _vm._v(" "), _c("el-upload", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showFileUpload,
      expression: "showFileUpload"
    }],
    key: _vm.keyUploadForm,
    ref: "uploadFiles",
    staticClass: "upload-wrapper",
    attrs: {
      drag: "",
      action: "",
      "before-upload": _vm.checkBeforeUpload,
      "http-request": _vm.uploadFile,
      multiple: ""
    }
  }, [_c("i", {
    staticClass: "el-icon-upload"
  }), _vm._v(" "), _c("div", {
    staticClass: "el-upload__text"
  }, [_vm._v("\n             " + _vm._s(_vm.$t("Drop file here or")) + " "), _c("em", [_vm._v(_vm._s(_vm.$t("click to upload")))])])]), _vm._v(" "), _c("footer", {
    staticClass: "re-toolbar-footer"
  }, [!_vm.isForward ? _c("el-dropdown", {
    staticClass: "margin-right-5",
    on: {
      command: _vm.handleCommand
    }
  }, [_c("el-button", {
    staticClass: "margin-right-5",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_c("i", {
    staticClass: "fal fa-paper-plane"
  }), _vm._v(" " + _vm._s(_vm.$t("Send")) + "\n           "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _vm.model.requestType && _vm.model.requestType.value == 1 ? _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "close"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Close")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "open"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Open")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "hold-customer"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Waiting for Customer")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "hold-internal"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Waiting for Internal Task")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "hold-third-party"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Waiting for Third Party")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "draft"
    }
  }, [_vm._v(_vm._s(_vm.$t("Save as Draft")))])], 1) : _vm.model.requestType && _vm.model.requestType.value == 2 ? _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "close"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Close")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "feasibility"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark status as Feasibility")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "project-plan"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Waiting for Project Plan")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "customer-approval"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Waiting for Customer Approval")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "in-progress"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as In Progress")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "completed"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Completed")))])], 1) : _vm.model.requestType && _vm.model.requestType.value == 3 ? _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "close"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Close")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "unqualified"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as Unqualified")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "converted-lead"
    }
  }, [_vm._v(_vm._s(_vm.$t("Send & Mark as converted to Lead")))])], 1) : _vm._e()], 1) : _c("el-button", {
    staticClass: "margin-right-5",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.sendForward();
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-paper-plane"
  }), _vm._v(" " + _vm._s(_vm.$t("Send")) + "\n       ")]), _vm._v(" "), _c("div", {
    attrs: {
      id: "editor-toolbar"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "redactor-toolbar"
  }, [_c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.show.cc = !_vm.show.cc;
      }
    }
  }, [_vm.show.cc ? _c("span", [_vm._v("x")]) : _c("span", [_vm._v("+")]), _vm._v(" CC")]), _vm._v(" "), _c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.show.bcc = !_vm.show.bcc;
      }
    }
  }, [_vm.show.bcc ? _c("span", [_vm._v("x")]) : _c("span", [_vm._v("+")]), _vm._v(" BCC")]), _vm._v(" "), _c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.showFileUpload = !_vm.showFileUpload;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-paperclip"
  })]), _vm._v(" "), _c("a", {
    staticClass: "re-button re-html re-button-icon",
    attrs: {
      href: "#",
      role: "button",
      "aria-label": "HTML",
      tabindex: "-1",
      "data-re-icon": "true"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.cannedResponseModal = true;
      }
    }
  }, [_c("i", {
    staticClass: "fal fa-comment-alt-lines"
  })])])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };