import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
import tasksMixin from "../../../mixins/tasks.js";
import attachmentMixin from "../../../mixins/attachment.js";
import { fileTypeMixin } from "@/mixins/fileTypeMixin";
export default {
  mixins: [tasksMixin, attachmentMixin, fileTypeMixin],
  data: function data() {
    return {
      files: this.attachmentsList,
      theatt: [],
      newFiles: this.attachmentsList,
      select_file: null,
      is_delete_attachment: false,
      files_available: false,
      selected_file: {},
      imageDialog: {
        visible: false,
        url: ""
      }
    };
  },
  components: {
    fileView: function fileView() {
      return import("../main/file_view");
    }
  },
  props: {
    visibility: Boolean,
    attachmentsList: {
      type: Array,
      default: []
    },
    listStyle: {
      type: String,
      default: "standard"
    },
    view: {
      type: String,
      default: "light"
    },
    record_id: [Number, String],
    documents_key: [Number, String],
    data: Object,
    task: Object,
    app: {
      type: String,
      default: "act"
    },
    model: {
      type: String,
      default: "task"
    },
    scrollDir: {
      type: String,
      default: "x"
    }
  },
  methods: {
    handleView: function handleView(att) {
      var record_id = this.record_id;
      var model = this.model;
      var app = this.app;
      var r = "".concat(this.$kvAttachmentViewPath, "?app=") + app + "&model=" + model + "&record_id=" + record_id + "&file_name=" + att.name + "&file_type=" + att.file_type + "&uid=" + att.file_id + "&_blank=1&_csrf=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOjI5MzI0LCJleHAiOjE1ODQ1MDA4Mjl9.Hb-ptWmjnoDhg7sGkXa2PDjJQ-K0UpoU6fjVEi7MAh4";
      var viewer = this.$el.querySelector(".viewer-".concat(att.file_id)).$viewer;
      viewer.show();
    },
    handleView2: function handleView2(att) {
      var viewer = this.$el.querySelector(".viewer-111").$viewer;
      viewer.show();
    },
    handlePreview: function handlePreview() {},
    handleRemove: function handleRemove() {},
    handleChange: function handleChange(e) {
      // her dosya eklemeyi  değişim olarak kabul ediyor bu nedenle array deki son item i alacağız..

      this.selectedFiles = this.$refs.inputUpload.uploadFiles;
      var files = this.$refs.inputUpload.uploadFiles;
      if (this.record_id && files && files[0] && files[0].name) {
        this.upload_task_attachment("last", null, null, this.app, this.model);
        this.$emit("showAttachments", false);
        this.newFiles = [];
      }
      this.$emit("updateAttachments", files);
    },
    imageDialogOpen: function imageDialogOpen(att) {
      // this.select_file = this.get_attachment(this.id, img.file_id);
      this.imageDialog.url = this.getImageUrl(att, record_id);
      this.imageDialog.visible = true;
    },
    getImageUrl: function getImageUrl(att, record_id) {
      var model = this.model;
      var app = this.app;
      return "".concat(this.$kvAttachmentViewPath, "?app=") + app + "&model=" + model + "&record_id=" + record_id + "&file_name=" + att.name + "&file_type=" + att.file_type + "&uid=" + att.file_id + "&_blank=1&_csrf=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOjI5MzI0LCJleHAiOjE1ODQ1MDA4Mjl9.Hb-ptWmjnoDhg7sGkXa2PDjJQ-K0UpoU6fjVEi7MAh4";
    },
    up_delete_attachment: function up_delete_attachment(img) {
      var _this = this;
      var model = this.model;
      var app = this.app;
      this.$confirm(this.$t("This will permanently delete the file"), this.$t("Warning"), {
        confirmButtonText: this.$t("OK_Delete"),
        cancelButtonText: this.$t("Cancel_Delete"),
        type: "warning"
      }).then(function () {
        _this.delete_attachment(_this.record_id, img.file_id, app, model);
        _this.$message({
          type: "success",
          message: _this.$t("Delete completed")
        });
      }).catch(function () {
        _this.$message({
          type: "info",
          message: _this.$t("Delete canceled")
        });
      });
      // this.is_delete_attachment = false;
    },
    delete_attachment_demand: function delete_attachment_demand() {
      this.is_delete_attachment = true;
    }
  },
  watch: {
    attachments: function attachments(val) {
      var att = val && val[0] ? true : false;
      // this.$emit("showAttachments",att)
    }
  },
  created: function created() {
    this.get_attachments(this.record_id, this.app, this.model);
  }
};