import "core-js/modules/es.array.map.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { TEMPLATE_CATEGORIES, TEMPLATE_STATUSES } from './mutation-types';
import { FieldOptionTransformer } from '@/transformers/FieldOptionTransformer';
export default {
  getTemplateCategories: function getTemplateCategories(_ref, payload) {
    var commit = _ref.commit;
    new KivaProxy({
      endpoint: 'rulesup/checklist_template_category'
    }).setFields(['category_name', 'sort_order', 'record_owner', 'created_on']).all().then(function (response) {
      commit(TEMPLATE_CATEGORIES, response.records);
    });
  },
  getTemplateStatuses: function getTemplateStatuses(_ref2, payload) {
    var commit = _ref2.commit;
    new KivaProxy({
      endpoint: 'rulesup/checklist_template/template_status/options'
    }).all().then(function (response) {
      commit(TEMPLATE_STATUSES, FieldOptionTransformer.fetchCollection(response.records));
    });
  },
  setTemplateCategoriesSort: function setTemplateCategoriesSort(_ref3, payload) {
    var commit = _ref3.commit;
    var mappedCategories = payload.map(function (category, index) {
      category.sort_order = index;
      return category;
    });
    var data = mappedCategories.map(function (_ref4) {
      var sort_order = _ref4.sort_order,
        record_id = _ref4.record_id;
      return {
        sort_order: sort_order,
        record_id: record_id
      };
    });
    new KivaProxy({
      endpoint: 'rulesup/checklist_template_category'
    }).update(data).then(function (response) {
      commit(TEMPLATE_CATEGORIES, mappedCategories);
    });
  }
};