import "core-js/modules/es.object.to-string.js";
import Vue from 'vue';
import KvImageCropModal from './KvImageCropModal';
var ImageCropModalService = Vue.extend(KvImageCropModal);
export var imageCropModalService = function imageCropModalService() {
  var _data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var onSave = arguments.length > 1 ? arguments[1] : undefined;
  return new Promise(function (resolve, reject) {
    var instance = new ImageCropModalService({
      data: function data() {
        return _data;
      },
      methods: {
        resolve: resolve,
        reject: reject
      }
    });
    instance.$mount();
    document.body.appendChild(instance.$el);
  });
};