export default {
  methods: {
    renew_alert: function renew_alert() {
      this.$store.state.response_alert = this.$store.state.default_response_alert;
    },
    start_notify: function start_notify() {
      var val = this.$store.state.response_alert;
      this.$notify({
        title: '',
        message: val.text,
        type: val.class
      });
    }
  }
};