var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h5", [_vm._v(_vm._s(_vm.$t("Something Awesome Is In The Work.")))]), _vm._v(" "), _c("p", [_vm._v(_vm._s(_vm.$t("We are working hard on this exicting feature that we think you really like!")))]), _vm._v(" "), _c("img", {
    staticClass: "image",
    attrs: {
      src: "/working.png"
    }
  })]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };