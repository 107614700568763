import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _search;
import moment from 'moment';
import Cookies from 'js-cookie';
import actions from './actions';
export var state = {
  list: [],
  search: (_search = {
    keyword: '',
    list_type: 'user_project_base',
    view: '',
    limit: 1000,
    page: 1,
    project: {
      value: null
    },
    dateRange: 'overdueThisWeek',
    task_status: 'open',
    groupBy: 'status'
  }, _defineProperty(_search, "project", {}), _defineProperty(_search, "member", {}), _defineProperty(_search, "relation", 'assigned_or_dependent'), _defineProperty(_search, "selectedModuls", ['task', 'support']), _defineProperty(_search, "record_owner", null), _search),
  panelView: Cookies.get('focusShowPanel') ? JSON.parse(Cookies.get('focusShowPanel')) : {
    part1: true,
    part2: false,
    part3: false,
    part4: false,
    view: '',
    groupBy: 'status',
    viewType: 'list'
  },
  popup: {
    show: false,
    data: {
      type: '',
      record_id: 0
    }
  }
};