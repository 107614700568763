import _objectWithoutProperties from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["input"];
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { CrmContactTransformer } from '@/transformers/CrmContactTransformer';
export default {
  name: 'KvAccountMemberSelect',
  props: {
    value: {},
    accountId: {}
  },
  data: function data() {
    return {
      mutableValue: this.value,
      loading: false,
      members: []
    };
  },
  methods: {
    getMembers: function getMembers() {
      var _this = this;
      this.loading = true;
      new KivaProxy({
        endpoint: 'crm/contact'
      }).setFilters({
        account_name: ['=', this.accountId]
      }).all().then(function (response) {
        _this.members = CrmContactTransformer.fetchCollection(response.records);
        var memberExist = _this.members.find(function (member) {
          return member.recordId === (_this.mutableValue && _this.mutableValue.recordId);
        });
        if (!memberExist) {
          _this.mutableValue = null;
        }
        _this.loading = false;
      });
    },
    input: function input(val) {
      this.$emit('input', val);
    }
  },
  computed: {
    attrs: function attrs() {
      return this.$attrs;
    },
    listeners: function listeners() {
      var _this$$listeners = this.$listeners,
        input = _this$$listeners.input,
        listeners = _objectWithoutProperties(_this$$listeners, _excluded);
      return listeners;
    }
  },
  watch: {
    value: function value(val) {
      this.mutableValue = val;
    },
    accountId: {
      immediate: true,
      handler: function handler(val) {
        if (val) {
          this.getMembers();
        } else {
          this.mutableValue = null;
        }
      }
    },
    mutableValue: function mutableValue(val) {
      this.input(val);
    }
  }
};