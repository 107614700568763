import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.map.js";
import Vue from 'vue';
import { datePickerOptions } from '@rulesup/configs/datepicker';
import { mapGetters, mapState } from 'vuex';
import { USERS } from '@/store/modules/users/mutation-types';
Vue.mixin({
  computed: _objectSpread(_objectSpread(_objectSpread({
    $datepickerOptions: function $datepickerOptions() {
      return datePickerOptions;
    },
    $isRulesupAdmin: function $isRulesupAdmin() {
      if (this.getCurrentUser) {
        return this.getCurrentUser.profile === 'Admin';
      } else {
        return false;
      }
    },
    $assignableData: function $assignableData() {
      var users = this.users.map(function (user) {
        return {
          label: user.fullName,
          value: "U".concat(user.userId)
        };
      });
      var groups = this.groups.map(function (group) {
        return {
          label: group.label,
          value: "G".concat(group.value)
        };
      });
      return [{
        title: this.$t('Groups'),
        values: groups
      }, {
        title: this.$t('Users'),
        values: users
      }];
    }
  }, mapGetters('common', ['groups'])), mapGetters('users', ['getCurrentUser'])), mapState('users', {
    users: USERS
  }))
});