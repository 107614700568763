import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var calendarRoutes = [{
  path: '',
  hidden: true,
  component: function component() {
    return import('@rulesup/views/calendar/Calendar');
  },
  name: 'rulesup.calendar',
  meta: {
    title: 'Calendar',
    icon: 'fal fa-calendar'
  },
  props: true
}];