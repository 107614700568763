var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "popup-view-container",
    attrs: {
      id: "popup-view-container"
    }
  }, [_vm.threadPopup.show ? _c("inbox-modal", {
    staticStyle: {
      "z-index": "99999"
    },
    attrs: {
      pushData: _vm.threadPopup.data
    },
    model: {
      value: _vm.threadPopup.show,
      callback: function callback($$v) {
        _vm.$set(_vm.threadPopup, "show", $$v);
      },
      expression: "threadPopup.show"
    }
  }) : _vm.quickTaskModal ? _c("quickTask") : _vm.openPagePopup.show ? _c("pages-modal", {
    attrs: {
      forcedData: _vm.openPagePopup.data,
      id: _vm.openPagePopup.data.record_id
    },
    on: {
      setDialogFormVisible: function setDialogFormVisible($event) {
        _vm.openPagePopup.show = false;
      }
    },
    model: {
      value: _vm.openPagePopup.show,
      callback: function callback($$v) {
        _vm.$set(_vm.openPagePopup, "show", $$v);
      },
      expression: "openPagePopup.show"
    }
  }) : _vm.openPageTemplatePopup.show ? _c("pages-template-modal", {
    attrs: {
      forcedData: _vm.openPageTemplatePopup.data,
      node: _vm.openPageTemplatePopup.node
    },
    on: {
      setDialogFormVisible: function setDialogFormVisible($event) {
        _vm.openPageTemplatePopup.show = false;
      }
    },
    model: {
      value: _vm.openPageTemplatePopup.show,
      callback: function callback($$v) {
        _vm.$set(_vm.openPageTemplatePopup, "show", $$v);
      },
      expression: "openPageTemplatePopup.show"
    }
  }) : _vm._e(), _vm._v(" "), _vm.componentName ? _c(_vm.componentName, {
    tag: "component"
  }) : _vm._e(), _vm._v(" "), _c("el-drawer", {
    attrs: {
      title: "",
      visible: _vm.HELP_DRAWER.visible,
      direction: "rtl",
      size: "800px",
      withHeader: false
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.HELP_DRAWER, "visible", $event);
      }
    }
  }, [_c("span", [_c("iframe", {
    staticStyle: {
      width: "800px",
      border: "0",
      height: "100vh"
    },
    attrs: {
      src: _vm.HELP_DRAWER.path + "&language=" + _vm.$locale,
      title: "Help Center"
    }
  })])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };