import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.push.js";
import TaskEditModal from "@/components/tasks/main/forms/taskEditModal";
import { Base64 } from "js-base64";
import AccountDetail from '@/views/crm/AccountDetail.vue';
import attachmentMixin from "@/mixins/attachment.js";
import notificationMixin from "@/mixins/notification.js";
import showUsers from "@/components/tasks/user/show_users.vue";
export default {
  mixins: [attachmentMixin, notificationMixin],
  components: {
    kvTag: function kvTag() {
      return import("@/components/kiva/kvTag");
    },
    // TaskEditModal: () => import("@/components/tasks/main/forms/taskEditModal"),
    // TaskEditModal: () => import("@/components/tasks/main/forms/taskEditModal"),
    KvAvatar: function KvAvatar() {
      return import("@/components/kiva/KvAvatar");
    },
    taskList: function taskList() {
      return import("@/layout/components/TagsView/results/taskList");
    },
    accountList: function accountList() {
      return import("@/layout/components/TagsView/results/accountList");
    },
    contactList: function contactList() {
      return import("@/layout/components/TagsView/results/contactList");
    },
    standardList: function standardList() {
      return import("@/layout/components/TagsView/results/standardList");
    },
    modalTemplate: function modalTemplate() {
      return import("@/components/kiva/kvModalTemplate");
    },
    historyTable: function historyTable() {
      return import("@/components/tasks/activities/historyTable");
    },
    AccountDetail: AccountDetail,
    TaskEditModal: TaskEditModal,
    showUsers: showUsers,
    SvgIcon: function SvgIcon() {
      return import("@/components/kiva/kvSvgIcon.vue");
    }
  },
  props: {
    showTrigger: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      currentItem: 0,
      currentGroup: 0,
      currentOption: 0,
      displayGroup: true,
      currentItemGroup: 0,
      loadingSearch: false,
      selectOne: false,
      showGroups: false,
      globalKeyword: "",
      oldKeyword: "",
      keywordChanged: null,
      timeoutKeyword: null,
      selectedGroup: {},
      foundGroups: [],
      totalGroup: 0,
      searchedText: {},
      resultGroups: [],
      results: [],
      search: {
        options: "all"
      },
      counter: {
        duration: 500,
        interval: null
      },
      globalGroups: {
        "t:": {
          index: 1,
          id: "t:",
          app: "act",
          hide: false,
          model: "task",
          label: this.$t("Tasks"),
          icon: "fa fa-tasks",
          component: "task-list",
          options: [{
            label: this.$t("My Open Tasks"),
            key: "my_open_tasks"
          }, {
            label: this.$t("All Open Tasks"),
            key: "all_open_tasks"
          }, {
            label: this.$t("All Tasks"),
            key: "all"
          }]
        },
        "c:": {
          index: 2,
          id: "c:",
          app: "crm",
          hide: false,
          component: "contact-list",
          model: "contact",
          label: this.$t("Contacts"),
          icon: "fal fa-address-book",
          options: [{
            label: this.$t("All Contacts"),
            key: "all"
          }]
        },
        "a:": {
          index: 3,
          id: "a:",
          app: "crm",
          hide: false,
          component: "account-list",
          model: "account",
          label: this.$t("Organization / Company Accounts"),
          icon: "fal fa-building",
          options: [{
            label: this.$t("All Accounts"),
            key: "all"
          }]
        },
        "p:": {
          index: 4,
          id: "p:",
          app: "act",
          hide: false,
          component: "standard-list",
          list_labels: {
            title: "project_name",
            sub_title: "account_name"
          },
          model: "project",
          label: this.$t("Projects"),
          icon: "fal fa-building",
          options: [{
            label: this.$t("All Projects"),
            key: "all"
          }]
        },
        "d:": {
          index: 5,
          hide: false,
          id: "d:",
          app: "crm",
          model: "deal",
          label: this.$t("Deals"),
          component: "standard-list",
          list_labels: {
            title: "subject",
            sub_title: "due_date",
            sub_title2: "account_name"
          },
          icon: "fal fa-bullseye-arrow ",
          //fa-handshake
          options: [{
            label: this.$t("Open Deals"),
            key: "open"
          }, {
            label: this.$t("All Deals"),
            key: "all"
          }]
        },
        "e:": {
          index: 6,
          hide: false,
          id: "e:",
          app: "act",
          model: "event",
          component: "standard-list",
          list_labels: {
            title: "subject",
            sub_title: "start_date",
            sub_title2: "end_date",
            sub_title3: "location"
          },
          label: this.$t("Activities"),
          icon: "fal fa-bell",
          options: [{
            label: this.$t("All Activities"),
            key: "all"
          }]
        },
        "dm:": {
          index: 7,
          hide: false,
          id: "dm:",
          app: "dm",
          model: "document",
          component: "standard-list",
          list_labels: {
            title: "document_name",
            sub_title: "record_owner"
          },
          label: this.$t("Documents"),
          icon: "fal fa-archive",
          options: [{
            label: this.$t("All Documents"),
            key: "all"
          }]
        },
        "pg:": {
          index: 8,
          hide: false,
          id: "pg:",
          app: "pages",
          model: "page",
          label: this.$t("Pages"),
          component: "standard-list",
          list_labels: {
            title: "title",
            sub_title: "page_type",
            sub_title2: "parent_page"
          },
          icon: "fal fa-file-edit",
          options: [{
            label: this.$t("All Pages"),
            key: "all"
          }]
        }
      }
    };
  },
  computed: {
    toggleSidebar: function toggleSidebar() {
      return this.$store.state.app.secondSidebar;
    },
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    tasksPopup: {
      get: function get() {
        return this.$store.state.tasks.popup;
      },
      set: function set(val) {
        this.$store.state.tasks.popup = val;
      }
    },
    threadPopup: {
      get: function get() {
        return this.$store.state.inbox.threadPopup;
      },
      set: function set(val) {
        this.$store.state.inbox.threadPopup = val;
      }
    },
    templatePopup: {
      get: function get() {
        return this.$store.state.app.templatePopup;
      },
      set: function set(val) {
        this.$store.state.app.templatePopup = val;
      }
    },
    searchGlobal: {
      get: function get() {
        return this.$store.state.app.searchGlobal;
      },
      set: function set(val) {
        this.$store.state.app.searchGlobal = val;
      }
    },
    openPagePopup: {
      get: function get() {
        return this.$store.state.app.openPagePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPagePopup = val;
      }
    },
    openPageTemplatePopup: {
      get: function get() {
        return this.$store.state.app.openPageTemplatePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPageTemplatePopup = val;
      }
    }
  },
  watch: {
    globalKeyword: function globalKeyword(val) {
      if (val == "") {
        // this.removeGlobalSearch();
        this.searchGlobal.show = true;
      } else {
        this.startSearch(val);
        this.searchGlobal.show = true;
      }
    },
    "searchGlobal.show": function searchGlobalShow(val) {
      var _this = this;
      if (val) {
        setTimeout(function () {
          _this.$refs.globalSearch.focus();
        }, 500);
      }
    },
    "$route.query.target": function $routeQueryTarget(q) {
      if (q) {
        var f = q.split("/");
        console.log("f", f);
        if (f[0] == "editRecord") {
          var data = Base64.decode(f[1]).split(",");
          if (data[0]) {
            data[0] = data[0].substring(1);
            this.$store.state.tasks.popup.task = {
              recordId: data[0],
              record_id: data[0]
            };
            this.$store.state.tasks.popup.show = true;
          }
        }
      }
    },
    "searchGlobal.addGroup": function searchGlobalAddGroup(val) {
      var _this2 = this;
      if (val && val != "") {
        setTimeout(function () {
          var group = _this2.globalGroups[val];
          _this2.addGroup(group);
          _this2.$refs.globalSearch.focus();
          _this2.searchGlobal.addGroup = "";
        }, 500);
      }
    }
  },
  methods: {
    setShowSearch: function setShowSearch() {
      this.searchGlobal.show = true;
    },
    chooseGroup: function chooseGroup() {
      if (this.selectedGroup && this.selectedGroup.model) {
        this.selectedGroup = {};
        this.searchQuery(true);
      } else {
        var group = this.foundGroups[this.currentGroup];
        this.triggerSearch(group, "all");
      }
    },
    showSearchPanel: function showSearchPanel() {
      this.searchGlobal.show = true;
    },
    closeEvent: function closeEvent() {
      if (this.searchGlobal.show) {
        this.searchGlobal.show = false;
      }
    },
    changeSelectedGroupOptions: function changeSelectedGroupOptions() {
      var _this3 = this;
      var c = this.currentOption;
      var options = this.selectedGroup.options;
      var total = this.selectedGroup.options.length - 1;
      var selected = options.forEach(function (element, i) {
        if (element.key == _this3.search.options) {
          return i;
        }
      });
      var newselected = total < selected ? selected + 1 : 0;
      var key = options[newselected].key;
      this.search.options = key;
      this.triggerSearch(selectedGroup, key);
    },
    changeSelectedGroup: function changeSelectedGroup() {
      var increase = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      var setCurrentItem = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "none";
      var c = this.currentGroup;
      var total = this.foundGroups.length - 1;
      if (!this.selectOne) {
        this.currentGroup = 0;
        this.currentItem = 0;
        this.showGroup(this.foundGroups[this.currentGroup]);
      } else if (increase && c < total) {
        this.currentGroup = this.currentGroup + 1;
        this.currentItem = 0;
        this.showGroup(this.foundGroups[this.currentGroup]);
      } else if (!increase) {
        this.currentGroup = c > 0 ? this.currentGroup - 1 : total;
        this.currentItem = 0;
        this.showGroup(this.foundGroups[this.currentGroup]);
      } else {
        this.currentGroup = 0;
        this.selectOne = false;
        this.showGroup("all");
      }
      if (setCurrentItem == "last") {
        var sgroup = this.foundGroups[this.currentGroup];
        this.currentItem = resultGroups[sgroup.key].length - 1;
      }
    },
    showGroup: function showGroup(group) {
      var _this4 = this;
      var status = group == "all" ? false : true;
      var keys = Object.keys(this.globalGroups);
      keys.forEach(function (k) {
        var key = _this4.globalGroups[k].hide = status;
      });
      if (group && group != "all") {
        group.hide = false;
        this.selectOne = true;
      } else {
        this.selectOne = false;
      }
    },
    removeGlobalSearch: function removeGlobalSearch() {
      this.globalKeyword = "";
      this.showGroups = false;
      Object.assign({}, this.selectedGroup);
      this.selectedGroup = {};
      this.resultGroups = [];
      this.foundGroups = [];
      this.results = [];
      this.search.options = "all";
      this.searchGlobal.show = false;
    },
    clickEnter: function clickEnter() {
      if (!this.showGroups && this.globalKeyword) {
        var currentItem = this.currentItem;
        var res = this.results;
        if (this.selectedGroup && this.selectedGroup.model && res[this.currentItem]) {
          this.searchGlobal.show = true;
          this.showGroups = false;
          this.getPath(res[currentItem], "template");
        } else {
          var _currentItem = this.currentItem;
          if (this.resultGroups) {
            var gr = this.foundGroups[this.currentGroup];
            var r = this.resultGroups[gr.key];
            var _res = r && r[_currentItem] ? r[_currentItem] : {};
            this.getPath(_objectSpread(_objectSpread({}, _res), gr), "template");
          }
        }
      } else {
        var currentItemGroup = this.currentItemGroup - 1;
        var _res2 = this.globalGroups;
        var keys = Object.keys(this.globalGroups);
        if (keys[currentItemGroup]) {
          this.addGroup(_res2[keys[currentItemGroup]]);
          this.globalKeyword = "";
          this.showGroups = false;
        }
      }
      this.currentItemGroup = 0;
    },
    getPath: function getPath(r, type) {
      // console.log("r",r,type)
      if (!r.model && this.selectedGroup) {
        Object.assign(r, this.selectedGroup);
      }
      if (r.model == "task") {
        this.$store.state.tasks.popup.task = {
          record_id: r.record_id
        };
        this.$store.state.tasks.popup.show = true;
      } else if (r.model == "event") {
        var template = "default";
        this.$store.state.app.templatePopup.data = {
          app: r.app,
          model: r.model,
          label: r.label,
          record_id: r.record_id,
          template: template
        };
        this.$store.state.app.templatePopup.show = true;
        this.$store.state.app.templatePopup.showView = false;
      } else if (r.model == "document") {
        this.$store.dispatch("app/activateDrawer", {
          show: true,
          tab: "files",
          options: {
            record_id: r.record_id
          }
        });
        // }
      } else if (r.model == "page") {
        if (type == "link") {
          this.$router.push({
            path: '/documents/pages?page=' + r.record_id
          });
          this.searchGlobal.show = false;
        } else {
          this.$store.state.app.openPagePopup = {
            show: true,
            data: _objectSpread({}, r)
          };
        }
      } else if (r.model == "project") {
        // this.$router.push({ path: "/tasks?project=" + r.record_id });
        // this.searchGlobal.show = false;
        if (type == "editMode") {
          this.$router.push({
            path: '/project/' + r.record_id + '/tasks/table'
          });
          this.searchGlobal.show = false;
        } else if (type == "link") {
          this.$router.push({
            path: '/project/' + r.record_id + '/overview'
          });
          this.searchGlobal.show = false;
        } else {
          //  this.searchGlobal.show = false;
          this.$store.dispatch("app/activateDrawer", {
            show: true,
            tab: "project",
            options: {
              record_id: r.record_id
            }
          });
        }
      } else if (type == "template" || type == "editMode") {
        var _template = "default";
        if (r.app == "crm" && r.model == "deal") {
          _template = "dealAccountContactDetail";
        }
        this.$store.state.app.templatePopup.data = {
          app: r.app,
          model: r.model,
          label: r.label,
          record_id: r.record_id,
          template: _template
        };
        this.$store.state.app.templatePopup.show = true;
        this.$store.state.app.templatePopup.showView = false;
        // this.$store.state.app.templatePopup.showView =  type == "editMode" ? false : true;
      } else if (r.model == "deal") {
        // this.$router.push({ path: "/crm/deal/" + r.record_id });
        // this.searchGlobal.show = false; 
        if (type == "link") {
          this.$router.push({
            path: '/crm/deal/' + r.record_id
          });
          this.searchGlobal.show = false;
          // this.searchGlobal.show=false;
          // this.$store.state.app.templatePopup.data = { 
          //   openSearchBox:false, 
          //       record_id:r.record_id, app: 'crm',
          //       model: 'dealDetail', 
          //       noBg:true
          //     };
          //   this.$store.state.app.templatePopup.show = true;
          //   this.$store.state.app.templatePopup.showView = false;
        } else {
          this.$store.state.app.templatePopup.data = {
            openSearchBox: false,
            record_id: r.record_id,
            app: 'crm',
            model: 'dealDetail',
            noBg: true
          };
          this.$store.state.app.templatePopup.show = true;
          this.$store.state.app.templatePopup.showView = false;
        }
      } else if (r.model == "contact") {
        // this.$router.push({ path: "/crm/contact/" + r.record_id });
        // this.searchGlobal.show = false;
        this.$store.dispatch("app/activateDrawer", {
          show: true,
          tab: "contact",
          options: {
            record_id: r.record_id
          }
        });
      } else if (r.model == "account") {
        // this.$router.push({ path: "/crm/account/" + r.record_id });
        // this.searchGlobal.show = false;
        if (type == "link") {
          this.$router.push({
            path: '/crm/account/' + r.record_id
          });
          this.searchGlobal.show = false;
        } else {
          this.$store.dispatch("app/activateDrawer", {
            show: true,
            tab: "account",
            options: {
              record_id: r.record_id
            }
          });
        }
      }
    },
    addGroup: function addGroup(r) {
      if (r && r.model) {
        this.selectedGroup = r;
        this.totalGroup = 1;
      }
      this.$refs.globalSearch.focus();
    },
    removeFromGroup: function removeFromGroup(group) {
      this.selectedGroup = {};
      this.totalGroup = 0;
      this.searchQuery(true);
    },
    handleSearch: function handleSearch(event) {
      var keyword = this.globalKeyword;
      var keyword_Arr = this.globalKeyword.split(":");

      //son karakter
      this.showGroups = keyword_Arr && keyword_Arr[1] == "" && keyword_Arr[0] == "" ? true : false;
      if (!this.showGroups && this.globalKeyword) {
        var max = 100;
        if (this.selectedGroup && this.selectedGroup && this.selectedGroup.model) {
          max = this.results.length - 1;
        } else {
          var gr = this.foundGroups[this.currentGroup];
          if (gr && gr.id) {
            max = this.resultGroups && gr && gr.key && this.resultGroups[gr.key] ? this.resultGroups[gr.key].length - 1 : 100;
          }
        }
        if (event.keyCode == 38 && this.currentItem >= 0) {
          this.currentItem--;
        } else if (event.keyCode == 40 && this.currentItem < max + 1) {
          this.currentItem++;
        } else if (event.keyCode == 37) {
          // left
          if (this.selectedGroup && this.selectedGroup.model) {
            this.changeSelectedGroupOptions();
          } else {
            this.changeSelectedGroup(false);
          }
        } else if (event.keyCode == 39) {
          // right
          if (this.selectedGroup && this.selectedGroup.model) {
            this.changeSelectedGroupOptions();
          } else {
            this.changeSelectedGroup();
          }
        }
        if (max < this.currentItem) {
          this.changeSelectedGroup();
        }
        if (event.keyCode == 38 && this.currentItem < 0) {
          this.changeSelectedGroup(false, "last");
        }
        if (this.$refs.kvGlobalSearch) {
          var liH = 45;
          this.$refs.kvGlobalSearch.scrollTop = liH * this.currentItem;
        }
      } else {
        var _max = 8;
        if ((event.keyCode == 38 || event.keyCode == 37) && this.currentItemGroup > 0) {
          this.currentItemGroup--;
        } else if ((event.keyCode == 40 || event.keyCode == 39) && this.currentItemGroup < _max) {
          this.currentItemGroup++;
        }
        if (this.$refs.kvGlobalSearch) {
          var _liH = 45;
          this.$refs.kvGlobalSearch.scrollTop = _liH * this.currentItem;
        }
      }
      // this.searchQuery();
    },
    startSearch: function startSearch(val) {
      var _this5 = this;
      if (val != this.oldKeyword && val != "") {
        if (this.counter.interval) {
          clearInterval(this.counter.interval);
        }
        this.counter.interval = setInterval(function () {
          _this5.searchQuery();
          _this5.oldKeyword = val;
          clearInterval(_this5.counter.interval);
        }, this.counter.duration);

        // var nowMS = new Date();
        // if (!this.keywordChanged) {
        // 	this.keywordChanged = new Date();
        // }

        // let diff = nowMS.getTime() - this.keywordChanged.getTime();
        // if (diff > 600) {
        // 	if (this.timeoutKeyword) {
        // 		clearTimeout(this.timeoutKeyword);
        // 	}
        // 	this.searchQuery();
        // 	this.oldKeyword = val;
        // } else {
        // 	if (this.timeoutKeyword) {
        // 		clearTimeout(this.timeoutKeyword);
        // 	}
        // 	this.timeoutKeyword = setTimeout(() => {
        // 		this.searchQuery();
        // 		this.oldKeyword = val;
        // 	}, 1000);
        // }
        // // console.log(this.keywordChanged,nowMS,diff)
        // this.keywordChanged = nowMS;
      }
    },
    triggerSearch: function triggerSearch(group, option) {
      this.addGroup(group);
      this.search.options = option;
      this.searchQuery(true);
    },
    searchQuery: function searchQuery() {
      var _this6 = this;
      var force = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var keyword = this.globalKeyword;
      if (force || keyword != this.oldKeyword && keyword != "") {
        var keyword_Arr = this.globalKeyword.split(":");
        var searchedText = keyword_Arr && typeof keyword_Arr[1] != "undefined" && keyword_Arr[1] != "" ? keyword_Arr[1] : keyword_Arr && typeof keyword_Arr[1] != "undefined" ? keyword_Arr[1] : keyword;
        this.searchedText = searchedText;
        var selectedGroupId = keyword_Arr && keyword_Arr[0] && typeof keyword_Arr[1] != "undefined" ? keyword_Arr[0] + ":" : "";
        var _selectedGroup = selectedGroupId && this.globalGroups[selectedGroupId] ? this.globalGroups[selectedGroupId] : {};
        this.addGroup(_selectedGroup);
        var parameters = [];
        if (this.selectedGroup && this.selectedGroup && this.selectedGroup.model) {
          var app = this.selectedGroup.app;
          var model = this.selectedGroup.model;
          searchedText = searchedText;
          if (searchedText == " ") {
            searchedText = "";
          }
          if (searchedText.length > 2) {
            this.globalKeyword = searchedText;
            var filters = {};
            var viewId = "all";
            if (this.search.options) {
              viewId = this.search.options;
            }

            // if (this.search.options == "my") {
            //   filters.record_owner = [
            //     "=",
            //     this.$store.state.users.CURRENT_USER.userId
            //   ];
            //   if(model=="task"){
            //       filters.task_status_custom = [
            //         {
            //           task_status_custom1: [
            //             "CUSTOM",
            //             "cfd_task_status.value != 'CLOSED'"
            //           ]
            //         }
            //       ];
            //   }
            // } else if (this.search.options == "all") {
            // } else {
            //  if(model=="task"){
            //       filters.task_status_custom = [
            //       {
            //         task_status_custom1: [
            //           "CUSTOM",
            //           "cfd_task_status.value != 'CLOSED'"
            //         ]
            //       }
            //    ];
            //  }
            // }

            this.loadingSearch = true;
            new KivaProxy({
              endpoint: app + "/" + model
            }).setViewId(viewId).setFilters(filters).setKeyword(searchedText).all().then(function (resp) {
              // this.results = resp.records;
              _this6.results = resp.records;
              _this6.loadingSearch = false;
              _this6.searchGlobal.show = true;
            }).catch(function (err) {
              _this6.loadingSearch = false;
            });
          }
        } else {
          if (searchedText.length > 2) {
            parameters = [["act", "task"], ["act", "event"], ["act", "project"], ["crm", "deal"], ["crm", "contact"], ["crm", "account"], ["dm", "document"], ["pages", "page"]];
            searchedText = searchedText;
            if (searchedText == " ") {
              searchedText = "";
            }
            this.globalKeyword = searchedText;
            this.loadingSearch = true;
            new KivaProxy({
              endpoint: "kvc/data/search",
              driver: "base"
            }).setKeyword(searchedText).setParameter("models", parameters).all().then(function (resp) {
              _this6.groupResults(resp.records);
              _this6.loadingSearch = false;
              _this6.searchGlobal.show = true;
            }).catch(function (err) {
              _this6.loadingSearch = false;
            });
          } else {
            this.loadingSearch = false;
          }
        }
      }
    },
    /*   
    	*/
    groupResults: function groupResults(res) {
      var _this7 = this;
      var fields = {};
      var groups = [];
      this.foundGroups = [];
      this.displayGroup = false;
      res.forEach(function (k) {
        var key = k.app + "_" + k.model;
        if (!fields[key]) {
          fields[key] = [];
        }
        fields[key].push(k);
        _this7.resultGroups = fields;
      });
      var keys = Object.keys(this.globalGroups);
      keys.forEach(function (k) {
        var nums = Math.random(99, 9999999);
        var key = _this7.globalGroups[k].app + "_" + _this7.globalGroups[k].model;
        if (fields[key]) {
          _this7.globalGroups[k].key = key;
          _this7.foundGroups.push(_this7.globalGroups[k]);
        }
      });
      setTimeout(function () {
        _this7.displayGroup = true;
      }, 300);
    }
  }
};