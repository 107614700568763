import Vue from 'vue';
Vue.component("Redactorx", {
  template: '<textarea ref="redactorx" :name="name" :placeholder="placeholder" :value="value" />',
  redactor: !1,
  props: {
    value: {
      default: "",
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    config: {
      default: {},
      type: Object
    }
  },
  watch: {
    value(e, t) {
      this.redactorx.editor.isFocus() || this.redactorx.app.$element.val(e);
    }
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.destroy();
  },
  methods: {
    init() {
      var e = this,
        t = {
          "editor.change": function (t) {
            var r = t.get("html");
            return e.handleInput(r), r;
          }
        };
      void 0 === this.config.subscribe ? Vue.set(this.config, "subscribe", t) : this.config.subscribe["editor.change"] = t["editor.change"];
      var r = RedactorX(this.$refs.redactorx, this.config);
      this.redactorx = r, this.$parent.redactorx = r;
    },
    destroy() {
      RedactorX(this.$refs.redactorx, "destroy"), this.redactorx = null, this.$parent.redactorx = null;
    },
    handleInput(e) {
      this.$emit("input", e);
    }
  }
});