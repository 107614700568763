import store from '@/store';
import checklist from './checklist';
var rulesupStore = {
  namespaced: true,
  modules: {
    checklist: checklist
  }
};
store.registerModule('rulesup', rulesupStore);

// store.dispatch('rulesup/checklist/getTemplateCategories')
// store.dispatch('rulesup/checklist/getTemplateStatuses')