import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.push.js";
import TimeCounter from "@/components/Items/TimeCounter";
import searchBar from "./header/searchBar";
import kvAvatar from "@/components/kiva/KvAvatar";
import plusButton from "./header/plusButton";
import profile from "@/components/Items/ProfileChange/index";
import path from "path";
import moment from "moment";
import notificationMixin from "@/mixins/notification.js";
import { mapState } from "vuex";
import { ACTIVITY_STATUS } from "@/store/modules/common/mutation-types";
import svgIcon from "@/components/kiva/kvSvgIcon.vue";
export default {
  mixins: [notificationMixin],
  components: {
    TimeCounter: TimeCounter,
    searchBar: searchBar,
    kvAvatar: kvAvatar,
    plusButton: plusButton,
    profile: profile
  },
  data: function data() {
    return {
      status: "stop",
      counter: {
        time: 0,
        storage: 0,
        isRunning: false,
        start: moment()
      },
      time_show: "00:00",
      visibleClose: false
    };
  },
  // mounted() {
  //   Object.assign(this.counter, this.getCounter());
  //   if (this.counter.isRunning) {
  //   } else {
  //     this.time_show = moment("1900-01-01 00:00:00")
  //       .add(this.counter.time, "seconds")
  //       .format("HH:mm:ss");
  //   }

  //   this.interval = setInterval(this.incrementTime, 1000);
  // },

  computed: _objectSpread(_objectSpread({
    toggleSidebar: function toggleSidebar() {
      return this.$store.state.app.secondSidebar;
    }
  }, mapState("common", {
    activityStatus: ACTIVITY_STATUS
  })), {}, {
    embeded: function embeded() {
      return this.$route.query.embeded;
    },
    plusButton: {
      get: function get() {
        return this.$store.state.app.plusButton;
      },
      set: function set(val) {
        this.$store.state.app.plusButton = val;
      }
    },
    MY_ACTIVITY_STATUS: {
      get: function get() {
        return this.$store.state.users.MY_ACTIVITY_STATUS;
      },
      set: function set(val) {
        this.$store.state.users.MY_ACTIVITY_STATUS = val;
      }
    },
    config: {
      get: function get() {
        return this.$store.state.notification.config;
      },
      set: function set(val) {
        this.$store.state.notification.config = val;
      }
    },
    scheduledDoNotDisturb: {
      get: function get() {
        return this.$store.state.notification.scheduledDoNotDisturb;
      },
      set: function set(val) {
        this.$store.state.notification.scheduledDoNotDisturb = val;
      }
    },
    activityDrawer: {
      get: function get() {
        return this.$store.state.app.activityDrawer;
      },
      set: function set(val) {
        this.$store.state.app.activityDrawer = val;
      }
    },
    templatePopup: {
      get: function get() {
        return this.$store.state.app.templatePopup;
      },
      set: function set(val) {
        this.$store.state.app.templatePopup = val;
      }
    },
    openPagePopup: {
      get: function get() {
        return this.$store.state.app.openPagePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPagePopup = val;
      }
    },
    openPageTemplatePopup: {
      get: function get() {
        return this.$store.state.app.openPageTemplatePopup;
      },
      set: function set(val) {
        this.$store.state.app.openPageTemplatePopup = val;
      }
    },
    unreadNotification: function unreadNotification() {
      return this.$store.state.notification.unreadNotification;
    },
    noUnreadSuccess: function noUnreadSuccess() {
      return this.$store.state.notification.noUnreadSuccess;
    },
    user: function user() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    saveStatus: function saveStatus() {
      return this.$store.state.saving;
    },
    drawer: {
      get: function get() {
        return this.$store.state.app.drawer;
      },
      set: function set(val) {
        this.$store.state.app.drawer = val;
      }
    }
  }),
  watch: {
    user: function user(val) {
      this.openUserGuide();
    },
    activityStatus: function activityStatus(val) {
      var me = this.$store.state.users.CURRENT_USER;
      var st = val.find(function (k) {
        return k.record_id == me.activity_status;
      });
      this.$store.state.users.MY_ACTIVITY_STATUS = st;
    },
    "user.accountId": function userAccountId() {
      //  if (process.env.NODE_ENV == 'development') {
      //       (function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){return function(){ug.q.push([n,arguments])};};var m=['previewGuide','finishPreview','track','identify','triggerNps','hideChecklist','launchChecklist'];for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer','241958196ID');
      //  }
    }
  },
  created: function created() {
    // console.log("process.env.NODE_ENV 1 ",process.env.NODE_ENV );
  },
  methods: {
    openUserGuide: function openUserGuide() {
      // console.log("env " );
      console.log("process.env.NODE_ENV ", process.env.NODE_ENV);
      if (process.env.NODE_ENV == "development") {
        var me = this.$store.state.users.CURRENT_USER;
        console.log("me", me);
        if (me && me.accountId == 3946) {
          (function (g, u, i, d, e, s) {
            g[e] = g[e] || [];
            var f = u.getElementsByTagName(i)[0];
            var k = u.createElement(i);
            k.async = true;
            k.src = "https://static.userguiding.com/media/user-guiding-" + s + "-embedded.js";
            f.parentNode.insertBefore(k, f);
            if (g[d]) return;
            var ug = g[d] = {
              q: []
            };
            ug.c = function (n) {
              return function () {
                ug.q.push([n, arguments]);
              };
            };
            var m = ["previewGuide", "finishPreview", "track", "identify", "triggerNps", "hideChecklist", "launchChecklist"];
            for (var j = 0; j < m.length; j += 1) {
              ug[m[j]] = ug.c(m[j]);
            }
          })(window, document, "script", "userGuiding", "userGuidingLayer", "241958196ID");
        } else {
          (function (w, d, s) {
            var a = d.getElementsByTagName("head")[0];
            var r = d.createElement("script");
            r.async = 1;
            r.src = s;
            r.setAttribute("id", "usetifulScript");
            r.dataset.token = "db5b6482981594047778ffa025876053";
            a.appendChild(r);
          })(window, document, "https://www.usetiful.com/dist/usetiful.js");
        }
        window.userGuidingLayer.push({
          event: 'onPreviewEnd',
          fn: function fn(data) {
            return console.log(data);
          }
        });
      }
    },
    changeActivityStatus: function changeActivityStatus(ast) {
      this.$store.dispatch("common/changeActivityStatus", _objectSpread(_objectSpread({}, ast), {}, {
        me: this.user
      }));
    },
    startPlusButton: function startPlusButton(val) {
      this.plusButton.show = val;
    },
    handleCommand: function handleCommand(command) {
      this.$router.push({
        path: command
      });
    },
    startDrawer: function startDrawer() {
      var tm = Math.ceil(this.counter.time / 60);
      var h = Math.floor(tm / 60);
      var m = tm % 60;
      this.$store.state.tasks.timeCounter = {
        time: this.counter.time,
        task: this.counter.task,
        thread: this.counter.thread,
        minutes: m,
        hours: h,
        status: this.counter.status
      };
      this.drawer = true;
    }
  }
};