import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "kv-avatar-container",
    class: _vm.className
  }, [_vm.notEmpty() && _vm.avatarUrl ? _c("figure", {
    staticClass: "kv-avatar",
    class: _vm.classes,
    style: _vm.getBorderStyle()
  }, [_c("img", {
    attrs: {
      src: _vm.avatarUrl,
      alt: ""
    }
  })]) : _vm.notEmpty() && _vm.userName ? _c("el-avatar", {
    style: _vm.getBorderStyle(),
    attrs: {
      size: _vm.getSize,
      fit: _vm.fit
    }
  }, [_vm._v("\n    " + _vm._s(_vm.userName) + "\n  ")]) : _vm.notEmpty() && _vm.name ? _c("el-avatar", {
    style: _vm.getBorderStyle(),
    attrs: {
      size: _vm.getSize,
      fit: _vm.fit
    }
  }, [_vm._v("\n    " + _vm._s(_vm._f("nameShorten")(_vm.name)) + "\n  ")]) : _c("el-avatar", {
    style: _vm.getBorderStyle(),
    attrs: {
      size: _vm.getSize,
      fit: _vm.fit
    }
  }, [_vm._t("default")], 2), _vm._v(" "), _vm.MY_ACTIVITY_STATUS ? _c("div", {
    key: "status_act_" + _vm.MY_ACTIVITY_STATUS.record_id,
    staticClass: "last-status",
    style: {
      backgroundColor: "#" + _vm.MY_ACTIVITY_STATUS.color,
      color: "#" + _vm.MY_ACTIVITY_STATUS.icon_color
    },
    attrs: {
      title: _vm.MY_ACTIVITY_STATUS.status_name
    }
  }, [_c("svg-icon", {
    key: _vm.MY_ACTIVITY_STATUS.icon,
    attrs: {
      icon: _vm.MY_ACTIVITY_STATUS.icon
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };