import "core-js/modules/es.object.to-string.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { CATEGORIES, FILES, LABELS } from './mutation-types';
import { DocumentCategoryTransformer } from '@/transformers/DocumentCategoryTransformer';
import { DocumentFileTransformer } from '@/transformers/DocumentFileTransformer';
import { DocumentLabelTransformer } from '@/transformers/DocumentLabelTransformer';
export default {
  getCategories: function getCategories(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'dm/category'
      }).setFields(['parent_category', 'category_name', 'record_owner', 'created_on', 'created_by', 'last_modified_on', 'last_modified_by']).all().then(function (response) {
        commit(CATEGORIES, DocumentCategoryTransformer.fetchCollection(response.records));
        resolve(response.records);
      }).catch(reject);
    });
  },
  getFiles: function getFiles(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'dm/document'
      }).setFields(['category', 'document_name', 'document_file', 'storage', 'account_name', 'project', 'deal', 'contract', 'record_owner', 'created_on', 'created_by', 'last_modified_on', 'last_modified_by']).all().then(function (response) {
        commit(FILES, DocumentFileTransformer.fetchCollection(response.records));
        resolve(response.records);
      }).catch(reject);
    });
  },
  getLabels: function getLabels(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: 'dm/label'
      }).setFields(['label_name', 'record_owner', 'created_on', 'created_by', 'last_modified_on', 'last_modified_by']).all().then(function (response) {
        commit(LABELS, DocumentLabelTransformer.fetchCollection(response.records));
        resolve(response.records);
      }).catch(reject);
    });
  }
};