import "core-js/modules/es.object.to-string.js";
import KivaProxy from '@/utils/kivaProxy/src';
export default {
  getConfig: function getConfig(_ref) {
    var commit = _ref.commit,
      dispatch = _ref.dispatch,
      state = _ref.state;
    // let me = this.$store.state.users.CURRENT_USER.uId
    new KivaProxy({
      endpoint: '/kvc/user/me/notification/config',
      driver: 'base'
    }).submit('get', 'kvc/user/me/notification/config').then(function (response) {
      if (!response.pausedBetweenTime) {
        response.pausedBetweenTime = [];
      }
      commit('setConfig', response);
    });
  },
  setConfig: function setConfig(_ref2, payload) {
    var commit = _ref2.commit;
    // let startTime = payload.pausedBetweenTime ? payload.pausedBetweenTime[0] : ''
    // let endTime =   payload.pausedBetweenTime ? payload.pausedBetweenTime[1] : ''
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: '/kvc/user/me/notification/config',
        driver: 'base'
      }).submit('post', 'kvc/user/me/notification/config', {
        paused: payload.paused == false ? 0 : 1,
        // paused: payload.paused,
        pausedAtDays: payload.pausedAtDays,
        bulkNotifyPeriod: payload.bulkNotifyPeriod,
        pausedBetweenTime: payload.pausedBetweenTime
      }).then(function (response) {
        commit('setConfig', response);
        Object.assign(payload, response);
        resolve(response);
      }).catch(reject);
    });
  },
  findUnreadNotification: function findUnreadNotification(_ref3) {
    var commit = _ref3.commit,
      dispatch = _ref3.dispatch,
      state = _ref3.state,
      rootState = _ref3.rootState;
    return new Promise(function (resolve, reject) {
      new KivaProxy({
        endpoint: '/kvc/data/notification/check',
        driver: 'base'
      }).submit('get', 'kvc/data/notification/check').then(function (response) {
        // console.log("response",response)
        commit('setUnreadNotification', response.count);
        rootState.auth.connectionLost = false;
        var duration = state.timeToRefresh < 5000 ? 5000 : state.timeToRefresh;
        // if (process.env.NODE_ENV !== 'development') {
        if (state.alwaysUpToDate) {
          setTimeout(function () {
            dispatch('findUnreadNotification');
          }, duration);
        }
        // }
        resolve(response.records);
      }).catch(function (error) {
        setTimeout(function () {
          if (error && error.error === 'SESSION_EXPIRED') {
            rootState.auth.forceLogin = true;
            reject;
          } else {
            // var ifConnected = window.navigator.onLine;
            // if(!ifConnected){
            rootState.auth.connectionLost = true;
            // }
          }
        }, 2000);
      });
    });
  },
  setAllRead: function setAllRead(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    new KivaProxy({
      endpoint: 'kvc/data/notification/seen',
      driver: 'base'
    }).submit('patch', 'kvc/data/notification/seen', {
      all: 1
    }).then(function (response) {
      state.unreadNotification = 0;
      state.noUnreadSuccess = true;
      setTimeout(function () {
        state.noUnreadSuccess = false;
      }, 10000);
    });
  }
};