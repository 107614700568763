import _toConsumableArray from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.filter.js";
import { mapGetters } from 'vuex';
import Logo from './Logo';
import SidebarItem from './SidebarItem';
import _variables from '@/styles/variables.scss';
import { SCHEMA, MEGAMENU, pathAuthority } from '@/router/routerSchema';
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      menuKey: 'MenuKey'
    };
  },
  watch: {
    level: function level(val) {
      this.menuKey = val;
    }
  },
  methods: {
    findMenuAndPush: function findMenuAndPush(k, menu) {
      var _this = this;
      var routes = this.$router.options.routes;
      var main = k.main;
      var children = k.children;
      if (k.main && k.children && k.children_level2) {
        var m1 = routes.find(function (r) {
          return r.name == k.main;
        });
        var m2 = m1.children.find(function (r) {
          return r.name == k.children;
        });
        var m = m2.children.find(function (c) {
          return c.name == k.children_level2;
        });
        if (typeof m != 'undefined' && typeof m1 != 'undefined' && typeof m2 != 'undefined') {
          m.pathFull = m1.path + '/' + m2.path + '/' + m.path;
          m.primaryClass = k.primaryClass ? k.primaryClass : '';
          m.show = true;
          if (k.meta) {
            m.meta = k.meta;
          }
          menu.push(m);
        }
      } else if (k.main && k.children) {
        var _m = routes.find(function (r) {
          return r.name == k.main;
        });
        var _m2 = _m.children.find(function (c) {
          return c.name == k.children;
        });
        if (typeof _m2 != 'undefined' && typeof _m != 'undefined') {
          _m2.pathFull = _m.path ? _m.path + '/' + _m2.path : '' + _m.path;
          _m2.primaryClass = k.primaryClass ? k.primaryClass : '';
          _m2.show = true;
          if (k.meta) {
            _m2.meta = k.meta;
          }
          menu.push(_m2);
        }
      } else if (k.main && !k.children) {
        var _m3 = routes.find(function (r) {
          return r.name == k.main;
        });
        if (typeof _m3 != 'undefined') {
          _m3.pathFull = '' + _m3.path;
          _m3.primaryClass = k.primaryClass ? k.primaryClass : '';
          _m3.show = true;
          if (k.meta) {
            _m3.meta = k.meta;
          }
          if (k.main && k.main == "invite") {
            _m3.clickF = function () {
              _this.$store.state.app.invitePeopleModal = true;
            };
          }
          menu.push(_m3);
        }
      } else if (k.menu && k.menu == "MEGAMENU") {
        menu.push(_objectSpread(_objectSpread({}, k), {}, {
          MEGAMENU: this.routerMegaMenu
        }));
      } else if (k.menu) {
        menu.push(_objectSpread({}, k));
      }
    }
  },
  computed: _objectSpread(_objectSpread({
    _SCHEMA: function _SCHEMA() {
      return {
        SCHEMA: SCHEMA,
        MEGAMENU: MEGAMENU,
        pathAuthority: pathAuthority
      };
    }
  }, mapGetters(['sidebar'])), {}, {
    profile: function profile() {
      return this.$store.state.users.MY_UX_PROFILE;
    },
    embeded: function embeded() {
      return this.$route.query.embeded;
    },
    level: {
      get: function get() {
        if (this.$store.state.users.CURRENT_USER) {
          return this.$store.state.users.CURRENT_USER.uxProfiles;
        }
      },
      set: function set(val) {
        this.$store.state.users.CURRENT_USER.uxProfiles = val;
      }
    },
    routerMegaMenu: function routerMegaMenu() {
      var _this2 = this;
      var profile = this.$store.state.users.MY_UX_PROFILE;
      if (profile && profile.record_id) {
        var licenses = _toConsumableArray(this.$store.state.users.CURRENT_USER.licenses);
        // licenses.push({packageAppId:"desk"})
        // licenses.push({packageAppId:"invoice"})

        licenses.forEach(function (k) {
          //  k.packageAppId = k.packageAppId=="kivaprojects" ? "projects" : k.packageAppId=="kivasales" ? "sales" : k.packageAppId=="kivadesk" ? "desk" : k.packageAppId=="kivainvoice" ? "invoice" : k.packageAppId;
          var selectedSchema = _this2._SCHEMA.MEGAMENU[k.packageAppId];
          if (selectedSchema.includes) {
            selectedSchema.includes.forEach(function (l) {
              licenses.push(l);
            });
          }
        });
        var megaMenu = [];

        // console.log("licenses",licenses)
        var licensesSales = licenses.find(function (k) {
          return k.packageAppId == "sales" || k.packageAppId == "kivasuite";
        });
        if (!licensesSales) {
          licenses.push({
            packageAppId: 'account'
          });
        }
        licenses.forEach(function (k) {
          var selectedSchema = _this2._SCHEMA.MEGAMENU[k.packageAppId];
          if (selectedSchema.includes) {
            selectedSchema.includes.forEach(function (l) {
              licenses.push(l);
            });
          }
          if (selectedSchema && selectedSchema.label) {
            var menu = {
              id: k.packageAppId,
              label: selectedSchema.label,
              icon: selectedSchema.icon,
              subList: []
            };
            if (selectedSchema.subList && selectedSchema.subList[0]) {
              selectedSchema.subList.forEach(function (s) {
                _this2.findMenuAndPush(s, menu.subList);
              });
              megaMenu.push(menu);
            }
          }
        });
        var uxProfile = this.$store.state.users.CURRENT_USER.uxProfiles;
        if (uxProfile == 1 || uxProfile == 18 || uxProfile == 19 || uxProfile == 20 || uxProfile == 21) {
          var selectedSchema = this._SCHEMA.MEGAMENU["settings"];
          if (selectedSchema.subList && selectedSchema.subList[0]) {
            var menu = {
              id: "settings",
              label: selectedSchema.label,
              icon: selectedSchema.icon,
              subList: []
            };
            selectedSchema.subList.forEach(function (s) {
              _this2.findMenuAndPush(s, menu.subList);
            });
            megaMenu.push(menu);
          }
        }
        return megaMenu;
      }
    },
    routerSchema: function routerSchema() {
      var _this3 = this;
      var profile = this.$store.state.users.MY_UX_PROFILE;
      // console.log("profile",profile);

      if (profile && profile.record_id) {
        profile.product = profile.product ? profile.product : profile.record_id == 1 ? 'ADMIN' : 'GUEST';

        // console.log("profile",profile);
        var selectedSchema = this._SCHEMA.SCHEMA[profile.product] ? this._SCHEMA.SCHEMA[profile.product][profile.profile_level] : this._SCHEMA.SCHEMA['default']['default'];
        // console.log("selectedSchema",selectedSchema);

        var licenses = this.$store.state.users.CURRENT_USER.licenses;
        // console.log("licenses",licenses);

        var menu = [];
        var l = licenses.map(function (a) {
          return a.packageAppId;
        });

        // console.log("selectedSchema",selectedSchema, l)
        // console.log("l", l)
        // console.log("this._SCHEMA", this._SCHEMA)
        // console.log("this._SCHEMA.pathAuthorityl", this._SCHEMA.pathAuthority)
        //  console.log("pathAuthority 1",pathAuthority)
        //  console.log("selectedSchema 1",selectedSchema)

        selectedSchema = selectedSchema.filter(function (k) {
          var pathAuthority = _this3._SCHEMA.pathAuthority.find(function (a) {
            return a.main == k.main && a.children == k.children;
          });
          return k.menu == "MEGAMENU" || pathAuthority && pathAuthority.products && (pathAuthority.products[0] == "all" || l[0] == "kivasuite" || l.includes(pathAuthority.products[0]) || l.includes(pathAuthority.products[1]) || l.includes(pathAuthority.products[2]));
        });

        //  console.log("selectedSchema",selectedSchema)
        selectedSchema.forEach(function (k) {
          _this3.findMenuAndPush(k, menu);
        });
        // console.log("menu",menu)
        return menu;
      }
    },
    routes: function routes() {
      return this.$router.options.routes;
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      // console.log("meta",meta ,path)
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  created: function created() {
    this.$store.dispatch('app/closeSideBar', false);
  }
};