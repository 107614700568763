var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.account && _vm.account.accountName ? _c("section", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    key: _vm.account_key,
    staticClass: "account-client-detail"
  }, [_c("el-row", {
    class: _vm.toggleSidebar.opened ? "openedSidebar" : "closedSidebar",
    attrs: {
      type: "flex",
      justify: "stretch"
    }
  }, [_c("el-col", {
    staticStyle: {
      width: "260px"
    },
    attrs: {
      width: "260px"
    }
  }, [_c("div", {
    staticClass: "box1 w-250 pa-0 bgWhite kv-card-default kv-scroll kv-radius-default kv-border-default"
  }, [_c("div", {
    staticClass: "leftHeader"
  }, [_c("div", {
    staticClass: "icon"
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "logo-uploader",
    attrs: {
      "auto-upload": false,
      action: "",
      "on-change": _vm.saveFormData,
      "show-file-list": false,
      "on-success": _vm.handleAvatarSuccess
    }
  }, [!_vm.account.logo ? _c("div", {
    staticClass: "card-icon"
  }, [_c("div", {
    staticClass: "fal fa-building"
  })]) : _vm._e(), _vm._v(" "), _vm.account.logo ? _c("div", {
    key: _vm.cardPhotoKey,
    staticClass: "card-photo"
  }, [_c("img", {
    attrs: {
      src: _vm.getAccountImagePath({
        id: _vm.account.logo,
        recordId: _vm.account.recordId
      }, true)
    }
  })]) : _vm._e()])], 1), _vm._v(" "), _c("div", {
    staticClass: "title"
  }, [!_vm.editTitle ? _c("h3", {
    on: {
      click: function click($event) {
        _vm.editTitle = true;
      }
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.account.accountName))]), _vm._v(" "), _c("i", {
    staticClass: "fa fa-pen editbutton"
  })]) : _c("el-input", {
    ref: "editTitle",
    staticClass: "inline-input",
    attrs: {
      size: "mini"
    },
    on: {
      blur: function blur($event) {
        _vm.editTitle = false;
        _vm.saveDirect = true;
      }
    },
    nativeOn: {
      keyup: [function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.editTitle = false;
        _vm.saveDirect = true;
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.editTitle = false;
      }]
    },
    model: {
      value: _vm.account.accountName,
      callback: function callback($$v) {
        _vm.$set(_vm.account, "accountName", $$v);
      },
      expression: "account.accountName"
    }
  }), _vm._v(" "), _c("div", [_c("el-dropdown", {
    key: "account" + _vm.account.accountType.value
  }, [_c("span", {
    staticClass: "el-dropdown-link",
    style: {
      color: _vm.account.accountType && _vm.account.accountType.color ? "#" + _vm.account.accountType.color : ""
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.account.accountType ? _vm.account.accountType.label : "") + "\n                  "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.options["crm_account_account_type"], function (status, i) {
    return _c("el-dropdown-item", {
      key: "k" + i
    }, [_c("a", {
      staticClass: "width-full pull-left",
      on: {
        click: function click($event) {
          _vm.account.accountType = status;
          _vm.saveDirect = true;
        }
      }
    }, [_vm._v(_vm._s(status.label))])]);
  }), 1)], 1)], 1)], 1)]), _vm._v(" "), _c("account-form", {
    key: "account" + _vm.account.record_id,
    attrs: {
      account: _vm.account,
      forceSaveDirect: _vm.saveDirect
    },
    on: {
      updateAccount: function updateAccount($event) {
        _vm.account = $event;
      },
      accountTypes: function accountTypes($event) {
        _vm.accountTypes = $event;
      },
      updatesaveDirect: function updatesaveDirect($event) {
        _vm.saveDirect = false;
      }
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      flex: "1"
    }
  }, [_c("div", {
    staticClass: "box1 pa-0"
  }, [_c("el-row", {
    staticClass: "kv-card-default bgWhite",
    staticStyle: {
      margin: "1px 5px 0px 5px"
    }
  }, [_c("el-col", {
    staticClass: "boxProgressContainer",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "boxProgress kv-scroll pa-10"
  }, [_c("el-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      width: "50px"
    }
  }, [_c("div", {
    staticClass: "iconBox color1"
  }, [_c("i", {
    staticClass: "fa fa-2x fa-check-square"
  })])]), _vm._v(" "), _c("el-col", [_c("div", {
    staticClass: "titleFont"
  }, [_vm._v(_vm._s(_vm.$t("Open Tasks")))]), _vm._v(" "), _c("div", {
    staticClass: "numberFont"
  }, [_vm._v(_vm._s(_vm.query.open_tasks))])])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "boxProgressContainer",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "boxProgress kv-scroll pa-10"
  }, [_c("el-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      width: "50px"
    }
  }, [_c("div", {
    staticClass: "iconBox color2"
  }, [_c("i", {
    staticClass: "fa fa-2x fa-tasks-alt"
  })])]), _vm._v(" "), _c("el-col", [_c("div", {
    staticClass: "titleFont"
  }, [_vm._v(_vm._s(_vm.$t("Active Projects")))]), _vm._v(" "), _c("div", {
    staticClass: "numberFont"
  }, [_vm._v(_vm._s(_vm.query.active_projects))])])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "boxProgressContainer",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "boxProgress kv-scroll pa-10"
  }, [_c("el-row", {
    attrs: {
      type: "flex"
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      width: "50px"
    }
  }, [_c("div", {
    staticClass: "iconBox color3"
  }, [_c("i", {
    staticClass: "fa fa-2x fa-funnel-dollar"
  })])]), _vm._v(" "), _c("el-col", [_c("div", {
    staticClass: "titleFont"
  }, [_vm._v(_vm._s(_vm.$t("Open Deals")))]), _vm._v(" "), _c("div", {
    staticClass: "numberFont"
  }, [_vm._v("\n                    " + _vm._s(_vm._f("currency")(_vm.query.open_deals_expected_revenue)) + "\n                  ")])])], 1)], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "tab-card"
  }, [_c("el-tabs", {
    staticClass: "kv-card-default bgWhite",
    attrs: {
      type: "card"
    },
    model: {
      value: _vm.activeTabsValue,
      callback: function callback($$v) {
        _vm.activeTabsValue = $$v;
      },
      expression: "activeTabsValue"
    }
  }, [_c("el-tab-pane", [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _vm._v(" " + _vm._s(_vm.$t("Log Activity")) + "\n              ")]), _vm._v(" "), _c("div", {
    staticClass: "kv-scroll"
  }, [_c("meetingsAccountForm", {
    key: _vm.selectedEventKey,
    attrs: {
      account: _vm.account,
      focus: _vm.focusSubject,
      event: _vm.selectedEvent
    },
    on: {
      updateFocus: function updateFocus($event) {
        _vm.focusSubject = $event;
      },
      saveEvent: function saveEvent($event) {
        return _vm.saveEvent($event);
      },
      setEvent: function setEvent($event) {
        _vm.selectedEvent = $event;
        _vm.selectedEventKey = Math.random(1, 99999);
      }
    }
  }), _vm._v(" "), _c("activities", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.focusSubject,
      expression: "!focusSubject"
    }],
    staticClass: "pull-left margin-top-10",
    attrs: {
      focusSubject: _vm.focusSubject,
      account: _vm.account
    },
    on: {
      setEdit: function setEdit($event) {
        _vm.selectedEvent = $event;
        _vm.focusSubject = true;
        _vm.selectedEventKey = Math.random(1, 99999);
      }
    }
  })], 1)]), _vm._v(" "), _c("el-tab-pane", {
    staticClass: "kv-note-list-pane"
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _vm._v(" " + _vm._s(_vm.$t("Create Note")) + "\n              ")]), _vm._v(" "), _c("div", {
    staticClass: "kv-scroll"
  }, [_c("kv-note-list", {
    attrs: {
      endpoint: "crm/account",
      id: _vm.accountId,
      showAvatar: true,
      formFixed: false,
      formRadiusClass: "kv-radius-default",
      formPosition: "bottom",
      position: "bottom",
      formStyle: {
        bottom: "0px"
      },
      maxHeight: "calc(100vh - 250px)"
    }
  })], 1)]), _vm._v(" "), _c("el-tab-pane", [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-date"
  }), _vm._v(" " + _vm._s(_vm.$t("E-mail")) + "\n              ")]), _vm._v(" "), _c("div", {
    staticClass: "v-scroll"
  }, [_c("email", {
    attrs: {
      paddingClass: "no-padding",
      readyData: _vm.emailSender
    },
    on: {
      setReadyData: function setReadyData($event) {
        _vm.emailSender = {};
      }
    }
  })], 1)])], 1)], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      width: "370px"
    },
    attrs: {
      width: "370px"
    }
  }, [_c("div", {
    staticClass: "box1 w-370 bgWhite kv-card-default kv-scroll kv-radius-default kv-border-default"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideIfNotFull || _vm.data.contact.type == "full",
      expression: "!hideIfNotFull || data.contact.type == 'full'"
    }],
    staticClass: "accordion"
  }, [_c("div", {
    staticClass: "acc-item"
  }, [_c("div", {
    staticClass: "acc-title",
    class: _vm.open["contact"] ? "selected-title" : ""
  }, [_c("div", {
    staticClass: "text",
    on: {
      click: function click($event) {
        return _vm.toggleSection("contact");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-contact"
    }
  }), _vm._v("\n                " + _vm._s(_vm.$t("Contacts")) + " (" + _vm._s(_vm.data.contact.total) + ")\n              ")], 1), _vm._v(" "), _c("div", {
    staticClass: "buttons"
  }, [_vm.data.contact.total == 0 || _vm.data.contact.type == "full" ? _c("a", {
    staticClass: "kv-icon-plus_o",
    on: {
      click: function click($event) {
        return _vm.selectContact(null);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-plus_o"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["contact"],
      expression: "open['contact']"
    }],
    on: {
      click: function click($event) {
        return _vm.openDetail("contact");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-search_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["contact"],
      expression: "open['contact']"
    }],
    on: {
      click: function click($event) {
        return _vm.goPath("contact");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-link_but"
    }
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "action-icon",
    class: _vm.open["contact"] ? "show" : "rotate",
    on: {
      click: function click($event) {
        return _vm.toggleSection("contact");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-arrow"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "acc-content",
    class: _vm.open["contact"] ? _vm.data.contact.type == "full" ? "v-visible" : "v-visible-" + _vm.data.contact.total : "v-hidden"
  }, [_c("contacts", {
    key: "contact" + _vm.account.recordId,
    attrs: {
      account: _vm.account,
      view: _vm.data.contact.type
    },
    on: {
      setTotal: function setTotal($event) {
        _vm.data.contact.total = $event;
      },
      setView: function setView($event) {
        _vm.hideIfNotFull = $event == "light" ? false : true;
        _vm.data.contact.type = $event;
      },
      setEvent: function setEvent($event) {
        return _vm.setEvent($event);
      }
    }
  }), _vm._v(" "), _vm.data.contact.total == 0 ? [_vm._v("\n                " + _vm._s(_vm.$t("There are no people in this organization")))] : _vm._e()], 2)])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideIfNotFull || _vm.data.deals.type == "full",
      expression: "!hideIfNotFull || data.deals.type == 'full'"
    }],
    staticClass: "accordion"
  }, [_c("div", {
    staticClass: "acc-item"
  }, [_c("div", {
    staticClass: "acc-title",
    class: _vm.open["deals"] ? "selected-title" : ""
  }, [_c("div", {
    staticClass: "text",
    on: {
      click: function click($event) {
        return _vm.toggleSection("deals");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-deals"
    }
  }), _vm._v("\n                " + _vm._s(_vm.$t("Deals")) + " (" + _vm._s(_vm.data.deals.total) + ")\n              ")], 1), _vm._v(" "), _c("div", {
    staticClass: "buttons"
  }, [_vm.data.deals.total == 0 || _vm.data.deals.type == "full" ? _c("a", {
    staticClass: "kv-icon-plus_o",
    on: {
      click: function click($event) {
        return _vm.selectDeal(null);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-plus_o"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["deals"],
      expression: "open['deals']"
    }],
    on: {
      click: function click($event) {
        return _vm.openDetail("deals");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-search_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["deals"],
      expression: "open['deals']"
    }],
    on: {
      click: function click($event) {
        return _vm.goPath("deals");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-link_but"
    }
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "action-icon",
    class: _vm.open["deals"] ? "show" : "rotate",
    on: {
      click: function click($event) {
        return _vm.toggleSection("deals");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-arrow"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "acc-content",
    class: _vm.open["deals"] ? _vm.data.deals.type == "full" ? "v-visible" : "v-visible-" + _vm.data.deals.total : "v-hidden"
  }, [_vm.account ? _c("deals", {
    key: "deeals" + _vm.account.recordId,
    attrs: {
      account: _vm.account,
      view: _vm.data.deals.type
    },
    on: {
      setTotal: function setTotal($event) {
        _vm.data.deals.total = $event;
      },
      setView: function setView($event) {
        _vm.hideIfNotFull = $event == "light" ? false : true;
        _vm.data.deals.type = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.deals.total == 0 ? [_vm._v("\n                " + _vm._s(_vm.$t("This organization has no related deals.")))] : _vm._e()], 2)])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideIfNotFull || _vm.data.project.type == "full",
      expression: "!hideIfNotFull || data.project.type == 'full'"
    }],
    staticClass: "accordion"
  }, [_c("div", {
    staticClass: "acc-item"
  }, [_c("div", {
    staticClass: "acc-title",
    class: _vm.open["project"] ? "selected-title" : ""
  }, [_c("div", {
    staticClass: "text",
    on: {
      click: function click($event) {
        return _vm.toggleSection("project");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-projects"
    }
  }), _vm._v("\n                " + _vm._s(_vm.$t("Projects")) + " (" + _vm._s(_vm.data.project.total) + ")\n              ")], 1), _vm._v(" "), _c("div", {
    staticClass: "buttons"
  }, [_vm.data.project.total == 0 || _vm.data.project.type == "full" ? _c("a", {
    on: {
      click: function click($event) {
        return _vm.selectProject(null);
      }
    }
  }, [_c("svg-icon", {
    staticClass: "kv-icon-plus_o",
    attrs: {
      icon: "kv-icon-plus_o"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["project"],
      expression: "open['project']"
    }],
    on: {
      click: function click($event) {
        return _vm.openDetail("project");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-search_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["project"],
      expression: "open['project']"
    }],
    on: {
      click: function click($event) {
        return _vm.goPath("project");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-link_but"
    }
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "action-icon",
    class: _vm.open["project"] ? "show" : "rotate",
    on: {
      click: function click($event) {
        return _vm.toggleSection("project");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-arrow"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "acc-content",
    class: _vm.open["project"] ? _vm.data.project.type == "full" ? "v-visible" : "v-visible-" + _vm.data.project.total : "v-hidden"
  }, [_vm.account ? _c("project", {
    key: "projects" + _vm.account.recordId,
    attrs: {
      account: _vm.account,
      view: _vm.data.project.type
    },
    on: {
      setTotal: function setTotal($event) {
        _vm.data.project.total = $event;
      },
      setView: function setView($event) {
        _vm.hideIfNotFull = $event == "light" ? false : true;
        _vm.data.project.type = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.project.total == 0 ? [_vm._v("\n                " + _vm._s(_vm.$t("This organization has no related projects.")))] : _vm._e()], 2)])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideIfNotFull || _vm.data.task.type == "full",
      expression: "!hideIfNotFull || data.task.type == 'full'"
    }],
    staticClass: "accordion"
  }, [_c("div", {
    staticClass: "acc-item"
  }, [_c("div", {
    staticClass: "acc-title",
    class: _vm.open["task"] ? "selected-title" : ""
  }, [_c("div", {
    staticClass: "text",
    on: {
      click: function click($event) {
        return _vm.toggleSection("task");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-task"
    }
  }), _vm._v("\n                " + _vm._s(_vm.$t("Tasks")) + " (" + _vm._s(_vm.data.task.total) + ")\n              ")], 1), _vm._v(" "), _c("div", {
    staticClass: "buttons"
  }, [_c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.data.task.total == 0 || _vm.data.task.type == "full",
      expression: "data.task.total == 0 || data.task.type == 'full'"
    }],
    on: {
      click: function click($event) {
        return _vm.selectTask(null);
      }
    }
  }, [_c("svg-icon", {
    staticClass: "kv-icon-plus_o",
    attrs: {
      icon: "kv-icon-plus_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["task"],
      expression: "open['task']"
    }],
    on: {
      click: function click($event) {
        return _vm.openDetail("task");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-search_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["task"],
      expression: "open['task']"
    }],
    on: {
      click: function click($event) {
        return _vm.goPath("task");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-link_but"
    }
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "action-icon",
    class: _vm.open["task"] ? "show" : "rotate",
    on: {
      click: function click($event) {
        return _vm.toggleSection("task");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-arrow"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "acc-content",
    class: _vm.open["task"] ? _vm.data.task.type == "full" ? "v-visible" : "v-visible-" + _vm.data.task.total : "v-hidden"
  }, [_vm.account ? _c("tasks", {
    key: "tasks" + _vm.account.recordId,
    attrs: {
      account: _vm.account,
      view: _vm.data.task.type
    },
    on: {
      setTotal: function setTotal($event) {
        _vm.data.task.total = $event;
      },
      setView: function setView($event) {
        _vm.hideIfNotFull = $event == "light" ? false : true;
        _vm.data.task.type = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.task.total == 0 ? [_vm._v(_vm._s(_vm.$t("This organization has no related tasks.")))] : _vm._e()], 2)])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.hideIfNotFull || _vm.data.files.type == "full",
      expression: "!hideIfNotFull || data.files.type == 'full'"
    }],
    staticClass: "accordion"
  }, [_c("div", {
    staticClass: "acc-item"
  }, [_c("div", {
    staticClass: "acc-title",
    class: _vm.open["files"] ? "selected-title" : ""
  }, [_c("div", {
    staticClass: "text",
    on: {
      click: function click($event) {
        return _vm.toggleSection("files");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-files"
    }
  }), _vm._v("\n                " + _vm._s(_vm.$t("Files")) + " (" + _vm._s(_vm.data.files.total) + ")\n              ")], 1), _vm._v(" "), _c("div", {
    staticClass: "buttons"
  }, [_c("a", {
    staticClass: "kv-icon-plus_o",
    on: {
      click: function click($event) {
        return _vm.selectFile(null);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-plus_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.open["files"],
      expression: "open['files']"
    }],
    on: {
      click: function click($event) {
        return _vm.openDetail("files");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-search_o"
    }
  })], 1), _vm._v(" "), _c("a", {
    staticClass: "action-icon",
    class: _vm.open["files"] ? "show" : "rotate",
    on: {
      click: function click($event) {
        return _vm.toggleSection("files");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      icon: "kv-icon-arrow"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "acc-content",
    class: _vm.open["files"] ? _vm.data.files.type == "full" ? "v-visible" : "v-visible-" + _vm.data.files.total : "v-hidden"
  }, [_c("transition", {
    attrs: {
      name: "breadcrumb",
      mode: "in-out"
    }
  }, [_vm.accountId ? _c("attachments", {
    key: "attachments" + _vm.account.recordId,
    attrs: {
      record_id: _vm.accountId,
      scrollDir: "y",
      app: "crm",
      model: "account",
      visibility: _vm.data.files.showUploadField,
      attachmentsList: _vm.attachmentsList,
      view: _vm.data.files.type,
      listStyle: "table"
    },
    on: {
      setTotal: function setTotal($event) {
        _vm.data.files.total = $event;
      },
      setView: function setView($event) {
        _vm.data.files.type = $event;
        _vm.hideIfNotFull = $event == "light" ? false : true;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.data.files.total == 0 ? [_vm._v(_vm._s(_vm.$t("This organization has no related files.")))] : _vm._e()], 2)], 1)])])])])], 1)], 1) : _vm._e();
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };