var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("kv-modal", {
    staticClass: "canned-response-modal",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v("\n    " + _vm._s(_vm.$t("Canned Responses")) + "\n  ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.mutableValue,
      callback: function callback($$v) {
        _vm.mutableValue = $$v;
      },
      expression: "mutableValue"
    }
  }, [_vm._v(" "), _c("main", [_c("el-table", {
    attrs: {
      data: _vm.cannedResponses
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      width: "85"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c("div", {
          on: {
            click: function click($event) {
              return _vm.handleSelect(row);
            }
          }
        }, [_c("i", {
          staticClass: "fal fa-compress-alt"
        })])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "ID",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [_vm._v("\n          " + _vm._s(row.id) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("Subject")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [_vm._v("\n          " + _vm._s(row.subject) + "\n        ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: _vm.$t("Owner")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_vm._v("\n          " + _vm._s(row.recordOwner.label) + "\n        ")];
      }
    }])
  })], 1), _vm._v(" "), _c("footer", [_c("el-pagination", {
    attrs: {
      "page-sizes": [25, 50, 100],
      "page-size": _vm.filters.limit,
      "current-page": _vm.currentPage,
      layout: "prev, jumper, pager, next, sizes",
      total: _vm.total
    },
    on: {
      "size-change": function sizeChange($event) {
        _vm.filters.limit = $event;
      },
      "current-change": _vm.getCannedResponses,
      "update:currentPage": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.currentPage = $event;
      }
    }
  })], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };