var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-wrapper app-fullscreen-wrapper"
  }, [_c("section", {
    staticClass: "app-main app-fullscreen-wrapper"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm._t("default")], 2)], 1), _vm._v(" "), _c("popups-ready")], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };