var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "activity-page-view__list"
  }, [_vm.selectedEvent.recordId ? _c("div", {
    staticClass: "margin-bottom-5"
  }, [_vm._v(_vm._s(_vm.$t("You are editing this activity")) + ": ")]) : _vm._e(), _vm._v(" "), _c("el-timeline", _vm._l(_vm.events, function (note, i) {
    return _c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.selectedEvent.recordId || note.recordId == _vm.selectedEvent.recordId,
        expression: "!selectedEvent.recordId || note.recordId==selectedEvent.recordId"
      }],
      key: "events" + i,
      staticClass: "timeline-content"
    }, [_vm.checkDate(i) ? _c("div", {
      staticClass: "headerDate"
    }, [_c("i", {
      staticClass: "fal fa-calendar"
    }), _vm._v("\n          " + _vm._s(_vm._f("onlyDateAndText")(note.startDate, "DD MMM YY", _vm.$locale)) + "\n        ")]) : _vm._e(), _vm._v(" "), _c("div", {
      key: note.recordId + "icon",
      staticClass: "timeline-icon-container",
      class: _vm.checkDate(i) ? "timeline-icon-first" : ""
    }, [note.calendar.value == "1" ? [_c("svg", {
      staticClass: "timeline-icon fa fa-calendar meetings-bg",
      class: _vm.checkDate(i) ? "" : ""
    })] : _vm._e(), _vm._v(" "), note.calendar.value == "6" ? [_c("svg", {
      staticClass: "timeline-icon fa fa-comment-alt-check updates-bg",
      class: _vm.checkDate(i) ? "" : ""
    })] : _vm._e(), _vm._v(" "), note.calendar.value == "8" ? [_c("svg", {
      staticClass: "timeline-icon fa fa-comment-alt-lines comments-bg",
      class: _vm.checkDate(i) ? "" : ""
    })] : _vm._e()], 2), _vm._v(" "), _c("div", {
      staticClass: "content"
    }, [_c("div", {
      staticClass: "columns"
    }, [_vm.isUserVisible ? _c("div", {
      staticClass: "column is-narrow"
    }, [note.createdBy ? _c("showUsers", {
      attrs: {
        selected_users: [note.createdBy.value],
        nameHidden: true,
        media_size: "large",
        size: "large"
      }
    }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.isUserVisible ? _c("div", {
      staticClass: "column is-3"
    }, [_c("p", {
      staticClass: "font-14 line-height-0_5"
    }, [_vm._v(_vm._s(note.createdBy.label))]), _vm._v(" "), note.calendar.value == "1" ? _c("p", {
      staticClass: "font-11 line-height-0_5 meetings-text"
    }, [_vm._v(_vm._s(_vm.$t("Meeting")))]) : _vm._e(), _vm._v(" "), note.calendar.value == "6" ? _c("p", {
      staticClass: "font-11 line-height-0_5 updates-text"
    }, [_vm._v(_vm._s(_vm.$t("Status")))]) : _vm._e(), _vm._v(" "), note.calendar.value == "8" ? _c("p", {
      staticClass: "font-11 line-height-0_5 comments-text"
    }, [_vm._v(_vm._s(_vm.$t("Comment")))]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("div", {
      staticClass: "column"
    }, [note.activityNotes ? _c("p", {
      domProps: {
        innerHTML: _vm._s(note.activityNotes)
      }
    }) : _c("p", {
      domProps: {
        innerHTML: _vm._s(note.subject)
      }
    }), _vm._v(" "), _c("p", {
      staticClass: "gray-text line-height-0_5"
    }, [note.calendar.value == "1" ? _c("span", {
      staticClass: "eventTime line-height-0_5"
    }, [_c("i", {
      staticClass: "fas fa-clock margin-right-5"
    }), _vm._v(" " + _vm._s(note.startTime) + " - " + _vm._s(note.endTime) + "\n                  "), _c("i", {
      staticClass: "fas fa-map-marker-alt margin-left-5 margin-right-2"
    }), _vm._v("\n                  " + _vm._s(note.location) + "\n                ")]) : note.calendar.value == "6" && note.projectStatus ? _c("span", {
      staticClass: "status"
    }, [_c("kv-tag", {
      attrs: {
        color: _vm.findStatusColor(note),
        width: "auto",
        label: note.projectStatus.label
      }
    })], 1) : _vm._e()])]), _vm._v(" "), _c("div", {
      staticClass: "column is-narrow"
    }, [note.recordOwner.value == _vm.me.userId ? _c("div", [_vm.selectedEvent && note.recordId == _vm.selectedEvent.recordId ? _c("span", {
      key: "note_times_" + i + _vm.selectedEvent.recordId,
      staticClass: "btn-top2"
    }, [_c("el-button", {
      attrs: {
        size: "small",
        type: "text",
        circle: "",
        href: "#"
      },
      on: {
        click: function click($event) {
          _vm.selectedEvent = {};
          _vm.$emit("removeSelectedEvent", "");
        }
      }
    }, [_c("span", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: note.recordId == _vm.selectedEvent.recordId,
        expression: "note.recordId==selectedEvent.recordId"
      }]
    }, [_c("svg", {
      staticClass: "fa fa-sm fa-times"
    })])])], 1) : _vm._e(), _vm._v(" "), _c("p", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: note.recordId != _vm.selectedEvent.recordId,
        expression: "note.recordId!=selectedEvent.recordId"
      }]
    }, [_c("el-link", {
      staticClass: "margin-right-5 text-primary",
      attrs: {
        type: "text",
        size: "small",
        color: "primary",
        title: _vm.$t("Edit")
      },
      on: {
        click: function click($event) {
          return _vm.handleUpdate({
            command: "edit",
            i: i
          });
        }
      }
    }, [_c("i", {
      staticClass: "fal fa-edit"
    })]), _vm._v(" "), _c("el-link", {
      staticClass: "margin-left-5 text-danger",
      attrs: {
        type: "text",
        size: "small",
        color: "danger",
        title: _vm.$t("Delete")
      },
      on: {
        click: function click($event) {
          return _vm.handleUpdate({
            command: "delete",
            i: i
          });
        }
      }
    }, [_c("i", {
      staticClass: "fal fa-trash-alt",
      attrs: {
        color: "danger"
      }
    })])], 1)]) : _vm._e()])])])]);
  }), 0)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };