import _objectSpread from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import KivaProxy from "@/utils/kivaProxy/src";
import popupsReady from "@/components/global/popupsReady";
export default {
  name: "FullScreenLayout",
  components: {
    popupsReady: popupsReady
  },
  created: function created() {
    this.handleIcon();
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  computed: {
    me: function me() {
      return _objectSpread({}, this.$store.state.users.CURRENT_USER);
    }
  },
  methods: {}
};