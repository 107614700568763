import "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/core-js/modules/es.array.iterator.js";
import "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/core-js/modules/es.promise.js";
import "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/core-js/modules/es.object.assign.js";
import "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n
import Cookies from 'js-cookie';
import '@/styles/index.scss'; // global css
import InfiniteLoading from 'vue-infinite-loading';

// devextreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
Vue.use(InfiniteLoading, {/* options */});

//font Awesome
// import library from '@fortawesome/fontawesome-pro'
import FontAwesomeIcon from '@/components/global/FontAwesomeIcon';

// library.add(fas, far, fal, fad)

Vue.component('font-awesome-icon', FontAwesomeIcon);
import VueContext from 'vue-context';
Vue.component('VueContext', VueContext);
import draggable from 'vuedraggable';
Vue.component('draggable', draggable);
import { BadgerAccordion, BadgerAccordionItem } from 'vue-badger-accordion';
Vue.component('BadgerAccordion', BadgerAccordion);
Vue.component('BadgerAccordionItem', BadgerAccordionItem);
import Render from '@/components/Render';
Vue.component('render', Render);
import App from './App';
import store from './store';
import router from './router';
import _i18n from './lang'; // internationalization
// Rulesup()

//VueGoodTable
import { VueGoodTable } from "vue-good-table";
Vue.component('VueGoodTable', VueGoodTable);
import svgIcon from "@/components/kiva/kvSvgIcon.vue";
Vue.component('svgIcon', svgIcon);
import '@/packages/redactor/redactor.css';
import '@/packages/redactor/redactor.min';
import '@/packages/redactor/redactor-vue';
import '@/packages/redactor/_plugins/alignment/alignment.js';
import '@/packages/redactor/_plugins/clips/clips.js';
import '@/packages/redactor/_plugins/counter/counter.js';
import '@/packages/redactor/_plugins/fontfamily/fontfamily.js';
import '@/packages/redactor/_plugins/fontsize/fontsize.js';
import '@/packages/redactor/_plugins/fullscreen/fullscreen.js';
import '@/packages/redactor/_plugins/table/table.js';
import '@/packages/redactor/_plugins/specialchars/specialchars.js';
import '@/packages/redactor/_plugins/inlinestyle/inlinestyle.js';
import '@/packages/redactor/_plugins/limiter/limiter.js';
import '@/packages/redactor/_plugins/fontcolor/fontcolor.js';
import '@/packages/redactorx/redactorx.css';
import '@/packages/redactorx/redactorx.min';
import '@/packages/redactorx/redactorx-vue';
import '@/packages/redactorx/plugins/alignment/alignment.js';
import '@/packages/redactorx/plugins/clips/clips.js';
import '@/packages/redactorx/plugins/counter/counter.js';
import '@/packages/redactorx/plugins/blockcode/blockcode.js';
import '@/packages/redactorx/plugins/handle/handle.js';
// import '@/packages/redactorx/plugins/fullscreen/fullscreen.js'
import '@/packages/redactorx/plugins/icons/icons.js';
import '@/packages/redactorx/plugins/specialchars/specialchars.js';
import '@/packages/redactorx/plugins/inlineformat/inlineformat.js';
import '@/packages/redactorx/plugins/selector/selector.js';
import '@/packages/redactorx/plugins/textdirection/textdirection.js';
import '@/packages/redactorx/plugins/textexpander/textexpander.js';
import '@/packages/redactorx/plugins/imageposition/imageposition.js';

//Article
import './packages/article/article.css';
import './packages/article/article-editor.usm.min';
import './packages/article/article-vue';
import '@/icons'; // icon
import '@/permission'; // permission control
import '@/mixins/global';
import '@/filters';
import '@/plugins/vue-editor';
import '@/plugins/vue-scroll-to';
import '@/plugins/vee-validate';
import '@/plugins/v-viewer';
import KivaProxy from '@/utils/kivaProxy/src';
import Editor from 'vue-editor-js';
import VueCodeHighlight from 'vue-code-highlight';
Vue.use(VueCodeHighlight);
Vue.use(Editor);
import { installRulesup } from '@rulesup/rulesup';
if (process.env.VUE_APP_APP_ID === 'rulesup') {
  installRulesup();
} else {
  installRulesup('rulesup');
}

// import necessary firebase products
// import firebase from 'firebase/app'
// import 'firebase/auth'
//import 'firebase/database';
//import 'firebase/analytics';

// $R.options = {
//   styles: false,
// }
// var firebaseApp = firebase.initializeApp({
//   apiKey: 'AIzaSyCUZn7uBYnH6WoO8QVRsRYxFsoFLddpVeY',
//   authDomain: 'kivacloud-8e9e4.firebaseapp.com',
//   databaseURL: 'https://kivacloud-8e9e4.firebaseio.com',
//   projectId: 'kivacloud',
//   storageBucket: 'kivacloud.appspot.com',
//   messagingSenderId: '1077811191654',
//   appId: '1:1077811191654:web:67da729ceb238364b6b2a7',
//   measurementId: 'G-BQS1H1EKFL'
// })
// firebase.auth().onAuthStateChanged(user => { });
// firebase.messaging().requestPermission().then(() => { });

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'small',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
KivaProxy.global.createHttpDriver('base', {
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BRIDGE
});
KivaProxy.global.createHttpDriver('custom', {
  withCredentials: true,
  baseURL: "".concat(process.env.VUE_APP_API_BRIDGE, "/custom")
});
if (window) {
  window.KivaProxy = KivaProxy;
}
window.vm = {};
var app = new Vue({
  i18n: _i18n,
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
}).$mount('#app');