import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
export var users = {
  namespaced: true,
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};