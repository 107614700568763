import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "searchContainer"
  }, [_vm.searchGlobal.show ? _c("div", {
    staticClass: "darkBg",
    class: _vm.searchGlobal.show ? "opacityHigh" : "opacityLow",
    on: {
      click: function click($event) {
        return _vm.closeEvent();
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.tasksPopup.show && _vm.tasksPopup.task ? _c("task-edit-modal", {
    staticStyle: {
      "z-index": "99999"
    },
    attrs: {
      task: _vm.tasksPopup.task,
      isRefresh: true
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.tasksPopup.show = $event;
      },
      setShowSearch: function setShowSearch($event) {
        return _vm.setShowSearch($event);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.templatePopup.show && _vm.templatePopup.data ? _c("modal-template", {
    staticStyle: {
      "z-index": "99999"
    },
    attrs: {
      data: _vm.templatePopup.data,
      extra: _vm.templatePopup.data,
      globalGroups: _vm.globalGroups
    },
    on: {
      updateShow: function updateShow($event) {
        _vm.templatePopup.show = $event;
        _vm.templatePopup.showView = $event;
        _vm.setShowSearch(true);
      },
      showResults: function showResults($event) {
        _vm.showResults = $event;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "globalSearchBox",
    class: [_vm.toggleSidebar.opened ? "padding" : "no-padding", _vm.searchGlobal.show || _vm.templatePopup.show || _vm.tasksPopup.show ? "zindex3" : "zindex0"]
  }, [_c("span", {
    staticClass: "globalIcons"
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showGroups,
      expression: "showGroups"
    }],
    staticClass: "groupSelection kv-card-default kv-shadow-default"
  }, [_c("ul", [_c("li", [_c("div", {
    staticClass: "pull-left leftSide"
  }, [_vm._v(_vm._s(_vm.$t("Object")))]), _vm._v(" "), _c("div", {
    staticClass: "pull-right"
  }, [_vm._v(_vm._s(_vm.$t("Shortcut")))])]), _vm._v(" "), _vm._l(_vm.globalGroups, function (gr, i) {
    return _c("li", {
      class: _vm.currentItemGroup == gr.index ? "active-item" : "",
      on: {
        click: function click($event) {
          return _vm.addGroup(gr);
        }
      }
    }, [_c("div", {
      staticClass: "pull-left leftSide"
    }, [_c("i", {
      class: gr.icon
    }), _vm._v("\n                " + _vm._s(gr.label) + "\n              ")]), _vm._v(" "), _c("div", {
      staticClass: "pull-right"
    }, [_vm._v(_vm._s(gr.id))])]);
  })], 2)])]), _vm._v(" "), _c("span", {
    staticClass: "globalIcons"
  }, [_c("span", {
    staticClass: "singleIcon",
    class: _vm.searchGlobal.show ? "inWideView" : ""
  }, [_c("i", {
    staticClass: "fa fa-search"
  })])]), _vm._v(" "), _c("div", {
    staticClass: "searchContainer kv-radius-default",
    class: _vm.searchGlobal.show ? "boxAction kv-scroll kv-card-default kv-shadow-default" : "boxNoAction"
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.globalKeyword,
      expression: "globalKeyword"
    }],
    ref: "globalSearch",
    staticClass: "globalSearchInput",
    attrs: {
      type: "text",
      placeholder: _vm.$t("Search")
    },
    domProps: {
      value: _vm.globalKeyword
    },
    on: {
      focus: function focus($event) {
        return _vm.showSearchPanel();
      },
      keydown: [function ($event) {
        _vm.searchGlobal.show = true;
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")) return null;
        $event.preventDefault();
        return _vm.chooseGroup();
      }],
      keyup: [function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        _vm.showGroups = false;
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.clickEnter();
      }, _vm.handleSearch],
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.globalKeyword = $event.target.value;
      }
    }
  }), _vm._v(" "), _vm.globalKeyword ? _c("span", {
    staticClass: "exitIcon",
    class: _vm.searchGlobal.show ? "inWideView" : "",
    on: {
      click: function click($event) {
        return _vm.removeGlobalSearch();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-times"
  })]) : _vm._e(), _vm._v(" "), _vm.searchGlobal.show ? _c("div", {
    ref: "kvGlobalSearch",
    staticClass: "globalResults kv-card-default kv-shadow-default kv-radius-default kv-scroll"
  }, [_vm.selectedGroup && _vm.selectedGroup.model ? _c("div", {
    staticClass: "headTitle"
  }, [_c("span", {
    staticClass: "first",
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v(_vm._s(_vm.$t("Search in")))]), _vm._v(" "), _vm._l(_vm.selectedGroup.options, function (opt) {
    return _c("span", {
      key: opt.key,
      staticClass: "box filter",
      class: _vm.search.options == opt.key ? "selected" : "",
      on: {
        click: function click($event) {
          _vm.searchGlobal.show = true;
          _vm.triggerSearch(_vm.selectedGroup, opt.key);
        }
      }
    }, [_vm._v("\n              " + _vm._s(opt.label) + "\n              "), _vm.search.options == opt.key ? _c("a", {
      staticClass: "cursor-pointer text-danger",
      on: {
        click: function click($event) {
          $event.preventDefault();
          _vm.removeFromGroup(_vm.selectedGroup);
          _vm.searchGlobal.show = true;
        }
      }
    }, [_vm._v("X")]) : _vm._e()]);
  })], 2) : _vm.globalKeyword ? _c("div", {
    staticClass: "headTitle"
  }, [_vm.foundGroups[0] ? _c("span", {
    staticClass: "box filter",
    class: _vm.selectOne ? "" : "selected text-primary",
    on: {
      click: function click($event) {
        return _vm.showGroup("all");
      }
    }
  }, [_vm._v("\n              " + _vm._s(_vm.$t("All")) + "\n            ")]) : _vm._e(), _vm._v(" "), _vm._l(_vm.foundGroups, function (item) {
    return _c("span", {
      key: item.id,
      staticClass: "box filter",
      class: !_vm.selectOne || item.hide ? "" : "selected text-primary",
      on: {
        click: function click($event) {
          return _vm.showGroup(item);
        }
      }
    }, [_vm._v("\n              " + _vm._s(item.label) + "\n              " + _vm._s(_vm.resultGroups[item.key] && _vm.resultGroups[item.key][0] ? "(" + _vm.resultGroups[item.key].length + ")" : "") + "\n            ")]);
  })], 2) : _vm._e(), _vm._v(" "), _vm.selectedGroup && _vm.selectedGroup && _vm.selectedGroup.model && _vm.selectedGroup.model != "" && _vm.displayGroup ? [_c(_vm.selectedGroup.component, {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingSearch,
      expression: "loadingSearch"
    }],
    tag: "component",
    attrs: {
      currentItem: _vm.currentItem,
      results: _vm.results,
      selectedGroup: _vm.selectedGroup
    },
    on: {
      getPath: function getPath($event) {
        return _vm.getPath($event[0], $event[1]);
      }
    }
  })] : _vm.selectedGroup && _vm.selectedGroup && _vm.selectedGroup.model && _vm.selectedGroup.model != "task" && _vm.displayGroup ? [_c("standatd-list", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingSearch,
      expression: "loadingSearch"
    }],
    attrs: {
      results: _vm.results,
      selectedGroup: _vm.selectedGroup,
      currentItem: _vm.currentItem
    },
    on: {
      getPath: function getPath($event) {
        return _vm.getPath($event[0], $event[1]);
      }
    }
  })] : _vm.globalKeyword && _vm.displayGroup ? [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loadingSearch,
      expression: "loadingSearch"
    }]
  }, _vm._l(_vm.foundGroups, function (group, groupIndex) {
    return _c("div", {
      staticClass: "groupPart"
    }, [_c("div", {
      staticClass: "groupTitle"
    }, [_c("div", {
      staticClass: "title",
      on: {
        click: function click($event) {
          return _vm.showGroup(group);
        }
      }
    }, [_c("i", {
      class: group.icon
    }), _vm._v("\n                    " + _vm._s(_vm.$t(group.label)) + "\n                    " + _vm._s(_vm.resultGroups[group.key] && _vm.resultGroups[group.key][0] ? "(" + _vm.resultGroups[group.key].length + ")" : "") + "\n                    "), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: group && group.hide,
        expression: "group && group.hide"
      }],
      key: "cDown" + group.model,
      staticClass: "fal fa-chevron-down"
    }), _vm._v(" "), _c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !group || !group.hide,
        expression: "!group || !group.hide"
      }],
      key: "cUp" + group.model,
      staticClass: "fal fa-chevron-up"
    })]), _vm._v(" "), _c("div", [_c("a", {
      staticClass: "margin-right-5",
      on: {
        click: function click($event) {
          return _vm.triggerSearch(group, "all");
        }
      }
    }, [_c("i", {
      staticClass: "fas fa-filter"
    })]), _vm._v(" "), _vm._l(group.options, function (opt) {
      return _c("el-link", {
        key: opt.key,
        staticStyle: {
          "margin-left": "8px",
          "margin-top": "-2px",
          "font-size": "13px",
          cursor: "pointer"
        },
        on: {
          click: function click($event) {
            return _vm.triggerSearch(group, opt.key);
          }
        }
      }, [_vm._v(_vm._s(opt.label))]);
    })], 2)]), _vm._v(" "), _vm.resultGroups[group.key] && _vm.resultGroups[group.key][0] ? _c("ul", {
      ref: "kvGlobalSelect",
      refInFor: true,
      staticClass: "resultsList kv-scroll",
      class: !group || group.hide ? "hidden" : "visible"
    }, _vm._l(_vm.resultGroups[group.key], function (res, i) {
      return _c("li", {
        key: res.record_id,
        staticClass: "item",
        class: _vm.currentGroup == groupIndex && _vm.currentItem == i ? "active-item" : ""
      }, [_c("div", {
        staticClass: "content"
      }, [_c("span", {
        staticClass: "content_title"
      }, [_c("a", {
        on: {
          click: function click($event) {
            return _vm.getPath(res, "link");
          }
        }
      }, [_vm._v(_vm._s(res.label) + "  ")])]), _vm._v(" "), _c("span", {
        staticClass: "content_subtitle"
      }, [_vm._v("\n                        " + _vm._s(res.label_2) + " \n                      ")])]), _vm._v(" "), _c("div", {
        staticClass: "button",
        staticStyle: {
          "min-width": "55px"
        }
      }, [group.model != "document" && group.model != "task" && group.model != "project" && group.model != "event" && group.model != "contact" ? [_c("el-button", {
        staticClass: "square-icon-button no-border padding-5",
        attrs: {
          type: "none",
          size: "medium",
          icon: "fal fa-external-link-alt"
        },
        on: {
          click: function click($event) {
            return _vm.getPath(res, "link");
          }
        }
      })] : _vm._e(), _vm._v(" "), group.model != "document" && group.model != "account" && group.model != "page" && group.model != "project" && group.model != "task" && group.model != "contact" && group.model != "deal" ? [_c("el-button", {
        staticClass: "square-icon-button no-border padding-5",
        attrs: {
          type: "none",
          size: "medium",
          icon: "fal fa-edit"
        },
        on: {
          click: function click($event) {
            return _vm.getPath(res, "editMode");
          }
        }
      })] : _vm._e(), _vm._v(" "), group.model != "event" ? [_c("el-button", {
        staticClass: "square-icon-button no-border padding-5",
        attrs: {
          type: "none",
          size: "medium",
          icon: "fal fa-eye"
        },
        on: {
          click: function click($event) {
            return _vm.getPath(res);
          }
        }
      })] : _vm._e(), _vm._v(" "), group.model == "project" ? [_c("el-button", {
        staticClass: "no-border padding-5",
        attrs: {
          type: "none",
          size: "medium"
        },
        on: {
          click: function click($event) {
            return _vm.getPath(res, "link");
          }
        }
      }, [_c("svg-icon", {
        attrs: {
          icon: "kv-icon-projects"
        }
      })], 1), _vm._v(" "), _c("el-button", {
        staticClass: "no-border padding-5",
        attrs: {
          type: "none",
          size: "medium"
        },
        on: {
          click: function click($event) {
            return _vm.getPath(res, "editMode");
          }
        }
      }, [_c("svg-icon", {
        attrs: {
          icon: "kv-icon-task"
        }
      })], 1)] : _vm._e()], 2)]);
    }), 0) : _vm._e(), _vm._v(" "), !_vm.foundGroups[0] ? _c("div", {
      staticClass: "pull-left width-full padding-10 text-center"
    }, [_vm._v("\n                    " + _vm._s(_vm.$t("Lets type what you want to find")) + "\n                ")]) : _vm._e()]);
  }), 0)] : _vm.displayGroup ? [_c("div", {
    staticStyle: {
      padding: "20px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 12
    }
  }, [_c("el-col", {
    key: "groupAll",
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "modelListCard",
    class: _vm.currentItemGroup == 0 ? "active-item" : "",
    on: {
      click: function click($event) {
        return _vm.addGroup({});
      }
    }
  }, [_c("i", {
    staticClass: "margin-right-10 fal fa-layer-group"
  }), _vm._v("\n                    " + _vm._s(_vm.$t("All")) + "\n                  ")])]), _vm._v(" "), _vm._l(_vm.globalGroups, function (group, groupIndex) {
    return [_c("el-col", {
      key: "group" + groupIndex,
      attrs: {
        span: group.index > 4 ? 8 : 8
      }
    }, [_c("div", {
      staticClass: "modelListCard",
      class: _vm.currentItemGroup == group.index ? "active-item" : "",
      on: {
        click: function click($event) {
          return _vm.addGroup(group);
        }
      }
    }, [_c("div", {
      staticClass: "modelText"
    }, [_c("i", {
      staticClass: "margin-right-10",
      class: group.icon
    }), _vm._v("\n                        " + _vm._s(_vm.$t(group.label)) + "\n                      ")]), _vm._v(" "), _c("span", {
      staticClass: "modelIcon"
    }, [_vm._v(_vm._s(group.id))])])])];
  })], 2), _vm._v(" "), _c("historyTable")], 1)] : _vm._e()], 2) : _vm._e()])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };