import "core-js/modules/es.json.stringify.js";
export function crmContactModel() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return Object.assign({
    recordId: null,
    accountName: {
      value: null
    },
    firstname: null,
    lastname: null,
    fullName: null,
    contactStatus: {
      value: 1
    },
    portalProfile: {
      value: 1,
      label: "Member"
    },
    title: null,
    mobile: null,
    phone: null,
    email: null,
    emailOptIn: false,
    isPrimary: false,
    PrivacyOptIn: false,
    CallOptIn: false,
    smsOptIn: false,
    customerPortalUser: false
  }, JSON.parse(JSON.stringify(data)));
}