import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import { makeNest } from '@/utils/makeNest';
import { CATEGORIES, FILES, LABELS } from './mutation-types';
export default {
  getCategoryTree: function getCategoryTree(state) {
    return makeNest(state[CATEGORIES], {
      elementKey: 'recordId',
      parentKey: 'parentCategory',
      childKey: 'children'
    });
  },
  getCategoryFiles: function getCategoryFiles(state) {
    return function (categoryId) {
      return state[FILES].filter(function (file) {
        return file.category.value === categoryId;
      });
    };
  },
  getLabels: function getLabels(state) {
    return state[LABELS];
  }
};