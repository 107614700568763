import "core-js/modules/es.number.constructor.js";
export default {
  name: 'KvModal',
  props: {
    value: {
      required: true
    },
    title: {
      default: ''
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    isHeaderShow: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'wide' // or slim
    },

    cols: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      mutableValue: this.value
    };
  },
  computed: {
    setClass: function setClass() {
      var size = this.size;
      var cols = this.cols == '1' ? '1' : this.cols == '2' ? '2' : '1';
      return ' kv-modal-col-' + size + '-' + cols;
    }
  },
  watch: {
    value: function value(val) {
      this.mutableValue = val;
    },
    mutableValue: function mutableValue(val) {
      if (!val) {
        this.$emit('beforeClose');
      }
      this.$emit('input', val);
    }
  }
};