import _defineProperty from "/builds/kivateknoloji/kivacloud/corona-ux/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _CURRENCIES$TIME_ZONE;
import { LOCALES, CURRENCIES, LANGUAGES, TIME_ZONES, TIME_ENTRIES, CRM_CURRENCIES, PROFILES, PREFERENCES, GROUPS, INBOX_PREFERENCES, ACTIVITY_STATUS } from '@/store/modules/common/mutation-types';
export default (_CURRENCIES$TIME_ZONE = {}, _defineProperty(_CURRENCIES$TIME_ZONE, CURRENCIES, function (state, payload) {
  state[CURRENCIES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, TIME_ZONES, function (state, payload) {
  state[TIME_ZONES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, TIME_ENTRIES, function (state, payload) {
  state[TIME_ENTRIES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, LANGUAGES, function (state, payload) {
  state[LANGUAGES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, LOCALES, function (state, payload) {
  state[LOCALES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, CRM_CURRENCIES, function (state, payload) {
  state[CRM_CURRENCIES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, PROFILES, function (state, payload) {
  state[PROFILES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, PREFERENCES, function (state, payload) {
  state[PREFERENCES] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, ACTIVITY_STATUS, function (state, payload) {
  state[ACTIVITY_STATUS] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, GROUPS, function (state, payload) {
  state[GROUPS] = payload;
}), _defineProperty(_CURRENCIES$TIME_ZONE, INBOX_PREFERENCES, function (state, payload) {
  state[INBOX_PREFERENCES] = payload;
}), _CURRENCIES$TIME_ZONE);