import { state } from './state';
import actions from './actions';
import mutations from './mutations';
// import getters from './getters'

export var project = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};