export default {
  methods: {
    getSetting: function getSetting(module, value) {
      var pref = this.$store.state.settings.preferences[module];
      if (pref && typeof pref[value] != "undefined") {
        return pref[value];
      } else {
        return this.getDefaultPreferences(module, value);
      }
    },
    getDefaultPreferences: function getDefaultPreferences(module, value) {
      var defaults = {};
      return false;
    }
  }
};