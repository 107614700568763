import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.atob.js";
import "core-js/modules/web.dom-exception.constructor.js";
import "core-js/modules/web.dom-exception.stack.js";
import "core-js/modules/web.dom-exception.to-string-tag.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array-buffer.slice.js";
import "core-js/modules/es.typed-array.uint8-array.js";
import "core-js/modules/es.typed-array.at.js";
import "core-js/modules/es.typed-array.copy-within.js";
import "core-js/modules/es.typed-array.every.js";
import "core-js/modules/es.typed-array.fill.js";
import "core-js/modules/es.typed-array.filter.js";
import "core-js/modules/es.typed-array.find.js";
import "core-js/modules/es.typed-array.find-index.js";
import "core-js/modules/es.typed-array.find-last.js";
import "core-js/modules/es.typed-array.find-last-index.js";
import "core-js/modules/es.typed-array.for-each.js";
import "core-js/modules/es.typed-array.includes.js";
import "core-js/modules/es.typed-array.index-of.js";
import "core-js/modules/es.typed-array.iterator.js";
import "core-js/modules/es.typed-array.join.js";
import "core-js/modules/es.typed-array.last-index-of.js";
import "core-js/modules/es.typed-array.map.js";
import "core-js/modules/es.typed-array.reduce.js";
import "core-js/modules/es.typed-array.reduce-right.js";
import "core-js/modules/es.typed-array.reverse.js";
import "core-js/modules/es.typed-array.set.js";
import "core-js/modules/es.typed-array.slice.js";
import "core-js/modules/es.typed-array.some.js";
import "core-js/modules/es.typed-array.sort.js";
import "core-js/modules/es.typed-array.subarray.js";
import "core-js/modules/es.typed-array.to-locale-string.js";
import "core-js/modules/es.typed-array.to-string.js";
import "core-js/modules/esnext.typed-array.to-reversed.js";
import "core-js/modules/esnext.typed-array.to-sorted.js";
import "core-js/modules/esnext.typed-array.with.js";
import KivaProxy from '@/utils/kivaProxy/src';
import { imageCropModalService } from '@/components/kiva/kv-image-crop/KvImageCropModalService';
export function temporaryImageUpload(fd, files, event, upload) {
  return new Promise(function (resolve, reject) {
    imageCropModalService({
      image: URL.createObjectURL(files[0])
    }).then(function (blob) {
      var formData = new FormData();
      formData.append('file', blob, files[0].name);
      new KivaProxy({
        endpoint: 'kvc/inbox/tmp_image',
        driver: 'base'
      }).submit('post', 'kvc/inbox/tmp_image', formData).then(function (response) {
        resolve(response.url);
      }).catch(reject);
    });
  }).then(function (response) {
    upload.complete(response);
  }).catch(function (response) {
    upload.complete(response);
  });
}
export function temporaryImageBase64(dataURI) {
  return new Promise(function (resolve, reject) {
    // serialize the base64/URLEncoded data
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // parse the mime type
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // construct a Blob of the image data
    var array = [];
    for (var i = 0; i < byteString.length; i++) {
      array.push(byteString.charCodeAt(i));
    }
    var blob = new Blob([new Uint8Array(array)], {
      type: mimeString
    });
    blob.name = 'captured_screenshot.png';
    var formData = new FormData();
    formData.append('file', blob, blob.name);
    new KivaProxy({
      endpoint: 'kvc/inbox/tmp_image',
      driver: 'base'
    }).submit('post', 'kvc/inbox/tmp_image', formData).then(function (response) {
      resolve(response.url);
    }).catch(reject);
  });
}