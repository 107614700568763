var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("kv-modal", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "image-crop-modal",
    attrs: {
      size: "wide",
      cols: 2
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._v("\n    " + _vm._s("Image Crop") + "\n  ")];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            icon: "fa fa-times"
          },
          on: {
            click: _vm.handleClose
          }
        }, [_vm._v("\n      " + _vm._s("Cancel") + "\n    ")]), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "primary",
            icon: "fal fa-save"
          },
          on: {
            click: _vm.getBlobAndClose
          }
        }, [_vm._v("\n      " + _vm._s("Save") + "\n    ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isVisible,
      callback: function callback($$v) {
        _vm.isVisible = $$v;
      },
      expression: "isVisible"
    }
  }, [_vm._v(" "), _c("kv-image-crop", {
    ref: "cropComponent",
    attrs: {
      image: _vm.image,
      quality: _vm.quality,
      "blob-type": _vm.blobType
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };